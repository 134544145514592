import { useEffect, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

interface CustomDateTimePickerProps {
  date?: Date | null;
  handleDateChange: (value: Date | null) => void;
  shouldDialogBeOpen?: boolean,
}

export default function CustomDateTimePicker({ date, handleDateChange, shouldDialogBeOpen = false }: CustomDateTimePickerProps): React.JSX.Element {
  const [isPickerModalOpen, setIsPickerModalOpen] = useState(shouldDialogBeOpen);

  useEffect(() => {
    setIsPickerModalOpen(shouldDialogBeOpen);
  }, [shouldDialogBeOpen]);

  return (
    <DateTimePicker
      /**
       * Since @mui/x-date-pickers-pro upgrade, date as string is not supported anymore by the adapter
       * Even if date is actually typed as a Date, it's a string due to graphQL scalars which
       * are not serialized by apollo (it was never done) but it show up today thanks
       * to the MUI upgrade -> KHEOPS-988 has been created to fix this
       * For now, we forced the date to be datified
       */
      value={date ? new Date(date) : null}
      onClose={() => setIsPickerModalOpen(false)}
      open={isPickerModalOpen}
      ampm={false}
      disablePast
      // https://github.com/mui/mui-x/issues/8520
      disableIgnoringDatePartForTimeValidation
      views={['year', 'day', 'hours']}
      format="dd/MM/yyyy HH:mm"
      slotProps={{
        inputAdornment: {
          position: 'start',
        },
        textField: {
          onClick: () => setIsPickerModalOpen(true),
        },
        openPickerIcon: {
          sx: {
            width: 20,
            height: 20,
            color: 'text.primary',
          },
        },
      }}
      slots={{
        openPickerIcon: LocalShippingOutlinedIcon,
      }}
      onChange={(value, context) => {
        if (context.validationError) {
          return;
        }
        handleDateChange(value);
      }}
    />
  );
}
