import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { Order_Status_Enum, TaskAction } from 'kheops-graphql';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useTheme } from '@mui/system';
import CustomDateTimePicker from '../../common/components/CustomDateTimePicker';
import { orderDeliveryDateAtom } from '../state/state';

export type DeliveryDateOrderStatus = Order_Status_Enum.OrderToBeValidatedBySupplier | Order_Status_Enum.OrderToBeDeliveredBySupplier | Order_Status_Enum.OrderToBePreparedBySupplier;

interface OrderDeliveryDateDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  orderStatus: DeliveryDateOrderStatus;
  actionType: Exclude<TaskAction, TaskAction.Cancel>;
}

const deliveryDateDialogWordingMap : {
  [action in Exclude<TaskAction, TaskAction.Cancel>]: {
  [status in DeliveryDateOrderStatus]: {
  title: string;
  description: string;
}}} = {
  APPROVE: {
    order_to_be_validated_by_supplier: {
      title: 'order:validate_order',
      description: 'order:confirm_delivery_date_message',
    },
    order_to_be_delivered_by_supplier: {
      title: 'order:confirm_delivery',
      description: 'order:confirm_delivery_message',
    },
    order_to_be_prepared_by_supplier: {
      title: 'order:confirm_delivery',
      description: 'order:confirm_delivery_message',
    },
  },
  MODIFY: {
    order_to_be_delivered_by_supplier: {
      title: 'order:reschedule_delivery',
      description: 'order:reschedule_delivery_message',
    },
    order_to_be_validated_by_supplier: {
      title: 'order:validate_order',
      description: 'order:confirm_delivery_date_message',
    },
    order_to_be_prepared_by_supplier: {
      title: 'order:reschedule_delivery',
      description: 'order:reschedule_delivery_message',
    },
  },
};

export default function OrderDeliveryDateDialog({ open, onClose, orderStatus, actionType }: OrderDeliveryDateDialogProps): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'order']);
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false);
  const [deliveryDate, setDeliveryDate] = useAtom(orderDeliveryDateAtom);
  const handleCancel = (): void => {
    onClose(false);
    setShouldShowErrorMessage(false);
  };

  const handleDateChange = (value: Date | null): void => {
    setDeliveryDate(value);
  };

  const handleConfirm = (): void => {
    if (!deliveryDate && orderStatus === Order_Status_Enum.OrderToBeValidatedBySupplier) {
      setShouldShowErrorMessage(true);
      return;
    }
    onClose(true);
  };

  const dateComponent = useMemo(() => {
    if (orderStatus === Order_Status_Enum.OrderToBeValidatedBySupplier || actionType === TaskAction.Modify) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: 'auto' }}>
          <Typography variant="h6" sx={{ mb: 1, textAlign: 'center' }}>
            {t('order:delivery_date')}
          </Typography>
          <CustomDateTimePicker
            date={deliveryDate}
            handleDateChange={handleDateChange}
          />
        </Box>
      );
    }
    return (
      <></>
    );
  }, [orderStatus, deliveryDate]);

  return (
    <Dialog onBackdropClick={handleCancel} open={open}>
      <DialogTitle>
        <Typography variant="h2">
          {t(deliveryDateDialogWordingMap[actionType][orderStatus].title)}
        </Typography>
        <Divider sx={{ mt: 3, mb: 1 }} />
      </DialogTitle>
      <DialogContent
        sx={{
          width: '590',
          align: 'center',
          justifyContent: 'center',
          m: 'auto',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            px: 0,
          },
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            mb: 3,
            [theme.breakpoints.down('sm')]: {
              px: 2,
            },
          }}
          variant="body1"
        >
          {t(deliveryDateDialogWordingMap[actionType][orderStatus].description)}
        </Typography>
        {dateComponent}
      </DialogContent>
      {shouldShowErrorMessage && (<Typography variant="subtitle1" color="error" align="center">{t('order:delivery_date_error_message')}</Typography>)}
      <DialogActions>
        <Button onClick={handleCancel} color="inherit" variant="text">
          {t('common:cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
        >
          {t('common:validate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
