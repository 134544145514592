import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Product_Family_Name_Enum } from 'kheops-graphql';
import { ProductFamilyMap } from '../state';
import { productFamilyLogoMap } from '../../common/utils/file.utils';
import { PackagingFamilyTypeIntl, ProductFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import ProductFamilyChip from '../settingsDialog/ProductFamilyChip';
import { useProductSubFamiliesQuery } from '../../queries/__generated__/productSubFamilies.generated';

export interface UsersDepartmentsByFamilyProps {
  selectedDepartments: ProductFamilyMap;
}

export default function UserDepartmentsByFamily({ selectedDepartments }: UsersDepartmentsByFamilyProps): React.JSX.Element {
  const { t } = useTranslation(['product-family', 'settings']);
  const { data: productSubFamiliesData } = useProductSubFamiliesQuery({ fetchPolicy: 'cache-only' });

  const isProductFamilySelected = (productFamily: Product_Family_Name_Enum): boolean => {
    return selectedDepartments[productFamily].length === productSubFamiliesData?.product_sub_family.filter((subFamily) => subFamily.product_family_name === productFamily).length;
  };

  return (
    <>
      {(Object.keys(selectedDepartments) as Product_Family_Name_Enum[]).map((productFamily) => {
        if (!selectedDepartments[productFamily].length) {
          return;
        }

        return (
          <Box
            key={productFamily}
            sx={{
              p: 2,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <img src={productFamilyLogoMap[productFamily]} alt={productFamily} height="64" />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 600, lineHeight: 1.125 }}>
                {t(`product-family:${ProductFamilyTypeIntl[productFamily]}`)}
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 1 }}>
                {
                  isProductFamilySelected(productFamily)
                    ? <ProductFamilyChip label={t('settings:all_departments')} selected />
                    : (
                      <>
                        {selectedDepartments[productFamily].map((subFamily) => {
                          return (
                            <ProductFamilyChip label={t(`product-family:${[PackagingFamilyTypeIntl[subFamily]]}`)} key={subFamily} selected />
                          );
                        })}
                      </>
                    )
                }
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
}
