import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { SxProps } from '@mui/material';
import { currentBasketQuantitiesAtom, currentBuyingCompanyIdAtom } from '../../basket/state/state';
import QuantitySelector from '../../common/components/quantitySelector/QuantitySelector';
import { useBasketPackagingQuantity } from '../../hooks/usePackagingQuantity';
import { contractAtom } from '../../state';

interface UpdateBasketButtonProps {
  packagingId: string;
  supplyingCompanyId: string;
  shouldDisplayTitle?: boolean;
  addToCartButtonSx?: SxProps;
}

export default function UpdateBasketButton({ packagingId, supplyingCompanyId, shouldDisplayTitle, addToCartButtonSx }: UpdateBasketButtonProps): React.JSX.Element {
  const editBasketPackagingQuantity = useSetAtom(currentBasketQuantitiesAtom);
  const buyingCompanyId = useAtomValue(currentBuyingCompanyIdAtom);
  const basketContractAtom = useMemo(() => {
    return contractAtom(buyingCompanyId!, supplyingCompanyId);
  }, [buyingCompanyId, supplyingCompanyId]);
  const contract = useAtomValue(basketContractAtom);

  const packagingQuantity = useBasketPackagingQuantity({ id: `${packagingId}_${contract?.reference_id}` });

  const handleQuantityChange = useCallback((newQuantity: number) => {
    editBasketPackagingQuantity({
      id: packagingQuantity?.id,
      packagingId,
      quantity: newQuantity,
      contractId: contract!.reference_id!,
    });
  }, [contract, packagingQuantity, packagingId]);

  return (
    contract
      ? <QuantitySelector quantity={packagingQuantity?.quantity || 0} onQuantityChange={handleQuantityChange} shouldDisplayTitle={shouldDisplayTitle} addToCartButtonSx={addToCartButtonSx} />
      : <></>
  );
}
