import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractPreview } from '../../hooks/useContracts';
import CheckboxAutocomplete from './CheckboxAutocomplete';

export interface ContractsSelectProps {
  contracts: ContractPreview[];
  hiddenContracts?: ContractPreview[];
  values: string[];
  onChange: (selectedIds: string[]) => void;
}

interface BuyerOption {
  value: string;
  label: string;
}

export default function ContractsSelect({ contracts, hiddenContracts, values, onChange }: ContractsSelectProps): React.JSX.Element {
  const { t } = useTranslation(['price']);

  const buyersOptions = useMemo((): BuyerOption[] => {
    const selectedHiddenContracts = (hiddenContracts || []).filter((contract) => values.includes(contract.id));

    return contracts.concat(selectedHiddenContracts).map(({ id, company: { tradeName } }) => ({
      value: id,
      label: tradeName,
    }));
  }, []);

  const buyersValues = useMemo((): BuyerOption[] => {
    return values.map((value) => {
      const resolvedContract = contracts.find((contract) => contract.id === value)
        || (hiddenContracts || []).find((contract) => contract.id === value);

      return {
        value,
        label: resolvedContract!.company.tradeName,
      };
    });
  }, [values]);

  return (
    <CheckboxAutocomplete
      onChange={(newValues) => onChange(newValues.map(({ value }) => value))}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': { minHeight: 48 },
      }}
      InputProps={{
        startAdornment: (
          <AddBusinessOutlinedIcon fontSize="small" sx={{ mx: 1 }} />
        ),
      }}
      label={t('price:select_catalog_stores')}
      options={buyersOptions}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(buyer) => buyer.label}
      values={buyersValues}
    />
  );
}
