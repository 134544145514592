import { Box } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { basketDataAtom } from '../../basket/state/state';
import MinimumOrderValueChip from '../../common/components/MinimumOrderValueChip';
import FileUtils from '../../common/utils/file.utils';
import { useBasketOrders } from '../../hooks/useBasketOrders';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import FilterTag from './FilterTag';
import { contractsAtom } from '../../state';

interface CompanyTagsProps {
  attribute: string;
}

interface DisplayCompany {
  name: string;
  contractId: string;
  photo: string;
  minimumOrderValue: number;
}

export default function CompanyTags({ attribute }: CompanyTagsProps): React.JSX.Element {
  const { refine } = useRefinementList({ attribute });
  const currentRefinements = useNormalizedCurrentRefinements(attribute);
  const basket = useAtomValue(basketDataAtom);
  const contracts = useAtomValue(contractsAtom);
  const orders = useBasketOrders({ basketPackagingQuantities: basket?.packaging_quantities || [] });

  const [companiesMap, setCompaniesMap] = useState<Map<string, DisplayCompany>>(new Map());

  useEffect(() => {
    const map = new Map<string, DisplayCompany>();

    contracts.forEach((contract) => {
      map.set(
        contract.company.tradeName,
        {
          name: contract.company.tradeName,
          contractId: contract.reference_id!,
          minimumOrderValue: contract.minimum_order_value || 0,
          photo: FileUtils.BuildCompanyImageUrl({ photo: contract.company.photos?.[0]?.photo, type: 'SUPPLIER', size: { height: 128, width: 128 } }),
        },
      );
    });

    setCompaniesMap(map);
  }, []);

  return (
    <>
      {currentRefinements.map((refinement) => {
        const company = companiesMap.get(refinement as string);

        if (!company) {
          return undefined;
        }

        return (
          <FilterTag
            key={refinement}
            chipProps={{
              avatar: <Box component="img" src={company.photo} sx={{ borderRadius: '50%', height: '32px', '&&': { marginRight: 0 } }} />,
              label: (
                <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                  <span>{company.name}</span>
                  <MinimumOrderValueChip
                    minimumOrderValue={company.minimumOrderValue}
                    totalPrice={orders[company.contractId]?.totalPrice || 0}
                    enableProgress
                    sx={{
                      ml: 1,
                    }}
                  />
                </Box>
              ),
              onDelete: () => refine(company.name),
            }}
            variant="outlined"
            size="medium"
          />
        );
      })}
    </>
  );
}
