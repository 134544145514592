import { Box, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../common/components/LoadingDots';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import { BasketPackagingQuantity } from './state/basket';
import { isBasketLoadingAtom } from './state/state';
import MinimumOrderValueChip from '../common/components/MinimumOrderValueChip';

export interface BasketOrderPriceProps {
  packagingQuantities: BasketPackagingQuantity[];
}

export function BasketOrderPrice({ packagingQuantities }: BasketOrderPriceProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const currencyFormat = useCurrencyFormat();
  const isBasketLoading = useAtomValue(isBasketLoadingAtom);

  const totalPrice = packagingQuantities.reduce(
    (total, { quantity, packaging }) => total + quantity * packaging.price,
    0,
  );
  const minimumOrderValue = packagingQuantities[0].contract.minimum_order_value!;
  const totalPriceDisplay = (
    <LoadingDots loading={isBasketLoading}>
      {currencyFormat.format(totalPrice)}
    </LoadingDots>
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body1" sx={{ fontWeight: 500, display: 'flex', mr: 1 }}>
        {t('common:total')}&nbsp;:&nbsp;{totalPriceDisplay}
      </Typography>
      <MinimumOrderValueChip minimumOrderValue={minimumOrderValue} totalPrice={totalPrice} enableProgress />
    </Box>
  );
}
