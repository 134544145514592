import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Box, Button, Drawer, FormHelperText, IconButton, LinearProgress, Toolbar, Typography } from '@mui/material';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import InvoiceCard from './InvoiceCard';
import { useProcessInvoice } from './useProcessInvoice';
import ConfirmDialog from '../../../common/components/ConfirmDialog';
import useOpenable from '../../../hooks/useOpenable';
import { useNavBlocker } from '../../../hooks/useNavBlocker';

export interface UploadInvoiceDrawerProps {
  orderReferenceId: string;
  open: boolean;
  onClose: () => void;
}
const errorCodeWordingMap = new Map<ErrorCode, string>([
  [ErrorCode.TooManyFiles, 'only_one_file_is_supported'],
  [ErrorCode.FileTooLarge, 'file_too_large'],
]);

export default function UploadInvoiceDrawer({ orderReferenceId, open, onClose }: UploadInvoiceDrawerProps): React.JSX.Element {
  const { t } = useTranslation(['order', 'common', 'error']);
  const { processInvoice, processedInvoice, loading, reset } = useProcessInvoice({ orderReferenceId });
  // navBlocker is called to block page refresh when drawer is open and invoice was parsed
  useNavBlocker(!!processedInvoice);
  const { isOpen: isDialogOpen, open: openDialog, close: closeDialog } = useOpenable();
  const [fileErrorMessage, setFileErrorMessage] = useState<string>();

  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]): Promise<void> => {
    if (rejectedFiles.length) {
      const errorCode = rejectedFiles[0].errors[0].code as ErrorCode;
      setFileErrorMessage(t(`error:${errorCodeWordingMap.get(errorCode)}`, { size: '20 MB' }));
    } else {
      setFileErrorMessage(undefined);
      processInvoice(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 20971520, // 20MB in binary
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
    },
  });

  const closeDrawer = (): void => {
    reset();
    onClose();
  };

  const handleCloseDrawer = (): void => {
    if (processedInvoice) {
      openDialog();
    } else {
      closeDrawer();
    }
  };

  const handleDialog = (isConfirmed: boolean): void => {
    if (isConfirmed) {
      closeDrawer();
    }
    closeDialog();
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
          boxShadow: 'none',
          height: 'calc(100vh - 64px)',
          borderTopLeftRadius: 32,
          borderTopRightRadius: 32,
          overflowY: 'auto',
        },
      }}
    >
      <ConfirmDialog
        title={t('order:abort_invoice_add_title')}
        message={t('order:abort_invoice_add_description')}
        confirmLabel={t('common:confirm')}
        cancelLabel={t('common:cancel')}
        open={isDialogOpen}
        onClose={handleDialog}
      />
      <Toolbar
        variant="dense"
        sx={{
          backgroundColor: 'background.paper',
          py: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          minHeight: 40,
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        <Typography variant="h2" sx={{ lineHeight: '40px' }}>
          {t('order:attach_an_invoice')}
        </Typography>
        <IconButton
          onClick={handleCloseDrawer}
          sx={{
            backgroundColor: 'greys.primary',
            color: 'text.primary',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Box sx={{ pt: 3, display: 'flex', mx: 'auto' }}>
        {!loading && !processedInvoice && (
          <Box
            {...getRootProps()}
            sx={{
              mx: 3,
              border: '1px dashed',
              borderColor: 'divider',
              borderRadius: 6,
              backgroundColor: 'background.paper',
              height: 480,
              width: {
                xs: '100%',
                sm: 600,
              },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input {...getInputProps()} />
            <Box
              sx={{
                mx: 3,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  gap: 1,
                }}
              >
                <Typography variant="h6" sx={{ lineHeight: 1 }}>
                  {t('order:drag_and_drop_the_invoice_or_click_to_add_it')}
                </Typography>
                <Typography variant="subtitle2">{t('order:invoice_format_description')}</Typography>
              </Box>
              <Button variant="contained" sx={{ width: 'fit-content' }}>
                {t('order:browse_my_files')}
              </Button>
              {fileErrorMessage && (<FormHelperText error>{fileErrorMessage}</FormHelperText>)}
            </Box>
          </Box>
        )}
        {loading && (
          <Box
            sx={{
              mx: 3,
              border: '1px dashed',
              borderColor: 'divider',
              borderRadius: 6,
              backgroundColor: 'background.paper',
              height: 480,
              width: {
                xs: '100%',
                sm: 600,
              },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6">{t('common:please_wait')}...</Typography>
            <LinearProgress sx={{ width: '80%' }} />
          </Box>
        )}
        {processedInvoice && (
          <InvoiceCard
            closeDrawer={closeDrawer}
            invoiceData={processedInvoice}
            onClose={handleCloseDrawer}
          />
        )}
      </Box>
    </Drawer>
  );
}
