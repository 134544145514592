import { Box, Button, Divider, Drawer, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Close, Tune } from '@mui/icons-material';
import { useInstantSearch } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import FullTextSearch from '../../common/components/FullTextSearch';
import useOpenable from '../../hooks/useOpenable';
import GeoLocationFilter from '../../search/filters/GeoLocationFilter';
import ContractStatusFilter from '../../search/filters/ContractStatusFilter';
import GeoLocationTags from '../../search/filters/GeoLocationTags';
import ContractStatusFilterTag from '../../search/filters/ContractStatusFilterTag';
import QueryTag from '../../search/filters/QueryTag';
import ClearFiltersButton from '../../search/filters/ClearFiltersButton';
import ProductLabelsFilter from '../filters/ProductLabelsFilter';
import ProductLabelsTags from '../filters/ProductLabelsTags';
import { SearchMode } from '../../hooks/useDiscoveryDefaultFilters';

export interface DiscoveryFiltersProps {
  contractFilterAttribute: string;
  navigationAttribute: string;
  fullTextSearchPlaceholder: string;
  clearAttributes: string[];
  hideLabelsFilter?: boolean;
  searchMode: SearchMode;
  index: string;
}

export default function DiscoveryFilters({ contractFilterAttribute, navigationAttribute, fullTextSearchPlaceholder, clearAttributes, hideLabelsFilter, searchMode, index }: DiscoveryFiltersProps): React.JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation(['common', 'discovery', 'search']);
  const { indexUiState } = useInstantSearch();
  const { isOpen, open, close } = useOpenable();

  return (
    <Box sx={{ position: 'sticky', top: 72, backgroundColor: 'white', zIndex: 100 }}>
      <Box
        sx={{
          py: 2,
          display: 'flex',
          gap: { xs: 1, lg: 2 },
        }}
      >
        <FullTextSearch
          inputProps={{
            placeholder: fullTextSearchPlaceholder,
            sx: {
              px: 2,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'divider',
              },
              borderRadius: 4,
              flex: {
                xs: 1,
                lg: 'unset',
              },
              minWidth: {
                lg: 320,
                xl: 599,
              },
            },
          }}
          value={indexUiState.query}
        />
        {
          isMobile
            ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={open}
                startIcon={<Tune />}
                sx={{
                  borderRadius: 4,
                  borderColor: 'divider',
                }}
              >
                {t('common:filters')}
              </Button>
            ) : (
              <>
                <GeoLocationFilter variant="outlined" index={index} />
                {!hideLabelsFilter && <ProductLabelsFilter />}
                <ContractStatusFilter attribute={contractFilterAttribute} variant="outlined" searchMode={searchMode} />
              </>
            )
        }
      </Box>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        <QueryTag variant="filled" size="small" />
        <GeoLocationTags variant="filled" size="small" />
        {!hideLabelsFilter && <ProductLabelsTags />}
        <ContractStatusFilterTag attribute={contractFilterAttribute} variant="filled" size="small" searchMode={searchMode} />
        <ClearFiltersButton
          sx={{ height: 36, mb: 2 }}
          attributesToCheck={clearAttributes}
          excludedAttributes={[navigationAttribute]}
          searchMode={searchMode}
        />
      </Box>
      <Drawer
        keepMounted
        open={isOpen}
        onClose={close}
        anchor="bottom"
        PaperProps={{
          sx: {
            height: 'calc(100% - 88px)',
            px: 3,
            pt: 2,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            gap: 2,
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="title" sx={{ lineHeight: '40px' }}>
            {t('common:filters')}
          </Typography>
          <IconButton
            onClick={close}
            sx={{
              backgroundColor: 'greys.primary',
              color: 'text.primary',
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <GeoLocationFilter variant="outlined" mode="ACCORDION" index={index} />
          {!hideLabelsFilter && <ProductLabelsFilter mode="ACCORDION" />}
          <ContractStatusFilter attribute={contractFilterAttribute} variant="outlined" mode="ACCORDION" searchMode={searchMode} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mx: -3,
            py: 2,
            backgroundColor: 'white',
            position: 'sticky',
            bottom: 0,
            zindex: 10,
          }}
        >
          <Divider />
          <Button variant="contained" onClick={close} sx={{ mx: 3 }}>
            {t('search:validate_changes')}
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}
