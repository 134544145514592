import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrdersDeliveryDates } from '../../basket/state/state';
import CustomDateTimePicker from '../../common/components/CustomDateTimePicker';
import SizedImage from '../../common/components/SizedImage';
import { BasketOrdersDict } from '../../hooks/useBasketOrders';

interface OrderSuggestionsDeliveryDatesDialogProps {
  orders: BasketOrdersDict;
  deliveryDates: OrdersDeliveryDates;
  open: boolean;
  onClose: (deliveryDates?: OrdersDeliveryDates) => void;
}

export default function OrderSuggestionsDeliveryDatesDialog({ orders, deliveryDates, open, onClose }: OrderSuggestionsDeliveryDatesDialogProps): React.JSX.Element {
  const { t } = useTranslation(['basket']);
  const [dialogDeliverayDates, setDialogDeliveryDates] = useState(deliveryDates);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const ordersCount = useMemo((): number => Object.keys(orders).length, [orders]);

  const handleDateChange = useCallback((contractId: string, date: Date | null): void => {
    if (!date && dialogDeliverayDates.has(contractId)) {
      dialogDeliverayDates.delete(contractId);
    } else {
      const newOrdersDeliveryDates = new Map(dialogDeliverayDates);

      setDialogDeliveryDates(newOrdersDeliveryDates.set(contractId, date));
    }
  }, [dialogDeliverayDates]);

  const handleCancel = useCallback((): void => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback((): void => {
    if (dialogDeliverayDates.size !== Object.keys(orders).length) {
      setShowErrorMessage(true);
    } else {
      onClose(dialogDeliverayDates);
    }
  }, [onClose, dialogDeliverayDates, orders]);

  useEffect(() => {
    setDialogDeliveryDates(new Map(deliveryDates));
  }, [deliveryDates]);

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      sx={{
        mx: {
          xs: 2,
          sm: 0,
        },
        '& .MuiPaper-root': {
          minWidth: {
            xs: '100%',
            sm: 600,
            md: 724,
            lg: 800,
          },
        },
      }}
    >
      <DialogTitle sx={{ mt: 0, pt: 3 }}>
        {t('basket:validate_suggestions', { count: ordersCount })}
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Divider sx={{ px: 2, mb: 2 }} />
        <Typography variant="body2" sx={{ mb: 3 }}>
          {t('basket:validate_suggestions_description', { count: ordersCount })}
        </Typography>
        <Grid container sx={{ mt: 1, justifyContent: 'center' }}>
          {Object.keys(orders).sort((a, b) => orders[a].name.localeCompare(orders[b].name)).map((contractId) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={contractId}
              sx={{
                py: 2,
                px: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SizedImage
                  src={orders[contractId].photoUrl}
                  alt={t('common:company')}
                  height={32}
                  width={32}
                  borderRadius={2}
                />
                <Typography variant="body1" sx={{ fontWeight: 600, ml: 1 }}>
                  {orders[contractId].name}
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'start' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {t('basket:add_delivery_date')}
                </Typography>
                <CustomDateTimePicker
                  date={dialogDeliverayDates.get(contractId) || null}
                  handleDateChange={(date) => handleDateChange(contractId, date)}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
        {showErrorMessage && (
          <Typography variant="body1" color="error" sx={{ fontWeight: 600, mt: 1 }}>
            {t('basket:delivery_dates_should_be_defined', { count: ordersCount })}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="inherit" variant="text">
          {t('common:cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('common:validate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
