import { Box, Typography } from '@mui/material';
import KheopsAvatar from '../common/components/KheopsAvatar';
import { Member } from '../chats/state';

export interface CompanyContactProps {
  contacts: Member[];
}

export default function CompanyContactList({ contacts }: CompanyContactProps): React.JSX.Element {
  return (
    <>
      {contacts.map((contact) => (
        <Box sx={{ display: 'flex', alignItems: 'center', py: 1, px: 2 }} key={contact.id}>
          <KheopsAvatar userAvatars={contact} size={48} />
          <Typography variant="body1" sx={{ ml: 1 }}>
            {contact.first_name} {contact.last_name}
          </Typography>
        </Box>
      ))}
    </>
  );
}
