"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAddressComponents = formatAddressComponents;
exports.computeDistanceInKm = computeDistanceInKm;
const googleMapsAddressComponentsMap = new Map([
    ['postal_code', 'postalCode'],
    ['locality', 'locality'],
    ['route', 'route'],
    ['administrative_area_level_1', 'administrativeAreaLevel1'],
    ['administrative_area_level_2', 'administrativeAreaLevel2'],
    ['administrative_area_level_3', 'administrativeAreaLevel3'],
    ['administrative_area_level_4', 'administrativeAreaLevel4'],
    ['administrative_area_level_5', 'administrativeAreaLevel5'],
    ['street_number', 'streetNumber'],
    ['country', 'countryCode'],
    ['archipelago', 'archipelago'],
    ['colloquial_area', 'colloquialArea'],
    ['continent', 'continent'],
    ['intersection', 'intersection'],
    ['neighborhood', 'neighborhood'],
    ['post_box', 'postBox'],
    ['postal_town', 'postalTown'],
    ['postal_code_prefix', 'postalCodePrefix'],
    ['postal_code_suffix', 'postalCodeSuffix'],
    ['premise', 'premise'],
    ['subpremise', 'subpremise'],
    ['town_square', 'townSquare'],
    ['street_address', 'streetAddress'],
    ['sublocality', 'sublocality'],
    ['sublocality_level_1', 'sublocalityLevel1'],
    ['sublocality_level_2', 'sublocalityLevel2'],
    ['sublocality_level_3', 'sublocalityLevel3'],
    ['sublocality_level_4', 'sublocalityLevel4'],
    ['sublocality_level_5', 'sublocalityLevel5'],
]);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
/**
 * This function accepts the array address_components returned by Google Maps API and parse it to database compatible object
 */
function formatAddressComponents(addressComponents) {
    const formattedAddressComponent = {};
    addressComponents.forEach((component) => {
        if (!googleMapsAddressComponentsMap.get(component.types[0])) {
            return;
        }
        if (component.types[0] === 'country') {
            formattedAddressComponent[googleMapsAddressComponentsMap.get(component.types[0])] = component.short_name;
        }
        else {
            formattedAddressComponent[googleMapsAddressComponentsMap.get(component.types[0])] = component.long_name;
        }
    });
    return formattedAddressComponent;
}
// Function based on Haversine formula. Copied from: https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
function computeDistanceInKm(lat1, lon1, lat2, lon2) {
    // function to convert angle's value from degrees to radians.
    function deg2rad(deg) {
        return deg * (Math.PI / 180);
    }
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
            * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}
