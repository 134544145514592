import { Box, Typography, chipClasses } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useTranslation } from 'react-i18next';
import MinimumOrderValueChip from '../../common/components/MinimumOrderValueChip';
import PriceModeChip from '../../common/components/PriceModeChip';
import VersionedValue from '../../common/components/VersionedValue';
import ContractsUtils from '../../common/utils/contracts.utils';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { currentContextAtom } from '../../state';
import { orderAtom, viewPackagingQuantitiesAtom } from '../state/state';
import CommissionInfo from '../../common/components/CommissionInfo';

export default function OrderChipsInfo(): React.JSX.Element {
  const { t } = useTranslation(['contracts', 'common', 'price']);
  const { realm } = useAtomValue(currentContextAtom);
  const order = useAtomValue(orderAtom);
  const packagingQuantities = useAtomValue(viewPackagingQuantitiesAtom);
  const currencyFormat = useCurrencyFormat();

  const refreshedTotal = packagingQuantities.reduce((acc, packagingQt) => (
    acc + (packagingQt.price)
  ), 0);
  const currentTotal = order.total_price_excl_tax!;
  const previousTotal = order.previousOrders[0]?.total_price_excl_tax;

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: {
          xs: 'wrap',
          sm: 'nowrap',
        },
        gap: 2,
      }}
    >
      <Box>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          {t('price:price_without_tax_total')}
        </Typography>
        <Box sx={{ mt: 0.5 }}>
          <VersionedValue
            currentValue={refreshedTotal}
            previousValue={previousTotal || currentTotal}
            color="primary.main"
            valueFormatter={currencyFormat.format}
            shouldHighlightDifference
            fontWeight={800}
            variant="h5"
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          {t('common:minimum_order_value')}
        </Typography>
        <MinimumOrderValueChip
          sx={{
            mt: 0.5,
            [`& .${chipClasses.label}`]: {
              fontSize: '1rem',
            },
            [`& .${chipClasses.icon}`]: {
              height: 18,
              width: 18,
            },
          }}
          minimumOrderValue={order.contract.minimum_order_value as number}
          disableTooltip
        />
      </Box>
      {ContractsUtils.displayPriceMode(realm, order.contract) && (
        <Box>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            {t('contracts:discount')}
          </Typography>
          <PriceModeChip contract={order.contract} chipProps={{ size: 'small', sx: { mt: 0.5, fontSize: 16 } }} />
        </Box>
      )}
      {realm === Business_Profile_Enum.Supplier && (
        <Box>
          <Typography
            sx={{
              mb: 0.5,
              color: 'text.secondary',
            }}
            variant="subtitle1"
          >
            {t('contracts:commission')}
          </Typography>
          <CommissionInfo
            short
            commission={order.contract.supplier_commission_rate as number}
          />
        </Box>
      )}
    </Box>
  );
}
