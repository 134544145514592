import { SxProps, Tabs, TabsProps, Theme, tabClasses } from '@mui/material';
import React, { FunctionComponent, SVGProps, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductIcon from '../assets/icons/product.svg?react';
import DeliveryIcon from '../assets/icons/delivery.svg?react';
import DiscountIcon from '../assets/icons/discount.svg?react';
import ProfileIcon from '../assets/icons/profile.svg?react';
import { RoutePaths } from '../routes/AppRoutes';
import KheopsTab from '../common/components/KheopsTab';
import useCurrentTabs from '../topMenu/hooks/useCurrentTab';

export interface UserCompanyNavigationProps {
  tabsProps?: TabsProps;
}

interface SubNavigationTabData {
  to: string;
  label: string;
}

export interface NavigationTabData extends SubNavigationTabData {
  category: NavigationCategories;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  subTabs: SubNavigationTabData[];
  sx?: SxProps<Theme>;
}

enum NavigationCategories {
  PROFILE = 'PROFILE',
  PRODUCTS = 'PRODUCTS',
  PRICES = 'PRICES',
  DELIVERY = 'DELIVERY',
}

export const userCompanyNavMap: NavigationTabData[] = [
  {
    category: NavigationCategories.PROFILE,
    to: RoutePaths.COMPANY_PROFILE,
    icon: ProfileIcon,
    label: 'settings:my_company',
    subTabs: [],
  },
  {
    category: NavigationCategories.PRODUCTS,
    to: RoutePaths.COMPANY_PRODUCTS,
    icon: ProductIcon,
    label: 'common:product_other',
    subTabs: [],
  },
  {
    category: NavigationCategories.PRICES,
    to: RoutePaths.COMPANY_PRODUCTS_PRICES,
    icon: DiscountIcon,
    label: 'price:prices',
    sx: {
      display: {
        xs: 'none',
        lg: 'inline-flex',
      },
    },
    subTabs: [
      {
        to: RoutePaths.COMPANY_PRODUCTS_PRICES,
        label: 'contracts:default_price',
      },
      {
        to: RoutePaths.COMPANY_GLOBAL_DISCOUNTS,
        label: 'price:global_discounts',
      },
      {
        to: RoutePaths.COMPANY_CATALOGS,
        label: 'price:prices_catalog',
      },
    ],
  },
  {
    category: NavigationCategories.DELIVERY,
    to: RoutePaths.COMPANY_DELIVERY_ZONES,
    icon: DeliveryIcon,
    label: 'common:delivery',
    subTabs: [],
  },
];

export default function UserCompanyNavigation({ tabsProps }: UserCompanyNavigationProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'price', 'contracts']);
  const [currentCategory, setCurrentCategory] = useState<NavigationCategories>();
  const currentTabValue = useCurrentTabs([
    RoutePaths.COMPANY_PROFILE,
    RoutePaths.COMPANY_PRODUCTS,
    RoutePaths.COMPANY_DELIVERY_ZONES,
    RoutePaths.COMPANY_CATALOGS,
    RoutePaths.COMPANY_PRODUCTS_PRICES,
    RoutePaths.COMPANY_GLOBAL_DISCOUNTS,
  ]);

  useEffect(() => {
    const currentNavData = userCompanyNavMap.find((navData) => {
      if (navData.subTabs.length) {
        return !!navData.subTabs.find((subTab) => (subTab.to === currentTabValue));
      }
      return navData.to === currentTabValue;
    });

    if (currentNavData) {
      setCurrentCategory(currentNavData.category);
    }
  }, [currentTabValue]);

  const mainTabs = useMemo(() => {
    return userCompanyNavMap.flatMap((item) => {
      const shouldDisplayNestedSubTabs = item.subTabs.length && currentCategory === item.category;
      const mainTab = (
        <KheopsTab
          key={item.to}
          to={item.to}
          component={Link}
          value={item.category}
          icon={<item.icon height={24} width={24} />}
          iconPosition="start"
          label={t(item.label)}
          sx={
            shouldDisplayNestedSubTabs
              ? {
                [`&.${tabClasses.selected}`]: {
                  backgroundColor: 'common.white',
                },
                ...item.sx,
              }
              : item.sx
          }
        />
      );

      const nestedSubTabs = shouldDisplayNestedSubTabs
        ? (
          <Tabs
            key={`${item.to}_nested_tabs`}
            textColor="secondary"
            value={currentTabValue}
            {...tabsProps}
            sx={{
              borderLeft: '1px solid',
              borderColor: 'divider',
              px: 2,
              mx: 2,
              mt: -1,
              ...tabsProps?.sx,
            }}
          >
            {item.subTabs.map((subTab) => (
              <KheopsTab
                key={subTab.to}
                value={subTab.to}
                component={Link}
                to={subTab.to}
                iconPosition="start"
                label={t(subTab.label)}
                sx={{
                  color: 'text.secondary',
                  height: 42,
                  minHeight: 42,
                  [`&.${tabClasses.selected}`]: {
                    fontWeight: 600,
                  },
                }}
              />
            ))}
          </Tabs>
        )
        : undefined;

      return [mainTab, nestedSubTabs];
    });
  }, [currentTabValue, currentCategory]);

  return (
    <Tabs
      textColor="secondary"
      value={currentCategory || false}
      {...tabsProps}
    >
      {mainTabs}
    </Tabs>
  );
}
