import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { FunctionComponent, SVGProps } from 'react';
import ProductIcon from '../../assets/icons/product.svg?react';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import DeliveryIcon from '../../assets/icons/delivery.svg?react';
import ProfileIcon from '../../assets/icons/profile.svg?react';
import { RoutePaths } from '../../routes/AppRoutes';

export type NavigationAction = 'logout';

export interface NavigationItem {
  icon: FunctionComponent<SVGProps<SVGSVGElement>> | OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  labelKey: string;
  // to and action should not be set at the same time, to is used in that case
  to?: string;
  action?: NavigationAction;
  desktopOnly?: boolean;
}

export const SupplierNavigation: NavigationItem[] = [
  {
    icon: ProfileIcon,
    labelKey: 'settings:my_company',
    to: RoutePaths.COMPANY_PROFILE,
  },
  {
    icon: ProductIcon,
    labelKey: 'common:my_products',
    to: RoutePaths.COMPANY_PRODUCTS,
  },
  {
    icon: DiscountIcon,
    labelKey: 'common:my_prices',
    to: RoutePaths.COMPANY_PRODUCTS_PRICES,
    desktopOnly: true,
  },
  {
    icon: DeliveryIcon,
    labelKey: 'common:my_delivery_zones',
    to: RoutePaths.COMPANY_DELIVERY_ZONES,
  },
];

export const CommonNavigation: NavigationItem[] = [
  {
    icon: PersonOutlinedIcon,
    labelKey: 'common:my_personal_information',
    to: RoutePaths.USER,
  },
  {
    icon: LogoutOutlinedIcon,
    labelKey: 'common:sign_out',
    action: 'logout',
  },
];

export const MobileNavigation: NavigationItem[] = [
  {
    icon: PersonOutlinedIcon,
    labelKey: 'common:my_personal_information',
    to: RoutePaths.USER,
  },
  {
    icon: ChangeCircleOutlinedIcon,
    labelKey: 'common:switch_company',
    to: RoutePaths.NAVIGATION_CONTEXTS,
  },
];
