import { Box, Button, Card, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useMemo } from 'react';
import SizedImage from '../common/components/SizedImage';
import FileUtils from '../common/utils/file.utils';
import { currentContextAtom } from '../state';
import ExpandableText from '../common/components/ExpandableText';
import PhoneNumberDisplay from '../common/components/PhoneNumberDisplay';
import { RoutePaths } from '../routes/AppRoutes';

export default function MyCompanyInfoCard(): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { companyName, companyPhotos, companyAddress, contactEmail, contactPhoneNumber, description, realm } = useAtomValue(currentContextAtom);

  const companyDetails = useMemo(() => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt: 1,
      }}
    >
      {description && description !== '<p></p>' && (<ExpandableText text={description} isDangerousText lineClampThreshold={3} />)}
      <Typography>✉️ {contactEmail}</Typography>
      {!!contactPhoneNumber && (<PhoneNumberDisplay typographyVariant="body1" phoneNumber={contactPhoneNumber} shouldDisplayFlag={false} phoneTypeEmoji="☎️" />)}
    </Box>
  ), []);

  return (
    <Card
      sx={{
        p: 3,
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <SizedImage
            src={
                FileUtils.BuildCompanyImageUrl({
                  photo: companyPhotos ? companyPhotos[0]?.photo : undefined,
                  type: realm,
                  size: { height: 332, width: 332 },
                })
              }
            alt={t('common:company')}
            height={{ xs: 112, lg: 256 }}
            width={{ xs: 112, lg: 256 }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 700 }}>{companyName}</Typography>
            <Typography variant="body2">
              📍 {`${companyAddress?.locality}, ${companyAddress?.countryCode} (${companyAddress?.postalCode})`}
            </Typography>
            {!isDownLg && companyDetails}
          </Box>
        </Box>
        {isDownLg && companyDetails}
        <Button
          color="primary"
          variant="outlined"
          component={Link}
          to={RoutePaths.COMPANY_PROFILE}
          startIcon={<EditIcon />}
          sx={{
            height: 'fit-content',
            flex: 'none',
          }}
        >
          {t('settings:update_my_profile')}
        </Button>
      </Box>

    </Card>
  );
}
