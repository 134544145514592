/* eslint-disable react/jsx-no-useless-fragment */
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Button, Container, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import FileUtils from '../common/utils/file.utils';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import useLongDateFormat from '../hooks/useLongDateFormat';
import { RoutePaths } from '../routes/AppRoutes';
import { lastOrdersOrderedAtom } from './state/state';
import { OrderedCompany } from './state/basket';
import SizedImage from '../common/components/SizedImage';
import OrderExportButton from '../orders/tracking-page/OrderExportButton';
import BrandBox from '../common/components/BrandBox';

const wordingsOrderConfirmationPage = {
  classicOrder: {
    title: 'basket:thanks_for_order',
    description: 'basket:thanks_for_order_description',
    listHeader: 'basket:your_orders',
    orderButtonLink: 'basket:see_your_order',
  },
  suggestionOrder: {
    title: 'basket:thanks_for_your_order_suggestion',
    description: 'basket:thanks_for_your_order_suggestion_description',
    listHeader: 'basket:your_order_suggestions',
    orderButtonLink: 'basket:see_your_order_suggestion',
  },
};

export default function OrdersConfirmationPage(): React.JSX.Element {
  const { t } = useTranslation(['basket', 'common', 'order']);
  const navigate = useNavigate();
  const currencyFormat = useCurrencyFormat();
  const orders = useAtomValue(lastOrdersOrderedAtom);
  const dateFormat = useLongDateFormat();

  useEffect(() => {
    if (!orders.length) {
      navigate(RoutePaths.ROOT);
    }
  }, []);

  const areOrdersSuggested = orders[0].isOrderSuggested;
  const wordings = areOrdersSuggested ? wordingsOrderConfirmationPage.suggestionOrder : wordingsOrderConfirmationPage.classicOrder;
  const photoSize = {
    height: 74,
    width: 74,
  };

  const orderList = orders.map((order) => {
    let companyToDisplay: OrderedCompany;
    let placeholderType: Business_Profile_Enum;

    if (areOrdersSuggested) {
      companyToDisplay = order.contract.buying_company;
      placeholderType = Business_Profile_Enum.Buyer;
    } else {
      companyToDisplay = order.contract.supplying_company;
      placeholderType = Business_Profile_Enum.Supplier;
    }

    return (
      <Box
        key={companyToDisplay.tradeName}
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 4,
          mb: 2,
          p: 2,
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: {
            xs: 'start',
            sm: 'center',
          },
        }}
      >
        <Box sx={{ display: 'flex', flex: 1 }}>
          <SizedImage
            src={
              FileUtils.BuildCompanyImageUrl({
                photo: companyToDisplay.photos[0]?.photo,
                size: photoSize,
                type: placeholderType,
              })
            }
            alt={t('common:company')}
            height={photoSize.height}
            width={photoSize.width}
            overlay={
              companyToDisplay.brand && <BrandBox brand={companyToDisplay.brand} />
            }
            sx={{
              '& .Kheops-brand': {
                width: 32,
                height: 32,
                borderRadius: 3,
                position: 'absolute',
                bottom: 8,
                right: 8,
              },
              '& .Kheops-brandLogo': {
                width: 18,
              },
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {companyToDisplay.tradeName}
            </Typography>
            <Typography variant="body2" sx={{ marginY: 0.5 }}>
              {t('basket:item', { count: order.packagingCount, value: order.packagingCount })}
            </Typography>
            {order.deliveryDate && (<Typography variant="body2">{`${t('order:proposed_delivery')} : ${format(new Date(order.deliveryDate), dateFormat)}`}</Typography>)}
            <Typography variant="h6" color="primary" sx={{ fontWeight: 700 }}>
              {currencyFormat.format(order.totalPrice)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: {
              sm: 'inherit',
              xs: '100%',
            },
            flexDirection: {
              xs: 'row',
              sm: 'column',
            },
            justifyContent: 'space-between',
            gap: 1,
            mt: {
              xs: 1,
              sm: 0,
            },
          }}
        >

          <Button
            component={Link}
            sx={{
              width: {
                sm: 'inherit',
                xs: '50%',
              },
            }}
            to={generatePath(RoutePaths.ORDER_TRACKING, { orderReferenceId: order.referenceId })}
            variant="outlined"
          >
            {t(wordings.orderButtonLink)}
          </Button>
          <OrderExportButton
            orderReferenceId={order.referenceId}
            orderFriendlyId={order.friendlyId}
            orderId={order.id}
            sx={{
              py: 0.5,
              width: {
                sm: 'inherit',
                xs: '50%',
              },
            }}
          >
            {t('common:export')}
          </OrderExportButton>
        </Box>

      </Box>
    );
  });

  return (
    <Container
      sx={{
        width: {
          sm: 600,
          lg: 800,
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 4,
          mt: 3,
          py: 3,
          px: 2,
          textAlign: 'center',
        }}
      >
        <Typography variant="h2" sx={{ margin: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TaskAltIcon color="primary" sx={{ width: 28, height: 28, marginRight: 1 }} />
          {t(wordings.title, { count: orders.length })}
        </Typography>
        <Typography variant="body1">
          {t(wordings.description, { count: orders.length })}
        </Typography>
        <Button
          variant="contained"
          component={Link}
          color="primary"
          sx={{
            textAlign: 'center',
            mt: 4,
          }}
          to={RoutePaths.ORDER_LIST}
        >
          {t('basket:see_orders_list')}
        </Button>
      </Box>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {t(wordings.listHeader)}
      </Typography>
      <Box sx={{ mb: 2, mt: 1 }}>
        {orderList}
      </Box>
    </Container>
  );
}
