import { AlertProps, SnackbarProps } from '@mui/material';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { AuthAction } from '../../hooks/useAuth/authActions';
import { AuthActionType } from '../../hooks/useAuth/authActionsTypes';
import { PREFERRED_PRODUCT_FAMILY } from './local-storage-keys';

interface CommonSnackbarProps {
  label: string;
  snackbarProps: SnackbarProps;
  alertProps?: AlertProps;
}

export interface AuthState {
  hasCheckedRefreshToken: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  errors?: string[];
}

export const initialState: AuthState = {
  hasCheckedRefreshToken: false,
  isLoading: true,
  isAuthenticated: false,
};

export const authStateAtom = atom<AuthState>(initialState);
export const restoreAuthTokenAtom = atom(null, (_get, set) => {
  set(authStateAtom, (previousState) => ({
    ...previousState,
    hasCheckedRefreshToken: true,
    isAuthenticated: true,
    isLoading: false,
    errors: undefined,
  }));
});
export const loginAtom = atom(null, (_get, set) => {
  set(authStateAtom, (previousState) => ({
    ...previousState,
    isLoading: true,
    errors: undefined,
  }));
});
export const loginErrorAtom = atom<null, string[], void>(null, (_get, set, ...errors) => {
  set(authStateAtom, (previousState) => ({
    ...previousState,
    errors,
    isLoading: false,
  }));
});
export const loginSuccessAtom = atom<null, string[], void>(null, (_get, set) => {
  set(authStateAtom, (previousState) => ({
    ...previousState,
    isAuthenticated: true,
    isLoading: false,
    errors: undefined,
  }));
});
export const logoutAtom = atom<null, string[], void>(null, (_get, set) => {
  set(authStateAtom, (previousState) => ({
    ...previousState,
    hasCheckedRefreshToken: true,
    isLoading: false,
    isAuthenticated: false,
  }));
});
export const dispatchAuthStateAtom = atom<AuthState, AuthAction[], void>(initialState, (_get, set, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case AuthActionType.RESTORE_TOKEN:
      set(restoreAuthTokenAtom);
      break;
    case AuthActionType.LOGIN:
      set(loginAtom);
      break;
    case AuthActionType.LOGIN_ERROR:
      set(loginErrorAtom, ...(action.errors || []));
      break;
    case AuthActionType.LOGIN_SUCCESS:
      set(loginSuccessAtom);
      break;
    case AuthActionType.LOGOUT:
      set(logoutAtom);
      break;
  }
});

export const preferredProductFamilyAtom = atomWithStorage<Product_Sub_Family_Name_Enum[]>(PREFERRED_PRODUCT_FAMILY, []);
export const commonSnackbarPropsAtom = atom<CommonSnackbarProps | undefined>(undefined);
