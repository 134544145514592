import { Box, Button, Chip, IconButton, SxProps } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import PercentIcon from '../../assets/icons/percent.svg?react';
import { round } from '../utils/common.utils';

export interface CommissionInfoProps {
  commission: number;
  size?: 'small' | 'medium';
  short?: boolean;
  sx?: SxProps;
}

export default function CommissionInfo({ commission, size, short, sx }: CommissionInfoProps): React.JSX.Element {
  const { t } = useTranslation(['common']);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        ...sx,
      }}
    >
      <Chip
        size={size || 'medium'}
        icon={<PercentIcon />}
        label={` ${round(commission * 100)}%`}
        sx={{
          backgroundColor: 'greys.primary',
          fontWeight: 400,
          height: 24,
        }}
      />
      {short
        ? (
          <IconButton onClick={() => Intercom('showArticle', 9425028)} sx={{ p: 0 }}>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        )
        : (
          <Button
            variant="text"
            color="secondary"
            disableRipple
            onClick={() => Intercom('showArticle', 9425028)}
            size={size}
            sx={{
              p: 0,
              textDecoration: 'underline',
              '&:hover': {
                backgroundColor: 'unset',
                textDecoration: 'underline',
              },
            }}
          >
            {t('common:kheops_commission')}
          </Button>
        )}
    </Box>
  );
}
