import { Atom, atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { LAST_ORDERED_ORDERS } from '../../common/state/local-storage-keys';
import { Basket, OrderedOrder } from './basket';

export interface BasketPackagingQuantity {
  id?: string;
  packagingId: string;
  quantity: number;
  contractId: string;
}

export type PackagingQuantities = Map<string, BasketPackagingQuantity>;
export type OrdersDeliveryDates = Map<string, Date | null>;
export type OrdersCommentsMap = Map<string, string | null>;

export const basketQuantitiesAtom = atom<PackagingQuantities>(new Map());

const ordersDeliveryDatesAtom = atom<OrdersDeliveryDates>(new Map());

const ordersCommentAtom = atom<OrdersCommentsMap>(new Map());

interface OrdersDeliveryDatesAtomUpdate {
  contractId: string;
  deliveryDate: Date | null;
}

interface OrdersCommentsAtomUpdate {
  contractId: string,
  comment: string | null;
}

export const currentOrdersDeliveryDatesAtom = atom<OrdersDeliveryDates, OrdersDeliveryDatesAtomUpdate[], void>(
  (get) => get(ordersDeliveryDatesAtom),
  (get, set, writeData) => {
    const newOrdersDeliveryDates = new Map(writeData ? get(ordersDeliveryDatesAtom) : []);
    if (writeData) {
      newOrdersDeliveryDates.set(writeData.contractId, writeData.deliveryDate);
    }
    set(ordersDeliveryDatesAtom, newOrdersDeliveryDates);
  },
);

export const currentOrdersCommentsAtom = atom<OrdersCommentsMap, OrdersCommentsAtomUpdate[], void>(
  (get) => get(ordersCommentAtom),
  (get, set, writeData) => {
    const newOrdersComments = new Map(writeData ? get(ordersCommentAtom) : []);
    if (writeData) {
      newOrdersComments.set(writeData.contractId, writeData.comment);
    }
    set(ordersCommentAtom, newOrdersComments);
  },
);

export const currentBasketQuantitiesAtom = atom<PackagingQuantities, BasketPackagingQuantity[], void>(
  (get) => get(basketQuantitiesAtom),
  (get, set, ...update) => {
    const quantities = new Map(update.length ? get(basketQuantitiesAtom) : []);

    // Garbage collector for previously deleted quantities
    Array.from(quantities.entries()).forEach(([id, { quantity }]) => {
      if (!quantity) {
        quantities.delete(id);
      }
    });

    update.forEach(({ id, packagingId, quantity, contractId }) => {
      quantities.set(`${packagingId}_${contractId}`, { id, packagingId, quantity, contractId });
    });

    set(basketQuantitiesAtom, quantities);
  },
);

export const currentBasketQuantityByIdAtom = (
  id: string,
): Atom<BasketPackagingQuantity | undefined> => {
  return atom((get) => get(currentBasketQuantitiesAtom).get(id));
};

export const basketDataAtom = atom<Basket | undefined>(undefined);
export const isBasketLoadingAtom = atom(true);
// Make this atom distinct from local basketQuantities to ensure UI top menu elements are synchronized
export const isRemoteBasketEmptyAtom = atom(true);
export const isBasketDrawerOpenAtom = atom(false);

export const lastOrdersOrderedAtom = atomWithStorage<OrderedOrder[]>(LAST_ORDERED_ORDERS, [], createJSONStorage(() => sessionStorage));

export const currentBuyingCompanyIdAtom = atom<string | undefined>(undefined);
