import i18n from 'i18next';
import { useMemo } from 'react';

export default function useLongDateFormat(): string {
  return useMemo(() => {
    if (i18n.resolvedLanguage === 'fr') {
      return "dd/MM/yyyy à HH'h'mm";
    }
    return 'dd/MM/yyyy HH:mm';
  }, [i18n.resolvedLanguage]);
}
