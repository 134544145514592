import { useTheme, Box, Typography, buttonClasses } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { useMemo } from 'react';
import { Business_Profile_Enum } from 'kheops-graphql';
import OrderExportButton from './OrderExportButton';
import { orderAtom, orderTerminationStatuses } from '../state/state';
import { currentContextAtom } from '../../state';
import ContractsUtils from '../../common/utils/contracts.utils';
import { RoutePaths } from '../../routes/AppRoutes';
import SizedImage from '../../common/components/SizedImage';
import FileUtils, { PhotoSizeFit } from '../../common/utils/file.utils';
import BrandBox from '../../common/components/BrandBox';
import BackButton from '../../common/components/BackButton';
import OrderCreationInfo from './OrderCreationInfo';
import OrderSuggestionChip from './OrderSuggestionChip';
import Comments from './comments/Comments';
import ReorderButton from './ReorderButton';
import OrderTotalPriceDisplay from './OrderTotalPriceDisplay';
import { getCompanyShortName } from '../../common/utils/common.utils';

export interface OrderHeaderProps {
  compact?: boolean;
}

export default function OrderHeader({ compact }: OrderHeaderProps): React.JSX.Element {
  const { realm } = useAtomValue(currentContextAtom);
  const { t } = useTranslation(['order', 'price']);
  const theme = useTheme();
  const order = useAtomValue(orderAtom);

  const otherCompanyRealm = useMemo(
    () => (realm === Business_Profile_Enum.Buyer ? Business_Profile_Enum.Supplier : Business_Profile_Enum.Buyer),
    [realm],
  );

  const otherCompany = ContractsUtils.getOtherCompany(order.contract, realm);
  const companyLink = useMemo(() => {
    return generatePath(RoutePaths.CONTRACT_COMPANY, { companyId: otherCompany.id });
  }, [otherCompany]);

  const companyImage = useMemo(() => {
    return {
      photo: otherCompany.photos[0]?.photo,
      type: otherCompanyRealm,
      size: { width: 80, height: 100, fit: 'cover' as PhotoSizeFit },
    };
  }, [otherCompany]);

  const isOrderTerminated = useMemo(() => (
    orderTerminationStatuses.includes(order.status)
  ), [order]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              left: theme.spacing(1),
              zIndex: 10,
              display: {
                xs: 'none',
                lg: 'block',
              },
            }}
          >
            <BackButton to={RoutePaths.ORDER_LIST} compact />
          </Box>
          <Link to={companyLink}>
            <SizedImage
              src={FileUtils.BuildCompanyImageUrl(companyImage)}
              sx={{
                '& .Kheops-brand': {
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                },
              }}
              width={80}
              borderRadius={4}
              height={100}
              alt={t('common:company')}
              overlay={
                otherCompany.brand && <BrandBox brand={otherCompany.brand} />
              }
            />
          </Link>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
            <Typography color="primary" variant="body1" sx={{ backgroundColor: 'primary.light', width: 'fit-content' }}>
              # {order.friendly_id}
            </Typography>
            <Typography
              variant="h5"
              component={Link}
              to={companyLink}
              sx={{
                lineHeight: 'normal',
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {getCompanyShortName(otherCompany.tradeName, otherCompanyRealm)}
            </Typography>
            <OrderTotalPriceDisplay />
          </Box>
          {compact && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <OrderExportButton
                orderReferenceId={order.reference_id}
                orderFriendlyId={order.friendly_id}
                orderId={order.id}
                sx={{
                  minWidth: 24,
                  p: 1,
                  borderColor: 'primary.main',
                  [`& .${buttonClasses.startIcon}`]: {
                    m: 0,
                  },
                }}
              />
              <Comments compact />
            </Box>
          )}
        </Box>
        {!compact && (
          <>
            {order.suggestor_id && <OrderSuggestionChip sx={{ alignSelf: 'flex-start' }} />}
            <OrderCreationInfo />
          </>
        )}
      </Box>
      {!compact && (
        <Box>
          <ReorderButton
            variant={isOrderTerminated ? 'contained' : 'outlined'}
            orderReferenceId={order.reference_id}
            sx={{ width: '100%' }}
          />
          <OrderExportButton
            orderReferenceId={order.reference_id}
            orderFriendlyId={order.friendly_id}
            orderId={order.id}
            sx={{
              width: '100%',
              mt: 1,
            }}
          >
            {t('order:export_order')}
          </OrderExportButton>
        </Box>
      )}
    </Box>
  );
}
