import { Box, IconButton, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { GlobalDiscountInfo } from './GlobalDiscountList';
import i18n from '../../i18n';
import { CatalogsByCompanyIdDocument } from '../../queries/__generated__/catalogsByCompanyId.generated';
import { ContractsAsSupplierDocument } from '../../queries/__generated__/contractsAsSupplier.generated';
import { useUpdateContractsMutation } from '../../mutations/__generated__/updateContracts.generated';
import { round } from '../../common/utils/common.utils';
import ConfirmDialog from '../../common/components/ConfirmDialog';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import useOpenable from '../../hooks/useOpenable';
import GlobalDiscountFormDialog, { GlobalDiscountFormDialogResponse } from './GlobalDiscountFormDialog';

export interface GlobalDiscountRowProps {
  discount: number;
  globalDiscountInfo: GlobalDiscountInfo[];
}

export default function GlobalDiscountRow({ discount, globalDiscountInfo }: GlobalDiscountRowProps): React.JSX.Element {
  const { t } = useTranslation();
  const percentFormatter = new Intl.NumberFormat(i18n.resolvedLanguage, { style: 'percent', maximumFractionDigits: 2 });
  const { open: openDeleteConfirm, close: closeDeleteConfirm, isOpen: isDeleteConfirmOpen } = useOpenable();
  const { open: openUpdateDialog, close: closeUpdateDialog, isOpen: isUpdateDialogOpen } = useOpenable();
  const [updateContracts, { data }] = useUpdateContractsMutation({ refetchQueries: [CatalogsByCompanyIdDocument, ContractsAsSupplierDocument] });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [performedAction, setPerformedAction] = useState<'UPDATE' | 'DELETE' | undefined>();

  const handleDeleteConfirm = (confirm: boolean): void => {
    if (confirm) {
      setPerformedAction('DELETE');
      updateContracts({
        variables: {
          contractIds: globalDiscountInfo.map(({ contractId }) => contractId),
          contractInput: {
            discount: 0,
          },
        },
      });
    }

    closeDeleteConfirm();
  };

  const handleUpdateDialogClose = (response?: GlobalDiscountFormDialogResponse): void => {
    closeUpdateDialog();

    if (!response) {
      return;
    }

    const deletedContractIds = globalDiscountInfo
      .map(({ contractId }) => contractId)
      .filter((contractId) => !response.contractIds.includes(contractId));

    setPerformedAction('UPDATE');
    updateContracts({
      variables: {
        contractIds: response.contractIds,
        contractInput: {
          discount: response.discount / 100,
          catalog_id: null,
        },
      },
    });
    updateContracts({
      variables: {
        contractIds: deletedContractIds,
        contractInput: {
          discount: 0,
        },
      },
    });
  };

  useEffect((): void => {
    if (data?.update_contract_many?.length) {
      setCommonSnackbarProps({
        label: t(performedAction === 'DELETE' ? 'price:global_discount_deleted' : 'price:global_discount_edited'),
        snackbarProps: {
          open: true,
        },
      });
    }
  }, [data]);

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 4,
        p: 2,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography variant="h6" sx={{ lineheight: 'normal' }}>
          {percentFormatter.format(discount)}
        </Typography>
        <Typography variant="subtitle2">
          {globalDiscountInfo.map(({ tradeName }) => tradeName).join(', ')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <IconButton
          onClick={openUpdateDialog}
          size="extraSmall"
          sx={{
            color: 'black',
            mt: -0.5,
          }}
        >
          <EditOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={openDeleteConfirm}
          size="extraSmall"
          sx={{
            color: 'black',
            mt: -0.5,
          }}
        >
          <DeleteOutlinedIcon />
        </IconButton>
      </Box>
      {isDeleteConfirmOpen && (
        <ConfirmDialog
          open
          onClose={handleDeleteConfirm}
          cancelLabel={t('common:cancel')}
          confirmLabel={t('common:delete')}
          confirmColor="error"
          confirmIcon={<DeleteOutlinedIcon />}
          title={t('price:delete_the_global_discount')}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              {t('price:confirm_global_discount_delete')}
            </Typography>
            <Box
              sx={{
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 4,
                p: 2,
              }}
            >
              <Typography variant="h6" sx={{ lineheight: 'normal' }}>
                {percentFormatter.format(discount)}
              </Typography>
              <Typography variant="subtitle2">
                {globalDiscountInfo.map(({ tradeName }) => tradeName).join(', ')}
              </Typography>
            </Box>
          </Box>
        </ConfirmDialog>
      )}
      {isUpdateDialogOpen && (
        <GlobalDiscountFormDialog
          open
          onClose={handleUpdateDialogClose}
          initialDiscount={round(discount * 100)}
          initialSelectedContracts={globalDiscountInfo.map(({ contractId }) => contractId)}
        />
      )}
    </Box>
  );
}
