import { Box, Button, Dialog, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Product_Family_Name_Enum } from 'kheops-graphql';
import { useProductSubFamiliesQuery } from '../../queries/__generated__/productSubFamilies.generated';
import EmptyDepartmentsPlaceholder from './EmptyDepartmentsPlaceholder';
import useOpenable from '../../hooks/useOpenable';
import { ProductFamilyMap } from '../state';
import UserDepartmentsByFamily from './UserDepartmentsByFamily';
import GlobalDepartmentSetting from './GlobalDepartmentSetting';
import ProductFamiliesSettings from '../settingsDialog/ProductFamiliesSettings';
import { currentContextAtom } from '../../state';

export default function UserDepartments(): React.JSX.Element {
  const { t } = useTranslation(['settings']);
  const context = useAtomValue(currentContextAtom);
  const { data: productSubFamiliesData } = useProductSubFamiliesQuery();
  const { open, close, isOpen } = useOpenable();

  const selectedDepartmentsMap = useMemo((): ProductFamilyMap => {
    const result = Object.values(Product_Family_Name_Enum).reduce((acc, productFamily) => {
      acc[productFamily] = [];

      return acc;
    }, {} as ProductFamilyMap);

    if (context.productFamilies?.length && productSubFamiliesData) {
      context.productFamilies.forEach(({ product_family, product_sub_family }) => {
        if (product_family) {
          result[product_family] = productSubFamiliesData.product_sub_family
            .filter((subFamily) => subFamily.product_family_name === product_family)
            .map((subFamily) => subFamily.name);
        } else if (product_sub_family) {
          const productFamily = productSubFamiliesData.product_sub_family
            .find((subFamily) => subFamily.name === product_sub_family)!.product_family_name as Product_Family_Name_Enum;

          result[productFamily].push(product_sub_family);
        }
      });
    }

    return result;
  }, [productSubFamiliesData, context]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt: 4,
        mx: 1,
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {t('settings:your_departments')}
      </Typography>
      {
        !context.productFamilies?.length
          ? <EmptyDepartmentsPlaceholder />
          : (
            <>
              {
                context.productFamilies[0].global_setting
                  ? <GlobalDepartmentSetting globalSetting={context.productFamilies[0].global_setting} />
                  : <UserDepartmentsByFamily selectedDepartments={selectedDepartmentsMap} />
              }
              <Button
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={open}
                sx={{ alignSelf: 'end' }}
              >
                {t('common:modify')}
              </Button>
              <Dialog
                open={isOpen}
                onClose={(): void => { /* noop */ }}
                sx={{
                  mx: {
                    xs: 2,
                    sm: 0,
                  },
                  '& .MuiPaper-root.MuiDialog-paper': {
                    minWidth: {
                      xs: '100%',
                      sm: 640,
                    },
                  },
                }}
              >
                <ProductFamiliesSettings next={close} standAlone />
              </Dialog>
            </>
          )
      }
    </Box>
  );
}
