import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { BasketPackagingQuantity, currentBasketQuantityByIdAtom } from '../basket/state/state';
import { OrderablePackaging, viewPackagingQuantityByIdAtom } from '../orders/state/state';

export interface PackagingOrderParams {
  id: string;
}

export function useOrderPackagingQuantity({
  id,
}: PackagingOrderParams): OrderablePackaging | undefined {
  const packagingQuantityOrderAtom = useMemo(() => viewPackagingQuantityByIdAtom(id), [id]);

  return useAtomValue(packagingQuantityOrderAtom);
}

export function useBasketPackagingQuantity({
  id,
}: PackagingOrderParams): BasketPackagingQuantity | undefined {
  const packagingQuantityBasketAtom = useMemo(() => currentBasketQuantityByIdAtom(id), [id]);

  return useAtomValue(packagingQuantityBasketAtom);
}
