import { Box, ResponsiveStyleValue, SxProps } from '@mui/system';

interface SizedImageProps {
  src: string;
  alt: string;
  height: number | ResponsiveStyleValue<number>;
  width: number | ResponsiveStyleValue<number>;
  borderRadius?: number | string;
  sx?: SxProps;
  overlay?: React.ReactNode;
  onLoad?: () => void;
}

export default function SizedImage({ src, alt, height, width, borderRadius, overlay, sx, onLoad }: SizedImageProps): React.JSX.Element {
  return (
    <Box
      sx={{
        height,
        width,
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: borderRadius ?? 6,
        flexShrink: 0,
        ...sx,
      }}
    >
      {overlay && (
        <Box
          sx={{
            height,
            width,
            position: 'absolute',
          }}
        >
          {overlay}
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          component="img"
          onLoad={onLoad}
          src={src}
          alt={alt}
          sx={{
            maxHeight: height,
            maxWidth: width,
          }}
          loading="lazy"
        />
      </Box>
    </Box>
  );
}
