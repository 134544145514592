import { useAtomValue, useSetAtom } from 'jotai';
import { Billing_Type_Enum } from 'kheops-graphql';
import { ComputePackagingPriceFromOrderPackagingQuantity, ComputePackagingWeight } from 'kheops-utils';
import { useEffect, useState } from 'react';
import { useOrderByReferenceIdLazyQuery } from '../../queries/__generated__/orderByReferenceId.generated';
import { useGetOrderNextActionsLazyQuery } from '../../queries/__generated__/orderNextActions.generated';
import { ContractOrder, currentPackagingQuantitiesAtom, orderActionsAtom, orderAtom, orderDeliveryDateAtom, OrderDisplay, orderSddPaymentDateAtom } from '../state/state';
import { currentContextAtom, userAtom } from '../../state';
import { round } from '../../common/utils/common.utils';

interface OrderProviderProps {
  orderReferenceId: string;
  children: React.ReactNode;
}
export default function OrderProvider({ orderReferenceId, children }: OrderProviderProps): React.JSX.Element {
  const [getOrderByReferenceIdLazyQuery, { data: orderData }] = useOrderByReferenceIdLazyQuery();
  const [getOrderNextActionsLazyQuery, { data: nextActionsData }] = useGetOrderNextActionsLazyQuery();
  const { companyId } = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const setOrderAtom = useSetAtom(orderAtom);
  const setOrderDeliveryDateAtom = useSetAtom(orderDeliveryDateAtom);
  const setOrderSddPaymentDate = useSetAtom(orderSddPaymentDateAtom);
  const setOrderActionsAtom = useSetAtom(orderActionsAtom);
  const setCurrentPackagingQuantities = useSetAtom(currentPackagingQuantitiesAtom);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    getOrderByReferenceIdLazyQuery({
      variables: { orderReferenceId },
      // to prevent having deprecated version in case the order was updated by another user
      fetchPolicy: 'network-only',
    });
    getOrderNextActionsLazyQuery({
      variables: {
        orderReferenceId,
        userId,
        userCompanyId: companyId,
      },
      // refetch to avoid displaying action buttons when its not needed
      fetchPolicy: 'network-only',
    });
  }, []);

  useEffect(() => {
    // check if getOrderNextActions query is successfull before setting states
    if (orderData && nextActionsData?.getOrderNextActions) {
      const order = orderData.order[0];
      const orderDisplay: OrderDisplay = {
        ...order,
        contract: order.contract as ContractOrder,
        packagingQuantities: order.packagingQuantities.map((packagingQuantity) => {
          return {
            ...packagingQuantity,
            defaultPrice: packagingQuantity.price,
            packaging: {
              ...packagingQuantity.packaging,
              price: ComputePackagingPriceFromOrderPackagingQuantity(packagingQuantity, packagingQuantity.packaging.base_unit!),
            },
          };
        }),
        previousOrders: orderData.previous_versions,
        delivery_date: order.delivery_date,
        buying_company_formatted_address: order.contract.buying_company.addresses[0].formattedAddress,
      };

      setOrderActionsAtom(nextActionsData.getOrderNextActions);
      setOrderAtom(orderDisplay);
      setOrderDeliveryDateAtom(orderDisplay.delivery_date);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setOrderSddPaymentDate(orderDisplay.sdd_payment_date ? new Date(orderDisplay.sdd_payment_date as any) : undefined);
      setCurrentPackagingQuantities(orderDisplay.packagingQuantities.map((packagingQuantity) => {
        const previousVersionPackagingQuantities = orderDisplay.previousOrders[0]?.order_packaging_quantities.find(
          (previousPackagingQuantity) => previousPackagingQuantity.packagingId === packagingQuantity.packaging.id,
        );
        const roundedWeight = packagingQuantity.weight ? round(packagingQuantity.weight, 3) : undefined;
        const notPresentInPreviousVersion = !previousVersionPackagingQuantities && !!orderDisplay.previousOrders[0]?.order_packaging_quantities.length;

        return {
          ...packagingQuantity,
          weight: roundedWeight,
          defaultQuantity: packagingQuantity.quantity,
          previousQuantity: notPresentInPreviousVersion ? 0 : previousVersionPackagingQuantities?.quantity,
          price: packagingQuantity.price,
          previousPrice: previousVersionPackagingQuantities?.price,
          previousWeight: previousVersionPackagingQuantities?.weight ? round(previousVersionPackagingQuantities.weight, 3) : undefined,
          defaultWeight: roundedWeight,
          defaultPackagingWeight: packagingQuantity.packaging.base_unit?.billing_type === Billing_Type_Enum.WeightDependent ? ComputePackagingWeight(packagingQuantity.packaging, packagingQuantity.packaging.base_unit!) : undefined,
          notPresentInPreviousVersion,
        };
      }));
      setIsReady(true);
    }
  }, [orderData, nextActionsData]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isReady && (children)}
    </>
  );
}
