import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Chip, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import i18n from 'i18next';
import { useSetAtom } from 'jotai';
import { FormatBaseUnitQuantity, FormatLogisticalPackaging } from 'kheops-utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QuantitySelector from '../common/components/quantitySelector/QuantitySelector';
import FileUtils from '../common/utils/file.utils';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import { useBasketPackagingQuantity } from '../hooks/usePackagingQuantity';
import { BasketPackagingQuantity } from './state/basket';
import { currentBasketQuantitiesAtom } from './state/state';

export enum PackagingQuantitySummarySizeOption {
  SMALL = 'small',
  MEDIUM = 'medium',
}

interface PackagingQuantitySummarySize {
  image: number;
  priceVariant: Variant;
  priceSx: SxProps;
  nameSx?: SxProps;
}

type PackagingQuantitySummarySizes = {
  [key in PackagingQuantitySummarySizeOption]: PackagingQuantitySummarySize;
}

const PACKAGING_QUANTITY_SUMMARY_SIZES: PackagingQuantitySummarySizes = {
  [PackagingQuantitySummarySizeOption.SMALL]: {
    image: 56,
    priceVariant: 'subtitle1',
    priceSx: {
      fontWeight: 600,
    },
    nameSx: {
      fontWeight: 600,
      lineHeight: 'normal',
    },
  },
  [PackagingQuantitySummarySizeOption.MEDIUM]: {
    image: 56,
    priceVariant: 'subtitle1',
    priceSx: {
      fontWeight: 700,
    },
    nameSx: {
      fontWeight: 600,
      lineHeight: 'normal',
    },
  },
};

export interface PackagingQuantitySummaryProps {
  packagingQuantity: BasketPackagingQuantity;
  editable?: boolean;
  size?: PackagingQuantitySummarySizeOption;
}

function PackagingQuantitySummary({ packagingQuantity, editable, size = PackagingQuantitySummarySizeOption.MEDIUM }: PackagingQuantitySummaryProps): React.JSX.Element {
  const { t } = useTranslation('common');
  const currencyFormat = useCurrencyFormat();
  const currentPackagingQuantity = useBasketPackagingQuantity({
    id: `${packagingQuantity.packaging.id}_${packagingQuantity.contract_id}`,
  })?.quantity || 0;
  const editBasketPackagingQuantity = useSetAtom(currentBasketQuantitiesAtom);
  const packagingPhotoUrl = FileUtils.BuildPackagingImageUrl({
    photo: packagingQuantity.packaging.base_unit?.main_photo,
    type: packagingQuantity.packaging.product!.sub_family,
    size: { height: 112 },
  });
  const totalPrice = currentPackagingQuantity * packagingQuantity.packaging.price;
  const numberFormatter = new Intl.NumberFormat(i18n.resolvedLanguage, { minimumFractionDigits: 0, maximumFractionDigits: 2 });

  const packagingDetails = useMemo(() => {
    return {
      name: packagingQuantity.packaging.product!.name,
      baseUnit: FormatBaseUnitQuantity(packagingQuantity.packaging.base_unit!, 'fr', numberFormatter),
      logisticalPackaging: FormatLogisticalPackaging(packagingQuantity.packaging, 'fr', packagingQuantity.packaging.base_unit!),
    };
  }, [packagingQuantity]);

  const handleChange = (newQuantity: number): void => {
    editBasketPackagingQuantity({
      id: packagingQuantity.id,
      packagingId: packagingQuantity.packaging.id,
      quantity: newQuantity,
      contractId: packagingQuantity.contract_id,
    });
  };

  return (
    <Box sx={{ display: 'flex', mb: 2 }}>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            height: PACKAGING_QUANTITY_SUMMARY_SIZES[size].image,
            width: PACKAGING_QUANTITY_SUMMARY_SIZES[size].image,
            textAlign: 'center',
          }}
        >
          <Box
            component="img"
            src={packagingPhotoUrl}
            alt={t('common:packaging_one')}
            sx={{
              maxWidth: PACKAGING_QUANTITY_SUMMARY_SIZES[size].image,
              maxHeight: PACKAGING_QUANTITY_SUMMARY_SIZES[size].image,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, width: '100%' }}>
        <Tooltip title={packagingDetails.name} placement="top-start" arrow>
          <Typography variant="subtitle1" sx={PACKAGING_QUANTITY_SUMMARY_SIZES[size].nameSx}>
            {packagingDetails.name}
          </Typography>
        </Tooltip>
        <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between', mt: 0.5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Chip
              sx={{
                backgroundColor: 'secondary.light',
              }}
              size="small"
              label={packagingDetails.baseUnit}
            />

            <Typography variant="subtitle2" sx={{ ml: 0.5, mt: 0.25 }}>
              {packagingDetails.logisticalPackaging}
            </Typography>
          </Box>
          {editable
            ? (
              <Box sx={{ marginLeft: 'auto' }}>
                <IconButton size="small" onClick={() => handleChange(0)} sx={{ paddingTop: 0 }}>
                  <DeleteOutlineIcon fontSize="inherit" color="secondary" />
                </IconButton>
              </Box>
            )
            : (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                <Typography variant={PACKAGING_QUANTITY_SUMMARY_SIZES[size].priceVariant} sx={PACKAGING_QUANTITY_SUMMARY_SIZES[size].priceSx}>
                  {currencyFormat.format(totalPrice)}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    height: 24,
                    width: 65,
                    gap: 0.5,
                  }}
                >
                  <Typography sx={{ whiteSpace: 'nowrap' }} variant="subtitle2">
                    {`${t('basket:abbreviated_quantity')} :`}
                  </Typography>
                  <QuantitySelector
                    inputSx={{
                      fontSize: 14,
                    }}
                    sx={{
                      mx: 0,
                    }}
                    quantity={currentPackagingQuantity}
                    onQuantityChange={handleChange}
                    shouldDisplayButtons={false}
                  />
                </Box>
              </Box>
            )}
        </Box>
        {editable && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Typography
              variant={PACKAGING_QUANTITY_SUMMARY_SIZES[size].priceVariant}
              sx={{
                ...PACKAGING_QUANTITY_SUMMARY_SIZES[size].priceSx,
                mr: 1,
              }}
            >
              {currencyFormat.format(totalPrice)}
            </Typography>
            <QuantitySelector quantity={currentPackagingQuantity} onQuantityChange={handleChange} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default React.memo(PackagingQuantitySummary);
