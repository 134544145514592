import { useAtom, useAtomValue } from 'jotai';
import { ReactElement, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Business_Profile_Enum, Order_Status_Enum, Payment_Method_Enum, TaskAction } from 'kheops-graphql';
import { OrderButtonType } from '../../../common/models/order.models';
import CancelOrderButton from '../CancelOrderButton';
import OrderActionButtonCancel from '../OrderActionButtonCancel';
import { DeliveryDateOrderStatus } from '../OrderDeliveryDateDialog';
import OrderInvoiceButton from '../OrderInvoiceButton';
import OrderUpdateDeliveryDateButton from '../OrderUpdateDeliveryDateButton';
import { arePackagingQuantitiesEditedAtom, isPerformingActionAtom, orderAtom, orderTerminationStatuses, thereArePackagingsWithUpdatedQuantitiesAtom } from '../../state/state';
import OrderActionButton from '../OrderActionButton';
import { currentContextAtom } from '../../../state';
import ReorderButton from '../ReorderButton';
import UpdatePaymentDateButton from '../UpdatePaymentDateButton';

export interface UseOrderFormButtonsProps {
  availableButtons: OrderButtonType[];
  userId: string;
}

export default function useOrderFormButtons({ availableButtons, userId }: UseOrderFormButtonsProps): React.JSX.Element[] {
  const location = useLocation();
  const { realm } = useAtomValue(currentContextAtom);
  const arePackagingQuantitiesEdited = useAtomValue(arePackagingQuantitiesEditedAtom);
  const thereAreSomePackagingQuantitiesWithUpdatedQuantities = useAtomValue(thereArePackagingsWithUpdatedQuantitiesAtom);
  const [isPerformingAction, setPerformingAction] = useAtom(isPerformingActionAtom);
  const order = useAtomValue(orderAtom);
  const contractHasCommission = !!order.contract.supplier_commission_rate;

  useEffect(() => {
    setPerformingAction(false);
  }, [location]);

  const orderActionButton = useMemo(() => {
    if (availableButtons.length || arePackagingQuantitiesEdited) {
      let action: TaskAction | undefined;

      if (availableButtons.includes(OrderButtonType.MODIFY) && arePackagingQuantitiesEdited) {
        action = TaskAction.Modify;
      } else if (availableButtons.includes(OrderButtonType.APPROVE)) {
        action = TaskAction.Approve;
      }

      return (
        <OrderActionButton
          action={action}
        />
      );
    }

    if (orderTerminationStatuses.includes(order.status)) {
      return (
        <ReorderButton
          variant="contained"
          orderReferenceId={order.reference_id}
          sx={{ flex: 'auto' }}
        />
      );
    }

    if (realm === Business_Profile_Enum.Buyer && order.status === Order_Status_Enum.OrderBeingPaidByBuyer && order.payment_method === Payment_Method_Enum.SddTriggered) {
      return (
        <UpdatePaymentDateButton />
      );
    }
  }, [availableButtons, arePackagingQuantitiesEdited, order]);

  const orderAlternativeButton = useMemo(() => {
    const orderInPreparationAndOnlyWeightsEdited = order.status === Order_Status_Enum.OrderToBePreparedBySupplier && !thereAreSomePackagingQuantitiesWithUpdatedQuantities && arePackagingQuantitiesEdited;

    /*
      When in to_be_prepared status, if as a supplier update at least a packaging's quantity (not weight) we display cancel modification.
      If however they only edit weights, we display the deliverydatebutton.
    */

    if (arePackagingQuantitiesEdited && !orderInPreparationAndOnlyWeightsEdited) {
      return (
        <OrderActionButtonCancel disabled={isPerformingAction} />
      );
    }

    switch (order.status) {
      case Order_Status_Enum.OrderToBeValidatedByBuyer:
      case Order_Status_Enum.OrderToBeValidatedBySupplier:

        return (
          <CancelOrderButton
            orderId={order.id}
            referenceId={order.reference_id}
            userId={userId}
          />
        );
      case Order_Status_Enum.OrderToBeDeliveredBySupplier:
      case Order_Status_Enum.OrderToBePreparedBySupplier:

        return realm === Business_Profile_Enum.Supplier
          ? (
            <OrderUpdateDeliveryDateButton
              orderId={order.id}
              orderReferenceId={order.reference_id}
              orderStatus={order.status as DeliveryDateOrderStatus}
            />
          )
          : undefined;
      case Order_Status_Enum.OrderClosed:
      case Order_Status_Enum.OrderBeingPaidByBuyer:
      case Order_Status_Enum.OrderToBePaidByBuyer:

        return (
          <OrderInvoiceButton
            orderFriendlyId={order.friendly_id!}
            hasCommission={contractHasCommission}
            hasInvoiceGeneration={order.has_invoice_generation}
            paymentMethod={order.payment_method!}
          />
        );
      default:
    }
  }, [arePackagingQuantitiesEdited, order, thereAreSomePackagingQuantitiesWithUpdatedQuantities]);

  return [orderAlternativeButton, orderActionButton].filter((element) => !!element) as ReactElement[];
}
