import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { RoutePaths } from '../../routes/AppRoutes';
import { useDeleteCatalogMutation } from '../../mutations/__generated__/deleteCatalog.generated';
import { CatalogsByCompanyIdDocument, CatalogsByCompanyIdQuery } from '../../queries/__generated__/catalogsByCompanyId.generated';
import ConfirmDialog from '../../common/components/ConfirmDialog';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import useOpenable from '../../hooks/useOpenable';
import { ContractsAsSupplierDocument } from '../../queries/__generated__/contractsAsSupplier.generated';

export interface CatalogInfoBoxProps {
  catalog: CatalogsByCompanyIdQuery['catalog'][number];
}

export default function CatalogInfoBox({ catalog }: CatalogInfoBoxProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const [deleteCatalog] = useDeleteCatalogMutation({
    refetchQueries: [CatalogsByCompanyIdDocument, ContractsAsSupplierDocument],
    awaitRefetchQueries: true,
  });
  const { open, close, isOpen } = useOpenable();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMoreActionsClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleConfirm = (confirm: boolean): void => {
    close();

    if (confirm) {
      deleteCatalog({ variables: { id: catalog.id } });

      setCommonSnackbarProps({
        label: t('common:delete_successful'),
        snackbarProps: {
          open: true,
        },
      });
    }
  };

  return (
    <>
      <Box
        component={Link}
        to={`${RoutePaths.CATALOG}?catalogId=${catalog.id}`}
        sx={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'space-between',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 4,
          p: 2,
          textDecoration: 'none',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="body1">
            {catalog.name}
          </Typography>
          {!!catalog.contracts.length && (
            <Typography variant="subtitle2">
              {catalog.contracts.slice(0, 2).map(({ buying_company }) => buying_company.tradeName).join(', ')}
              {catalog.contracts.length > 2 && ` +${catalog.contracts.length - 2}`}
            </Typography>
          )}
        </Box>
        <IconButton
          size="extraSmall"
          onClick={handleMoreActionsClick}
          sx={{
            color: 'black',
            mt: -0.5,
            backgroundColor: anchorEl ? 'divider' : 'unset',
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 4,
            mt: 1,
            gap: 2,
          },
          '& .MuiMenuItem-root': {
            py: 1,
          },
          '& .MuiListItemIcon-root': {
            minWidth: 26,
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1.125rem',
            color: 'black',
          },
        }}
      >
        <MenuItem onClick={() => navigate(`${RoutePaths.CATALOG}?catalogId=${catalog.id}`)}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:modify')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate(`${RoutePaths.CATALOG}?copyCatalogId=${catalog.id}`)}>
          <ListItemIcon>
            <ContentCopyOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:duplicate')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={open}>
          <ListItemIcon>
            <DeleteOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:delete')}
          </ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmDialog
        open={isOpen}
        onClose={handleConfirm}
        cancelLabel={t('common:cancel')}
        confirmLabel={t('common:delete')}
        confirmColor="error"
        title={t('price:delete_catalog')}
        message={(
          <>
            <Typography variant="body2">
              {t('price:delete_catalog_description')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                border: '1px solid',
                borderColor: 'divider',
                textAlign: 'left',
                borderRadius: 4,
                p: 2,
                mt: 3,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body1">
                  {catalog.name}
                </Typography>
                {!!catalog.contracts.length && (
                  <Typography variant="subtitle2">
                    {catalog.contracts.map(({ buying_company }) => buying_company.tradeName).join(', ')}
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        )}
      />
    </>
  );
}
