import { Box, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { Business_Profile_Enum, Document, Document_Type_Enum, Order_Status_Enum } from 'kheops-graphql';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { orderAtom, orderInvoiceAtom } from '../../state/state';
import useOrderInvoiceType from '../../../hooks/useOrderInvoiceType';
import { useDocumentsByOrderLazyQuery } from '../../../queries/__generated__/documentsByOrder.generated';
import DocumentButton from './DocumentButton';
import { currentContextAtom } from '../../../state';

export type OrderDocument = Pick<Document, 'domain' | 'path' | 'type' | 'id'>;

type DocumentMap = {[type in Document_Type_Enum]?: OrderDocument};

export default function OrderDocuments(): React.JSX.Element {
  const { t } = useTranslation(['common', 'order']);
  const {
    id: orderId,
    status: orderStatus,
    previousOrders: [previousOrder],
    has_invoice_generation,
    payment_method,
    contract,
  } = useAtomValue(orderAtom);
  const { realm } = useAtomValue(currentContextAtom);
  const invoiceType = useOrderInvoiceType(!!contract.supplier_commission_rate, has_invoice_generation, payment_method!);
  const setOrderInvoice = useSetAtom(orderInvoiceAtom);
  const [fecthDocuments, { data, loading, startPolling, stopPolling }] = useDocumentsByOrderLazyQuery({
    variables: {
      orderId,
      isBuyer: realm === Business_Profile_Enum.Buyer,
    },
    fetchPolicy: 'cache-and-network',
  });
  const [documents, setDocuments] = useState<DocumentMap>({});
  const [pollingDocument, setPollingDocument] = useState(false);

  useEffect(() => {
    setOrderInvoice(undefined);
    fecthDocuments();
  }, [orderStatus]);

  useEffect(() => {
    if (loading) {
      return;
    }

    let dataDocuments: DocumentMap | undefined;
    const documentList = data?.documentAsBuyer || data?.documentAsSupplier;

    if (documentList) {
      dataDocuments = Object.values(Document_Type_Enum).reduce((acc, documentType) => {
        const dataDocument = documentList.find((document) => document.type === documentType);

        if (dataDocument) {
          acc[documentType] = dataDocument;

          if (invoiceType === documentType && dataDocument.invoice) {
            const invoiceData = dataDocument.invoice;

            setOrderInvoice(invoiceData);
          }
        }

        return acc;
      }, {} as DocumentMap);

      setDocuments(dataDocuments);
    }

    if (previousOrder?.status !== orderStatus && previousOrder?.status === Order_Status_Enum.OrderToBeReceivedByBuyer) {
      // we expect that the order summary invoice will be available for orders with payment managed by kheops.
      if (documentList && (!documentList.length || !dataDocuments![invoiceType]) && has_invoice_generation) {
        if (!pollingDocument) {
          startPolling(1000);
          setPollingDocument(true);
        }
      } else {
        stopPolling();
        setPollingDocument(false);
      }
    }
  }, [data?.documentAsBuyer, data?.documentAsSupplier, loading]);

  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
        {t('common:documents')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DocumentButton
          documentType={Document_Type_Enum.PurchaseOrder}
          document={documents[Document_Type_Enum.PurchaseOrder]}
        />
        <DocumentButton
          documentType={invoiceType}
          document={documents[invoiceType]}
        />
        <DocumentButton
          documentType={Document_Type_Enum.DeliveryNote}
          document={documents[Document_Type_Enum.DeliveryNote]}
        />
      </Box>
    </Box>
  );
}
