import { Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScopeProvider } from 'jotai-scope';
import { PageWrapper } from '../../common/components/PageWrapper';
import OrderList from './OrderList';
import { currentBasketQuantitiesAtom } from '../../basket/state/state';

export default function OrderListPage(): React.JSX.Element {
  const { t } = useTranslation('order');

  return (
    <PageWrapper>
      <ScopeProvider atoms={[currentBasketQuantitiesAtom]}>
        <Card sx={{ p: { xs: 3, sm: 4, lg: 5 } }}>
          <Typography
            variant="h4"
            sx={{
              mb: 2,
            }}
          >
            {t('order:track_orders')}
          </Typography>
          <OrderList />
        </Card>
      </ScopeProvider>
    </PageWrapper>
  );
}
