import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { PackagingFamilyTypeIntl } from '../common/i18n/product-family.translation';
import { ContractPreview } from '../hooks/useContracts';
import { RoutePaths } from '../routes/AppRoutes';
import SizedImage from '../common/components/SizedImage';
import FileUtils from '../common/utils/file.utils';
import CompanyDistanceChip from '../common/components/CompanyDistanceChip';
import MinimumOrderValueChip from '../common/components/MinimumOrderValueChip';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import BrandBox from '../common/components/BrandBox';
import PriceModeChip from '../common/components/PriceModeChip';

interface ContractRowProps {
  contract: ContractPreview;
}

export default function ContractRow({ contract }: ContractRowProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts', 'product-family']);
  const navigate = useNavigate();
  const currencyFormatter = useCurrencyFormat({ maximumFractionDigits: 0 });

  const onRowClick = (): void => {
    navigate(
      generatePath(RoutePaths.CONTRACT_COMPANY, {
        companyId: contract.company.id,
      }),
    );
  };

  return (
    <Grid
      item
      container
      xs={12}
      onClick={onRowClick}
      columnSpacing={2}
      sx={{
        pt: 3,
        mb: {
          xs: 3,
          md: 0,
        },
        cursor: 'pointer',
        '&:not(:first-child)': {
          borderTop: '1px solid',
          borderColor: 'divider',
        },
      }}
    >
      <Grid item xs={12} md={6} sx={{ display: 'flex', mb: 3 }}>
        <SizedImage
          src={
            FileUtils.BuildCompanyImageUrl({
              photo: contract.company.photos[0]?.photo,
              type: contract.company.business_profile,
              size: { height: 128, width: 128 },
            })
          }
          alt={t('common:company')}
          height={80}
          width={80}
          sx={{
            mr: 2,
            '& .Kheops-brand': {
              width: 32,
              height: 32,
              borderRadius: 3,
              position: 'absolute',
              bottom: 8,
              right: 8,
            },
            '& .Kheops-brandLogo': {
              width: 18,
            },
          }}
          borderRadius={4}
          overlay={
            contract.company.brand && <BrandBox brand={contract.company.brand} />
          }
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography variant="h6" sx={{ lineHeight: 1, mb: 1 }}>{contract.company.tradeName}</Typography>
          {contract.company.products_aggregate?.aggregate.max.sub_family && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Chip
                icon={<ShoppingBasketOutlinedIcon fontSize="small" />}
                label={t(`product-family:${PackagingFamilyTypeIntl[contract.company.products_aggregate.aggregate.max.sub_family as Product_Sub_Family_Name_Enum]}`)}
                size="small"
                sx={{ mr: 1, backgroundColor: 'secondary.light' }}
              />
              {contract.company.products!.length > 1 && (
                <Typography variant="subtitle1">+{contract.company.products!.length - 1}</Typography>
              )}
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
            <CompanyDistanceChip
              targetGeoLoc={{
                lat: contract.company.addresses[0].latitude,
                lng: contract.company.addresses[0].longitude,
              }}
            />
            <MinimumOrderValueChip minimumOrderValue={contract.minimum_order_value as number} />
            {!!(contract.catalog_id || contract.discount) && (
              <PriceModeChip contract={contract} chipProps={{ size: 'small' }} />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item container xs={12} md={6} rowSpacing={3}>
        <Grid item xs={6} sm={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1">
            {t('contracts:last_order')}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {contract.last_order_date ? format(new Date(contract.last_order_date), 'dd/MM/yyyy') : '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1">
            {t('contracts:orders_count')}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {contract.order_count}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography variant="subtitle1">
            {t('contracts:total_revenue')}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            💰 {currencyFormatter.format(contract.total_revenue!)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
