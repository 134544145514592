import { useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Availability_Status_Enum } from 'kheops-graphql';
import { currentBasketQuantitiesAtom, currentBuyingCompanyIdAtom } from '../../basket/state/state';
import { contractsAtom } from '../../state';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import { usePackagingsByBarcodeLazyQuery } from '../../queries/__generated__/packagingsByBarcode.generated';

interface useUpdateBasketReturn {
  upsertPackagingByBarCode: (barcode: string) => void;
}

/**
 * This hooks only works in an algolia instant search context
 */
export default function useUpdateBasketByBarCode(): useUpdateBasketReturn {
  const { t } = useTranslation(['basket']);
  const editBasketPackagingQuantity = useSetAtom(currentBasketQuantitiesAtom);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [packagingsByBarcodeQuery, { data: packagingsByBarcodeData, loading }] = usePackagingsByBarcodeLazyQuery({
    fetchPolicy: 'network-only',
  });
  const currentBuyingCompanyId = useAtomValue(currentBuyingCompanyIdAtom);
  const contracts = useAtomValue(contractsAtom);
  const basketQuantities = useAtomValue(currentBasketQuantitiesAtom);

  useEffect((): void => {
    if (!packagingsByBarcodeData?.packaging.length || loading) {
      return;
    }

    const packagings = packagingsByBarcodeData.packaging;

    if (packagings.length === 1) {
      const packaging = packagings[0];

      if (packaging.base_unit.availability === Availability_Status_Enum.Unavailable) {
        setCommonSnackbarProps({
          label: t('basket:product_not_added_to_basket_unavailable_packaging', { product_name: packaging.product.name }),
          snackbarProps: {
            open: true,
          },
          alertProps: {
            severity: 'warning',
          },
        });
      } else {
        const contract = contracts.find(({ buyingCompanyId, supplyingCompanyId }) => buyingCompanyId === currentBuyingCompanyId && supplyingCompanyId === packagings[0].company.id);
        const basketPackagingQuantity = basketQuantities.get(`${packaging.id}_${contract!.reference_id}`);

        editBasketPackagingQuantity({
          id: basketPackagingQuantity?.id,
          packagingId: packaging.id,
          quantity: basketPackagingQuantity?.quantity ? basketPackagingQuantity.quantity + 1 : 1,
          contractId: contract!.reference_id!,
        });
      }
    } else if (packagings.length > 1) {
      const potentialPackagingCompanyId = packagings[0].company.id;
      const isPackagingCreatedByOtherSupplier = packagings.some(({ company }) => company.id !== potentialPackagingCompanyId);

      setCommonSnackbarProps({
        label: t(`basket:${
          isPackagingCreatedByOtherSupplier
            ? 'product_not_added_to_basket_multiple_suppliers'
            : 'product_not_added_to_basket_multiple_packagings'
        }`),
        snackbarProps: {
          open: true,
        },
        alertProps: {
          severity: 'warning',
        },
      });
    }
  }, [packagingsByBarcodeData, loading]);

  const upsertPackagingByBarCode = (barcode: string): void => {
    packagingsByBarcodeQuery({ variables: { barcode } });
  };

  return { upsertPackagingByBarCode };
}
