import { Box, Card, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { LoadingButton } from '@mui/lab';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAtomValue } from 'jotai';
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import { BusinessProfile, Business_Profile_Enum, Global_Product_Family_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { GetProductFamilyMapFromSubFamilies, ProductFamilyMap } from 'kheops-utils';
import { RoutePaths } from '../routes/AppRoutes';
import { useCreateContractRequestMutation } from '../mutations/__generated__/createContractRequest.generated';
import { CreateGroupChannelMutation, useCreateGroupChannelMutation } from '../mutations/__generated__/createGroupChannel.generated';
import { ContractChannelUsersInfoQuery, useContractChannelUsersInfoQuery } from '../queries/__generated__/contractChannelUsersInfo.generated';
import { useProductSubFamiliesQuery } from '../queries/__generated__/productSubFamilies.generated';
import { currentContextAtom, userAtom } from '../state';
import { PackagingFamilyTypeIntl } from '../common/i18n/product-family.translation';
import { packagingLogoMap } from '../common/utils/file.utils';
import { ContractPreview } from '../hooks/useContracts';
import CompanyContactList from './CompanyContactList';
import { ContractsAsSupplierDocument } from '../queries/__generated__/contractsAsSupplier.generated';
import { CompanyByIdDocument } from '../queries/__generated__/companyById.generated';
import { ContractsAsBuyerDocument } from '../queries/__generated__/contractsAsBuyer.generated';

type ContactMap = {
  [key in Product_Sub_Family_Name_Enum]?: NonNullable<ContractChannelUsersInfoQuery['buyingCompany']>['role_assignments'];
}

export interface CompanyContactsProps {
  companyId: string;
  contract?: ContractPreview;
}

export default function CompanyContacts({ companyId, contract }: CompanyContactsProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts', 'product-family']);
  const navigate = useNavigate();
  const context = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const { data: productSubFamiliesData } = useProductSubFamiliesQuery();
  const { data: contractChannelUsersInfoData } = useContractChannelUsersInfoQuery({
    variables: {
      buyingCompanyId: companyId,
      supplyingCompanyId: context.companyId!,
    },
  });
  const [createContractRequest, { data: createContractRequestData, loading: loadingContractCreation }] = useCreateContractRequestMutation({
    refetchQueries: [CompanyByIdDocument, ContractsAsSupplierDocument, ContractsAsBuyerDocument],
    awaitRefetchQueries: true,
  });
  const [createGroupChannel, { data: dataChannel, loading: loadingChannelCreation }] = useCreateGroupChannelMutation({ refetchQueries: [ContractsAsSupplierDocument] });
  const [message, setMessage] = useState('');

  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);

  const productFamilyMap = useMemo((): ProductFamilyMap | undefined => {
    if (!productSubFamiliesData) {
      return;
    }

    return GetProductFamilyMapFromSubFamilies(productSubFamiliesData.product_sub_family);
  }, [productSubFamiliesData]);

  const contactMap = useMemo((): ContactMap => {
    if (!contractChannelUsersInfoData || !productFamilyMap) {
      return {};
    }

    const { buyingCompany, supplyingCompany } = contractChannelUsersInfoData;
    const supplierSubFamilies = supplyingCompany!.products.map(({ sub_family }) => sub_family) as Product_Sub_Family_Name_Enum[];

    return supplierSubFamilies.reduce((acc, supplierSubFamily) => {
      // Code is quite dense here, just filtering user that match supplier sub family
      const contacts = buyingCompany!.role_assignments.filter(({ product_families }) => {
        if (!product_families?.length) {
          return false;
        }

        const manageAllProductFamilies = product_families[0].global_setting === Global_Product_Family_Enum.All;
        const manageAnySupplierProductFamily = product_families.some(({ product_family, product_sub_family }) => {
          const subFamilies = product_family ? productFamilyMap[product_family] : [product_sub_family || ''];

          return subFamilies.includes(supplierSubFamily);
        });

        return manageAllProductFamilies || manageAnySupplierProductFamily;
      });

      if (contacts.length) {
        acc[supplierSubFamily] = contacts;
      }

      return acc;
    }, {} as ContactMap);
  }, [contractChannelUsersInfoData, productFamilyMap]);

  const handleContractRequestSubmit = useCallback((): void => {
    createContractRequest({
      variables: {
        userId,
        buyingCompanyId: companyId,
        supplyingCompanyId: context.companyId!,
        fromBusinessProfile: context.realm as unknown as BusinessProfile,
        message,
      },
    });
  }, [context, message]);

  const handleSeeDiscussionClick = useCallback(async (): Promise<void> => {
    let data: CreateGroupChannelMutation | null | undefined;

    if (!contract?.channel_url) {
      let buyingCompanyId: string;
      let supplyingCompanyId: string;

      if (context.realm === Business_Profile_Enum.Buyer) {
        buyingCompanyId = context.companyId!;
        supplyingCompanyId = companyId;
      } else {
        buyingCompanyId = companyId;
        supplyingCompanyId = context.companyId!;
      }

      data = (await createGroupChannel({ variables: { buyingCompanyId, supplyingCompanyId, fromBusinessProfile: context.realm as unknown as BusinessProfile } })).data;
    }

    if (data?.createGroupChannel?.channelUrl) {
      navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${data.createGroupChannel.channelUrl}`);
    } else {
      navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${contract!.channel_url}`);
    }
  }, [contract]);

  useEffect(() => {
    if (createContractRequestData?.createContractRequest?.channelUrl) {
      navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${createContractRequestData.createContractRequest.channelUrl}`);
    }
  }, [createContractRequestData]);

  useEffect(() => {
    const sendMessage = async (): Promise<void> => {
      const createdChannelUrl = dataChannel!.createGroupChannel.channelUrl;
      const channel = await sdkInstance?.groupChannel.getChannel(createdChannelUrl);
      const messageRequestHandler = channel!.sendUserMessage({
        message,
      });

      messageRequestHandler.onSucceeded(() => {
        navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${createdChannelUrl}`);
      });
    };

    if (dataChannel?.createGroupChannel.channelUrl) {
      sendMessage();
    }
  }, [dataChannel, message]);

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box>
        <Typography variant="h4" sx={{ mb: 1 }}>
          {t('contracts:contact_this_store')}
        </Typography>
        <Typography variant="subtitle2">
          {t('contracts:contact_this_store_description')}
        </Typography>
      </Box>
      {contractChannelUsersInfoData && !!Object.keys(contactMap).length && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography variant="h6">
            {t('contracts:your_recipients')}
          </Typography>
          {
            (Object.keys(contactMap) as Product_Sub_Family_Name_Enum[])
              .sort((a, b) => t(`product-family:${PackagingFamilyTypeIntl[a]}`).localeCompare(t(`product-family:${PackagingFamilyTypeIntl[b]}`)))
              .map((subFamily) => (
                <Box key={subFamily}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <img src={packagingLogoMap[subFamily]} alt={subFamily} height="32" />
                    <Typography variant="h6" sx={{ ml: 0.5 }}>
                      {t(`product-family:${PackagingFamilyTypeIntl[subFamily]}`)}
                    </Typography>
                  </Box>
                  <CompanyContactList contacts={contactMap[subFamily]!.map((contact) => contact.user)} />
                </Box>
              ))
          }
          {!Object.keys(contactMap).length && (
            <CompanyContactList contacts={contractChannelUsersInfoData.buyingCompany!.role_assignments.map((role) => role.user)} />
          )}
        </Box>
      )}
      {!contract?.status && (
        <>
          <Typography variant="body1">
            {t('contracts:your_message')}
          </Typography>
          <TextField
            placeholder={t('contracts:type_your_message')}
            onChange={(event) => setMessage(event.target.value)}
            multiline
            minRows={3}
          />
          <LoadingButton
            onClick={handleContractRequestSubmit}
            loading={loadingContractCreation}
            variant="contained"
            disabled={!message}
            startIcon={<SendIcon />}
            sx={{
              px: 8,
              alignSelf: {
                sm: 'center',
              },
            }}
          >
            {t('common:send')}
          </LoadingButton>
        </>
      )}
      {contract && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            justifyContent: {
              sm: 'center',
            },
            gap: 2,
            py: 2,
          }}
        >
          <LoadingButton
            variant="contained"
            onClick={() => handleSeeDiscussionClick()}
            loading={loadingChannelCreation}
            startIcon={<AddBusinessOutlinedIcon />}
          >
            {t('contracts:trade_with_this_buyer')}
          </LoadingButton>
        </Box>
      )}
    </Card>
  );
}
