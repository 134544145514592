import { Business_Profile_Enum } from 'kheops-graphql';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { BasketPackagingQuantity } from '../basket/state/basket';
import FileUtils, { PhotoSize } from '../common/utils/file.utils';
import { round } from '../common/utils/common.utils';
import { contractsAtom, currentContextAtom } from '../state';

export interface BasketOrder {
  packagingQuantities: BasketPackagingQuantity[];
  contractId: string;
  photoUrl: string;
  name: string;
  companyId: string;
  minimumOrderValue: number;
  totalPrice: number;
}

export interface UseBasketOrdersParams {
  basketPackagingQuantities: BasketPackagingQuantity[];
  photoSize?: PhotoSize;
}

export interface BasketOrdersDict {
  [contractId: string]: BasketOrder,
}

export function useBasketOrders({ basketPackagingQuantities, photoSize }: UseBasketOrdersParams): BasketOrdersDict {
  const { realm } = useAtomValue(currentContextAtom);
  const contracts = useAtomValue(contractsAtom);

  return useMemo(() => {
    if (!contracts.length) {
      return {};
    }

    return basketPackagingQuantities.reduce(
      (acc, packagingQuantity) => {
        const contractId = packagingQuantity.contract_id;
        const contract = contracts.find(({ reference_id }) => contractId === reference_id)!;
        const catalogDiscount = contract.catalog?.catalog_packagings
          .find(({ packaging_sku }) => packaging_sku === packagingQuantity.packaging.sku)
          ?.discount;
        const adjustedPackagingQuantity: BasketPackagingQuantity = {
          ...packagingQuantity,
          packaging: {
            ...packagingQuantity.packaging,
            price: round(packagingQuantity.packaging.price * (1 - (catalogDiscount || packagingQuantity.contract.discount))),
          },
        };
        const price = adjustedPackagingQuantity.quantity * adjustedPackagingQuantity.packaging.price;

        if (!acc[contractId]) {
          const { id, tradeName, photos, business_profile } = realm === Business_Profile_Enum.Buyer
            ? adjustedPackagingQuantity.contract.supplying_company
            : adjustedPackagingQuantity.contract.buying_company;

          acc[contractId] = {
            packagingQuantities: [adjustedPackagingQuantity],
            contractId,
            photoUrl: FileUtils.BuildCompanyImageUrl({
              photo: photos[0]?.photo,
              size: photoSize || { height: 112, width: 112 },
              type: business_profile,
            }),
            companyId: id,
            name: tradeName,
            minimumOrderValue: adjustedPackagingQuantity.contract.minimum_order_value!,
            totalPrice: price,
          };
        } else {
          acc[contractId].packagingQuantities.push(adjustedPackagingQuantity);
          acc[contractId].totalPrice += price;
        }

        return acc;
      },
      {} as BasketOrdersDict,
    );
  }, [basketPackagingQuantities, contracts]);
}
