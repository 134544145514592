import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { FormatPackagingUnitPrice, FormatPackagingVolumePrice } from 'kheops-utils';
import { Base_Unit_Type_Enum, Billing_Type_Enum } from 'kheops-graphql';
import { DraftPackagingsPrices, DraftPackagingsPricesCollection } from './state';
import { companyPackagingsAtom } from '../state';
import { usePackagingsByCompanyQuery } from '../../queries/__generated__/packagingsByCompany.generated';
import { round } from '../../common/utils/common.utils';
import { currentContextAtom } from '../../state';

export interface UsePackagingsPricesFormDefaultValues {
  defaultValues: DraftPackagingsPrices;
  ready: boolean;
}

export default function usePackagingsPricesFormDefaultValues(): UsePackagingsPricesFormDefaultValues {
  const { companyId } = useAtomValue(currentContextAtom);
  const [companyPackagings, setCompanyPackagings] = useAtom(companyPackagingsAtom);
  const { data: companyPackagingsData, loading, called } = usePackagingsByCompanyQuery({
    variables: {
      companyId,
      skuPackagingsToIgnore: [],
    },
  });

  useEffect((): void => {
    if (companyPackagingsData?.packaging?.length) {
      setCompanyPackagings(companyPackagingsData?.packaging);
    }
  }, [companyPackagingsData]);

  const defaultValues = useMemo((): DraftPackagingsPrices => {
    return {
      prices: companyPackagings.reduce((acc, packaging) => {
        const isPackagingBilledByWeight = packaging.base_unit.billing_type === Billing_Type_Enum.WeightDependent;
        const isBulk = packaging.base_unit.unit_type === Base_Unit_Type_Enum.Bulk;
        const packagingUnitPrice = isPackagingBilledByWeight || isBulk
          ? FormatPackagingVolumePrice(packaging, packaging.base_unit).value
          : FormatPackagingUnitPrice(packaging);

        acc[packaging.sku] = {
          packagingPrice: packaging.price,
          packagingUnitPrice: round(packagingUnitPrice),
        };

        return acc;
      }, {} as DraftPackagingsPricesCollection),
    };
  }, [companyPackagings]);

  return {
    defaultValues,
    ready: !loading && called,
  };
}
