import { Box, Button, Card, Divider, Drawer, IconButton, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import OrderHeader from './OrderHeader';
import OrderDeliveryDateForm from './OrderDeliveryDateForm';
import useOpenable from '../../hooks/useOpenable';
import OrderCreationInfo from './OrderCreationInfo';
import Comments from './comments/Comments';
import Documents from './documents/Documents';
import ReorderButton from './ReorderButton';
import { orderAtom } from '../state/state';

export interface OrderInformationProps {
  sx?: SxProps;
}

export default function OrderInformation({ sx }: OrderInformationProps): React.JSX.Element {
  const { t } = useTranslation(['order']);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const { open, close, isOpen } = useOpenable();
  const order = useAtomValue(orderAtom);

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', gap: 3, ...sx }}>
      <OrderHeader compact={isSmall} />
      <Box
        sx={{
          display: {
            xs: 'none',
            lg: 'flex',
          },
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Divider />
        <OrderDeliveryDateForm />
        <Divider />
        <Documents />
        <Divider />
        <Comments />
      </Box>
      {isSmall && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <ReorderButton orderReferenceId={order.reference_id} sx={{ width: '100%' }} />
          <Divider />
          <Button
            onClick={open}
            color="secondary"
            variant="text"
            sx={{
              textDecoration: 'underline',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            + {t('order:more_details')}
          </Button>
          <Drawer
            open={isOpen}
            onClose={close}
            anchor="bottom"
            PaperProps={{
              sx: {
                p: 3,
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
                gap: 3,
              },
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="title" sx={{ lineHeight: '40px' }}>
                {t('order:order_details')}
              </Typography>
              <IconButton
                onClick={close}
                sx={{
                  backgroundColor: 'greys.primary',
                  color: 'text.primary',
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <OrderCreationInfo />
              <Divider />
              <OrderDeliveryDateForm />
              <Divider />
              <Documents />
            </Box>
          </Drawer>
        </Box>
      )}
    </Card>
  );
}
