import { Box } from '@mui/material';
import { Brand_Enum } from 'kheops-graphql';
import FileUtils from '../utils/file.utils';

interface BrandBoxProps {
  brand: Brand_Enum;
}

export default function BrandBox({ brand }: BrandBoxProps): React.JSX.Element {
  return (
    <Box
      className="Kheops-brand"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.paper',
        borderRadius: 4,
        width: 40,
        height: 40,
      }}
    >
      <Box
        className="Kheops-brandLogo"
        component="img"
        src={FileUtils.BuildBrandLogoURL(brand)}
        alt="logo"
        loading="lazy"
        sx={{
          width: 24,
        }}
      />
    </Box>
  );
}
