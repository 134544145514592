import { useEffect } from 'react';
import { Provider, useAtom } from 'jotai';
import DiscoveryAsBuyerPage from './DiscoveryAsBuyerPage';
import useBaseStore from '../../hooks/useBaseStore';
import { basketQuantitiesAtom } from '../../basket/state/state';

export default function DiscoveryAsBuyerProvider(): React.JSX.Element {
  const baseStore = useBaseStore();
  const [basketQuantities, setBasketQuantities] = useAtom(basketQuantitiesAtom);

  useEffect((): void => {
    baseStore.sub(basketQuantitiesAtom, () => {
      setBasketQuantities(baseStore.get(basketQuantitiesAtom));
    });
  }, []);

  useEffect((): void => {
    baseStore.set(basketQuantitiesAtom, basketQuantities);
  }, [basketQuantitiesAtom]);

  return (
    <Provider store={baseStore}>
      <DiscoveryAsBuyerPage />
    </Provider>
  );
}
