import { atom } from 'jotai';
import { Global_Product_Family_Enum, Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';

export type ProductFamilyMap = {[key in Product_Family_Name_Enum]: Product_Sub_Family_Name_Enum[]};

export interface ProductFamilySettings {
  global_setting?: Global_Product_Family_Enum;
  product_sub_families?: Set<Product_Sub_Family_Name_Enum>;
}

export const productFamiliesSettingsAtom = atom<ProductFamilySettings>({});
