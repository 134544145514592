import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHits } from 'react-instantsearch';
import { AlgoliaHit } from 'instantsearch.js';
import { ProductHit } from '../../search/search';
import DiscoveryFilters from './DiscoveryFilters';
import DiscoveryProductHit from './DiscoveryProductHit';
import i18n from '../../i18n';

export default function DiscoveryProductHits(): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  // eslint-disable-next-line deprecation/deprecation
  const { hits, results } = useHits<AlgoliaHit<ProductHit>>();
  const format = new Intl.NumberFormat(i18n.resolvedLanguage);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {!!results && (
        <>
          <Typography variant="h5">
            {t('discovery:N_product', { count: results.nbHits, formattedCount: format.format(results.nbHits) })}
          </Typography>
          <DiscoveryFilters
            fullTextSearchPlaceholder={t('discovery:search_a_product')}
            contractFilterAttribute="buyers"
            navigationAttribute="family"
            clearAttributes={[
              'administrative_areas',
              'labels',
            ]}
            searchMode="PRODUCT"
            index={import.meta.env.REACT_APP_ALGOLIA_PACKAGING_INDEX}
          />
          <Grid container rowSpacing={2} columnSpacing={2} sx={{ pt: '1px' }}>
            {hits.map((hit) => (
              <Grid key={hit.objectID} item xs={12} sm={6} md={4} xl={3}>
                <DiscoveryProductHit hit={hit} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}
