import { useCallback, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';
import { FormatBaseUnitQuantity } from 'kheops-utils';
import { AddOrderPackaging } from '../common/components/AddOrderToBasketButton';
import { BasketPackagingQuantity, currentBasketQuantitiesAtom, isBasketDrawerOpenAtom } from '../basket/state/state';
import { RoutePaths } from '../routes/AppRoutes';
import { commonSnackbarPropsAtom } from '../common/state/state';
import { useOrderPacakgingsLazyQuery } from '../queries/__generated__/orderPackagings.generated';
import i18n from '../i18n';

interface OrderPackagings {
  packagings: AddOrderPackaging[];
  priceUpdatedPackagings: string[];
  unavailablePackagings: string[];
  contractReferenceId: string;
}

interface UseAddOrderToBasketProps {
  orderPackagings?: OrderPackagings;
  orderReferenceId?: string;
  onClick?: () => void;
}

interface AddOrderToBasket {
  addOrderToBasket: () => void;
  addedToBasket: boolean;
}

export function useAddOrderToBasket({ orderPackagings, orderReferenceId, onClick }: UseAddOrderToBasketProps): AddOrderToBasket {
  const { t } = useTranslation(['basket', 'common']);
  const matchShoppingPage = useMatch(RoutePaths.SEARCH_PRODUCT);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const setIsBasketDrawerOpen = useSetAtom(isBasketDrawerOpenAtom);
  const [basketPackagingQuantities, editBasketPackagingQuantity] = useAtom(currentBasketQuantitiesAtom);
  const [addedToBasket, setAddedToBasket] = useState(false);
  const [getOrderPackagings] = useOrderPacakgingsLazyQuery();
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  const addOrderToBasket = useCallback(async () => {
    let orderPackagingsData: OrderPackagings;

    if (orderPackagings) {
      // orderPAcakging object was already given as a param
      orderPackagingsData = orderPackagings;
    } else {
      // build orderPackaging object using given order reference.
      const { data } = await getOrderPackagings({
        variables: {
          orderReferenceId: orderReferenceId!,
        },
      });

      const unavailablePackagings: string[] = [];
      const priceUpdatedPackagings: string[] = [];
      const { contract, packagingQuantities } = data!.order[0];

      const packagings = packagingQuantities.reduce((acc, { quantity, packaging }) => {
        const currentPackaging = contract.supplying_company.packagings.find(({ sku }) => sku === packaging.sku);
        const packagingName = `${packaging.product!.name} - ${FormatBaseUnitQuantity(packaging.base_unit!, 'fr', numberFormat)}`;

        if (!currentPackaging) {
          unavailablePackagings.push(packagingName);
        } else {
          if (currentPackaging.price !== packaging.price) {
            priceUpdatedPackagings.push(packagingName);
          }

          acc.push({
            id: currentPackaging.id,
            quantity,
          });
        }

        return acc;
      }, [] as AddOrderPackaging[]);

      orderPackagingsData = {
        packagings,
        priceUpdatedPackagings,
        unavailablePackagings,
        contractReferenceId: contract.reference_id!,
      };
    }

    const { packagings, priceUpdatedPackagings, unavailablePackagings, contractReferenceId } = orderPackagingsData;
    const newBasketPackagingQuantities = packagings.reduce((acc, { quantity, id }) => {
      const packagingQuantityId = `${id}_${contractReferenceId}`;
      const oldBasketPackagingQuantity = basketPackagingQuantities.get(packagingQuantityId);

      acc.push({
        id: oldBasketPackagingQuantity?.id,
        packagingId: id,
        quantity: oldBasketPackagingQuantity ? oldBasketPackagingQuantity.quantity + quantity : quantity,
        contractId: contractReferenceId,
      });

      return acc;
    }, [] as BasketPackagingQuantity[]);

    if (unavailablePackagings.length || priceUpdatedPackagings.length) {
      let label: string;

      if (unavailablePackagings.length && priceUpdatedPackagings.length) {
        label = t('basket:some_products_have_changed_since_last_order');
      } else if (unavailablePackagings.length) {
        label = unavailablePackagings.length > 1 ? t('basket:some_products_are_unavailable') : t('basket:one_product_is_unavailable', { name: unavailablePackagings[0] });
      } else {
        label = priceUpdatedPackagings.length > 1 ? t('basket:prices_have_changed_since_last_order') : t('basket:one_price_has_changed_since_last_order', { name: priceUpdatedPackagings[0] });
      }

      setCommonSnackbarProps({
        label,
        snackbarProps: {
          open: true,
        },
        alertProps: {
          severity: 'warning',
          icon: <InfoOutlinedIcon />,
          sx: {
            color: 'white',
            '&.MuiPaper-root': {
              alignItems: 'center',
            },
          },
        },
      });
    }

    editBasketPackagingQuantity(...newBasketPackagingQuantities);

    if (!matchShoppingPage) {
      setIsBasketDrawerOpen(true);
    }

    setAddedToBasket(true);
    setTimeout((): void => {
      setAddedToBasket(false);
      onClick?.();
    }, 500);
  }, [orderPackagings, basketPackagingQuantities]);

  return {
    addOrderToBasket,
    addedToBasket,
  };
}
