import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { DiscoveryWordingsKeys } from '../discovery.utils';
import { currentContextAtom } from '../../state';
import FilterTag, { FilterTagSize, FilterTagVariant } from './FilterTag';
import FilterUtils from './filter.utils';
import useRefine from '../../hooks/useRefine';
import useDiscoveryDefaultFilters, { SearchMode } from '../../hooks/useDiscoveryDefaultFilters';

export interface ContractStatusFilterTagProps {
  attribute: string;
  variant: FilterTagVariant;
  size: FilterTagSize;
  searchMode?: SearchMode;
}

export default function ContractStatusFilterTag({ attribute, variant, size, searchMode }: ContractStatusFilterTagProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const { refine, uiState } = useRefine();
  const { companyId, realm, companyAddress } = useAtomValue(currentContextAtom);
  const defaultFilters = useDiscoveryDefaultFilters(searchMode);
  const signedContractFilter = FilterUtils.GetSignedContractFilter(attribute, companyId);
  const deliveryZoneFilter = FilterUtils.GetSupplierCanDeliverFilter(companyAddress!.administrativeAreaLevel2!);
  const [hasContractStatusFilter, setHasContractStatusFilter] = useState(false);
  const [hasDeliveryZoneFilter, setHasDeliveryZoneFilter] = useState(false);

  const defaultFiltersList = useMemo(() => {
    return defaultFilters ? [defaultFilters] : [];
  }, [defaultFilters]);

  const handleContractStatusTagDelete = useCallback((): void => {
    const filtersList = [...defaultFiltersList, signedContractFilter];

    if (hasDeliveryZoneFilter) {
      filtersList.push(deliveryZoneFilter);
    }

    const filters = filtersList.join(' AND ');

    refine({ filters });
  }, [refine, hasDeliveryZoneFilter, defaultFiltersList]);

  const handleDeliveryZoneTagDelete = useCallback((): void => {
    const filtersList = [...defaultFiltersList];

    if (!hasContractStatusFilter) {
      filtersList.push(signedContractFilter);
    }

    const filters = filtersList.join(' AND ');

    refine({ filters });
  }, [refine, hasContractStatusFilter, defaultFiltersList]);

  useEffect(() => {
    setHasContractStatusFilter(uiState.filters !== undefined && !uiState.filters.includes(signedContractFilter));
    setHasDeliveryZoneFilter(!!uiState.filters?.includes(deliveryZoneFilter));
  }, [uiState]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasContractStatusFilter && (
        <FilterTag
          chipProps={{
            icon: <PersonOutlinedIcon />,
            label: t(DiscoveryWordingsKeys[realm].contract_status_all_option),
            onDelete: handleContractStatusTagDelete,
          }}
          variant={variant}
          size={size}
        />
      )}
      {hasDeliveryZoneFilter && (
        <FilterTag
          chipProps={{
            icon: <PersonOutlinedIcon />,
            label: t(DiscoveryWordingsKeys[realm].delivery_zone_tag),
            onDelete: handleDeliveryZoneTagDelete,
          }}
          variant={variant}
          size={size}
        />
      )}
    </>
  );
}
