import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import { BusinessProfile, Document_Type_Enum, Order_Status_Enum, TaskAction } from 'kheops-graphql';
import { useEffect } from 'react';
import { InvoiceFormFields, InvoiceParseData } from './types';
import { orderAtom } from '../../state/state';
import InvoiceForm from './InvoiceForm';
import { useUpsertDocumentOfOrderMutation } from '../../../mutations/__generated__/upsertDocumentOfOrder.generated';
import { OrderByReferenceIdDocument } from '../../../queries/__generated__/orderByReferenceId.generated';
import { useErrorSnackbar } from '../../../hooks/useErrorSnackbar';
import { currentContextAtom } from '../../../state';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import useOrderActions from '../../../hooks/useOrderActions';

interface InvoiceCardProps {
  invoiceData: InvoiceParseData;
  closeDrawer: () => void;
  onClose: () => void;
}

export default function InvoiceCard({ invoiceData, onClose, closeDrawer }: InvoiceCardProps): React.JSX.Element {
  const { t } = useTranslation(['order', 'common', 'error']);
  const order = useAtomValue(orderAtom);
  const { updateOrder } = useOrderActions({ orderReferenceId: order.reference_id, orderId: order.id });
  const displayError = useErrorSnackbar(t('error:order_has_already_been_updated'));
  const { realm } = useAtomValue(currentContextAtom);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [upsertDocumentOfOrder, { data, client, loading }] = useUpsertDocumentOfOrderMutation(
    {
      refetchQueries: [OrderByReferenceIdDocument],
      awaitRefetchQueries: true,
      onError: () => {
        displayError();
        client.refetchQueries({ include: [OrderByReferenceIdDocument] });
      },
    },
  );
  const form = useForm<InvoiceFormFields>({
    defaultValues: {
      path: invoiceData.path,
      revisedReference: invoiceData.invoice_reference,
      domain: invoiceData.domain,
      previewUrl: invoiceData.preview_link,
      reference: invoiceData.invoice_reference,
      priceExclTax: invoiceData.total_price_excl_tax,
      revisedPriceExclTax: invoiceData.total_price_excl_tax,
      priceInclTax: invoiceData.total_price_incl_tax,
      revisedPriceInclTax: invoiceData.total_price_incl_tax,
    },
  });

  const hasDetectedDisparity = form.getValues('priceExclTax') !== order.total_price_excl_tax || form.getValues('priceInclTax') !== order.total_price_incl_tax;

  const onSubmit = (values: InvoiceFormFields): void => {
    upsertDocumentOfOrder({
      variables: {
        data: {
          path: values.path,
          domain: values.domain,
          type: Document_Type_Enum.Invoice,
          userBusinessProfile: realm as unknown as BusinessProfile,
          orderId: order.id,
          orderReferenceId: order.reference_id,
          invoice: {
            reference: values.reference,
            totalExclTax: Number(values.priceExclTax),
            totalInclTax: Number(values.priceInclTax),
            revisedReference: values.reference !== values.revisedReference ? values.revisedReference : undefined,
            revisedTotalExclTax: values.priceExclTax !== values.revisedPriceExclTax ? Number(values.revisedPriceExclTax) : undefined,
            revisedTotalInclTax: values.priceInclTax !== values.revisedPriceInclTax ? Number(values.revisedPriceInclTax) : undefined,
            comment: values.comment,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (data?.upsertDocumentOfOrder?.success) {
      if (order.status === Order_Status_Enum.OrderToBeBilledBySupplier) {
        updateOrder(TaskAction.Approve);
      }

      closeDrawer();
      setCommonSnackbarProps({
        label: t('order:invoice_added'),
        snackbarProps: {
          open: true,
        },
      });
    }
  }, [order]);

  return (
    <FormProvider {...form}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          borderRadius: 6,
          mx: 3,
          p: 3,
          backgroundColor: 'background.paper',
          width: {
            xs: '100%',
            sm: 600,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="h5">{t(hasDetectedDisparity ? 'order:invoice_verification_title_disparity' : 'order:invoice_verification_title')}</Typography>
          <Typography variant="body2">{t(hasDetectedDisparity ? 'order:invoice_verification_description_disparity' : 'order:invoice_verification_description')}</Typography>
        </Box>
        <InvoiceForm />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
              xs: 'column-reverse',
              lg: 'row',
            },
            gap: 2,
          }}
        >
          <Button
            color="secondary"
            sx={{
              flex: 1,
            }}
            onClick={onClose}
          >
            {t('common:cancel')}
          </Button>
          <LoadingButton
            sx={{
              flex: 1,
            }}
            loading={loading}
            variant="contained"
            onClick={form.handleSubmit(onSubmit)}
          >
            {t('order:add_invoice')}
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  );
}
