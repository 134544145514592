import { addDays, isSunday } from 'date-fns';

export default class DateUtils {
  static AddWorkingDaysToDate(date: Date, workingDays: number): Date {
    let dateToShift = date;
    for (let i = 1; i <= workingDays; i++) {
      dateToShift = addDays(dateToShift, 1);
      if (isSunday(dateToShift)) {
        dateToShift = (addDays(dateToShift, 1));
      }
    }
    return dateToShift;
  }
}
