export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: number; output: number; }
  citext: { input: string; output: string; }
  numeric: { input: number; output: number; }
  timestamp: { input: Date; output: Date; }
  timestamptz: { input: Date; output: Date; }
  uuid: { input: string; output: string; }
};

export type AcceptContractOfferOutput = {
  __typename?: 'AcceptContractOfferOutput';
  contractId: Scalars['uuid']['output'];
};

export enum ActionType {
  Approve = 'APPROVE',
  ApproveWithoutPreparation = 'APPROVE_WITHOUT_PREPARATION',
  ApproveWithPreparation = 'APPROVE_WITH_PREPARATION',
  Modify = 'MODIFY'
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export enum BusinessProfile {
  Buyer = 'BUYER',
  Supplier = 'SUPPLIER'
}

export type CreateContractRequestOutput = {
  __typename?: 'CreateContractRequestOutput';
  channelUrl: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  status: Scalars['String']['output'];
};

export type CreateGroupChannelOutput = {
  __typename?: 'CreateGroupChannelOutput';
  channelUrl: Scalars['String']['output'];
};

export type CustomOrderPackagingQuantities = {
  packagingId: Scalars['uuid']['input'];
  quantity: Scalars['Int']['input'];
};

export type CustomOrderPackagingQuantity = {
  adjustedWeight?: InputMaybe<Scalars['Float']['input']>;
  packagingId: Scalars['uuid']['input'];
  quantity: Scalars['Int']['input'];
};

export type CustomOrdersInsert = {
  comment?: InputMaybe<Scalars['String']['input']>;
  contractId: Scalars['uuid']['input'];
  deliveryDate?: InputMaybe<Scalars['timestamptz']['input']>;
  packagingQuantities: Array<CustomOrderPackagingQuantities>;
};

export type DeleteDocumentByIdFromOrderOutput = {
  __typename?: 'DeleteDocumentByIdFromOrderOutput';
  success: Scalars['Boolean']['output'];
};

export type GetDownloadUrlOutput = {
  __typename?: 'GetDownloadUrlOutput';
  downloadUrl: Scalars['String']['output'];
};

export type GetOrderRecommendationsOutput = {
  __typename?: 'GetOrderRecommendationsOutput';
  recommendations: Array<Maybe<OrderRecommendation>>;
};

export type GetUploadUrlOutput = {
  __typename?: 'GetUploadUrlOutput';
  uploadUrl: Scalars['String']['output'];
};

export type InsertOrdersActionOutput = {
  __typename?: 'InsertOrdersActionOutput';
  buyerPhotoDomain?: Maybe<Scalars['String']['output']>;
  buyerPhotoPath?: Maybe<Scalars['String']['output']>;
  buying_company_brand?: Maybe<Scalars['String']['output']>;
  buying_company_name: Scalars['String']['output'];
  deliveryDate?: Maybe<Scalars['timestamptz']['output']>;
  friendly_id: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  order_price: Scalars['Int']['output'];
  packagingCount: Scalars['Int']['output'];
  reference_id: Scalars['uuid']['output'];
  supplierPhotoDomain?: Maybe<Scalars['String']['output']>;
  supplierPhotoPath?: Maybe<Scalars['String']['output']>;
  supplying_company_name: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InvoiceData = {
  comment?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  revisedReference?: InputMaybe<Scalars['String']['input']>;
  revisedTotalExclTax?: InputMaybe<Scalars['Float']['input']>;
  revisedTotalInclTax?: InputMaybe<Scalars['Float']['input']>;
  totalExclTax?: InputMaybe<Scalars['Float']['input']>;
  totalInclTax?: InputMaybe<Scalars['Float']['input']>;
};

export type JoinGroupChannelOutput = {
  __typename?: 'JoinGroupChannelOutput';
  channelUrl: Scalars['String']['output'];
};

export type LoginV2Output = {
  __typename?: 'LoginV2Output';
  access_token: Scalars['String']['output'];
  expires_in: Scalars['Int']['output'];
  refresh_token: Scalars['String']['output'];
  sendbird_access_token?: Maybe<Scalars['String']['output']>;
  token_type: Scalars['String']['output'];
};

export type NextActions = {
  __typename?: 'NextActions';
  actions: Array<ActionType>;
};

export type OrderPackaging = {
  __typename?: 'OrderPackaging';
  id: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
};

export type OrderRecommendation = {
  __typename?: 'OrderRecommendation';
  availablePackagings: Array<Maybe<OrderPackaging>>;
  contractReferenceId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  orderCreatedAt: Scalars['timestamptz']['output'];
  priceUpdatedPackagings: Array<Scalars['String']['output']>;
  totalPriceExlTax: Scalars['Float']['output'];
  unavailablePackagings: Array<Scalars['String']['output']>;
};

export type PreLoginOutput = {
  __typename?: 'PreLoginOutput';
  is_success: Scalars['Boolean']['output'];
};

export type SendBuyerRepliedToSupplierChatOutput = {
  __typename?: 'SendBuyerRepliedToSupplierChatOutput';
  success: Scalars['Boolean']['output'];
};

export type StaffGoogleLoginOutput = {
  __typename?: 'StaffGoogleLoginOutput';
  access_token: Scalars['String']['output'];
  expires_in: Scalars['Int']['output'];
  refresh_token: Scalars['String']['output'];
  token_type: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export enum TaskAction {
  Approve = 'APPROVE',
  Cancel = 'CANCEL',
  Modify = 'MODIFY'
}

export type UpdateOrderActionOutput = {
  __typename?: 'UpdateOrderActionOutput';
  status: Scalars['String']['output'];
};

export type UpdatePaymentDateOutput = {
  __typename?: 'UpdatePaymentDateOutput';
  orderReferenceId: Scalars['String']['output'];
};

export type UpsertDocumentOfOrderInput = {
  domain: Scalars['String']['input'];
  invoice?: InputMaybe<InvoiceData>;
  orderId: Scalars['uuid']['input'];
  orderReferenceId: Scalars['uuid']['input'];
  path: Scalars['String']['input'];
  type: Scalars['String']['input'];
  userBusinessProfile: BusinessProfile;
};

export type UpsertDocumentOfOrderOutput = {
  __typename?: 'UpsertDocumentOfOrderOutput';
  success: Scalars['Boolean']['output'];
};

export type UserContactInformationOutput = {
  __typename?: 'UserContactInformationOutput';
  email?: Maybe<Scalars['String']['output']>;
  landlinePhoneNumber?: Maybe<Scalars['String']['output']>;
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "act_hi_procinst" */
export type Act_Hi_Procinst = {
  __typename?: 'act_hi_procinst';
  business_key_?: Maybe<Scalars['String']['output']>;
  business_status_?: Maybe<Scalars['String']['output']>;
  callback_id_?: Maybe<Scalars['String']['output']>;
  callback_type_?: Maybe<Scalars['String']['output']>;
  delete_reason_?: Maybe<Scalars['String']['output']>;
  duration_?: Maybe<Scalars['bigint']['output']>;
  end_act_id_?: Maybe<Scalars['String']['output']>;
  end_time_?: Maybe<Scalars['timestamp']['output']>;
  id_: Scalars['String']['output'];
  name_?: Maybe<Scalars['String']['output']>;
  proc_def_id_: Scalars['String']['output'];
  proc_inst_id_: Scalars['String']['output'];
  propagated_stage_inst_id_?: Maybe<Scalars['String']['output']>;
  reference_id_?: Maybe<Scalars['String']['output']>;
  reference_type_?: Maybe<Scalars['String']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
  start_act_id_?: Maybe<Scalars['String']['output']>;
  start_time_: Scalars['timestamp']['output'];
  start_user_id_?: Maybe<Scalars['String']['output']>;
  super_process_instance_id_?: Maybe<Scalars['String']['output']>;
  tenant_id_?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "act_hi_procinst" */
export type Act_Hi_Procinst_Aggregate = {
  __typename?: 'act_hi_procinst_aggregate';
  aggregate?: Maybe<Act_Hi_Procinst_Aggregate_Fields>;
  nodes: Array<Act_Hi_Procinst>;
};

/** aggregate fields of "act_hi_procinst" */
export type Act_Hi_Procinst_Aggregate_Fields = {
  __typename?: 'act_hi_procinst_aggregate_fields';
  avg?: Maybe<Act_Hi_Procinst_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Act_Hi_Procinst_Max_Fields>;
  min?: Maybe<Act_Hi_Procinst_Min_Fields>;
  stddev?: Maybe<Act_Hi_Procinst_Stddev_Fields>;
  stddev_pop?: Maybe<Act_Hi_Procinst_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Act_Hi_Procinst_Stddev_Samp_Fields>;
  sum?: Maybe<Act_Hi_Procinst_Sum_Fields>;
  var_pop?: Maybe<Act_Hi_Procinst_Var_Pop_Fields>;
  var_samp?: Maybe<Act_Hi_Procinst_Var_Samp_Fields>;
  variance?: Maybe<Act_Hi_Procinst_Variance_Fields>;
};


/** aggregate fields of "act_hi_procinst" */
export type Act_Hi_Procinst_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Act_Hi_Procinst_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Act_Hi_Procinst_Avg_Fields = {
  __typename?: 'act_hi_procinst_avg_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "act_hi_procinst". All fields are combined with a logical 'AND'. */
export type Act_Hi_Procinst_Bool_Exp = {
  _and?: InputMaybe<Array<Act_Hi_Procinst_Bool_Exp>>;
  _not?: InputMaybe<Act_Hi_Procinst_Bool_Exp>;
  _or?: InputMaybe<Array<Act_Hi_Procinst_Bool_Exp>>;
  business_key_?: InputMaybe<String_Comparison_Exp>;
  business_status_?: InputMaybe<String_Comparison_Exp>;
  callback_id_?: InputMaybe<String_Comparison_Exp>;
  callback_type_?: InputMaybe<String_Comparison_Exp>;
  delete_reason_?: InputMaybe<String_Comparison_Exp>;
  duration_?: InputMaybe<Bigint_Comparison_Exp>;
  end_act_id_?: InputMaybe<String_Comparison_Exp>;
  end_time_?: InputMaybe<Timestamp_Comparison_Exp>;
  id_?: InputMaybe<String_Comparison_Exp>;
  name_?: InputMaybe<String_Comparison_Exp>;
  proc_def_id_?: InputMaybe<String_Comparison_Exp>;
  proc_inst_id_?: InputMaybe<String_Comparison_Exp>;
  propagated_stage_inst_id_?: InputMaybe<String_Comparison_Exp>;
  reference_id_?: InputMaybe<String_Comparison_Exp>;
  reference_type_?: InputMaybe<String_Comparison_Exp>;
  rev_?: InputMaybe<Int_Comparison_Exp>;
  start_act_id_?: InputMaybe<String_Comparison_Exp>;
  start_time_?: InputMaybe<Timestamp_Comparison_Exp>;
  start_user_id_?: InputMaybe<String_Comparison_Exp>;
  super_process_instance_id_?: InputMaybe<String_Comparison_Exp>;
  tenant_id_?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "act_hi_procinst" */
export enum Act_Hi_Procinst_Constraint {
  /** unique or primary key constraint on columns "id_" */
  ActHiProcinstPkey = 'act_hi_procinst_pkey',
  /** unique or primary key constraint on columns "proc_inst_id_" */
  ActHiProcinstProcInstIdKey = 'act_hi_procinst_proc_inst_id__key'
}

/** input type for incrementing numeric columns in table "act_hi_procinst" */
export type Act_Hi_Procinst_Inc_Input = {
  duration_?: InputMaybe<Scalars['bigint']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "act_hi_procinst" */
export type Act_Hi_Procinst_Insert_Input = {
  business_key_?: InputMaybe<Scalars['String']['input']>;
  business_status_?: InputMaybe<Scalars['String']['input']>;
  callback_id_?: InputMaybe<Scalars['String']['input']>;
  callback_type_?: InputMaybe<Scalars['String']['input']>;
  delete_reason_?: InputMaybe<Scalars['String']['input']>;
  duration_?: InputMaybe<Scalars['bigint']['input']>;
  end_act_id_?: InputMaybe<Scalars['String']['input']>;
  end_time_?: InputMaybe<Scalars['timestamp']['input']>;
  id_?: InputMaybe<Scalars['String']['input']>;
  name_?: InputMaybe<Scalars['String']['input']>;
  proc_def_id_?: InputMaybe<Scalars['String']['input']>;
  proc_inst_id_?: InputMaybe<Scalars['String']['input']>;
  propagated_stage_inst_id_?: InputMaybe<Scalars['String']['input']>;
  reference_id_?: InputMaybe<Scalars['String']['input']>;
  reference_type_?: InputMaybe<Scalars['String']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
  start_act_id_?: InputMaybe<Scalars['String']['input']>;
  start_time_?: InputMaybe<Scalars['timestamp']['input']>;
  start_user_id_?: InputMaybe<Scalars['String']['input']>;
  super_process_instance_id_?: InputMaybe<Scalars['String']['input']>;
  tenant_id_?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Act_Hi_Procinst_Max_Fields = {
  __typename?: 'act_hi_procinst_max_fields';
  business_key_?: Maybe<Scalars['String']['output']>;
  business_status_?: Maybe<Scalars['String']['output']>;
  callback_id_?: Maybe<Scalars['String']['output']>;
  callback_type_?: Maybe<Scalars['String']['output']>;
  delete_reason_?: Maybe<Scalars['String']['output']>;
  duration_?: Maybe<Scalars['bigint']['output']>;
  end_act_id_?: Maybe<Scalars['String']['output']>;
  end_time_?: Maybe<Scalars['timestamp']['output']>;
  id_?: Maybe<Scalars['String']['output']>;
  name_?: Maybe<Scalars['String']['output']>;
  proc_def_id_?: Maybe<Scalars['String']['output']>;
  proc_inst_id_?: Maybe<Scalars['String']['output']>;
  propagated_stage_inst_id_?: Maybe<Scalars['String']['output']>;
  reference_id_?: Maybe<Scalars['String']['output']>;
  reference_type_?: Maybe<Scalars['String']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
  start_act_id_?: Maybe<Scalars['String']['output']>;
  start_time_?: Maybe<Scalars['timestamp']['output']>;
  start_user_id_?: Maybe<Scalars['String']['output']>;
  super_process_instance_id_?: Maybe<Scalars['String']['output']>;
  tenant_id_?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Act_Hi_Procinst_Min_Fields = {
  __typename?: 'act_hi_procinst_min_fields';
  business_key_?: Maybe<Scalars['String']['output']>;
  business_status_?: Maybe<Scalars['String']['output']>;
  callback_id_?: Maybe<Scalars['String']['output']>;
  callback_type_?: Maybe<Scalars['String']['output']>;
  delete_reason_?: Maybe<Scalars['String']['output']>;
  duration_?: Maybe<Scalars['bigint']['output']>;
  end_act_id_?: Maybe<Scalars['String']['output']>;
  end_time_?: Maybe<Scalars['timestamp']['output']>;
  id_?: Maybe<Scalars['String']['output']>;
  name_?: Maybe<Scalars['String']['output']>;
  proc_def_id_?: Maybe<Scalars['String']['output']>;
  proc_inst_id_?: Maybe<Scalars['String']['output']>;
  propagated_stage_inst_id_?: Maybe<Scalars['String']['output']>;
  reference_id_?: Maybe<Scalars['String']['output']>;
  reference_type_?: Maybe<Scalars['String']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
  start_act_id_?: Maybe<Scalars['String']['output']>;
  start_time_?: Maybe<Scalars['timestamp']['output']>;
  start_user_id_?: Maybe<Scalars['String']['output']>;
  super_process_instance_id_?: Maybe<Scalars['String']['output']>;
  tenant_id_?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "act_hi_procinst" */
export type Act_Hi_Procinst_Mutation_Response = {
  __typename?: 'act_hi_procinst_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Act_Hi_Procinst>;
};

/** on_conflict condition type for table "act_hi_procinst" */
export type Act_Hi_Procinst_On_Conflict = {
  constraint: Act_Hi_Procinst_Constraint;
  update_columns?: Array<Act_Hi_Procinst_Update_Column>;
  where?: InputMaybe<Act_Hi_Procinst_Bool_Exp>;
};

/** Ordering options when selecting data from "act_hi_procinst". */
export type Act_Hi_Procinst_Order_By = {
  business_key_?: InputMaybe<Order_By>;
  business_status_?: InputMaybe<Order_By>;
  callback_id_?: InputMaybe<Order_By>;
  callback_type_?: InputMaybe<Order_By>;
  delete_reason_?: InputMaybe<Order_By>;
  duration_?: InputMaybe<Order_By>;
  end_act_id_?: InputMaybe<Order_By>;
  end_time_?: InputMaybe<Order_By>;
  id_?: InputMaybe<Order_By>;
  name_?: InputMaybe<Order_By>;
  proc_def_id_?: InputMaybe<Order_By>;
  proc_inst_id_?: InputMaybe<Order_By>;
  propagated_stage_inst_id_?: InputMaybe<Order_By>;
  reference_id_?: InputMaybe<Order_By>;
  reference_type_?: InputMaybe<Order_By>;
  rev_?: InputMaybe<Order_By>;
  start_act_id_?: InputMaybe<Order_By>;
  start_time_?: InputMaybe<Order_By>;
  start_user_id_?: InputMaybe<Order_By>;
  super_process_instance_id_?: InputMaybe<Order_By>;
  tenant_id_?: InputMaybe<Order_By>;
};

/** primary key columns input for table: act_hi_procinst */
export type Act_Hi_Procinst_Pk_Columns_Input = {
  id_: Scalars['String']['input'];
};

/** select columns of table "act_hi_procinst" */
export enum Act_Hi_Procinst_Select_Column {
  /** column name */
  BusinessKey = 'business_key_',
  /** column name */
  BusinessStatus = 'business_status_',
  /** column name */
  CallbackId = 'callback_id_',
  /** column name */
  CallbackType = 'callback_type_',
  /** column name */
  DeleteReason = 'delete_reason_',
  /** column name */
  Duration = 'duration_',
  /** column name */
  EndActId = 'end_act_id_',
  /** column name */
  EndTime = 'end_time_',
  /** column name */
  Id = 'id_',
  /** column name */
  Name = 'name_',
  /** column name */
  ProcDefId = 'proc_def_id_',
  /** column name */
  ProcInstId = 'proc_inst_id_',
  /** column name */
  PropagatedStageInstId = 'propagated_stage_inst_id_',
  /** column name */
  ReferenceId = 'reference_id_',
  /** column name */
  ReferenceType = 'reference_type_',
  /** column name */
  Rev = 'rev_',
  /** column name */
  StartActId = 'start_act_id_',
  /** column name */
  StartTime = 'start_time_',
  /** column name */
  StartUserId = 'start_user_id_',
  /** column name */
  SuperProcessInstanceId = 'super_process_instance_id_',
  /** column name */
  TenantId = 'tenant_id_'
}

/** input type for updating data in table "act_hi_procinst" */
export type Act_Hi_Procinst_Set_Input = {
  business_key_?: InputMaybe<Scalars['String']['input']>;
  business_status_?: InputMaybe<Scalars['String']['input']>;
  callback_id_?: InputMaybe<Scalars['String']['input']>;
  callback_type_?: InputMaybe<Scalars['String']['input']>;
  delete_reason_?: InputMaybe<Scalars['String']['input']>;
  duration_?: InputMaybe<Scalars['bigint']['input']>;
  end_act_id_?: InputMaybe<Scalars['String']['input']>;
  end_time_?: InputMaybe<Scalars['timestamp']['input']>;
  id_?: InputMaybe<Scalars['String']['input']>;
  name_?: InputMaybe<Scalars['String']['input']>;
  proc_def_id_?: InputMaybe<Scalars['String']['input']>;
  proc_inst_id_?: InputMaybe<Scalars['String']['input']>;
  propagated_stage_inst_id_?: InputMaybe<Scalars['String']['input']>;
  reference_id_?: InputMaybe<Scalars['String']['input']>;
  reference_type_?: InputMaybe<Scalars['String']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
  start_act_id_?: InputMaybe<Scalars['String']['input']>;
  start_time_?: InputMaybe<Scalars['timestamp']['input']>;
  start_user_id_?: InputMaybe<Scalars['String']['input']>;
  super_process_instance_id_?: InputMaybe<Scalars['String']['input']>;
  tenant_id_?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Act_Hi_Procinst_Stddev_Fields = {
  __typename?: 'act_hi_procinst_stddev_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Act_Hi_Procinst_Stddev_Pop_Fields = {
  __typename?: 'act_hi_procinst_stddev_pop_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Act_Hi_Procinst_Stddev_Samp_Fields = {
  __typename?: 'act_hi_procinst_stddev_samp_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "act_hi_procinst" */
export type Act_Hi_Procinst_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Act_Hi_Procinst_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Act_Hi_Procinst_Stream_Cursor_Value_Input = {
  business_key_?: InputMaybe<Scalars['String']['input']>;
  business_status_?: InputMaybe<Scalars['String']['input']>;
  callback_id_?: InputMaybe<Scalars['String']['input']>;
  callback_type_?: InputMaybe<Scalars['String']['input']>;
  delete_reason_?: InputMaybe<Scalars['String']['input']>;
  duration_?: InputMaybe<Scalars['bigint']['input']>;
  end_act_id_?: InputMaybe<Scalars['String']['input']>;
  end_time_?: InputMaybe<Scalars['timestamp']['input']>;
  id_?: InputMaybe<Scalars['String']['input']>;
  name_?: InputMaybe<Scalars['String']['input']>;
  proc_def_id_?: InputMaybe<Scalars['String']['input']>;
  proc_inst_id_?: InputMaybe<Scalars['String']['input']>;
  propagated_stage_inst_id_?: InputMaybe<Scalars['String']['input']>;
  reference_id_?: InputMaybe<Scalars['String']['input']>;
  reference_type_?: InputMaybe<Scalars['String']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
  start_act_id_?: InputMaybe<Scalars['String']['input']>;
  start_time_?: InputMaybe<Scalars['timestamp']['input']>;
  start_user_id_?: InputMaybe<Scalars['String']['input']>;
  super_process_instance_id_?: InputMaybe<Scalars['String']['input']>;
  tenant_id_?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Act_Hi_Procinst_Sum_Fields = {
  __typename?: 'act_hi_procinst_sum_fields';
  duration_?: Maybe<Scalars['bigint']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "act_hi_procinst" */
export enum Act_Hi_Procinst_Update_Column {
  /** column name */
  BusinessKey = 'business_key_',
  /** column name */
  BusinessStatus = 'business_status_',
  /** column name */
  CallbackId = 'callback_id_',
  /** column name */
  CallbackType = 'callback_type_',
  /** column name */
  DeleteReason = 'delete_reason_',
  /** column name */
  Duration = 'duration_',
  /** column name */
  EndActId = 'end_act_id_',
  /** column name */
  EndTime = 'end_time_',
  /** column name */
  Id = 'id_',
  /** column name */
  Name = 'name_',
  /** column name */
  ProcDefId = 'proc_def_id_',
  /** column name */
  ProcInstId = 'proc_inst_id_',
  /** column name */
  PropagatedStageInstId = 'propagated_stage_inst_id_',
  /** column name */
  ReferenceId = 'reference_id_',
  /** column name */
  ReferenceType = 'reference_type_',
  /** column name */
  Rev = 'rev_',
  /** column name */
  StartActId = 'start_act_id_',
  /** column name */
  StartTime = 'start_time_',
  /** column name */
  StartUserId = 'start_user_id_',
  /** column name */
  SuperProcessInstanceId = 'super_process_instance_id_',
  /** column name */
  TenantId = 'tenant_id_'
}

export type Act_Hi_Procinst_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Act_Hi_Procinst_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Act_Hi_Procinst_Set_Input>;
  /** filter the rows which have to be updated */
  where: Act_Hi_Procinst_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Act_Hi_Procinst_Var_Pop_Fields = {
  __typename?: 'act_hi_procinst_var_pop_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Act_Hi_Procinst_Var_Samp_Fields = {
  __typename?: 'act_hi_procinst_var_samp_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Act_Hi_Procinst_Variance_Fields = {
  __typename?: 'act_hi_procinst_variance_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "act_hi_taskinst" */
export type Act_Hi_Taskinst = {
  __typename?: 'act_hi_taskinst';
  assignee_?: Maybe<Scalars['String']['output']>;
  category_?: Maybe<Scalars['String']['output']>;
  claim_time_?: Maybe<Scalars['timestamp']['output']>;
  claimed_by_?: Maybe<Scalars['String']['output']>;
  completed_by_?: Maybe<Scalars['String']['output']>;
  delete_reason_?: Maybe<Scalars['String']['output']>;
  description_?: Maybe<Scalars['String']['output']>;
  due_date_?: Maybe<Scalars['timestamp']['output']>;
  duration_?: Maybe<Scalars['bigint']['output']>;
  end_time_?: Maybe<Scalars['timestamp']['output']>;
  execution_id_?: Maybe<Scalars['String']['output']>;
  form_key_?: Maybe<Scalars['String']['output']>;
  id_: Scalars['String']['output'];
  in_progress_due_date_?: Maybe<Scalars['timestamp']['output']>;
  in_progress_started_by_?: Maybe<Scalars['String']['output']>;
  in_progress_time_?: Maybe<Scalars['timestamp']['output']>;
  last_updated_time_?: Maybe<Scalars['timestamp']['output']>;
  name_?: Maybe<Scalars['String']['output']>;
  owner_?: Maybe<Scalars['String']['output']>;
  parent_task_id_?: Maybe<Scalars['String']['output']>;
  priority_?: Maybe<Scalars['Int']['output']>;
  proc_def_id_?: Maybe<Scalars['String']['output']>;
  proc_inst_id_?: Maybe<Scalars['String']['output']>;
  propagated_stage_inst_id_?: Maybe<Scalars['String']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
  scope_definition_id_?: Maybe<Scalars['String']['output']>;
  scope_id_?: Maybe<Scalars['String']['output']>;
  scope_type_?: Maybe<Scalars['String']['output']>;
  start_time_: Scalars['timestamp']['output'];
  state_?: Maybe<Scalars['String']['output']>;
  sub_scope_id_?: Maybe<Scalars['String']['output']>;
  suspended_by_?: Maybe<Scalars['String']['output']>;
  suspended_time_?: Maybe<Scalars['timestamp']['output']>;
  task_def_id_?: Maybe<Scalars['String']['output']>;
  task_def_key_?: Maybe<Scalars['String']['output']>;
  tenant_id_?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "act_hi_taskinst" */
export type Act_Hi_Taskinst_Aggregate = {
  __typename?: 'act_hi_taskinst_aggregate';
  aggregate?: Maybe<Act_Hi_Taskinst_Aggregate_Fields>;
  nodes: Array<Act_Hi_Taskinst>;
};

/** aggregate fields of "act_hi_taskinst" */
export type Act_Hi_Taskinst_Aggregate_Fields = {
  __typename?: 'act_hi_taskinst_aggregate_fields';
  avg?: Maybe<Act_Hi_Taskinst_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Act_Hi_Taskinst_Max_Fields>;
  min?: Maybe<Act_Hi_Taskinst_Min_Fields>;
  stddev?: Maybe<Act_Hi_Taskinst_Stddev_Fields>;
  stddev_pop?: Maybe<Act_Hi_Taskinst_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Act_Hi_Taskinst_Stddev_Samp_Fields>;
  sum?: Maybe<Act_Hi_Taskinst_Sum_Fields>;
  var_pop?: Maybe<Act_Hi_Taskinst_Var_Pop_Fields>;
  var_samp?: Maybe<Act_Hi_Taskinst_Var_Samp_Fields>;
  variance?: Maybe<Act_Hi_Taskinst_Variance_Fields>;
};


/** aggregate fields of "act_hi_taskinst" */
export type Act_Hi_Taskinst_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Act_Hi_Taskinst_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Act_Hi_Taskinst_Avg_Fields = {
  __typename?: 'act_hi_taskinst_avg_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  priority_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "act_hi_taskinst". All fields are combined with a logical 'AND'. */
export type Act_Hi_Taskinst_Bool_Exp = {
  _and?: InputMaybe<Array<Act_Hi_Taskinst_Bool_Exp>>;
  _not?: InputMaybe<Act_Hi_Taskinst_Bool_Exp>;
  _or?: InputMaybe<Array<Act_Hi_Taskinst_Bool_Exp>>;
  assignee_?: InputMaybe<String_Comparison_Exp>;
  category_?: InputMaybe<String_Comparison_Exp>;
  claim_time_?: InputMaybe<Timestamp_Comparison_Exp>;
  claimed_by_?: InputMaybe<String_Comparison_Exp>;
  completed_by_?: InputMaybe<String_Comparison_Exp>;
  delete_reason_?: InputMaybe<String_Comparison_Exp>;
  description_?: InputMaybe<String_Comparison_Exp>;
  due_date_?: InputMaybe<Timestamp_Comparison_Exp>;
  duration_?: InputMaybe<Bigint_Comparison_Exp>;
  end_time_?: InputMaybe<Timestamp_Comparison_Exp>;
  execution_id_?: InputMaybe<String_Comparison_Exp>;
  form_key_?: InputMaybe<String_Comparison_Exp>;
  id_?: InputMaybe<String_Comparison_Exp>;
  in_progress_due_date_?: InputMaybe<Timestamp_Comparison_Exp>;
  in_progress_started_by_?: InputMaybe<String_Comparison_Exp>;
  in_progress_time_?: InputMaybe<Timestamp_Comparison_Exp>;
  last_updated_time_?: InputMaybe<Timestamp_Comparison_Exp>;
  name_?: InputMaybe<String_Comparison_Exp>;
  owner_?: InputMaybe<String_Comparison_Exp>;
  parent_task_id_?: InputMaybe<String_Comparison_Exp>;
  priority_?: InputMaybe<Int_Comparison_Exp>;
  proc_def_id_?: InputMaybe<String_Comparison_Exp>;
  proc_inst_id_?: InputMaybe<String_Comparison_Exp>;
  propagated_stage_inst_id_?: InputMaybe<String_Comparison_Exp>;
  rev_?: InputMaybe<Int_Comparison_Exp>;
  scope_definition_id_?: InputMaybe<String_Comparison_Exp>;
  scope_id_?: InputMaybe<String_Comparison_Exp>;
  scope_type_?: InputMaybe<String_Comparison_Exp>;
  start_time_?: InputMaybe<Timestamp_Comparison_Exp>;
  state_?: InputMaybe<String_Comparison_Exp>;
  sub_scope_id_?: InputMaybe<String_Comparison_Exp>;
  suspended_by_?: InputMaybe<String_Comparison_Exp>;
  suspended_time_?: InputMaybe<Timestamp_Comparison_Exp>;
  task_def_id_?: InputMaybe<String_Comparison_Exp>;
  task_def_key_?: InputMaybe<String_Comparison_Exp>;
  tenant_id_?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "act_hi_taskinst" */
export enum Act_Hi_Taskinst_Constraint {
  /** unique or primary key constraint on columns "id_" */
  ActHiTaskinstPkey = 'act_hi_taskinst_pkey'
}

/** input type for incrementing numeric columns in table "act_hi_taskinst" */
export type Act_Hi_Taskinst_Inc_Input = {
  duration_?: InputMaybe<Scalars['bigint']['input']>;
  priority_?: InputMaybe<Scalars['Int']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "act_hi_taskinst" */
export type Act_Hi_Taskinst_Insert_Input = {
  assignee_?: InputMaybe<Scalars['String']['input']>;
  category_?: InputMaybe<Scalars['String']['input']>;
  claim_time_?: InputMaybe<Scalars['timestamp']['input']>;
  claimed_by_?: InputMaybe<Scalars['String']['input']>;
  completed_by_?: InputMaybe<Scalars['String']['input']>;
  delete_reason_?: InputMaybe<Scalars['String']['input']>;
  description_?: InputMaybe<Scalars['String']['input']>;
  due_date_?: InputMaybe<Scalars['timestamp']['input']>;
  duration_?: InputMaybe<Scalars['bigint']['input']>;
  end_time_?: InputMaybe<Scalars['timestamp']['input']>;
  execution_id_?: InputMaybe<Scalars['String']['input']>;
  form_key_?: InputMaybe<Scalars['String']['input']>;
  id_?: InputMaybe<Scalars['String']['input']>;
  in_progress_due_date_?: InputMaybe<Scalars['timestamp']['input']>;
  in_progress_started_by_?: InputMaybe<Scalars['String']['input']>;
  in_progress_time_?: InputMaybe<Scalars['timestamp']['input']>;
  last_updated_time_?: InputMaybe<Scalars['timestamp']['input']>;
  name_?: InputMaybe<Scalars['String']['input']>;
  owner_?: InputMaybe<Scalars['String']['input']>;
  parent_task_id_?: InputMaybe<Scalars['String']['input']>;
  priority_?: InputMaybe<Scalars['Int']['input']>;
  proc_def_id_?: InputMaybe<Scalars['String']['input']>;
  proc_inst_id_?: InputMaybe<Scalars['String']['input']>;
  propagated_stage_inst_id_?: InputMaybe<Scalars['String']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
  scope_definition_id_?: InputMaybe<Scalars['String']['input']>;
  scope_id_?: InputMaybe<Scalars['String']['input']>;
  scope_type_?: InputMaybe<Scalars['String']['input']>;
  start_time_?: InputMaybe<Scalars['timestamp']['input']>;
  state_?: InputMaybe<Scalars['String']['input']>;
  sub_scope_id_?: InputMaybe<Scalars['String']['input']>;
  suspended_by_?: InputMaybe<Scalars['String']['input']>;
  suspended_time_?: InputMaybe<Scalars['timestamp']['input']>;
  task_def_id_?: InputMaybe<Scalars['String']['input']>;
  task_def_key_?: InputMaybe<Scalars['String']['input']>;
  tenant_id_?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Act_Hi_Taskinst_Max_Fields = {
  __typename?: 'act_hi_taskinst_max_fields';
  assignee_?: Maybe<Scalars['String']['output']>;
  category_?: Maybe<Scalars['String']['output']>;
  claim_time_?: Maybe<Scalars['timestamp']['output']>;
  claimed_by_?: Maybe<Scalars['String']['output']>;
  completed_by_?: Maybe<Scalars['String']['output']>;
  delete_reason_?: Maybe<Scalars['String']['output']>;
  description_?: Maybe<Scalars['String']['output']>;
  due_date_?: Maybe<Scalars['timestamp']['output']>;
  duration_?: Maybe<Scalars['bigint']['output']>;
  end_time_?: Maybe<Scalars['timestamp']['output']>;
  execution_id_?: Maybe<Scalars['String']['output']>;
  form_key_?: Maybe<Scalars['String']['output']>;
  id_?: Maybe<Scalars['String']['output']>;
  in_progress_due_date_?: Maybe<Scalars['timestamp']['output']>;
  in_progress_started_by_?: Maybe<Scalars['String']['output']>;
  in_progress_time_?: Maybe<Scalars['timestamp']['output']>;
  last_updated_time_?: Maybe<Scalars['timestamp']['output']>;
  name_?: Maybe<Scalars['String']['output']>;
  owner_?: Maybe<Scalars['String']['output']>;
  parent_task_id_?: Maybe<Scalars['String']['output']>;
  priority_?: Maybe<Scalars['Int']['output']>;
  proc_def_id_?: Maybe<Scalars['String']['output']>;
  proc_inst_id_?: Maybe<Scalars['String']['output']>;
  propagated_stage_inst_id_?: Maybe<Scalars['String']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
  scope_definition_id_?: Maybe<Scalars['String']['output']>;
  scope_id_?: Maybe<Scalars['String']['output']>;
  scope_type_?: Maybe<Scalars['String']['output']>;
  start_time_?: Maybe<Scalars['timestamp']['output']>;
  state_?: Maybe<Scalars['String']['output']>;
  sub_scope_id_?: Maybe<Scalars['String']['output']>;
  suspended_by_?: Maybe<Scalars['String']['output']>;
  suspended_time_?: Maybe<Scalars['timestamp']['output']>;
  task_def_id_?: Maybe<Scalars['String']['output']>;
  task_def_key_?: Maybe<Scalars['String']['output']>;
  tenant_id_?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Act_Hi_Taskinst_Min_Fields = {
  __typename?: 'act_hi_taskinst_min_fields';
  assignee_?: Maybe<Scalars['String']['output']>;
  category_?: Maybe<Scalars['String']['output']>;
  claim_time_?: Maybe<Scalars['timestamp']['output']>;
  claimed_by_?: Maybe<Scalars['String']['output']>;
  completed_by_?: Maybe<Scalars['String']['output']>;
  delete_reason_?: Maybe<Scalars['String']['output']>;
  description_?: Maybe<Scalars['String']['output']>;
  due_date_?: Maybe<Scalars['timestamp']['output']>;
  duration_?: Maybe<Scalars['bigint']['output']>;
  end_time_?: Maybe<Scalars['timestamp']['output']>;
  execution_id_?: Maybe<Scalars['String']['output']>;
  form_key_?: Maybe<Scalars['String']['output']>;
  id_?: Maybe<Scalars['String']['output']>;
  in_progress_due_date_?: Maybe<Scalars['timestamp']['output']>;
  in_progress_started_by_?: Maybe<Scalars['String']['output']>;
  in_progress_time_?: Maybe<Scalars['timestamp']['output']>;
  last_updated_time_?: Maybe<Scalars['timestamp']['output']>;
  name_?: Maybe<Scalars['String']['output']>;
  owner_?: Maybe<Scalars['String']['output']>;
  parent_task_id_?: Maybe<Scalars['String']['output']>;
  priority_?: Maybe<Scalars['Int']['output']>;
  proc_def_id_?: Maybe<Scalars['String']['output']>;
  proc_inst_id_?: Maybe<Scalars['String']['output']>;
  propagated_stage_inst_id_?: Maybe<Scalars['String']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
  scope_definition_id_?: Maybe<Scalars['String']['output']>;
  scope_id_?: Maybe<Scalars['String']['output']>;
  scope_type_?: Maybe<Scalars['String']['output']>;
  start_time_?: Maybe<Scalars['timestamp']['output']>;
  state_?: Maybe<Scalars['String']['output']>;
  sub_scope_id_?: Maybe<Scalars['String']['output']>;
  suspended_by_?: Maybe<Scalars['String']['output']>;
  suspended_time_?: Maybe<Scalars['timestamp']['output']>;
  task_def_id_?: Maybe<Scalars['String']['output']>;
  task_def_key_?: Maybe<Scalars['String']['output']>;
  tenant_id_?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "act_hi_taskinst" */
export type Act_Hi_Taskinst_Mutation_Response = {
  __typename?: 'act_hi_taskinst_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Act_Hi_Taskinst>;
};

/** on_conflict condition type for table "act_hi_taskinst" */
export type Act_Hi_Taskinst_On_Conflict = {
  constraint: Act_Hi_Taskinst_Constraint;
  update_columns?: Array<Act_Hi_Taskinst_Update_Column>;
  where?: InputMaybe<Act_Hi_Taskinst_Bool_Exp>;
};

/** Ordering options when selecting data from "act_hi_taskinst". */
export type Act_Hi_Taskinst_Order_By = {
  assignee_?: InputMaybe<Order_By>;
  category_?: InputMaybe<Order_By>;
  claim_time_?: InputMaybe<Order_By>;
  claimed_by_?: InputMaybe<Order_By>;
  completed_by_?: InputMaybe<Order_By>;
  delete_reason_?: InputMaybe<Order_By>;
  description_?: InputMaybe<Order_By>;
  due_date_?: InputMaybe<Order_By>;
  duration_?: InputMaybe<Order_By>;
  end_time_?: InputMaybe<Order_By>;
  execution_id_?: InputMaybe<Order_By>;
  form_key_?: InputMaybe<Order_By>;
  id_?: InputMaybe<Order_By>;
  in_progress_due_date_?: InputMaybe<Order_By>;
  in_progress_started_by_?: InputMaybe<Order_By>;
  in_progress_time_?: InputMaybe<Order_By>;
  last_updated_time_?: InputMaybe<Order_By>;
  name_?: InputMaybe<Order_By>;
  owner_?: InputMaybe<Order_By>;
  parent_task_id_?: InputMaybe<Order_By>;
  priority_?: InputMaybe<Order_By>;
  proc_def_id_?: InputMaybe<Order_By>;
  proc_inst_id_?: InputMaybe<Order_By>;
  propagated_stage_inst_id_?: InputMaybe<Order_By>;
  rev_?: InputMaybe<Order_By>;
  scope_definition_id_?: InputMaybe<Order_By>;
  scope_id_?: InputMaybe<Order_By>;
  scope_type_?: InputMaybe<Order_By>;
  start_time_?: InputMaybe<Order_By>;
  state_?: InputMaybe<Order_By>;
  sub_scope_id_?: InputMaybe<Order_By>;
  suspended_by_?: InputMaybe<Order_By>;
  suspended_time_?: InputMaybe<Order_By>;
  task_def_id_?: InputMaybe<Order_By>;
  task_def_key_?: InputMaybe<Order_By>;
  tenant_id_?: InputMaybe<Order_By>;
};

/** primary key columns input for table: act_hi_taskinst */
export type Act_Hi_Taskinst_Pk_Columns_Input = {
  id_: Scalars['String']['input'];
};

/** select columns of table "act_hi_taskinst" */
export enum Act_Hi_Taskinst_Select_Column {
  /** column name */
  Assignee = 'assignee_',
  /** column name */
  Category = 'category_',
  /** column name */
  ClaimTime = 'claim_time_',
  /** column name */
  ClaimedBy = 'claimed_by_',
  /** column name */
  CompletedBy = 'completed_by_',
  /** column name */
  DeleteReason = 'delete_reason_',
  /** column name */
  Description = 'description_',
  /** column name */
  DueDate = 'due_date_',
  /** column name */
  Duration = 'duration_',
  /** column name */
  EndTime = 'end_time_',
  /** column name */
  ExecutionId = 'execution_id_',
  /** column name */
  FormKey = 'form_key_',
  /** column name */
  Id = 'id_',
  /** column name */
  InProgressDueDate = 'in_progress_due_date_',
  /** column name */
  InProgressStartedBy = 'in_progress_started_by_',
  /** column name */
  InProgressTime = 'in_progress_time_',
  /** column name */
  LastUpdatedTime = 'last_updated_time_',
  /** column name */
  Name = 'name_',
  /** column name */
  Owner = 'owner_',
  /** column name */
  ParentTaskId = 'parent_task_id_',
  /** column name */
  Priority = 'priority_',
  /** column name */
  ProcDefId = 'proc_def_id_',
  /** column name */
  ProcInstId = 'proc_inst_id_',
  /** column name */
  PropagatedStageInstId = 'propagated_stage_inst_id_',
  /** column name */
  Rev = 'rev_',
  /** column name */
  ScopeDefinitionId = 'scope_definition_id_',
  /** column name */
  ScopeId = 'scope_id_',
  /** column name */
  ScopeType = 'scope_type_',
  /** column name */
  StartTime = 'start_time_',
  /** column name */
  State = 'state_',
  /** column name */
  SubScopeId = 'sub_scope_id_',
  /** column name */
  SuspendedBy = 'suspended_by_',
  /** column name */
  SuspendedTime = 'suspended_time_',
  /** column name */
  TaskDefId = 'task_def_id_',
  /** column name */
  TaskDefKey = 'task_def_key_',
  /** column name */
  TenantId = 'tenant_id_'
}

/** input type for updating data in table "act_hi_taskinst" */
export type Act_Hi_Taskinst_Set_Input = {
  assignee_?: InputMaybe<Scalars['String']['input']>;
  category_?: InputMaybe<Scalars['String']['input']>;
  claim_time_?: InputMaybe<Scalars['timestamp']['input']>;
  claimed_by_?: InputMaybe<Scalars['String']['input']>;
  completed_by_?: InputMaybe<Scalars['String']['input']>;
  delete_reason_?: InputMaybe<Scalars['String']['input']>;
  description_?: InputMaybe<Scalars['String']['input']>;
  due_date_?: InputMaybe<Scalars['timestamp']['input']>;
  duration_?: InputMaybe<Scalars['bigint']['input']>;
  end_time_?: InputMaybe<Scalars['timestamp']['input']>;
  execution_id_?: InputMaybe<Scalars['String']['input']>;
  form_key_?: InputMaybe<Scalars['String']['input']>;
  id_?: InputMaybe<Scalars['String']['input']>;
  in_progress_due_date_?: InputMaybe<Scalars['timestamp']['input']>;
  in_progress_started_by_?: InputMaybe<Scalars['String']['input']>;
  in_progress_time_?: InputMaybe<Scalars['timestamp']['input']>;
  last_updated_time_?: InputMaybe<Scalars['timestamp']['input']>;
  name_?: InputMaybe<Scalars['String']['input']>;
  owner_?: InputMaybe<Scalars['String']['input']>;
  parent_task_id_?: InputMaybe<Scalars['String']['input']>;
  priority_?: InputMaybe<Scalars['Int']['input']>;
  proc_def_id_?: InputMaybe<Scalars['String']['input']>;
  proc_inst_id_?: InputMaybe<Scalars['String']['input']>;
  propagated_stage_inst_id_?: InputMaybe<Scalars['String']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
  scope_definition_id_?: InputMaybe<Scalars['String']['input']>;
  scope_id_?: InputMaybe<Scalars['String']['input']>;
  scope_type_?: InputMaybe<Scalars['String']['input']>;
  start_time_?: InputMaybe<Scalars['timestamp']['input']>;
  state_?: InputMaybe<Scalars['String']['input']>;
  sub_scope_id_?: InputMaybe<Scalars['String']['input']>;
  suspended_by_?: InputMaybe<Scalars['String']['input']>;
  suspended_time_?: InputMaybe<Scalars['timestamp']['input']>;
  task_def_id_?: InputMaybe<Scalars['String']['input']>;
  task_def_key_?: InputMaybe<Scalars['String']['input']>;
  tenant_id_?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Act_Hi_Taskinst_Stddev_Fields = {
  __typename?: 'act_hi_taskinst_stddev_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  priority_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Act_Hi_Taskinst_Stddev_Pop_Fields = {
  __typename?: 'act_hi_taskinst_stddev_pop_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  priority_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Act_Hi_Taskinst_Stddev_Samp_Fields = {
  __typename?: 'act_hi_taskinst_stddev_samp_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  priority_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "act_hi_taskinst" */
export type Act_Hi_Taskinst_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Act_Hi_Taskinst_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Act_Hi_Taskinst_Stream_Cursor_Value_Input = {
  assignee_?: InputMaybe<Scalars['String']['input']>;
  category_?: InputMaybe<Scalars['String']['input']>;
  claim_time_?: InputMaybe<Scalars['timestamp']['input']>;
  claimed_by_?: InputMaybe<Scalars['String']['input']>;
  completed_by_?: InputMaybe<Scalars['String']['input']>;
  delete_reason_?: InputMaybe<Scalars['String']['input']>;
  description_?: InputMaybe<Scalars['String']['input']>;
  due_date_?: InputMaybe<Scalars['timestamp']['input']>;
  duration_?: InputMaybe<Scalars['bigint']['input']>;
  end_time_?: InputMaybe<Scalars['timestamp']['input']>;
  execution_id_?: InputMaybe<Scalars['String']['input']>;
  form_key_?: InputMaybe<Scalars['String']['input']>;
  id_?: InputMaybe<Scalars['String']['input']>;
  in_progress_due_date_?: InputMaybe<Scalars['timestamp']['input']>;
  in_progress_started_by_?: InputMaybe<Scalars['String']['input']>;
  in_progress_time_?: InputMaybe<Scalars['timestamp']['input']>;
  last_updated_time_?: InputMaybe<Scalars['timestamp']['input']>;
  name_?: InputMaybe<Scalars['String']['input']>;
  owner_?: InputMaybe<Scalars['String']['input']>;
  parent_task_id_?: InputMaybe<Scalars['String']['input']>;
  priority_?: InputMaybe<Scalars['Int']['input']>;
  proc_def_id_?: InputMaybe<Scalars['String']['input']>;
  proc_inst_id_?: InputMaybe<Scalars['String']['input']>;
  propagated_stage_inst_id_?: InputMaybe<Scalars['String']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
  scope_definition_id_?: InputMaybe<Scalars['String']['input']>;
  scope_id_?: InputMaybe<Scalars['String']['input']>;
  scope_type_?: InputMaybe<Scalars['String']['input']>;
  start_time_?: InputMaybe<Scalars['timestamp']['input']>;
  state_?: InputMaybe<Scalars['String']['input']>;
  sub_scope_id_?: InputMaybe<Scalars['String']['input']>;
  suspended_by_?: InputMaybe<Scalars['String']['input']>;
  suspended_time_?: InputMaybe<Scalars['timestamp']['input']>;
  task_def_id_?: InputMaybe<Scalars['String']['input']>;
  task_def_key_?: InputMaybe<Scalars['String']['input']>;
  tenant_id_?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Act_Hi_Taskinst_Sum_Fields = {
  __typename?: 'act_hi_taskinst_sum_fields';
  duration_?: Maybe<Scalars['bigint']['output']>;
  priority_?: Maybe<Scalars['Int']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "act_hi_taskinst" */
export enum Act_Hi_Taskinst_Update_Column {
  /** column name */
  Assignee = 'assignee_',
  /** column name */
  Category = 'category_',
  /** column name */
  ClaimTime = 'claim_time_',
  /** column name */
  ClaimedBy = 'claimed_by_',
  /** column name */
  CompletedBy = 'completed_by_',
  /** column name */
  DeleteReason = 'delete_reason_',
  /** column name */
  Description = 'description_',
  /** column name */
  DueDate = 'due_date_',
  /** column name */
  Duration = 'duration_',
  /** column name */
  EndTime = 'end_time_',
  /** column name */
  ExecutionId = 'execution_id_',
  /** column name */
  FormKey = 'form_key_',
  /** column name */
  Id = 'id_',
  /** column name */
  InProgressDueDate = 'in_progress_due_date_',
  /** column name */
  InProgressStartedBy = 'in_progress_started_by_',
  /** column name */
  InProgressTime = 'in_progress_time_',
  /** column name */
  LastUpdatedTime = 'last_updated_time_',
  /** column name */
  Name = 'name_',
  /** column name */
  Owner = 'owner_',
  /** column name */
  ParentTaskId = 'parent_task_id_',
  /** column name */
  Priority = 'priority_',
  /** column name */
  ProcDefId = 'proc_def_id_',
  /** column name */
  ProcInstId = 'proc_inst_id_',
  /** column name */
  PropagatedStageInstId = 'propagated_stage_inst_id_',
  /** column name */
  Rev = 'rev_',
  /** column name */
  ScopeDefinitionId = 'scope_definition_id_',
  /** column name */
  ScopeId = 'scope_id_',
  /** column name */
  ScopeType = 'scope_type_',
  /** column name */
  StartTime = 'start_time_',
  /** column name */
  State = 'state_',
  /** column name */
  SubScopeId = 'sub_scope_id_',
  /** column name */
  SuspendedBy = 'suspended_by_',
  /** column name */
  SuspendedTime = 'suspended_time_',
  /** column name */
  TaskDefId = 'task_def_id_',
  /** column name */
  TaskDefKey = 'task_def_key_',
  /** column name */
  TenantId = 'tenant_id_'
}

export type Act_Hi_Taskinst_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Act_Hi_Taskinst_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Act_Hi_Taskinst_Set_Input>;
  /** filter the rows which have to be updated */
  where: Act_Hi_Taskinst_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Act_Hi_Taskinst_Var_Pop_Fields = {
  __typename?: 'act_hi_taskinst_var_pop_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  priority_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Act_Hi_Taskinst_Var_Samp_Fields = {
  __typename?: 'act_hi_taskinst_var_samp_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  priority_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Act_Hi_Taskinst_Variance_Fields = {
  __typename?: 'act_hi_taskinst_variance_fields';
  duration_?: Maybe<Scalars['Float']['output']>;
  priority_?: Maybe<Scalars['Float']['output']>;
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "act_ru_event_subscr" */
export type Act_Ru_Event_Subscr = {
  __typename?: 'act_ru_event_subscr';
  activity_id_?: Maybe<Scalars['String']['output']>;
  configuration_?: Maybe<Scalars['String']['output']>;
  created_: Scalars['timestamp']['output'];
  event_name_?: Maybe<Scalars['String']['output']>;
  event_type_: Scalars['String']['output'];
  execution_id_?: Maybe<Scalars['String']['output']>;
  id_: Scalars['String']['output'];
  lock_owner_?: Maybe<Scalars['String']['output']>;
  lock_time_?: Maybe<Scalars['timestamp']['output']>;
  proc_def_id_?: Maybe<Scalars['String']['output']>;
  proc_inst_id_?: Maybe<Scalars['String']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
  scope_definition_id_?: Maybe<Scalars['String']['output']>;
  scope_definition_key_?: Maybe<Scalars['String']['output']>;
  scope_id_?: Maybe<Scalars['String']['output']>;
  scope_type_?: Maybe<Scalars['String']['output']>;
  sub_scope_id_?: Maybe<Scalars['String']['output']>;
  tenant_id_?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "act_ru_event_subscr" */
export type Act_Ru_Event_Subscr_Aggregate = {
  __typename?: 'act_ru_event_subscr_aggregate';
  aggregate?: Maybe<Act_Ru_Event_Subscr_Aggregate_Fields>;
  nodes: Array<Act_Ru_Event_Subscr>;
};

/** aggregate fields of "act_ru_event_subscr" */
export type Act_Ru_Event_Subscr_Aggregate_Fields = {
  __typename?: 'act_ru_event_subscr_aggregate_fields';
  avg?: Maybe<Act_Ru_Event_Subscr_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Act_Ru_Event_Subscr_Max_Fields>;
  min?: Maybe<Act_Ru_Event_Subscr_Min_Fields>;
  stddev?: Maybe<Act_Ru_Event_Subscr_Stddev_Fields>;
  stddev_pop?: Maybe<Act_Ru_Event_Subscr_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Act_Ru_Event_Subscr_Stddev_Samp_Fields>;
  sum?: Maybe<Act_Ru_Event_Subscr_Sum_Fields>;
  var_pop?: Maybe<Act_Ru_Event_Subscr_Var_Pop_Fields>;
  var_samp?: Maybe<Act_Ru_Event_Subscr_Var_Samp_Fields>;
  variance?: Maybe<Act_Ru_Event_Subscr_Variance_Fields>;
};


/** aggregate fields of "act_ru_event_subscr" */
export type Act_Ru_Event_Subscr_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Act_Ru_Event_Subscr_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Act_Ru_Event_Subscr_Avg_Fields = {
  __typename?: 'act_ru_event_subscr_avg_fields';
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "act_ru_event_subscr". All fields are combined with a logical 'AND'. */
export type Act_Ru_Event_Subscr_Bool_Exp = {
  _and?: InputMaybe<Array<Act_Ru_Event_Subscr_Bool_Exp>>;
  _not?: InputMaybe<Act_Ru_Event_Subscr_Bool_Exp>;
  _or?: InputMaybe<Array<Act_Ru_Event_Subscr_Bool_Exp>>;
  activity_id_?: InputMaybe<String_Comparison_Exp>;
  configuration_?: InputMaybe<String_Comparison_Exp>;
  created_?: InputMaybe<Timestamp_Comparison_Exp>;
  event_name_?: InputMaybe<String_Comparison_Exp>;
  event_type_?: InputMaybe<String_Comparison_Exp>;
  execution_id_?: InputMaybe<String_Comparison_Exp>;
  id_?: InputMaybe<String_Comparison_Exp>;
  lock_owner_?: InputMaybe<String_Comparison_Exp>;
  lock_time_?: InputMaybe<Timestamp_Comparison_Exp>;
  proc_def_id_?: InputMaybe<String_Comparison_Exp>;
  proc_inst_id_?: InputMaybe<String_Comparison_Exp>;
  rev_?: InputMaybe<Int_Comparison_Exp>;
  scope_definition_id_?: InputMaybe<String_Comparison_Exp>;
  scope_definition_key_?: InputMaybe<String_Comparison_Exp>;
  scope_id_?: InputMaybe<String_Comparison_Exp>;
  scope_type_?: InputMaybe<String_Comparison_Exp>;
  sub_scope_id_?: InputMaybe<String_Comparison_Exp>;
  tenant_id_?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "act_ru_event_subscr" */
export enum Act_Ru_Event_Subscr_Constraint {
  /** unique or primary key constraint on columns "id_" */
  ActRuEventSubscrPkey = 'act_ru_event_subscr_pkey'
}

/** input type for incrementing numeric columns in table "act_ru_event_subscr" */
export type Act_Ru_Event_Subscr_Inc_Input = {
  rev_?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "act_ru_event_subscr" */
export type Act_Ru_Event_Subscr_Insert_Input = {
  activity_id_?: InputMaybe<Scalars['String']['input']>;
  configuration_?: InputMaybe<Scalars['String']['input']>;
  created_?: InputMaybe<Scalars['timestamp']['input']>;
  event_name_?: InputMaybe<Scalars['String']['input']>;
  event_type_?: InputMaybe<Scalars['String']['input']>;
  execution_id_?: InputMaybe<Scalars['String']['input']>;
  id_?: InputMaybe<Scalars['String']['input']>;
  lock_owner_?: InputMaybe<Scalars['String']['input']>;
  lock_time_?: InputMaybe<Scalars['timestamp']['input']>;
  proc_def_id_?: InputMaybe<Scalars['String']['input']>;
  proc_inst_id_?: InputMaybe<Scalars['String']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
  scope_definition_id_?: InputMaybe<Scalars['String']['input']>;
  scope_definition_key_?: InputMaybe<Scalars['String']['input']>;
  scope_id_?: InputMaybe<Scalars['String']['input']>;
  scope_type_?: InputMaybe<Scalars['String']['input']>;
  sub_scope_id_?: InputMaybe<Scalars['String']['input']>;
  tenant_id_?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Act_Ru_Event_Subscr_Max_Fields = {
  __typename?: 'act_ru_event_subscr_max_fields';
  activity_id_?: Maybe<Scalars['String']['output']>;
  configuration_?: Maybe<Scalars['String']['output']>;
  created_?: Maybe<Scalars['timestamp']['output']>;
  event_name_?: Maybe<Scalars['String']['output']>;
  event_type_?: Maybe<Scalars['String']['output']>;
  execution_id_?: Maybe<Scalars['String']['output']>;
  id_?: Maybe<Scalars['String']['output']>;
  lock_owner_?: Maybe<Scalars['String']['output']>;
  lock_time_?: Maybe<Scalars['timestamp']['output']>;
  proc_def_id_?: Maybe<Scalars['String']['output']>;
  proc_inst_id_?: Maybe<Scalars['String']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
  scope_definition_id_?: Maybe<Scalars['String']['output']>;
  scope_definition_key_?: Maybe<Scalars['String']['output']>;
  scope_id_?: Maybe<Scalars['String']['output']>;
  scope_type_?: Maybe<Scalars['String']['output']>;
  sub_scope_id_?: Maybe<Scalars['String']['output']>;
  tenant_id_?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Act_Ru_Event_Subscr_Min_Fields = {
  __typename?: 'act_ru_event_subscr_min_fields';
  activity_id_?: Maybe<Scalars['String']['output']>;
  configuration_?: Maybe<Scalars['String']['output']>;
  created_?: Maybe<Scalars['timestamp']['output']>;
  event_name_?: Maybe<Scalars['String']['output']>;
  event_type_?: Maybe<Scalars['String']['output']>;
  execution_id_?: Maybe<Scalars['String']['output']>;
  id_?: Maybe<Scalars['String']['output']>;
  lock_owner_?: Maybe<Scalars['String']['output']>;
  lock_time_?: Maybe<Scalars['timestamp']['output']>;
  proc_def_id_?: Maybe<Scalars['String']['output']>;
  proc_inst_id_?: Maybe<Scalars['String']['output']>;
  rev_?: Maybe<Scalars['Int']['output']>;
  scope_definition_id_?: Maybe<Scalars['String']['output']>;
  scope_definition_key_?: Maybe<Scalars['String']['output']>;
  scope_id_?: Maybe<Scalars['String']['output']>;
  scope_type_?: Maybe<Scalars['String']['output']>;
  sub_scope_id_?: Maybe<Scalars['String']['output']>;
  tenant_id_?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "act_ru_event_subscr" */
export type Act_Ru_Event_Subscr_Mutation_Response = {
  __typename?: 'act_ru_event_subscr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Act_Ru_Event_Subscr>;
};

/** on_conflict condition type for table "act_ru_event_subscr" */
export type Act_Ru_Event_Subscr_On_Conflict = {
  constraint: Act_Ru_Event_Subscr_Constraint;
  update_columns?: Array<Act_Ru_Event_Subscr_Update_Column>;
  where?: InputMaybe<Act_Ru_Event_Subscr_Bool_Exp>;
};

/** Ordering options when selecting data from "act_ru_event_subscr". */
export type Act_Ru_Event_Subscr_Order_By = {
  activity_id_?: InputMaybe<Order_By>;
  configuration_?: InputMaybe<Order_By>;
  created_?: InputMaybe<Order_By>;
  event_name_?: InputMaybe<Order_By>;
  event_type_?: InputMaybe<Order_By>;
  execution_id_?: InputMaybe<Order_By>;
  id_?: InputMaybe<Order_By>;
  lock_owner_?: InputMaybe<Order_By>;
  lock_time_?: InputMaybe<Order_By>;
  proc_def_id_?: InputMaybe<Order_By>;
  proc_inst_id_?: InputMaybe<Order_By>;
  rev_?: InputMaybe<Order_By>;
  scope_definition_id_?: InputMaybe<Order_By>;
  scope_definition_key_?: InputMaybe<Order_By>;
  scope_id_?: InputMaybe<Order_By>;
  scope_type_?: InputMaybe<Order_By>;
  sub_scope_id_?: InputMaybe<Order_By>;
  tenant_id_?: InputMaybe<Order_By>;
};

/** primary key columns input for table: act_ru_event_subscr */
export type Act_Ru_Event_Subscr_Pk_Columns_Input = {
  id_: Scalars['String']['input'];
};

/** select columns of table "act_ru_event_subscr" */
export enum Act_Ru_Event_Subscr_Select_Column {
  /** column name */
  ActivityId = 'activity_id_',
  /** column name */
  Configuration = 'configuration_',
  /** column name */
  Created = 'created_',
  /** column name */
  EventName = 'event_name_',
  /** column name */
  EventType = 'event_type_',
  /** column name */
  ExecutionId = 'execution_id_',
  /** column name */
  Id = 'id_',
  /** column name */
  LockOwner = 'lock_owner_',
  /** column name */
  LockTime = 'lock_time_',
  /** column name */
  ProcDefId = 'proc_def_id_',
  /** column name */
  ProcInstId = 'proc_inst_id_',
  /** column name */
  Rev = 'rev_',
  /** column name */
  ScopeDefinitionId = 'scope_definition_id_',
  /** column name */
  ScopeDefinitionKey = 'scope_definition_key_',
  /** column name */
  ScopeId = 'scope_id_',
  /** column name */
  ScopeType = 'scope_type_',
  /** column name */
  SubScopeId = 'sub_scope_id_',
  /** column name */
  TenantId = 'tenant_id_'
}

/** input type for updating data in table "act_ru_event_subscr" */
export type Act_Ru_Event_Subscr_Set_Input = {
  activity_id_?: InputMaybe<Scalars['String']['input']>;
  configuration_?: InputMaybe<Scalars['String']['input']>;
  created_?: InputMaybe<Scalars['timestamp']['input']>;
  event_name_?: InputMaybe<Scalars['String']['input']>;
  event_type_?: InputMaybe<Scalars['String']['input']>;
  execution_id_?: InputMaybe<Scalars['String']['input']>;
  id_?: InputMaybe<Scalars['String']['input']>;
  lock_owner_?: InputMaybe<Scalars['String']['input']>;
  lock_time_?: InputMaybe<Scalars['timestamp']['input']>;
  proc_def_id_?: InputMaybe<Scalars['String']['input']>;
  proc_inst_id_?: InputMaybe<Scalars['String']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
  scope_definition_id_?: InputMaybe<Scalars['String']['input']>;
  scope_definition_key_?: InputMaybe<Scalars['String']['input']>;
  scope_id_?: InputMaybe<Scalars['String']['input']>;
  scope_type_?: InputMaybe<Scalars['String']['input']>;
  sub_scope_id_?: InputMaybe<Scalars['String']['input']>;
  tenant_id_?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Act_Ru_Event_Subscr_Stddev_Fields = {
  __typename?: 'act_ru_event_subscr_stddev_fields';
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Act_Ru_Event_Subscr_Stddev_Pop_Fields = {
  __typename?: 'act_ru_event_subscr_stddev_pop_fields';
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Act_Ru_Event_Subscr_Stddev_Samp_Fields = {
  __typename?: 'act_ru_event_subscr_stddev_samp_fields';
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "act_ru_event_subscr" */
export type Act_Ru_Event_Subscr_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Act_Ru_Event_Subscr_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Act_Ru_Event_Subscr_Stream_Cursor_Value_Input = {
  activity_id_?: InputMaybe<Scalars['String']['input']>;
  configuration_?: InputMaybe<Scalars['String']['input']>;
  created_?: InputMaybe<Scalars['timestamp']['input']>;
  event_name_?: InputMaybe<Scalars['String']['input']>;
  event_type_?: InputMaybe<Scalars['String']['input']>;
  execution_id_?: InputMaybe<Scalars['String']['input']>;
  id_?: InputMaybe<Scalars['String']['input']>;
  lock_owner_?: InputMaybe<Scalars['String']['input']>;
  lock_time_?: InputMaybe<Scalars['timestamp']['input']>;
  proc_def_id_?: InputMaybe<Scalars['String']['input']>;
  proc_inst_id_?: InputMaybe<Scalars['String']['input']>;
  rev_?: InputMaybe<Scalars['Int']['input']>;
  scope_definition_id_?: InputMaybe<Scalars['String']['input']>;
  scope_definition_key_?: InputMaybe<Scalars['String']['input']>;
  scope_id_?: InputMaybe<Scalars['String']['input']>;
  scope_type_?: InputMaybe<Scalars['String']['input']>;
  sub_scope_id_?: InputMaybe<Scalars['String']['input']>;
  tenant_id_?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Act_Ru_Event_Subscr_Sum_Fields = {
  __typename?: 'act_ru_event_subscr_sum_fields';
  rev_?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "act_ru_event_subscr" */
export enum Act_Ru_Event_Subscr_Update_Column {
  /** column name */
  ActivityId = 'activity_id_',
  /** column name */
  Configuration = 'configuration_',
  /** column name */
  Created = 'created_',
  /** column name */
  EventName = 'event_name_',
  /** column name */
  EventType = 'event_type_',
  /** column name */
  ExecutionId = 'execution_id_',
  /** column name */
  Id = 'id_',
  /** column name */
  LockOwner = 'lock_owner_',
  /** column name */
  LockTime = 'lock_time_',
  /** column name */
  ProcDefId = 'proc_def_id_',
  /** column name */
  ProcInstId = 'proc_inst_id_',
  /** column name */
  Rev = 'rev_',
  /** column name */
  ScopeDefinitionId = 'scope_definition_id_',
  /** column name */
  ScopeDefinitionKey = 'scope_definition_key_',
  /** column name */
  ScopeId = 'scope_id_',
  /** column name */
  ScopeType = 'scope_type_',
  /** column name */
  SubScopeId = 'sub_scope_id_',
  /** column name */
  TenantId = 'tenant_id_'
}

export type Act_Ru_Event_Subscr_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Act_Ru_Event_Subscr_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Act_Ru_Event_Subscr_Set_Input>;
  /** filter the rows which have to be updated */
  where: Act_Ru_Event_Subscr_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Act_Ru_Event_Subscr_Var_Pop_Fields = {
  __typename?: 'act_ru_event_subscr_var_pop_fields';
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Act_Ru_Event_Subscr_Var_Samp_Fields = {
  __typename?: 'act_ru_event_subscr_var_samp_fields';
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Act_Ru_Event_Subscr_Variance_Fields = {
  __typename?: 'act_ru_event_subscr_variance_fields';
  rev_?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'address';
  administrativeAreaLevel1?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel3?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel4?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel5?: Maybe<Scalars['String']['output']>;
  archipelago?: Maybe<Scalars['String']['output']>;
  colloquialArea?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid']['output'];
  continent?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  formattedAddress: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  intersection?: Maybe<Scalars['String']['output']>;
  latitude: Scalars['numeric']['output'];
  locality?: Maybe<Scalars['String']['output']>;
  longitude: Scalars['numeric']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  placeId: Scalars['String']['output'];
  plusCode?: Maybe<Scalars['String']['output']>;
  postBox?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  postalCodePrefix?: Maybe<Scalars['String']['output']>;
  postalCodeSuffix?: Maybe<Scalars['String']['output']>;
  postalTown?: Maybe<Scalars['String']['output']>;
  premise?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  sublocality?: Maybe<Scalars['String']['output']>;
  sublocalityLevel1?: Maybe<Scalars['String']['output']>;
  sublocalityLevel2?: Maybe<Scalars['String']['output']>;
  sublocalityLevel3?: Maybe<Scalars['String']['output']>;
  sublocalityLevel4?: Maybe<Scalars['String']['output']>;
  sublocalityLevel5?: Maybe<Scalars['String']['output']>;
  subpremise?: Maybe<Scalars['String']['output']>;
  townSquare?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

export type Address_Aggregate_Bool_Exp = {
  count?: InputMaybe<Address_Aggregate_Bool_Exp_Count>;
};

export type Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'address_aggregate_fields';
  avg?: Maybe<Address_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
  stddev?: Maybe<Address_Stddev_Fields>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Fields>;
  sum?: Maybe<Address_Sum_Fields>;
  var_pop?: Maybe<Address_Var_Pop_Fields>;
  var_samp?: Maybe<Address_Var_Samp_Fields>;
  variance?: Maybe<Address_Variance_Fields>;
};


/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "address" */
export type Address_Aggregate_Order_By = {
  avg?: InputMaybe<Address_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Address_Max_Order_By>;
  min?: InputMaybe<Address_Min_Order_By>;
  stddev?: InputMaybe<Address_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Address_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Address_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Address_Sum_Order_By>;
  var_pop?: InputMaybe<Address_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Address_Var_Samp_Order_By>;
  variance?: InputMaybe<Address_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "address" */
export type Address_Arr_Rel_Insert_Input = {
  data: Array<Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Address_Avg_Fields = {
  __typename?: 'address_avg_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "address" */
export type Address_Avg_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Bool_Exp>>;
  _not?: InputMaybe<Address_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Bool_Exp>>;
  administrativeAreaLevel1?: InputMaybe<String_Comparison_Exp>;
  administrativeAreaLevel2?: InputMaybe<String_Comparison_Exp>;
  administrativeAreaLevel3?: InputMaybe<String_Comparison_Exp>;
  administrativeAreaLevel4?: InputMaybe<String_Comparison_Exp>;
  administrativeAreaLevel5?: InputMaybe<String_Comparison_Exp>;
  archipelago?: InputMaybe<String_Comparison_Exp>;
  colloquialArea?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  continent?: InputMaybe<String_Comparison_Exp>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  formattedAddress?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  intersection?: InputMaybe<String_Comparison_Exp>;
  latitude?: InputMaybe<Numeric_Comparison_Exp>;
  locality?: InputMaybe<String_Comparison_Exp>;
  longitude?: InputMaybe<Numeric_Comparison_Exp>;
  neighborhood?: InputMaybe<String_Comparison_Exp>;
  placeId?: InputMaybe<String_Comparison_Exp>;
  plusCode?: InputMaybe<String_Comparison_Exp>;
  postBox?: InputMaybe<String_Comparison_Exp>;
  postalCode?: InputMaybe<String_Comparison_Exp>;
  postalCodePrefix?: InputMaybe<String_Comparison_Exp>;
  postalCodeSuffix?: InputMaybe<String_Comparison_Exp>;
  postalTown?: InputMaybe<String_Comparison_Exp>;
  premise?: InputMaybe<String_Comparison_Exp>;
  route?: InputMaybe<String_Comparison_Exp>;
  streetAddress?: InputMaybe<String_Comparison_Exp>;
  streetNumber?: InputMaybe<String_Comparison_Exp>;
  sublocality?: InputMaybe<String_Comparison_Exp>;
  sublocalityLevel1?: InputMaybe<String_Comparison_Exp>;
  sublocalityLevel2?: InputMaybe<String_Comparison_Exp>;
  sublocalityLevel3?: InputMaybe<String_Comparison_Exp>;
  sublocalityLevel4?: InputMaybe<String_Comparison_Exp>;
  sublocalityLevel5?: InputMaybe<String_Comparison_Exp>;
  subpremise?: InputMaybe<String_Comparison_Exp>;
  townSquare?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkD92de1f82754668b5f5f5dd4fd5 = 'PK_d92de1f82754668b5f5f5dd4fd5',
  /** unique or primary key constraint on columns "companyId" */
  RelC3fdf52cd6b4cbbeca8f5184fb = 'REL_c3fdf52cd6b4cbbeca8f5184fb'
}

/** input type for incrementing numeric columns in table "address" */
export type Address_Inc_Input = {
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  administrativeAreaLevel1?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel2?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel3?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel4?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel5?: InputMaybe<Scalars['String']['input']>;
  archipelago?: InputMaybe<Scalars['String']['input']>;
  colloquialArea?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['uuid']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  intersection?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  neighborhood?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  plusCode?: InputMaybe<Scalars['String']['input']>;
  postBox?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  postalCodePrefix?: InputMaybe<Scalars['String']['input']>;
  postalCodeSuffix?: InputMaybe<Scalars['String']['input']>;
  postalTown?: InputMaybe<Scalars['String']['input']>;
  premise?: InputMaybe<Scalars['String']['input']>;
  route?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  sublocality?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel1?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel2?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel3?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel4?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel5?: InputMaybe<Scalars['String']['input']>;
  subpremise?: InputMaybe<Scalars['String']['input']>;
  townSquare?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'address_max_fields';
  administrativeAreaLevel1?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel3?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel4?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel5?: Maybe<Scalars['String']['output']>;
  archipelago?: Maybe<Scalars['String']['output']>;
  colloquialArea?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['uuid']['output']>;
  continent?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  intersection?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  neighborhood?: Maybe<Scalars['String']['output']>;
  placeId?: Maybe<Scalars['String']['output']>;
  plusCode?: Maybe<Scalars['String']['output']>;
  postBox?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  postalCodePrefix?: Maybe<Scalars['String']['output']>;
  postalCodeSuffix?: Maybe<Scalars['String']['output']>;
  postalTown?: Maybe<Scalars['String']['output']>;
  premise?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  sublocality?: Maybe<Scalars['String']['output']>;
  sublocalityLevel1?: Maybe<Scalars['String']['output']>;
  sublocalityLevel2?: Maybe<Scalars['String']['output']>;
  sublocalityLevel3?: Maybe<Scalars['String']['output']>;
  sublocalityLevel4?: Maybe<Scalars['String']['output']>;
  sublocalityLevel5?: Maybe<Scalars['String']['output']>;
  subpremise?: Maybe<Scalars['String']['output']>;
  townSquare?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "address" */
export type Address_Max_Order_By = {
  administrativeAreaLevel1?: InputMaybe<Order_By>;
  administrativeAreaLevel2?: InputMaybe<Order_By>;
  administrativeAreaLevel3?: InputMaybe<Order_By>;
  administrativeAreaLevel4?: InputMaybe<Order_By>;
  administrativeAreaLevel5?: InputMaybe<Order_By>;
  archipelago?: InputMaybe<Order_By>;
  colloquialArea?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  continent?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  formattedAddress?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intersection?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  neighborhood?: InputMaybe<Order_By>;
  placeId?: InputMaybe<Order_By>;
  plusCode?: InputMaybe<Order_By>;
  postBox?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  postalCodePrefix?: InputMaybe<Order_By>;
  postalCodeSuffix?: InputMaybe<Order_By>;
  postalTown?: InputMaybe<Order_By>;
  premise?: InputMaybe<Order_By>;
  route?: InputMaybe<Order_By>;
  streetAddress?: InputMaybe<Order_By>;
  streetNumber?: InputMaybe<Order_By>;
  sublocality?: InputMaybe<Order_By>;
  sublocalityLevel1?: InputMaybe<Order_By>;
  sublocalityLevel2?: InputMaybe<Order_By>;
  sublocalityLevel3?: InputMaybe<Order_By>;
  sublocalityLevel4?: InputMaybe<Order_By>;
  sublocalityLevel5?: InputMaybe<Order_By>;
  subpremise?: InputMaybe<Order_By>;
  townSquare?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'address_min_fields';
  administrativeAreaLevel1?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel3?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel4?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel5?: Maybe<Scalars['String']['output']>;
  archipelago?: Maybe<Scalars['String']['output']>;
  colloquialArea?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['uuid']['output']>;
  continent?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  intersection?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  neighborhood?: Maybe<Scalars['String']['output']>;
  placeId?: Maybe<Scalars['String']['output']>;
  plusCode?: Maybe<Scalars['String']['output']>;
  postBox?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  postalCodePrefix?: Maybe<Scalars['String']['output']>;
  postalCodeSuffix?: Maybe<Scalars['String']['output']>;
  postalTown?: Maybe<Scalars['String']['output']>;
  premise?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  sublocality?: Maybe<Scalars['String']['output']>;
  sublocalityLevel1?: Maybe<Scalars['String']['output']>;
  sublocalityLevel2?: Maybe<Scalars['String']['output']>;
  sublocalityLevel3?: Maybe<Scalars['String']['output']>;
  sublocalityLevel4?: Maybe<Scalars['String']['output']>;
  sublocalityLevel5?: Maybe<Scalars['String']['output']>;
  subpremise?: Maybe<Scalars['String']['output']>;
  townSquare?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "address" */
export type Address_Min_Order_By = {
  administrativeAreaLevel1?: InputMaybe<Order_By>;
  administrativeAreaLevel2?: InputMaybe<Order_By>;
  administrativeAreaLevel3?: InputMaybe<Order_By>;
  administrativeAreaLevel4?: InputMaybe<Order_By>;
  administrativeAreaLevel5?: InputMaybe<Order_By>;
  archipelago?: InputMaybe<Order_By>;
  colloquialArea?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  continent?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  formattedAddress?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intersection?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  neighborhood?: InputMaybe<Order_By>;
  placeId?: InputMaybe<Order_By>;
  plusCode?: InputMaybe<Order_By>;
  postBox?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  postalCodePrefix?: InputMaybe<Order_By>;
  postalCodeSuffix?: InputMaybe<Order_By>;
  postalTown?: InputMaybe<Order_By>;
  premise?: InputMaybe<Order_By>;
  route?: InputMaybe<Order_By>;
  streetAddress?: InputMaybe<Order_By>;
  streetNumber?: InputMaybe<Order_By>;
  sublocality?: InputMaybe<Order_By>;
  sublocalityLevel1?: InputMaybe<Order_By>;
  sublocalityLevel2?: InputMaybe<Order_By>;
  sublocalityLevel3?: InputMaybe<Order_By>;
  sublocalityLevel4?: InputMaybe<Order_By>;
  sublocalityLevel5?: InputMaybe<Order_By>;
  subpremise?: InputMaybe<Order_By>;
  townSquare?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  administrativeAreaLevel1?: InputMaybe<Order_By>;
  administrativeAreaLevel2?: InputMaybe<Order_By>;
  administrativeAreaLevel3?: InputMaybe<Order_By>;
  administrativeAreaLevel4?: InputMaybe<Order_By>;
  administrativeAreaLevel5?: InputMaybe<Order_By>;
  archipelago?: InputMaybe<Order_By>;
  colloquialArea?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  continent?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  formattedAddress?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intersection?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  neighborhood?: InputMaybe<Order_By>;
  placeId?: InputMaybe<Order_By>;
  plusCode?: InputMaybe<Order_By>;
  postBox?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  postalCodePrefix?: InputMaybe<Order_By>;
  postalCodeSuffix?: InputMaybe<Order_By>;
  postalTown?: InputMaybe<Order_By>;
  premise?: InputMaybe<Order_By>;
  route?: InputMaybe<Order_By>;
  streetAddress?: InputMaybe<Order_By>;
  streetNumber?: InputMaybe<Order_By>;
  sublocality?: InputMaybe<Order_By>;
  sublocalityLevel1?: InputMaybe<Order_By>;
  sublocalityLevel2?: InputMaybe<Order_By>;
  sublocalityLevel3?: InputMaybe<Order_By>;
  sublocalityLevel4?: InputMaybe<Order_By>;
  sublocalityLevel5?: InputMaybe<Order_By>;
  subpremise?: InputMaybe<Order_By>;
  townSquare?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  AdministrativeAreaLevel1 = 'administrativeAreaLevel1',
  /** column name */
  AdministrativeAreaLevel2 = 'administrativeAreaLevel2',
  /** column name */
  AdministrativeAreaLevel3 = 'administrativeAreaLevel3',
  /** column name */
  AdministrativeAreaLevel4 = 'administrativeAreaLevel4',
  /** column name */
  AdministrativeAreaLevel5 = 'administrativeAreaLevel5',
  /** column name */
  Archipelago = 'archipelago',
  /** column name */
  ColloquialArea = 'colloquialArea',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Continent = 'continent',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FormattedAddress = 'formattedAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Intersection = 'intersection',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Locality = 'locality',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Neighborhood = 'neighborhood',
  /** column name */
  PlaceId = 'placeId',
  /** column name */
  PlusCode = 'plusCode',
  /** column name */
  PostBox = 'postBox',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  PostalCodePrefix = 'postalCodePrefix',
  /** column name */
  PostalCodeSuffix = 'postalCodeSuffix',
  /** column name */
  PostalTown = 'postalTown',
  /** column name */
  Premise = 'premise',
  /** column name */
  Route = 'route',
  /** column name */
  StreetAddress = 'streetAddress',
  /** column name */
  StreetNumber = 'streetNumber',
  /** column name */
  Sublocality = 'sublocality',
  /** column name */
  SublocalityLevel1 = 'sublocalityLevel1',
  /** column name */
  SublocalityLevel2 = 'sublocalityLevel2',
  /** column name */
  SublocalityLevel3 = 'sublocalityLevel3',
  /** column name */
  SublocalityLevel4 = 'sublocalityLevel4',
  /** column name */
  SublocalityLevel5 = 'sublocalityLevel5',
  /** column name */
  Subpremise = 'subpremise',
  /** column name */
  TownSquare = 'townSquare',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  administrativeAreaLevel1?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel2?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel3?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel4?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel5?: InputMaybe<Scalars['String']['input']>;
  archipelago?: InputMaybe<Scalars['String']['input']>;
  colloquialArea?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['uuid']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  intersection?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  neighborhood?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  plusCode?: InputMaybe<Scalars['String']['input']>;
  postBox?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  postalCodePrefix?: InputMaybe<Scalars['String']['input']>;
  postalCodeSuffix?: InputMaybe<Scalars['String']['input']>;
  postalTown?: InputMaybe<Scalars['String']['input']>;
  premise?: InputMaybe<Scalars['String']['input']>;
  route?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  sublocality?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel1?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel2?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel3?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel4?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel5?: InputMaybe<Scalars['String']['input']>;
  subpremise?: InputMaybe<Scalars['String']['input']>;
  townSquare?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Address_Stddev_Fields = {
  __typename?: 'address_stddev_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "address" */
export type Address_Stddev_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Address_Stddev_Pop_Fields = {
  __typename?: 'address_stddev_pop_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "address" */
export type Address_Stddev_Pop_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Address_Stddev_Samp_Fields = {
  __typename?: 'address_stddev_samp_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "address" */
export type Address_Stddev_Samp_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  administrativeAreaLevel1?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel2?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel3?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel4?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel5?: InputMaybe<Scalars['String']['input']>;
  archipelago?: InputMaybe<Scalars['String']['input']>;
  colloquialArea?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['uuid']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  intersection?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  neighborhood?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  plusCode?: InputMaybe<Scalars['String']['input']>;
  postBox?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  postalCodePrefix?: InputMaybe<Scalars['String']['input']>;
  postalCodeSuffix?: InputMaybe<Scalars['String']['input']>;
  postalTown?: InputMaybe<Scalars['String']['input']>;
  premise?: InputMaybe<Scalars['String']['input']>;
  route?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  sublocality?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel1?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel2?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel3?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel4?: InputMaybe<Scalars['String']['input']>;
  sublocalityLevel5?: InputMaybe<Scalars['String']['input']>;
  subpremise?: InputMaybe<Scalars['String']['input']>;
  townSquare?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Address_Sum_Fields = {
  __typename?: 'address_sum_fields';
  latitude?: Maybe<Scalars['numeric']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "address" */
export type Address_Sum_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  AdministrativeAreaLevel1 = 'administrativeAreaLevel1',
  /** column name */
  AdministrativeAreaLevel2 = 'administrativeAreaLevel2',
  /** column name */
  AdministrativeAreaLevel3 = 'administrativeAreaLevel3',
  /** column name */
  AdministrativeAreaLevel4 = 'administrativeAreaLevel4',
  /** column name */
  AdministrativeAreaLevel5 = 'administrativeAreaLevel5',
  /** column name */
  Archipelago = 'archipelago',
  /** column name */
  ColloquialArea = 'colloquialArea',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Continent = 'continent',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FormattedAddress = 'formattedAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Intersection = 'intersection',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Locality = 'locality',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Neighborhood = 'neighborhood',
  /** column name */
  PlaceId = 'placeId',
  /** column name */
  PlusCode = 'plusCode',
  /** column name */
  PostBox = 'postBox',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  PostalCodePrefix = 'postalCodePrefix',
  /** column name */
  PostalCodeSuffix = 'postalCodeSuffix',
  /** column name */
  PostalTown = 'postalTown',
  /** column name */
  Premise = 'premise',
  /** column name */
  Route = 'route',
  /** column name */
  StreetAddress = 'streetAddress',
  /** column name */
  StreetNumber = 'streetNumber',
  /** column name */
  Sublocality = 'sublocality',
  /** column name */
  SublocalityLevel1 = 'sublocalityLevel1',
  /** column name */
  SublocalityLevel2 = 'sublocalityLevel2',
  /** column name */
  SublocalityLevel3 = 'sublocalityLevel3',
  /** column name */
  SublocalityLevel4 = 'sublocalityLevel4',
  /** column name */
  SublocalityLevel5 = 'sublocalityLevel5',
  /** column name */
  Subpremise = 'subpremise',
  /** column name */
  TownSquare = 'townSquare',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Address_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Address_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Address_Var_Pop_Fields = {
  __typename?: 'address_var_pop_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "address" */
export type Address_Var_Pop_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Address_Var_Samp_Fields = {
  __typename?: 'address_var_samp_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "address" */
export type Address_Var_Samp_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Address_Variance_Fields = {
  __typename?: 'address_variance_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "address" */
export type Address_Variance_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** columns and relationships of "administrative_area" */
export type Administrative_Area = {
  __typename?: 'administrative_area';
  area: Scalars['String']['output'];
  country_code: Scalars['String']['output'];
  level: Scalars['Int']['output'];
};

/** aggregated selection of "administrative_area" */
export type Administrative_Area_Aggregate = {
  __typename?: 'administrative_area_aggregate';
  aggregate?: Maybe<Administrative_Area_Aggregate_Fields>;
  nodes: Array<Administrative_Area>;
};

/** aggregate fields of "administrative_area" */
export type Administrative_Area_Aggregate_Fields = {
  __typename?: 'administrative_area_aggregate_fields';
  avg?: Maybe<Administrative_Area_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Administrative_Area_Max_Fields>;
  min?: Maybe<Administrative_Area_Min_Fields>;
  stddev?: Maybe<Administrative_Area_Stddev_Fields>;
  stddev_pop?: Maybe<Administrative_Area_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Administrative_Area_Stddev_Samp_Fields>;
  sum?: Maybe<Administrative_Area_Sum_Fields>;
  var_pop?: Maybe<Administrative_Area_Var_Pop_Fields>;
  var_samp?: Maybe<Administrative_Area_Var_Samp_Fields>;
  variance?: Maybe<Administrative_Area_Variance_Fields>;
};


/** aggregate fields of "administrative_area" */
export type Administrative_Area_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Administrative_Area_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Administrative_Area_Avg_Fields = {
  __typename?: 'administrative_area_avg_fields';
  level?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "administrative_area". All fields are combined with a logical 'AND'. */
export type Administrative_Area_Bool_Exp = {
  _and?: InputMaybe<Array<Administrative_Area_Bool_Exp>>;
  _not?: InputMaybe<Administrative_Area_Bool_Exp>;
  _or?: InputMaybe<Array<Administrative_Area_Bool_Exp>>;
  area?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "administrative_area" */
export enum Administrative_Area_Constraint {
  /** unique or primary key constraint on columns "area", "country_code" */
  AdministrativeAreaPkey = 'administrative_area_pkey'
}

/** input type for incrementing numeric columns in table "administrative_area" */
export type Administrative_Area_Inc_Input = {
  level?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "administrative_area" */
export type Administrative_Area_Insert_Input = {
  area?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Administrative_Area_Max_Fields = {
  __typename?: 'administrative_area_max_fields';
  area?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Administrative_Area_Min_Fields = {
  __typename?: 'administrative_area_min_fields';
  area?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "administrative_area" */
export type Administrative_Area_Mutation_Response = {
  __typename?: 'administrative_area_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Administrative_Area>;
};

/** on_conflict condition type for table "administrative_area" */
export type Administrative_Area_On_Conflict = {
  constraint: Administrative_Area_Constraint;
  update_columns?: Array<Administrative_Area_Update_Column>;
  where?: InputMaybe<Administrative_Area_Bool_Exp>;
};

/** Ordering options when selecting data from "administrative_area". */
export type Administrative_Area_Order_By = {
  area?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** primary key columns input for table: administrative_area */
export type Administrative_Area_Pk_Columns_Input = {
  area: Scalars['String']['input'];
  country_code: Scalars['String']['input'];
};

/** select columns of table "administrative_area" */
export enum Administrative_Area_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  Level = 'level'
}

/** input type for updating data in table "administrative_area" */
export type Administrative_Area_Set_Input = {
  area?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Administrative_Area_Stddev_Fields = {
  __typename?: 'administrative_area_stddev_fields';
  level?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Administrative_Area_Stddev_Pop_Fields = {
  __typename?: 'administrative_area_stddev_pop_fields';
  level?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Administrative_Area_Stddev_Samp_Fields = {
  __typename?: 'administrative_area_stddev_samp_fields';
  level?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "administrative_area" */
export type Administrative_Area_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Administrative_Area_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Administrative_Area_Stream_Cursor_Value_Input = {
  area?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Administrative_Area_Sum_Fields = {
  __typename?: 'administrative_area_sum_fields';
  level?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "administrative_area" */
export enum Administrative_Area_Update_Column {
  /** column name */
  Area = 'area',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  Level = 'level'
}

export type Administrative_Area_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Administrative_Area_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Administrative_Area_Set_Input>;
  /** filter the rows which have to be updated */
  where: Administrative_Area_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Administrative_Area_Var_Pop_Fields = {
  __typename?: 'administrative_area_var_pop_fields';
  level?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Administrative_Area_Var_Samp_Fields = {
  __typename?: 'administrative_area_var_samp_fields';
  level?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Administrative_Area_Variance_Fields = {
  __typename?: 'administrative_area_variance_fields';
  level?: Maybe<Scalars['Float']['output']>;
};

/** all_contracts_total_priceNative Query Arguments */
export type All_Contracts_Total_Price_Arguments = {
  buying_company_id?: InputMaybe<Scalars['uuid']['input']>;
  supplying_company_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id: Scalars['uuid']['input'];
};

/** columns and relationships of "authentication_code" */
export type Authentication_Code = {
  __typename?: 'authentication_code';
  code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  expires_at: Scalars['timestamptz']['output'];
  failed_attempts_count: Scalars['numeric']['output'];
  id: Scalars['uuid']['output'];
  is_revoked: Scalars['Boolean']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid']['output'];
  verification_status?: Maybe<Authentication_Verification_Type_Enum>;
};

/** aggregated selection of "authentication_code" */
export type Authentication_Code_Aggregate = {
  __typename?: 'authentication_code_aggregate';
  aggregate?: Maybe<Authentication_Code_Aggregate_Fields>;
  nodes: Array<Authentication_Code>;
};

/** aggregate fields of "authentication_code" */
export type Authentication_Code_Aggregate_Fields = {
  __typename?: 'authentication_code_aggregate_fields';
  avg?: Maybe<Authentication_Code_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Authentication_Code_Max_Fields>;
  min?: Maybe<Authentication_Code_Min_Fields>;
  stddev?: Maybe<Authentication_Code_Stddev_Fields>;
  stddev_pop?: Maybe<Authentication_Code_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Authentication_Code_Stddev_Samp_Fields>;
  sum?: Maybe<Authentication_Code_Sum_Fields>;
  var_pop?: Maybe<Authentication_Code_Var_Pop_Fields>;
  var_samp?: Maybe<Authentication_Code_Var_Samp_Fields>;
  variance?: Maybe<Authentication_Code_Variance_Fields>;
};


/** aggregate fields of "authentication_code" */
export type Authentication_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Authentication_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Authentication_Code_Avg_Fields = {
  __typename?: 'authentication_code_avg_fields';
  failed_attempts_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "authentication_code". All fields are combined with a logical 'AND'. */
export type Authentication_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Authentication_Code_Bool_Exp>>;
  _not?: InputMaybe<Authentication_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Authentication_Code_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  failed_attempts_count?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_revoked?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  verification_status?: InputMaybe<Authentication_Verification_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "authentication_code" */
export enum Authentication_Code_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuthenticationCodePkey = 'authentication_code_pkey'
}

/** input type for incrementing numeric columns in table "authentication_code" */
export type Authentication_Code_Inc_Input = {
  failed_attempts_count?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "authentication_code" */
export type Authentication_Code_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failed_attempts_count?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_revoked?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  verification_status?: InputMaybe<Authentication_Verification_Type_Enum>;
};

/** aggregate max on columns */
export type Authentication_Code_Max_Fields = {
  __typename?: 'authentication_code_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  expires_at?: Maybe<Scalars['timestamptz']['output']>;
  failed_attempts_count?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Authentication_Code_Min_Fields = {
  __typename?: 'authentication_code_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  expires_at?: Maybe<Scalars['timestamptz']['output']>;
  failed_attempts_count?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "authentication_code" */
export type Authentication_Code_Mutation_Response = {
  __typename?: 'authentication_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Authentication_Code>;
};

/** on_conflict condition type for table "authentication_code" */
export type Authentication_Code_On_Conflict = {
  constraint: Authentication_Code_Constraint;
  update_columns?: Array<Authentication_Code_Update_Column>;
  where?: InputMaybe<Authentication_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "authentication_code". */
export type Authentication_Code_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  failed_attempts_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_revoked?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  verification_status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: authentication_code */
export type Authentication_Code_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "authentication_code" */
export enum Authentication_Code_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  FailedAttemptsCount = 'failed_attempts_count',
  /** column name */
  Id = 'id',
  /** column name */
  IsRevoked = 'is_revoked',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VerificationStatus = 'verification_status'
}

/** input type for updating data in table "authentication_code" */
export type Authentication_Code_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failed_attempts_count?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_revoked?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  verification_status?: InputMaybe<Authentication_Verification_Type_Enum>;
};

/** aggregate stddev on columns */
export type Authentication_Code_Stddev_Fields = {
  __typename?: 'authentication_code_stddev_fields';
  failed_attempts_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Authentication_Code_Stddev_Pop_Fields = {
  __typename?: 'authentication_code_stddev_pop_fields';
  failed_attempts_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Authentication_Code_Stddev_Samp_Fields = {
  __typename?: 'authentication_code_stddev_samp_fields';
  failed_attempts_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "authentication_code" */
export type Authentication_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Authentication_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Authentication_Code_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failed_attempts_count?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_revoked?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  verification_status?: InputMaybe<Authentication_Verification_Type_Enum>;
};

/** aggregate sum on columns */
export type Authentication_Code_Sum_Fields = {
  __typename?: 'authentication_code_sum_fields';
  failed_attempts_count?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "authentication_code" */
export enum Authentication_Code_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  FailedAttemptsCount = 'failed_attempts_count',
  /** column name */
  Id = 'id',
  /** column name */
  IsRevoked = 'is_revoked',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VerificationStatus = 'verification_status'
}

export type Authentication_Code_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Authentication_Code_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Authentication_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Authentication_Code_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Authentication_Code_Var_Pop_Fields = {
  __typename?: 'authentication_code_var_pop_fields';
  failed_attempts_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Authentication_Code_Var_Samp_Fields = {
  __typename?: 'authentication_code_var_samp_fields';
  failed_attempts_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Authentication_Code_Variance_Fields = {
  __typename?: 'authentication_code_variance_fields';
  failed_attempts_count?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "authentication_verification_type" */
export type Authentication_Verification_Type = {
  __typename?: 'authentication_verification_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "authentication_verification_type" */
export type Authentication_Verification_Type_Aggregate = {
  __typename?: 'authentication_verification_type_aggregate';
  aggregate?: Maybe<Authentication_Verification_Type_Aggregate_Fields>;
  nodes: Array<Authentication_Verification_Type>;
};

/** aggregate fields of "authentication_verification_type" */
export type Authentication_Verification_Type_Aggregate_Fields = {
  __typename?: 'authentication_verification_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Authentication_Verification_Type_Max_Fields>;
  min?: Maybe<Authentication_Verification_Type_Min_Fields>;
};


/** aggregate fields of "authentication_verification_type" */
export type Authentication_Verification_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Authentication_Verification_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "authentication_verification_type". All fields are combined with a logical 'AND'. */
export type Authentication_Verification_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Authentication_Verification_Type_Bool_Exp>>;
  _not?: InputMaybe<Authentication_Verification_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Authentication_Verification_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "authentication_verification_type" */
export enum Authentication_Verification_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  AuthenticationVerificationTypePkey = 'authentication_verification_type_pkey'
}

export enum Authentication_Verification_Type_Enum {
  Invalid = 'INVALID',
  NoLongerValid = 'NO_LONGER_VALID',
  Valid = 'VALID'
}

/** Boolean expression to compare columns of type "authentication_verification_type_enum". All fields are combined with logical 'AND'. */
export type Authentication_Verification_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Authentication_Verification_Type_Enum>;
  _in?: InputMaybe<Array<Authentication_Verification_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Authentication_Verification_Type_Enum>;
  _nin?: InputMaybe<Array<Authentication_Verification_Type_Enum>>;
};

/** input type for inserting data into table "authentication_verification_type" */
export type Authentication_Verification_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Authentication_Verification_Type_Max_Fields = {
  __typename?: 'authentication_verification_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Authentication_Verification_Type_Min_Fields = {
  __typename?: 'authentication_verification_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "authentication_verification_type" */
export type Authentication_Verification_Type_Mutation_Response = {
  __typename?: 'authentication_verification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Authentication_Verification_Type>;
};

/** on_conflict condition type for table "authentication_verification_type" */
export type Authentication_Verification_Type_On_Conflict = {
  constraint: Authentication_Verification_Type_Constraint;
  update_columns?: Array<Authentication_Verification_Type_Update_Column>;
  where?: InputMaybe<Authentication_Verification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "authentication_verification_type". */
export type Authentication_Verification_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: authentication_verification_type */
export type Authentication_Verification_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "authentication_verification_type" */
export enum Authentication_Verification_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "authentication_verification_type" */
export type Authentication_Verification_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authentication_verification_type" */
export type Authentication_Verification_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Authentication_Verification_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Authentication_Verification_Type_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "authentication_verification_type" */
export enum Authentication_Verification_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Authentication_Verification_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Authentication_Verification_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Authentication_Verification_Type_Bool_Exp;
};

/** columns and relationships of "availability_status" */
export type Availability_Status = {
  __typename?: 'availability_status';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "availability_status" */
export type Availability_Status_Aggregate = {
  __typename?: 'availability_status_aggregate';
  aggregate?: Maybe<Availability_Status_Aggregate_Fields>;
  nodes: Array<Availability_Status>;
};

/** aggregate fields of "availability_status" */
export type Availability_Status_Aggregate_Fields = {
  __typename?: 'availability_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Availability_Status_Max_Fields>;
  min?: Maybe<Availability_Status_Min_Fields>;
};


/** aggregate fields of "availability_status" */
export type Availability_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Availability_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "availability_status". All fields are combined with a logical 'AND'. */
export type Availability_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Availability_Status_Bool_Exp>>;
  _not?: InputMaybe<Availability_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Availability_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "availability_status" */
export enum Availability_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  AvailabilityStatusPkey = 'availability_status_pkey'
}

export enum Availability_Status_Enum {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

/** Boolean expression to compare columns of type "availability_status_enum". All fields are combined with logical 'AND'. */
export type Availability_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Availability_Status_Enum>;
  _in?: InputMaybe<Array<Availability_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Availability_Status_Enum>;
  _nin?: InputMaybe<Array<Availability_Status_Enum>>;
};

/** input type for inserting data into table "availability_status" */
export type Availability_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Availability_Status_Max_Fields = {
  __typename?: 'availability_status_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Availability_Status_Min_Fields = {
  __typename?: 'availability_status_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "availability_status" */
export type Availability_Status_Mutation_Response = {
  __typename?: 'availability_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Availability_Status>;
};

/** on_conflict condition type for table "availability_status" */
export type Availability_Status_On_Conflict = {
  constraint: Availability_Status_Constraint;
  update_columns?: Array<Availability_Status_Update_Column>;
  where?: InputMaybe<Availability_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "availability_status". */
export type Availability_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: availability_status */
export type Availability_Status_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "availability_status" */
export enum Availability_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "availability_status" */
export type Availability_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "availability_status" */
export type Availability_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Availability_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Availability_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "availability_status" */
export enum Availability_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Availability_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Availability_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Availability_Status_Bool_Exp;
};

/** columns and relationships of "base_unit" */
export type Base_Unit = {
  __typename?: 'base_unit';
  availability: Availability_Status_Enum;
  billing_type: Billing_Type_Enum;
  /** An object relationship */
  company: Company;
  company_id: Scalars['uuid']['output'];
  content_measurement_unit?: Maybe<Measurement_Unit_Enum>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  gtin_type?: Maybe<Gtin_Type_Enum>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  main_photo?: Maybe<Photo>;
  main_photo_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  packagings: Array<Packaging>;
  /** An aggregate relationship */
  packagings_aggregate: Packaging_Aggregate;
  /** An array relationship */
  photos: Array<Base_Unit_Photo>;
  /** An aggregate relationship */
  photos_aggregate: Base_Unit_Photo_Aggregate;
  /** An object relationship */
  product: Product;
  product_id: Scalars['uuid']['output'];
  sku?: Maybe<Scalars['Int']['output']>;
  suggested_retail_price?: Maybe<Scalars['numeric']['output']>;
  unit_net_content?: Maybe<Scalars['numeric']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
  unit_type?: Maybe<Base_Unit_Type_Enum>;
};


/** columns and relationships of "base_unit" */
export type Base_UnitPackagingsArgs = {
  distinct_on?: InputMaybe<Array<Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_Order_By>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};


/** columns and relationships of "base_unit" */
export type Base_UnitPackagings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_Order_By>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};


/** columns and relationships of "base_unit" */
export type Base_UnitPhotosArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
};


/** columns and relationships of "base_unit" */
export type Base_UnitPhotos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
};

/** aggregated selection of "base_unit" */
export type Base_Unit_Aggregate = {
  __typename?: 'base_unit_aggregate';
  aggregate?: Maybe<Base_Unit_Aggregate_Fields>;
  nodes: Array<Base_Unit>;
};

export type Base_Unit_Aggregate_Bool_Exp = {
  count?: InputMaybe<Base_Unit_Aggregate_Bool_Exp_Count>;
};

export type Base_Unit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Base_Unit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Base_Unit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "base_unit" */
export type Base_Unit_Aggregate_Fields = {
  __typename?: 'base_unit_aggregate_fields';
  avg?: Maybe<Base_Unit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Base_Unit_Max_Fields>;
  min?: Maybe<Base_Unit_Min_Fields>;
  stddev?: Maybe<Base_Unit_Stddev_Fields>;
  stddev_pop?: Maybe<Base_Unit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Base_Unit_Stddev_Samp_Fields>;
  sum?: Maybe<Base_Unit_Sum_Fields>;
  var_pop?: Maybe<Base_Unit_Var_Pop_Fields>;
  var_samp?: Maybe<Base_Unit_Var_Samp_Fields>;
  variance?: Maybe<Base_Unit_Variance_Fields>;
};


/** aggregate fields of "base_unit" */
export type Base_Unit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Base_Unit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "base_unit" */
export type Base_Unit_Aggregate_Order_By = {
  avg?: InputMaybe<Base_Unit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Base_Unit_Max_Order_By>;
  min?: InputMaybe<Base_Unit_Min_Order_By>;
  stddev?: InputMaybe<Base_Unit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Base_Unit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Base_Unit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Base_Unit_Sum_Order_By>;
  var_pop?: InputMaybe<Base_Unit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Base_Unit_Var_Samp_Order_By>;
  variance?: InputMaybe<Base_Unit_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "base_unit" */
export type Base_Unit_Arr_Rel_Insert_Input = {
  data: Array<Base_Unit_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Base_Unit_On_Conflict>;
};

/** aggregate avg on columns */
export type Base_Unit_Avg_Fields = {
  __typename?: 'base_unit_avg_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "base_unit" */
export type Base_Unit_Avg_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "base_unit". All fields are combined with a logical 'AND'. */
export type Base_Unit_Bool_Exp = {
  _and?: InputMaybe<Array<Base_Unit_Bool_Exp>>;
  _not?: InputMaybe<Base_Unit_Bool_Exp>;
  _or?: InputMaybe<Array<Base_Unit_Bool_Exp>>;
  availability?: InputMaybe<Availability_Status_Enum_Comparison_Exp>;
  billing_type?: InputMaybe<Billing_Type_Enum_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deprecated_default_price_id?: InputMaybe<Uuid_Comparison_Exp>;
  deprecated_productSku?: InputMaybe<String_Comparison_Exp>;
  deprecated_sku?: InputMaybe<String_Comparison_Exp>;
  gtin?: InputMaybe<String_Comparison_Exp>;
  gtin_type?: InputMaybe<Gtin_Type_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  main_photo?: InputMaybe<Photo_Bool_Exp>;
  main_photo_id?: InputMaybe<Uuid_Comparison_Exp>;
  packagings?: InputMaybe<Packaging_Bool_Exp>;
  packagings_aggregate?: InputMaybe<Packaging_Aggregate_Bool_Exp>;
  photos?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
  photos_aggregate?: InputMaybe<Base_Unit_Photo_Aggregate_Bool_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  sku?: InputMaybe<Int_Comparison_Exp>;
  suggested_retail_price?: InputMaybe<Numeric_Comparison_Exp>;
  unit_net_content?: InputMaybe<Numeric_Comparison_Exp>;
  unit_quantity?: InputMaybe<Int_Comparison_Exp>;
  unit_type?: InputMaybe<Base_Unit_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "base_unit" */
export enum Base_Unit_Constraint {
  /** unique or primary key constraint on columns "company_id", "sku" */
  BaseUnitCompanyIdSkuKey = 'base_unit_company_id_sku_key',
  /** unique or primary key constraint on columns "id" */
  BaseUnitPkey = 'base_unit_pkey',
  /** unique or primary key constraint on columns "gtin", "company_id" */
  GtinCompanyIdUq = 'gtin_company_id_uq'
}

/** columns and relationships of "base_unit_history" */
export type Base_Unit_History = {
  __typename?: 'base_unit_history';
  availability: Scalars['String']['output'];
  billing_type: Billing_Type_Enum;
  company_id?: Maybe<Scalars['uuid']['output']>;
  content_measurement_unit?: Maybe<Measurement_Unit_Enum>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  gtin_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  main_photo?: Maybe<Photo>;
  main_photo_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  packagings: Array<Packaging_History>;
  /** An aggregate relationship */
  packagings_aggregate: Packaging_History_Aggregate;
  /** An array relationship */
  photos: Array<Base_Unit_Photo_History>;
  /** An aggregate relationship */
  photos_aggregate: Base_Unit_Photo_History_Aggregate;
  product_id?: Maybe<Scalars['uuid']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  suggested_retail_price?: Maybe<Scalars['numeric']['output']>;
  unit_net_content?: Maybe<Scalars['numeric']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
  unit_type?: Maybe<Base_Unit_Type_Enum>;
};


/** columns and relationships of "base_unit_history" */
export type Base_Unit_HistoryPackagingsArgs = {
  distinct_on?: InputMaybe<Array<Packaging_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_History_Order_By>>;
  where?: InputMaybe<Packaging_History_Bool_Exp>;
};


/** columns and relationships of "base_unit_history" */
export type Base_Unit_HistoryPackagings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Packaging_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_History_Order_By>>;
  where?: InputMaybe<Packaging_History_Bool_Exp>;
};


/** columns and relationships of "base_unit_history" */
export type Base_Unit_HistoryPhotosArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_History_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
};


/** columns and relationships of "base_unit_history" */
export type Base_Unit_HistoryPhotos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_History_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
};

/** aggregated selection of "base_unit_history" */
export type Base_Unit_History_Aggregate = {
  __typename?: 'base_unit_history_aggregate';
  aggregate?: Maybe<Base_Unit_History_Aggregate_Fields>;
  nodes: Array<Base_Unit_History>;
};

export type Base_Unit_History_Aggregate_Bool_Exp = {
  count?: InputMaybe<Base_Unit_History_Aggregate_Bool_Exp_Count>;
};

export type Base_Unit_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Base_Unit_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Base_Unit_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "base_unit_history" */
export type Base_Unit_History_Aggregate_Fields = {
  __typename?: 'base_unit_history_aggregate_fields';
  avg?: Maybe<Base_Unit_History_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Base_Unit_History_Max_Fields>;
  min?: Maybe<Base_Unit_History_Min_Fields>;
  stddev?: Maybe<Base_Unit_History_Stddev_Fields>;
  stddev_pop?: Maybe<Base_Unit_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Base_Unit_History_Stddev_Samp_Fields>;
  sum?: Maybe<Base_Unit_History_Sum_Fields>;
  var_pop?: Maybe<Base_Unit_History_Var_Pop_Fields>;
  var_samp?: Maybe<Base_Unit_History_Var_Samp_Fields>;
  variance?: Maybe<Base_Unit_History_Variance_Fields>;
};


/** aggregate fields of "base_unit_history" */
export type Base_Unit_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Base_Unit_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "base_unit_history" */
export type Base_Unit_History_Aggregate_Order_By = {
  avg?: InputMaybe<Base_Unit_History_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Base_Unit_History_Max_Order_By>;
  min?: InputMaybe<Base_Unit_History_Min_Order_By>;
  stddev?: InputMaybe<Base_Unit_History_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Base_Unit_History_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Base_Unit_History_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Base_Unit_History_Sum_Order_By>;
  var_pop?: InputMaybe<Base_Unit_History_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Base_Unit_History_Var_Samp_Order_By>;
  variance?: InputMaybe<Base_Unit_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "base_unit_history" */
export type Base_Unit_History_Arr_Rel_Insert_Input = {
  data: Array<Base_Unit_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Base_Unit_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Base_Unit_History_Avg_Fields = {
  __typename?: 'base_unit_history_avg_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "base_unit_history" */
export type Base_Unit_History_Avg_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "base_unit_history". All fields are combined with a logical 'AND'. */
export type Base_Unit_History_Bool_Exp = {
  _and?: InputMaybe<Array<Base_Unit_History_Bool_Exp>>;
  _not?: InputMaybe<Base_Unit_History_Bool_Exp>;
  _or?: InputMaybe<Array<Base_Unit_History_Bool_Exp>>;
  availability?: InputMaybe<String_Comparison_Exp>;
  billing_type?: InputMaybe<Billing_Type_Enum_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deprecated_default_price_id?: InputMaybe<Uuid_Comparison_Exp>;
  deprecated_productSku?: InputMaybe<String_Comparison_Exp>;
  deprecated_sku?: InputMaybe<String_Comparison_Exp>;
  gtin?: InputMaybe<String_Comparison_Exp>;
  gtin_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  main_photo?: InputMaybe<Photo_Bool_Exp>;
  main_photo_id?: InputMaybe<Uuid_Comparison_Exp>;
  packagings?: InputMaybe<Packaging_History_Bool_Exp>;
  packagings_aggregate?: InputMaybe<Packaging_History_Aggregate_Bool_Exp>;
  photos?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
  photos_aggregate?: InputMaybe<Base_Unit_Photo_History_Aggregate_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  sku?: InputMaybe<Int_Comparison_Exp>;
  suggested_retail_price?: InputMaybe<Numeric_Comparison_Exp>;
  unit_net_content?: InputMaybe<Numeric_Comparison_Exp>;
  unit_quantity?: InputMaybe<Int_Comparison_Exp>;
  unit_type?: InputMaybe<Base_Unit_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "base_unit_history" */
export enum Base_Unit_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  BaseUnitHistoryPkey = 'base_unit_history_pkey'
}

/** input type for incrementing numeric columns in table "base_unit_history" */
export type Base_Unit_History_Inc_Input = {
  sku?: InputMaybe<Scalars['Int']['input']>;
  suggested_retail_price?: InputMaybe<Scalars['numeric']['input']>;
  unit_net_content?: InputMaybe<Scalars['numeric']['input']>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "base_unit_history" */
export type Base_Unit_History_Insert_Input = {
  availability?: InputMaybe<Scalars['String']['input']>;
  billing_type?: InputMaybe<Billing_Type_Enum>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  main_photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  main_photo_id?: InputMaybe<Scalars['uuid']['input']>;
  packagings?: InputMaybe<Packaging_History_Arr_Rel_Insert_Input>;
  photos?: InputMaybe<Base_Unit_Photo_History_Arr_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  suggested_retail_price?: InputMaybe<Scalars['numeric']['input']>;
  unit_net_content?: InputMaybe<Scalars['numeric']['input']>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
  unit_type?: InputMaybe<Base_Unit_Type_Enum>;
};

/** aggregate max on columns */
export type Base_Unit_History_Max_Fields = {
  __typename?: 'base_unit_history_max_fields';
  availability?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  gtin_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  main_photo_id?: Maybe<Scalars['uuid']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  suggested_retail_price?: Maybe<Scalars['numeric']['output']>;
  unit_net_content?: Maybe<Scalars['numeric']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "base_unit_history" */
export type Base_Unit_History_Max_Order_By = {
  availability?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  gtin_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_photo_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Base_Unit_History_Min_Fields = {
  __typename?: 'base_unit_history_min_fields';
  availability?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  gtin_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  main_photo_id?: Maybe<Scalars['uuid']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  suggested_retail_price?: Maybe<Scalars['numeric']['output']>;
  unit_net_content?: Maybe<Scalars['numeric']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "base_unit_history" */
export type Base_Unit_History_Min_Order_By = {
  availability?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  gtin_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_photo_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "base_unit_history" */
export type Base_Unit_History_Mutation_Response = {
  __typename?: 'base_unit_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Unit_History>;
};

/** input type for inserting object relation for remote table "base_unit_history" */
export type Base_Unit_History_Obj_Rel_Insert_Input = {
  data: Base_Unit_History_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Base_Unit_History_On_Conflict>;
};

/** on_conflict condition type for table "base_unit_history" */
export type Base_Unit_History_On_Conflict = {
  constraint: Base_Unit_History_Constraint;
  update_columns?: Array<Base_Unit_History_Update_Column>;
  where?: InputMaybe<Base_Unit_History_Bool_Exp>;
};

/** Ordering options when selecting data from "base_unit_history". */
export type Base_Unit_History_Order_By = {
  availability?: InputMaybe<Order_By>;
  billing_type?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  content_measurement_unit?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  gtin_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_photo?: InputMaybe<Photo_Order_By>;
  main_photo_id?: InputMaybe<Order_By>;
  packagings_aggregate?: InputMaybe<Packaging_History_Aggregate_Order_By>;
  photos_aggregate?: InputMaybe<Base_Unit_Photo_History_Aggregate_Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
  unit_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: base_unit_history */
export type Base_Unit_History_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "base_unit_history" */
export enum Base_Unit_History_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  BillingType = 'billing_type',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContentMeasurementUnit = 'content_measurement_unit',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeprecatedDefaultPriceId = 'deprecated_default_price_id',
  /** column name */
  DeprecatedProductSku = 'deprecated_productSku',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  GtinType = 'gtin_type',
  /** column name */
  Id = 'id',
  /** column name */
  MainPhotoId = 'main_photo_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Sku = 'sku',
  /** column name */
  SuggestedRetailPrice = 'suggested_retail_price',
  /** column name */
  UnitNetContent = 'unit_net_content',
  /** column name */
  UnitQuantity = 'unit_quantity',
  /** column name */
  UnitType = 'unit_type'
}

/** input type for updating data in table "base_unit_history" */
export type Base_Unit_History_Set_Input = {
  availability?: InputMaybe<Scalars['String']['input']>;
  billing_type?: InputMaybe<Billing_Type_Enum>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  main_photo_id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  suggested_retail_price?: InputMaybe<Scalars['numeric']['input']>;
  unit_net_content?: InputMaybe<Scalars['numeric']['input']>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
  unit_type?: InputMaybe<Base_Unit_Type_Enum>;
};

/** aggregate stddev on columns */
export type Base_Unit_History_Stddev_Fields = {
  __typename?: 'base_unit_history_stddev_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "base_unit_history" */
export type Base_Unit_History_Stddev_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Base_Unit_History_Stddev_Pop_Fields = {
  __typename?: 'base_unit_history_stddev_pop_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "base_unit_history" */
export type Base_Unit_History_Stddev_Pop_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Base_Unit_History_Stddev_Samp_Fields = {
  __typename?: 'base_unit_history_stddev_samp_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "base_unit_history" */
export type Base_Unit_History_Stddev_Samp_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "base_unit_history" */
export type Base_Unit_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Unit_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Unit_History_Stream_Cursor_Value_Input = {
  availability?: InputMaybe<Scalars['String']['input']>;
  billing_type?: InputMaybe<Billing_Type_Enum>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  main_photo_id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  suggested_retail_price?: InputMaybe<Scalars['numeric']['input']>;
  unit_net_content?: InputMaybe<Scalars['numeric']['input']>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
  unit_type?: InputMaybe<Base_Unit_Type_Enum>;
};

/** aggregate sum on columns */
export type Base_Unit_History_Sum_Fields = {
  __typename?: 'base_unit_history_sum_fields';
  sku?: Maybe<Scalars['Int']['output']>;
  suggested_retail_price?: Maybe<Scalars['numeric']['output']>;
  unit_net_content?: Maybe<Scalars['numeric']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "base_unit_history" */
export type Base_Unit_History_Sum_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** update columns of table "base_unit_history" */
export enum Base_Unit_History_Update_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  BillingType = 'billing_type',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContentMeasurementUnit = 'content_measurement_unit',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeprecatedDefaultPriceId = 'deprecated_default_price_id',
  /** column name */
  DeprecatedProductSku = 'deprecated_productSku',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  GtinType = 'gtin_type',
  /** column name */
  Id = 'id',
  /** column name */
  MainPhotoId = 'main_photo_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Sku = 'sku',
  /** column name */
  SuggestedRetailPrice = 'suggested_retail_price',
  /** column name */
  UnitNetContent = 'unit_net_content',
  /** column name */
  UnitQuantity = 'unit_quantity',
  /** column name */
  UnitType = 'unit_type'
}

export type Base_Unit_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Base_Unit_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Base_Unit_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Base_Unit_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Base_Unit_History_Var_Pop_Fields = {
  __typename?: 'base_unit_history_var_pop_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "base_unit_history" */
export type Base_Unit_History_Var_Pop_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Base_Unit_History_Var_Samp_Fields = {
  __typename?: 'base_unit_history_var_samp_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "base_unit_history" */
export type Base_Unit_History_Var_Samp_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Base_Unit_History_Variance_Fields = {
  __typename?: 'base_unit_history_variance_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "base_unit_history" */
export type Base_Unit_History_Variance_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "base_unit" */
export type Base_Unit_Inc_Input = {
  sku?: InputMaybe<Scalars['Int']['input']>;
  suggested_retail_price?: InputMaybe<Scalars['numeric']['input']>;
  unit_net_content?: InputMaybe<Scalars['numeric']['input']>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "base_unit" */
export type Base_Unit_Insert_Input = {
  availability?: InputMaybe<Availability_Status_Enum>;
  billing_type?: InputMaybe<Billing_Type_Enum>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Gtin_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  main_photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  main_photo_id?: InputMaybe<Scalars['uuid']['input']>;
  packagings?: InputMaybe<Packaging_Arr_Rel_Insert_Input>;
  photos?: InputMaybe<Base_Unit_Photo_Arr_Rel_Insert_Input>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  suggested_retail_price?: InputMaybe<Scalars['numeric']['input']>;
  unit_net_content?: InputMaybe<Scalars['numeric']['input']>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
  unit_type?: InputMaybe<Base_Unit_Type_Enum>;
};

/** aggregate max on columns */
export type Base_Unit_Max_Fields = {
  __typename?: 'base_unit_max_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  main_photo_id?: Maybe<Scalars['uuid']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  suggested_retail_price?: Maybe<Scalars['numeric']['output']>;
  unit_net_content?: Maybe<Scalars['numeric']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "base_unit" */
export type Base_Unit_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_photo_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Base_Unit_Min_Fields = {
  __typename?: 'base_unit_min_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  main_photo_id?: Maybe<Scalars['uuid']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  suggested_retail_price?: Maybe<Scalars['numeric']['output']>;
  unit_net_content?: Maybe<Scalars['numeric']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "base_unit" */
export type Base_Unit_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_photo_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "base_unit" */
export type Base_Unit_Mutation_Response = {
  __typename?: 'base_unit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Unit>;
};

/** input type for inserting object relation for remote table "base_unit" */
export type Base_Unit_Obj_Rel_Insert_Input = {
  data: Base_Unit_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Base_Unit_On_Conflict>;
};

/** on_conflict condition type for table "base_unit" */
export type Base_Unit_On_Conflict = {
  constraint: Base_Unit_Constraint;
  update_columns?: Array<Base_Unit_Update_Column>;
  where?: InputMaybe<Base_Unit_Bool_Exp>;
};

/** Ordering options when selecting data from "base_unit". */
export type Base_Unit_Order_By = {
  availability?: InputMaybe<Order_By>;
  billing_type?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  content_measurement_unit?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  gtin_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_photo?: InputMaybe<Photo_Order_By>;
  main_photo_id?: InputMaybe<Order_By>;
  packagings_aggregate?: InputMaybe<Packaging_Aggregate_Order_By>;
  photos_aggregate?: InputMaybe<Base_Unit_Photo_Aggregate_Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
  unit_type?: InputMaybe<Order_By>;
};

/** columns and relationships of "base_unit_photo" */
export type Base_Unit_Photo = {
  __typename?: 'base_unit_photo';
  base_unit_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  photo: Photo;
  photo_id: Scalars['uuid']['output'];
};

/** aggregated selection of "base_unit_photo" */
export type Base_Unit_Photo_Aggregate = {
  __typename?: 'base_unit_photo_aggregate';
  aggregate?: Maybe<Base_Unit_Photo_Aggregate_Fields>;
  nodes: Array<Base_Unit_Photo>;
};

export type Base_Unit_Photo_Aggregate_Bool_Exp = {
  count?: InputMaybe<Base_Unit_Photo_Aggregate_Bool_Exp_Count>;
};

export type Base_Unit_Photo_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Base_Unit_Photo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "base_unit_photo" */
export type Base_Unit_Photo_Aggregate_Fields = {
  __typename?: 'base_unit_photo_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Base_Unit_Photo_Max_Fields>;
  min?: Maybe<Base_Unit_Photo_Min_Fields>;
};


/** aggregate fields of "base_unit_photo" */
export type Base_Unit_Photo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Base_Unit_Photo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "base_unit_photo" */
export type Base_Unit_Photo_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Base_Unit_Photo_Max_Order_By>;
  min?: InputMaybe<Base_Unit_Photo_Min_Order_By>;
};

/** input type for inserting array relation for remote table "base_unit_photo" */
export type Base_Unit_Photo_Arr_Rel_Insert_Input = {
  data: Array<Base_Unit_Photo_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Base_Unit_Photo_On_Conflict>;
};

/** Boolean expression to filter rows from the table "base_unit_photo". All fields are combined with a logical 'AND'. */
export type Base_Unit_Photo_Bool_Exp = {
  _and?: InputMaybe<Array<Base_Unit_Photo_Bool_Exp>>;
  _not?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
  _or?: InputMaybe<Array<Base_Unit_Photo_Bool_Exp>>;
  base_unit_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  photo?: InputMaybe<Photo_Bool_Exp>;
  photo_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "base_unit_photo" */
export enum Base_Unit_Photo_Constraint {
  /** unique or primary key constraint on columns "id" */
  BaseUnitPhotoPkey = 'base_unit_photo_pkey'
}

/** columns and relationships of "base_unit_photo_history" */
export type Base_Unit_Photo_History = {
  __typename?: 'base_unit_photo_history';
  base_unit_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  photo?: Maybe<Photo>;
  photo_id: Scalars['uuid']['output'];
};

/** aggregated selection of "base_unit_photo_history" */
export type Base_Unit_Photo_History_Aggregate = {
  __typename?: 'base_unit_photo_history_aggregate';
  aggregate?: Maybe<Base_Unit_Photo_History_Aggregate_Fields>;
  nodes: Array<Base_Unit_Photo_History>;
};

export type Base_Unit_Photo_History_Aggregate_Bool_Exp = {
  count?: InputMaybe<Base_Unit_Photo_History_Aggregate_Bool_Exp_Count>;
};

export type Base_Unit_Photo_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Base_Unit_Photo_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "base_unit_photo_history" */
export type Base_Unit_Photo_History_Aggregate_Fields = {
  __typename?: 'base_unit_photo_history_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Base_Unit_Photo_History_Max_Fields>;
  min?: Maybe<Base_Unit_Photo_History_Min_Fields>;
};


/** aggregate fields of "base_unit_photo_history" */
export type Base_Unit_Photo_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Base_Unit_Photo_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "base_unit_photo_history" */
export type Base_Unit_Photo_History_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Base_Unit_Photo_History_Max_Order_By>;
  min?: InputMaybe<Base_Unit_Photo_History_Min_Order_By>;
};

/** input type for inserting array relation for remote table "base_unit_photo_history" */
export type Base_Unit_Photo_History_Arr_Rel_Insert_Input = {
  data: Array<Base_Unit_Photo_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Base_Unit_Photo_History_On_Conflict>;
};

/** Boolean expression to filter rows from the table "base_unit_photo_history". All fields are combined with a logical 'AND'. */
export type Base_Unit_Photo_History_Bool_Exp = {
  _and?: InputMaybe<Array<Base_Unit_Photo_History_Bool_Exp>>;
  _not?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
  _or?: InputMaybe<Array<Base_Unit_Photo_History_Bool_Exp>>;
  base_unit_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  photo?: InputMaybe<Photo_Bool_Exp>;
  photo_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "base_unit_photo_history" */
export enum Base_Unit_Photo_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  BaseUnitPhotoHistoryPkey = 'base_unit_photo_history_pkey'
}

/** input type for inserting data into table "base_unit_photo_history" */
export type Base_Unit_Photo_History_Insert_Input = {
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  photo_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Base_Unit_Photo_History_Max_Fields = {
  __typename?: 'base_unit_photo_history_max_fields';
  base_unit_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  photo_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "base_unit_photo_history" */
export type Base_Unit_Photo_History_Max_Order_By = {
  base_unit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photo_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Base_Unit_Photo_History_Min_Fields = {
  __typename?: 'base_unit_photo_history_min_fields';
  base_unit_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  photo_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "base_unit_photo_history" */
export type Base_Unit_Photo_History_Min_Order_By = {
  base_unit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photo_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "base_unit_photo_history" */
export type Base_Unit_Photo_History_Mutation_Response = {
  __typename?: 'base_unit_photo_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Unit_Photo_History>;
};

/** on_conflict condition type for table "base_unit_photo_history" */
export type Base_Unit_Photo_History_On_Conflict = {
  constraint: Base_Unit_Photo_History_Constraint;
  update_columns?: Array<Base_Unit_Photo_History_Update_Column>;
  where?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
};

/** Ordering options when selecting data from "base_unit_photo_history". */
export type Base_Unit_Photo_History_Order_By = {
  base_unit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photo?: InputMaybe<Photo_Order_By>;
  photo_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: base_unit_photo_history */
export type Base_Unit_Photo_History_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "base_unit_photo_history" */
export enum Base_Unit_Photo_History_Select_Column {
  /** column name */
  BaseUnitId = 'base_unit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoId = 'photo_id'
}

/** input type for updating data in table "base_unit_photo_history" */
export type Base_Unit_Photo_History_Set_Input = {
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  photo_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "base_unit_photo_history" */
export type Base_Unit_Photo_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Unit_Photo_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Unit_Photo_History_Stream_Cursor_Value_Input = {
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  photo_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "base_unit_photo_history" */
export enum Base_Unit_Photo_History_Update_Column {
  /** column name */
  BaseUnitId = 'base_unit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoId = 'photo_id'
}

export type Base_Unit_Photo_History_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Base_Unit_Photo_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Base_Unit_Photo_History_Bool_Exp;
};

/** input type for inserting data into table "base_unit_photo" */
export type Base_Unit_Photo_Insert_Input = {
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  photo_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Base_Unit_Photo_Max_Fields = {
  __typename?: 'base_unit_photo_max_fields';
  base_unit_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  photo_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "base_unit_photo" */
export type Base_Unit_Photo_Max_Order_By = {
  base_unit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photo_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Base_Unit_Photo_Min_Fields = {
  __typename?: 'base_unit_photo_min_fields';
  base_unit_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  photo_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "base_unit_photo" */
export type Base_Unit_Photo_Min_Order_By = {
  base_unit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photo_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "base_unit_photo" */
export type Base_Unit_Photo_Mutation_Response = {
  __typename?: 'base_unit_photo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Unit_Photo>;
};

/** on_conflict condition type for table "base_unit_photo" */
export type Base_Unit_Photo_On_Conflict = {
  constraint: Base_Unit_Photo_Constraint;
  update_columns?: Array<Base_Unit_Photo_Update_Column>;
  where?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
};

/** Ordering options when selecting data from "base_unit_photo". */
export type Base_Unit_Photo_Order_By = {
  base_unit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photo?: InputMaybe<Photo_Order_By>;
  photo_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: base_unit_photo */
export type Base_Unit_Photo_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "base_unit_photo" */
export enum Base_Unit_Photo_Select_Column {
  /** column name */
  BaseUnitId = 'base_unit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoId = 'photo_id'
}

/** input type for updating data in table "base_unit_photo" */
export type Base_Unit_Photo_Set_Input = {
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  photo_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "base_unit_photo" */
export type Base_Unit_Photo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Unit_Photo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Unit_Photo_Stream_Cursor_Value_Input = {
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  photo_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "base_unit_photo" */
export enum Base_Unit_Photo_Update_Column {
  /** column name */
  BaseUnitId = 'base_unit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoId = 'photo_id'
}

export type Base_Unit_Photo_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Base_Unit_Photo_Set_Input>;
  /** filter the rows which have to be updated */
  where: Base_Unit_Photo_Bool_Exp;
};

/** primary key columns input for table: base_unit */
export type Base_Unit_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "base_unit" */
export enum Base_Unit_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  BillingType = 'billing_type',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContentMeasurementUnit = 'content_measurement_unit',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeprecatedDefaultPriceId = 'deprecated_default_price_id',
  /** column name */
  DeprecatedProductSku = 'deprecated_productSku',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  GtinType = 'gtin_type',
  /** column name */
  Id = 'id',
  /** column name */
  MainPhotoId = 'main_photo_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Sku = 'sku',
  /** column name */
  SuggestedRetailPrice = 'suggested_retail_price',
  /** column name */
  UnitNetContent = 'unit_net_content',
  /** column name */
  UnitQuantity = 'unit_quantity',
  /** column name */
  UnitType = 'unit_type'
}

/** input type for updating data in table "base_unit" */
export type Base_Unit_Set_Input = {
  availability?: InputMaybe<Availability_Status_Enum>;
  billing_type?: InputMaybe<Billing_Type_Enum>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Gtin_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  main_photo_id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  suggested_retail_price?: InputMaybe<Scalars['numeric']['input']>;
  unit_net_content?: InputMaybe<Scalars['numeric']['input']>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
  unit_type?: InputMaybe<Base_Unit_Type_Enum>;
};

/** aggregate stddev on columns */
export type Base_Unit_Stddev_Fields = {
  __typename?: 'base_unit_stddev_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "base_unit" */
export type Base_Unit_Stddev_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Base_Unit_Stddev_Pop_Fields = {
  __typename?: 'base_unit_stddev_pop_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "base_unit" */
export type Base_Unit_Stddev_Pop_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Base_Unit_Stddev_Samp_Fields = {
  __typename?: 'base_unit_stddev_samp_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "base_unit" */
export type Base_Unit_Stddev_Samp_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "base_unit" */
export type Base_Unit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Unit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Unit_Stream_Cursor_Value_Input = {
  availability?: InputMaybe<Availability_Status_Enum>;
  billing_type?: InputMaybe<Billing_Type_Enum>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Gtin_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  main_photo_id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  suggested_retail_price?: InputMaybe<Scalars['numeric']['input']>;
  unit_net_content?: InputMaybe<Scalars['numeric']['input']>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
  unit_type?: InputMaybe<Base_Unit_Type_Enum>;
};

/** aggregate sum on columns */
export type Base_Unit_Sum_Fields = {
  __typename?: 'base_unit_sum_fields';
  sku?: Maybe<Scalars['Int']['output']>;
  suggested_retail_price?: Maybe<Scalars['numeric']['output']>;
  unit_net_content?: Maybe<Scalars['numeric']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "base_unit" */
export type Base_Unit_Sum_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "base_unit_type" */
export type Base_Unit_Type = {
  __typename?: 'base_unit_type';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "base_unit_type" */
export type Base_Unit_Type_Aggregate = {
  __typename?: 'base_unit_type_aggregate';
  aggregate?: Maybe<Base_Unit_Type_Aggregate_Fields>;
  nodes: Array<Base_Unit_Type>;
};

/** aggregate fields of "base_unit_type" */
export type Base_Unit_Type_Aggregate_Fields = {
  __typename?: 'base_unit_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Base_Unit_Type_Max_Fields>;
  min?: Maybe<Base_Unit_Type_Min_Fields>;
};


/** aggregate fields of "base_unit_type" */
export type Base_Unit_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Base_Unit_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "base_unit_type". All fields are combined with a logical 'AND'. */
export type Base_Unit_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Base_Unit_Type_Bool_Exp>>;
  _not?: InputMaybe<Base_Unit_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Base_Unit_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "base_unit_type" */
export enum Base_Unit_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  BaseUnitTypePkey = 'base_unit_type_pkey'
}

export enum Base_Unit_Type_Enum {
  Bag = 'BAG',
  BagInBox = 'BAG_IN_BOX',
  Bottle = 'BOTTLE',
  Box = 'BOX',
  BoxSet = 'BOX_SET',
  Bulk = 'BULK',
  Can = 'CAN',
  Carton = 'CARTON',
  Cup = 'CUP',
  Flask = 'FLASK',
  Jar = 'JAR',
  Keg = 'KEG',
  /**   */
  Net = 'NET',
  Pack = 'PACK',
  Piece = 'PIECE',
  Sachet = 'SACHET',
  Slice = 'SLICE',
  Spray = 'SPRAY',
  Tin = 'TIN',
  Tray = 'TRAY',
  Tube = 'TUBE'
}

/** Boolean expression to compare columns of type "base_unit_type_enum". All fields are combined with logical 'AND'. */
export type Base_Unit_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Base_Unit_Type_Enum>;
  _in?: InputMaybe<Array<Base_Unit_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Base_Unit_Type_Enum>;
  _nin?: InputMaybe<Array<Base_Unit_Type_Enum>>;
};

/** input type for inserting data into table "base_unit_type" */
export type Base_Unit_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Base_Unit_Type_Max_Fields = {
  __typename?: 'base_unit_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Base_Unit_Type_Min_Fields = {
  __typename?: 'base_unit_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "base_unit_type" */
export type Base_Unit_Type_Mutation_Response = {
  __typename?: 'base_unit_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Unit_Type>;
};

/** on_conflict condition type for table "base_unit_type" */
export type Base_Unit_Type_On_Conflict = {
  constraint: Base_Unit_Type_Constraint;
  update_columns?: Array<Base_Unit_Type_Update_Column>;
  where?: InputMaybe<Base_Unit_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "base_unit_type". */
export type Base_Unit_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: base_unit_type */
export type Base_Unit_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "base_unit_type" */
export enum Base_Unit_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "base_unit_type" */
export type Base_Unit_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "base_unit_type" */
export type Base_Unit_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Unit_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Unit_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "base_unit_type" */
export enum Base_Unit_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Base_Unit_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Base_Unit_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Base_Unit_Type_Bool_Exp;
};

/** update columns of table "base_unit" */
export enum Base_Unit_Update_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  BillingType = 'billing_type',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContentMeasurementUnit = 'content_measurement_unit',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeprecatedDefaultPriceId = 'deprecated_default_price_id',
  /** column name */
  DeprecatedProductSku = 'deprecated_productSku',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  GtinType = 'gtin_type',
  /** column name */
  Id = 'id',
  /** column name */
  MainPhotoId = 'main_photo_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Sku = 'sku',
  /** column name */
  SuggestedRetailPrice = 'suggested_retail_price',
  /** column name */
  UnitNetContent = 'unit_net_content',
  /** column name */
  UnitQuantity = 'unit_quantity',
  /** column name */
  UnitType = 'unit_type'
}

export type Base_Unit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Base_Unit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Base_Unit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Base_Unit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Base_Unit_Var_Pop_Fields = {
  __typename?: 'base_unit_var_pop_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "base_unit" */
export type Base_Unit_Var_Pop_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Base_Unit_Var_Samp_Fields = {
  __typename?: 'base_unit_var_samp_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "base_unit" */
export type Base_Unit_Var_Samp_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Base_Unit_Variance_Fields = {
  __typename?: 'base_unit_variance_fields';
  sku?: Maybe<Scalars['Float']['output']>;
  suggested_retail_price?: Maybe<Scalars['Float']['output']>;
  unit_net_content?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "base_unit" */
export type Base_Unit_Variance_Order_By = {
  sku?: InputMaybe<Order_By>;
  suggested_retail_price?: InputMaybe<Order_By>;
  unit_net_content?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "basket" */
export type Basket = {
  __typename?: 'basket';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  last_updated_date: Scalars['timestamptz']['output'];
  /** An array relationship */
  packaging_quantities: Array<Basket_Packaging_Quantity>;
  /** An aggregate relationship */
  packaging_quantities_aggregate: Basket_Packaging_Quantity_Aggregate;
  status: Basket_Status_Enum;
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "basket" */
export type BasketPackaging_QuantitiesArgs = {
  distinct_on?: InputMaybe<Array<Basket_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
};


/** columns and relationships of "basket" */
export type BasketPackaging_Quantities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Basket_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
};

/** aggregated selection of "basket" */
export type Basket_Aggregate = {
  __typename?: 'basket_aggregate';
  aggregate?: Maybe<Basket_Aggregate_Fields>;
  nodes: Array<Basket>;
};

/** aggregate fields of "basket" */
export type Basket_Aggregate_Fields = {
  __typename?: 'basket_aggregate_fields';
  avg?: Maybe<Basket_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Basket_Max_Fields>;
  min?: Maybe<Basket_Min_Fields>;
  stddev?: Maybe<Basket_Stddev_Fields>;
  stddev_pop?: Maybe<Basket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Basket_Stddev_Samp_Fields>;
  sum?: Maybe<Basket_Sum_Fields>;
  var_pop?: Maybe<Basket_Var_Pop_Fields>;
  var_samp?: Maybe<Basket_Var_Samp_Fields>;
  variance?: Maybe<Basket_Variance_Fields>;
};


/** aggregate fields of "basket" */
export type Basket_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Basket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Basket_Avg_Fields = {
  __typename?: 'basket_avg_fields';
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** Boolean expression to filter rows from the table "basket". All fields are combined with a logical 'AND'. */
export type Basket_Bool_Exp = {
  _and?: InputMaybe<Array<Basket_Bool_Exp>>;
  _not?: InputMaybe<Basket_Bool_Exp>;
  _or?: InputMaybe<Array<Basket_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_updated_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  packaging_quantities?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
  packaging_quantities_aggregate?: InputMaybe<Basket_Packaging_Quantity_Aggregate_Bool_Exp>;
  status?: InputMaybe<Basket_Status_Enum_Comparison_Exp>;
  total_price_excl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  total_price_incl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  total_tax?: InputMaybe<Numeric_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "basket" */
export enum Basket_Constraint {
  /** unique or primary key constraint on columns "id" */
  BasketPkey = 'basket_pkey'
}

/** input type for inserting data into table "basket" */
export type Basket_Insert_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_updated_date?: InputMaybe<Scalars['timestamptz']['input']>;
  packaging_quantities?: InputMaybe<Basket_Packaging_Quantity_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Basket_Status_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Basket_Max_Fields = {
  __typename?: 'basket_max_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_updated_date?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Basket_Min_Fields = {
  __typename?: 'basket_min_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_updated_date?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "basket" */
export type Basket_Mutation_Response = {
  __typename?: 'basket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Basket>;
};

/** input type for inserting object relation for remote table "basket" */
export type Basket_Obj_Rel_Insert_Input = {
  data: Basket_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Basket_On_Conflict>;
};

/** on_conflict condition type for table "basket" */
export type Basket_On_Conflict = {
  constraint: Basket_Constraint;
  update_columns?: Array<Basket_Update_Column>;
  where?: InputMaybe<Basket_Bool_Exp>;
};

/** Ordering options when selecting data from "basket". */
export type Basket_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_updated_date?: InputMaybe<Order_By>;
  packaging_quantities_aggregate?: InputMaybe<Basket_Packaging_Quantity_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  total_price_excl_tax?: InputMaybe<Order_By>;
  total_price_incl_tax?: InputMaybe<Order_By>;
  total_tax?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "basket_packaging_quantity" */
export type Basket_Packaging_Quantity = {
  __typename?: 'basket_packaging_quantity';
  /** An object relationship */
  basket: Basket;
  basket_id: Scalars['uuid']['output'];
  /** An object relationship */
  contract: Contract;
  contract_id: Scalars['uuid']['output'];
  created_date: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  last_updated_date: Scalars['timestamptz']['output'];
  /** An object relationship */
  packaging: Packaging;
  packaging_id: Scalars['uuid']['output'];
  quantity: Scalars['Int']['output'];
};

/** aggregated selection of "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Aggregate = {
  __typename?: 'basket_packaging_quantity_aggregate';
  aggregate?: Maybe<Basket_Packaging_Quantity_Aggregate_Fields>;
  nodes: Array<Basket_Packaging_Quantity>;
};

export type Basket_Packaging_Quantity_Aggregate_Bool_Exp = {
  count?: InputMaybe<Basket_Packaging_Quantity_Aggregate_Bool_Exp_Count>;
};

export type Basket_Packaging_Quantity_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Basket_Packaging_Quantity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Aggregate_Fields = {
  __typename?: 'basket_packaging_quantity_aggregate_fields';
  avg?: Maybe<Basket_Packaging_Quantity_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Basket_Packaging_Quantity_Max_Fields>;
  min?: Maybe<Basket_Packaging_Quantity_Min_Fields>;
  stddev?: Maybe<Basket_Packaging_Quantity_Stddev_Fields>;
  stddev_pop?: Maybe<Basket_Packaging_Quantity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Basket_Packaging_Quantity_Stddev_Samp_Fields>;
  sum?: Maybe<Basket_Packaging_Quantity_Sum_Fields>;
  var_pop?: Maybe<Basket_Packaging_Quantity_Var_Pop_Fields>;
  var_samp?: Maybe<Basket_Packaging_Quantity_Var_Samp_Fields>;
  variance?: Maybe<Basket_Packaging_Quantity_Variance_Fields>;
};


/** aggregate fields of "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Basket_Packaging_Quantity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Aggregate_Order_By = {
  avg?: InputMaybe<Basket_Packaging_Quantity_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Basket_Packaging_Quantity_Max_Order_By>;
  min?: InputMaybe<Basket_Packaging_Quantity_Min_Order_By>;
  stddev?: InputMaybe<Basket_Packaging_Quantity_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Basket_Packaging_Quantity_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Basket_Packaging_Quantity_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Basket_Packaging_Quantity_Sum_Order_By>;
  var_pop?: InputMaybe<Basket_Packaging_Quantity_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Basket_Packaging_Quantity_Var_Samp_Order_By>;
  variance?: InputMaybe<Basket_Packaging_Quantity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Arr_Rel_Insert_Input = {
  data: Array<Basket_Packaging_Quantity_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Basket_Packaging_Quantity_On_Conflict>;
};

/** aggregate avg on columns */
export type Basket_Packaging_Quantity_Avg_Fields = {
  __typename?: 'basket_packaging_quantity_avg_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "basket_packaging_quantity". All fields are combined with a logical 'AND'. */
export type Basket_Packaging_Quantity_Bool_Exp = {
  _and?: InputMaybe<Array<Basket_Packaging_Quantity_Bool_Exp>>;
  _not?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
  _or?: InputMaybe<Array<Basket_Packaging_Quantity_Bool_Exp>>;
  basket?: InputMaybe<Basket_Bool_Exp>;
  basket_id?: InputMaybe<Uuid_Comparison_Exp>;
  contract?: InputMaybe<Contract_Bool_Exp>;
  contract_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_updated_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  packaging?: InputMaybe<Packaging_Bool_Exp>;
  packaging_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "basket_packaging_quantity" */
export enum Basket_Packaging_Quantity_Constraint {
  /** unique or primary key constraint on columns "basket_id", "packaging_id", "contract_id" */
  BasketPackagingQuantityBasketIdPackagingIdContractIdKe = 'basket_packaging_quantity_basket_id_packaging_id_contract_id_ke',
  /** unique or primary key constraint on columns "id" */
  BasketPackagingQuantityIdKey = 'basket_packaging_quantity_id_key',
  /** unique or primary key constraint on columns "id" */
  BasketPackagingQuantityPkey = 'basket_packaging_quantity_pkey'
}

/** input type for incrementing numeric columns in table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Inc_Input = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Insert_Input = {
  basket?: InputMaybe<Basket_Obj_Rel_Insert_Input>;
  basket_id?: InputMaybe<Scalars['uuid']['input']>;
  contract?: InputMaybe<Contract_Obj_Rel_Insert_Input>;
  contract_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_updated_date?: InputMaybe<Scalars['timestamptz']['input']>;
  packaging?: InputMaybe<Packaging_Obj_Rel_Insert_Input>;
  packaging_id?: InputMaybe<Scalars['uuid']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Basket_Packaging_Quantity_Max_Fields = {
  __typename?: 'basket_packaging_quantity_max_fields';
  basket_id?: Maybe<Scalars['uuid']['output']>;
  contract_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_updated_date?: Maybe<Scalars['timestamptz']['output']>;
  packaging_id?: Maybe<Scalars['uuid']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Max_Order_By = {
  basket_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_updated_date?: InputMaybe<Order_By>;
  packaging_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Basket_Packaging_Quantity_Min_Fields = {
  __typename?: 'basket_packaging_quantity_min_fields';
  basket_id?: Maybe<Scalars['uuid']['output']>;
  contract_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_updated_date?: Maybe<Scalars['timestamptz']['output']>;
  packaging_id?: Maybe<Scalars['uuid']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Min_Order_By = {
  basket_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_updated_date?: InputMaybe<Order_By>;
  packaging_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Mutation_Response = {
  __typename?: 'basket_packaging_quantity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Basket_Packaging_Quantity>;
};

/** on_conflict condition type for table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_On_Conflict = {
  constraint: Basket_Packaging_Quantity_Constraint;
  update_columns?: Array<Basket_Packaging_Quantity_Update_Column>;
  where?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
};

/** Ordering options when selecting data from "basket_packaging_quantity". */
export type Basket_Packaging_Quantity_Order_By = {
  basket?: InputMaybe<Basket_Order_By>;
  basket_id?: InputMaybe<Order_By>;
  contract?: InputMaybe<Contract_Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_updated_date?: InputMaybe<Order_By>;
  packaging?: InputMaybe<Packaging_Order_By>;
  packaging_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: basket_packaging_quantity */
export type Basket_Packaging_Quantity_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "basket_packaging_quantity" */
export enum Basket_Packaging_Quantity_Select_Column {
  /** column name */
  BasketId = 'basket_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdatedDate = 'last_updated_date',
  /** column name */
  PackagingId = 'packaging_id',
  /** column name */
  Quantity = 'quantity'
}

/** input type for updating data in table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Set_Input = {
  basket_id?: InputMaybe<Scalars['uuid']['input']>;
  contract_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_updated_date?: InputMaybe<Scalars['timestamptz']['input']>;
  packaging_id?: InputMaybe<Scalars['uuid']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Basket_Packaging_Quantity_Stddev_Fields = {
  __typename?: 'basket_packaging_quantity_stddev_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Basket_Packaging_Quantity_Stddev_Pop_Fields = {
  __typename?: 'basket_packaging_quantity_stddev_pop_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Basket_Packaging_Quantity_Stddev_Samp_Fields = {
  __typename?: 'basket_packaging_quantity_stddev_samp_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Basket_Packaging_Quantity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Basket_Packaging_Quantity_Stream_Cursor_Value_Input = {
  basket_id?: InputMaybe<Scalars['uuid']['input']>;
  contract_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_updated_date?: InputMaybe<Scalars['timestamptz']['input']>;
  packaging_id?: InputMaybe<Scalars['uuid']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Basket_Packaging_Quantity_Sum_Fields = {
  __typename?: 'basket_packaging_quantity_sum_fields';
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "basket_packaging_quantity" */
export enum Basket_Packaging_Quantity_Update_Column {
  /** column name */
  BasketId = 'basket_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdatedDate = 'last_updated_date',
  /** column name */
  PackagingId = 'packaging_id',
  /** column name */
  Quantity = 'quantity'
}

export type Basket_Packaging_Quantity_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Basket_Packaging_Quantity_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Basket_Packaging_Quantity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Basket_Packaging_Quantity_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Basket_Packaging_Quantity_Var_Pop_Fields = {
  __typename?: 'basket_packaging_quantity_var_pop_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Basket_Packaging_Quantity_Var_Samp_Fields = {
  __typename?: 'basket_packaging_quantity_var_samp_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Basket_Packaging_Quantity_Variance_Fields = {
  __typename?: 'basket_packaging_quantity_variance_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "basket_packaging_quantity" */
export type Basket_Packaging_Quantity_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: basket */
export type Basket_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "basket" */
export enum Basket_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdatedDate = 'last_updated_date',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "basket" */
export type Basket_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_updated_date?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Basket_Status_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "basket_status" */
export type Basket_Status = {
  __typename?: 'basket_status';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "basket_status" */
export type Basket_Status_Aggregate = {
  __typename?: 'basket_status_aggregate';
  aggregate?: Maybe<Basket_Status_Aggregate_Fields>;
  nodes: Array<Basket_Status>;
};

/** aggregate fields of "basket_status" */
export type Basket_Status_Aggregate_Fields = {
  __typename?: 'basket_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Basket_Status_Max_Fields>;
  min?: Maybe<Basket_Status_Min_Fields>;
};


/** aggregate fields of "basket_status" */
export type Basket_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Basket_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "basket_status". All fields are combined with a logical 'AND'. */
export type Basket_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Basket_Status_Bool_Exp>>;
  _not?: InputMaybe<Basket_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Basket_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "basket_status" */
export enum Basket_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  BasketStatusPkey = 'basket_status_pkey'
}

export enum Basket_Status_Enum {
  Cancelled = 'CANCELLED',
  Open = 'OPEN',
  Ordered = 'ORDERED'
}

/** Boolean expression to compare columns of type "basket_status_enum". All fields are combined with logical 'AND'. */
export type Basket_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Basket_Status_Enum>;
  _in?: InputMaybe<Array<Basket_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Basket_Status_Enum>;
  _nin?: InputMaybe<Array<Basket_Status_Enum>>;
};

/** input type for inserting data into table "basket_status" */
export type Basket_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Basket_Status_Max_Fields = {
  __typename?: 'basket_status_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Basket_Status_Min_Fields = {
  __typename?: 'basket_status_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "basket_status" */
export type Basket_Status_Mutation_Response = {
  __typename?: 'basket_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Basket_Status>;
};

/** on_conflict condition type for table "basket_status" */
export type Basket_Status_On_Conflict = {
  constraint: Basket_Status_Constraint;
  update_columns?: Array<Basket_Status_Update_Column>;
  where?: InputMaybe<Basket_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "basket_status". */
export type Basket_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: basket_status */
export type Basket_Status_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "basket_status" */
export enum Basket_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "basket_status" */
export type Basket_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "basket_status" */
export type Basket_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Basket_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Basket_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "basket_status" */
export enum Basket_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Basket_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Basket_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Basket_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Basket_Stddev_Fields = {
  __typename?: 'basket_stddev_fields';
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_pop on columns */
export type Basket_Stddev_Pop_Fields = {
  __typename?: 'basket_stddev_pop_fields';
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_samp on columns */
export type Basket_Stddev_Samp_Fields = {
  __typename?: 'basket_stddev_samp_fields';
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** Streaming cursor of the table "basket" */
export type Basket_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Basket_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Basket_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_updated_date?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Basket_Status_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Basket_Sum_Fields = {
  __typename?: 'basket_sum_fields';
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "basket" */
export enum Basket_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdatedDate = 'last_updated_date',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

export type Basket_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Basket_Set_Input>;
  /** filter the rows which have to be updated */
  where: Basket_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Basket_Var_Pop_Fields = {
  __typename?: 'basket_var_pop_fields';
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_samp on columns */
export type Basket_Var_Samp_Fields = {
  __typename?: 'basket_var_samp_fields';
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate variance on columns */
export type Basket_Variance_Fields = {
  __typename?: 'basket_variance_fields';
  /** A computed field, executes function "compute_basket_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_basket_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "billing_type" */
export type Billing_Type = {
  __typename?: 'billing_type';
  comment?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

/** aggregated selection of "billing_type" */
export type Billing_Type_Aggregate = {
  __typename?: 'billing_type_aggregate';
  aggregate?: Maybe<Billing_Type_Aggregate_Fields>;
  nodes: Array<Billing_Type>;
};

/** aggregate fields of "billing_type" */
export type Billing_Type_Aggregate_Fields = {
  __typename?: 'billing_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Billing_Type_Max_Fields>;
  min?: Maybe<Billing_Type_Min_Fields>;
};


/** aggregate fields of "billing_type" */
export type Billing_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Billing_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "billing_type". All fields are combined with a logical 'AND'. */
export type Billing_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Billing_Type_Bool_Exp>>;
  _not?: InputMaybe<Billing_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Billing_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "billing_type" */
export enum Billing_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  BillingTypePkey = 'billing_type_pkey'
}

export enum Billing_Type_Enum {
  /** billed per unit */
  Fixed = 'FIXED',
  /** billed per measured weight */
  WeightDependent = 'WEIGHT_DEPENDENT'
}

/** Boolean expression to compare columns of type "billing_type_enum". All fields are combined with logical 'AND'. */
export type Billing_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Billing_Type_Enum>;
  _in?: InputMaybe<Array<Billing_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Billing_Type_Enum>;
  _nin?: InputMaybe<Array<Billing_Type_Enum>>;
};

/** input type for inserting data into table "billing_type" */
export type Billing_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Billing_Type_Max_Fields = {
  __typename?: 'billing_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Billing_Type_Min_Fields = {
  __typename?: 'billing_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "billing_type" */
export type Billing_Type_Mutation_Response = {
  __typename?: 'billing_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Type>;
};

/** on_conflict condition type for table "billing_type" */
export type Billing_Type_On_Conflict = {
  constraint: Billing_Type_Constraint;
  update_columns?: Array<Billing_Type_Update_Column>;
  where?: InputMaybe<Billing_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "billing_type". */
export type Billing_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: billing_type */
export type Billing_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "billing_type" */
export enum Billing_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "billing_type" */
export type Billing_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "billing_type" */
export type Billing_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Billing_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "billing_type" */
export enum Billing_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Type = 'type'
}

export type Billing_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Billing_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Billing_Type_Bool_Exp;
};

/** columns and relationships of "brand" */
export type Brand = {
  __typename?: 'brand';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "brand" */
export type Brand_Aggregate = {
  __typename?: 'brand_aggregate';
  aggregate?: Maybe<Brand_Aggregate_Fields>;
  nodes: Array<Brand>;
};

/** aggregate fields of "brand" */
export type Brand_Aggregate_Fields = {
  __typename?: 'brand_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Brand_Max_Fields>;
  min?: Maybe<Brand_Min_Fields>;
};


/** aggregate fields of "brand" */
export type Brand_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Brand_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "brand". All fields are combined with a logical 'AND'. */
export type Brand_Bool_Exp = {
  _and?: InputMaybe<Array<Brand_Bool_Exp>>;
  _not?: InputMaybe<Brand_Bool_Exp>;
  _or?: InputMaybe<Array<Brand_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "brand" */
export enum Brand_Constraint {
  /** unique or primary key constraint on columns "value" */
  BrandPkey = 'brand_pkey'
}

export enum Brand_Enum {
  /** Auchan Hyper */
  AuchanHyper = 'auchan_hyper',
  /** Auchan MyAuchan */
  AuchanMyauchan = 'auchan_myauchan',
  /** Auchan Super */
  AuchanSuper = 'auchan_super',
  /** Carrefour City */
  CarrefourCity = 'carrefour_city',
  /** Carrefour Hyper */
  CarrefourHyper = 'carrefour_hyper',
  /** Carrefour Market */
  CarrefourMarket = 'carrefour_market',
  /** Cora */
  Cora = 'cora',
  /** E.Leclerc */
  ELeclerc = 'e_leclerc',
  /** Hyper U */
  HyperU = 'hyper_u',
  /** Intermarché Contact */
  IntermarcheContact = 'intermarche_contact',
  /** Intermarché Express */
  IntermarcheExpress = 'intermarche_express',
  /** Intermarché Hyper */
  IntermarcheHyper = 'intermarche_hyper',
  /** Intermarché Super */
  IntermarcheSuper = 'intermarche_super',
  /** Super U */
  SuperU = 'super_u',
  /** U Express */
  UExpress = 'u_express',
  /** Utile */
  Utile = 'utile'
}

/** Boolean expression to compare columns of type "brand_enum". All fields are combined with logical 'AND'. */
export type Brand_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Brand_Enum>;
  _in?: InputMaybe<Array<Brand_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Brand_Enum>;
  _nin?: InputMaybe<Array<Brand_Enum>>;
};

/** input type for inserting data into table "brand" */
export type Brand_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Brand_Max_Fields = {
  __typename?: 'brand_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Brand_Min_Fields = {
  __typename?: 'brand_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "brand" */
export type Brand_Mutation_Response = {
  __typename?: 'brand_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Brand>;
};

/** on_conflict condition type for table "brand" */
export type Brand_On_Conflict = {
  constraint: Brand_Constraint;
  update_columns?: Array<Brand_Update_Column>;
  where?: InputMaybe<Brand_Bool_Exp>;
};

/** Ordering options when selecting data from "brand". */
export type Brand_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: brand */
export type Brand_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "brand" */
export enum Brand_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "brand" */
export type Brand_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "brand" */
export type Brand_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Brand_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Brand_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "brand" */
export enum Brand_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Brand_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Brand_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brand_Bool_Exp;
};

/** columns and relationships of "business_profile" */
export type Business_Profile = {
  __typename?: 'business_profile';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "business_profile" */
export type Business_Profile_Aggregate = {
  __typename?: 'business_profile_aggregate';
  aggregate?: Maybe<Business_Profile_Aggregate_Fields>;
  nodes: Array<Business_Profile>;
};

/** aggregate fields of "business_profile" */
export type Business_Profile_Aggregate_Fields = {
  __typename?: 'business_profile_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Business_Profile_Max_Fields>;
  min?: Maybe<Business_Profile_Min_Fields>;
};


/** aggregate fields of "business_profile" */
export type Business_Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Business_Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "business_profile". All fields are combined with a logical 'AND'. */
export type Business_Profile_Bool_Exp = {
  _and?: InputMaybe<Array<Business_Profile_Bool_Exp>>;
  _not?: InputMaybe<Business_Profile_Bool_Exp>;
  _or?: InputMaybe<Array<Business_Profile_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "business_profile" */
export enum Business_Profile_Constraint {
  /** unique or primary key constraint on columns "value" */
  BusinessProfilePkey = 'business_profile_pkey'
}

export enum Business_Profile_Enum {
  Buyer = 'BUYER',
  Supplier = 'SUPPLIER'
}

/** Boolean expression to compare columns of type "business_profile_enum". All fields are combined with logical 'AND'. */
export type Business_Profile_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Business_Profile_Enum>;
  _in?: InputMaybe<Array<Business_Profile_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Business_Profile_Enum>;
  _nin?: InputMaybe<Array<Business_Profile_Enum>>;
};

/** input type for inserting data into table "business_profile" */
export type Business_Profile_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Business_Profile_Max_Fields = {
  __typename?: 'business_profile_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Business_Profile_Min_Fields = {
  __typename?: 'business_profile_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "business_profile" */
export type Business_Profile_Mutation_Response = {
  __typename?: 'business_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Business_Profile>;
};

/** on_conflict condition type for table "business_profile" */
export type Business_Profile_On_Conflict = {
  constraint: Business_Profile_Constraint;
  update_columns?: Array<Business_Profile_Update_Column>;
  where?: InputMaybe<Business_Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "business_profile". */
export type Business_Profile_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: business_profile */
export type Business_Profile_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "business_profile" */
export enum Business_Profile_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "business_profile" */
export type Business_Profile_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "business_profile" */
export type Business_Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Business_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Business_Profile_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "business_profile" */
export enum Business_Profile_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Business_Profile_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Business_Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Business_Profile_Bool_Exp;
};

/** columns and relationships of "catalog" */
export type Catalog = {
  __typename?: 'catalog';
  /** An array relationship */
  catalog_packagings: Array<Catalog_Packaging>;
  /** An aggregate relationship */
  catalog_packagings_aggregate: Catalog_Packaging_Aggregate;
  /** An object relationship */
  company: Company;
  company_id: Scalars['uuid']['output'];
  /** An array relationship */
  contracts: Array<Contract>;
  /** An aggregate relationship */
  contracts_aggregate: Contract_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "catalog" */
export type CatalogCatalog_PackagingsArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Packaging_Order_By>>;
  where?: InputMaybe<Catalog_Packaging_Bool_Exp>;
};


/** columns and relationships of "catalog" */
export type CatalogCatalog_Packagings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Packaging_Order_By>>;
  where?: InputMaybe<Catalog_Packaging_Bool_Exp>;
};


/** columns and relationships of "catalog" */
export type CatalogContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


/** columns and relationships of "catalog" */
export type CatalogContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

/** aggregated selection of "catalog" */
export type Catalog_Aggregate = {
  __typename?: 'catalog_aggregate';
  aggregate?: Maybe<Catalog_Aggregate_Fields>;
  nodes: Array<Catalog>;
};

/** aggregate fields of "catalog" */
export type Catalog_Aggregate_Fields = {
  __typename?: 'catalog_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Catalog_Max_Fields>;
  min?: Maybe<Catalog_Min_Fields>;
};


/** aggregate fields of "catalog" */
export type Catalog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Catalog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "catalog". All fields are combined with a logical 'AND'. */
export type Catalog_Bool_Exp = {
  _and?: InputMaybe<Array<Catalog_Bool_Exp>>;
  _not?: InputMaybe<Catalog_Bool_Exp>;
  _or?: InputMaybe<Array<Catalog_Bool_Exp>>;
  catalog_packagings?: InputMaybe<Catalog_Packaging_Bool_Exp>;
  catalog_packagings_aggregate?: InputMaybe<Catalog_Packaging_Aggregate_Bool_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  contracts?: InputMaybe<Contract_Bool_Exp>;
  contracts_aggregate?: InputMaybe<Contract_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "catalog" */
export enum Catalog_Constraint {
  /** unique or primary key constraint on columns "company_id", "name" */
  CatalogNameCompanyIdKey = 'catalog_name_company_id_key',
  /** unique or primary key constraint on columns "id" */
  CatalogPkey = 'catalog_pkey'
}

/** input type for inserting data into table "catalog" */
export type Catalog_Insert_Input = {
  catalog_packagings?: InputMaybe<Catalog_Packaging_Arr_Rel_Insert_Input>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  contracts?: InputMaybe<Contract_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Catalog_Max_Fields = {
  __typename?: 'catalog_max_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Catalog_Min_Fields = {
  __typename?: 'catalog_min_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "catalog" */
export type Catalog_Mutation_Response = {
  __typename?: 'catalog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Catalog>;
};

/** input type for inserting object relation for remote table "catalog" */
export type Catalog_Obj_Rel_Insert_Input = {
  data: Catalog_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Catalog_On_Conflict>;
};

/** on_conflict condition type for table "catalog" */
export type Catalog_On_Conflict = {
  constraint: Catalog_Constraint;
  update_columns?: Array<Catalog_Update_Column>;
  where?: InputMaybe<Catalog_Bool_Exp>;
};

/** Ordering options when selecting data from "catalog". */
export type Catalog_Order_By = {
  catalog_packagings_aggregate?: InputMaybe<Catalog_Packaging_Aggregate_Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  contracts_aggregate?: InputMaybe<Contract_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "catalog_packaging" */
export type Catalog_Packaging = {
  __typename?: 'catalog_packaging';
  /** An object relationship */
  catalog: Catalog;
  catalog_id: Scalars['uuid']['output'];
  company_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  discount: Scalars['numeric']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  packaging?: Maybe<Packaging>;
  packaging_sku: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "catalog_packaging" */
export type Catalog_Packaging_Aggregate = {
  __typename?: 'catalog_packaging_aggregate';
  aggregate?: Maybe<Catalog_Packaging_Aggregate_Fields>;
  nodes: Array<Catalog_Packaging>;
};

export type Catalog_Packaging_Aggregate_Bool_Exp = {
  count?: InputMaybe<Catalog_Packaging_Aggregate_Bool_Exp_Count>;
};

export type Catalog_Packaging_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Catalog_Packaging_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Catalog_Packaging_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "catalog_packaging" */
export type Catalog_Packaging_Aggregate_Fields = {
  __typename?: 'catalog_packaging_aggregate_fields';
  avg?: Maybe<Catalog_Packaging_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Catalog_Packaging_Max_Fields>;
  min?: Maybe<Catalog_Packaging_Min_Fields>;
  stddev?: Maybe<Catalog_Packaging_Stddev_Fields>;
  stddev_pop?: Maybe<Catalog_Packaging_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Catalog_Packaging_Stddev_Samp_Fields>;
  sum?: Maybe<Catalog_Packaging_Sum_Fields>;
  var_pop?: Maybe<Catalog_Packaging_Var_Pop_Fields>;
  var_samp?: Maybe<Catalog_Packaging_Var_Samp_Fields>;
  variance?: Maybe<Catalog_Packaging_Variance_Fields>;
};


/** aggregate fields of "catalog_packaging" */
export type Catalog_Packaging_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Catalog_Packaging_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "catalog_packaging" */
export type Catalog_Packaging_Aggregate_Order_By = {
  avg?: InputMaybe<Catalog_Packaging_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Catalog_Packaging_Max_Order_By>;
  min?: InputMaybe<Catalog_Packaging_Min_Order_By>;
  stddev?: InputMaybe<Catalog_Packaging_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Catalog_Packaging_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Catalog_Packaging_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Catalog_Packaging_Sum_Order_By>;
  var_pop?: InputMaybe<Catalog_Packaging_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Catalog_Packaging_Var_Samp_Order_By>;
  variance?: InputMaybe<Catalog_Packaging_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "catalog_packaging" */
export type Catalog_Packaging_Arr_Rel_Insert_Input = {
  data: Array<Catalog_Packaging_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Catalog_Packaging_On_Conflict>;
};

/** aggregate avg on columns */
export type Catalog_Packaging_Avg_Fields = {
  __typename?: 'catalog_packaging_avg_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "catalog_packaging" */
export type Catalog_Packaging_Avg_Order_By = {
  discount?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "catalog_packaging". All fields are combined with a logical 'AND'. */
export type Catalog_Packaging_Bool_Exp = {
  _and?: InputMaybe<Array<Catalog_Packaging_Bool_Exp>>;
  _not?: InputMaybe<Catalog_Packaging_Bool_Exp>;
  _or?: InputMaybe<Array<Catalog_Packaging_Bool_Exp>>;
  catalog?: InputMaybe<Catalog_Bool_Exp>;
  catalog_id?: InputMaybe<Uuid_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  discount?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  packaging?: InputMaybe<Packaging_Bool_Exp>;
  packaging_sku?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "catalog_packaging" */
export enum Catalog_Packaging_Constraint {
  /** unique or primary key constraint on columns "company_id", "packaging_sku", "catalog_id" */
  CatalogPackagingPackagingSkuCompanyIdCatalogIdKey = 'catalog_packaging_packaging_sku_company_id_catalog_id_key',
  /** unique or primary key constraint on columns "id" */
  CatalogPackagingPkey = 'catalog_packaging_pkey'
}

/** columns and relationships of "catalog_packaging_history" */
export type Catalog_Packaging_History = {
  __typename?: 'catalog_packaging_history';
  catalog_id: Scalars['uuid']['output'];
  company_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  discount: Scalars['numeric']['output'];
  id: Scalars['uuid']['output'];
  packaging_sku: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "catalog_packaging_history" */
export type Catalog_Packaging_History_Aggregate = {
  __typename?: 'catalog_packaging_history_aggregate';
  aggregate?: Maybe<Catalog_Packaging_History_Aggregate_Fields>;
  nodes: Array<Catalog_Packaging_History>;
};

/** aggregate fields of "catalog_packaging_history" */
export type Catalog_Packaging_History_Aggregate_Fields = {
  __typename?: 'catalog_packaging_history_aggregate_fields';
  avg?: Maybe<Catalog_Packaging_History_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Catalog_Packaging_History_Max_Fields>;
  min?: Maybe<Catalog_Packaging_History_Min_Fields>;
  stddev?: Maybe<Catalog_Packaging_History_Stddev_Fields>;
  stddev_pop?: Maybe<Catalog_Packaging_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Catalog_Packaging_History_Stddev_Samp_Fields>;
  sum?: Maybe<Catalog_Packaging_History_Sum_Fields>;
  var_pop?: Maybe<Catalog_Packaging_History_Var_Pop_Fields>;
  var_samp?: Maybe<Catalog_Packaging_History_Var_Samp_Fields>;
  variance?: Maybe<Catalog_Packaging_History_Variance_Fields>;
};


/** aggregate fields of "catalog_packaging_history" */
export type Catalog_Packaging_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Catalog_Packaging_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Catalog_Packaging_History_Avg_Fields = {
  __typename?: 'catalog_packaging_history_avg_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "catalog_packaging_history". All fields are combined with a logical 'AND'. */
export type Catalog_Packaging_History_Bool_Exp = {
  _and?: InputMaybe<Array<Catalog_Packaging_History_Bool_Exp>>;
  _not?: InputMaybe<Catalog_Packaging_History_Bool_Exp>;
  _or?: InputMaybe<Array<Catalog_Packaging_History_Bool_Exp>>;
  catalog_id?: InputMaybe<Uuid_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  discount?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  packaging_sku?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "catalog_packaging_history" */
export enum Catalog_Packaging_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  CatalogPackagingHistoryPkey = 'catalog_packaging_history_pkey'
}

/** input type for incrementing numeric columns in table "catalog_packaging_history" */
export type Catalog_Packaging_History_Inc_Input = {
  discount?: InputMaybe<Scalars['numeric']['input']>;
  packaging_sku?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "catalog_packaging_history" */
export type Catalog_Packaging_History_Insert_Input = {
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  packaging_sku?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Catalog_Packaging_History_Max_Fields = {
  __typename?: 'catalog_packaging_history_max_fields';
  catalog_id?: Maybe<Scalars['uuid']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  discount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  packaging_sku?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Catalog_Packaging_History_Min_Fields = {
  __typename?: 'catalog_packaging_history_min_fields';
  catalog_id?: Maybe<Scalars['uuid']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  discount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  packaging_sku?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "catalog_packaging_history" */
export type Catalog_Packaging_History_Mutation_Response = {
  __typename?: 'catalog_packaging_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Catalog_Packaging_History>;
};

/** on_conflict condition type for table "catalog_packaging_history" */
export type Catalog_Packaging_History_On_Conflict = {
  constraint: Catalog_Packaging_History_Constraint;
  update_columns?: Array<Catalog_Packaging_History_Update_Column>;
  where?: InputMaybe<Catalog_Packaging_History_Bool_Exp>;
};

/** Ordering options when selecting data from "catalog_packaging_history". */
export type Catalog_Packaging_History_Order_By = {
  catalog_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: catalog_packaging_history */
export type Catalog_Packaging_History_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "catalog_packaging_history" */
export enum Catalog_Packaging_History_Select_Column {
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  PackagingSku = 'packaging_sku',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "catalog_packaging_history" */
export type Catalog_Packaging_History_Set_Input = {
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  packaging_sku?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Catalog_Packaging_History_Stddev_Fields = {
  __typename?: 'catalog_packaging_history_stddev_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Catalog_Packaging_History_Stddev_Pop_Fields = {
  __typename?: 'catalog_packaging_history_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Catalog_Packaging_History_Stddev_Samp_Fields = {
  __typename?: 'catalog_packaging_history_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "catalog_packaging_history" */
export type Catalog_Packaging_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Catalog_Packaging_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Catalog_Packaging_History_Stream_Cursor_Value_Input = {
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  packaging_sku?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Catalog_Packaging_History_Sum_Fields = {
  __typename?: 'catalog_packaging_history_sum_fields';
  discount?: Maybe<Scalars['numeric']['output']>;
  packaging_sku?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "catalog_packaging_history" */
export enum Catalog_Packaging_History_Update_Column {
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  PackagingSku = 'packaging_sku',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Catalog_Packaging_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Catalog_Packaging_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Catalog_Packaging_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Catalog_Packaging_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Catalog_Packaging_History_Var_Pop_Fields = {
  __typename?: 'catalog_packaging_history_var_pop_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Catalog_Packaging_History_Var_Samp_Fields = {
  __typename?: 'catalog_packaging_history_var_samp_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Catalog_Packaging_History_Variance_Fields = {
  __typename?: 'catalog_packaging_history_variance_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "catalog_packaging" */
export type Catalog_Packaging_Inc_Input = {
  discount?: InputMaybe<Scalars['numeric']['input']>;
  packaging_sku?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "catalog_packaging" */
export type Catalog_Packaging_Insert_Input = {
  catalog?: InputMaybe<Catalog_Obj_Rel_Insert_Input>;
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  packaging?: InputMaybe<Packaging_Obj_Rel_Insert_Input>;
  packaging_sku?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Catalog_Packaging_Max_Fields = {
  __typename?: 'catalog_packaging_max_fields';
  catalog_id?: Maybe<Scalars['uuid']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  discount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  packaging_sku?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "catalog_packaging" */
export type Catalog_Packaging_Max_Order_By = {
  catalog_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Catalog_Packaging_Min_Fields = {
  __typename?: 'catalog_packaging_min_fields';
  catalog_id?: Maybe<Scalars['uuid']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  discount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  packaging_sku?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "catalog_packaging" */
export type Catalog_Packaging_Min_Order_By = {
  catalog_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "catalog_packaging" */
export type Catalog_Packaging_Mutation_Response = {
  __typename?: 'catalog_packaging_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Catalog_Packaging>;
};

/** on_conflict condition type for table "catalog_packaging" */
export type Catalog_Packaging_On_Conflict = {
  constraint: Catalog_Packaging_Constraint;
  update_columns?: Array<Catalog_Packaging_Update_Column>;
  where?: InputMaybe<Catalog_Packaging_Bool_Exp>;
};

/** Ordering options when selecting data from "catalog_packaging". */
export type Catalog_Packaging_Order_By = {
  catalog?: InputMaybe<Catalog_Order_By>;
  catalog_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  packaging?: InputMaybe<Packaging_Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: catalog_packaging */
export type Catalog_Packaging_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "catalog_packaging" */
export enum Catalog_Packaging_Select_Column {
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  PackagingSku = 'packaging_sku',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "catalog_packaging" */
export type Catalog_Packaging_Set_Input = {
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  packaging_sku?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Catalog_Packaging_Stddev_Fields = {
  __typename?: 'catalog_packaging_stddev_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "catalog_packaging" */
export type Catalog_Packaging_Stddev_Order_By = {
  discount?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Catalog_Packaging_Stddev_Pop_Fields = {
  __typename?: 'catalog_packaging_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "catalog_packaging" */
export type Catalog_Packaging_Stddev_Pop_Order_By = {
  discount?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Catalog_Packaging_Stddev_Samp_Fields = {
  __typename?: 'catalog_packaging_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "catalog_packaging" */
export type Catalog_Packaging_Stddev_Samp_Order_By = {
  discount?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "catalog_packaging" */
export type Catalog_Packaging_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Catalog_Packaging_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Catalog_Packaging_Stream_Cursor_Value_Input = {
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  packaging_sku?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Catalog_Packaging_Sum_Fields = {
  __typename?: 'catalog_packaging_sum_fields';
  discount?: Maybe<Scalars['numeric']['output']>;
  packaging_sku?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "catalog_packaging" */
export type Catalog_Packaging_Sum_Order_By = {
  discount?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
};

/** update columns of table "catalog_packaging" */
export enum Catalog_Packaging_Update_Column {
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  PackagingSku = 'packaging_sku',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Catalog_Packaging_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Catalog_Packaging_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Catalog_Packaging_Set_Input>;
  /** filter the rows which have to be updated */
  where: Catalog_Packaging_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Catalog_Packaging_Var_Pop_Fields = {
  __typename?: 'catalog_packaging_var_pop_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "catalog_packaging" */
export type Catalog_Packaging_Var_Pop_Order_By = {
  discount?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Catalog_Packaging_Var_Samp_Fields = {
  __typename?: 'catalog_packaging_var_samp_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "catalog_packaging" */
export type Catalog_Packaging_Var_Samp_Order_By = {
  discount?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Catalog_Packaging_Variance_Fields = {
  __typename?: 'catalog_packaging_variance_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  packaging_sku?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "catalog_packaging" */
export type Catalog_Packaging_Variance_Order_By = {
  discount?: InputMaybe<Order_By>;
  packaging_sku?: InputMaybe<Order_By>;
};

/** primary key columns input for table: catalog */
export type Catalog_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "catalog" */
export enum Catalog_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "catalog" */
export type Catalog_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "catalog" */
export type Catalog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Catalog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Catalog_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "catalog" */
export enum Catalog_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Catalog_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Catalog_Set_Input>;
  /** filter the rows which have to be updated */
  where: Catalog_Bool_Exp;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
};

/** columns and relationships of "comment" */
export type Comment = {
  __typename?: 'comment';
  content?: Maybe<Scalars['String']['output']>;
  created_date: Scalars['timestamptz']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  modified_date: Scalars['timestamptz']['output'];
  /** An object relationship */
  order: Order;
  order_reference_id: Scalars['uuid']['output'];
  reference_id: Scalars['uuid']['output'];
  type: Comment_Type_Enum;
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "comment" */
export type Comment_Aggregate = {
  __typename?: 'comment_aggregate';
  aggregate?: Maybe<Comment_Aggregate_Fields>;
  nodes: Array<Comment>;
};

export type Comment_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Comment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Comment_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Comment_Aggregate_Bool_Exp_Count>;
};

export type Comment_Aggregate_Bool_Exp_Bool_And = {
  arguments: Comment_Select_Column_Comment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Comment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Comment_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Comment_Select_Column_Comment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Comment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Comment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Comment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Comment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comment" */
export type Comment_Aggregate_Fields = {
  __typename?: 'comment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Comment_Max_Fields>;
  min?: Maybe<Comment_Min_Fields>;
};


/** aggregate fields of "comment" */
export type Comment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comment" */
export type Comment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comment_Max_Order_By>;
  min?: InputMaybe<Comment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comment" */
export type Comment_Arr_Rel_Insert_Input = {
  data: Array<Comment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comment". All fields are combined with a logical 'AND'. */
export type Comment_Bool_Exp = {
  _and?: InputMaybe<Array<Comment_Bool_Exp>>;
  _not?: InputMaybe<Comment_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  modified_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  order_reference_id?: InputMaybe<Uuid_Comparison_Exp>;
  reference_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Comment_Type_Enum_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment" */
export enum Comment_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentPkey = 'comment_pkey',
  /** unique or primary key constraint on columns "reference_id" */
  CommentReferenceIdKey = 'comment_reference_id_key'
}

/** columns and relationships of "comment_history" */
export type Comment_History = {
  __typename?: 'comment_history';
  content?: Maybe<Scalars['String']['output']>;
  created_date: Scalars['timestamptz']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  modified_date: Scalars['timestamptz']['output'];
  order_reference_id: Scalars['uuid']['output'];
  reference_id: Scalars['uuid']['output'];
  type?: Maybe<Scalars['String']['output']>;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "comment_history" */
export type Comment_History_Aggregate = {
  __typename?: 'comment_history_aggregate';
  aggregate?: Maybe<Comment_History_Aggregate_Fields>;
  nodes: Array<Comment_History>;
};

export type Comment_History_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Comment_History_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Comment_History_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Comment_History_Aggregate_Bool_Exp_Count>;
};

export type Comment_History_Aggregate_Bool_Exp_Bool_And = {
  arguments: Comment_History_Select_Column_Comment_History_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Comment_History_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Comment_History_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Comment_History_Select_Column_Comment_History_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Comment_History_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Comment_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Comment_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Comment_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comment_history" */
export type Comment_History_Aggregate_Fields = {
  __typename?: 'comment_history_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Comment_History_Max_Fields>;
  min?: Maybe<Comment_History_Min_Fields>;
};


/** aggregate fields of "comment_history" */
export type Comment_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comment_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comment_history" */
export type Comment_History_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comment_History_Max_Order_By>;
  min?: InputMaybe<Comment_History_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comment_history" */
export type Comment_History_Arr_Rel_Insert_Input = {
  data: Array<Comment_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_History_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comment_history". All fields are combined with a logical 'AND'. */
export type Comment_History_Bool_Exp = {
  _and?: InputMaybe<Array<Comment_History_Bool_Exp>>;
  _not?: InputMaybe<Comment_History_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_History_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  modified_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  order_reference_id?: InputMaybe<Uuid_Comparison_Exp>;
  reference_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_history" */
export enum Comment_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentHistoryPkey = 'comment_history_pkey'
}

/** input type for inserting data into table "comment_history" */
export type Comment_History_Insert_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order_reference_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Comment_History_Max_Fields = {
  __typename?: 'comment_history_max_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modified_date?: Maybe<Scalars['timestamptz']['output']>;
  order_reference_id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "comment_history" */
export type Comment_History_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  order_reference_id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comment_History_Min_Fields = {
  __typename?: 'comment_history_min_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modified_date?: Maybe<Scalars['timestamptz']['output']>;
  order_reference_id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "comment_history" */
export type Comment_History_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  order_reference_id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comment_history" */
export type Comment_History_Mutation_Response = {
  __typename?: 'comment_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment_History>;
};

/** on_conflict condition type for table "comment_history" */
export type Comment_History_On_Conflict = {
  constraint: Comment_History_Constraint;
  update_columns?: Array<Comment_History_Update_Column>;
  where?: InputMaybe<Comment_History_Bool_Exp>;
};

/** Ordering options when selecting data from "comment_history". */
export type Comment_History_Order_By = {
  content?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  order_reference_id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comment_history */
export type Comment_History_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "comment_history" */
export enum Comment_History_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedDate = 'modified_date',
  /** column name */
  OrderReferenceId = 'order_reference_id',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** select "comment_history_aggregate_bool_exp_bool_and_arguments_columns" columns of table "comment_history" */
export enum Comment_History_Select_Column_Comment_History_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** select "comment_history_aggregate_bool_exp_bool_or_arguments_columns" columns of table "comment_history" */
export enum Comment_History_Select_Column_Comment_History_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** input type for updating data in table "comment_history" */
export type Comment_History_Set_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order_reference_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "comment_history" */
export type Comment_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_History_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order_reference_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "comment_history" */
export enum Comment_History_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedDate = 'modified_date',
  /** column name */
  OrderReferenceId = 'order_reference_id',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type Comment_History_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comment_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comment_History_Bool_Exp;
};

/** input type for inserting data into table "comment" */
export type Comment_Insert_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
  order_reference_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Comment_Type_Enum>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Comment_Max_Fields = {
  __typename?: 'comment_max_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modified_date?: Maybe<Scalars['timestamptz']['output']>;
  order_reference_id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "comment" */
export type Comment_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  order_reference_id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comment_Min_Fields = {
  __typename?: 'comment_min_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modified_date?: Maybe<Scalars['timestamptz']['output']>;
  order_reference_id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "comment" */
export type Comment_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  order_reference_id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comment" */
export type Comment_Mutation_Response = {
  __typename?: 'comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment>;
};

/** on_conflict condition type for table "comment" */
export type Comment_On_Conflict = {
  constraint: Comment_Constraint;
  update_columns?: Array<Comment_Update_Column>;
  where?: InputMaybe<Comment_Bool_Exp>;
};

/** Ordering options when selecting data from "comment". */
export type Comment_Order_By = {
  content?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  order_reference_id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comment */
export type Comment_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "comment" */
export enum Comment_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedDate = 'modified_date',
  /** column name */
  OrderReferenceId = 'order_reference_id',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** select "comment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "comment" */
export enum Comment_Select_Column_Comment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** select "comment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "comment" */
export enum Comment_Select_Column_Comment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** input type for updating data in table "comment" */
export type Comment_Set_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order_reference_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Comment_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "comment" */
export type Comment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order_reference_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Comment_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "comment_type" */
export type Comment_Type = {
  __typename?: 'comment_type';
  comment?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

/** aggregated selection of "comment_type" */
export type Comment_Type_Aggregate = {
  __typename?: 'comment_type_aggregate';
  aggregate?: Maybe<Comment_Type_Aggregate_Fields>;
  nodes: Array<Comment_Type>;
};

/** aggregate fields of "comment_type" */
export type Comment_Type_Aggregate_Fields = {
  __typename?: 'comment_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Comment_Type_Max_Fields>;
  min?: Maybe<Comment_Type_Min_Fields>;
};


/** aggregate fields of "comment_type" */
export type Comment_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comment_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "comment_type". All fields are combined with a logical 'AND'. */
export type Comment_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Comment_Type_Bool_Exp>>;
  _not?: InputMaybe<Comment_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_type" */
export enum Comment_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  CommentTypePkey = 'comment_type_pkey'
}

export enum Comment_Type_Enum {
  /** comment to specify order cancellation reason */
  CancelReason = 'CANCEL_REASON',
  /** default comment */
  Default = 'DEFAULT',
  /** comment added in order creation */
  OrderCreation = 'ORDER_CREATION'
}

/** Boolean expression to compare columns of type "comment_type_enum". All fields are combined with logical 'AND'. */
export type Comment_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Comment_Type_Enum>;
  _in?: InputMaybe<Array<Comment_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Comment_Type_Enum>;
  _nin?: InputMaybe<Array<Comment_Type_Enum>>;
};

/** input type for inserting data into table "comment_type" */
export type Comment_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Comment_Type_Max_Fields = {
  __typename?: 'comment_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Comment_Type_Min_Fields = {
  __typename?: 'comment_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "comment_type" */
export type Comment_Type_Mutation_Response = {
  __typename?: 'comment_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment_Type>;
};

/** on_conflict condition type for table "comment_type" */
export type Comment_Type_On_Conflict = {
  constraint: Comment_Type_Constraint;
  update_columns?: Array<Comment_Type_Update_Column>;
  where?: InputMaybe<Comment_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "comment_type". */
export type Comment_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comment_type */
export type Comment_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "comment_type" */
export enum Comment_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "comment_type" */
export type Comment_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "comment_type" */
export type Comment_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "comment_type" */
export enum Comment_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Type = 'type'
}

export type Comment_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comment_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comment_Type_Bool_Exp;
};

/** update columns of table "comment" */
export enum Comment_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedDate = 'modified_date',
  /** column name */
  OrderReferenceId = 'order_reference_id',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type Comment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comment_Bool_Exp;
};

/** columns and relationships of "commission" */
export type Commission = {
  __typename?: 'commission';
  type: Commission_Type_Enum;
  value: Scalars['numeric']['output'];
};

/** aggregated selection of "commission" */
export type Commission_Aggregate = {
  __typename?: 'commission_aggregate';
  aggregate?: Maybe<Commission_Aggregate_Fields>;
  nodes: Array<Commission>;
};

/** aggregate fields of "commission" */
export type Commission_Aggregate_Fields = {
  __typename?: 'commission_aggregate_fields';
  avg?: Maybe<Commission_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Commission_Max_Fields>;
  min?: Maybe<Commission_Min_Fields>;
  stddev?: Maybe<Commission_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Sum_Fields>;
  var_pop?: Maybe<Commission_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Var_Samp_Fields>;
  variance?: Maybe<Commission_Variance_Fields>;
};


/** aggregate fields of "commission" */
export type Commission_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Commission_Avg_Fields = {
  __typename?: 'commission_avg_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "commission". All fields are combined with a logical 'AND'. */
export type Commission_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Bool_Exp>>;
  _not?: InputMaybe<Commission_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Bool_Exp>>;
  type?: InputMaybe<Commission_Type_Enum_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "commission" */
export enum Commission_Constraint {
  /** unique or primary key constraint on columns "type" */
  CommissionPkey = 'commission_pkey'
}

/** input type for incrementing numeric columns in table "commission" */
export type Commission_Inc_Input = {
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "commission" */
export type Commission_Insert_Input = {
  type?: InputMaybe<Commission_Type_Enum>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Commission_Max_Fields = {
  __typename?: 'commission_max_fields';
  value?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Commission_Min_Fields = {
  __typename?: 'commission_min_fields';
  value?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "commission" */
export type Commission_Mutation_Response = {
  __typename?: 'commission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Commission>;
};

/** input type for inserting object relation for remote table "commission" */
export type Commission_Obj_Rel_Insert_Input = {
  data: Commission_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Commission_On_Conflict>;
};

/** on_conflict condition type for table "commission" */
export type Commission_On_Conflict = {
  constraint: Commission_Constraint;
  update_columns?: Array<Commission_Update_Column>;
  where?: InputMaybe<Commission_Bool_Exp>;
};

/** Ordering options when selecting data from "commission". */
export type Commission_Order_By = {
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: commission */
export type Commission_Pk_Columns_Input = {
  type: Commission_Type_Enum;
};

/** select columns of table "commission" */
export enum Commission_Select_Column {
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "commission" */
export type Commission_Set_Input = {
  type?: InputMaybe<Commission_Type_Enum>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Commission_Stddev_Fields = {
  __typename?: 'commission_stddev_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Stddev_Pop_Fields = {
  __typename?: 'commission_stddev_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Stddev_Samp_Fields = {
  __typename?: 'commission_stddev_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "commission" */
export type Commission_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Commission_Type_Enum>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Commission_Sum_Fields = {
  __typename?: 'commission_sum_fields';
  value?: Maybe<Scalars['numeric']['output']>;
};

/** columns and relationships of "commission_type" */
export type Commission_Type = {
  __typename?: 'commission_type';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "commission_type" */
export type Commission_Type_Aggregate = {
  __typename?: 'commission_type_aggregate';
  aggregate?: Maybe<Commission_Type_Aggregate_Fields>;
  nodes: Array<Commission_Type>;
};

/** aggregate fields of "commission_type" */
export type Commission_Type_Aggregate_Fields = {
  __typename?: 'commission_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Commission_Type_Max_Fields>;
  min?: Maybe<Commission_Type_Min_Fields>;
};


/** aggregate fields of "commission_type" */
export type Commission_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "commission_type". All fields are combined with a logical 'AND'. */
export type Commission_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Type_Bool_Exp>>;
  _not?: InputMaybe<Commission_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "commission_type" */
export enum Commission_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  CommissionTypePkey = 'commission_type_pkey'
}

export enum Commission_Type_Enum {
  Custom = 'CUSTOM',
  NoCommission = 'NO_COMMISSION',
  /** 5% commission on order value */
  Standard_5Percent = 'STANDARD_5_PERCENT',
  /** 10% commission on order value */
  Standard_10Percent = 'STANDARD_10_PERCENT'
}

/** Boolean expression to compare columns of type "commission_type_enum". All fields are combined with logical 'AND'. */
export type Commission_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Commission_Type_Enum>;
  _in?: InputMaybe<Array<Commission_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Commission_Type_Enum>;
  _nin?: InputMaybe<Array<Commission_Type_Enum>>;
};

/** input type for inserting data into table "commission_type" */
export type Commission_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Commission_Type_Max_Fields = {
  __typename?: 'commission_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Commission_Type_Min_Fields = {
  __typename?: 'commission_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "commission_type" */
export type Commission_Type_Mutation_Response = {
  __typename?: 'commission_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Commission_Type>;
};

/** on_conflict condition type for table "commission_type" */
export type Commission_Type_On_Conflict = {
  constraint: Commission_Type_Constraint;
  update_columns?: Array<Commission_Type_Update_Column>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "commission_type". */
export type Commission_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: commission_type */
export type Commission_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "commission_type" */
export enum Commission_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "commission_type" */
export type Commission_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "commission_type" */
export type Commission_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "commission_type" */
export enum Commission_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Commission_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Commission_Type_Bool_Exp;
};

/** update columns of table "commission" */
export enum Commission_Update_Column {
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value'
}

export type Commission_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Commission_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Set_Input>;
  /** filter the rows which have to be updated */
  where: Commission_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Commission_Var_Pop_Fields = {
  __typename?: 'commission_var_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Commission_Var_Samp_Fields = {
  __typename?: 'commission_var_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Commission_Variance_Fields = {
  __typename?: 'commission_variance_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: 'company';
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  brand?: Maybe<Brand_Enum>;
  business_profile: Business_Profile_Enum;
  /** An array relationship */
  buying_contracts: Array<Contract>;
  /** An aggregate relationship */
  buying_contracts_aggregate: Contract_Aggregate;
  /** An object relationship */
  commission?: Maybe<Commission>;
  contactEmail: Scalars['String']['output'];
  contactPhoneNumber: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  default_commission_type?: Maybe<Commission_Type_Enum>;
  default_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
  default_payment_method?: Maybe<Payment_Method_Enum>;
  /** An array relationship */
  delivery_zones: Array<Delivery_Zone>;
  /** An aggregate relationship */
  delivery_zones_aggregate: Delivery_Zone_Aggregate;
  description?: Maybe<Scalars['String']['output']>;
  enable_invoice_generation: Scalars['Boolean']['output'];
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  invoice_prefix: Scalars['String']['output'];
  is_visible_in_discovery: Scalars['Boolean']['output'];
  legalName: Scalars['String']['output'];
  lemonway_iban_id?: Maybe<Scalars['Int']['output']>;
  national_company_registration_number: Scalars['String']['output'];
  /** An array relationship */
  packagings: Array<Packaging>;
  /** An aggregate relationship */
  packagings_aggregate: Packaging_Aggregate;
  /** An array relationship */
  photos: Array<Company_Photo>;
  /** An aggregate relationship */
  photos_aggregate: Company_Photo_Aggregate;
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  /** An array relationship */
  role_assignments: Array<Role_Assignment>;
  /** An aggregate relationship */
  role_assignments_aggregate: Role_Assignment_Aggregate;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  supplying_contracts: Array<Contract>;
  /** An aggregate relationship */
  supplying_contracts_aggregate: Contract_Aggregate;
  tradeName: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "company" */
export type CompanyAddressesArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyBuying_ContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyBuying_Contracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyDelivery_ZonesArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyDelivery_Zones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyPackagingsArgs = {
  distinct_on?: InputMaybe<Array<Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_Order_By>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyPackagings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_Order_By>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyPhotosArgs = {
  distinct_on?: InputMaybe<Array<Company_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Photo_Order_By>>;
  where?: InputMaybe<Company_Photo_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyPhotos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Photo_Order_By>>;
  where?: InputMaybe<Company_Photo_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyProductsArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyRole_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Role_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Assignment_Order_By>>;
  where?: InputMaybe<Role_Assignment_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyRole_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Assignment_Order_By>>;
  where?: InputMaybe<Role_Assignment_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanySupplying_ContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanySupplying_Contracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

/** aggregated selection of "company" */
export type Company_Aggregate = {
  __typename?: 'company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
  __typename?: 'company_aggregate_fields';
  avg?: Maybe<Company_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
  stddev?: Maybe<Company_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Sum_Fields>;
  var_pop?: Maybe<Company_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Var_Samp_Fields>;
  variance?: Maybe<Company_Variance_Fields>;
};


/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Company_Avg_Fields = {
  __typename?: 'company_avg_fields';
  default_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
  lemonway_iban_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Bool_Exp>>;
  _not?: InputMaybe<Company_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Bool_Exp>>;
  addresses?: InputMaybe<Address_Bool_Exp>;
  addresses_aggregate?: InputMaybe<Address_Aggregate_Bool_Exp>;
  brand?: InputMaybe<Brand_Enum_Comparison_Exp>;
  business_profile?: InputMaybe<Business_Profile_Enum_Comparison_Exp>;
  buying_contracts?: InputMaybe<Contract_Bool_Exp>;
  buying_contracts_aggregate?: InputMaybe<Contract_Aggregate_Bool_Exp>;
  commission?: InputMaybe<Commission_Bool_Exp>;
  contactEmail?: InputMaybe<String_Comparison_Exp>;
  contactPhoneNumber?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default_commission_type?: InputMaybe<Commission_Type_Enum_Comparison_Exp>;
  default_custom_commission_rate?: InputMaybe<Numeric_Comparison_Exp>;
  default_payment_method?: InputMaybe<Payment_Method_Enum_Comparison_Exp>;
  delivery_zones?: InputMaybe<Delivery_Zone_Bool_Exp>;
  delivery_zones_aggregate?: InputMaybe<Delivery_Zone_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  enable_invoice_generation?: InputMaybe<Boolean_Comparison_Exp>;
  iban?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_prefix?: InputMaybe<String_Comparison_Exp>;
  is_visible_in_discovery?: InputMaybe<Boolean_Comparison_Exp>;
  legalName?: InputMaybe<String_Comparison_Exp>;
  lemonway_iban_id?: InputMaybe<Int_Comparison_Exp>;
  national_company_registration_number?: InputMaybe<String_Comparison_Exp>;
  packagings?: InputMaybe<Packaging_Bool_Exp>;
  packagings_aggregate?: InputMaybe<Packaging_Aggregate_Bool_Exp>;
  photos?: InputMaybe<Company_Photo_Bool_Exp>;
  photos_aggregate?: InputMaybe<Company_Photo_Aggregate_Bool_Exp>;
  products?: InputMaybe<Product_Bool_Exp>;
  products_aggregate?: InputMaybe<Product_Aggregate_Bool_Exp>;
  role_assignments?: InputMaybe<Role_Assignment_Bool_Exp>;
  role_assignments_aggregate?: InputMaybe<Role_Assignment_Aggregate_Bool_Exp>;
  supplier_commission_rate?: InputMaybe<Numeric_Comparison_Exp>;
  supplying_contracts?: InputMaybe<Contract_Bool_Exp>;
  supplying_contracts_aggregate?: InputMaybe<Contract_Aggregate_Bool_Exp>;
  tradeName?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_056f7854a7afdba7cbd6d45fc20 = 'PK_056f7854a7afdba7cbd6d45fc20',
  /** unique or primary key constraint on columns "invoice_prefix" */
  CompanyInvoicePrefixKey = 'company_invoice_prefix_key',
  /** unique or primary key constraint on columns "lemonway_iban_id" */
  CompanyLemonwayIbanIdKey = 'company_lemonway_iban_id_key',
  /** unique or primary key constraint on columns "national_company_registration_number" */
  UqCompanyNationalCompanyRegistrationNumber = 'uq_company_national_company_registration_number'
}

/** input type for incrementing numeric columns in table "company" */
export type Company_Inc_Input = {
  default_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
  lemonway_iban_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  addresses?: InputMaybe<Address_Arr_Rel_Insert_Input>;
  brand?: InputMaybe<Brand_Enum>;
  business_profile?: InputMaybe<Business_Profile_Enum>;
  buying_contracts?: InputMaybe<Contract_Arr_Rel_Insert_Input>;
  commission?: InputMaybe<Commission_Obj_Rel_Insert_Input>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_commission_type?: InputMaybe<Commission_Type_Enum>;
  default_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
  default_payment_method?: InputMaybe<Payment_Method_Enum>;
  delivery_zones?: InputMaybe<Delivery_Zone_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  enable_invoice_generation?: InputMaybe<Scalars['Boolean']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoice_prefix?: InputMaybe<Scalars['String']['input']>;
  is_visible_in_discovery?: InputMaybe<Scalars['Boolean']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  lemonway_iban_id?: InputMaybe<Scalars['Int']['input']>;
  national_company_registration_number?: InputMaybe<Scalars['String']['input']>;
  packagings?: InputMaybe<Packaging_Arr_Rel_Insert_Input>;
  photos?: InputMaybe<Company_Photo_Arr_Rel_Insert_Input>;
  products?: InputMaybe<Product_Arr_Rel_Insert_Input>;
  role_assignments?: InputMaybe<Role_Assignment_Arr_Rel_Insert_Input>;
  supplying_contracts?: InputMaybe<Contract_Arr_Rel_Insert_Input>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: 'company_max_fields';
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  default_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invoice_prefix?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  lemonway_iban_id?: Maybe<Scalars['Int']['output']>;
  national_company_registration_number?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  tradeName?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: 'company_min_fields';
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  default_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invoice_prefix?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  lemonway_iban_id?: Maybe<Scalars['Int']['output']>;
  national_company_registration_number?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  tradeName?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
  __typename?: 'company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** on_conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns?: Array<Company_Update_Column>;
  where?: InputMaybe<Company_Bool_Exp>;
};

/** Ordering options when selecting data from "company". */
export type Company_Order_By = {
  addresses_aggregate?: InputMaybe<Address_Aggregate_Order_By>;
  brand?: InputMaybe<Order_By>;
  business_profile?: InputMaybe<Order_By>;
  buying_contracts_aggregate?: InputMaybe<Contract_Aggregate_Order_By>;
  commission?: InputMaybe<Commission_Order_By>;
  contactEmail?: InputMaybe<Order_By>;
  contactPhoneNumber?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_commission_type?: InputMaybe<Order_By>;
  default_custom_commission_rate?: InputMaybe<Order_By>;
  default_payment_method?: InputMaybe<Order_By>;
  delivery_zones_aggregate?: InputMaybe<Delivery_Zone_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  enable_invoice_generation?: InputMaybe<Order_By>;
  iban?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_prefix?: InputMaybe<Order_By>;
  is_visible_in_discovery?: InputMaybe<Order_By>;
  legalName?: InputMaybe<Order_By>;
  lemonway_iban_id?: InputMaybe<Order_By>;
  national_company_registration_number?: InputMaybe<Order_By>;
  packagings_aggregate?: InputMaybe<Packaging_Aggregate_Order_By>;
  photos_aggregate?: InputMaybe<Company_Photo_Aggregate_Order_By>;
  products_aggregate?: InputMaybe<Product_Aggregate_Order_By>;
  role_assignments_aggregate?: InputMaybe<Role_Assignment_Aggregate_Order_By>;
  supplier_commission_rate?: InputMaybe<Order_By>;
  supplying_contracts_aggregate?: InputMaybe<Contract_Aggregate_Order_By>;
  tradeName?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "company_photo" */
export type Company_Photo = {
  __typename?: 'company_photo';
  /** An object relationship */
  company: Company;
  company_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  photo: Photo;
  photo_id: Scalars['uuid']['output'];
};

/** aggregated selection of "company_photo" */
export type Company_Photo_Aggregate = {
  __typename?: 'company_photo_aggregate';
  aggregate?: Maybe<Company_Photo_Aggregate_Fields>;
  nodes: Array<Company_Photo>;
};

export type Company_Photo_Aggregate_Bool_Exp = {
  count?: InputMaybe<Company_Photo_Aggregate_Bool_Exp_Count>;
};

export type Company_Photo_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Photo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Company_Photo_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "company_photo" */
export type Company_Photo_Aggregate_Fields = {
  __typename?: 'company_photo_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Company_Photo_Max_Fields>;
  min?: Maybe<Company_Photo_Min_Fields>;
};


/** aggregate fields of "company_photo" */
export type Company_Photo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Photo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "company_photo" */
export type Company_Photo_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Photo_Max_Order_By>;
  min?: InputMaybe<Company_Photo_Min_Order_By>;
};

/** input type for inserting array relation for remote table "company_photo" */
export type Company_Photo_Arr_Rel_Insert_Input = {
  data: Array<Company_Photo_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Photo_On_Conflict>;
};

/** Boolean expression to filter rows from the table "company_photo". All fields are combined with a logical 'AND'. */
export type Company_Photo_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Photo_Bool_Exp>>;
  _not?: InputMaybe<Company_Photo_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Photo_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  photo?: InputMaybe<Photo_Bool_Exp>;
  photo_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_photo" */
export enum Company_Photo_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyPhotoPkey = 'company_photo_pkey'
}

/** input type for inserting data into table "company_photo" */
export type Company_Photo_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  photo_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Company_Photo_Max_Fields = {
  __typename?: 'company_photo_max_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  photo_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "company_photo" */
export type Company_Photo_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photo_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Photo_Min_Fields = {
  __typename?: 'company_photo_min_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  photo_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "company_photo" */
export type Company_Photo_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photo_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_photo" */
export type Company_Photo_Mutation_Response = {
  __typename?: 'company_photo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Photo>;
};

/** on_conflict condition type for table "company_photo" */
export type Company_Photo_On_Conflict = {
  constraint: Company_Photo_Constraint;
  update_columns?: Array<Company_Photo_Update_Column>;
  where?: InputMaybe<Company_Photo_Bool_Exp>;
};

/** Ordering options when selecting data from "company_photo". */
export type Company_Photo_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photo?: InputMaybe<Photo_Order_By>;
  photo_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_photo */
export type Company_Photo_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "company_photo" */
export enum Company_Photo_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoId = 'photo_id'
}

/** input type for updating data in table "company_photo" */
export type Company_Photo_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  photo_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "company_photo" */
export type Company_Photo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Photo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Photo_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  photo_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "company_photo" */
export enum Company_Photo_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoId = 'photo_id'
}

export type Company_Photo_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Photo_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Photo_Bool_Exp;
};

/** primary key columns input for table: company */
export type Company_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** company_product_count_by_sub_familyNative Query Arguments */
export type Company_Product_Count_By_Sub_Family_Arguments = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type Company_Product_Sub_Family_Count = {
  __typename?: 'company_product_sub_family_count';
  company_id: Scalars['uuid']['output'];
  count: Scalars['bigint']['output'];
  sub_family: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the logical model for "company_product_sub_family_count". All fields are combined with a logical 'AND'. */
export type Company_Product_Sub_Family_Count_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Product_Sub_Family_Count_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<Company_Product_Sub_Family_Count_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Product_Sub_Family_Count_Bool_Exp_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  sub_family?: InputMaybe<String_Comparison_Exp>;
};

export enum Company_Product_Sub_Family_Count_Enum_Name {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  SubFamily = 'sub_family'
}

/** Ordering options when selecting data from "company_product_sub_family_count". */
export type Company_Product_Sub_Family_Count_Order_By = {
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  sub_family?: InputMaybe<Order_By>;
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  BusinessProfile = 'business_profile',
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  ContactPhoneNumber = 'contactPhoneNumber',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultCommissionType = 'default_commission_type',
  /** column name */
  DefaultCustomCommissionRate = 'default_custom_commission_rate',
  /** column name */
  DefaultPaymentMethod = 'default_payment_method',
  /** column name */
  Description = 'description',
  /** column name */
  EnableInvoiceGeneration = 'enable_invoice_generation',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  InvoicePrefix = 'invoice_prefix',
  /** column name */
  IsVisibleInDiscovery = 'is_visible_in_discovery',
  /** column name */
  LegalName = 'legalName',
  /** column name */
  LemonwayIbanId = 'lemonway_iban_id',
  /** column name */
  NationalCompanyRegistrationNumber = 'national_company_registration_number',
  /** column name */
  TradeName = 'tradeName',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  brand?: InputMaybe<Brand_Enum>;
  business_profile?: InputMaybe<Business_Profile_Enum>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_commission_type?: InputMaybe<Commission_Type_Enum>;
  default_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
  default_payment_method?: InputMaybe<Payment_Method_Enum>;
  description?: InputMaybe<Scalars['String']['input']>;
  enable_invoice_generation?: InputMaybe<Scalars['Boolean']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoice_prefix?: InputMaybe<Scalars['String']['input']>;
  is_visible_in_discovery?: InputMaybe<Scalars['Boolean']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  lemonway_iban_id?: InputMaybe<Scalars['Int']['input']>;
  national_company_registration_number?: InputMaybe<Scalars['String']['input']>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Company_Stddev_Fields = {
  __typename?: 'company_stddev_fields';
  default_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
  lemonway_iban_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_pop on columns */
export type Company_Stddev_Pop_Fields = {
  __typename?: 'company_stddev_pop_fields';
  default_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
  lemonway_iban_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate stddev_samp on columns */
export type Company_Stddev_Samp_Fields = {
  __typename?: 'company_stddev_samp_fields';
  default_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
  lemonway_iban_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
};

/** Streaming cursor of the table "company" */
export type Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Stream_Cursor_Value_Input = {
  brand?: InputMaybe<Brand_Enum>;
  business_profile?: InputMaybe<Business_Profile_Enum>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_commission_type?: InputMaybe<Commission_Type_Enum>;
  default_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
  default_payment_method?: InputMaybe<Payment_Method_Enum>;
  description?: InputMaybe<Scalars['String']['input']>;
  enable_invoice_generation?: InputMaybe<Scalars['Boolean']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoice_prefix?: InputMaybe<Scalars['String']['input']>;
  is_visible_in_discovery?: InputMaybe<Scalars['Boolean']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  lemonway_iban_id?: InputMaybe<Scalars['Int']['input']>;
  national_company_registration_number?: InputMaybe<Scalars['String']['input']>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Company_Sum_Fields = {
  __typename?: 'company_sum_fields';
  default_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
  lemonway_iban_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  BusinessProfile = 'business_profile',
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  ContactPhoneNumber = 'contactPhoneNumber',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultCommissionType = 'default_commission_type',
  /** column name */
  DefaultCustomCommissionRate = 'default_custom_commission_rate',
  /** column name */
  DefaultPaymentMethod = 'default_payment_method',
  /** column name */
  Description = 'description',
  /** column name */
  EnableInvoiceGeneration = 'enable_invoice_generation',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  InvoicePrefix = 'invoice_prefix',
  /** column name */
  IsVisibleInDiscovery = 'is_visible_in_discovery',
  /** column name */
  LegalName = 'legalName',
  /** column name */
  LemonwayIbanId = 'lemonway_iban_id',
  /** column name */
  NationalCompanyRegistrationNumber = 'national_company_registration_number',
  /** column name */
  TradeName = 'tradeName',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Company_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Var_Pop_Fields = {
  __typename?: 'company_var_pop_fields';
  default_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
  lemonway_iban_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_samp on columns */
export type Company_Var_Samp_Fields = {
  __typename?: 'company_var_samp_fields';
  default_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
  lemonway_iban_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate variance on columns */
export type Company_Variance_Fields = {
  __typename?: 'company_variance_fields';
  default_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
  lemonway_iban_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_company_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
};

/** columns and relationships of "contract" */
export type Contract = {
  __typename?: 'contract';
  buyingCompanyId: Scalars['uuid']['output'];
  /** An object relationship */
  buying_company: Company;
  /** An object relationship */
  catalog?: Maybe<Catalog>;
  catalog_id?: Maybe<Scalars['uuid']['output']>;
  channel_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  commission?: Maybe<Commission>;
  created_at: Scalars['timestamptz']['output'];
  custom_sdd_payment_delay?: Maybe<Scalars['Int']['output']>;
  discount: Scalars['numeric']['output'];
  id: Scalars['uuid']['output'];
  /** A computed field, executes function "compute_last_order_date" */
  last_order_date?: Maybe<Scalars['timestamptz']['output']>;
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  payment_method?: Maybe<Payment_Method_Enum>;
  /** An object relationship */
  proposer?: Maybe<User>;
  proposer_business_profile?: Maybe<Business_Profile_Enum>;
  proposer_user_id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  status: Contract_Status_Enum;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  /** Non-nullable is only applied by supplier_commission_type_constraint */
  supplier_commission_type?: Maybe<Commission_Type_Enum>;
  supplier_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplyingCompanyId: Scalars['uuid']['output'];
  /** An object relationship */
  supplying_company: Company;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "contract" */
export type Contract_Aggregate = {
  __typename?: 'contract_aggregate';
  aggregate?: Maybe<Contract_Aggregate_Fields>;
  nodes: Array<Contract>;
};

export type Contract_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contract_Aggregate_Bool_Exp_Count>;
};

export type Contract_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Contract_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contract" */
export type Contract_Aggregate_Fields = {
  __typename?: 'contract_aggregate_fields';
  avg?: Maybe<Contract_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Contract_Max_Fields>;
  min?: Maybe<Contract_Min_Fields>;
  stddev?: Maybe<Contract_Stddev_Fields>;
  stddev_pop?: Maybe<Contract_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contract_Stddev_Samp_Fields>;
  sum?: Maybe<Contract_Sum_Fields>;
  var_pop?: Maybe<Contract_Var_Pop_Fields>;
  var_samp?: Maybe<Contract_Var_Samp_Fields>;
  variance?: Maybe<Contract_Variance_Fields>;
};


/** aggregate fields of "contract" */
export type Contract_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contract" */
export type Contract_Aggregate_Order_By = {
  avg?: InputMaybe<Contract_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contract_Max_Order_By>;
  min?: InputMaybe<Contract_Min_Order_By>;
  stddev?: InputMaybe<Contract_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contract_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contract_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contract_Sum_Order_By>;
  var_pop?: InputMaybe<Contract_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contract_Var_Samp_Order_By>;
  variance?: InputMaybe<Contract_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contract" */
export type Contract_Arr_Rel_Insert_Input = {
  data: Array<Contract_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contract_On_Conflict>;
};

/** aggregate avg on columns */
export type Contract_Avg_Fields = {
  __typename?: 'contract_avg_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "contract" */
export type Contract_Avg_Order_By = {
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contract". All fields are combined with a logical 'AND'. */
export type Contract_Bool_Exp = {
  _and?: InputMaybe<Array<Contract_Bool_Exp>>;
  _not?: InputMaybe<Contract_Bool_Exp>;
  _or?: InputMaybe<Array<Contract_Bool_Exp>>;
  buyingCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
  buying_company?: InputMaybe<Company_Bool_Exp>;
  catalog?: InputMaybe<Catalog_Bool_Exp>;
  catalog_id?: InputMaybe<Uuid_Comparison_Exp>;
  channel_url?: InputMaybe<String_Comparison_Exp>;
  commission?: InputMaybe<Commission_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_sdd_payment_delay?: InputMaybe<Int_Comparison_Exp>;
  discount?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_order_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  minimum_order_value?: InputMaybe<Numeric_Comparison_Exp>;
  order_count?: InputMaybe<Numeric_Comparison_Exp>;
  payment_method?: InputMaybe<Payment_Method_Enum_Comparison_Exp>;
  proposer?: InputMaybe<User_Bool_Exp>;
  proposer_business_profile?: InputMaybe<Business_Profile_Enum_Comparison_Exp>;
  proposer_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  reference_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Contract_Status_Enum_Comparison_Exp>;
  supplier_commission_rate?: InputMaybe<Numeric_Comparison_Exp>;
  supplier_commission_type?: InputMaybe<Commission_Type_Enum_Comparison_Exp>;
  supplier_custom_commission_rate?: InputMaybe<Numeric_Comparison_Exp>;
  supplyingCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
  supplying_company?: InputMaybe<Company_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contract" */
export enum Contract_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_17c3a89f58a2997276084e706e8 = 'PK_17c3a89f58a2997276084e706e8',
  /** unique or primary key constraint on columns "buyingCompanyId", "supplyingCompanyId" */
  ContractBuyingCompanyIdSupplyingCompanyIdKey = 'contract_buyingCompanyId_supplyingCompanyId_key',
  /** unique or primary key constraint on columns "channel_url" */
  ContractChannelUrlKey = 'contract_channel_url_key',
  /** unique or primary key constraint on columns "reference_id" */
  ContractReferenceIdKey = 'contract_reference_id_key'
}

/** columns and relationships of "contract_history" */
export type Contract_History = {
  __typename?: 'contract_history';
  buyingCompanyId: Scalars['uuid']['output'];
  /** An object relationship */
  buying_company: Company;
  /** An object relationship */
  catalog?: Maybe<Catalog>;
  catalog_id?: Maybe<Scalars['uuid']['output']>;
  channel_url?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  custom_sdd_payment_delay?: Maybe<Scalars['Int']['output']>;
  discount: Scalars['numeric']['output'];
  id: Scalars['uuid']['output'];
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregate relationship */
  orders_aggregate: Order_Aggregate;
  payment_method?: Maybe<Scalars['String']['output']>;
  proposer_business_profile?: Maybe<Business_Profile_Enum>;
  proposer_user_id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  status: Contract_Status_Enum;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_commission_type?: Maybe<Commission_Type_Enum>;
  supplier_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplyingCompanyId: Scalars['uuid']['output'];
  /** An object relationship */
  supplying_company: Company;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "contract_history" */
export type Contract_HistoryOrdersArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


/** columns and relationships of "contract_history" */
export type Contract_HistoryOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};

/** aggregated selection of "contract_history" */
export type Contract_History_Aggregate = {
  __typename?: 'contract_history_aggregate';
  aggregate?: Maybe<Contract_History_Aggregate_Fields>;
  nodes: Array<Contract_History>;
};

/** aggregate fields of "contract_history" */
export type Contract_History_Aggregate_Fields = {
  __typename?: 'contract_history_aggregate_fields';
  avg?: Maybe<Contract_History_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Contract_History_Max_Fields>;
  min?: Maybe<Contract_History_Min_Fields>;
  stddev?: Maybe<Contract_History_Stddev_Fields>;
  stddev_pop?: Maybe<Contract_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contract_History_Stddev_Samp_Fields>;
  sum?: Maybe<Contract_History_Sum_Fields>;
  var_pop?: Maybe<Contract_History_Var_Pop_Fields>;
  var_samp?: Maybe<Contract_History_Var_Samp_Fields>;
  variance?: Maybe<Contract_History_Variance_Fields>;
};


/** aggregate fields of "contract_history" */
export type Contract_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contract_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Contract_History_Avg_Fields = {
  __typename?: 'contract_history_avg_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "contract_history". All fields are combined with a logical 'AND'. */
export type Contract_History_Bool_Exp = {
  _and?: InputMaybe<Array<Contract_History_Bool_Exp>>;
  _not?: InputMaybe<Contract_History_Bool_Exp>;
  _or?: InputMaybe<Array<Contract_History_Bool_Exp>>;
  buyingCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
  buying_company?: InputMaybe<Company_Bool_Exp>;
  catalog?: InputMaybe<Catalog_Bool_Exp>;
  catalog_id?: InputMaybe<Uuid_Comparison_Exp>;
  channel_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_sdd_payment_delay?: InputMaybe<Int_Comparison_Exp>;
  discount?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  minimum_order_value?: InputMaybe<Numeric_Comparison_Exp>;
  orders?: InputMaybe<Order_Bool_Exp>;
  orders_aggregate?: InputMaybe<Order_Aggregate_Bool_Exp>;
  payment_method?: InputMaybe<String_Comparison_Exp>;
  proposer_business_profile?: InputMaybe<Business_Profile_Enum_Comparison_Exp>;
  proposer_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  reference_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Contract_Status_Enum_Comparison_Exp>;
  supplier_commission_rate?: InputMaybe<Numeric_Comparison_Exp>;
  supplier_commission_type?: InputMaybe<Commission_Type_Enum_Comparison_Exp>;
  supplier_custom_commission_rate?: InputMaybe<Numeric_Comparison_Exp>;
  supplyingCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
  supplying_company?: InputMaybe<Company_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contract_history" */
export enum Contract_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContractHistoryPkey = 'contract_history_pkey'
}

/** input type for incrementing numeric columns in table "contract_history" */
export type Contract_History_Inc_Input = {
  custom_sdd_payment_delay?: InputMaybe<Scalars['Int']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  supplier_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "contract_history" */
export type Contract_History_Insert_Input = {
  buyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  buying_company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  catalog?: InputMaybe<Catalog_Obj_Rel_Insert_Input>;
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  channel_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_sdd_payment_delay?: InputMaybe<Scalars['Int']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  orders?: InputMaybe<Order_Arr_Rel_Insert_Input>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  proposer_business_profile?: InputMaybe<Business_Profile_Enum>;
  proposer_user_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Contract_Status_Enum>;
  supplier_commission_type?: InputMaybe<Commission_Type_Enum>;
  supplier_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
  supplyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  supplying_company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Contract_History_Max_Fields = {
  __typename?: 'contract_history_max_fields';
  buyingCompanyId?: Maybe<Scalars['uuid']['output']>;
  catalog_id?: Maybe<Scalars['uuid']['output']>;
  channel_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  custom_sdd_payment_delay?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  proposer_user_id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplyingCompanyId?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Contract_History_Min_Fields = {
  __typename?: 'contract_history_min_fields';
  buyingCompanyId?: Maybe<Scalars['uuid']['output']>;
  catalog_id?: Maybe<Scalars['uuid']['output']>;
  channel_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  custom_sdd_payment_delay?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  proposer_user_id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplyingCompanyId?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "contract_history" */
export type Contract_History_Mutation_Response = {
  __typename?: 'contract_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contract_History>;
};

/** input type for inserting object relation for remote table "contract_history" */
export type Contract_History_Obj_Rel_Insert_Input = {
  data: Contract_History_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contract_History_On_Conflict>;
};

/** on_conflict condition type for table "contract_history" */
export type Contract_History_On_Conflict = {
  constraint: Contract_History_Constraint;
  update_columns?: Array<Contract_History_Update_Column>;
  where?: InputMaybe<Contract_History_Bool_Exp>;
};

/** Ordering options when selecting data from "contract_history". */
export type Contract_History_Order_By = {
  buyingCompanyId?: InputMaybe<Order_By>;
  buying_company?: InputMaybe<Company_Order_By>;
  catalog?: InputMaybe<Catalog_Order_By>;
  catalog_id?: InputMaybe<Order_By>;
  channel_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  orders_aggregate?: InputMaybe<Order_Aggregate_Order_By>;
  payment_method?: InputMaybe<Order_By>;
  proposer_business_profile?: InputMaybe<Order_By>;
  proposer_user_id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  supplier_commission_rate?: InputMaybe<Order_By>;
  supplier_commission_type?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
  supplyingCompanyId?: InputMaybe<Order_By>;
  supplying_company?: InputMaybe<Company_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contract_history */
export type Contract_History_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "contract_history" */
export enum Contract_History_Select_Column {
  /** column name */
  BuyingCompanyId = 'buyingCompanyId',
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  ChannelUrl = 'channel_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomSddPaymentDelay = 'custom_sdd_payment_delay',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumOrderValue = 'minimum_order_value',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  ProposerBusinessProfile = 'proposer_business_profile',
  /** column name */
  ProposerUserId = 'proposer_user_id',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierCommissionType = 'supplier_commission_type',
  /** column name */
  SupplierCustomCommissionRate = 'supplier_custom_commission_rate',
  /** column name */
  SupplyingCompanyId = 'supplyingCompanyId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contract_history" */
export type Contract_History_Set_Input = {
  buyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  channel_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_sdd_payment_delay?: InputMaybe<Scalars['Int']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  proposer_business_profile?: InputMaybe<Business_Profile_Enum>;
  proposer_user_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Contract_Status_Enum>;
  supplier_commission_type?: InputMaybe<Commission_Type_Enum>;
  supplier_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
  supplyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Contract_History_Stddev_Fields = {
  __typename?: 'contract_history_stddev_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Contract_History_Stddev_Pop_Fields = {
  __typename?: 'contract_history_stddev_pop_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Contract_History_Stddev_Samp_Fields = {
  __typename?: 'contract_history_stddev_samp_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "contract_history" */
export type Contract_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contract_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contract_History_Stream_Cursor_Value_Input = {
  buyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  channel_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_sdd_payment_delay?: InputMaybe<Scalars['Int']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  proposer_business_profile?: InputMaybe<Business_Profile_Enum>;
  proposer_user_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Contract_Status_Enum>;
  supplier_commission_type?: InputMaybe<Commission_Type_Enum>;
  supplier_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
  supplyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Contract_History_Sum_Fields = {
  __typename?: 'contract_history_sum_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['numeric']['output']>;
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "contract_history" */
export enum Contract_History_Update_Column {
  /** column name */
  BuyingCompanyId = 'buyingCompanyId',
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  ChannelUrl = 'channel_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomSddPaymentDelay = 'custom_sdd_payment_delay',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumOrderValue = 'minimum_order_value',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  ProposerBusinessProfile = 'proposer_business_profile',
  /** column name */
  ProposerUserId = 'proposer_user_id',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierCommissionType = 'supplier_commission_type',
  /** column name */
  SupplierCustomCommissionRate = 'supplier_custom_commission_rate',
  /** column name */
  SupplyingCompanyId = 'supplyingCompanyId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Contract_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contract_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contract_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contract_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contract_History_Var_Pop_Fields = {
  __typename?: 'contract_history_var_pop_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Contract_History_Var_Samp_Fields = {
  __typename?: 'contract_history_var_samp_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Contract_History_Variance_Fields = {
  __typename?: 'contract_history_variance_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_history_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "contract" */
export type Contract_Inc_Input = {
  custom_sdd_payment_delay?: InputMaybe<Scalars['Int']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  supplier_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "contract" */
export type Contract_Insert_Input = {
  buyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  buying_company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  catalog?: InputMaybe<Catalog_Obj_Rel_Insert_Input>;
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  channel_url?: InputMaybe<Scalars['String']['input']>;
  commission?: InputMaybe<Commission_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_sdd_payment_delay?: InputMaybe<Scalars['Int']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  payment_method?: InputMaybe<Payment_Method_Enum>;
  proposer?: InputMaybe<User_Obj_Rel_Insert_Input>;
  proposer_business_profile?: InputMaybe<Business_Profile_Enum>;
  proposer_user_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Contract_Status_Enum>;
  /** Non-nullable is only applied by supplier_commission_type_constraint */
  supplier_commission_type?: InputMaybe<Commission_Type_Enum>;
  supplier_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
  supplyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  supplying_company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Contract_Max_Fields = {
  __typename?: 'contract_max_fields';
  buyingCompanyId?: Maybe<Scalars['uuid']['output']>;
  catalog_id?: Maybe<Scalars['uuid']['output']>;
  channel_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  custom_sdd_payment_delay?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_last_order_date" */
  last_order_date?: Maybe<Scalars['timestamptz']['output']>;
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  proposer_user_id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplyingCompanyId?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "contract" */
export type Contract_Max_Order_By = {
  buyingCompanyId?: InputMaybe<Order_By>;
  catalog_id?: InputMaybe<Order_By>;
  channel_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  proposer_user_id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
  supplyingCompanyId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contract_Min_Fields = {
  __typename?: 'contract_min_fields';
  buyingCompanyId?: Maybe<Scalars['uuid']['output']>;
  catalog_id?: Maybe<Scalars['uuid']['output']>;
  channel_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  custom_sdd_payment_delay?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_last_order_date" */
  last_order_date?: Maybe<Scalars['timestamptz']['output']>;
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  proposer_user_id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplyingCompanyId?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "contract" */
export type Contract_Min_Order_By = {
  buyingCompanyId?: InputMaybe<Order_By>;
  catalog_id?: InputMaybe<Order_By>;
  channel_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  proposer_user_id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
  supplyingCompanyId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contract" */
export type Contract_Mutation_Response = {
  __typename?: 'contract_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contract>;
};

/** input type for inserting object relation for remote table "contract" */
export type Contract_Obj_Rel_Insert_Input = {
  data: Contract_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contract_On_Conflict>;
};

/** on_conflict condition type for table "contract" */
export type Contract_On_Conflict = {
  constraint: Contract_Constraint;
  update_columns?: Array<Contract_Update_Column>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

/** Ordering options when selecting data from "contract". */
export type Contract_Order_By = {
  buyingCompanyId?: InputMaybe<Order_By>;
  buying_company?: InputMaybe<Company_Order_By>;
  catalog?: InputMaybe<Catalog_Order_By>;
  catalog_id?: InputMaybe<Order_By>;
  channel_url?: InputMaybe<Order_By>;
  commission?: InputMaybe<Commission_Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_order_date?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  order_count?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  proposer?: InputMaybe<User_Order_By>;
  proposer_business_profile?: InputMaybe<Order_By>;
  proposer_user_id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  supplier_commission_rate?: InputMaybe<Order_By>;
  supplier_commission_type?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
  supplyingCompanyId?: InputMaybe<Order_By>;
  supplying_company?: InputMaybe<Company_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contract */
export type Contract_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "contract" */
export enum Contract_Select_Column {
  /** column name */
  BuyingCompanyId = 'buyingCompanyId',
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  ChannelUrl = 'channel_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomSddPaymentDelay = 'custom_sdd_payment_delay',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumOrderValue = 'minimum_order_value',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  ProposerBusinessProfile = 'proposer_business_profile',
  /** column name */
  ProposerUserId = 'proposer_user_id',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierCommissionType = 'supplier_commission_type',
  /** column name */
  SupplierCustomCommissionRate = 'supplier_custom_commission_rate',
  /** column name */
  SupplyingCompanyId = 'supplyingCompanyId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contract" */
export type Contract_Set_Input = {
  buyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  channel_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_sdd_payment_delay?: InputMaybe<Scalars['Int']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  payment_method?: InputMaybe<Payment_Method_Enum>;
  proposer_business_profile?: InputMaybe<Business_Profile_Enum>;
  proposer_user_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Contract_Status_Enum>;
  /** Non-nullable is only applied by supplier_commission_type_constraint */
  supplier_commission_type?: InputMaybe<Commission_Type_Enum>;
  supplier_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
  supplyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "contract_status" */
export type Contract_Status = {
  __typename?: 'contract_status';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "contract_status" */
export type Contract_Status_Aggregate = {
  __typename?: 'contract_status_aggregate';
  aggregate?: Maybe<Contract_Status_Aggregate_Fields>;
  nodes: Array<Contract_Status>;
};

/** aggregate fields of "contract_status" */
export type Contract_Status_Aggregate_Fields = {
  __typename?: 'contract_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contract_Status_Max_Fields>;
  min?: Maybe<Contract_Status_Min_Fields>;
};


/** aggregate fields of "contract_status" */
export type Contract_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contract_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "contract_status". All fields are combined with a logical 'AND'. */
export type Contract_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Contract_Status_Bool_Exp>>;
  _not?: InputMaybe<Contract_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Contract_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contract_status" */
export enum Contract_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ContractStatusPkey = 'contract_status_pkey'
}

export enum Contract_Status_Enum {
  Signed = 'SIGNED',
  Unsigned = 'UNSIGNED'
}

/** Boolean expression to compare columns of type "contract_status_enum". All fields are combined with logical 'AND'. */
export type Contract_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Contract_Status_Enum>;
  _in?: InputMaybe<Array<Contract_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Contract_Status_Enum>;
  _nin?: InputMaybe<Array<Contract_Status_Enum>>;
};

/** input type for inserting data into table "contract_status" */
export type Contract_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Contract_Status_Max_Fields = {
  __typename?: 'contract_status_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Contract_Status_Min_Fields = {
  __typename?: 'contract_status_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "contract_status" */
export type Contract_Status_Mutation_Response = {
  __typename?: 'contract_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contract_Status>;
};

/** on_conflict condition type for table "contract_status" */
export type Contract_Status_On_Conflict = {
  constraint: Contract_Status_Constraint;
  update_columns?: Array<Contract_Status_Update_Column>;
  where?: InputMaybe<Contract_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "contract_status". */
export type Contract_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contract_status */
export type Contract_Status_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "contract_status" */
export enum Contract_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "contract_status" */
export type Contract_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "contract_status" */
export type Contract_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contract_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contract_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "contract_status" */
export enum Contract_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Contract_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contract_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contract_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Contract_Stddev_Fields = {
  __typename?: 'contract_stddev_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "contract" */
export type Contract_Stddev_Order_By = {
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contract_Stddev_Pop_Fields = {
  __typename?: 'contract_stddev_pop_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "contract" */
export type Contract_Stddev_Pop_Order_By = {
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contract_Stddev_Samp_Fields = {
  __typename?: 'contract_stddev_samp_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "contract" */
export type Contract_Stddev_Samp_Order_By = {
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "contract" */
export type Contract_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contract_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contract_Stream_Cursor_Value_Input = {
  buyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  catalog_id?: InputMaybe<Scalars['uuid']['input']>;
  channel_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_sdd_payment_delay?: InputMaybe<Scalars['Int']['input']>;
  discount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  payment_method?: InputMaybe<Payment_Method_Enum>;
  proposer_business_profile?: InputMaybe<Business_Profile_Enum>;
  proposer_user_id?: InputMaybe<Scalars['uuid']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Contract_Status_Enum>;
  /** Non-nullable is only applied by supplier_commission_type_constraint */
  supplier_commission_type?: InputMaybe<Commission_Type_Enum>;
  supplier_custom_commission_rate?: InputMaybe<Scalars['numeric']['input']>;
  supplyingCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Contract_Sum_Fields = {
  __typename?: 'contract_sum_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['numeric']['output']>;
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "contract" */
export type Contract_Sum_Order_By = {
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
};

export type Contract_Total_Price = {
  __typename?: 'contract_total_price';
  reference_id: Scalars['uuid']['output'];
  total_price: Scalars['numeric']['output'];
  user_id: Scalars['uuid']['output'];
};

/** contract_total_priceNative Query Arguments */
export type Contract_Total_Price_Arguments = {
  buying_company_id: Scalars['uuid']['input'];
  supplying_company_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

/** Boolean expression to filter rows from the logical model for "contract_total_price". All fields are combined with a logical 'AND'. */
export type Contract_Total_Price_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<Contract_Total_Price_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<Contract_Total_Price_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<Contract_Total_Price_Bool_Exp_Bool_Exp>>;
  reference_id?: InputMaybe<Uuid_Comparison_Exp>;
  total_price?: InputMaybe<Numeric_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

export enum Contract_Total_Price_Enum_Name {
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  TotalPrice = 'total_price',
  /** column name */
  UserId = 'user_id'
}

/** Ordering options when selecting data from "contract_total_price". */
export type Contract_Total_Price_Order_By = {
  reference_id?: InputMaybe<Order_By>;
  total_price?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "contract" */
export enum Contract_Update_Column {
  /** column name */
  BuyingCompanyId = 'buyingCompanyId',
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  ChannelUrl = 'channel_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomSddPaymentDelay = 'custom_sdd_payment_delay',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumOrderValue = 'minimum_order_value',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  ProposerBusinessProfile = 'proposer_business_profile',
  /** column name */
  ProposerUserId = 'proposer_user_id',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierCommissionType = 'supplier_commission_type',
  /** column name */
  SupplierCustomCommissionRate = 'supplier_custom_commission_rate',
  /** column name */
  SupplyingCompanyId = 'supplyingCompanyId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Contract_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contract_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contract_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contract_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contract_Var_Pop_Fields = {
  __typename?: 'contract_var_pop_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "contract" */
export type Contract_Var_Pop_Order_By = {
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contract_Var_Samp_Fields = {
  __typename?: 'contract_var_samp_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "contract" */
export type Contract_Var_Samp_Order_By = {
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contract_Variance_Fields = {
  __typename?: 'contract_variance_fields';
  custom_sdd_payment_delay?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_contract_order_count" */
  order_count?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_contract_supplier_commission_rate" */
  supplier_commission_rate?: Maybe<Scalars['numeric']['output']>;
  supplier_custom_commission_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "contract" */
export type Contract_Variance_Order_By = {
  custom_sdd_payment_delay?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  supplier_custom_commission_rate?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "delivery_zone" */
export type Delivery_Zone = {
  __typename?: 'delivery_zone';
  /** An array relationship */
  administrative_areas: Array<Delivery_Zone_Administrative_Area>;
  /** An aggregate relationship */
  administrative_areas_aggregate: Delivery_Zone_Administrative_Area_Aggregate;
  /** An object relationship */
  company: Company;
  company_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  index: Scalars['Int']['output'];
  minimum_order_value: Scalars['numeric']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "delivery_zone" */
export type Delivery_ZoneAdministrative_AreasArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
};


/** columns and relationships of "delivery_zone" */
export type Delivery_ZoneAdministrative_Areas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
};

/** columns and relationships of "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area = {
  __typename?: 'delivery_zone_administrative_area';
  area: Scalars['String']['output'];
  country_code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  delivery_zone: Delivery_Zone;
  delivery_zone_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Aggregate = {
  __typename?: 'delivery_zone_administrative_area_aggregate';
  aggregate?: Maybe<Delivery_Zone_Administrative_Area_Aggregate_Fields>;
  nodes: Array<Delivery_Zone_Administrative_Area>;
};

export type Delivery_Zone_Administrative_Area_Aggregate_Bool_Exp = {
  count?: InputMaybe<Delivery_Zone_Administrative_Area_Aggregate_Bool_Exp_Count>;
};

export type Delivery_Zone_Administrative_Area_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Aggregate_Fields = {
  __typename?: 'delivery_zone_administrative_area_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Delivery_Zone_Administrative_Area_Max_Fields>;
  min?: Maybe<Delivery_Zone_Administrative_Area_Min_Fields>;
};


/** aggregate fields of "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Delivery_Zone_Administrative_Area_Max_Order_By>;
  min?: InputMaybe<Delivery_Zone_Administrative_Area_Min_Order_By>;
};

/** input type for inserting array relation for remote table "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Arr_Rel_Insert_Input = {
  data: Array<Delivery_Zone_Administrative_Area_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Delivery_Zone_Administrative_Area_On_Conflict>;
};

/** Boolean expression to filter rows from the table "delivery_zone_administrative_area". All fields are combined with a logical 'AND'. */
export type Delivery_Zone_Administrative_Area_Bool_Exp = {
  _and?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Bool_Exp>>;
  _not?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
  _or?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Bool_Exp>>;
  area?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery_zone?: InputMaybe<Delivery_Zone_Bool_Exp>;
  delivery_zone_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "delivery_zone_administrative_area" */
export enum Delivery_Zone_Administrative_Area_Constraint {
  /** unique or primary key constraint on columns "area", "country_code", "delivery_zone_id" */
  DeliveryZoneAdministrativeAreaPkey = 'delivery_zone_administrative_area_pkey'
}

/** input type for inserting data into table "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Insert_Input = {
  area?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_zone?: InputMaybe<Delivery_Zone_Obj_Rel_Insert_Input>;
  delivery_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Delivery_Zone_Administrative_Area_Max_Fields = {
  __typename?: 'delivery_zone_administrative_area_max_fields';
  area?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  delivery_zone_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Max_Order_By = {
  area?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_zone_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Delivery_Zone_Administrative_Area_Min_Fields = {
  __typename?: 'delivery_zone_administrative_area_min_fields';
  area?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  delivery_zone_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Min_Order_By = {
  area?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_zone_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Mutation_Response = {
  __typename?: 'delivery_zone_administrative_area_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Delivery_Zone_Administrative_Area>;
};

/** on_conflict condition type for table "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_On_Conflict = {
  constraint: Delivery_Zone_Administrative_Area_Constraint;
  update_columns?: Array<Delivery_Zone_Administrative_Area_Update_Column>;
  where?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
};

/** Ordering options when selecting data from "delivery_zone_administrative_area". */
export type Delivery_Zone_Administrative_Area_Order_By = {
  area?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_zone?: InputMaybe<Delivery_Zone_Order_By>;
  delivery_zone_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: delivery_zone_administrative_area */
export type Delivery_Zone_Administrative_Area_Pk_Columns_Input = {
  area: Scalars['String']['input'];
  country_code: Scalars['String']['input'];
  delivery_zone_id: Scalars['uuid']['input'];
};

/** select columns of table "delivery_zone_administrative_area" */
export enum Delivery_Zone_Administrative_Area_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryZoneId = 'delivery_zone_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Set_Input = {
  area?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "delivery_zone_administrative_area" */
export type Delivery_Zone_Administrative_Area_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Delivery_Zone_Administrative_Area_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Delivery_Zone_Administrative_Area_Stream_Cursor_Value_Input = {
  area?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "delivery_zone_administrative_area" */
export enum Delivery_Zone_Administrative_Area_Update_Column {
  /** column name */
  Area = 'area',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryZoneId = 'delivery_zone_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Delivery_Zone_Administrative_Area_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Delivery_Zone_Administrative_Area_Set_Input>;
  /** filter the rows which have to be updated */
  where: Delivery_Zone_Administrative_Area_Bool_Exp;
};

/** aggregated selection of "delivery_zone" */
export type Delivery_Zone_Aggregate = {
  __typename?: 'delivery_zone_aggregate';
  aggregate?: Maybe<Delivery_Zone_Aggregate_Fields>;
  nodes: Array<Delivery_Zone>;
};

export type Delivery_Zone_Aggregate_Bool_Exp = {
  count?: InputMaybe<Delivery_Zone_Aggregate_Bool_Exp_Count>;
};

export type Delivery_Zone_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Delivery_Zone_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Delivery_Zone_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "delivery_zone" */
export type Delivery_Zone_Aggregate_Fields = {
  __typename?: 'delivery_zone_aggregate_fields';
  avg?: Maybe<Delivery_Zone_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Delivery_Zone_Max_Fields>;
  min?: Maybe<Delivery_Zone_Min_Fields>;
  stddev?: Maybe<Delivery_Zone_Stddev_Fields>;
  stddev_pop?: Maybe<Delivery_Zone_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Delivery_Zone_Stddev_Samp_Fields>;
  sum?: Maybe<Delivery_Zone_Sum_Fields>;
  var_pop?: Maybe<Delivery_Zone_Var_Pop_Fields>;
  var_samp?: Maybe<Delivery_Zone_Var_Samp_Fields>;
  variance?: Maybe<Delivery_Zone_Variance_Fields>;
};


/** aggregate fields of "delivery_zone" */
export type Delivery_Zone_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Delivery_Zone_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "delivery_zone" */
export type Delivery_Zone_Aggregate_Order_By = {
  avg?: InputMaybe<Delivery_Zone_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Delivery_Zone_Max_Order_By>;
  min?: InputMaybe<Delivery_Zone_Min_Order_By>;
  stddev?: InputMaybe<Delivery_Zone_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Delivery_Zone_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Delivery_Zone_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Delivery_Zone_Sum_Order_By>;
  var_pop?: InputMaybe<Delivery_Zone_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Delivery_Zone_Var_Samp_Order_By>;
  variance?: InputMaybe<Delivery_Zone_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "delivery_zone" */
export type Delivery_Zone_Arr_Rel_Insert_Input = {
  data: Array<Delivery_Zone_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Delivery_Zone_On_Conflict>;
};

/** aggregate avg on columns */
export type Delivery_Zone_Avg_Fields = {
  __typename?: 'delivery_zone_avg_fields';
  index?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "delivery_zone" */
export type Delivery_Zone_Avg_Order_By = {
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "delivery_zone". All fields are combined with a logical 'AND'. */
export type Delivery_Zone_Bool_Exp = {
  _and?: InputMaybe<Array<Delivery_Zone_Bool_Exp>>;
  _not?: InputMaybe<Delivery_Zone_Bool_Exp>;
  _or?: InputMaybe<Array<Delivery_Zone_Bool_Exp>>;
  administrative_areas?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
  administrative_areas_aggregate?: InputMaybe<Delivery_Zone_Administrative_Area_Aggregate_Bool_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  minimum_order_value?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "delivery_zone" */
export enum Delivery_Zone_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeliveryZonePkey = 'delivery_zone_pkey'
}

/** input type for incrementing numeric columns in table "delivery_zone" */
export type Delivery_Zone_Inc_Input = {
  index?: InputMaybe<Scalars['Int']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "delivery_zone" */
export type Delivery_Zone_Insert_Input = {
  administrative_areas?: InputMaybe<Delivery_Zone_Administrative_Area_Arr_Rel_Insert_Input>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Delivery_Zone_Max_Fields = {
  __typename?: 'delivery_zone_max_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "delivery_zone" */
export type Delivery_Zone_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Delivery_Zone_Min_Fields = {
  __typename?: 'delivery_zone_min_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "delivery_zone" */
export type Delivery_Zone_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "delivery_zone" */
export type Delivery_Zone_Mutation_Response = {
  __typename?: 'delivery_zone_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Delivery_Zone>;
};

/** input type for inserting object relation for remote table "delivery_zone" */
export type Delivery_Zone_Obj_Rel_Insert_Input = {
  data: Delivery_Zone_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Delivery_Zone_On_Conflict>;
};

/** on_conflict condition type for table "delivery_zone" */
export type Delivery_Zone_On_Conflict = {
  constraint: Delivery_Zone_Constraint;
  update_columns?: Array<Delivery_Zone_Update_Column>;
  where?: InputMaybe<Delivery_Zone_Bool_Exp>;
};

/** Ordering options when selecting data from "delivery_zone". */
export type Delivery_Zone_Order_By = {
  administrative_areas_aggregate?: InputMaybe<Delivery_Zone_Administrative_Area_Aggregate_Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: delivery_zone */
export type Delivery_Zone_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "delivery_zone" */
export enum Delivery_Zone_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  MinimumOrderValue = 'minimum_order_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "delivery_zone" */
export type Delivery_Zone_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Delivery_Zone_Stddev_Fields = {
  __typename?: 'delivery_zone_stddev_fields';
  index?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "delivery_zone" */
export type Delivery_Zone_Stddev_Order_By = {
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Delivery_Zone_Stddev_Pop_Fields = {
  __typename?: 'delivery_zone_stddev_pop_fields';
  index?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "delivery_zone" */
export type Delivery_Zone_Stddev_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Delivery_Zone_Stddev_Samp_Fields = {
  __typename?: 'delivery_zone_stddev_samp_fields';
  index?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "delivery_zone" */
export type Delivery_Zone_Stddev_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "delivery_zone" */
export type Delivery_Zone_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Delivery_Zone_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Delivery_Zone_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  minimum_order_value?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Delivery_Zone_Sum_Fields = {
  __typename?: 'delivery_zone_sum_fields';
  index?: Maybe<Scalars['Int']['output']>;
  minimum_order_value?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "delivery_zone" */
export type Delivery_Zone_Sum_Order_By = {
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
};

/** update columns of table "delivery_zone" */
export enum Delivery_Zone_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  MinimumOrderValue = 'minimum_order_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Delivery_Zone_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Delivery_Zone_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Delivery_Zone_Set_Input>;
  /** filter the rows which have to be updated */
  where: Delivery_Zone_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Delivery_Zone_Var_Pop_Fields = {
  __typename?: 'delivery_zone_var_pop_fields';
  index?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "delivery_zone" */
export type Delivery_Zone_Var_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Delivery_Zone_Var_Samp_Fields = {
  __typename?: 'delivery_zone_var_samp_fields';
  index?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "delivery_zone" */
export type Delivery_Zone_Var_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Delivery_Zone_Variance_Fields = {
  __typename?: 'delivery_zone_variance_fields';
  index?: Maybe<Scalars['Float']['output']>;
  minimum_order_value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "delivery_zone" */
export type Delivery_Zone_Variance_Order_By = {
  index?: InputMaybe<Order_By>;
  minimum_order_value?: InputMaybe<Order_By>;
};

/** columns and relationships of "document" */
export type Document = {
  __typename?: 'document';
  created_date: Scalars['timestamptz']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  invoice_id?: Maybe<Scalars['uuid']['output']>;
  modified_date: Scalars['timestamptz']['output'];
  /** An object relationship */
  order?: Maybe<Order>;
  order_id: Scalars['uuid']['output'];
  path: Scalars['String']['output'];
  type: Document_Type_Enum;
};

/** aggregated selection of "document" */
export type Document_Aggregate = {
  __typename?: 'document_aggregate';
  aggregate?: Maybe<Document_Aggregate_Fields>;
  nodes: Array<Document>;
};

export type Document_Aggregate_Bool_Exp = {
  count?: InputMaybe<Document_Aggregate_Bool_Exp_Count>;
};

export type Document_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Document_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "document" */
export type Document_Aggregate_Fields = {
  __typename?: 'document_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Document_Max_Fields>;
  min?: Maybe<Document_Min_Fields>;
};


/** aggregate fields of "document" */
export type Document_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "document" */
export type Document_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_Max_Order_By>;
  min?: InputMaybe<Document_Min_Order_By>;
};

/** input type for inserting array relation for remote table "document" */
export type Document_Arr_Rel_Insert_Input = {
  data: Array<Document_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_On_Conflict>;
};

/** Boolean expression to filter rows from the table "document". All fields are combined with a logical 'AND'. */
export type Document_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Bool_Exp>>;
  _not?: InputMaybe<Document_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Bool_Exp>>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoice_id?: InputMaybe<Uuid_Comparison_Exp>;
  modified_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  order_id?: InputMaybe<Uuid_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Document_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "document" */
export enum Document_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentPkey = 'document_pkey'
}

/** input type for inserting data into table "document" */
export type Document_Insert_Input = {
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Document_Type_Enum>;
};

/** aggregate max on columns */
export type Document_Max_Fields = {
  __typename?: 'document_max_fields';
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invoice_id?: Maybe<Scalars['uuid']['output']>;
  modified_date?: Maybe<Scalars['timestamptz']['output']>;
  order_id?: Maybe<Scalars['uuid']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "document" */
export type Document_Max_Order_By = {
  created_date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Document_Min_Fields = {
  __typename?: 'document_min_fields';
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invoice_id?: Maybe<Scalars['uuid']['output']>;
  modified_date?: Maybe<Scalars['timestamptz']['output']>;
  order_id?: Maybe<Scalars['uuid']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "document" */
export type Document_Min_Order_By = {
  created_date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "document" */
export type Document_Mutation_Response = {
  __typename?: 'document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Document>;
};

/** on_conflict condition type for table "document" */
export type Document_On_Conflict = {
  constraint: Document_Constraint;
  update_columns?: Array<Document_Update_Column>;
  where?: InputMaybe<Document_Bool_Exp>;
};

/** Ordering options when selecting data from "document". */
export type Document_Order_By = {
  created_date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  order_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document */
export type Document_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "document" */
export enum Document_Select_Column {
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  ModifiedDate = 'modified_date',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Path = 'path',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "document" */
export type Document_Set_Input = {
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoice_id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order_id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Document_Type_Enum>;
};

/** Streaming cursor of the table "document" */
export type Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Stream_Cursor_Value_Input = {
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoice_id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order_id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Document_Type_Enum>;
};

/** columns and relationships of "document_type" */
export type Document_Type = {
  __typename?: 'document_type';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "document_type" */
export type Document_Type_Aggregate = {
  __typename?: 'document_type_aggregate';
  aggregate?: Maybe<Document_Type_Aggregate_Fields>;
  nodes: Array<Document_Type>;
};

/** aggregate fields of "document_type" */
export type Document_Type_Aggregate_Fields = {
  __typename?: 'document_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Document_Type_Max_Fields>;
  min?: Maybe<Document_Type_Min_Fields>;
};


/** aggregate fields of "document_type" */
export type Document_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "document_type". All fields are combined with a logical 'AND'. */
export type Document_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Type_Bool_Exp>>;
  _not?: InputMaybe<Document_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "document_type" */
export enum Document_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  DocumentTypePkey = 'document_type_pkey'
}

export enum Document_Type_Enum {
  DeliveryNote = 'DELIVERY_NOTE',
  /** type for invoice document */
  Invoice = 'INVOICE',
  OrderSummary = 'ORDER_SUMMARY',
  PurchaseOrder = 'PURCHASE_ORDER'
}

/** Boolean expression to compare columns of type "document_type_enum". All fields are combined with logical 'AND'. */
export type Document_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Document_Type_Enum>;
  _in?: InputMaybe<Array<Document_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Document_Type_Enum>;
  _nin?: InputMaybe<Array<Document_Type_Enum>>;
};

/** input type for inserting data into table "document_type" */
export type Document_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Document_Type_Max_Fields = {
  __typename?: 'document_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Document_Type_Min_Fields = {
  __typename?: 'document_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "document_type" */
export type Document_Type_Mutation_Response = {
  __typename?: 'document_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Document_Type>;
};

/** on_conflict condition type for table "document_type" */
export type Document_Type_On_Conflict = {
  constraint: Document_Type_Constraint;
  update_columns?: Array<Document_Type_Update_Column>;
  where?: InputMaybe<Document_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "document_type". */
export type Document_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document_type */
export type Document_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "document_type" */
export enum Document_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "document_type" */
export type Document_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "document_type" */
export type Document_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "document_type" */
export enum Document_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Document_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Type_Bool_Exp;
};

/** update columns of table "document" */
export enum Document_Update_Column {
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  ModifiedDate = 'modified_date',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Path = 'path',
  /** column name */
  Type = 'type'
}

export type Document_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Bool_Exp;
};

/** columns and relationships of "duration_type" */
export type Duration_Type = {
  __typename?: 'duration_type';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "duration_type" */
export type Duration_Type_Aggregate = {
  __typename?: 'duration_type_aggregate';
  aggregate?: Maybe<Duration_Type_Aggregate_Fields>;
  nodes: Array<Duration_Type>;
};

/** aggregate fields of "duration_type" */
export type Duration_Type_Aggregate_Fields = {
  __typename?: 'duration_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Duration_Type_Max_Fields>;
  min?: Maybe<Duration_Type_Min_Fields>;
};


/** aggregate fields of "duration_type" */
export type Duration_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Duration_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "duration_type". All fields are combined with a logical 'AND'. */
export type Duration_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Duration_Type_Bool_Exp>>;
  _not?: InputMaybe<Duration_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Duration_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "duration_type" */
export enum Duration_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  DurationTypePkey = 'duration_type_pkey'
}

export enum Duration_Type_Enum {
  /** days duration type */
  Days = 'DAYS',
  /** months duration type */
  Months = 'MONTHS',
  /** years duration type */
  Years = 'YEARS'
}

/** Boolean expression to compare columns of type "duration_type_enum". All fields are combined with logical 'AND'. */
export type Duration_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Duration_Type_Enum>;
  _in?: InputMaybe<Array<Duration_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Duration_Type_Enum>;
  _nin?: InputMaybe<Array<Duration_Type_Enum>>;
};

/** input type for inserting data into table "duration_type" */
export type Duration_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Duration_Type_Max_Fields = {
  __typename?: 'duration_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Duration_Type_Min_Fields = {
  __typename?: 'duration_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "duration_type" */
export type Duration_Type_Mutation_Response = {
  __typename?: 'duration_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Duration_Type>;
};

/** on_conflict condition type for table "duration_type" */
export type Duration_Type_On_Conflict = {
  constraint: Duration_Type_Constraint;
  update_columns?: Array<Duration_Type_Update_Column>;
  where?: InputMaybe<Duration_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "duration_type". */
export type Duration_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: duration_type */
export type Duration_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "duration_type" */
export enum Duration_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "duration_type" */
export type Duration_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "duration_type" */
export type Duration_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Duration_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Duration_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "duration_type" */
export enum Duration_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Duration_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Duration_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Duration_Type_Bool_Exp;
};

/** columns and relationships of "global_product_family" */
export type Global_Product_Family = {
  __typename?: 'global_product_family';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "global_product_family" */
export type Global_Product_Family_Aggregate = {
  __typename?: 'global_product_family_aggregate';
  aggregate?: Maybe<Global_Product_Family_Aggregate_Fields>;
  nodes: Array<Global_Product_Family>;
};

/** aggregate fields of "global_product_family" */
export type Global_Product_Family_Aggregate_Fields = {
  __typename?: 'global_product_family_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Global_Product_Family_Max_Fields>;
  min?: Maybe<Global_Product_Family_Min_Fields>;
};


/** aggregate fields of "global_product_family" */
export type Global_Product_Family_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Global_Product_Family_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "global_product_family". All fields are combined with a logical 'AND'. */
export type Global_Product_Family_Bool_Exp = {
  _and?: InputMaybe<Array<Global_Product_Family_Bool_Exp>>;
  _not?: InputMaybe<Global_Product_Family_Bool_Exp>;
  _or?: InputMaybe<Array<Global_Product_Family_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "global_product_family" */
export enum Global_Product_Family_Constraint {
  /** unique or primary key constraint on columns "value" */
  GlobalProductFamilyPkey = 'global_product_family_pkey'
}

export enum Global_Product_Family_Enum {
  All = 'ALL',
  None = 'NONE'
}

/** Boolean expression to compare columns of type "global_product_family_enum". All fields are combined with logical 'AND'. */
export type Global_Product_Family_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Global_Product_Family_Enum>;
  _in?: InputMaybe<Array<Global_Product_Family_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Global_Product_Family_Enum>;
  _nin?: InputMaybe<Array<Global_Product_Family_Enum>>;
};

/** input type for inserting data into table "global_product_family" */
export type Global_Product_Family_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Global_Product_Family_Max_Fields = {
  __typename?: 'global_product_family_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Global_Product_Family_Min_Fields = {
  __typename?: 'global_product_family_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "global_product_family" */
export type Global_Product_Family_Mutation_Response = {
  __typename?: 'global_product_family_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Global_Product_Family>;
};

/** on_conflict condition type for table "global_product_family" */
export type Global_Product_Family_On_Conflict = {
  constraint: Global_Product_Family_Constraint;
  update_columns?: Array<Global_Product_Family_Update_Column>;
  where?: InputMaybe<Global_Product_Family_Bool_Exp>;
};

/** Ordering options when selecting data from "global_product_family". */
export type Global_Product_Family_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: global_product_family */
export type Global_Product_Family_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "global_product_family" */
export enum Global_Product_Family_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "global_product_family" */
export type Global_Product_Family_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "global_product_family" */
export type Global_Product_Family_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Global_Product_Family_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Global_Product_Family_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "global_product_family" */
export enum Global_Product_Family_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Global_Product_Family_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Global_Product_Family_Set_Input>;
  /** filter the rows which have to be updated */
  where: Global_Product_Family_Bool_Exp;
};

/** columns and relationships of "gtin_type" */
export type Gtin_Type = {
  __typename?: 'gtin_type';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "gtin_type" */
export type Gtin_Type_Aggregate = {
  __typename?: 'gtin_type_aggregate';
  aggregate?: Maybe<Gtin_Type_Aggregate_Fields>;
  nodes: Array<Gtin_Type>;
};

/** aggregate fields of "gtin_type" */
export type Gtin_Type_Aggregate_Fields = {
  __typename?: 'gtin_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Gtin_Type_Max_Fields>;
  min?: Maybe<Gtin_Type_Min_Fields>;
};


/** aggregate fields of "gtin_type" */
export type Gtin_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gtin_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "gtin_type". All fields are combined with a logical 'AND'. */
export type Gtin_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Gtin_Type_Bool_Exp>>;
  _not?: InputMaybe<Gtin_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Gtin_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gtin_type" */
export enum Gtin_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  GtinTypePkey = 'gtin_type_pkey'
}

export enum Gtin_Type_Enum {
  Gtin_8 = 'GTIN_8',
  Gtin_12 = 'GTIN_12',
  Gtin_13 = 'GTIN_13',
  Gtin_14 = 'GTIN_14'
}

/** Boolean expression to compare columns of type "gtin_type_enum". All fields are combined with logical 'AND'. */
export type Gtin_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Gtin_Type_Enum>;
  _in?: InputMaybe<Array<Gtin_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Gtin_Type_Enum>;
  _nin?: InputMaybe<Array<Gtin_Type_Enum>>;
};

/** input type for inserting data into table "gtin_type" */
export type Gtin_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Gtin_Type_Max_Fields = {
  __typename?: 'gtin_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Gtin_Type_Min_Fields = {
  __typename?: 'gtin_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "gtin_type" */
export type Gtin_Type_Mutation_Response = {
  __typename?: 'gtin_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Gtin_Type>;
};

/** on_conflict condition type for table "gtin_type" */
export type Gtin_Type_On_Conflict = {
  constraint: Gtin_Type_Constraint;
  update_columns?: Array<Gtin_Type_Update_Column>;
  where?: InputMaybe<Gtin_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "gtin_type". */
export type Gtin_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gtin_type */
export type Gtin_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "gtin_type" */
export enum Gtin_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "gtin_type" */
export type Gtin_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "gtin_type" */
export type Gtin_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gtin_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gtin_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "gtin_type" */
export enum Gtin_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Gtin_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gtin_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Gtin_Type_Bool_Exp;
};

/** columns and relationships of "invoice" */
export type Invoice = {
  __typename?: 'invoice';
  auto_generated: Scalars['Boolean']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  company_id: Scalars['uuid']['output'];
  created_date: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  modified_date: Scalars['timestamptz']['output'];
  /** An object relationship */
  order: Order_History;
  order_id: Scalars['uuid']['output'];
  price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  revised_reference?: Maybe<Scalars['String']['output']>;
  type: Invoice_Type_Enum;
};

/** aggregated selection of "invoice" */
export type Invoice_Aggregate = {
  __typename?: 'invoice_aggregate';
  aggregate?: Maybe<Invoice_Aggregate_Fields>;
  nodes: Array<Invoice>;
};

/** aggregate fields of "invoice" */
export type Invoice_Aggregate_Fields = {
  __typename?: 'invoice_aggregate_fields';
  avg?: Maybe<Invoice_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Invoice_Max_Fields>;
  min?: Maybe<Invoice_Min_Fields>;
  stddev?: Maybe<Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Sum_Fields>;
  var_pop?: Maybe<Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Variance_Fields>;
};


/** aggregate fields of "invoice" */
export type Invoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Invoice_Avg_Fields = {
  __typename?: 'invoice_avg_fields';
  price_excl_tax?: Maybe<Scalars['Float']['output']>;
  price_incl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "invoice". All fields are combined with a logical 'AND'. */
export type Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Bool_Exp>>;
  auto_generated?: InputMaybe<Boolean_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  modified_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  order?: InputMaybe<Order_History_Bool_Exp>;
  order_id?: InputMaybe<Uuid_Comparison_Exp>;
  price_excl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  price_incl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  reference?: InputMaybe<String_Comparison_Exp>;
  revised_price_excl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  revised_price_incl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  revised_reference?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Invoice_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice" */
export enum Invoice_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoicePkey = 'invoice_pkey',
  /** unique or primary key constraint on columns "reference", "company_id" */
  UqAutoGeneratedInvoiceReferenceCompanyId = 'uq_auto_generated_invoice_reference_company_id'
}

/** input type for incrementing numeric columns in table "invoice" */
export type Invoice_Inc_Input = {
  price_excl_tax?: InputMaybe<Scalars['numeric']['input']>;
  price_incl_tax?: InputMaybe<Scalars['numeric']['input']>;
  revised_price_excl_tax?: InputMaybe<Scalars['numeric']['input']>;
  revised_price_incl_tax?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "invoice" */
export type Invoice_Insert_Input = {
  auto_generated?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order?: InputMaybe<Order_History_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['uuid']['input']>;
  price_excl_tax?: InputMaybe<Scalars['numeric']['input']>;
  price_incl_tax?: InputMaybe<Scalars['numeric']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  revised_price_excl_tax?: InputMaybe<Scalars['numeric']['input']>;
  revised_price_incl_tax?: InputMaybe<Scalars['numeric']['input']>;
  revised_reference?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Invoice_Type_Enum>;
};

/** aggregate max on columns */
export type Invoice_Max_Fields = {
  __typename?: 'invoice_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modified_date?: Maybe<Scalars['timestamptz']['output']>;
  order_id?: Maybe<Scalars['uuid']['output']>;
  price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  revised_reference?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Invoice_Min_Fields = {
  __typename?: 'invoice_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modified_date?: Maybe<Scalars['timestamptz']['output']>;
  order_id?: Maybe<Scalars['uuid']['output']>;
  price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  revised_reference?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "invoice" */
export type Invoice_Mutation_Response = {
  __typename?: 'invoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice>;
};

/** input type for inserting object relation for remote table "invoice" */
export type Invoice_Obj_Rel_Insert_Input = {
  data: Invoice_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** on_conflict condition type for table "invoice" */
export type Invoice_On_Conflict = {
  constraint: Invoice_Constraint;
  update_columns?: Array<Invoice_Update_Column>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice". */
export type Invoice_Order_By = {
  auto_generated?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_History_Order_By>;
  order_id?: InputMaybe<Order_By>;
  price_excl_tax?: InputMaybe<Order_By>;
  price_incl_tax?: InputMaybe<Order_By>;
  reference?: InputMaybe<Order_By>;
  revised_price_excl_tax?: InputMaybe<Order_By>;
  revised_price_incl_tax?: InputMaybe<Order_By>;
  revised_reference?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice */
export type Invoice_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "invoice" */
export enum Invoice_Select_Column {
  /** column name */
  AutoGenerated = 'auto_generated',
  /** column name */
  Comment = 'comment',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedDate = 'modified_date',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PriceExclTax = 'price_excl_tax',
  /** column name */
  PriceInclTax = 'price_incl_tax',
  /** column name */
  Reference = 'reference',
  /** column name */
  RevisedPriceExclTax = 'revised_price_excl_tax',
  /** column name */
  RevisedPriceInclTax = 'revised_price_incl_tax',
  /** column name */
  RevisedReference = 'revised_reference',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "invoice" */
export type Invoice_Set_Input = {
  auto_generated?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order_id?: InputMaybe<Scalars['uuid']['input']>;
  price_excl_tax?: InputMaybe<Scalars['numeric']['input']>;
  price_incl_tax?: InputMaybe<Scalars['numeric']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  revised_price_excl_tax?: InputMaybe<Scalars['numeric']['input']>;
  revised_price_incl_tax?: InputMaybe<Scalars['numeric']['input']>;
  revised_reference?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Invoice_Type_Enum>;
};

/** aggregate stddev on columns */
export type Invoice_Stddev_Fields = {
  __typename?: 'invoice_stddev_fields';
  price_excl_tax?: Maybe<Scalars['Float']['output']>;
  price_incl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Stddev_Pop_Fields = {
  __typename?: 'invoice_stddev_pop_fields';
  price_excl_tax?: Maybe<Scalars['Float']['output']>;
  price_incl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Stddev_Samp_Fields = {
  __typename?: 'invoice_stddev_samp_fields';
  price_excl_tax?: Maybe<Scalars['Float']['output']>;
  price_incl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "invoice" */
export type Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Stream_Cursor_Value_Input = {
  auto_generated?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modified_date?: InputMaybe<Scalars['timestamptz']['input']>;
  order_id?: InputMaybe<Scalars['uuid']['input']>;
  price_excl_tax?: InputMaybe<Scalars['numeric']['input']>;
  price_incl_tax?: InputMaybe<Scalars['numeric']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  revised_price_excl_tax?: InputMaybe<Scalars['numeric']['input']>;
  revised_price_incl_tax?: InputMaybe<Scalars['numeric']['input']>;
  revised_reference?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Invoice_Type_Enum>;
};

/** aggregate sum on columns */
export type Invoice_Sum_Fields = {
  __typename?: 'invoice_sum_fields';
  price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
};

/** columns and relationships of "invoice_type" */
export type Invoice_Type = {
  __typename?: 'invoice_type';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "invoice_type" */
export type Invoice_Type_Aggregate = {
  __typename?: 'invoice_type_aggregate';
  aggregate?: Maybe<Invoice_Type_Aggregate_Fields>;
  nodes: Array<Invoice_Type>;
};

/** aggregate fields of "invoice_type" */
export type Invoice_Type_Aggregate_Fields = {
  __typename?: 'invoice_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Invoice_Type_Max_Fields>;
  min?: Maybe<Invoice_Type_Min_Fields>;
};


/** aggregate fields of "invoice_type" */
export type Invoice_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "invoice_type". All fields are combined with a logical 'AND'. */
export type Invoice_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Type_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_type" */
export enum Invoice_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  InvoiceTypePkey = 'invoice_type_pkey'
}

export enum Invoice_Type_Enum {
  /** commission invoice */
  Commission = 'COMMISSION',
  /** order invoice */
  Order = 'ORDER'
}

/** Boolean expression to compare columns of type "invoice_type_enum". All fields are combined with logical 'AND'. */
export type Invoice_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Invoice_Type_Enum>;
  _in?: InputMaybe<Array<Invoice_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Invoice_Type_Enum>;
  _nin?: InputMaybe<Array<Invoice_Type_Enum>>;
};

/** input type for inserting data into table "invoice_type" */
export type Invoice_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Invoice_Type_Max_Fields = {
  __typename?: 'invoice_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Invoice_Type_Min_Fields = {
  __typename?: 'invoice_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "invoice_type" */
export type Invoice_Type_Mutation_Response = {
  __typename?: 'invoice_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Type>;
};

/** on_conflict condition type for table "invoice_type" */
export type Invoice_Type_On_Conflict = {
  constraint: Invoice_Type_Constraint;
  update_columns?: Array<Invoice_Type_Update_Column>;
  where?: InputMaybe<Invoice_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_type". */
export type Invoice_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice_type */
export type Invoice_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "invoice_type" */
export enum Invoice_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "invoice_type" */
export type Invoice_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "invoice_type" */
export type Invoice_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "invoice_type" */
export enum Invoice_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Invoice_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Type_Bool_Exp;
};

/** update columns of table "invoice" */
export enum Invoice_Update_Column {
  /** column name */
  AutoGenerated = 'auto_generated',
  /** column name */
  Comment = 'comment',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedDate = 'modified_date',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PriceExclTax = 'price_excl_tax',
  /** column name */
  PriceInclTax = 'price_incl_tax',
  /** column name */
  Reference = 'reference',
  /** column name */
  RevisedPriceExclTax = 'revised_price_excl_tax',
  /** column name */
  RevisedPriceInclTax = 'revised_price_incl_tax',
  /** column name */
  RevisedReference = 'revised_reference',
  /** column name */
  Type = 'type'
}

export type Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Var_Pop_Fields = {
  __typename?: 'invoice_var_pop_fields';
  price_excl_tax?: Maybe<Scalars['Float']['output']>;
  price_incl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Invoice_Var_Samp_Fields = {
  __typename?: 'invoice_var_samp_fields';
  price_excl_tax?: Maybe<Scalars['Float']['output']>;
  price_incl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Invoice_Variance_Fields = {
  __typename?: 'invoice_variance_fields';
  price_excl_tax?: Maybe<Scalars['Float']['output']>;
  price_incl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_excl_tax?: Maybe<Scalars['Float']['output']>;
  revised_price_incl_tax?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "measurement_unit" */
export type Measurement_Unit = {
  __typename?: 'measurement_unit';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "measurement_unit" */
export type Measurement_Unit_Aggregate = {
  __typename?: 'measurement_unit_aggregate';
  aggregate?: Maybe<Measurement_Unit_Aggregate_Fields>;
  nodes: Array<Measurement_Unit>;
};

/** aggregate fields of "measurement_unit" */
export type Measurement_Unit_Aggregate_Fields = {
  __typename?: 'measurement_unit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Measurement_Unit_Max_Fields>;
  min?: Maybe<Measurement_Unit_Min_Fields>;
};


/** aggregate fields of "measurement_unit" */
export type Measurement_Unit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Measurement_Unit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "measurement_unit". All fields are combined with a logical 'AND'. */
export type Measurement_Unit_Bool_Exp = {
  _and?: InputMaybe<Array<Measurement_Unit_Bool_Exp>>;
  _not?: InputMaybe<Measurement_Unit_Bool_Exp>;
  _or?: InputMaybe<Array<Measurement_Unit_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "measurement_unit" */
export enum Measurement_Unit_Constraint {
  /** unique or primary key constraint on columns "value" */
  MeasurementUnitPkey = 'measurement_unit_pkey'
}

export enum Measurement_Unit_Enum {
  Cl = 'CL',
  G = 'G',
  Kg = 'KG',
  L = 'L',
  Ml = 'ML',
  T = 'T',
  /** Used to count units in logistical packages */
  Unit = 'UNIT'
}

/** Boolean expression to compare columns of type "measurement_unit_enum". All fields are combined with logical 'AND'. */
export type Measurement_Unit_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Measurement_Unit_Enum>;
  _in?: InputMaybe<Array<Measurement_Unit_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Measurement_Unit_Enum>;
  _nin?: InputMaybe<Array<Measurement_Unit_Enum>>;
};

/** input type for inserting data into table "measurement_unit" */
export type Measurement_Unit_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Measurement_Unit_Max_Fields = {
  __typename?: 'measurement_unit_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Measurement_Unit_Min_Fields = {
  __typename?: 'measurement_unit_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "measurement_unit" */
export type Measurement_Unit_Mutation_Response = {
  __typename?: 'measurement_unit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Measurement_Unit>;
};

/** on_conflict condition type for table "measurement_unit" */
export type Measurement_Unit_On_Conflict = {
  constraint: Measurement_Unit_Constraint;
  update_columns?: Array<Measurement_Unit_Update_Column>;
  where?: InputMaybe<Measurement_Unit_Bool_Exp>;
};

/** Ordering options when selecting data from "measurement_unit". */
export type Measurement_Unit_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: measurement_unit */
export type Measurement_Unit_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "measurement_unit" */
export enum Measurement_Unit_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "measurement_unit" */
export type Measurement_Unit_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "measurement_unit" */
export type Measurement_Unit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Measurement_Unit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Measurement_Unit_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "measurement_unit" */
export enum Measurement_Unit_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Measurement_Unit_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Measurement_Unit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Measurement_Unit_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  acceptContractOffer: AcceptContractOfferOutput;
  createContractRequest: CreateContractRequestOutput;
  createGroupChannel: CreateGroupChannelOutput;
  /** Delete a document from an order using the document id */
  deleteDocumentByIdFromOrder?: Maybe<DeleteDocumentByIdFromOrderOutput>;
  /** delete data from the table: "act_hi_procinst" */
  delete_act_hi_procinst?: Maybe<Act_Hi_Procinst_Mutation_Response>;
  /** delete single row from the table: "act_hi_procinst" */
  delete_act_hi_procinst_by_pk?: Maybe<Act_Hi_Procinst>;
  /** delete data from the table: "act_hi_taskinst" */
  delete_act_hi_taskinst?: Maybe<Act_Hi_Taskinst_Mutation_Response>;
  /** delete single row from the table: "act_hi_taskinst" */
  delete_act_hi_taskinst_by_pk?: Maybe<Act_Hi_Taskinst>;
  /** delete data from the table: "act_ru_event_subscr" */
  delete_act_ru_event_subscr?: Maybe<Act_Ru_Event_Subscr_Mutation_Response>;
  /** delete single row from the table: "act_ru_event_subscr" */
  delete_act_ru_event_subscr_by_pk?: Maybe<Act_Ru_Event_Subscr>;
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "administrative_area" */
  delete_administrative_area?: Maybe<Administrative_Area_Mutation_Response>;
  /** delete single row from the table: "administrative_area" */
  delete_administrative_area_by_pk?: Maybe<Administrative_Area>;
  /** delete data from the table: "authentication_code" */
  delete_authentication_code?: Maybe<Authentication_Code_Mutation_Response>;
  /** delete single row from the table: "authentication_code" */
  delete_authentication_code_by_pk?: Maybe<Authentication_Code>;
  /** delete data from the table: "authentication_verification_type" */
  delete_authentication_verification_type?: Maybe<Authentication_Verification_Type_Mutation_Response>;
  /** delete single row from the table: "authentication_verification_type" */
  delete_authentication_verification_type_by_pk?: Maybe<Authentication_Verification_Type>;
  /** delete data from the table: "availability_status" */
  delete_availability_status?: Maybe<Availability_Status_Mutation_Response>;
  /** delete single row from the table: "availability_status" */
  delete_availability_status_by_pk?: Maybe<Availability_Status>;
  /** delete data from the table: "base_unit" */
  delete_base_unit?: Maybe<Base_Unit_Mutation_Response>;
  /** delete single row from the table: "base_unit" */
  delete_base_unit_by_pk?: Maybe<Base_Unit>;
  /** delete data from the table: "base_unit_history" */
  delete_base_unit_history?: Maybe<Base_Unit_History_Mutation_Response>;
  /** delete single row from the table: "base_unit_history" */
  delete_base_unit_history_by_pk?: Maybe<Base_Unit_History>;
  /** delete data from the table: "base_unit_photo" */
  delete_base_unit_photo?: Maybe<Base_Unit_Photo_Mutation_Response>;
  /** delete single row from the table: "base_unit_photo" */
  delete_base_unit_photo_by_pk?: Maybe<Base_Unit_Photo>;
  /** delete data from the table: "base_unit_photo_history" */
  delete_base_unit_photo_history?: Maybe<Base_Unit_Photo_History_Mutation_Response>;
  /** delete single row from the table: "base_unit_photo_history" */
  delete_base_unit_photo_history_by_pk?: Maybe<Base_Unit_Photo_History>;
  /** delete data from the table: "base_unit_type" */
  delete_base_unit_type?: Maybe<Base_Unit_Type_Mutation_Response>;
  /** delete single row from the table: "base_unit_type" */
  delete_base_unit_type_by_pk?: Maybe<Base_Unit_Type>;
  /** delete data from the table: "basket" */
  delete_basket?: Maybe<Basket_Mutation_Response>;
  /** delete single row from the table: "basket" */
  delete_basket_by_pk?: Maybe<Basket>;
  /** delete data from the table: "basket_packaging_quantity" */
  delete_basket_packaging_quantity?: Maybe<Basket_Packaging_Quantity_Mutation_Response>;
  /** delete single row from the table: "basket_packaging_quantity" */
  delete_basket_packaging_quantity_by_pk?: Maybe<Basket_Packaging_Quantity>;
  /** delete data from the table: "basket_status" */
  delete_basket_status?: Maybe<Basket_Status_Mutation_Response>;
  /** delete single row from the table: "basket_status" */
  delete_basket_status_by_pk?: Maybe<Basket_Status>;
  /** delete data from the table: "billing_type" */
  delete_billing_type?: Maybe<Billing_Type_Mutation_Response>;
  /** delete single row from the table: "billing_type" */
  delete_billing_type_by_pk?: Maybe<Billing_Type>;
  /** delete data from the table: "brand" */
  delete_brand?: Maybe<Brand_Mutation_Response>;
  /** delete single row from the table: "brand" */
  delete_brand_by_pk?: Maybe<Brand>;
  /** delete data from the table: "business_profile" */
  delete_business_profile?: Maybe<Business_Profile_Mutation_Response>;
  /** delete single row from the table: "business_profile" */
  delete_business_profile_by_pk?: Maybe<Business_Profile>;
  /** delete data from the table: "catalog" */
  delete_catalog?: Maybe<Catalog_Mutation_Response>;
  /** delete single row from the table: "catalog" */
  delete_catalog_by_pk?: Maybe<Catalog>;
  /** delete data from the table: "catalog_packaging" */
  delete_catalog_packaging?: Maybe<Catalog_Packaging_Mutation_Response>;
  /** delete single row from the table: "catalog_packaging" */
  delete_catalog_packaging_by_pk?: Maybe<Catalog_Packaging>;
  /** delete data from the table: "catalog_packaging_history" */
  delete_catalog_packaging_history?: Maybe<Catalog_Packaging_History_Mutation_Response>;
  /** delete single row from the table: "catalog_packaging_history" */
  delete_catalog_packaging_history_by_pk?: Maybe<Catalog_Packaging_History>;
  /** delete data from the table: "comment" */
  delete_comment?: Maybe<Comment_Mutation_Response>;
  /** delete single row from the table: "comment" */
  delete_comment_by_pk?: Maybe<Comment>;
  /** delete data from the table: "comment_history" */
  delete_comment_history?: Maybe<Comment_History_Mutation_Response>;
  /** delete single row from the table: "comment_history" */
  delete_comment_history_by_pk?: Maybe<Comment_History>;
  /** delete data from the table: "comment_type" */
  delete_comment_type?: Maybe<Comment_Type_Mutation_Response>;
  /** delete single row from the table: "comment_type" */
  delete_comment_type_by_pk?: Maybe<Comment_Type>;
  /** delete data from the table: "commission" */
  delete_commission?: Maybe<Commission_Mutation_Response>;
  /** delete single row from the table: "commission" */
  delete_commission_by_pk?: Maybe<Commission>;
  /** delete data from the table: "commission_type" */
  delete_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** delete single row from the table: "commission_type" */
  delete_commission_type_by_pk?: Maybe<Commission_Type>;
  /** delete data from the table: "company" */
  delete_company?: Maybe<Company_Mutation_Response>;
  /** delete single row from the table: "company" */
  delete_company_by_pk?: Maybe<Company>;
  /** delete data from the table: "company_photo" */
  delete_company_photo?: Maybe<Company_Photo_Mutation_Response>;
  /** delete single row from the table: "company_photo" */
  delete_company_photo_by_pk?: Maybe<Company_Photo>;
  /** delete data from the table: "contract" */
  delete_contract?: Maybe<Contract_Mutation_Response>;
  /** delete single row from the table: "contract" */
  delete_contract_by_pk?: Maybe<Contract>;
  /** delete data from the table: "contract_history" */
  delete_contract_history?: Maybe<Contract_History_Mutation_Response>;
  /** delete single row from the table: "contract_history" */
  delete_contract_history_by_pk?: Maybe<Contract_History>;
  /** delete data from the table: "contract_status" */
  delete_contract_status?: Maybe<Contract_Status_Mutation_Response>;
  /** delete single row from the table: "contract_status" */
  delete_contract_status_by_pk?: Maybe<Contract_Status>;
  /** delete data from the table: "delivery_zone" */
  delete_delivery_zone?: Maybe<Delivery_Zone_Mutation_Response>;
  /** delete data from the table: "delivery_zone_administrative_area" */
  delete_delivery_zone_administrative_area?: Maybe<Delivery_Zone_Administrative_Area_Mutation_Response>;
  /** delete single row from the table: "delivery_zone_administrative_area" */
  delete_delivery_zone_administrative_area_by_pk?: Maybe<Delivery_Zone_Administrative_Area>;
  /** delete single row from the table: "delivery_zone" */
  delete_delivery_zone_by_pk?: Maybe<Delivery_Zone>;
  /** delete data from the table: "document" */
  delete_document?: Maybe<Document_Mutation_Response>;
  /** delete single row from the table: "document" */
  delete_document_by_pk?: Maybe<Document>;
  /** delete data from the table: "document_type" */
  delete_document_type?: Maybe<Document_Type_Mutation_Response>;
  /** delete single row from the table: "document_type" */
  delete_document_type_by_pk?: Maybe<Document_Type>;
  /** delete data from the table: "duration_type" */
  delete_duration_type?: Maybe<Duration_Type_Mutation_Response>;
  /** delete single row from the table: "duration_type" */
  delete_duration_type_by_pk?: Maybe<Duration_Type>;
  /** delete data from the table: "global_product_family" */
  delete_global_product_family?: Maybe<Global_Product_Family_Mutation_Response>;
  /** delete single row from the table: "global_product_family" */
  delete_global_product_family_by_pk?: Maybe<Global_Product_Family>;
  /** delete data from the table: "gtin_type" */
  delete_gtin_type?: Maybe<Gtin_Type_Mutation_Response>;
  /** delete single row from the table: "gtin_type" */
  delete_gtin_type_by_pk?: Maybe<Gtin_Type>;
  /** delete data from the table: "invoice" */
  delete_invoice?: Maybe<Invoice_Mutation_Response>;
  /** delete single row from the table: "invoice" */
  delete_invoice_by_pk?: Maybe<Invoice>;
  /** delete data from the table: "invoice_type" */
  delete_invoice_type?: Maybe<Invoice_Type_Mutation_Response>;
  /** delete single row from the table: "invoice_type" */
  delete_invoice_type_by_pk?: Maybe<Invoice_Type>;
  /** delete data from the table: "measurement_unit" */
  delete_measurement_unit?: Maybe<Measurement_Unit_Mutation_Response>;
  /** delete single row from the table: "measurement_unit" */
  delete_measurement_unit_by_pk?: Maybe<Measurement_Unit>;
  /** delete data from the table: "non_reconciliated_moneyin" */
  delete_non_reconciliated_moneyin?: Maybe<Non_Reconciliated_Moneyin_Mutation_Response>;
  /** delete single row from the table: "non_reconciliated_moneyin" */
  delete_non_reconciliated_moneyin_by_pk?: Maybe<Non_Reconciliated_Moneyin>;
  /** delete data from the table: "notification_business_profile" */
  delete_notification_business_profile?: Maybe<Notification_Business_Profile_Mutation_Response>;
  /** delete single row from the table: "notification_business_profile" */
  delete_notification_business_profile_by_pk?: Maybe<Notification_Business_Profile>;
  /** delete data from the table: "notification_code" */
  delete_notification_code?: Maybe<Notification_Code_Mutation_Response>;
  /** delete single row from the table: "notification_code" */
  delete_notification_code_by_pk?: Maybe<Notification_Code>;
  /** delete data from the table: "notification_default" */
  delete_notification_default?: Maybe<Notification_Default_Mutation_Response>;
  /** delete single row from the table: "notification_default" */
  delete_notification_default_by_pk?: Maybe<Notification_Default>;
  /** delete data from the table: "notification_method" */
  delete_notification_method?: Maybe<Notification_Method_Mutation_Response>;
  /** delete single row from the table: "notification_method" */
  delete_notification_method_by_pk?: Maybe<Notification_Method>;
  /** delete data from the table: "notification_recipient" */
  delete_notification_recipient?: Maybe<Notification_Recipient_Mutation_Response>;
  /** delete single row from the table: "notification_recipient" */
  delete_notification_recipient_by_pk?: Maybe<Notification_Recipient>;
  /** delete data from the table: "notification_scope" */
  delete_notification_scope?: Maybe<Notification_Scope_Mutation_Response>;
  /** delete single row from the table: "notification_scope" */
  delete_notification_scope_by_pk?: Maybe<Notification_Scope>;
  /** delete data from the table: "notification_subscription" */
  delete_notification_subscription?: Maybe<Notification_Subscription_Mutation_Response>;
  /** delete single row from the table: "notification_subscription" */
  delete_notification_subscription_by_pk?: Maybe<Notification_Subscription>;
  /** delete data from the table: "order" */
  delete_order?: Maybe<Order_Mutation_Response>;
  /** delete single row from the table: "order" */
  delete_order_by_pk?: Maybe<Order>;
  /** delete data from the table: "order_history" */
  delete_order_history?: Maybe<Order_History_Mutation_Response>;
  /** delete single row from the table: "order_history" */
  delete_order_history_by_pk?: Maybe<Order_History>;
  /** delete data from the table: "order_packaging_quantity" */
  delete_order_packaging_quantity?: Maybe<Order_Packaging_Quantity_Mutation_Response>;
  /** delete single row from the table: "order_packaging_quantity" */
  delete_order_packaging_quantity_by_pk?: Maybe<Order_Packaging_Quantity>;
  /** delete data from the table: "order_status" */
  delete_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** delete single row from the table: "order_status" */
  delete_order_status_by_pk?: Maybe<Order_Status>;
  /** delete data from the table: "packaging" */
  delete_packaging?: Maybe<Packaging_Mutation_Response>;
  /** delete single row from the table: "packaging" */
  delete_packaging_by_pk?: Maybe<Packaging>;
  /** delete data from the table: "packaging_history" */
  delete_packaging_history?: Maybe<Packaging_History_Mutation_Response>;
  /** delete single row from the table: "packaging_history" */
  delete_packaging_history_by_pk?: Maybe<Packaging_History>;
  /** delete data from the table: "payment_method" */
  delete_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** delete single row from the table: "payment_method" */
  delete_payment_method_by_pk?: Maybe<Payment_Method>;
  /** delete data from the table: "payment_process_status" */
  delete_payment_process_status?: Maybe<Payment_Process_Status_Mutation_Response>;
  /** delete single row from the table: "payment_process_status" */
  delete_payment_process_status_by_pk?: Maybe<Payment_Process_Status>;
  /** delete data from the table: "photo" */
  delete_photo?: Maybe<Photo_Mutation_Response>;
  /** delete single row from the table: "photo" */
  delete_photo_by_pk?: Maybe<Photo>;
  /** delete data from the table: "privacy_setting" */
  delete_privacy_setting?: Maybe<Privacy_Setting_Mutation_Response>;
  /** delete single row from the table: "privacy_setting" */
  delete_privacy_setting_by_pk?: Maybe<Privacy_Setting>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "product_family" */
  delete_product_family?: Maybe<Product_Family_Mutation_Response>;
  /** delete single row from the table: "product_family" */
  delete_product_family_by_pk?: Maybe<Product_Family>;
  /** delete data from the table: "product_family_name" */
  delete_product_family_name?: Maybe<Product_Family_Name_Mutation_Response>;
  /** delete single row from the table: "product_family_name" */
  delete_product_family_name_by_pk?: Maybe<Product_Family_Name>;
  /** delete data from the table: "product_history" */
  delete_product_history?: Maybe<Product_History_Mutation_Response>;
  /** delete single row from the table: "product_history" */
  delete_product_history_by_pk?: Maybe<Product_History>;
  /** delete data from the table: "product_label_name" */
  delete_product_label_name?: Maybe<Product_Label_Name_Mutation_Response>;
  /** delete single row from the table: "product_label_name" */
  delete_product_label_name_by_pk?: Maybe<Product_Label_Name>;
  /** delete data from the table: "product_product_labels_product_label" */
  delete_product_product_labels_product_label?: Maybe<Product_Product_Labels_Product_Label_Mutation_Response>;
  /** delete single row from the table: "product_product_labels_product_label" */
  delete_product_product_labels_product_label_by_pk?: Maybe<Product_Product_Labels_Product_Label>;
  /** delete data from the table: "product_sub_family" */
  delete_product_sub_family?: Maybe<Product_Sub_Family_Mutation_Response>;
  /** delete single row from the table: "product_sub_family" */
  delete_product_sub_family_by_pk?: Maybe<Product_Sub_Family>;
  /** delete data from the table: "product_sub_family_name" */
  delete_product_sub_family_name?: Maybe<Product_Sub_Family_Name_Mutation_Response>;
  /** delete single row from the table: "product_sub_family_name" */
  delete_product_sub_family_name_by_pk?: Maybe<Product_Sub_Family_Name>;
  /** delete data from the table: "recommended_supplier" */
  delete_recommended_supplier?: Maybe<Recommended_Supplier_Mutation_Response>;
  /** delete single row from the table: "recommended_supplier" */
  delete_recommended_supplier_by_pk?: Maybe<Recommended_Supplier>;
  /** delete data from the table: "refresh_token" */
  delete_refresh_token?: Maybe<Refresh_Token_Mutation_Response>;
  /** delete single row from the table: "refresh_token" */
  delete_refresh_token_by_pk?: Maybe<Refresh_Token>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>;
  /** delete data from the table: "role_assignment" */
  delete_role_assignment?: Maybe<Role_Assignment_Mutation_Response>;
  /** delete single row from the table: "role_assignment" */
  delete_role_assignment_by_pk?: Maybe<Role_Assignment>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "staff_role" */
  delete_staff_role?: Maybe<Staff_Role_Mutation_Response>;
  /** delete single row from the table: "staff_role" */
  delete_staff_role_by_pk?: Maybe<Staff_Role>;
  /** delete data from the table: "staff_user" */
  delete_staff_user?: Maybe<Staff_User_Mutation_Response>;
  /** delete single row from the table: "staff_user" */
  delete_staff_user_by_pk?: Maybe<Staff_User>;
  /** delete data from the table: "trade_item_unit_descriptor" */
  delete_trade_item_unit_descriptor?: Maybe<Trade_Item_Unit_Descriptor_Mutation_Response>;
  /** delete single row from the table: "trade_item_unit_descriptor" */
  delete_trade_item_unit_descriptor_by_pk?: Maybe<Trade_Item_Unit_Descriptor>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_product_family" */
  delete_user_product_family?: Maybe<User_Product_Family_Mutation_Response>;
  /** delete single row from the table: "user_product_family" */
  delete_user_product_family_by_pk?: Maybe<User_Product_Family>;
  insertOrdersAction?: Maybe<Array<InsertOrdersActionOutput>>;
  /** insert data into the table: "act_hi_procinst" */
  insert_act_hi_procinst?: Maybe<Act_Hi_Procinst_Mutation_Response>;
  /** insert a single row into the table: "act_hi_procinst" */
  insert_act_hi_procinst_one?: Maybe<Act_Hi_Procinst>;
  /** insert data into the table: "act_hi_taskinst" */
  insert_act_hi_taskinst?: Maybe<Act_Hi_Taskinst_Mutation_Response>;
  /** insert a single row into the table: "act_hi_taskinst" */
  insert_act_hi_taskinst_one?: Maybe<Act_Hi_Taskinst>;
  /** insert data into the table: "act_ru_event_subscr" */
  insert_act_ru_event_subscr?: Maybe<Act_Ru_Event_Subscr_Mutation_Response>;
  /** insert a single row into the table: "act_ru_event_subscr" */
  insert_act_ru_event_subscr_one?: Maybe<Act_Ru_Event_Subscr>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "administrative_area" */
  insert_administrative_area?: Maybe<Administrative_Area_Mutation_Response>;
  /** insert a single row into the table: "administrative_area" */
  insert_administrative_area_one?: Maybe<Administrative_Area>;
  /** insert data into the table: "authentication_code" */
  insert_authentication_code?: Maybe<Authentication_Code_Mutation_Response>;
  /** insert a single row into the table: "authentication_code" */
  insert_authentication_code_one?: Maybe<Authentication_Code>;
  /** insert data into the table: "authentication_verification_type" */
  insert_authentication_verification_type?: Maybe<Authentication_Verification_Type_Mutation_Response>;
  /** insert a single row into the table: "authentication_verification_type" */
  insert_authentication_verification_type_one?: Maybe<Authentication_Verification_Type>;
  /** insert data into the table: "availability_status" */
  insert_availability_status?: Maybe<Availability_Status_Mutation_Response>;
  /** insert a single row into the table: "availability_status" */
  insert_availability_status_one?: Maybe<Availability_Status>;
  /** insert data into the table: "base_unit" */
  insert_base_unit?: Maybe<Base_Unit_Mutation_Response>;
  /** insert data into the table: "base_unit_history" */
  insert_base_unit_history?: Maybe<Base_Unit_History_Mutation_Response>;
  /** insert a single row into the table: "base_unit_history" */
  insert_base_unit_history_one?: Maybe<Base_Unit_History>;
  /** insert a single row into the table: "base_unit" */
  insert_base_unit_one?: Maybe<Base_Unit>;
  /** insert data into the table: "base_unit_photo" */
  insert_base_unit_photo?: Maybe<Base_Unit_Photo_Mutation_Response>;
  /** insert data into the table: "base_unit_photo_history" */
  insert_base_unit_photo_history?: Maybe<Base_Unit_Photo_History_Mutation_Response>;
  /** insert a single row into the table: "base_unit_photo_history" */
  insert_base_unit_photo_history_one?: Maybe<Base_Unit_Photo_History>;
  /** insert a single row into the table: "base_unit_photo" */
  insert_base_unit_photo_one?: Maybe<Base_Unit_Photo>;
  /** insert data into the table: "base_unit_type" */
  insert_base_unit_type?: Maybe<Base_Unit_Type_Mutation_Response>;
  /** insert a single row into the table: "base_unit_type" */
  insert_base_unit_type_one?: Maybe<Base_Unit_Type>;
  /** insert data into the table: "basket" */
  insert_basket?: Maybe<Basket_Mutation_Response>;
  /** insert a single row into the table: "basket" */
  insert_basket_one?: Maybe<Basket>;
  /** insert data into the table: "basket_packaging_quantity" */
  insert_basket_packaging_quantity?: Maybe<Basket_Packaging_Quantity_Mutation_Response>;
  /** insert a single row into the table: "basket_packaging_quantity" */
  insert_basket_packaging_quantity_one?: Maybe<Basket_Packaging_Quantity>;
  /** insert data into the table: "basket_status" */
  insert_basket_status?: Maybe<Basket_Status_Mutation_Response>;
  /** insert a single row into the table: "basket_status" */
  insert_basket_status_one?: Maybe<Basket_Status>;
  /** insert data into the table: "billing_type" */
  insert_billing_type?: Maybe<Billing_Type_Mutation_Response>;
  /** insert a single row into the table: "billing_type" */
  insert_billing_type_one?: Maybe<Billing_Type>;
  /** insert data into the table: "brand" */
  insert_brand?: Maybe<Brand_Mutation_Response>;
  /** insert a single row into the table: "brand" */
  insert_brand_one?: Maybe<Brand>;
  /** insert data into the table: "business_profile" */
  insert_business_profile?: Maybe<Business_Profile_Mutation_Response>;
  /** insert a single row into the table: "business_profile" */
  insert_business_profile_one?: Maybe<Business_Profile>;
  /** insert data into the table: "catalog" */
  insert_catalog?: Maybe<Catalog_Mutation_Response>;
  /** insert a single row into the table: "catalog" */
  insert_catalog_one?: Maybe<Catalog>;
  /** insert data into the table: "catalog_packaging" */
  insert_catalog_packaging?: Maybe<Catalog_Packaging_Mutation_Response>;
  /** insert data into the table: "catalog_packaging_history" */
  insert_catalog_packaging_history?: Maybe<Catalog_Packaging_History_Mutation_Response>;
  /** insert a single row into the table: "catalog_packaging_history" */
  insert_catalog_packaging_history_one?: Maybe<Catalog_Packaging_History>;
  /** insert a single row into the table: "catalog_packaging" */
  insert_catalog_packaging_one?: Maybe<Catalog_Packaging>;
  /** insert data into the table: "comment" */
  insert_comment?: Maybe<Comment_Mutation_Response>;
  /** insert data into the table: "comment_history" */
  insert_comment_history?: Maybe<Comment_History_Mutation_Response>;
  /** insert a single row into the table: "comment_history" */
  insert_comment_history_one?: Maybe<Comment_History>;
  /** insert a single row into the table: "comment" */
  insert_comment_one?: Maybe<Comment>;
  /** insert data into the table: "comment_type" */
  insert_comment_type?: Maybe<Comment_Type_Mutation_Response>;
  /** insert a single row into the table: "comment_type" */
  insert_comment_type_one?: Maybe<Comment_Type>;
  /** insert data into the table: "commission" */
  insert_commission?: Maybe<Commission_Mutation_Response>;
  /** insert a single row into the table: "commission" */
  insert_commission_one?: Maybe<Commission>;
  /** insert data into the table: "commission_type" */
  insert_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** insert a single row into the table: "commission_type" */
  insert_commission_type_one?: Maybe<Commission_Type>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<Company_Mutation_Response>;
  /** insert a single row into the table: "company" */
  insert_company_one?: Maybe<Company>;
  /** insert data into the table: "company_photo" */
  insert_company_photo?: Maybe<Company_Photo_Mutation_Response>;
  /** insert a single row into the table: "company_photo" */
  insert_company_photo_one?: Maybe<Company_Photo>;
  /** insert data into the table: "contract" */
  insert_contract?: Maybe<Contract_Mutation_Response>;
  /** insert data into the table: "contract_history" */
  insert_contract_history?: Maybe<Contract_History_Mutation_Response>;
  /** insert a single row into the table: "contract_history" */
  insert_contract_history_one?: Maybe<Contract_History>;
  /** insert a single row into the table: "contract" */
  insert_contract_one?: Maybe<Contract>;
  /** insert data into the table: "contract_status" */
  insert_contract_status?: Maybe<Contract_Status_Mutation_Response>;
  /** insert a single row into the table: "contract_status" */
  insert_contract_status_one?: Maybe<Contract_Status>;
  /** insert data into the table: "delivery_zone" */
  insert_delivery_zone?: Maybe<Delivery_Zone_Mutation_Response>;
  /** insert data into the table: "delivery_zone_administrative_area" */
  insert_delivery_zone_administrative_area?: Maybe<Delivery_Zone_Administrative_Area_Mutation_Response>;
  /** insert a single row into the table: "delivery_zone_administrative_area" */
  insert_delivery_zone_administrative_area_one?: Maybe<Delivery_Zone_Administrative_Area>;
  /** insert a single row into the table: "delivery_zone" */
  insert_delivery_zone_one?: Maybe<Delivery_Zone>;
  /** insert data into the table: "document" */
  insert_document?: Maybe<Document_Mutation_Response>;
  /** insert a single row into the table: "document" */
  insert_document_one?: Maybe<Document>;
  /** insert data into the table: "document_type" */
  insert_document_type?: Maybe<Document_Type_Mutation_Response>;
  /** insert a single row into the table: "document_type" */
  insert_document_type_one?: Maybe<Document_Type>;
  /** insert data into the table: "duration_type" */
  insert_duration_type?: Maybe<Duration_Type_Mutation_Response>;
  /** insert a single row into the table: "duration_type" */
  insert_duration_type_one?: Maybe<Duration_Type>;
  /** insert data into the table: "global_product_family" */
  insert_global_product_family?: Maybe<Global_Product_Family_Mutation_Response>;
  /** insert a single row into the table: "global_product_family" */
  insert_global_product_family_one?: Maybe<Global_Product_Family>;
  /** insert data into the table: "gtin_type" */
  insert_gtin_type?: Maybe<Gtin_Type_Mutation_Response>;
  /** insert a single row into the table: "gtin_type" */
  insert_gtin_type_one?: Maybe<Gtin_Type>;
  /** insert data into the table: "invoice" */
  insert_invoice?: Maybe<Invoice_Mutation_Response>;
  /** insert a single row into the table: "invoice" */
  insert_invoice_one?: Maybe<Invoice>;
  /** insert data into the table: "invoice_type" */
  insert_invoice_type?: Maybe<Invoice_Type_Mutation_Response>;
  /** insert a single row into the table: "invoice_type" */
  insert_invoice_type_one?: Maybe<Invoice_Type>;
  /** insert data into the table: "measurement_unit" */
  insert_measurement_unit?: Maybe<Measurement_Unit_Mutation_Response>;
  /** insert a single row into the table: "measurement_unit" */
  insert_measurement_unit_one?: Maybe<Measurement_Unit>;
  /** insert data into the table: "non_reconciliated_moneyin" */
  insert_non_reconciliated_moneyin?: Maybe<Non_Reconciliated_Moneyin_Mutation_Response>;
  /** insert a single row into the table: "non_reconciliated_moneyin" */
  insert_non_reconciliated_moneyin_one?: Maybe<Non_Reconciliated_Moneyin>;
  /** insert data into the table: "notification_business_profile" */
  insert_notification_business_profile?: Maybe<Notification_Business_Profile_Mutation_Response>;
  /** insert a single row into the table: "notification_business_profile" */
  insert_notification_business_profile_one?: Maybe<Notification_Business_Profile>;
  /** insert data into the table: "notification_code" */
  insert_notification_code?: Maybe<Notification_Code_Mutation_Response>;
  /** insert a single row into the table: "notification_code" */
  insert_notification_code_one?: Maybe<Notification_Code>;
  /** insert data into the table: "notification_default" */
  insert_notification_default?: Maybe<Notification_Default_Mutation_Response>;
  /** insert a single row into the table: "notification_default" */
  insert_notification_default_one?: Maybe<Notification_Default>;
  /** insert data into the table: "notification_method" */
  insert_notification_method?: Maybe<Notification_Method_Mutation_Response>;
  /** insert a single row into the table: "notification_method" */
  insert_notification_method_one?: Maybe<Notification_Method>;
  /** insert data into the table: "notification_recipient" */
  insert_notification_recipient?: Maybe<Notification_Recipient_Mutation_Response>;
  /** insert a single row into the table: "notification_recipient" */
  insert_notification_recipient_one?: Maybe<Notification_Recipient>;
  /** insert data into the table: "notification_scope" */
  insert_notification_scope?: Maybe<Notification_Scope_Mutation_Response>;
  /** insert a single row into the table: "notification_scope" */
  insert_notification_scope_one?: Maybe<Notification_Scope>;
  /** insert data into the table: "notification_subscription" */
  insert_notification_subscription?: Maybe<Notification_Subscription_Mutation_Response>;
  /** insert a single row into the table: "notification_subscription" */
  insert_notification_subscription_one?: Maybe<Notification_Subscription>;
  /** insert data into the table: "order" */
  insert_order?: Maybe<Order_Mutation_Response>;
  /** insert data into the table: "order_history" */
  insert_order_history?: Maybe<Order_History_Mutation_Response>;
  /** insert a single row into the table: "order_history" */
  insert_order_history_one?: Maybe<Order_History>;
  /** insert a single row into the table: "order" */
  insert_order_one?: Maybe<Order>;
  /** insert data into the table: "order_packaging_quantity" */
  insert_order_packaging_quantity?: Maybe<Order_Packaging_Quantity_Mutation_Response>;
  /** insert a single row into the table: "order_packaging_quantity" */
  insert_order_packaging_quantity_one?: Maybe<Order_Packaging_Quantity>;
  /** insert data into the table: "order_status" */
  insert_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** insert a single row into the table: "order_status" */
  insert_order_status_one?: Maybe<Order_Status>;
  /** insert data into the table: "packaging" */
  insert_packaging?: Maybe<Packaging_Mutation_Response>;
  /** insert data into the table: "packaging_history" */
  insert_packaging_history?: Maybe<Packaging_History_Mutation_Response>;
  /** insert a single row into the table: "packaging_history" */
  insert_packaging_history_one?: Maybe<Packaging_History>;
  /** insert a single row into the table: "packaging" */
  insert_packaging_one?: Maybe<Packaging>;
  /** insert data into the table: "payment_method" */
  insert_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** insert a single row into the table: "payment_method" */
  insert_payment_method_one?: Maybe<Payment_Method>;
  /** insert data into the table: "payment_process_status" */
  insert_payment_process_status?: Maybe<Payment_Process_Status_Mutation_Response>;
  /** insert a single row into the table: "payment_process_status" */
  insert_payment_process_status_one?: Maybe<Payment_Process_Status>;
  /** insert data into the table: "photo" */
  insert_photo?: Maybe<Photo_Mutation_Response>;
  /** insert a single row into the table: "photo" */
  insert_photo_one?: Maybe<Photo>;
  /** insert data into the table: "privacy_setting" */
  insert_privacy_setting?: Maybe<Privacy_Setting_Mutation_Response>;
  /** insert a single row into the table: "privacy_setting" */
  insert_privacy_setting_one?: Maybe<Privacy_Setting>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert data into the table: "product_family" */
  insert_product_family?: Maybe<Product_Family_Mutation_Response>;
  /** insert data into the table: "product_family_name" */
  insert_product_family_name?: Maybe<Product_Family_Name_Mutation_Response>;
  /** insert a single row into the table: "product_family_name" */
  insert_product_family_name_one?: Maybe<Product_Family_Name>;
  /** insert a single row into the table: "product_family" */
  insert_product_family_one?: Maybe<Product_Family>;
  /** insert data into the table: "product_history" */
  insert_product_history?: Maybe<Product_History_Mutation_Response>;
  /** insert a single row into the table: "product_history" */
  insert_product_history_one?: Maybe<Product_History>;
  /** insert data into the table: "product_label_name" */
  insert_product_label_name?: Maybe<Product_Label_Name_Mutation_Response>;
  /** insert a single row into the table: "product_label_name" */
  insert_product_label_name_one?: Maybe<Product_Label_Name>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "product_product_labels_product_label" */
  insert_product_product_labels_product_label?: Maybe<Product_Product_Labels_Product_Label_Mutation_Response>;
  /** insert a single row into the table: "product_product_labels_product_label" */
  insert_product_product_labels_product_label_one?: Maybe<Product_Product_Labels_Product_Label>;
  /** insert data into the table: "product_sub_family" */
  insert_product_sub_family?: Maybe<Product_Sub_Family_Mutation_Response>;
  /** insert data into the table: "product_sub_family_name" */
  insert_product_sub_family_name?: Maybe<Product_Sub_Family_Name_Mutation_Response>;
  /** insert a single row into the table: "product_sub_family_name" */
  insert_product_sub_family_name_one?: Maybe<Product_Sub_Family_Name>;
  /** insert a single row into the table: "product_sub_family" */
  insert_product_sub_family_one?: Maybe<Product_Sub_Family>;
  /** insert data into the table: "recommended_supplier" */
  insert_recommended_supplier?: Maybe<Recommended_Supplier_Mutation_Response>;
  /** insert a single row into the table: "recommended_supplier" */
  insert_recommended_supplier_one?: Maybe<Recommended_Supplier>;
  /** insert data into the table: "refresh_token" */
  insert_refresh_token?: Maybe<Refresh_Token_Mutation_Response>;
  /** insert a single row into the table: "refresh_token" */
  insert_refresh_token_one?: Maybe<Refresh_Token>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>;
  /** insert data into the table: "role_assignment" */
  insert_role_assignment?: Maybe<Role_Assignment_Mutation_Response>;
  /** insert a single row into the table: "role_assignment" */
  insert_role_assignment_one?: Maybe<Role_Assignment>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "staff_role" */
  insert_staff_role?: Maybe<Staff_Role_Mutation_Response>;
  /** insert a single row into the table: "staff_role" */
  insert_staff_role_one?: Maybe<Staff_Role>;
  /** insert data into the table: "staff_user" */
  insert_staff_user?: Maybe<Staff_User_Mutation_Response>;
  /** insert a single row into the table: "staff_user" */
  insert_staff_user_one?: Maybe<Staff_User>;
  /** insert data into the table: "trade_item_unit_descriptor" */
  insert_trade_item_unit_descriptor?: Maybe<Trade_Item_Unit_Descriptor_Mutation_Response>;
  /** insert a single row into the table: "trade_item_unit_descriptor" */
  insert_trade_item_unit_descriptor_one?: Maybe<Trade_Item_Unit_Descriptor>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_product_family" */
  insert_user_product_family?: Maybe<User_Product_Family_Mutation_Response>;
  /** insert a single row into the table: "user_product_family" */
  insert_user_product_family_one?: Maybe<User_Product_Family>;
  joinGroupChannel?: Maybe<JoinGroupChannelOutput>;
  loginV2?: Maybe<LoginV2Output>;
  /** Authenticate user with their email and authentication code. */
  loginWithAuthCode?: Maybe<LoginV2Output>;
  preLogin?: Maybe<PreLoginOutput>;
  reconciliateMoneyin?: Maybe<ReconciliateMoneyinOutput>;
  /** Send notification that buyer has replied in chat channel creted by supplier for first time */
  sendBuyerRepliedToSupplierChat?: Maybe<SendBuyerRepliedToSupplierChatOutput>;
  staffGoogleLogin?: Maybe<StaffGoogleLoginOutput>;
  updateOrderAction?: Maybe<UpdateOrderActionOutput>;
  updatePaymentDate: UpdatePaymentDateOutput;
  /** update data of the table: "act_hi_procinst" */
  update_act_hi_procinst?: Maybe<Act_Hi_Procinst_Mutation_Response>;
  /** update single row of the table: "act_hi_procinst" */
  update_act_hi_procinst_by_pk?: Maybe<Act_Hi_Procinst>;
  /** update multiples rows of table: "act_hi_procinst" */
  update_act_hi_procinst_many?: Maybe<Array<Maybe<Act_Hi_Procinst_Mutation_Response>>>;
  /** update data of the table: "act_hi_taskinst" */
  update_act_hi_taskinst?: Maybe<Act_Hi_Taskinst_Mutation_Response>;
  /** update single row of the table: "act_hi_taskinst" */
  update_act_hi_taskinst_by_pk?: Maybe<Act_Hi_Taskinst>;
  /** update multiples rows of table: "act_hi_taskinst" */
  update_act_hi_taskinst_many?: Maybe<Array<Maybe<Act_Hi_Taskinst_Mutation_Response>>>;
  /** update data of the table: "act_ru_event_subscr" */
  update_act_ru_event_subscr?: Maybe<Act_Ru_Event_Subscr_Mutation_Response>;
  /** update single row of the table: "act_ru_event_subscr" */
  update_act_ru_event_subscr_by_pk?: Maybe<Act_Ru_Event_Subscr>;
  /** update multiples rows of table: "act_ru_event_subscr" */
  update_act_ru_event_subscr_many?: Maybe<Array<Maybe<Act_Ru_Event_Subscr_Mutation_Response>>>;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update data of the table: "administrative_area" */
  update_administrative_area?: Maybe<Administrative_Area_Mutation_Response>;
  /** update single row of the table: "administrative_area" */
  update_administrative_area_by_pk?: Maybe<Administrative_Area>;
  /** update multiples rows of table: "administrative_area" */
  update_administrative_area_many?: Maybe<Array<Maybe<Administrative_Area_Mutation_Response>>>;
  /** update data of the table: "authentication_code" */
  update_authentication_code?: Maybe<Authentication_Code_Mutation_Response>;
  /** update single row of the table: "authentication_code" */
  update_authentication_code_by_pk?: Maybe<Authentication_Code>;
  /** update multiples rows of table: "authentication_code" */
  update_authentication_code_many?: Maybe<Array<Maybe<Authentication_Code_Mutation_Response>>>;
  /** update data of the table: "authentication_verification_type" */
  update_authentication_verification_type?: Maybe<Authentication_Verification_Type_Mutation_Response>;
  /** update single row of the table: "authentication_verification_type" */
  update_authentication_verification_type_by_pk?: Maybe<Authentication_Verification_Type>;
  /** update multiples rows of table: "authentication_verification_type" */
  update_authentication_verification_type_many?: Maybe<Array<Maybe<Authentication_Verification_Type_Mutation_Response>>>;
  /** update data of the table: "availability_status" */
  update_availability_status?: Maybe<Availability_Status_Mutation_Response>;
  /** update single row of the table: "availability_status" */
  update_availability_status_by_pk?: Maybe<Availability_Status>;
  /** update multiples rows of table: "availability_status" */
  update_availability_status_many?: Maybe<Array<Maybe<Availability_Status_Mutation_Response>>>;
  /** update data of the table: "base_unit" */
  update_base_unit?: Maybe<Base_Unit_Mutation_Response>;
  /** update single row of the table: "base_unit" */
  update_base_unit_by_pk?: Maybe<Base_Unit>;
  /** update data of the table: "base_unit_history" */
  update_base_unit_history?: Maybe<Base_Unit_History_Mutation_Response>;
  /** update single row of the table: "base_unit_history" */
  update_base_unit_history_by_pk?: Maybe<Base_Unit_History>;
  /** update multiples rows of table: "base_unit_history" */
  update_base_unit_history_many?: Maybe<Array<Maybe<Base_Unit_History_Mutation_Response>>>;
  /** update multiples rows of table: "base_unit" */
  update_base_unit_many?: Maybe<Array<Maybe<Base_Unit_Mutation_Response>>>;
  /** update data of the table: "base_unit_photo" */
  update_base_unit_photo?: Maybe<Base_Unit_Photo_Mutation_Response>;
  /** update single row of the table: "base_unit_photo" */
  update_base_unit_photo_by_pk?: Maybe<Base_Unit_Photo>;
  /** update data of the table: "base_unit_photo_history" */
  update_base_unit_photo_history?: Maybe<Base_Unit_Photo_History_Mutation_Response>;
  /** update single row of the table: "base_unit_photo_history" */
  update_base_unit_photo_history_by_pk?: Maybe<Base_Unit_Photo_History>;
  /** update multiples rows of table: "base_unit_photo_history" */
  update_base_unit_photo_history_many?: Maybe<Array<Maybe<Base_Unit_Photo_History_Mutation_Response>>>;
  /** update multiples rows of table: "base_unit_photo" */
  update_base_unit_photo_many?: Maybe<Array<Maybe<Base_Unit_Photo_Mutation_Response>>>;
  /** update data of the table: "base_unit_type" */
  update_base_unit_type?: Maybe<Base_Unit_Type_Mutation_Response>;
  /** update single row of the table: "base_unit_type" */
  update_base_unit_type_by_pk?: Maybe<Base_Unit_Type>;
  /** update multiples rows of table: "base_unit_type" */
  update_base_unit_type_many?: Maybe<Array<Maybe<Base_Unit_Type_Mutation_Response>>>;
  /** update data of the table: "basket" */
  update_basket?: Maybe<Basket_Mutation_Response>;
  /** update single row of the table: "basket" */
  update_basket_by_pk?: Maybe<Basket>;
  /** update multiples rows of table: "basket" */
  update_basket_many?: Maybe<Array<Maybe<Basket_Mutation_Response>>>;
  /** update data of the table: "basket_packaging_quantity" */
  update_basket_packaging_quantity?: Maybe<Basket_Packaging_Quantity_Mutation_Response>;
  /** update single row of the table: "basket_packaging_quantity" */
  update_basket_packaging_quantity_by_pk?: Maybe<Basket_Packaging_Quantity>;
  /** update multiples rows of table: "basket_packaging_quantity" */
  update_basket_packaging_quantity_many?: Maybe<Array<Maybe<Basket_Packaging_Quantity_Mutation_Response>>>;
  /** update data of the table: "basket_status" */
  update_basket_status?: Maybe<Basket_Status_Mutation_Response>;
  /** update single row of the table: "basket_status" */
  update_basket_status_by_pk?: Maybe<Basket_Status>;
  /** update multiples rows of table: "basket_status" */
  update_basket_status_many?: Maybe<Array<Maybe<Basket_Status_Mutation_Response>>>;
  /** update data of the table: "billing_type" */
  update_billing_type?: Maybe<Billing_Type_Mutation_Response>;
  /** update single row of the table: "billing_type" */
  update_billing_type_by_pk?: Maybe<Billing_Type>;
  /** update multiples rows of table: "billing_type" */
  update_billing_type_many?: Maybe<Array<Maybe<Billing_Type_Mutation_Response>>>;
  /** update data of the table: "brand" */
  update_brand?: Maybe<Brand_Mutation_Response>;
  /** update single row of the table: "brand" */
  update_brand_by_pk?: Maybe<Brand>;
  /** update multiples rows of table: "brand" */
  update_brand_many?: Maybe<Array<Maybe<Brand_Mutation_Response>>>;
  /** update data of the table: "business_profile" */
  update_business_profile?: Maybe<Business_Profile_Mutation_Response>;
  /** update single row of the table: "business_profile" */
  update_business_profile_by_pk?: Maybe<Business_Profile>;
  /** update multiples rows of table: "business_profile" */
  update_business_profile_many?: Maybe<Array<Maybe<Business_Profile_Mutation_Response>>>;
  /** update data of the table: "catalog" */
  update_catalog?: Maybe<Catalog_Mutation_Response>;
  /** update single row of the table: "catalog" */
  update_catalog_by_pk?: Maybe<Catalog>;
  /** update multiples rows of table: "catalog" */
  update_catalog_many?: Maybe<Array<Maybe<Catalog_Mutation_Response>>>;
  /** update data of the table: "catalog_packaging" */
  update_catalog_packaging?: Maybe<Catalog_Packaging_Mutation_Response>;
  /** update single row of the table: "catalog_packaging" */
  update_catalog_packaging_by_pk?: Maybe<Catalog_Packaging>;
  /** update data of the table: "catalog_packaging_history" */
  update_catalog_packaging_history?: Maybe<Catalog_Packaging_History_Mutation_Response>;
  /** update single row of the table: "catalog_packaging_history" */
  update_catalog_packaging_history_by_pk?: Maybe<Catalog_Packaging_History>;
  /** update multiples rows of table: "catalog_packaging_history" */
  update_catalog_packaging_history_many?: Maybe<Array<Maybe<Catalog_Packaging_History_Mutation_Response>>>;
  /** update multiples rows of table: "catalog_packaging" */
  update_catalog_packaging_many?: Maybe<Array<Maybe<Catalog_Packaging_Mutation_Response>>>;
  /** update data of the table: "comment" */
  update_comment?: Maybe<Comment_Mutation_Response>;
  /** update single row of the table: "comment" */
  update_comment_by_pk?: Maybe<Comment>;
  /** update data of the table: "comment_history" */
  update_comment_history?: Maybe<Comment_History_Mutation_Response>;
  /** update single row of the table: "comment_history" */
  update_comment_history_by_pk?: Maybe<Comment_History>;
  /** update multiples rows of table: "comment_history" */
  update_comment_history_many?: Maybe<Array<Maybe<Comment_History_Mutation_Response>>>;
  /** update multiples rows of table: "comment" */
  update_comment_many?: Maybe<Array<Maybe<Comment_Mutation_Response>>>;
  /** update data of the table: "comment_type" */
  update_comment_type?: Maybe<Comment_Type_Mutation_Response>;
  /** update single row of the table: "comment_type" */
  update_comment_type_by_pk?: Maybe<Comment_Type>;
  /** update multiples rows of table: "comment_type" */
  update_comment_type_many?: Maybe<Array<Maybe<Comment_Type_Mutation_Response>>>;
  /** update data of the table: "commission" */
  update_commission?: Maybe<Commission_Mutation_Response>;
  /** update single row of the table: "commission" */
  update_commission_by_pk?: Maybe<Commission>;
  /** update multiples rows of table: "commission" */
  update_commission_many?: Maybe<Array<Maybe<Commission_Mutation_Response>>>;
  /** update data of the table: "commission_type" */
  update_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** update single row of the table: "commission_type" */
  update_commission_type_by_pk?: Maybe<Commission_Type>;
  /** update multiples rows of table: "commission_type" */
  update_commission_type_many?: Maybe<Array<Maybe<Commission_Type_Mutation_Response>>>;
  /** update data of the table: "company" */
  update_company?: Maybe<Company_Mutation_Response>;
  /** update single row of the table: "company" */
  update_company_by_pk?: Maybe<Company>;
  /** update multiples rows of table: "company" */
  update_company_many?: Maybe<Array<Maybe<Company_Mutation_Response>>>;
  /** update data of the table: "company_photo" */
  update_company_photo?: Maybe<Company_Photo_Mutation_Response>;
  /** update single row of the table: "company_photo" */
  update_company_photo_by_pk?: Maybe<Company_Photo>;
  /** update multiples rows of table: "company_photo" */
  update_company_photo_many?: Maybe<Array<Maybe<Company_Photo_Mutation_Response>>>;
  /** update data of the table: "contract" */
  update_contract?: Maybe<Contract_Mutation_Response>;
  /** update single row of the table: "contract" */
  update_contract_by_pk?: Maybe<Contract>;
  /** update data of the table: "contract_history" */
  update_contract_history?: Maybe<Contract_History_Mutation_Response>;
  /** update single row of the table: "contract_history" */
  update_contract_history_by_pk?: Maybe<Contract_History>;
  /** update multiples rows of table: "contract_history" */
  update_contract_history_many?: Maybe<Array<Maybe<Contract_History_Mutation_Response>>>;
  /** update multiples rows of table: "contract" */
  update_contract_many?: Maybe<Array<Maybe<Contract_Mutation_Response>>>;
  /** update data of the table: "contract_status" */
  update_contract_status?: Maybe<Contract_Status_Mutation_Response>;
  /** update single row of the table: "contract_status" */
  update_contract_status_by_pk?: Maybe<Contract_Status>;
  /** update multiples rows of table: "contract_status" */
  update_contract_status_many?: Maybe<Array<Maybe<Contract_Status_Mutation_Response>>>;
  /** update data of the table: "delivery_zone" */
  update_delivery_zone?: Maybe<Delivery_Zone_Mutation_Response>;
  /** update data of the table: "delivery_zone_administrative_area" */
  update_delivery_zone_administrative_area?: Maybe<Delivery_Zone_Administrative_Area_Mutation_Response>;
  /** update single row of the table: "delivery_zone_administrative_area" */
  update_delivery_zone_administrative_area_by_pk?: Maybe<Delivery_Zone_Administrative_Area>;
  /** update multiples rows of table: "delivery_zone_administrative_area" */
  update_delivery_zone_administrative_area_many?: Maybe<Array<Maybe<Delivery_Zone_Administrative_Area_Mutation_Response>>>;
  /** update single row of the table: "delivery_zone" */
  update_delivery_zone_by_pk?: Maybe<Delivery_Zone>;
  /** update multiples rows of table: "delivery_zone" */
  update_delivery_zone_many?: Maybe<Array<Maybe<Delivery_Zone_Mutation_Response>>>;
  /** update data of the table: "document" */
  update_document?: Maybe<Document_Mutation_Response>;
  /** update single row of the table: "document" */
  update_document_by_pk?: Maybe<Document>;
  /** update multiples rows of table: "document" */
  update_document_many?: Maybe<Array<Maybe<Document_Mutation_Response>>>;
  /** update data of the table: "document_type" */
  update_document_type?: Maybe<Document_Type_Mutation_Response>;
  /** update single row of the table: "document_type" */
  update_document_type_by_pk?: Maybe<Document_Type>;
  /** update multiples rows of table: "document_type" */
  update_document_type_many?: Maybe<Array<Maybe<Document_Type_Mutation_Response>>>;
  /** update data of the table: "duration_type" */
  update_duration_type?: Maybe<Duration_Type_Mutation_Response>;
  /** update single row of the table: "duration_type" */
  update_duration_type_by_pk?: Maybe<Duration_Type>;
  /** update multiples rows of table: "duration_type" */
  update_duration_type_many?: Maybe<Array<Maybe<Duration_Type_Mutation_Response>>>;
  /** update data of the table: "global_product_family" */
  update_global_product_family?: Maybe<Global_Product_Family_Mutation_Response>;
  /** update single row of the table: "global_product_family" */
  update_global_product_family_by_pk?: Maybe<Global_Product_Family>;
  /** update multiples rows of table: "global_product_family" */
  update_global_product_family_many?: Maybe<Array<Maybe<Global_Product_Family_Mutation_Response>>>;
  /** update data of the table: "gtin_type" */
  update_gtin_type?: Maybe<Gtin_Type_Mutation_Response>;
  /** update single row of the table: "gtin_type" */
  update_gtin_type_by_pk?: Maybe<Gtin_Type>;
  /** update multiples rows of table: "gtin_type" */
  update_gtin_type_many?: Maybe<Array<Maybe<Gtin_Type_Mutation_Response>>>;
  /** update data of the table: "invoice" */
  update_invoice?: Maybe<Invoice_Mutation_Response>;
  /** update single row of the table: "invoice" */
  update_invoice_by_pk?: Maybe<Invoice>;
  /** update multiples rows of table: "invoice" */
  update_invoice_many?: Maybe<Array<Maybe<Invoice_Mutation_Response>>>;
  /** update data of the table: "invoice_type" */
  update_invoice_type?: Maybe<Invoice_Type_Mutation_Response>;
  /** update single row of the table: "invoice_type" */
  update_invoice_type_by_pk?: Maybe<Invoice_Type>;
  /** update multiples rows of table: "invoice_type" */
  update_invoice_type_many?: Maybe<Array<Maybe<Invoice_Type_Mutation_Response>>>;
  /** update data of the table: "measurement_unit" */
  update_measurement_unit?: Maybe<Measurement_Unit_Mutation_Response>;
  /** update single row of the table: "measurement_unit" */
  update_measurement_unit_by_pk?: Maybe<Measurement_Unit>;
  /** update multiples rows of table: "measurement_unit" */
  update_measurement_unit_many?: Maybe<Array<Maybe<Measurement_Unit_Mutation_Response>>>;
  /** update data of the table: "non_reconciliated_moneyin" */
  update_non_reconciliated_moneyin?: Maybe<Non_Reconciliated_Moneyin_Mutation_Response>;
  /** update single row of the table: "non_reconciliated_moneyin" */
  update_non_reconciliated_moneyin_by_pk?: Maybe<Non_Reconciliated_Moneyin>;
  /** update multiples rows of table: "non_reconciliated_moneyin" */
  update_non_reconciliated_moneyin_many?: Maybe<Array<Maybe<Non_Reconciliated_Moneyin_Mutation_Response>>>;
  /** update data of the table: "notification_business_profile" */
  update_notification_business_profile?: Maybe<Notification_Business_Profile_Mutation_Response>;
  /** update single row of the table: "notification_business_profile" */
  update_notification_business_profile_by_pk?: Maybe<Notification_Business_Profile>;
  /** update multiples rows of table: "notification_business_profile" */
  update_notification_business_profile_many?: Maybe<Array<Maybe<Notification_Business_Profile_Mutation_Response>>>;
  /** update data of the table: "notification_code" */
  update_notification_code?: Maybe<Notification_Code_Mutation_Response>;
  /** update single row of the table: "notification_code" */
  update_notification_code_by_pk?: Maybe<Notification_Code>;
  /** update multiples rows of table: "notification_code" */
  update_notification_code_many?: Maybe<Array<Maybe<Notification_Code_Mutation_Response>>>;
  /** update data of the table: "notification_default" */
  update_notification_default?: Maybe<Notification_Default_Mutation_Response>;
  /** update single row of the table: "notification_default" */
  update_notification_default_by_pk?: Maybe<Notification_Default>;
  /** update multiples rows of table: "notification_default" */
  update_notification_default_many?: Maybe<Array<Maybe<Notification_Default_Mutation_Response>>>;
  /** update data of the table: "notification_method" */
  update_notification_method?: Maybe<Notification_Method_Mutation_Response>;
  /** update single row of the table: "notification_method" */
  update_notification_method_by_pk?: Maybe<Notification_Method>;
  /** update multiples rows of table: "notification_method" */
  update_notification_method_many?: Maybe<Array<Maybe<Notification_Method_Mutation_Response>>>;
  /** update data of the table: "notification_recipient" */
  update_notification_recipient?: Maybe<Notification_Recipient_Mutation_Response>;
  /** update single row of the table: "notification_recipient" */
  update_notification_recipient_by_pk?: Maybe<Notification_Recipient>;
  /** update multiples rows of table: "notification_recipient" */
  update_notification_recipient_many?: Maybe<Array<Maybe<Notification_Recipient_Mutation_Response>>>;
  /** update data of the table: "notification_scope" */
  update_notification_scope?: Maybe<Notification_Scope_Mutation_Response>;
  /** update single row of the table: "notification_scope" */
  update_notification_scope_by_pk?: Maybe<Notification_Scope>;
  /** update multiples rows of table: "notification_scope" */
  update_notification_scope_many?: Maybe<Array<Maybe<Notification_Scope_Mutation_Response>>>;
  /** update data of the table: "notification_subscription" */
  update_notification_subscription?: Maybe<Notification_Subscription_Mutation_Response>;
  /** update single row of the table: "notification_subscription" */
  update_notification_subscription_by_pk?: Maybe<Notification_Subscription>;
  /** update multiples rows of table: "notification_subscription" */
  update_notification_subscription_many?: Maybe<Array<Maybe<Notification_Subscription_Mutation_Response>>>;
  /** update data of the table: "order" */
  update_order?: Maybe<Order_Mutation_Response>;
  /** update single row of the table: "order" */
  update_order_by_pk?: Maybe<Order>;
  /** update data of the table: "order_history" */
  update_order_history?: Maybe<Order_History_Mutation_Response>;
  /** update single row of the table: "order_history" */
  update_order_history_by_pk?: Maybe<Order_History>;
  /** update multiples rows of table: "order_history" */
  update_order_history_many?: Maybe<Array<Maybe<Order_History_Mutation_Response>>>;
  /** update multiples rows of table: "order" */
  update_order_many?: Maybe<Array<Maybe<Order_Mutation_Response>>>;
  /** update data of the table: "order_packaging_quantity" */
  update_order_packaging_quantity?: Maybe<Order_Packaging_Quantity_Mutation_Response>;
  /** update single row of the table: "order_packaging_quantity" */
  update_order_packaging_quantity_by_pk?: Maybe<Order_Packaging_Quantity>;
  /** update multiples rows of table: "order_packaging_quantity" */
  update_order_packaging_quantity_many?: Maybe<Array<Maybe<Order_Packaging_Quantity_Mutation_Response>>>;
  /** update data of the table: "order_status" */
  update_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** update single row of the table: "order_status" */
  update_order_status_by_pk?: Maybe<Order_Status>;
  /** update multiples rows of table: "order_status" */
  update_order_status_many?: Maybe<Array<Maybe<Order_Status_Mutation_Response>>>;
  /** update data of the table: "packaging" */
  update_packaging?: Maybe<Packaging_Mutation_Response>;
  /** update single row of the table: "packaging" */
  update_packaging_by_pk?: Maybe<Packaging>;
  /** update data of the table: "packaging_history" */
  update_packaging_history?: Maybe<Packaging_History_Mutation_Response>;
  /** update single row of the table: "packaging_history" */
  update_packaging_history_by_pk?: Maybe<Packaging_History>;
  /** update multiples rows of table: "packaging_history" */
  update_packaging_history_many?: Maybe<Array<Maybe<Packaging_History_Mutation_Response>>>;
  /** update multiples rows of table: "packaging" */
  update_packaging_many?: Maybe<Array<Maybe<Packaging_Mutation_Response>>>;
  /** update data of the table: "payment_method" */
  update_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** update single row of the table: "payment_method" */
  update_payment_method_by_pk?: Maybe<Payment_Method>;
  /** update multiples rows of table: "payment_method" */
  update_payment_method_many?: Maybe<Array<Maybe<Payment_Method_Mutation_Response>>>;
  /** update data of the table: "payment_process_status" */
  update_payment_process_status?: Maybe<Payment_Process_Status_Mutation_Response>;
  /** update single row of the table: "payment_process_status" */
  update_payment_process_status_by_pk?: Maybe<Payment_Process_Status>;
  /** update multiples rows of table: "payment_process_status" */
  update_payment_process_status_many?: Maybe<Array<Maybe<Payment_Process_Status_Mutation_Response>>>;
  /** update data of the table: "photo" */
  update_photo?: Maybe<Photo_Mutation_Response>;
  /** update single row of the table: "photo" */
  update_photo_by_pk?: Maybe<Photo>;
  /** update multiples rows of table: "photo" */
  update_photo_many?: Maybe<Array<Maybe<Photo_Mutation_Response>>>;
  /** update data of the table: "privacy_setting" */
  update_privacy_setting?: Maybe<Privacy_Setting_Mutation_Response>;
  /** update single row of the table: "privacy_setting" */
  update_privacy_setting_by_pk?: Maybe<Privacy_Setting>;
  /** update multiples rows of table: "privacy_setting" */
  update_privacy_setting_many?: Maybe<Array<Maybe<Privacy_Setting_Mutation_Response>>>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update data of the table: "product_family" */
  update_product_family?: Maybe<Product_Family_Mutation_Response>;
  /** update single row of the table: "product_family" */
  update_product_family_by_pk?: Maybe<Product_Family>;
  /** update multiples rows of table: "product_family" */
  update_product_family_many?: Maybe<Array<Maybe<Product_Family_Mutation_Response>>>;
  /** update data of the table: "product_family_name" */
  update_product_family_name?: Maybe<Product_Family_Name_Mutation_Response>;
  /** update single row of the table: "product_family_name" */
  update_product_family_name_by_pk?: Maybe<Product_Family_Name>;
  /** update multiples rows of table: "product_family_name" */
  update_product_family_name_many?: Maybe<Array<Maybe<Product_Family_Name_Mutation_Response>>>;
  /** update data of the table: "product_history" */
  update_product_history?: Maybe<Product_History_Mutation_Response>;
  /** update single row of the table: "product_history" */
  update_product_history_by_pk?: Maybe<Product_History>;
  /** update multiples rows of table: "product_history" */
  update_product_history_many?: Maybe<Array<Maybe<Product_History_Mutation_Response>>>;
  /** update data of the table: "product_label_name" */
  update_product_label_name?: Maybe<Product_Label_Name_Mutation_Response>;
  /** update single row of the table: "product_label_name" */
  update_product_label_name_by_pk?: Maybe<Product_Label_Name>;
  /** update multiples rows of table: "product_label_name" */
  update_product_label_name_many?: Maybe<Array<Maybe<Product_Label_Name_Mutation_Response>>>;
  /** update multiples rows of table: "product" */
  update_product_many?: Maybe<Array<Maybe<Product_Mutation_Response>>>;
  /** update data of the table: "product_product_labels_product_label" */
  update_product_product_labels_product_label?: Maybe<Product_Product_Labels_Product_Label_Mutation_Response>;
  /** update single row of the table: "product_product_labels_product_label" */
  update_product_product_labels_product_label_by_pk?: Maybe<Product_Product_Labels_Product_Label>;
  /** update multiples rows of table: "product_product_labels_product_label" */
  update_product_product_labels_product_label_many?: Maybe<Array<Maybe<Product_Product_Labels_Product_Label_Mutation_Response>>>;
  /** update data of the table: "product_sub_family" */
  update_product_sub_family?: Maybe<Product_Sub_Family_Mutation_Response>;
  /** update single row of the table: "product_sub_family" */
  update_product_sub_family_by_pk?: Maybe<Product_Sub_Family>;
  /** update multiples rows of table: "product_sub_family" */
  update_product_sub_family_many?: Maybe<Array<Maybe<Product_Sub_Family_Mutation_Response>>>;
  /** update data of the table: "product_sub_family_name" */
  update_product_sub_family_name?: Maybe<Product_Sub_Family_Name_Mutation_Response>;
  /** update single row of the table: "product_sub_family_name" */
  update_product_sub_family_name_by_pk?: Maybe<Product_Sub_Family_Name>;
  /** update multiples rows of table: "product_sub_family_name" */
  update_product_sub_family_name_many?: Maybe<Array<Maybe<Product_Sub_Family_Name_Mutation_Response>>>;
  /** update data of the table: "recommended_supplier" */
  update_recommended_supplier?: Maybe<Recommended_Supplier_Mutation_Response>;
  /** update single row of the table: "recommended_supplier" */
  update_recommended_supplier_by_pk?: Maybe<Recommended_Supplier>;
  /** update multiples rows of table: "recommended_supplier" */
  update_recommended_supplier_many?: Maybe<Array<Maybe<Recommended_Supplier_Mutation_Response>>>;
  /** update data of the table: "refresh_token" */
  update_refresh_token?: Maybe<Refresh_Token_Mutation_Response>;
  /** update single row of the table: "refresh_token" */
  update_refresh_token_by_pk?: Maybe<Refresh_Token>;
  /** update multiples rows of table: "refresh_token" */
  update_refresh_token_many?: Maybe<Array<Maybe<Refresh_Token_Mutation_Response>>>;
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>;
  /** update data of the table: "role_assignment" */
  update_role_assignment?: Maybe<Role_Assignment_Mutation_Response>;
  /** update single row of the table: "role_assignment" */
  update_role_assignment_by_pk?: Maybe<Role_Assignment>;
  /** update multiples rows of table: "role_assignment" */
  update_role_assignment_many?: Maybe<Array<Maybe<Role_Assignment_Mutation_Response>>>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>;
  /** update data of the table: "staff_role" */
  update_staff_role?: Maybe<Staff_Role_Mutation_Response>;
  /** update single row of the table: "staff_role" */
  update_staff_role_by_pk?: Maybe<Staff_Role>;
  /** update multiples rows of table: "staff_role" */
  update_staff_role_many?: Maybe<Array<Maybe<Staff_Role_Mutation_Response>>>;
  /** update data of the table: "staff_user" */
  update_staff_user?: Maybe<Staff_User_Mutation_Response>;
  /** update single row of the table: "staff_user" */
  update_staff_user_by_pk?: Maybe<Staff_User>;
  /** update multiples rows of table: "staff_user" */
  update_staff_user_many?: Maybe<Array<Maybe<Staff_User_Mutation_Response>>>;
  /** update data of the table: "trade_item_unit_descriptor" */
  update_trade_item_unit_descriptor?: Maybe<Trade_Item_Unit_Descriptor_Mutation_Response>;
  /** update single row of the table: "trade_item_unit_descriptor" */
  update_trade_item_unit_descriptor_by_pk?: Maybe<Trade_Item_Unit_Descriptor>;
  /** update multiples rows of table: "trade_item_unit_descriptor" */
  update_trade_item_unit_descriptor_many?: Maybe<Array<Maybe<Trade_Item_Unit_Descriptor_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "user_product_family" */
  update_user_product_family?: Maybe<User_Product_Family_Mutation_Response>;
  /** update single row of the table: "user_product_family" */
  update_user_product_family_by_pk?: Maybe<User_Product_Family>;
  /** update multiples rows of table: "user_product_family" */
  update_user_product_family_many?: Maybe<Array<Maybe<User_Product_Family_Mutation_Response>>>;
  /** Action used to insert a new document or edit an existing one for an order */
  upsertDocumentOfOrder?: Maybe<UpsertDocumentOfOrderOutput>;
  /** execute VOLATILE function "verify_user_authentication_code" which returns "authentication_verification_type" */
  verify_user_authentication_code?: Maybe<Authentication_Verification_Type>;
};


/** mutation root */
export type Mutation_RootAcceptContractOfferArgs = {
  contractId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootCreateContractRequestArgs = {
  buyingCompanyId: Scalars['uuid']['input'];
  fromBusinessProfile: BusinessProfile;
  message: Scalars['String']['input'];
  supplyingCompanyId: Scalars['uuid']['input'];
  userId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootCreateGroupChannelArgs = {
  buyingCompanyId: Scalars['uuid']['input'];
  fromBusinessProfile: BusinessProfile;
  supplyingCompanyId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteDocumentByIdFromOrderArgs = {
  documentId: Scalars['uuid']['input'];
  userBusinessProfile: BusinessProfile;
};


/** mutation root */
export type Mutation_RootDelete_Act_Hi_ProcinstArgs = {
  where: Act_Hi_Procinst_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Act_Hi_Procinst_By_PkArgs = {
  id_: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Act_Hi_TaskinstArgs = {
  where: Act_Hi_Taskinst_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Act_Hi_Taskinst_By_PkArgs = {
  id_: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Act_Ru_Event_SubscrArgs = {
  where: Act_Ru_Event_Subscr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Act_Ru_Event_Subscr_By_PkArgs = {
  id_: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Administrative_AreaArgs = {
  where: Administrative_Area_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Administrative_Area_By_PkArgs = {
  area: Scalars['String']['input'];
  country_code: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Authentication_CodeArgs = {
  where: Authentication_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Authentication_Code_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Authentication_Verification_TypeArgs = {
  where: Authentication_Verification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Authentication_Verification_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Availability_StatusArgs = {
  where: Availability_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Availability_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Base_UnitArgs = {
  where: Base_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_Unit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Base_Unit_HistoryArgs = {
  where: Base_Unit_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_Unit_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Base_Unit_PhotoArgs = {
  where: Base_Unit_Photo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_Unit_Photo_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Base_Unit_Photo_HistoryArgs = {
  where: Base_Unit_Photo_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_Unit_Photo_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Base_Unit_TypeArgs = {
  where: Base_Unit_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_Unit_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BasketArgs = {
  where: Basket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Basket_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Basket_Packaging_QuantityArgs = {
  where: Basket_Packaging_Quantity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Basket_Packaging_Quantity_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Basket_StatusArgs = {
  where: Basket_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Basket_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_TypeArgs = {
  where: Billing_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BrandArgs = {
  where: Brand_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Brand_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Business_ProfileArgs = {
  where: Business_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Business_Profile_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CatalogArgs = {
  where: Catalog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Catalog_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Catalog_PackagingArgs = {
  where: Catalog_Packaging_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Catalog_Packaging_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Catalog_Packaging_HistoryArgs = {
  where: Catalog_Packaging_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Catalog_Packaging_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CommentArgs = {
  where: Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comment_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Comment_HistoryArgs = {
  where: Comment_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comment_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Comment_TypeArgs = {
  where: Comment_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comment_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CommissionArgs = {
  where: Commission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Commission_By_PkArgs = {
  type: Commission_Type_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Commission_TypeArgs = {
  where: Commission_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Commission_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Company_PhotoArgs = {
  where: Company_Photo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Photo_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ContractArgs = {
  where: Contract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contract_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Contract_HistoryArgs = {
  where: Contract_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contract_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Contract_StatusArgs = {
  where: Contract_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contract_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Delivery_ZoneArgs = {
  where: Delivery_Zone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Delivery_Zone_Administrative_AreaArgs = {
  where: Delivery_Zone_Administrative_Area_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Delivery_Zone_Administrative_Area_By_PkArgs = {
  area: Scalars['String']['input'];
  country_code: Scalars['String']['input'];
  delivery_zone_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Delivery_Zone_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DocumentArgs = {
  where: Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Document_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Document_TypeArgs = {
  where: Document_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Document_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Duration_TypeArgs = {
  where: Duration_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Duration_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Global_Product_FamilyArgs = {
  where: Global_Product_Family_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Global_Product_Family_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Gtin_TypeArgs = {
  where: Gtin_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gtin_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceArgs = {
  where: Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Invoice_TypeArgs = {
  where: Invoice_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Measurement_UnitArgs = {
  where: Measurement_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Measurement_Unit_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Non_Reconciliated_MoneyinArgs = {
  where: Non_Reconciliated_Moneyin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Non_Reconciliated_Moneyin_By_PkArgs = {
  id: Scalars['numeric']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_Business_ProfileArgs = {
  where: Notification_Business_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Business_Profile_By_PkArgs = {
  business_profile: Business_Profile_Enum;
  code: Notification_Code_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Notification_CodeArgs = {
  where: Notification_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Code_By_PkArgs = {
  code: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_DefaultArgs = {
  where: Notification_Default_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Default_By_PkArgs = {
  code: Notification_Code_Enum;
  recipient: Notification_Recipient_Enum;
  scope: Notification_Scope_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Notification_MethodArgs = {
  where: Notification_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Method_By_PkArgs = {
  code: Notification_Code_Enum;
  user_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_RecipientArgs = {
  where: Notification_Recipient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Recipient_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_ScopeArgs = {
  where: Notification_Scope_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Scope_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_SubscriptionArgs = {
  where: Notification_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Subscription_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OrderArgs = {
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Order_HistoryArgs = {
  where: Order_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Packaging_QuantityArgs = {
  where: Order_Packaging_Quantity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Packaging_Quantity_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Order_StatusArgs = {
  where: Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PackagingArgs = {
  where: Packaging_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Packaging_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Packaging_HistoryArgs = {
  where: Packaging_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Packaging_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_MethodArgs = {
  where: Payment_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Method_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_Process_StatusArgs = {
  where: Payment_Process_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Process_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PhotoArgs = {
  where: Photo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Photo_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Privacy_SettingArgs = {
  where: Privacy_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Privacy_Setting_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Product_FamilyArgs = {
  where: Product_Family_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Family_By_PkArgs = {
  name: Product_Family_Name_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Product_Family_NameArgs = {
  where: Product_Family_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Family_Name_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Product_HistoryArgs = {
  where: Product_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Product_Label_NameArgs = {
  where: Product_Label_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Label_Name_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Product_Product_Labels_Product_LabelArgs = {
  where: Product_Product_Labels_Product_Label_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Product_Labels_Product_Label_By_PkArgs = {
  label: Product_Label_Name_Enum;
  product_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Product_Sub_FamilyArgs = {
  where: Product_Sub_Family_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Sub_Family_By_PkArgs = {
  name: Product_Sub_Family_Name_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Product_Sub_Family_NameArgs = {
  where: Product_Sub_Family_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Sub_Family_Name_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Recommended_SupplierArgs = {
  where: Recommended_Supplier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recommended_Supplier_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Refresh_TokenArgs = {
  where: Refresh_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Refresh_Token_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_AssignmentArgs = {
  where: Role_Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_Assignment_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Staff_RoleArgs = {
  where: Staff_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staff_Role_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Staff_UserArgs = {
  where: Staff_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staff_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Trade_Item_Unit_DescriptorArgs = {
  where: Trade_Item_Unit_Descriptor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trade_Item_Unit_Descriptor_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Product_FamilyArgs = {
  where: User_Product_Family_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Product_Family_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootInsertOrdersActionArgs = {
  basketId: Scalars['uuid']['input'];
  isSuggested?: InputMaybe<Scalars['Boolean']['input']>;
  ordersData: Array<CustomOrdersInsert>;
  userId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootInsert_Act_Hi_ProcinstArgs = {
  objects: Array<Act_Hi_Procinst_Insert_Input>;
  on_conflict?: InputMaybe<Act_Hi_Procinst_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Act_Hi_Procinst_OneArgs = {
  object: Act_Hi_Procinst_Insert_Input;
  on_conflict?: InputMaybe<Act_Hi_Procinst_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Act_Hi_TaskinstArgs = {
  objects: Array<Act_Hi_Taskinst_Insert_Input>;
  on_conflict?: InputMaybe<Act_Hi_Taskinst_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Act_Hi_Taskinst_OneArgs = {
  object: Act_Hi_Taskinst_Insert_Input;
  on_conflict?: InputMaybe<Act_Hi_Taskinst_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Act_Ru_Event_SubscrArgs = {
  objects: Array<Act_Ru_Event_Subscr_Insert_Input>;
  on_conflict?: InputMaybe<Act_Ru_Event_Subscr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Act_Ru_Event_Subscr_OneArgs = {
  object: Act_Ru_Event_Subscr_Insert_Input;
  on_conflict?: InputMaybe<Act_Ru_Event_Subscr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Administrative_AreaArgs = {
  objects: Array<Administrative_Area_Insert_Input>;
  on_conflict?: InputMaybe<Administrative_Area_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Administrative_Area_OneArgs = {
  object: Administrative_Area_Insert_Input;
  on_conflict?: InputMaybe<Administrative_Area_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Authentication_CodeArgs = {
  objects: Array<Authentication_Code_Insert_Input>;
  on_conflict?: InputMaybe<Authentication_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Authentication_Code_OneArgs = {
  object: Authentication_Code_Insert_Input;
  on_conflict?: InputMaybe<Authentication_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Authentication_Verification_TypeArgs = {
  objects: Array<Authentication_Verification_Type_Insert_Input>;
  on_conflict?: InputMaybe<Authentication_Verification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Authentication_Verification_Type_OneArgs = {
  object: Authentication_Verification_Type_Insert_Input;
  on_conflict?: InputMaybe<Authentication_Verification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Availability_StatusArgs = {
  objects: Array<Availability_Status_Insert_Input>;
  on_conflict?: InputMaybe<Availability_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Availability_Status_OneArgs = {
  object: Availability_Status_Insert_Input;
  on_conflict?: InputMaybe<Availability_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_UnitArgs = {
  objects: Array<Base_Unit_Insert_Input>;
  on_conflict?: InputMaybe<Base_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Unit_HistoryArgs = {
  objects: Array<Base_Unit_History_Insert_Input>;
  on_conflict?: InputMaybe<Base_Unit_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Unit_History_OneArgs = {
  object: Base_Unit_History_Insert_Input;
  on_conflict?: InputMaybe<Base_Unit_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Unit_OneArgs = {
  object: Base_Unit_Insert_Input;
  on_conflict?: InputMaybe<Base_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Unit_PhotoArgs = {
  objects: Array<Base_Unit_Photo_Insert_Input>;
  on_conflict?: InputMaybe<Base_Unit_Photo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Unit_Photo_HistoryArgs = {
  objects: Array<Base_Unit_Photo_History_Insert_Input>;
  on_conflict?: InputMaybe<Base_Unit_Photo_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Unit_Photo_History_OneArgs = {
  object: Base_Unit_Photo_History_Insert_Input;
  on_conflict?: InputMaybe<Base_Unit_Photo_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Unit_Photo_OneArgs = {
  object: Base_Unit_Photo_Insert_Input;
  on_conflict?: InputMaybe<Base_Unit_Photo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Unit_TypeArgs = {
  objects: Array<Base_Unit_Type_Insert_Input>;
  on_conflict?: InputMaybe<Base_Unit_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Unit_Type_OneArgs = {
  object: Base_Unit_Type_Insert_Input;
  on_conflict?: InputMaybe<Base_Unit_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BasketArgs = {
  objects: Array<Basket_Insert_Input>;
  on_conflict?: InputMaybe<Basket_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Basket_OneArgs = {
  object: Basket_Insert_Input;
  on_conflict?: InputMaybe<Basket_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Basket_Packaging_QuantityArgs = {
  objects: Array<Basket_Packaging_Quantity_Insert_Input>;
  on_conflict?: InputMaybe<Basket_Packaging_Quantity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Basket_Packaging_Quantity_OneArgs = {
  object: Basket_Packaging_Quantity_Insert_Input;
  on_conflict?: InputMaybe<Basket_Packaging_Quantity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Basket_StatusArgs = {
  objects: Array<Basket_Status_Insert_Input>;
  on_conflict?: InputMaybe<Basket_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Basket_Status_OneArgs = {
  object: Basket_Status_Insert_Input;
  on_conflict?: InputMaybe<Basket_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_TypeArgs = {
  objects: Array<Billing_Type_Insert_Input>;
  on_conflict?: InputMaybe<Billing_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Type_OneArgs = {
  object: Billing_Type_Insert_Input;
  on_conflict?: InputMaybe<Billing_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BrandArgs = {
  objects: Array<Brand_Insert_Input>;
  on_conflict?: InputMaybe<Brand_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Brand_OneArgs = {
  object: Brand_Insert_Input;
  on_conflict?: InputMaybe<Brand_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Business_ProfileArgs = {
  objects: Array<Business_Profile_Insert_Input>;
  on_conflict?: InputMaybe<Business_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Business_Profile_OneArgs = {
  object: Business_Profile_Insert_Input;
  on_conflict?: InputMaybe<Business_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CatalogArgs = {
  objects: Array<Catalog_Insert_Input>;
  on_conflict?: InputMaybe<Catalog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Catalog_OneArgs = {
  object: Catalog_Insert_Input;
  on_conflict?: InputMaybe<Catalog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Catalog_PackagingArgs = {
  objects: Array<Catalog_Packaging_Insert_Input>;
  on_conflict?: InputMaybe<Catalog_Packaging_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Catalog_Packaging_HistoryArgs = {
  objects: Array<Catalog_Packaging_History_Insert_Input>;
  on_conflict?: InputMaybe<Catalog_Packaging_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Catalog_Packaging_History_OneArgs = {
  object: Catalog_Packaging_History_Insert_Input;
  on_conflict?: InputMaybe<Catalog_Packaging_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Catalog_Packaging_OneArgs = {
  object: Catalog_Packaging_Insert_Input;
  on_conflict?: InputMaybe<Catalog_Packaging_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommentArgs = {
  objects: Array<Comment_Insert_Input>;
  on_conflict?: InputMaybe<Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_HistoryArgs = {
  objects: Array<Comment_History_Insert_Input>;
  on_conflict?: InputMaybe<Comment_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_History_OneArgs = {
  object: Comment_History_Insert_Input;
  on_conflict?: InputMaybe<Comment_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_OneArgs = {
  object: Comment_Insert_Input;
  on_conflict?: InputMaybe<Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_TypeArgs = {
  objects: Array<Comment_Type_Insert_Input>;
  on_conflict?: InputMaybe<Comment_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_Type_OneArgs = {
  object: Comment_Type_Insert_Input;
  on_conflict?: InputMaybe<Comment_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommissionArgs = {
  objects: Array<Commission_Insert_Input>;
  on_conflict?: InputMaybe<Commission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_OneArgs = {
  object: Commission_Insert_Input;
  on_conflict?: InputMaybe<Commission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_TypeArgs = {
  objects: Array<Commission_Type_Insert_Input>;
  on_conflict?: InputMaybe<Commission_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_Type_OneArgs = {
  object: Commission_Type_Insert_Input;
  on_conflict?: InputMaybe<Commission_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_PhotoArgs = {
  objects: Array<Company_Photo_Insert_Input>;
  on_conflict?: InputMaybe<Company_Photo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Photo_OneArgs = {
  object: Company_Photo_Insert_Input;
  on_conflict?: InputMaybe<Company_Photo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractArgs = {
  objects: Array<Contract_Insert_Input>;
  on_conflict?: InputMaybe<Contract_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contract_HistoryArgs = {
  objects: Array<Contract_History_Insert_Input>;
  on_conflict?: InputMaybe<Contract_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contract_History_OneArgs = {
  object: Contract_History_Insert_Input;
  on_conflict?: InputMaybe<Contract_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contract_OneArgs = {
  object: Contract_Insert_Input;
  on_conflict?: InputMaybe<Contract_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contract_StatusArgs = {
  objects: Array<Contract_Status_Insert_Input>;
  on_conflict?: InputMaybe<Contract_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contract_Status_OneArgs = {
  object: Contract_Status_Insert_Input;
  on_conflict?: InputMaybe<Contract_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Delivery_ZoneArgs = {
  objects: Array<Delivery_Zone_Insert_Input>;
  on_conflict?: InputMaybe<Delivery_Zone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Delivery_Zone_Administrative_AreaArgs = {
  objects: Array<Delivery_Zone_Administrative_Area_Insert_Input>;
  on_conflict?: InputMaybe<Delivery_Zone_Administrative_Area_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Delivery_Zone_Administrative_Area_OneArgs = {
  object: Delivery_Zone_Administrative_Area_Insert_Input;
  on_conflict?: InputMaybe<Delivery_Zone_Administrative_Area_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Delivery_Zone_OneArgs = {
  object: Delivery_Zone_Insert_Input;
  on_conflict?: InputMaybe<Delivery_Zone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DocumentArgs = {
  objects: Array<Document_Insert_Input>;
  on_conflict?: InputMaybe<Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_OneArgs = {
  object: Document_Insert_Input;
  on_conflict?: InputMaybe<Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_TypeArgs = {
  objects: Array<Document_Type_Insert_Input>;
  on_conflict?: InputMaybe<Document_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_Type_OneArgs = {
  object: Document_Type_Insert_Input;
  on_conflict?: InputMaybe<Document_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Duration_TypeArgs = {
  objects: Array<Duration_Type_Insert_Input>;
  on_conflict?: InputMaybe<Duration_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Duration_Type_OneArgs = {
  object: Duration_Type_Insert_Input;
  on_conflict?: InputMaybe<Duration_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Global_Product_FamilyArgs = {
  objects: Array<Global_Product_Family_Insert_Input>;
  on_conflict?: InputMaybe<Global_Product_Family_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Global_Product_Family_OneArgs = {
  object: Global_Product_Family_Insert_Input;
  on_conflict?: InputMaybe<Global_Product_Family_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gtin_TypeArgs = {
  objects: Array<Gtin_Type_Insert_Input>;
  on_conflict?: InputMaybe<Gtin_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gtin_Type_OneArgs = {
  object: Gtin_Type_Insert_Input;
  on_conflict?: InputMaybe<Gtin_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceArgs = {
  objects: Array<Invoice_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_OneArgs = {
  object: Invoice_Insert_Input;
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_TypeArgs = {
  objects: Array<Invoice_Type_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_Type_OneArgs = {
  object: Invoice_Type_Insert_Input;
  on_conflict?: InputMaybe<Invoice_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_UnitArgs = {
  objects: Array<Measurement_Unit_Insert_Input>;
  on_conflict?: InputMaybe<Measurement_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_Unit_OneArgs = {
  object: Measurement_Unit_Insert_Input;
  on_conflict?: InputMaybe<Measurement_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Non_Reconciliated_MoneyinArgs = {
  objects: Array<Non_Reconciliated_Moneyin_Insert_Input>;
  on_conflict?: InputMaybe<Non_Reconciliated_Moneyin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Non_Reconciliated_Moneyin_OneArgs = {
  object: Non_Reconciliated_Moneyin_Insert_Input;
  on_conflict?: InputMaybe<Non_Reconciliated_Moneyin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Business_ProfileArgs = {
  objects: Array<Notification_Business_Profile_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Business_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Business_Profile_OneArgs = {
  object: Notification_Business_Profile_Insert_Input;
  on_conflict?: InputMaybe<Notification_Business_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_CodeArgs = {
  objects: Array<Notification_Code_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Code_OneArgs = {
  object: Notification_Code_Insert_Input;
  on_conflict?: InputMaybe<Notification_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_DefaultArgs = {
  objects: Array<Notification_Default_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Default_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Default_OneArgs = {
  object: Notification_Default_Insert_Input;
  on_conflict?: InputMaybe<Notification_Default_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_MethodArgs = {
  objects: Array<Notification_Method_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Method_OneArgs = {
  object: Notification_Method_Insert_Input;
  on_conflict?: InputMaybe<Notification_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_RecipientArgs = {
  objects: Array<Notification_Recipient_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Recipient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Recipient_OneArgs = {
  object: Notification_Recipient_Insert_Input;
  on_conflict?: InputMaybe<Notification_Recipient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_ScopeArgs = {
  objects: Array<Notification_Scope_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Scope_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Scope_OneArgs = {
  object: Notification_Scope_Insert_Input;
  on_conflict?: InputMaybe<Notification_Scope_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_SubscriptionArgs = {
  objects: Array<Notification_Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Subscription_OneArgs = {
  object: Notification_Subscription_Insert_Input;
  on_conflict?: InputMaybe<Notification_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrderArgs = {
  objects: Array<Order_Insert_Input>;
  on_conflict?: InputMaybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_HistoryArgs = {
  objects: Array<Order_History_Insert_Input>;
  on_conflict?: InputMaybe<Order_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_History_OneArgs = {
  object: Order_History_Insert_Input;
  on_conflict?: InputMaybe<Order_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_OneArgs = {
  object: Order_Insert_Input;
  on_conflict?: InputMaybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Packaging_QuantityArgs = {
  objects: Array<Order_Packaging_Quantity_Insert_Input>;
  on_conflict?: InputMaybe<Order_Packaging_Quantity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Packaging_Quantity_OneArgs = {
  object: Order_Packaging_Quantity_Insert_Input;
  on_conflict?: InputMaybe<Order_Packaging_Quantity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_StatusArgs = {
  objects: Array<Order_Status_Insert_Input>;
  on_conflict?: InputMaybe<Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Status_OneArgs = {
  object: Order_Status_Insert_Input;
  on_conflict?: InputMaybe<Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PackagingArgs = {
  objects: Array<Packaging_Insert_Input>;
  on_conflict?: InputMaybe<Packaging_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Packaging_HistoryArgs = {
  objects: Array<Packaging_History_Insert_Input>;
  on_conflict?: InputMaybe<Packaging_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Packaging_History_OneArgs = {
  object: Packaging_History_Insert_Input;
  on_conflict?: InputMaybe<Packaging_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Packaging_OneArgs = {
  object: Packaging_Insert_Input;
  on_conflict?: InputMaybe<Packaging_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_MethodArgs = {
  objects: Array<Payment_Method_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Method_OneArgs = {
  object: Payment_Method_Insert_Input;
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Process_StatusArgs = {
  objects: Array<Payment_Process_Status_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Process_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Process_Status_OneArgs = {
  object: Payment_Process_Status_Insert_Input;
  on_conflict?: InputMaybe<Payment_Process_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PhotoArgs = {
  objects: Array<Photo_Insert_Input>;
  on_conflict?: InputMaybe<Photo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Photo_OneArgs = {
  object: Photo_Insert_Input;
  on_conflict?: InputMaybe<Photo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Privacy_SettingArgs = {
  objects: Array<Privacy_Setting_Insert_Input>;
  on_conflict?: InputMaybe<Privacy_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Privacy_Setting_OneArgs = {
  object: Privacy_Setting_Insert_Input;
  on_conflict?: InputMaybe<Privacy_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_FamilyArgs = {
  objects: Array<Product_Family_Insert_Input>;
  on_conflict?: InputMaybe<Product_Family_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Family_NameArgs = {
  objects: Array<Product_Family_Name_Insert_Input>;
  on_conflict?: InputMaybe<Product_Family_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Family_Name_OneArgs = {
  object: Product_Family_Name_Insert_Input;
  on_conflict?: InputMaybe<Product_Family_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Family_OneArgs = {
  object: Product_Family_Insert_Input;
  on_conflict?: InputMaybe<Product_Family_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_HistoryArgs = {
  objects: Array<Product_History_Insert_Input>;
  on_conflict?: InputMaybe<Product_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_History_OneArgs = {
  object: Product_History_Insert_Input;
  on_conflict?: InputMaybe<Product_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Label_NameArgs = {
  objects: Array<Product_Label_Name_Insert_Input>;
  on_conflict?: InputMaybe<Product_Label_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Label_Name_OneArgs = {
  object: Product_Label_Name_Insert_Input;
  on_conflict?: InputMaybe<Product_Label_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Product_Labels_Product_LabelArgs = {
  objects: Array<Product_Product_Labels_Product_Label_Insert_Input>;
  on_conflict?: InputMaybe<Product_Product_Labels_Product_Label_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Product_Labels_Product_Label_OneArgs = {
  object: Product_Product_Labels_Product_Label_Insert_Input;
  on_conflict?: InputMaybe<Product_Product_Labels_Product_Label_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Sub_FamilyArgs = {
  objects: Array<Product_Sub_Family_Insert_Input>;
  on_conflict?: InputMaybe<Product_Sub_Family_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Sub_Family_NameArgs = {
  objects: Array<Product_Sub_Family_Name_Insert_Input>;
  on_conflict?: InputMaybe<Product_Sub_Family_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Sub_Family_Name_OneArgs = {
  object: Product_Sub_Family_Name_Insert_Input;
  on_conflict?: InputMaybe<Product_Sub_Family_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Sub_Family_OneArgs = {
  object: Product_Sub_Family_Insert_Input;
  on_conflict?: InputMaybe<Product_Sub_Family_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommended_SupplierArgs = {
  objects: Array<Recommended_Supplier_Insert_Input>;
  on_conflict?: InputMaybe<Recommended_Supplier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommended_Supplier_OneArgs = {
  object: Recommended_Supplier_Insert_Input;
  on_conflict?: InputMaybe<Recommended_Supplier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refresh_TokenArgs = {
  objects: Array<Refresh_Token_Insert_Input>;
  on_conflict?: InputMaybe<Refresh_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refresh_Token_OneArgs = {
  object: Refresh_Token_Insert_Input;
  on_conflict?: InputMaybe<Refresh_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_AssignmentArgs = {
  objects: Array<Role_Assignment_Insert_Input>;
  on_conflict?: InputMaybe<Role_Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Assignment_OneArgs = {
  object: Role_Assignment_Insert_Input;
  on_conflict?: InputMaybe<Role_Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staff_RoleArgs = {
  objects: Array<Staff_Role_Insert_Input>;
  on_conflict?: InputMaybe<Staff_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staff_Role_OneArgs = {
  object: Staff_Role_Insert_Input;
  on_conflict?: InputMaybe<Staff_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staff_UserArgs = {
  objects: Array<Staff_User_Insert_Input>;
  on_conflict?: InputMaybe<Staff_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staff_User_OneArgs = {
  object: Staff_User_Insert_Input;
  on_conflict?: InputMaybe<Staff_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_Item_Unit_DescriptorArgs = {
  objects: Array<Trade_Item_Unit_Descriptor_Insert_Input>;
  on_conflict?: InputMaybe<Trade_Item_Unit_Descriptor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_Item_Unit_Descriptor_OneArgs = {
  object: Trade_Item_Unit_Descriptor_Insert_Input;
  on_conflict?: InputMaybe<Trade_Item_Unit_Descriptor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Product_FamilyArgs = {
  objects: Array<User_Product_Family_Insert_Input>;
  on_conflict?: InputMaybe<User_Product_Family_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Product_Family_OneArgs = {
  object: User_Product_Family_Insert_Input;
  on_conflict?: InputMaybe<User_Product_Family_On_Conflict>;
};


/** mutation root */
export type Mutation_RootJoinGroupChannelArgs = {
  contractId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootLoginV2Args = {
  token: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootLoginWithAuthCodeArgs = {
  authenticationCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPreLoginArgs = {
  email: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootReconciliateMoneyinArgs = {
  moneyinId: Scalars['Int']['input'];
  orderInvoiceReference: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSendBuyerRepliedToSupplierChatArgs = {
  buyerCompanyId: Scalars['uuid']['input'];
  buyerName: Scalars['String']['input'];
  supplierCompanyId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootStaffGoogleLoginArgs = {
  access_token: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateOrderActionArgs = {
  deliveryDate?: InputMaybe<Scalars['timestamptz']['input']>;
  orderId: Scalars['uuid']['input'];
  packagingQuantity?: InputMaybe<Array<CustomOrderPackagingQuantity>>;
  referenceId: Scalars['uuid']['input'];
  sddTriggerDate?: InputMaybe<Scalars['timestamptz']['input']>;
  taskAction?: InputMaybe<TaskAction>;
  userBusinessProfile?: InputMaybe<BusinessProfile>;
  userCompanyId?: InputMaybe<Scalars['uuid']['input']>;
};


/** mutation root */
export type Mutation_RootUpdatePaymentDateArgs = {
  newDate: Scalars['timestamptz']['input'];
  orderId: Scalars['String']['input'];
  orderReferenceId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdate_Act_Hi_ProcinstArgs = {
  _inc?: InputMaybe<Act_Hi_Procinst_Inc_Input>;
  _set?: InputMaybe<Act_Hi_Procinst_Set_Input>;
  where: Act_Hi_Procinst_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Act_Hi_Procinst_By_PkArgs = {
  _inc?: InputMaybe<Act_Hi_Procinst_Inc_Input>;
  _set?: InputMaybe<Act_Hi_Procinst_Set_Input>;
  pk_columns: Act_Hi_Procinst_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Act_Hi_Procinst_ManyArgs = {
  updates: Array<Act_Hi_Procinst_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Act_Hi_TaskinstArgs = {
  _inc?: InputMaybe<Act_Hi_Taskinst_Inc_Input>;
  _set?: InputMaybe<Act_Hi_Taskinst_Set_Input>;
  where: Act_Hi_Taskinst_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Act_Hi_Taskinst_By_PkArgs = {
  _inc?: InputMaybe<Act_Hi_Taskinst_Inc_Input>;
  _set?: InputMaybe<Act_Hi_Taskinst_Set_Input>;
  pk_columns: Act_Hi_Taskinst_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Act_Hi_Taskinst_ManyArgs = {
  updates: Array<Act_Hi_Taskinst_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Act_Ru_Event_SubscrArgs = {
  _inc?: InputMaybe<Act_Ru_Event_Subscr_Inc_Input>;
  _set?: InputMaybe<Act_Ru_Event_Subscr_Set_Input>;
  where: Act_Ru_Event_Subscr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Act_Ru_Event_Subscr_By_PkArgs = {
  _inc?: InputMaybe<Act_Ru_Event_Subscr_Inc_Input>;
  _set?: InputMaybe<Act_Ru_Event_Subscr_Set_Input>;
  pk_columns: Act_Ru_Event_Subscr_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Act_Ru_Event_Subscr_ManyArgs = {
  updates: Array<Act_Ru_Event_Subscr_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _inc?: InputMaybe<Address_Inc_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _inc?: InputMaybe<Address_Inc_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_ManyArgs = {
  updates: Array<Address_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Administrative_AreaArgs = {
  _inc?: InputMaybe<Administrative_Area_Inc_Input>;
  _set?: InputMaybe<Administrative_Area_Set_Input>;
  where: Administrative_Area_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Administrative_Area_By_PkArgs = {
  _inc?: InputMaybe<Administrative_Area_Inc_Input>;
  _set?: InputMaybe<Administrative_Area_Set_Input>;
  pk_columns: Administrative_Area_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Administrative_Area_ManyArgs = {
  updates: Array<Administrative_Area_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Authentication_CodeArgs = {
  _inc?: InputMaybe<Authentication_Code_Inc_Input>;
  _set?: InputMaybe<Authentication_Code_Set_Input>;
  where: Authentication_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Authentication_Code_By_PkArgs = {
  _inc?: InputMaybe<Authentication_Code_Inc_Input>;
  _set?: InputMaybe<Authentication_Code_Set_Input>;
  pk_columns: Authentication_Code_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Authentication_Code_ManyArgs = {
  updates: Array<Authentication_Code_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Authentication_Verification_TypeArgs = {
  _set?: InputMaybe<Authentication_Verification_Type_Set_Input>;
  where: Authentication_Verification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Authentication_Verification_Type_By_PkArgs = {
  _set?: InputMaybe<Authentication_Verification_Type_Set_Input>;
  pk_columns: Authentication_Verification_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Authentication_Verification_Type_ManyArgs = {
  updates: Array<Authentication_Verification_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Availability_StatusArgs = {
  _set?: InputMaybe<Availability_Status_Set_Input>;
  where: Availability_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Availability_Status_By_PkArgs = {
  _set?: InputMaybe<Availability_Status_Set_Input>;
  pk_columns: Availability_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Availability_Status_ManyArgs = {
  updates: Array<Availability_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_UnitArgs = {
  _inc?: InputMaybe<Base_Unit_Inc_Input>;
  _set?: InputMaybe<Base_Unit_Set_Input>;
  where: Base_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_By_PkArgs = {
  _inc?: InputMaybe<Base_Unit_Inc_Input>;
  _set?: InputMaybe<Base_Unit_Set_Input>;
  pk_columns: Base_Unit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_HistoryArgs = {
  _inc?: InputMaybe<Base_Unit_History_Inc_Input>;
  _set?: InputMaybe<Base_Unit_History_Set_Input>;
  where: Base_Unit_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_History_By_PkArgs = {
  _inc?: InputMaybe<Base_Unit_History_Inc_Input>;
  _set?: InputMaybe<Base_Unit_History_Set_Input>;
  pk_columns: Base_Unit_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_History_ManyArgs = {
  updates: Array<Base_Unit_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_ManyArgs = {
  updates: Array<Base_Unit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_PhotoArgs = {
  _set?: InputMaybe<Base_Unit_Photo_Set_Input>;
  where: Base_Unit_Photo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_Photo_By_PkArgs = {
  _set?: InputMaybe<Base_Unit_Photo_Set_Input>;
  pk_columns: Base_Unit_Photo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_Photo_HistoryArgs = {
  _set?: InputMaybe<Base_Unit_Photo_History_Set_Input>;
  where: Base_Unit_Photo_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_Photo_History_By_PkArgs = {
  _set?: InputMaybe<Base_Unit_Photo_History_Set_Input>;
  pk_columns: Base_Unit_Photo_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_Photo_History_ManyArgs = {
  updates: Array<Base_Unit_Photo_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_Photo_ManyArgs = {
  updates: Array<Base_Unit_Photo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_TypeArgs = {
  _set?: InputMaybe<Base_Unit_Type_Set_Input>;
  where: Base_Unit_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_Type_By_PkArgs = {
  _set?: InputMaybe<Base_Unit_Type_Set_Input>;
  pk_columns: Base_Unit_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Unit_Type_ManyArgs = {
  updates: Array<Base_Unit_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BasketArgs = {
  _set?: InputMaybe<Basket_Set_Input>;
  where: Basket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Basket_By_PkArgs = {
  _set?: InputMaybe<Basket_Set_Input>;
  pk_columns: Basket_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Basket_ManyArgs = {
  updates: Array<Basket_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Basket_Packaging_QuantityArgs = {
  _inc?: InputMaybe<Basket_Packaging_Quantity_Inc_Input>;
  _set?: InputMaybe<Basket_Packaging_Quantity_Set_Input>;
  where: Basket_Packaging_Quantity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Basket_Packaging_Quantity_By_PkArgs = {
  _inc?: InputMaybe<Basket_Packaging_Quantity_Inc_Input>;
  _set?: InputMaybe<Basket_Packaging_Quantity_Set_Input>;
  pk_columns: Basket_Packaging_Quantity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Basket_Packaging_Quantity_ManyArgs = {
  updates: Array<Basket_Packaging_Quantity_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Basket_StatusArgs = {
  _set?: InputMaybe<Basket_Status_Set_Input>;
  where: Basket_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Basket_Status_By_PkArgs = {
  _set?: InputMaybe<Basket_Status_Set_Input>;
  pk_columns: Basket_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Basket_Status_ManyArgs = {
  updates: Array<Basket_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_TypeArgs = {
  _set?: InputMaybe<Billing_Type_Set_Input>;
  where: Billing_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Type_By_PkArgs = {
  _set?: InputMaybe<Billing_Type_Set_Input>;
  pk_columns: Billing_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Type_ManyArgs = {
  updates: Array<Billing_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BrandArgs = {
  _set?: InputMaybe<Brand_Set_Input>;
  where: Brand_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Brand_By_PkArgs = {
  _set?: InputMaybe<Brand_Set_Input>;
  pk_columns: Brand_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Brand_ManyArgs = {
  updates: Array<Brand_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Business_ProfileArgs = {
  _set?: InputMaybe<Business_Profile_Set_Input>;
  where: Business_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Business_Profile_By_PkArgs = {
  _set?: InputMaybe<Business_Profile_Set_Input>;
  pk_columns: Business_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Business_Profile_ManyArgs = {
  updates: Array<Business_Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CatalogArgs = {
  _set?: InputMaybe<Catalog_Set_Input>;
  where: Catalog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_By_PkArgs = {
  _set?: InputMaybe<Catalog_Set_Input>;
  pk_columns: Catalog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_ManyArgs = {
  updates: Array<Catalog_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_PackagingArgs = {
  _inc?: InputMaybe<Catalog_Packaging_Inc_Input>;
  _set?: InputMaybe<Catalog_Packaging_Set_Input>;
  where: Catalog_Packaging_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_Packaging_By_PkArgs = {
  _inc?: InputMaybe<Catalog_Packaging_Inc_Input>;
  _set?: InputMaybe<Catalog_Packaging_Set_Input>;
  pk_columns: Catalog_Packaging_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_Packaging_HistoryArgs = {
  _inc?: InputMaybe<Catalog_Packaging_History_Inc_Input>;
  _set?: InputMaybe<Catalog_Packaging_History_Set_Input>;
  where: Catalog_Packaging_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_Packaging_History_By_PkArgs = {
  _inc?: InputMaybe<Catalog_Packaging_History_Inc_Input>;
  _set?: InputMaybe<Catalog_Packaging_History_Set_Input>;
  pk_columns: Catalog_Packaging_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_Packaging_History_ManyArgs = {
  updates: Array<Catalog_Packaging_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_Packaging_ManyArgs = {
  updates: Array<Catalog_Packaging_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommentArgs = {
  _set?: InputMaybe<Comment_Set_Input>;
  where: Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_By_PkArgs = {
  _set?: InputMaybe<Comment_Set_Input>;
  pk_columns: Comment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_HistoryArgs = {
  _set?: InputMaybe<Comment_History_Set_Input>;
  where: Comment_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_History_By_PkArgs = {
  _set?: InputMaybe<Comment_History_Set_Input>;
  pk_columns: Comment_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_History_ManyArgs = {
  updates: Array<Comment_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_ManyArgs = {
  updates: Array<Comment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_TypeArgs = {
  _set?: InputMaybe<Comment_Type_Set_Input>;
  where: Comment_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Type_By_PkArgs = {
  _set?: InputMaybe<Comment_Type_Set_Input>;
  pk_columns: Comment_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Type_ManyArgs = {
  updates: Array<Comment_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommissionArgs = {
  _inc?: InputMaybe<Commission_Inc_Input>;
  _set?: InputMaybe<Commission_Set_Input>;
  where: Commission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_By_PkArgs = {
  _inc?: InputMaybe<Commission_Inc_Input>;
  _set?: InputMaybe<Commission_Set_Input>;
  pk_columns: Commission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_ManyArgs = {
  updates: Array<Commission_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_TypeArgs = {
  _set?: InputMaybe<Commission_Type_Set_Input>;
  where: Commission_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Type_By_PkArgs = {
  _set?: InputMaybe<Commission_Type_Set_Input>;
  pk_columns: Commission_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Type_ManyArgs = {
  updates: Array<Commission_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_ManyArgs = {
  updates: Array<Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_PhotoArgs = {
  _set?: InputMaybe<Company_Photo_Set_Input>;
  where: Company_Photo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Photo_By_PkArgs = {
  _set?: InputMaybe<Company_Photo_Set_Input>;
  pk_columns: Company_Photo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Photo_ManyArgs = {
  updates: Array<Company_Photo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContractArgs = {
  _inc?: InputMaybe<Contract_Inc_Input>;
  _set?: InputMaybe<Contract_Set_Input>;
  where: Contract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_By_PkArgs = {
  _inc?: InputMaybe<Contract_Inc_Input>;
  _set?: InputMaybe<Contract_Set_Input>;
  pk_columns: Contract_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_HistoryArgs = {
  _inc?: InputMaybe<Contract_History_Inc_Input>;
  _set?: InputMaybe<Contract_History_Set_Input>;
  where: Contract_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_History_By_PkArgs = {
  _inc?: InputMaybe<Contract_History_Inc_Input>;
  _set?: InputMaybe<Contract_History_Set_Input>;
  pk_columns: Contract_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_History_ManyArgs = {
  updates: Array<Contract_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_ManyArgs = {
  updates: Array<Contract_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_StatusArgs = {
  _set?: InputMaybe<Contract_Status_Set_Input>;
  where: Contract_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_Status_By_PkArgs = {
  _set?: InputMaybe<Contract_Status_Set_Input>;
  pk_columns: Contract_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_Status_ManyArgs = {
  updates: Array<Contract_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Delivery_ZoneArgs = {
  _inc?: InputMaybe<Delivery_Zone_Inc_Input>;
  _set?: InputMaybe<Delivery_Zone_Set_Input>;
  where: Delivery_Zone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Delivery_Zone_Administrative_AreaArgs = {
  _set?: InputMaybe<Delivery_Zone_Administrative_Area_Set_Input>;
  where: Delivery_Zone_Administrative_Area_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Delivery_Zone_Administrative_Area_By_PkArgs = {
  _set?: InputMaybe<Delivery_Zone_Administrative_Area_Set_Input>;
  pk_columns: Delivery_Zone_Administrative_Area_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Delivery_Zone_Administrative_Area_ManyArgs = {
  updates: Array<Delivery_Zone_Administrative_Area_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Delivery_Zone_By_PkArgs = {
  _inc?: InputMaybe<Delivery_Zone_Inc_Input>;
  _set?: InputMaybe<Delivery_Zone_Set_Input>;
  pk_columns: Delivery_Zone_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Delivery_Zone_ManyArgs = {
  updates: Array<Delivery_Zone_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DocumentArgs = {
  _set?: InputMaybe<Document_Set_Input>;
  where: Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Document_By_PkArgs = {
  _set?: InputMaybe<Document_Set_Input>;
  pk_columns: Document_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Document_ManyArgs = {
  updates: Array<Document_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Document_TypeArgs = {
  _set?: InputMaybe<Document_Type_Set_Input>;
  where: Document_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Document_Type_By_PkArgs = {
  _set?: InputMaybe<Document_Type_Set_Input>;
  pk_columns: Document_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Document_Type_ManyArgs = {
  updates: Array<Document_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Duration_TypeArgs = {
  _set?: InputMaybe<Duration_Type_Set_Input>;
  where: Duration_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Duration_Type_By_PkArgs = {
  _set?: InputMaybe<Duration_Type_Set_Input>;
  pk_columns: Duration_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Duration_Type_ManyArgs = {
  updates: Array<Duration_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Global_Product_FamilyArgs = {
  _set?: InputMaybe<Global_Product_Family_Set_Input>;
  where: Global_Product_Family_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Global_Product_Family_By_PkArgs = {
  _set?: InputMaybe<Global_Product_Family_Set_Input>;
  pk_columns: Global_Product_Family_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Global_Product_Family_ManyArgs = {
  updates: Array<Global_Product_Family_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gtin_TypeArgs = {
  _set?: InputMaybe<Gtin_Type_Set_Input>;
  where: Gtin_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gtin_Type_By_PkArgs = {
  _set?: InputMaybe<Gtin_Type_Set_Input>;
  pk_columns: Gtin_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gtin_Type_ManyArgs = {
  updates: Array<Gtin_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>;
  _set?: InputMaybe<Invoice_Set_Input>;
  where: Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>;
  _set?: InputMaybe<Invoice_Set_Input>;
  pk_columns: Invoice_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_ManyArgs = {
  updates: Array<Invoice_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_TypeArgs = {
  _set?: InputMaybe<Invoice_Type_Set_Input>;
  where: Invoice_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_Type_By_PkArgs = {
  _set?: InputMaybe<Invoice_Type_Set_Input>;
  pk_columns: Invoice_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_Type_ManyArgs = {
  updates: Array<Invoice_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_UnitArgs = {
  _set?: InputMaybe<Measurement_Unit_Set_Input>;
  where: Measurement_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Unit_By_PkArgs = {
  _set?: InputMaybe<Measurement_Unit_Set_Input>;
  pk_columns: Measurement_Unit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Unit_ManyArgs = {
  updates: Array<Measurement_Unit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Non_Reconciliated_MoneyinArgs = {
  _inc?: InputMaybe<Non_Reconciliated_Moneyin_Inc_Input>;
  _set?: InputMaybe<Non_Reconciliated_Moneyin_Set_Input>;
  where: Non_Reconciliated_Moneyin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Non_Reconciliated_Moneyin_By_PkArgs = {
  _inc?: InputMaybe<Non_Reconciliated_Moneyin_Inc_Input>;
  _set?: InputMaybe<Non_Reconciliated_Moneyin_Set_Input>;
  pk_columns: Non_Reconciliated_Moneyin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Non_Reconciliated_Moneyin_ManyArgs = {
  updates: Array<Non_Reconciliated_Moneyin_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Business_ProfileArgs = {
  _set?: InputMaybe<Notification_Business_Profile_Set_Input>;
  where: Notification_Business_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Business_Profile_By_PkArgs = {
  _set?: InputMaybe<Notification_Business_Profile_Set_Input>;
  pk_columns: Notification_Business_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Business_Profile_ManyArgs = {
  updates: Array<Notification_Business_Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_CodeArgs = {
  _set?: InputMaybe<Notification_Code_Set_Input>;
  where: Notification_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Code_By_PkArgs = {
  _set?: InputMaybe<Notification_Code_Set_Input>;
  pk_columns: Notification_Code_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Code_ManyArgs = {
  updates: Array<Notification_Code_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_DefaultArgs = {
  _set?: InputMaybe<Notification_Default_Set_Input>;
  where: Notification_Default_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Default_By_PkArgs = {
  _set?: InputMaybe<Notification_Default_Set_Input>;
  pk_columns: Notification_Default_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Default_ManyArgs = {
  updates: Array<Notification_Default_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_MethodArgs = {
  _set?: InputMaybe<Notification_Method_Set_Input>;
  where: Notification_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Method_By_PkArgs = {
  _set?: InputMaybe<Notification_Method_Set_Input>;
  pk_columns: Notification_Method_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Method_ManyArgs = {
  updates: Array<Notification_Method_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_RecipientArgs = {
  _set?: InputMaybe<Notification_Recipient_Set_Input>;
  where: Notification_Recipient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Recipient_By_PkArgs = {
  _set?: InputMaybe<Notification_Recipient_Set_Input>;
  pk_columns: Notification_Recipient_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Recipient_ManyArgs = {
  updates: Array<Notification_Recipient_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_ScopeArgs = {
  _set?: InputMaybe<Notification_Scope_Set_Input>;
  where: Notification_Scope_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Scope_By_PkArgs = {
  _set?: InputMaybe<Notification_Scope_Set_Input>;
  pk_columns: Notification_Scope_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Scope_ManyArgs = {
  updates: Array<Notification_Scope_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_SubscriptionArgs = {
  _set?: InputMaybe<Notification_Subscription_Set_Input>;
  where: Notification_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Subscription_By_PkArgs = {
  _set?: InputMaybe<Notification_Subscription_Set_Input>;
  pk_columns: Notification_Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Subscription_ManyArgs = {
  updates: Array<Notification_Subscription_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrderArgs = {
  _inc?: InputMaybe<Order_Inc_Input>;
  _set?: InputMaybe<Order_Set_Input>;
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_By_PkArgs = {
  _inc?: InputMaybe<Order_Inc_Input>;
  _set?: InputMaybe<Order_Set_Input>;
  pk_columns: Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_HistoryArgs = {
  _inc?: InputMaybe<Order_History_Inc_Input>;
  _set?: InputMaybe<Order_History_Set_Input>;
  where: Order_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_History_By_PkArgs = {
  _inc?: InputMaybe<Order_History_Inc_Input>;
  _set?: InputMaybe<Order_History_Set_Input>;
  pk_columns: Order_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_History_ManyArgs = {
  updates: Array<Order_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Order_ManyArgs = {
  updates: Array<Order_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Packaging_QuantityArgs = {
  _inc?: InputMaybe<Order_Packaging_Quantity_Inc_Input>;
  _set?: InputMaybe<Order_Packaging_Quantity_Set_Input>;
  where: Order_Packaging_Quantity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Packaging_Quantity_By_PkArgs = {
  _inc?: InputMaybe<Order_Packaging_Quantity_Inc_Input>;
  _set?: InputMaybe<Order_Packaging_Quantity_Set_Input>;
  pk_columns: Order_Packaging_Quantity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Packaging_Quantity_ManyArgs = {
  updates: Array<Order_Packaging_Quantity_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Order_StatusArgs = {
  _set?: InputMaybe<Order_Status_Set_Input>;
  where: Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Status_By_PkArgs = {
  _set?: InputMaybe<Order_Status_Set_Input>;
  pk_columns: Order_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Status_ManyArgs = {
  updates: Array<Order_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PackagingArgs = {
  _inc?: InputMaybe<Packaging_Inc_Input>;
  _set?: InputMaybe<Packaging_Set_Input>;
  where: Packaging_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Packaging_By_PkArgs = {
  _inc?: InputMaybe<Packaging_Inc_Input>;
  _set?: InputMaybe<Packaging_Set_Input>;
  pk_columns: Packaging_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Packaging_HistoryArgs = {
  _inc?: InputMaybe<Packaging_History_Inc_Input>;
  _set?: InputMaybe<Packaging_History_Set_Input>;
  where: Packaging_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Packaging_History_By_PkArgs = {
  _inc?: InputMaybe<Packaging_History_Inc_Input>;
  _set?: InputMaybe<Packaging_History_Set_Input>;
  pk_columns: Packaging_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Packaging_History_ManyArgs = {
  updates: Array<Packaging_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Packaging_ManyArgs = {
  updates: Array<Packaging_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_MethodArgs = {
  _set?: InputMaybe<Payment_Method_Set_Input>;
  where: Payment_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Method_By_PkArgs = {
  _set?: InputMaybe<Payment_Method_Set_Input>;
  pk_columns: Payment_Method_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Method_ManyArgs = {
  updates: Array<Payment_Method_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Process_StatusArgs = {
  _set?: InputMaybe<Payment_Process_Status_Set_Input>;
  where: Payment_Process_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Process_Status_By_PkArgs = {
  _set?: InputMaybe<Payment_Process_Status_Set_Input>;
  pk_columns: Payment_Process_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Process_Status_ManyArgs = {
  updates: Array<Payment_Process_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PhotoArgs = {
  _set?: InputMaybe<Photo_Set_Input>;
  where: Photo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Photo_By_PkArgs = {
  _set?: InputMaybe<Photo_Set_Input>;
  pk_columns: Photo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Photo_ManyArgs = {
  updates: Array<Photo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Privacy_SettingArgs = {
  _set?: InputMaybe<Privacy_Setting_Set_Input>;
  where: Privacy_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Privacy_Setting_By_PkArgs = {
  _set?: InputMaybe<Privacy_Setting_Set_Input>;
  pk_columns: Privacy_Setting_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Privacy_Setting_ManyArgs = {
  updates: Array<Privacy_Setting_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_FamilyArgs = {
  _set?: InputMaybe<Product_Family_Set_Input>;
  where: Product_Family_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Family_By_PkArgs = {
  _set?: InputMaybe<Product_Family_Set_Input>;
  pk_columns: Product_Family_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Family_ManyArgs = {
  updates: Array<Product_Family_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Family_NameArgs = {
  _set?: InputMaybe<Product_Family_Name_Set_Input>;
  where: Product_Family_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Family_Name_By_PkArgs = {
  _set?: InputMaybe<Product_Family_Name_Set_Input>;
  pk_columns: Product_Family_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Family_Name_ManyArgs = {
  updates: Array<Product_Family_Name_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_HistoryArgs = {
  _inc?: InputMaybe<Product_History_Inc_Input>;
  _set?: InputMaybe<Product_History_Set_Input>;
  where: Product_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_History_By_PkArgs = {
  _inc?: InputMaybe<Product_History_Inc_Input>;
  _set?: InputMaybe<Product_History_Set_Input>;
  pk_columns: Product_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_History_ManyArgs = {
  updates: Array<Product_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Label_NameArgs = {
  _set?: InputMaybe<Product_Label_Name_Set_Input>;
  where: Product_Label_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Label_Name_By_PkArgs = {
  _set?: InputMaybe<Product_Label_Name_Set_Input>;
  pk_columns: Product_Label_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Label_Name_ManyArgs = {
  updates: Array<Product_Label_Name_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_ManyArgs = {
  updates: Array<Product_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Product_Labels_Product_LabelArgs = {
  _set?: InputMaybe<Product_Product_Labels_Product_Label_Set_Input>;
  where: Product_Product_Labels_Product_Label_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Product_Labels_Product_Label_By_PkArgs = {
  _set?: InputMaybe<Product_Product_Labels_Product_Label_Set_Input>;
  pk_columns: Product_Product_Labels_Product_Label_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Product_Labels_Product_Label_ManyArgs = {
  updates: Array<Product_Product_Labels_Product_Label_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Sub_FamilyArgs = {
  _set?: InputMaybe<Product_Sub_Family_Set_Input>;
  where: Product_Sub_Family_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Sub_Family_By_PkArgs = {
  _set?: InputMaybe<Product_Sub_Family_Set_Input>;
  pk_columns: Product_Sub_Family_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Sub_Family_ManyArgs = {
  updates: Array<Product_Sub_Family_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Sub_Family_NameArgs = {
  _set?: InputMaybe<Product_Sub_Family_Name_Set_Input>;
  where: Product_Sub_Family_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Sub_Family_Name_By_PkArgs = {
  _set?: InputMaybe<Product_Sub_Family_Name_Set_Input>;
  pk_columns: Product_Sub_Family_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Sub_Family_Name_ManyArgs = {
  updates: Array<Product_Sub_Family_Name_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recommended_SupplierArgs = {
  _inc?: InputMaybe<Recommended_Supplier_Inc_Input>;
  _set?: InputMaybe<Recommended_Supplier_Set_Input>;
  where: Recommended_Supplier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recommended_Supplier_By_PkArgs = {
  _inc?: InputMaybe<Recommended_Supplier_Inc_Input>;
  _set?: InputMaybe<Recommended_Supplier_Set_Input>;
  pk_columns: Recommended_Supplier_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recommended_Supplier_ManyArgs = {
  updates: Array<Recommended_Supplier_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Refresh_TokenArgs = {
  _set?: InputMaybe<Refresh_Token_Set_Input>;
  where: Refresh_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Refresh_Token_By_PkArgs = {
  _set?: InputMaybe<Refresh_Token_Set_Input>;
  pk_columns: Refresh_Token_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Refresh_Token_ManyArgs = {
  updates: Array<Refresh_Token_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _set?: InputMaybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_AssignmentArgs = {
  _set?: InputMaybe<Role_Assignment_Set_Input>;
  where: Role_Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Assignment_By_PkArgs = {
  _set?: InputMaybe<Role_Assignment_Set_Input>;
  pk_columns: Role_Assignment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Assignment_ManyArgs = {
  updates: Array<Role_Assignment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _set?: InputMaybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staff_RoleArgs = {
  _set?: InputMaybe<Staff_Role_Set_Input>;
  where: Staff_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staff_Role_By_PkArgs = {
  _set?: InputMaybe<Staff_Role_Set_Input>;
  pk_columns: Staff_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staff_Role_ManyArgs = {
  updates: Array<Staff_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staff_UserArgs = {
  _set?: InputMaybe<Staff_User_Set_Input>;
  where: Staff_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staff_User_By_PkArgs = {
  _set?: InputMaybe<Staff_User_Set_Input>;
  pk_columns: Staff_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staff_User_ManyArgs = {
  updates: Array<Staff_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_Item_Unit_DescriptorArgs = {
  _set?: InputMaybe<Trade_Item_Unit_Descriptor_Set_Input>;
  where: Trade_Item_Unit_Descriptor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_Item_Unit_Descriptor_By_PkArgs = {
  _set?: InputMaybe<Trade_Item_Unit_Descriptor_Set_Input>;
  pk_columns: Trade_Item_Unit_Descriptor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_Item_Unit_Descriptor_ManyArgs = {
  updates: Array<Trade_Item_Unit_Descriptor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Product_FamilyArgs = {
  _set?: InputMaybe<User_Product_Family_Set_Input>;
  where: User_Product_Family_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Product_Family_By_PkArgs = {
  _set?: InputMaybe<User_Product_Family_Set_Input>;
  pk_columns: User_Product_Family_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Product_Family_ManyArgs = {
  updates: Array<User_Product_Family_Updates>;
};


/** mutation root */
export type Mutation_RootUpsertDocumentOfOrderArgs = {
  data: UpsertDocumentOfOrderInput;
};


/** mutation root */
export type Mutation_RootVerify_User_Authentication_CodeArgs = {
  args: Verify_User_Authentication_Code_Args;
  distinct_on?: InputMaybe<Array<Authentication_Verification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authentication_Verification_Type_Order_By>>;
  where?: InputMaybe<Authentication_Verification_Type_Bool_Exp>;
};

/** columns and relationships of "non_reconciliated_moneyin" */
export type Non_Reconciliated_Moneyin = {
  __typename?: 'non_reconciliated_moneyin';
  amount: Scalars['numeric']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['numeric']['output'];
  reason?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "non_reconciliated_moneyin" */
export type Non_Reconciliated_Moneyin_Aggregate = {
  __typename?: 'non_reconciliated_moneyin_aggregate';
  aggregate?: Maybe<Non_Reconciliated_Moneyin_Aggregate_Fields>;
  nodes: Array<Non_Reconciliated_Moneyin>;
};

/** aggregate fields of "non_reconciliated_moneyin" */
export type Non_Reconciliated_Moneyin_Aggregate_Fields = {
  __typename?: 'non_reconciliated_moneyin_aggregate_fields';
  avg?: Maybe<Non_Reconciliated_Moneyin_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Non_Reconciliated_Moneyin_Max_Fields>;
  min?: Maybe<Non_Reconciliated_Moneyin_Min_Fields>;
  stddev?: Maybe<Non_Reconciliated_Moneyin_Stddev_Fields>;
  stddev_pop?: Maybe<Non_Reconciliated_Moneyin_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Non_Reconciliated_Moneyin_Stddev_Samp_Fields>;
  sum?: Maybe<Non_Reconciliated_Moneyin_Sum_Fields>;
  var_pop?: Maybe<Non_Reconciliated_Moneyin_Var_Pop_Fields>;
  var_samp?: Maybe<Non_Reconciliated_Moneyin_Var_Samp_Fields>;
  variance?: Maybe<Non_Reconciliated_Moneyin_Variance_Fields>;
};


/** aggregate fields of "non_reconciliated_moneyin" */
export type Non_Reconciliated_Moneyin_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Non_Reconciliated_Moneyin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Non_Reconciliated_Moneyin_Avg_Fields = {
  __typename?: 'non_reconciliated_moneyin_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "non_reconciliated_moneyin". All fields are combined with a logical 'AND'. */
export type Non_Reconciliated_Moneyin_Bool_Exp = {
  _and?: InputMaybe<Array<Non_Reconciliated_Moneyin_Bool_Exp>>;
  _not?: InputMaybe<Non_Reconciliated_Moneyin_Bool_Exp>;
  _or?: InputMaybe<Array<Non_Reconciliated_Moneyin_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Numeric_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "non_reconciliated_moneyin" */
export enum Non_Reconciliated_Moneyin_Constraint {
  /** unique or primary key constraint on columns "id" */
  NonReconciliatedMoneyinPkey = 'non_reconciliated_moneyin_pkey'
}

/** input type for incrementing numeric columns in table "non_reconciliated_moneyin" */
export type Non_Reconciliated_Moneyin_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "non_reconciliated_moneyin" */
export type Non_Reconciliated_Moneyin_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['numeric']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Non_Reconciliated_Moneyin_Max_Fields = {
  __typename?: 'non_reconciliated_moneyin_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['numeric']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Non_Reconciliated_Moneyin_Min_Fields = {
  __typename?: 'non_reconciliated_moneyin_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['numeric']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "non_reconciliated_moneyin" */
export type Non_Reconciliated_Moneyin_Mutation_Response = {
  __typename?: 'non_reconciliated_moneyin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Non_Reconciliated_Moneyin>;
};

/** on_conflict condition type for table "non_reconciliated_moneyin" */
export type Non_Reconciliated_Moneyin_On_Conflict = {
  constraint: Non_Reconciliated_Moneyin_Constraint;
  update_columns?: Array<Non_Reconciliated_Moneyin_Update_Column>;
  where?: InputMaybe<Non_Reconciliated_Moneyin_Bool_Exp>;
};

/** Ordering options when selecting data from "non_reconciliated_moneyin". */
export type Non_Reconciliated_Moneyin_Order_By = {
  amount?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
};

/** primary key columns input for table: non_reconciliated_moneyin */
export type Non_Reconciliated_Moneyin_Pk_Columns_Input = {
  id: Scalars['numeric']['input'];
};

/** select columns of table "non_reconciliated_moneyin" */
export enum Non_Reconciliated_Moneyin_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason'
}

/** input type for updating data in table "non_reconciliated_moneyin" */
export type Non_Reconciliated_Moneyin_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['numeric']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Non_Reconciliated_Moneyin_Stddev_Fields = {
  __typename?: 'non_reconciliated_moneyin_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Non_Reconciliated_Moneyin_Stddev_Pop_Fields = {
  __typename?: 'non_reconciliated_moneyin_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Non_Reconciliated_Moneyin_Stddev_Samp_Fields = {
  __typename?: 'non_reconciliated_moneyin_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "non_reconciliated_moneyin" */
export type Non_Reconciliated_Moneyin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Non_Reconciliated_Moneyin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Non_Reconciliated_Moneyin_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['numeric']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Non_Reconciliated_Moneyin_Sum_Fields = {
  __typename?: 'non_reconciliated_moneyin_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "non_reconciliated_moneyin" */
export enum Non_Reconciliated_Moneyin_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason'
}

export type Non_Reconciliated_Moneyin_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Non_Reconciliated_Moneyin_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Non_Reconciliated_Moneyin_Set_Input>;
  /** filter the rows which have to be updated */
  where: Non_Reconciliated_Moneyin_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Non_Reconciliated_Moneyin_Var_Pop_Fields = {
  __typename?: 'non_reconciliated_moneyin_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Non_Reconciliated_Moneyin_Var_Samp_Fields = {
  __typename?: 'non_reconciliated_moneyin_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Non_Reconciliated_Moneyin_Variance_Fields = {
  __typename?: 'non_reconciliated_moneyin_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "notification_business_profile" */
export type Notification_Business_Profile = {
  __typename?: 'notification_business_profile';
  business_profile: Business_Profile_Enum;
  code: Notification_Code_Enum;
};

/** aggregated selection of "notification_business_profile" */
export type Notification_Business_Profile_Aggregate = {
  __typename?: 'notification_business_profile_aggregate';
  aggregate?: Maybe<Notification_Business_Profile_Aggregate_Fields>;
  nodes: Array<Notification_Business_Profile>;
};

/** aggregate fields of "notification_business_profile" */
export type Notification_Business_Profile_Aggregate_Fields = {
  __typename?: 'notification_business_profile_aggregate_fields';
  count: Scalars['Int']['output'];
};


/** aggregate fields of "notification_business_profile" */
export type Notification_Business_Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Business_Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notification_business_profile". All fields are combined with a logical 'AND'. */
export type Notification_Business_Profile_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Business_Profile_Bool_Exp>>;
  _not?: InputMaybe<Notification_Business_Profile_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Business_Profile_Bool_Exp>>;
  business_profile?: InputMaybe<Business_Profile_Enum_Comparison_Exp>;
  code?: InputMaybe<Notification_Code_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_business_profile" */
export enum Notification_Business_Profile_Constraint {
  /** unique or primary key constraint on columns "code", "business_profile" */
  NotificationBusinessProfilePkey = 'notification_business_profile_pkey'
}

/** input type for inserting data into table "notification_business_profile" */
export type Notification_Business_Profile_Insert_Input = {
  business_profile?: InputMaybe<Business_Profile_Enum>;
  code?: InputMaybe<Notification_Code_Enum>;
};

/** response of any mutation on the table "notification_business_profile" */
export type Notification_Business_Profile_Mutation_Response = {
  __typename?: 'notification_business_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Business_Profile>;
};

/** on_conflict condition type for table "notification_business_profile" */
export type Notification_Business_Profile_On_Conflict = {
  constraint: Notification_Business_Profile_Constraint;
  update_columns?: Array<Notification_Business_Profile_Update_Column>;
  where?: InputMaybe<Notification_Business_Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_business_profile". */
export type Notification_Business_Profile_Order_By = {
  business_profile?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_business_profile */
export type Notification_Business_Profile_Pk_Columns_Input = {
  business_profile: Business_Profile_Enum;
  code: Notification_Code_Enum;
};

/** select columns of table "notification_business_profile" */
export enum Notification_Business_Profile_Select_Column {
  /** column name */
  BusinessProfile = 'business_profile',
  /** column name */
  Code = 'code'
}

/** input type for updating data in table "notification_business_profile" */
export type Notification_Business_Profile_Set_Input = {
  business_profile?: InputMaybe<Business_Profile_Enum>;
  code?: InputMaybe<Notification_Code_Enum>;
};

/** Streaming cursor of the table "notification_business_profile" */
export type Notification_Business_Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Business_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Business_Profile_Stream_Cursor_Value_Input = {
  business_profile?: InputMaybe<Business_Profile_Enum>;
  code?: InputMaybe<Notification_Code_Enum>;
};

/** update columns of table "notification_business_profile" */
export enum Notification_Business_Profile_Update_Column {
  /** column name */
  BusinessProfile = 'business_profile',
  /** column name */
  Code = 'code'
}

export type Notification_Business_Profile_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Business_Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Business_Profile_Bool_Exp;
};

/** columns and relationships of "notification_code" */
export type Notification_Code = {
  __typename?: 'notification_code';
  code: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "notification_code" */
export type Notification_Code_Aggregate = {
  __typename?: 'notification_code_aggregate';
  aggregate?: Maybe<Notification_Code_Aggregate_Fields>;
  nodes: Array<Notification_Code>;
};

/** aggregate fields of "notification_code" */
export type Notification_Code_Aggregate_Fields = {
  __typename?: 'notification_code_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Code_Max_Fields>;
  min?: Maybe<Notification_Code_Min_Fields>;
};


/** aggregate fields of "notification_code" */
export type Notification_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notification_code". All fields are combined with a logical 'AND'. */
export type Notification_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Code_Bool_Exp>>;
  _not?: InputMaybe<Notification_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Code_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_code" */
export enum Notification_Code_Constraint {
  /** unique or primary key constraint on columns "code" */
  NotificationCodePkey = 'notification_code_pkey'
}

export enum Notification_Code_Enum {
  /** A Money-in on Lemonway's wallet couldn't be reconciliated with an order. */
  A100 = 'A100',
  /** We couldn't execute the whole payment process for an order. */
  A101 = 'A101',
  /** SDD request to Lemonway failed */
  A102 = 'A102',
  /** comment created */
  C100 = 'C100',
  /** there are  unread messages in channel */
  Ch100 = 'CH100',
  /** The discount has been updated on a contract */
  Ct100 = 'CT100',
  /** The minimum order value has been updated on the contract */
  Ct101 = 'CT101',
  /** document created */
  D100 = 'D100',
  /** document updated */
  D200 = 'D200',
  /** new order created */
  O100 = 'O100',
  /** new order received */
  O101 = 'O101',
  /** new order suggestion received */
  O102 = 'O102',
  /** Weekly algorithm recommendations for restocking orders */
  O150 = 'O150',
  /** order to be validated by the supplier */
  O200 = 'O200',
  /** Reminder #1 to the supplier to validate the order */
  O210 = 'O210',
  /** Reminder #2 to the supplier to validate the order */
  O211 = 'O211',
  /** Reminder #3 to the supplier to validate the order */
  O212 = 'O212',
  /** Order quantities have been changed by the buyer in the to_be_validated_by_supplier status. */
  O220 = 'O220',
  /** order to be validated by the buyer */
  O300 = 'O300',
  /** Order quantities have been changed by the supplier in the to_be_validated_by_buyer status. */
  O320 = 'O320',
  /** order has been validated */
  O350 = 'O350',
  /** Order has automatically been validated after 1 business day */
  O351 = 'O351',
  /** Order passed by buyer with a suggested delivery time has been validated by supplier with a modification in delivery date or time */
  O352 = 'O352',
  /** order to be delivered by supplier */
  O400 = 'O400',
  /** Order quantities have been changed by the supplier in the to_be_prepared_by_supplier status. */
  O420 = 'O420',
  /** order delivery date update */
  O500 = 'O500',
  /** order auto delivery confirmation */
  O501 = 'O501',
  /** order to be received by buyer */
  O600 = 'O600',
  /** order auto reception confirmation */
  O601 = 'O601',
  /** Order has been in the `to_be_received_by_buyer` state for two days, and will be auto received in 1 day */
  O602 = 'O602',
  /** Order quantities have been changed by the supplier in the `to_be_received_by_buyer` step */
  O603 = 'O603',
  /** Order quantities have been changed by the supplier in the to_be_delivered_by_supplier status. */
  O620 = 'O620',
  /** Order quantities have been changed by the supplier in the to_be_received_by_buyer status. Order still has to be received by buyer. */
  O621 = 'O621',
  /** order to be billed by supplier */
  O700 = 'O700',
  /** order to be paid by buyer */
  O701 = 'O701',
  /** order being paid by buyer for sdd payment */
  O702 = 'O702',
  /** Order must be paid by buyer (order amounts have been modified) */
  O703 = 'O703',
  /** Direct Debit has been planned by Buyer, order will be paid. */
  O704 = 'O704',
  /** order closed */
  O800 = 'O800',
  /** Order has been paid by Kheops and is now closed. */
  O801 = 'O801',
  /** order has been cancelled by buyer */
  O900 = 'O900',
  /** order has been cancelled by supplier */
  O901 = 'O901',
  /** Order has been archived by Kheops */
  O902 = 'O902',
  /** Order suggestion has been archived by Kheops */
  O903 = 'O903',
  /** When a Supplier updates one of his product price, inform Buyers collaborating with Supplier */
  P100 = 'P100',
  /** creation of a new user account */
  U100 = 'U100',
  /** Weekly new suppliers for buyers */
  W100 = 'W100',
  /** Weekly new buyers for suppliers */
  W101 = 'W101'
}

/** Boolean expression to compare columns of type "notification_code_enum". All fields are combined with logical 'AND'. */
export type Notification_Code_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Code_Enum>;
  _in?: InputMaybe<Array<Notification_Code_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Notification_Code_Enum>;
  _nin?: InputMaybe<Array<Notification_Code_Enum>>;
};

/** input type for inserting data into table "notification_code" */
export type Notification_Code_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notification_Code_Max_Fields = {
  __typename?: 'notification_code_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Notification_Code_Min_Fields = {
  __typename?: 'notification_code_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notification_code" */
export type Notification_Code_Mutation_Response = {
  __typename?: 'notification_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Code>;
};

/** on_conflict condition type for table "notification_code" */
export type Notification_Code_On_Conflict = {
  constraint: Notification_Code_Constraint;
  update_columns?: Array<Notification_Code_Update_Column>;
  where?: InputMaybe<Notification_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_code". */
export type Notification_Code_Order_By = {
  code?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_code */
export type Notification_Code_Pk_Columns_Input = {
  code: Scalars['String']['input'];
};

/** select columns of table "notification_code" */
export enum Notification_Code_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Comment = 'comment'
}

/** input type for updating data in table "notification_code" */
export type Notification_Code_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notification_code" */
export type Notification_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Code_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notification_code" */
export enum Notification_Code_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Comment = 'comment'
}

export type Notification_Code_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Code_Bool_Exp;
};

/** columns and relationships of "notification_default" */
export type Notification_Default = {
  __typename?: 'notification_default';
  code: Notification_Code_Enum;
  email: Scalars['Boolean']['output'];
  recipient: Notification_Recipient_Enum;
  scope: Notification_Scope_Enum;
  sms: Scalars['Boolean']['output'];
};

/** aggregated selection of "notification_default" */
export type Notification_Default_Aggregate = {
  __typename?: 'notification_default_aggregate';
  aggregate?: Maybe<Notification_Default_Aggregate_Fields>;
  nodes: Array<Notification_Default>;
};

/** aggregate fields of "notification_default" */
export type Notification_Default_Aggregate_Fields = {
  __typename?: 'notification_default_aggregate_fields';
  count: Scalars['Int']['output'];
};


/** aggregate fields of "notification_default" */
export type Notification_Default_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Default_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notification_default". All fields are combined with a logical 'AND'. */
export type Notification_Default_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Default_Bool_Exp>>;
  _not?: InputMaybe<Notification_Default_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Default_Bool_Exp>>;
  code?: InputMaybe<Notification_Code_Enum_Comparison_Exp>;
  email?: InputMaybe<Boolean_Comparison_Exp>;
  recipient?: InputMaybe<Notification_Recipient_Enum_Comparison_Exp>;
  scope?: InputMaybe<Notification_Scope_Enum_Comparison_Exp>;
  sms?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_default" */
export enum Notification_Default_Constraint {
  /** unique or primary key constraint on columns "scope", "recipient", "code" */
  NotificationDefaultPkey = 'notification_default_pkey'
}

/** input type for inserting data into table "notification_default" */
export type Notification_Default_Insert_Input = {
  code?: InputMaybe<Notification_Code_Enum>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  recipient?: InputMaybe<Notification_Recipient_Enum>;
  scope?: InputMaybe<Notification_Scope_Enum>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** response of any mutation on the table "notification_default" */
export type Notification_Default_Mutation_Response = {
  __typename?: 'notification_default_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Default>;
};

/** on_conflict condition type for table "notification_default" */
export type Notification_Default_On_Conflict = {
  constraint: Notification_Default_Constraint;
  update_columns?: Array<Notification_Default_Update_Column>;
  where?: InputMaybe<Notification_Default_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_default". */
export type Notification_Default_Order_By = {
  code?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  recipient?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  sms?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_default */
export type Notification_Default_Pk_Columns_Input = {
  code: Notification_Code_Enum;
  recipient: Notification_Recipient_Enum;
  scope: Notification_Scope_Enum;
};

/** select columns of table "notification_default" */
export enum Notification_Default_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Email = 'email',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  Scope = 'scope',
  /** column name */
  Sms = 'sms'
}

/** input type for updating data in table "notification_default" */
export type Notification_Default_Set_Input = {
  code?: InputMaybe<Notification_Code_Enum>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  recipient?: InputMaybe<Notification_Recipient_Enum>;
  scope?: InputMaybe<Notification_Scope_Enum>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "notification_default" */
export type Notification_Default_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Default_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Default_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Notification_Code_Enum>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  recipient?: InputMaybe<Notification_Recipient_Enum>;
  scope?: InputMaybe<Notification_Scope_Enum>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "notification_default" */
export enum Notification_Default_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Email = 'email',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  Scope = 'scope',
  /** column name */
  Sms = 'sms'
}

export type Notification_Default_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Default_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Default_Bool_Exp;
};

/** columns and relationships of "notification_method" */
export type Notification_Method = {
  __typename?: 'notification_method';
  code: Notification_Code_Enum;
  created_at: Scalars['timestamptz']['output'];
  email?: Maybe<Scalars['Boolean']['output']>;
  sms?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "notification_method" */
export type Notification_Method_Aggregate = {
  __typename?: 'notification_method_aggregate';
  aggregate?: Maybe<Notification_Method_Aggregate_Fields>;
  nodes: Array<Notification_Method>;
};

/** aggregate fields of "notification_method" */
export type Notification_Method_Aggregate_Fields = {
  __typename?: 'notification_method_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Method_Max_Fields>;
  min?: Maybe<Notification_Method_Min_Fields>;
};


/** aggregate fields of "notification_method" */
export type Notification_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notification_method". All fields are combined with a logical 'AND'. */
export type Notification_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Method_Bool_Exp>>;
  _not?: InputMaybe<Notification_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Method_Bool_Exp>>;
  code?: InputMaybe<Notification_Code_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<Boolean_Comparison_Exp>;
  sms?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_method" */
export enum Notification_Method_Constraint {
  /** unique or primary key constraint on columns "user_id", "code" */
  NotificationMethodPkey = 'notification_method_pkey'
}

/** input type for inserting data into table "notification_method" */
export type Notification_Method_Insert_Input = {
  code?: InputMaybe<Notification_Code_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Notification_Method_Max_Fields = {
  __typename?: 'notification_method_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Notification_Method_Min_Fields = {
  __typename?: 'notification_method_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "notification_method" */
export type Notification_Method_Mutation_Response = {
  __typename?: 'notification_method_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Method>;
};

/** input type for inserting object relation for remote table "notification_method" */
export type Notification_Method_Obj_Rel_Insert_Input = {
  data: Notification_Method_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Method_On_Conflict>;
};

/** on_conflict condition type for table "notification_method" */
export type Notification_Method_On_Conflict = {
  constraint: Notification_Method_Constraint;
  update_columns?: Array<Notification_Method_Update_Column>;
  where?: InputMaybe<Notification_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_method". */
export type Notification_Method_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  sms?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_method */
export type Notification_Method_Pk_Columns_Input = {
  code: Notification_Code_Enum;
  user_id: Scalars['uuid']['input'];
};

/** select columns of table "notification_method" */
export enum Notification_Method_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Sms = 'sms',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "notification_method" */
export type Notification_Method_Set_Input = {
  code?: InputMaybe<Notification_Code_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "notification_method" */
export type Notification_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Method_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Notification_Code_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "notification_method" */
export enum Notification_Method_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Sms = 'sms',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Notification_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Method_Bool_Exp;
};

/** columns and relationships of "notification_recipient" */
export type Notification_Recipient = {
  __typename?: 'notification_recipient';
  name: Scalars['String']['output'];
};

/** aggregated selection of "notification_recipient" */
export type Notification_Recipient_Aggregate = {
  __typename?: 'notification_recipient_aggregate';
  aggregate?: Maybe<Notification_Recipient_Aggregate_Fields>;
  nodes: Array<Notification_Recipient>;
};

/** aggregate fields of "notification_recipient" */
export type Notification_Recipient_Aggregate_Fields = {
  __typename?: 'notification_recipient_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Recipient_Max_Fields>;
  min?: Maybe<Notification_Recipient_Min_Fields>;
};


/** aggregate fields of "notification_recipient" */
export type Notification_Recipient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Recipient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notification_recipient". All fields are combined with a logical 'AND'. */
export type Notification_Recipient_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Recipient_Bool_Exp>>;
  _not?: InputMaybe<Notification_Recipient_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Recipient_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_recipient" */
export enum Notification_Recipient_Constraint {
  /** unique or primary key constraint on columns "name" */
  NotificationRecipientPkey = 'notification_recipient_pkey'
}

export enum Notification_Recipient_Enum {
  BuyersInScope = 'BUYERS_IN_SCOPE',
  BuyingCompanyUsers = 'BUYING_COMPANY_USERS',
  OrderCreator = 'ORDER_CREATOR',
  Self = 'SELF',
  StaffAdmin = 'STAFF_ADMIN',
  SuppliersInScope = 'SUPPLIERS_IN_SCOPE',
  SupplyingCompanyUsers = 'SUPPLYING_COMPANY_USERS'
}

/** Boolean expression to compare columns of type "notification_recipient_enum". All fields are combined with logical 'AND'. */
export type Notification_Recipient_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Recipient_Enum>;
  _in?: InputMaybe<Array<Notification_Recipient_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Notification_Recipient_Enum>;
  _nin?: InputMaybe<Array<Notification_Recipient_Enum>>;
};

/** input type for inserting data into table "notification_recipient" */
export type Notification_Recipient_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notification_Recipient_Max_Fields = {
  __typename?: 'notification_recipient_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Notification_Recipient_Min_Fields = {
  __typename?: 'notification_recipient_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notification_recipient" */
export type Notification_Recipient_Mutation_Response = {
  __typename?: 'notification_recipient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Recipient>;
};

/** on_conflict condition type for table "notification_recipient" */
export type Notification_Recipient_On_Conflict = {
  constraint: Notification_Recipient_Constraint;
  update_columns?: Array<Notification_Recipient_Update_Column>;
  where?: InputMaybe<Notification_Recipient_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_recipient". */
export type Notification_Recipient_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_recipient */
export type Notification_Recipient_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "notification_recipient" */
export enum Notification_Recipient_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "notification_recipient" */
export type Notification_Recipient_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notification_recipient" */
export type Notification_Recipient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Recipient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Recipient_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notification_recipient" */
export enum Notification_Recipient_Update_Column {
  /** column name */
  Name = 'name'
}

export type Notification_Recipient_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Recipient_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Recipient_Bool_Exp;
};

/** columns and relationships of "notification_scope" */
export type Notification_Scope = {
  __typename?: 'notification_scope';
  name: Scalars['String']['output'];
};

/** aggregated selection of "notification_scope" */
export type Notification_Scope_Aggregate = {
  __typename?: 'notification_scope_aggregate';
  aggregate?: Maybe<Notification_Scope_Aggregate_Fields>;
  nodes: Array<Notification_Scope>;
};

/** aggregate fields of "notification_scope" */
export type Notification_Scope_Aggregate_Fields = {
  __typename?: 'notification_scope_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Scope_Max_Fields>;
  min?: Maybe<Notification_Scope_Min_Fields>;
};


/** aggregate fields of "notification_scope" */
export type Notification_Scope_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Scope_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notification_scope". All fields are combined with a logical 'AND'. */
export type Notification_Scope_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Scope_Bool_Exp>>;
  _not?: InputMaybe<Notification_Scope_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Scope_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_scope" */
export enum Notification_Scope_Constraint {
  /** unique or primary key constraint on columns "name" */
  NotificationScopePkey = 'notification_scope_pkey'
}

export enum Notification_Scope_Enum {
  Admin = 'ADMIN',
  Chat = 'CHAT',
  Contract = 'CONTRACT',
  Order = 'ORDER',
  OrderDocument = 'ORDER_DOCUMENT',
  Product = 'PRODUCT',
  User = 'USER',
  Weekly = 'WEEKLY'
}

/** Boolean expression to compare columns of type "notification_scope_enum". All fields are combined with logical 'AND'. */
export type Notification_Scope_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Scope_Enum>;
  _in?: InputMaybe<Array<Notification_Scope_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Notification_Scope_Enum>;
  _nin?: InputMaybe<Array<Notification_Scope_Enum>>;
};

/** input type for inserting data into table "notification_scope" */
export type Notification_Scope_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notification_Scope_Max_Fields = {
  __typename?: 'notification_scope_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Notification_Scope_Min_Fields = {
  __typename?: 'notification_scope_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notification_scope" */
export type Notification_Scope_Mutation_Response = {
  __typename?: 'notification_scope_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Scope>;
};

/** on_conflict condition type for table "notification_scope" */
export type Notification_Scope_On_Conflict = {
  constraint: Notification_Scope_Constraint;
  update_columns?: Array<Notification_Scope_Update_Column>;
  where?: InputMaybe<Notification_Scope_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_scope". */
export type Notification_Scope_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_scope */
export type Notification_Scope_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "notification_scope" */
export enum Notification_Scope_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "notification_scope" */
export type Notification_Scope_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notification_scope" */
export type Notification_Scope_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Scope_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Scope_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notification_scope" */
export enum Notification_Scope_Update_Column {
  /** column name */
  Name = 'name'
}

export type Notification_Scope_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Scope_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Scope_Bool_Exp;
};

/** columns and relationships of "notification_subscription" */
export type Notification_Subscription = {
  __typename?: 'notification_subscription';
  code?: Maybe<Notification_Code_Enum>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  notification_method?: Maybe<Notification_Method>;
  object_id?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Notification_Scope_Enum>;
  subscribed: Scalars['Boolean']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "notification_subscription" */
export type Notification_Subscription_Aggregate = {
  __typename?: 'notification_subscription_aggregate';
  aggregate?: Maybe<Notification_Subscription_Aggregate_Fields>;
  nodes: Array<Notification_Subscription>;
};

export type Notification_Subscription_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Notification_Subscription_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Notification_Subscription_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Notification_Subscription_Aggregate_Bool_Exp_Count>;
};

export type Notification_Subscription_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notification_Subscription_Select_Column_Notification_Subscription_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notification_Subscription_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notification_Subscription_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notification_Subscription_Select_Column_Notification_Subscription_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notification_Subscription_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notification_Subscription_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notification_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notification_Subscription_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notification_subscription" */
export type Notification_Subscription_Aggregate_Fields = {
  __typename?: 'notification_subscription_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Subscription_Max_Fields>;
  min?: Maybe<Notification_Subscription_Min_Fields>;
};


/** aggregate fields of "notification_subscription" */
export type Notification_Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notification_subscription" */
export type Notification_Subscription_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notification_Subscription_Max_Order_By>;
  min?: InputMaybe<Notification_Subscription_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notification_subscription" */
export type Notification_Subscription_Arr_Rel_Insert_Input = {
  data: Array<Notification_Subscription_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Subscription_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notification_subscription". All fields are combined with a logical 'AND'. */
export type Notification_Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Subscription_Bool_Exp>>;
  _not?: InputMaybe<Notification_Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Subscription_Bool_Exp>>;
  code?: InputMaybe<Notification_Code_Enum_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_method?: InputMaybe<Notification_Method_Bool_Exp>;
  object_id?: InputMaybe<String_Comparison_Exp>;
  scope?: InputMaybe<Notification_Scope_Enum_Comparison_Exp>;
  subscribed?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_subscription" */
export enum Notification_Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationSubscriptionPkey = 'notification_subscription_pkey'
}

/** input type for inserting data into table "notification_subscription" */
export type Notification_Subscription_Insert_Input = {
  code?: InputMaybe<Notification_Code_Enum>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_method?: InputMaybe<Notification_Method_Obj_Rel_Insert_Input>;
  object_id?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Notification_Scope_Enum>;
  subscribed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Notification_Subscription_Max_Fields = {
  __typename?: 'notification_subscription_max_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  object_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "notification_subscription" */
export type Notification_Subscription_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notification_Subscription_Min_Fields = {
  __typename?: 'notification_subscription_min_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  object_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "notification_subscription" */
export type Notification_Subscription_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notification_subscription" */
export type Notification_Subscription_Mutation_Response = {
  __typename?: 'notification_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Subscription>;
};

/** on_conflict condition type for table "notification_subscription" */
export type Notification_Subscription_On_Conflict = {
  constraint: Notification_Subscription_Constraint;
  update_columns?: Array<Notification_Subscription_Update_Column>;
  where?: InputMaybe<Notification_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_subscription". */
export type Notification_Subscription_Order_By = {
  code?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_method?: InputMaybe<Notification_Method_Order_By>;
  object_id?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  subscribed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_subscription */
export type Notification_Subscription_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "notification_subscription" */
export enum Notification_Subscription_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  Scope = 'scope',
  /** column name */
  Subscribed = 'subscribed',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "notification_subscription_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notification_subscription" */
export enum Notification_Subscription_Select_Column_Notification_Subscription_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Subscribed = 'subscribed'
}

/** select "notification_subscription_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notification_subscription" */
export enum Notification_Subscription_Select_Column_Notification_Subscription_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Subscribed = 'subscribed'
}

/** input type for updating data in table "notification_subscription" */
export type Notification_Subscription_Set_Input = {
  code?: InputMaybe<Notification_Code_Enum>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  object_id?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Notification_Scope_Enum>;
  subscribed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "notification_subscription" */
export type Notification_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Subscription_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Notification_Code_Enum>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  object_id?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Notification_Scope_Enum>;
  subscribed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "notification_subscription" */
export enum Notification_Subscription_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  Scope = 'scope',
  /** column name */
  Subscribed = 'subscribed',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Notification_Subscription_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Subscription_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** columns and relationships of "order" */
export type Order = {
  __typename?: 'order';
  basket_id?: Maybe<Scalars['uuid']['output']>;
  cancelled_by?: Maybe<Business_Profile_Enum>;
  /** An array relationship */
  comments: Array<Comment>;
  /** An aggregate relationship */
  comments_aggregate: Comment_Aggregate;
  /** An object relationship */
  contract: Contract_History;
  contractId: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  creator_id?: Maybe<Scalars['uuid']['output']>;
  delivery_date?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  documents: Array<Document>;
  /** An aggregate relationship */
  documents_aggregate: Document_Aggregate;
  entered_status_at?: Maybe<Scalars['timestamptz']['output']>;
  friendly_id: Scalars['String']['output'];
  has_invoice_generation: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  packagingQuantities: Array<Order_Packaging_Quantity>;
  /** An aggregate relationship */
  packagingQuantities_aggregate: Order_Packaging_Quantity_Aggregate;
  payment_method?: Maybe<Payment_Method_Enum>;
  payment_process_status?: Maybe<Payment_Process_Status_Enum>;
  reference_id: Scalars['uuid']['output'];
  sdd_payment_date?: Maybe<Scalars['timestamptz']['output']>;
  sdd_payment_transaction_id?: Maybe<Scalars['numeric']['output']>;
  status: Order_Status_Enum;
  /** An object relationship */
  suggestor?: Maybe<User>;
  suggestor_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  updater?: Maybe<User>;
  /** An object relationship */
  user?: Maybe<User>;
};


/** columns and relationships of "order" */
export type OrderCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderPackagingQuantitiesArgs = {
  distinct_on?: InputMaybe<Array<Order_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderPackagingQuantities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
};

/** aggregated selection of "order" */
export type Order_Aggregate = {
  __typename?: 'order_aggregate';
  aggregate?: Maybe<Order_Aggregate_Fields>;
  nodes: Array<Order>;
};

export type Order_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Order_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Order_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Order_Aggregate_Bool_Exp_Count>;
};

export type Order_Aggregate_Bool_Exp_Bool_And = {
  arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order" */
export type Order_Aggregate_Fields = {
  __typename?: 'order_aggregate_fields';
  avg?: Maybe<Order_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Order_Max_Fields>;
  min?: Maybe<Order_Min_Fields>;
  stddev?: Maybe<Order_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Sum_Fields>;
  var_pop?: Maybe<Order_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Var_Samp_Fields>;
  variance?: Maybe<Order_Variance_Fields>;
};


/** aggregate fields of "order" */
export type Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "order" */
export type Order_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Max_Order_By>;
  min?: InputMaybe<Order_Min_Order_By>;
  stddev?: InputMaybe<Order_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order" */
export type Order_Arr_Rel_Insert_Input = {
  data: Array<Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Avg_Fields = {
  __typename?: 'order_avg_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by avg() on columns of table "order" */
export type Order_Avg_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Bool_Exp>>;
  _not?: InputMaybe<Order_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Bool_Exp>>;
  basket_id?: InputMaybe<Uuid_Comparison_Exp>;
  cancelled_by?: InputMaybe<Business_Profile_Enum_Comparison_Exp>;
  comments?: InputMaybe<Comment_Bool_Exp>;
  comments_aggregate?: InputMaybe<Comment_Aggregate_Bool_Exp>;
  contract?: InputMaybe<Contract_History_Bool_Exp>;
  contractId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  delivery_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  documents?: InputMaybe<Document_Bool_Exp>;
  documents_aggregate?: InputMaybe<Document_Aggregate_Bool_Exp>;
  entered_status_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  friendly_id?: InputMaybe<String_Comparison_Exp>;
  has_invoice_generation?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  packagingQuantities?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
  packagingQuantities_aggregate?: InputMaybe<Order_Packaging_Quantity_Aggregate_Bool_Exp>;
  payment_method?: InputMaybe<Payment_Method_Enum_Comparison_Exp>;
  payment_process_status?: InputMaybe<Payment_Process_Status_Enum_Comparison_Exp>;
  reference_id?: InputMaybe<Uuid_Comparison_Exp>;
  sdd_payment_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  sdd_payment_transaction_id?: InputMaybe<Numeric_Comparison_Exp>;
  status?: InputMaybe<Order_Status_Enum_Comparison_Exp>;
  suggestor?: InputMaybe<User_Bool_Exp>;
  suggestor_id?: InputMaybe<Uuid_Comparison_Exp>;
  supplier_commission_value_excl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  supplier_commission_value_incl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  total_price_excl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  total_price_incl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  total_price_to_pay_excl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  total_price_to_pay_incl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  total_tax?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
  updater?: InputMaybe<User_Bool_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** unique or primary key constraints on table "order" */
export enum Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_1031171c13130102495201e3e20 = 'PK_1031171c13130102495201e3e20',
  /** unique or primary key constraint on columns "basket_id", "contractId" */
  OrderBasketIdContractIdKey = 'order_basket_id_contractId_key',
  /** unique or primary key constraint on columns "friendly_id" */
  OrderFriendlyIdKey = 'order_friendly_id_key',
  /** unique or primary key constraint on columns "reference_id" */
  OrderReferenceIdKey = 'order_reference_id_key'
}

/** columns and relationships of "order_history" */
export type Order_History = {
  __typename?: 'order_history';
  basket_id?: Maybe<Scalars['uuid']['output']>;
  cancelled_by?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  contract?: Maybe<Contract_History>;
  contractId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  creator_id?: Maybe<Scalars['uuid']['output']>;
  delivery_date?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "compute_order_history_entered_status_at" */
  entered_status_at?: Maybe<Scalars['timestamptz']['output']>;
  friendly_id?: Maybe<Scalars['String']['output']>;
  has_invoice_generation: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  order_packaging_quantities: Array<Order_Packaging_Quantity>;
  /** An aggregate relationship */
  order_packaging_quantities_aggregate: Order_Packaging_Quantity_Aggregate;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_process_status?: Maybe<Scalars['String']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  sdd_payment_date?: Maybe<Scalars['timestamptz']['output']>;
  sdd_payment_transaction_id?: Maybe<Scalars['numeric']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  suggestor_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  updater?: Maybe<User>;
};


/** columns and relationships of "order_history" */
export type Order_HistoryOrder_Packaging_QuantitiesArgs = {
  distinct_on?: InputMaybe<Array<Order_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
};


/** columns and relationships of "order_history" */
export type Order_HistoryOrder_Packaging_Quantities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
};

/** aggregated selection of "order_history" */
export type Order_History_Aggregate = {
  __typename?: 'order_history_aggregate';
  aggregate?: Maybe<Order_History_Aggregate_Fields>;
  nodes: Array<Order_History>;
};

export type Order_History_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Order_History_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Order_History_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Order_History_Aggregate_Bool_Exp_Count>;
};

export type Order_History_Aggregate_Bool_Exp_Bool_And = {
  arguments: Order_History_Select_Column_Order_History_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Order_History_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Order_History_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Order_History_Select_Column_Order_History_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Order_History_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Order_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Order_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_history" */
export type Order_History_Aggregate_Fields = {
  __typename?: 'order_history_aggregate_fields';
  avg?: Maybe<Order_History_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Order_History_Max_Fields>;
  min?: Maybe<Order_History_Min_Fields>;
  stddev?: Maybe<Order_History_Stddev_Fields>;
  stddev_pop?: Maybe<Order_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_History_Stddev_Samp_Fields>;
  sum?: Maybe<Order_History_Sum_Fields>;
  var_pop?: Maybe<Order_History_Var_Pop_Fields>;
  var_samp?: Maybe<Order_History_Var_Samp_Fields>;
  variance?: Maybe<Order_History_Variance_Fields>;
};


/** aggregate fields of "order_history" */
export type Order_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "order_history" */
export type Order_History_Aggregate_Order_By = {
  avg?: InputMaybe<Order_History_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_History_Max_Order_By>;
  min?: InputMaybe<Order_History_Min_Order_By>;
  stddev?: InputMaybe<Order_History_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_History_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_History_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_History_Sum_Order_By>;
  var_pop?: InputMaybe<Order_History_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_History_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_history" */
export type Order_History_Arr_Rel_Insert_Input = {
  data: Array<Order_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_History_Avg_Fields = {
  __typename?: 'order_history_avg_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by avg() on columns of table "order_history" */
export type Order_History_Avg_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_history". All fields are combined with a logical 'AND'. */
export type Order_History_Bool_Exp = {
  _and?: InputMaybe<Array<Order_History_Bool_Exp>>;
  _not?: InputMaybe<Order_History_Bool_Exp>;
  _or?: InputMaybe<Array<Order_History_Bool_Exp>>;
  basket_id?: InputMaybe<Uuid_Comparison_Exp>;
  cancelled_by?: InputMaybe<String_Comparison_Exp>;
  contract?: InputMaybe<Contract_History_Bool_Exp>;
  contractId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  delivery_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  entered_status_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  friendly_id?: InputMaybe<String_Comparison_Exp>;
  has_invoice_generation?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order_packaging_quantities?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
  order_packaging_quantities_aggregate?: InputMaybe<Order_Packaging_Quantity_Aggregate_Bool_Exp>;
  payment_method?: InputMaybe<String_Comparison_Exp>;
  payment_process_status?: InputMaybe<String_Comparison_Exp>;
  reference_id?: InputMaybe<Uuid_Comparison_Exp>;
  sdd_payment_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  sdd_payment_transaction_id?: InputMaybe<Numeric_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  suggestor_id?: InputMaybe<Uuid_Comparison_Exp>;
  supplier_commission_value_excl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  total_price_excl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  total_price_incl_tax?: InputMaybe<Numeric_Comparison_Exp>;
  total_tax?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
  updater?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "order_history" */
export enum Order_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderHistoryPkey = 'order_history_pkey',
  /** unique or primary key constraint on columns "id" */
  OrderIdUq = 'order_id_uq'
}

/** input type for incrementing numeric columns in table "order_history" */
export type Order_History_Inc_Input = {
  sdd_payment_transaction_id?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "order_history" */
export type Order_History_Insert_Input = {
  basket_id?: InputMaybe<Scalars['uuid']['input']>;
  cancelled_by?: InputMaybe<Scalars['String']['input']>;
  contract?: InputMaybe<Contract_History_Obj_Rel_Insert_Input>;
  contractId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['uuid']['input']>;
  delivery_date?: InputMaybe<Scalars['timestamptz']['input']>;
  friendly_id?: InputMaybe<Scalars['String']['input']>;
  has_invoice_generation?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  order_packaging_quantities?: InputMaybe<Order_Packaging_Quantity_Arr_Rel_Insert_Input>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  payment_process_status?: InputMaybe<Scalars['String']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  sdd_payment_date?: InputMaybe<Scalars['timestamptz']['input']>;
  sdd_payment_transaction_id?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggestor_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
  updater?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Order_History_Max_Fields = {
  __typename?: 'order_history_max_fields';
  basket_id?: Maybe<Scalars['uuid']['output']>;
  cancelled_by?: Maybe<Scalars['String']['output']>;
  contractId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  creator_id?: Maybe<Scalars['uuid']['output']>;
  delivery_date?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "compute_order_history_entered_status_at" */
  entered_status_at?: Maybe<Scalars['timestamptz']['output']>;
  friendly_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_process_status?: Maybe<Scalars['String']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  sdd_payment_date?: Maybe<Scalars['timestamptz']['output']>;
  sdd_payment_transaction_id?: Maybe<Scalars['numeric']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  suggestor_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "order_history" */
export type Order_History_Max_Order_By = {
  basket_id?: InputMaybe<Order_By>;
  cancelled_by?: InputMaybe<Order_By>;
  contractId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  delivery_date?: InputMaybe<Order_By>;
  friendly_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  payment_process_status?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  sdd_payment_date?: InputMaybe<Order_By>;
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggestor_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_History_Min_Fields = {
  __typename?: 'order_history_min_fields';
  basket_id?: Maybe<Scalars['uuid']['output']>;
  cancelled_by?: Maybe<Scalars['String']['output']>;
  contractId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  creator_id?: Maybe<Scalars['uuid']['output']>;
  delivery_date?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "compute_order_history_entered_status_at" */
  entered_status_at?: Maybe<Scalars['timestamptz']['output']>;
  friendly_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_process_status?: Maybe<Scalars['String']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  sdd_payment_date?: Maybe<Scalars['timestamptz']['output']>;
  sdd_payment_transaction_id?: Maybe<Scalars['numeric']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  suggestor_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "order_history" */
export type Order_History_Min_Order_By = {
  basket_id?: InputMaybe<Order_By>;
  cancelled_by?: InputMaybe<Order_By>;
  contractId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  delivery_date?: InputMaybe<Order_By>;
  friendly_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  payment_process_status?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  sdd_payment_date?: InputMaybe<Order_By>;
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggestor_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_history" */
export type Order_History_Mutation_Response = {
  __typename?: 'order_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_History>;
};

/** input type for inserting object relation for remote table "order_history" */
export type Order_History_Obj_Rel_Insert_Input = {
  data: Order_History_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_History_On_Conflict>;
};

/** on_conflict condition type for table "order_history" */
export type Order_History_On_Conflict = {
  constraint: Order_History_Constraint;
  update_columns?: Array<Order_History_Update_Column>;
  where?: InputMaybe<Order_History_Bool_Exp>;
};

/** Ordering options when selecting data from "order_history". */
export type Order_History_Order_By = {
  basket_id?: InputMaybe<Order_By>;
  cancelled_by?: InputMaybe<Order_By>;
  contract?: InputMaybe<Contract_History_Order_By>;
  contractId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  delivery_date?: InputMaybe<Order_By>;
  entered_status_at?: InputMaybe<Order_By>;
  friendly_id?: InputMaybe<Order_By>;
  has_invoice_generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_packaging_quantities_aggregate?: InputMaybe<Order_Packaging_Quantity_Aggregate_Order_By>;
  payment_method?: InputMaybe<Order_By>;
  payment_process_status?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  sdd_payment_date?: InputMaybe<Order_By>;
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggestor_id?: InputMaybe<Order_By>;
  supplier_commission_value_excl_tax?: InputMaybe<Order_By>;
  total_price_excl_tax?: InputMaybe<Order_By>;
  total_price_incl_tax?: InputMaybe<Order_By>;
  total_tax?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  updater?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: order_history */
export type Order_History_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "order_history" */
export enum Order_History_Select_Column {
  /** column name */
  BasketId = 'basket_id',
  /** column name */
  CancelledBy = 'cancelled_by',
  /** column name */
  ContractId = 'contractId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeliveryDate = 'delivery_date',
  /** column name */
  FriendlyId = 'friendly_id',
  /** column name */
  HasInvoiceGeneration = 'has_invoice_generation',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentProcessStatus = 'payment_process_status',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  SddPaymentDate = 'sdd_payment_date',
  /** column name */
  SddPaymentTransactionId = 'sdd_payment_transaction_id',
  /** column name */
  Status = 'status',
  /** column name */
  SuggestorId = 'suggestor_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "order_history_aggregate_bool_exp_bool_and_arguments_columns" columns of table "order_history" */
export enum Order_History_Select_Column_Order_History_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasInvoiceGeneration = 'has_invoice_generation'
}

/** select "order_history_aggregate_bool_exp_bool_or_arguments_columns" columns of table "order_history" */
export enum Order_History_Select_Column_Order_History_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasInvoiceGeneration = 'has_invoice_generation'
}

/** input type for updating data in table "order_history" */
export type Order_History_Set_Input = {
  basket_id?: InputMaybe<Scalars['uuid']['input']>;
  cancelled_by?: InputMaybe<Scalars['String']['input']>;
  contractId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['uuid']['input']>;
  delivery_date?: InputMaybe<Scalars['timestamptz']['input']>;
  friendly_id?: InputMaybe<Scalars['String']['input']>;
  has_invoice_generation?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  payment_process_status?: InputMaybe<Scalars['String']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  sdd_payment_date?: InputMaybe<Scalars['timestamptz']['input']>;
  sdd_payment_transaction_id?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggestor_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Order_History_Stddev_Fields = {
  __typename?: 'order_history_stddev_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev() on columns of table "order_history" */
export type Order_History_Stddev_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_History_Stddev_Pop_Fields = {
  __typename?: 'order_history_stddev_pop_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev_pop() on columns of table "order_history" */
export type Order_History_Stddev_Pop_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_History_Stddev_Samp_Fields = {
  __typename?: 'order_history_stddev_samp_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev_samp() on columns of table "order_history" */
export type Order_History_Stddev_Samp_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_history" */
export type Order_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_History_Stream_Cursor_Value_Input = {
  basket_id?: InputMaybe<Scalars['uuid']['input']>;
  cancelled_by?: InputMaybe<Scalars['String']['input']>;
  contractId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['uuid']['input']>;
  delivery_date?: InputMaybe<Scalars['timestamptz']['input']>;
  friendly_id?: InputMaybe<Scalars['String']['input']>;
  has_invoice_generation?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  payment_process_status?: InputMaybe<Scalars['String']['input']>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  sdd_payment_date?: InputMaybe<Scalars['timestamptz']['input']>;
  sdd_payment_transaction_id?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suggestor_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Order_History_Sum_Fields = {
  __typename?: 'order_history_sum_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "order_history" */
export type Order_History_Sum_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** update columns of table "order_history" */
export enum Order_History_Update_Column {
  /** column name */
  BasketId = 'basket_id',
  /** column name */
  CancelledBy = 'cancelled_by',
  /** column name */
  ContractId = 'contractId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeliveryDate = 'delivery_date',
  /** column name */
  FriendlyId = 'friendly_id',
  /** column name */
  HasInvoiceGeneration = 'has_invoice_generation',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentProcessStatus = 'payment_process_status',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  SddPaymentDate = 'sdd_payment_date',
  /** column name */
  SddPaymentTransactionId = 'sdd_payment_transaction_id',
  /** column name */
  Status = 'status',
  /** column name */
  SuggestorId = 'suggestor_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Order_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Order_History_Var_Pop_Fields = {
  __typename?: 'order_history_var_pop_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by var_pop() on columns of table "order_history" */
export type Order_History_Var_Pop_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_History_Var_Samp_Fields = {
  __typename?: 'order_history_var_samp_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by var_samp() on columns of table "order_history" */
export type Order_History_Var_Samp_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_History_Variance_Fields = {
  __typename?: 'order_history_variance_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_history_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_history_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by variance() on columns of table "order_history" */
export type Order_History_Variance_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "order" */
export type Order_Inc_Input = {
  sdd_payment_transaction_id?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "order" */
export type Order_Insert_Input = {
  basket_id?: InputMaybe<Scalars['uuid']['input']>;
  cancelled_by?: InputMaybe<Business_Profile_Enum>;
  comments?: InputMaybe<Comment_Arr_Rel_Insert_Input>;
  contract?: InputMaybe<Contract_History_Obj_Rel_Insert_Input>;
  contractId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['uuid']['input']>;
  delivery_date?: InputMaybe<Scalars['timestamptz']['input']>;
  documents?: InputMaybe<Document_Arr_Rel_Insert_Input>;
  friendly_id?: InputMaybe<Scalars['String']['input']>;
  has_invoice_generation?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  packagingQuantities?: InputMaybe<Order_Packaging_Quantity_Arr_Rel_Insert_Input>;
  payment_method?: InputMaybe<Payment_Method_Enum>;
  payment_process_status?: InputMaybe<Payment_Process_Status_Enum>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  sdd_payment_date?: InputMaybe<Scalars['timestamptz']['input']>;
  sdd_payment_transaction_id?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Order_Status_Enum>;
  suggestor?: InputMaybe<User_Obj_Rel_Insert_Input>;
  suggestor_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
  updater?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Order_Max_Fields = {
  __typename?: 'order_max_fields';
  basket_id?: Maybe<Scalars['uuid']['output']>;
  contractId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  creator_id?: Maybe<Scalars['uuid']['output']>;
  delivery_date?: Maybe<Scalars['timestamptz']['output']>;
  entered_status_at?: Maybe<Scalars['timestamptz']['output']>;
  friendly_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  sdd_payment_date?: Maybe<Scalars['timestamptz']['output']>;
  sdd_payment_transaction_id?: Maybe<Scalars['numeric']['output']>;
  suggestor_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "order" */
export type Order_Max_Order_By = {
  basket_id?: InputMaybe<Order_By>;
  contractId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  delivery_date?: InputMaybe<Order_By>;
  friendly_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  sdd_payment_date?: InputMaybe<Order_By>;
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
  suggestor_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Min_Fields = {
  __typename?: 'order_min_fields';
  basket_id?: Maybe<Scalars['uuid']['output']>;
  contractId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  creator_id?: Maybe<Scalars['uuid']['output']>;
  delivery_date?: Maybe<Scalars['timestamptz']['output']>;
  entered_status_at?: Maybe<Scalars['timestamptz']['output']>;
  friendly_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  reference_id?: Maybe<Scalars['uuid']['output']>;
  sdd_payment_date?: Maybe<Scalars['timestamptz']['output']>;
  sdd_payment_transaction_id?: Maybe<Scalars['numeric']['output']>;
  suggestor_id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "order" */
export type Order_Min_Order_By = {
  basket_id?: InputMaybe<Order_By>;
  contractId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  delivery_date?: InputMaybe<Order_By>;
  friendly_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  sdd_payment_date?: InputMaybe<Order_By>;
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
  suggestor_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order" */
export type Order_Mutation_Response = {
  __typename?: 'order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Order>;
};

/** input type for inserting object relation for remote table "order" */
export type Order_Obj_Rel_Insert_Input = {
  data: Order_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_On_Conflict>;
};

/** on_conflict condition type for table "order" */
export type Order_On_Conflict = {
  constraint: Order_Constraint;
  update_columns?: Array<Order_Update_Column>;
  where?: InputMaybe<Order_Bool_Exp>;
};

/** Ordering options when selecting data from "order". */
export type Order_Order_By = {
  basket_id?: InputMaybe<Order_By>;
  cancelled_by?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Comment_Aggregate_Order_By>;
  contract?: InputMaybe<Contract_History_Order_By>;
  contractId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  delivery_date?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Document_Aggregate_Order_By>;
  entered_status_at?: InputMaybe<Order_By>;
  friendly_id?: InputMaybe<Order_By>;
  has_invoice_generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  packagingQuantities_aggregate?: InputMaybe<Order_Packaging_Quantity_Aggregate_Order_By>;
  payment_method?: InputMaybe<Order_By>;
  payment_process_status?: InputMaybe<Order_By>;
  reference_id?: InputMaybe<Order_By>;
  sdd_payment_date?: InputMaybe<Order_By>;
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  suggestor?: InputMaybe<User_Order_By>;
  suggestor_id?: InputMaybe<Order_By>;
  supplier_commission_value_excl_tax?: InputMaybe<Order_By>;
  supplier_commission_value_incl_tax?: InputMaybe<Order_By>;
  total_price_excl_tax?: InputMaybe<Order_By>;
  total_price_incl_tax?: InputMaybe<Order_By>;
  total_price_to_pay_excl_tax?: InputMaybe<Order_By>;
  total_price_to_pay_incl_tax?: InputMaybe<Order_By>;
  total_tax?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  updater?: InputMaybe<User_Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** columns and relationships of "order_packaging_quantity" */
export type Order_Packaging_Quantity = {
  __typename?: 'order_packaging_quantity';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  order: Order_History;
  orderId: Scalars['uuid']['output'];
  /** An object relationship */
  packaging: Packaging_History;
  packagingId: Scalars['uuid']['output'];
  price: Scalars['numeric']['output'];
  quantity: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  weight?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "order_packaging_quantity" */
export type Order_Packaging_Quantity_Aggregate = {
  __typename?: 'order_packaging_quantity_aggregate';
  aggregate?: Maybe<Order_Packaging_Quantity_Aggregate_Fields>;
  nodes: Array<Order_Packaging_Quantity>;
};

export type Order_Packaging_Quantity_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Packaging_Quantity_Aggregate_Bool_Exp_Count>;
};

export type Order_Packaging_Quantity_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Packaging_Quantity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_packaging_quantity" */
export type Order_Packaging_Quantity_Aggregate_Fields = {
  __typename?: 'order_packaging_quantity_aggregate_fields';
  avg?: Maybe<Order_Packaging_Quantity_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Order_Packaging_Quantity_Max_Fields>;
  min?: Maybe<Order_Packaging_Quantity_Min_Fields>;
  stddev?: Maybe<Order_Packaging_Quantity_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Packaging_Quantity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Packaging_Quantity_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Packaging_Quantity_Sum_Fields>;
  var_pop?: Maybe<Order_Packaging_Quantity_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Packaging_Quantity_Var_Samp_Fields>;
  variance?: Maybe<Order_Packaging_Quantity_Variance_Fields>;
};


/** aggregate fields of "order_packaging_quantity" */
export type Order_Packaging_Quantity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Packaging_Quantity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Packaging_Quantity_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Packaging_Quantity_Max_Order_By>;
  min?: InputMaybe<Order_Packaging_Quantity_Min_Order_By>;
  stddev?: InputMaybe<Order_Packaging_Quantity_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Packaging_Quantity_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Packaging_Quantity_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Packaging_Quantity_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Packaging_Quantity_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Packaging_Quantity_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Packaging_Quantity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Arr_Rel_Insert_Input = {
  data: Array<Order_Packaging_Quantity_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Packaging_Quantity_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Packaging_Quantity_Avg_Fields = {
  __typename?: 'order_packaging_quantity_avg_fields';
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_packaging_quantity". All fields are combined with a logical 'AND'. */
export type Order_Packaging_Quantity_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Packaging_Quantity_Bool_Exp>>;
  _not?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Packaging_Quantity_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Order_History_Bool_Exp>;
  orderId?: InputMaybe<Uuid_Comparison_Exp>;
  packaging?: InputMaybe<Packaging_History_Bool_Exp>;
  packagingId?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  weight?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_packaging_quantity" */
export enum Order_Packaging_Quantity_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkEc6b3f7bb5c277c7361c14466e3 = 'PK_ec6b3f7bb5c277c7361c14466e3',
  /** unique or primary key constraint on columns "packagingId", "orderId", "quantity" */
  Uq_8ec0fa89bfa79d63589c5fc5ccd = 'UQ_8ec0fa89bfa79d63589c5fc5ccd'
}

/** input type for incrementing numeric columns in table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  order?: InputMaybe<Order_History_Obj_Rel_Insert_Input>;
  orderId?: InputMaybe<Scalars['uuid']['input']>;
  packaging?: InputMaybe<Packaging_History_Obj_Rel_Insert_Input>;
  packagingId?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Order_Packaging_Quantity_Max_Fields = {
  __typename?: 'order_packaging_quantity_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  orderId?: Maybe<Scalars['uuid']['output']>;
  packagingId?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  weight?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  packagingId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Packaging_Quantity_Min_Fields = {
  __typename?: 'order_packaging_quantity_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  orderId?: Maybe<Scalars['uuid']['output']>;
  packagingId?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  weight?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  packagingId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Mutation_Response = {
  __typename?: 'order_packaging_quantity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Packaging_Quantity>;
};

/** on_conflict condition type for table "order_packaging_quantity" */
export type Order_Packaging_Quantity_On_Conflict = {
  constraint: Order_Packaging_Quantity_Constraint;
  update_columns?: Array<Order_Packaging_Quantity_Update_Column>;
  where?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
};

/** Ordering options when selecting data from "order_packaging_quantity". */
export type Order_Packaging_Quantity_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_History_Order_By>;
  orderId?: InputMaybe<Order_By>;
  packaging?: InputMaybe<Packaging_History_Order_By>;
  packagingId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_packaging_quantity */
export type Order_Packaging_Quantity_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "order_packaging_quantity" */
export enum Order_Packaging_Quantity_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PackagingId = 'packagingId',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weight = 'weight'
}

/** input type for updating data in table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orderId?: InputMaybe<Scalars['uuid']['input']>;
  packagingId?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Order_Packaging_Quantity_Stddev_Fields = {
  __typename?: 'order_packaging_quantity_stddev_fields';
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Packaging_Quantity_Stddev_Pop_Fields = {
  __typename?: 'order_packaging_quantity_stddev_pop_fields';
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Packaging_Quantity_Stddev_Samp_Fields = {
  __typename?: 'order_packaging_quantity_stddev_samp_fields';
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Packaging_Quantity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Packaging_Quantity_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orderId?: InputMaybe<Scalars['uuid']['input']>;
  packagingId?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Order_Packaging_Quantity_Sum_Fields = {
  __typename?: 'order_packaging_quantity_sum_fields';
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** update columns of table "order_packaging_quantity" */
export enum Order_Packaging_Quantity_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PackagingId = 'packagingId',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weight = 'weight'
}

export type Order_Packaging_Quantity_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Packaging_Quantity_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Packaging_Quantity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Packaging_Quantity_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Order_Packaging_Quantity_Var_Pop_Fields = {
  __typename?: 'order_packaging_quantity_var_pop_fields';
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Packaging_Quantity_Var_Samp_Fields = {
  __typename?: 'order_packaging_quantity_var_samp_fields';
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Packaging_Quantity_Variance_Fields = {
  __typename?: 'order_packaging_quantity_variance_fields';
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "order_packaging_quantity" */
export type Order_Packaging_Quantity_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order */
export type Order_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  BasketId = 'basket_id',
  /** column name */
  CancelledBy = 'cancelled_by',
  /** column name */
  ContractId = 'contractId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeliveryDate = 'delivery_date',
  /** column name */
  FriendlyId = 'friendly_id',
  /** column name */
  HasInvoiceGeneration = 'has_invoice_generation',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentProcessStatus = 'payment_process_status',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  SddPaymentDate = 'sdd_payment_date',
  /** column name */
  SddPaymentTransactionId = 'sdd_payment_transaction_id',
  /** column name */
  Status = 'status',
  /** column name */
  SuggestorId = 'suggestor_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "order_aggregate_bool_exp_bool_and_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasInvoiceGeneration = 'has_invoice_generation'
}

/** select "order_aggregate_bool_exp_bool_or_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasInvoiceGeneration = 'has_invoice_generation'
}

/** input type for updating data in table "order" */
export type Order_Set_Input = {
  basket_id?: InputMaybe<Scalars['uuid']['input']>;
  cancelled_by?: InputMaybe<Business_Profile_Enum>;
  contractId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['uuid']['input']>;
  delivery_date?: InputMaybe<Scalars['timestamptz']['input']>;
  friendly_id?: InputMaybe<Scalars['String']['input']>;
  has_invoice_generation?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payment_method?: InputMaybe<Payment_Method_Enum>;
  payment_process_status?: InputMaybe<Payment_Process_Status_Enum>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  sdd_payment_date?: InputMaybe<Scalars['timestamptz']['input']>;
  sdd_payment_transaction_id?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Order_Status_Enum>;
  suggestor_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "order_status" */
export type Order_Status = {
  __typename?: 'order_status';
  value: Scalars['String']['output'];
};

/** aggregated selection of "order_status" */
export type Order_Status_Aggregate = {
  __typename?: 'order_status_aggregate';
  aggregate?: Maybe<Order_Status_Aggregate_Fields>;
  nodes: Array<Order_Status>;
};

/** aggregate fields of "order_status" */
export type Order_Status_Aggregate_Fields = {
  __typename?: 'order_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Order_Status_Max_Fields>;
  min?: Maybe<Order_Status_Min_Fields>;
};


/** aggregate fields of "order_status" */
export type Order_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "order_status". All fields are combined with a logical 'AND'. */
export type Order_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Status_Bool_Exp>>;
  _not?: InputMaybe<Order_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_status" */
export enum Order_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  OrderStatusPkey = 'order_status_pkey'
}

export enum Order_Status_Enum {
  OrderArchived = 'order_archived',
  OrderBeingPaidByBuyer = 'order_being_paid_by_buyer',
  OrderCancelled = 'order_cancelled',
  OrderClosed = 'order_closed',
  OrderToBeBilledBySupplier = 'order_to_be_billed_by_supplier',
  OrderToBeDeliveredBySupplier = 'order_to_be_delivered_by_supplier',
  OrderToBePaidByBuyer = 'order_to_be_paid_by_buyer',
  OrderToBePreparedBySupplier = 'order_to_be_prepared_by_supplier',
  OrderToBeReceivedByBuyer = 'order_to_be_received_by_buyer',
  OrderToBeValidatedByBuyer = 'order_to_be_validated_by_buyer',
  OrderToBeValidatedBySupplier = 'order_to_be_validated_by_supplier'
}

/** Boolean expression to compare columns of type "order_status_enum". All fields are combined with logical 'AND'. */
export type Order_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Order_Status_Enum>;
  _in?: InputMaybe<Array<Order_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Order_Status_Enum>;
  _nin?: InputMaybe<Array<Order_Status_Enum>>;
};

/** input type for inserting data into table "order_status" */
export type Order_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Order_Status_Max_Fields = {
  __typename?: 'order_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Order_Status_Min_Fields = {
  __typename?: 'order_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "order_status" */
export type Order_Status_Mutation_Response = {
  __typename?: 'order_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Status>;
};

/** on_conflict condition type for table "order_status" */
export type Order_Status_On_Conflict = {
  constraint: Order_Status_Constraint;
  update_columns?: Array<Order_Status_Update_Column>;
  where?: InputMaybe<Order_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "order_status". */
export type Order_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_status */
export type Order_Status_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "order_status" */
export enum Order_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "order_status" */
export type Order_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "order_status" */
export type Order_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "order_status" */
export enum Order_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Order_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Order_Stddev_Fields = {
  __typename?: 'order_stddev_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev() on columns of table "order" */
export type Order_Stddev_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Stddev_Pop_Fields = {
  __typename?: 'order_stddev_pop_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev_pop() on columns of table "order" */
export type Order_Stddev_Pop_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Stddev_Samp_Fields = {
  __typename?: 'order_stddev_samp_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev_samp() on columns of table "order" */
export type Order_Stddev_Samp_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order" */
export type Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Stream_Cursor_Value_Input = {
  basket_id?: InputMaybe<Scalars['uuid']['input']>;
  cancelled_by?: InputMaybe<Business_Profile_Enum>;
  contractId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['uuid']['input']>;
  delivery_date?: InputMaybe<Scalars['timestamptz']['input']>;
  friendly_id?: InputMaybe<Scalars['String']['input']>;
  has_invoice_generation?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payment_method?: InputMaybe<Payment_Method_Enum>;
  payment_process_status?: InputMaybe<Payment_Process_Status_Enum>;
  reference_id?: InputMaybe<Scalars['uuid']['input']>;
  sdd_payment_date?: InputMaybe<Scalars['timestamptz']['input']>;
  sdd_payment_transaction_id?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Order_Status_Enum>;
  suggestor_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Order_Sum_Fields = {
  __typename?: 'order_sum_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "order" */
export type Order_Sum_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** update columns of table "order" */
export enum Order_Update_Column {
  /** column name */
  BasketId = 'basket_id',
  /** column name */
  CancelledBy = 'cancelled_by',
  /** column name */
  ContractId = 'contractId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeliveryDate = 'delivery_date',
  /** column name */
  FriendlyId = 'friendly_id',
  /** column name */
  HasInvoiceGeneration = 'has_invoice_generation',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentProcessStatus = 'payment_process_status',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  SddPaymentDate = 'sdd_payment_date',
  /** column name */
  SddPaymentTransactionId = 'sdd_payment_transaction_id',
  /** column name */
  Status = 'status',
  /** column name */
  SuggestorId = 'suggestor_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Order_Var_Pop_Fields = {
  __typename?: 'order_var_pop_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by var_pop() on columns of table "order" */
export type Order_Var_Pop_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Var_Samp_Fields = {
  __typename?: 'order_var_samp_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by var_samp() on columns of table "order" */
export type Order_Var_Samp_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Variance_Fields = {
  __typename?: 'order_variance_fields';
  sdd_payment_transaction_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_excl_tax" */
  supplier_commission_value_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_supplier_commission_value_incl_tax" */
  supplier_commission_value_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_excl_tax" */
  total_price_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_incl_tax" */
  total_price_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_excl_tax" */
  total_price_to_pay_excl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_price_to_pay_incl_tax" */
  total_price_to_pay_incl_tax?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "compute_order_total_tax" */
  total_tax?: Maybe<Scalars['numeric']['output']>;
};

/** order by variance() on columns of table "order" */
export type Order_Variance_Order_By = {
  sdd_payment_transaction_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "packaging" */
export type Packaging = {
  __typename?: 'packaging';
  /** An object relationship */
  base_unit: Base_Unit;
  base_unit_id: Scalars['uuid']['output'];
  /** An object relationship */
  company: Company;
  company_id: Scalars['uuid']['output'];
  content_measurement_unit?: Maybe<Measurement_Unit_Enum>;
  created_date: Scalars['timestamptz']['output'];
  deprecated_baseUnitSku?: Maybe<Scalars['String']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_parentSku?: Maybe<Scalars['String']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  gtin_type?: Maybe<Gtin_Type_Enum>;
  id: Scalars['uuid']['output'];
  net_content?: Maybe<Scalars['numeric']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  price: Scalars['numeric']['output'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['uuid']['output'];
  sku: Scalars['Int']['output'];
  trade_item_unit_descriptor: Trade_Item_Unit_Descriptor_Enum;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "packaging" */
export type Packaging_Aggregate = {
  __typename?: 'packaging_aggregate';
  aggregate?: Maybe<Packaging_Aggregate_Fields>;
  nodes: Array<Packaging>;
};

export type Packaging_Aggregate_Bool_Exp = {
  count?: InputMaybe<Packaging_Aggregate_Bool_Exp_Count>;
};

export type Packaging_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Packaging_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Packaging_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "packaging" */
export type Packaging_Aggregate_Fields = {
  __typename?: 'packaging_aggregate_fields';
  avg?: Maybe<Packaging_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Packaging_Max_Fields>;
  min?: Maybe<Packaging_Min_Fields>;
  stddev?: Maybe<Packaging_Stddev_Fields>;
  stddev_pop?: Maybe<Packaging_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Packaging_Stddev_Samp_Fields>;
  sum?: Maybe<Packaging_Sum_Fields>;
  var_pop?: Maybe<Packaging_Var_Pop_Fields>;
  var_samp?: Maybe<Packaging_Var_Samp_Fields>;
  variance?: Maybe<Packaging_Variance_Fields>;
};


/** aggregate fields of "packaging" */
export type Packaging_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Packaging_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "packaging" */
export type Packaging_Aggregate_Order_By = {
  avg?: InputMaybe<Packaging_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Packaging_Max_Order_By>;
  min?: InputMaybe<Packaging_Min_Order_By>;
  stddev?: InputMaybe<Packaging_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Packaging_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Packaging_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Packaging_Sum_Order_By>;
  var_pop?: InputMaybe<Packaging_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Packaging_Var_Samp_Order_By>;
  variance?: InputMaybe<Packaging_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "packaging" */
export type Packaging_Arr_Rel_Insert_Input = {
  data: Array<Packaging_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Packaging_On_Conflict>;
};

/** aggregate avg on columns */
export type Packaging_Avg_Fields = {
  __typename?: 'packaging_avg_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "packaging" */
export type Packaging_Avg_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "packaging". All fields are combined with a logical 'AND'. */
export type Packaging_Bool_Exp = {
  _and?: InputMaybe<Array<Packaging_Bool_Exp>>;
  _not?: InputMaybe<Packaging_Bool_Exp>;
  _or?: InputMaybe<Array<Packaging_Bool_Exp>>;
  base_unit?: InputMaybe<Base_Unit_Bool_Exp>;
  base_unit_id?: InputMaybe<Uuid_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deprecated_baseUnitSku?: InputMaybe<String_Comparison_Exp>;
  deprecated_default_price_id?: InputMaybe<Uuid_Comparison_Exp>;
  deprecated_parentSku?: InputMaybe<String_Comparison_Exp>;
  deprecated_productSku?: InputMaybe<String_Comparison_Exp>;
  deprecated_sku?: InputMaybe<String_Comparison_Exp>;
  gtin?: InputMaybe<String_Comparison_Exp>;
  gtin_type?: InputMaybe<Gtin_Type_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  net_content?: InputMaybe<Numeric_Comparison_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  sku?: InputMaybe<Int_Comparison_Exp>;
  trade_item_unit_descriptor?: InputMaybe<Trade_Item_Unit_Descriptor_Enum_Comparison_Exp>;
  unit_quantity?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "packaging" */
export enum Packaging_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkC555edac95babf9a092ff829eba = 'PK_c555edac95babf9a092ff829eba',
  /** unique or primary key constraint on columns "gtin" */
  Rel_4008e423352b403348a14107e5 = 'REL_4008e423352b403348a14107e5',
  /** unique or primary key constraint on columns "company_id", "sku" */
  PackagingCompanyIdSkuUnique = 'packaging_company_id_sku_unique'
}

/** columns and relationships of "packaging_history" */
export type Packaging_History = {
  __typename?: 'packaging_history';
  /** An object relationship */
  base_unit?: Maybe<Base_Unit_History>;
  base_unit_id?: Maybe<Scalars['uuid']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  content_measurement_unit?: Maybe<Measurement_Unit_Enum>;
  created_date: Scalars['timestamptz']['output'];
  deprecated_baseUnitSku?: Maybe<Scalars['String']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_parentSku?: Maybe<Scalars['String']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  gtin_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  net_content?: Maybe<Scalars['numeric']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  price: Scalars['numeric']['output'];
  /** An object relationship */
  product?: Maybe<Product_History>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  sku: Scalars['Int']['output'];
  trade_item_unit_descriptor: Trade_Item_Unit_Descriptor_Enum;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "packaging_history" */
export type Packaging_History_Aggregate = {
  __typename?: 'packaging_history_aggregate';
  aggregate?: Maybe<Packaging_History_Aggregate_Fields>;
  nodes: Array<Packaging_History>;
};

export type Packaging_History_Aggregate_Bool_Exp = {
  count?: InputMaybe<Packaging_History_Aggregate_Bool_Exp_Count>;
};

export type Packaging_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Packaging_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Packaging_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "packaging_history" */
export type Packaging_History_Aggregate_Fields = {
  __typename?: 'packaging_history_aggregate_fields';
  avg?: Maybe<Packaging_History_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Packaging_History_Max_Fields>;
  min?: Maybe<Packaging_History_Min_Fields>;
  stddev?: Maybe<Packaging_History_Stddev_Fields>;
  stddev_pop?: Maybe<Packaging_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Packaging_History_Stddev_Samp_Fields>;
  sum?: Maybe<Packaging_History_Sum_Fields>;
  var_pop?: Maybe<Packaging_History_Var_Pop_Fields>;
  var_samp?: Maybe<Packaging_History_Var_Samp_Fields>;
  variance?: Maybe<Packaging_History_Variance_Fields>;
};


/** aggregate fields of "packaging_history" */
export type Packaging_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Packaging_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "packaging_history" */
export type Packaging_History_Aggregate_Order_By = {
  avg?: InputMaybe<Packaging_History_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Packaging_History_Max_Order_By>;
  min?: InputMaybe<Packaging_History_Min_Order_By>;
  stddev?: InputMaybe<Packaging_History_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Packaging_History_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Packaging_History_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Packaging_History_Sum_Order_By>;
  var_pop?: InputMaybe<Packaging_History_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Packaging_History_Var_Samp_Order_By>;
  variance?: InputMaybe<Packaging_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "packaging_history" */
export type Packaging_History_Arr_Rel_Insert_Input = {
  data: Array<Packaging_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Packaging_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Packaging_History_Avg_Fields = {
  __typename?: 'packaging_history_avg_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "packaging_history" */
export type Packaging_History_Avg_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "packaging_history". All fields are combined with a logical 'AND'. */
export type Packaging_History_Bool_Exp = {
  _and?: InputMaybe<Array<Packaging_History_Bool_Exp>>;
  _not?: InputMaybe<Packaging_History_Bool_Exp>;
  _or?: InputMaybe<Array<Packaging_History_Bool_Exp>>;
  base_unit?: InputMaybe<Base_Unit_History_Bool_Exp>;
  base_unit_id?: InputMaybe<Uuid_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deprecated_baseUnitSku?: InputMaybe<String_Comparison_Exp>;
  deprecated_default_price_id?: InputMaybe<Uuid_Comparison_Exp>;
  deprecated_parentSku?: InputMaybe<String_Comparison_Exp>;
  deprecated_productSku?: InputMaybe<String_Comparison_Exp>;
  deprecated_sku?: InputMaybe<String_Comparison_Exp>;
  gtin?: InputMaybe<String_Comparison_Exp>;
  gtin_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  net_content?: InputMaybe<Numeric_Comparison_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  product?: InputMaybe<Product_History_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  sku?: InputMaybe<Int_Comparison_Exp>;
  trade_item_unit_descriptor?: InputMaybe<Trade_Item_Unit_Descriptor_Enum_Comparison_Exp>;
  unit_quantity?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "packaging_history" */
export enum Packaging_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  PackagingHistoryPkey = 'packaging_history_pkey'
}

/** input type for incrementing numeric columns in table "packaging_history" */
export type Packaging_History_Inc_Input = {
  net_content?: InputMaybe<Scalars['numeric']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "packaging_history" */
export type Packaging_History_Insert_Input = {
  base_unit?: InputMaybe<Base_Unit_History_Obj_Rel_Insert_Input>;
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_baseUnitSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_parentSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  net_content?: InputMaybe<Scalars['numeric']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  product?: InputMaybe<Product_History_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  trade_item_unit_descriptor?: InputMaybe<Trade_Item_Unit_Descriptor_Enum>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Packaging_History_Max_Fields = {
  __typename?: 'packaging_history_max_fields';
  base_unit_id?: Maybe<Scalars['uuid']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_baseUnitSku?: Maybe<Scalars['String']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_parentSku?: Maybe<Scalars['String']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  gtin_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  net_content?: Maybe<Scalars['numeric']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "packaging_history" */
export type Packaging_History_Max_Order_By = {
  base_unit_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_baseUnitSku?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_parentSku?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  gtin_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  net_content?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Packaging_History_Min_Fields = {
  __typename?: 'packaging_history_min_fields';
  base_unit_id?: Maybe<Scalars['uuid']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_baseUnitSku?: Maybe<Scalars['String']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_parentSku?: Maybe<Scalars['String']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  gtin_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  net_content?: Maybe<Scalars['numeric']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "packaging_history" */
export type Packaging_History_Min_Order_By = {
  base_unit_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_baseUnitSku?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_parentSku?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  gtin_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  net_content?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "packaging_history" */
export type Packaging_History_Mutation_Response = {
  __typename?: 'packaging_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Packaging_History>;
};

/** input type for inserting object relation for remote table "packaging_history" */
export type Packaging_History_Obj_Rel_Insert_Input = {
  data: Packaging_History_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Packaging_History_On_Conflict>;
};

/** on_conflict condition type for table "packaging_history" */
export type Packaging_History_On_Conflict = {
  constraint: Packaging_History_Constraint;
  update_columns?: Array<Packaging_History_Update_Column>;
  where?: InputMaybe<Packaging_History_Bool_Exp>;
};

/** Ordering options when selecting data from "packaging_history". */
export type Packaging_History_Order_By = {
  base_unit?: InputMaybe<Base_Unit_History_Order_By>;
  base_unit_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  content_measurement_unit?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_baseUnitSku?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_parentSku?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  gtin_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  net_content?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_History_Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  trade_item_unit_descriptor?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: packaging_history */
export type Packaging_History_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "packaging_history" */
export enum Packaging_History_Select_Column {
  /** column name */
  BaseUnitId = 'base_unit_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContentMeasurementUnit = 'content_measurement_unit',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeprecatedBaseUnitSku = 'deprecated_baseUnitSku',
  /** column name */
  DeprecatedDefaultPriceId = 'deprecated_default_price_id',
  /** column name */
  DeprecatedParentSku = 'deprecated_parentSku',
  /** column name */
  DeprecatedProductSku = 'deprecated_productSku',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  GtinType = 'gtin_type',
  /** column name */
  Id = 'id',
  /** column name */
  NetContent = 'net_content',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Sku = 'sku',
  /** column name */
  TradeItemUnitDescriptor = 'trade_item_unit_descriptor',
  /** column name */
  UnitQuantity = 'unit_quantity'
}

/** input type for updating data in table "packaging_history" */
export type Packaging_History_Set_Input = {
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_baseUnitSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_parentSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  net_content?: InputMaybe<Scalars['numeric']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  trade_item_unit_descriptor?: InputMaybe<Trade_Item_Unit_Descriptor_Enum>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Packaging_History_Stddev_Fields = {
  __typename?: 'packaging_history_stddev_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "packaging_history" */
export type Packaging_History_Stddev_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Packaging_History_Stddev_Pop_Fields = {
  __typename?: 'packaging_history_stddev_pop_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "packaging_history" */
export type Packaging_History_Stddev_Pop_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Packaging_History_Stddev_Samp_Fields = {
  __typename?: 'packaging_history_stddev_samp_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "packaging_history" */
export type Packaging_History_Stddev_Samp_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "packaging_history" */
export type Packaging_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Packaging_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Packaging_History_Stream_Cursor_Value_Input = {
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_baseUnitSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_parentSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  net_content?: InputMaybe<Scalars['numeric']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  trade_item_unit_descriptor?: InputMaybe<Trade_Item_Unit_Descriptor_Enum>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Packaging_History_Sum_Fields = {
  __typename?: 'packaging_history_sum_fields';
  net_content?: Maybe<Scalars['numeric']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "packaging_history" */
export type Packaging_History_Sum_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** update columns of table "packaging_history" */
export enum Packaging_History_Update_Column {
  /** column name */
  BaseUnitId = 'base_unit_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContentMeasurementUnit = 'content_measurement_unit',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeprecatedBaseUnitSku = 'deprecated_baseUnitSku',
  /** column name */
  DeprecatedDefaultPriceId = 'deprecated_default_price_id',
  /** column name */
  DeprecatedParentSku = 'deprecated_parentSku',
  /** column name */
  DeprecatedProductSku = 'deprecated_productSku',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  GtinType = 'gtin_type',
  /** column name */
  Id = 'id',
  /** column name */
  NetContent = 'net_content',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Sku = 'sku',
  /** column name */
  TradeItemUnitDescriptor = 'trade_item_unit_descriptor',
  /** column name */
  UnitQuantity = 'unit_quantity'
}

export type Packaging_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Packaging_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Packaging_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Packaging_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Packaging_History_Var_Pop_Fields = {
  __typename?: 'packaging_history_var_pop_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "packaging_history" */
export type Packaging_History_Var_Pop_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Packaging_History_Var_Samp_Fields = {
  __typename?: 'packaging_history_var_samp_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "packaging_history" */
export type Packaging_History_Var_Samp_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Packaging_History_Variance_Fields = {
  __typename?: 'packaging_history_variance_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "packaging_history" */
export type Packaging_History_Variance_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "packaging" */
export type Packaging_Inc_Input = {
  net_content?: InputMaybe<Scalars['numeric']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "packaging" */
export type Packaging_Insert_Input = {
  base_unit?: InputMaybe<Base_Unit_Obj_Rel_Insert_Input>;
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_baseUnitSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_parentSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Gtin_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  net_content?: InputMaybe<Scalars['numeric']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  trade_item_unit_descriptor?: InputMaybe<Trade_Item_Unit_Descriptor_Enum>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Packaging_Max_Fields = {
  __typename?: 'packaging_max_fields';
  base_unit_id?: Maybe<Scalars['uuid']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_baseUnitSku?: Maybe<Scalars['String']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_parentSku?: Maybe<Scalars['String']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  net_content?: Maybe<Scalars['numeric']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "packaging" */
export type Packaging_Max_Order_By = {
  base_unit_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_baseUnitSku?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_parentSku?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  net_content?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Packaging_Min_Fields = {
  __typename?: 'packaging_min_fields';
  base_unit_id?: Maybe<Scalars['uuid']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_baseUnitSku?: Maybe<Scalars['String']['output']>;
  deprecated_default_price_id?: Maybe<Scalars['uuid']['output']>;
  deprecated_parentSku?: Maybe<Scalars['String']['output']>;
  deprecated_productSku?: Maybe<Scalars['String']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  net_content?: Maybe<Scalars['numeric']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "packaging" */
export type Packaging_Min_Order_By = {
  base_unit_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_baseUnitSku?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_parentSku?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  net_content?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "packaging" */
export type Packaging_Mutation_Response = {
  __typename?: 'packaging_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Packaging>;
};

/** input type for inserting object relation for remote table "packaging" */
export type Packaging_Obj_Rel_Insert_Input = {
  data: Packaging_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Packaging_On_Conflict>;
};

/** on_conflict condition type for table "packaging" */
export type Packaging_On_Conflict = {
  constraint: Packaging_Constraint;
  update_columns?: Array<Packaging_Update_Column>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};

/** Ordering options when selecting data from "packaging". */
export type Packaging_Order_By = {
  base_unit?: InputMaybe<Base_Unit_Order_By>;
  base_unit_id?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  content_measurement_unit?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deprecated_baseUnitSku?: InputMaybe<Order_By>;
  deprecated_default_price_id?: InputMaybe<Order_By>;
  deprecated_parentSku?: InputMaybe<Order_By>;
  deprecated_productSku?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  gtin_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  net_content?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  trade_item_unit_descriptor?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: packaging */
export type Packaging_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "packaging" */
export enum Packaging_Select_Column {
  /** column name */
  BaseUnitId = 'base_unit_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContentMeasurementUnit = 'content_measurement_unit',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeprecatedBaseUnitSku = 'deprecated_baseUnitSku',
  /** column name */
  DeprecatedDefaultPriceId = 'deprecated_default_price_id',
  /** column name */
  DeprecatedParentSku = 'deprecated_parentSku',
  /** column name */
  DeprecatedProductSku = 'deprecated_productSku',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  GtinType = 'gtin_type',
  /** column name */
  Id = 'id',
  /** column name */
  NetContent = 'net_content',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Sku = 'sku',
  /** column name */
  TradeItemUnitDescriptor = 'trade_item_unit_descriptor',
  /** column name */
  UnitQuantity = 'unit_quantity'
}

/** input type for updating data in table "packaging" */
export type Packaging_Set_Input = {
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_baseUnitSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_parentSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Gtin_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  net_content?: InputMaybe<Scalars['numeric']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  trade_item_unit_descriptor?: InputMaybe<Trade_Item_Unit_Descriptor_Enum>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Packaging_Stddev_Fields = {
  __typename?: 'packaging_stddev_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "packaging" */
export type Packaging_Stddev_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Packaging_Stddev_Pop_Fields = {
  __typename?: 'packaging_stddev_pop_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "packaging" */
export type Packaging_Stddev_Pop_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Packaging_Stddev_Samp_Fields = {
  __typename?: 'packaging_stddev_samp_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "packaging" */
export type Packaging_Stddev_Samp_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "packaging" */
export type Packaging_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Packaging_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Packaging_Stream_Cursor_Value_Input = {
  base_unit_id?: InputMaybe<Scalars['uuid']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  content_measurement_unit?: InputMaybe<Measurement_Unit_Enum>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_baseUnitSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_default_price_id?: InputMaybe<Scalars['uuid']['input']>;
  deprecated_parentSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_productSku?: InputMaybe<Scalars['String']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gtin_type?: InputMaybe<Gtin_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  net_content?: InputMaybe<Scalars['numeric']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  trade_item_unit_descriptor?: InputMaybe<Trade_Item_Unit_Descriptor_Enum>;
  unit_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Packaging_Sum_Fields = {
  __typename?: 'packaging_sum_fields';
  net_content?: Maybe<Scalars['numeric']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  unit_quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "packaging" */
export type Packaging_Sum_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** update columns of table "packaging" */
export enum Packaging_Update_Column {
  /** column name */
  BaseUnitId = 'base_unit_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContentMeasurementUnit = 'content_measurement_unit',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeprecatedBaseUnitSku = 'deprecated_baseUnitSku',
  /** column name */
  DeprecatedDefaultPriceId = 'deprecated_default_price_id',
  /** column name */
  DeprecatedParentSku = 'deprecated_parentSku',
  /** column name */
  DeprecatedProductSku = 'deprecated_productSku',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  GtinType = 'gtin_type',
  /** column name */
  Id = 'id',
  /** column name */
  NetContent = 'net_content',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Sku = 'sku',
  /** column name */
  TradeItemUnitDescriptor = 'trade_item_unit_descriptor',
  /** column name */
  UnitQuantity = 'unit_quantity'
}

export type Packaging_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Packaging_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Packaging_Set_Input>;
  /** filter the rows which have to be updated */
  where: Packaging_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Packaging_Var_Pop_Fields = {
  __typename?: 'packaging_var_pop_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "packaging" */
export type Packaging_Var_Pop_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Packaging_Var_Samp_Fields = {
  __typename?: 'packaging_var_samp_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "packaging" */
export type Packaging_Var_Samp_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Packaging_Variance_Fields = {
  __typename?: 'packaging_variance_fields';
  net_content?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  unit_quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "packaging" */
export type Packaging_Variance_Order_By = {
  net_content?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  unit_quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "payment_method" */
export type Payment_Method = {
  __typename?: 'payment_method';
  value: Scalars['String']['output'];
};

/** aggregated selection of "payment_method" */
export type Payment_Method_Aggregate = {
  __typename?: 'payment_method_aggregate';
  aggregate?: Maybe<Payment_Method_Aggregate_Fields>;
  nodes: Array<Payment_Method>;
};

/** aggregate fields of "payment_method" */
export type Payment_Method_Aggregate_Fields = {
  __typename?: 'payment_method_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Payment_Method_Max_Fields>;
  min?: Maybe<Payment_Method_Min_Fields>;
};


/** aggregate fields of "payment_method" */
export type Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "payment_method". All fields are combined with a logical 'AND'. */
export type Payment_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Method_Bool_Exp>>;
  _not?: InputMaybe<Payment_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Method_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_method" */
export enum Payment_Method_Constraint {
  /** unique or primary key constraint on columns "value" */
  PaymentMethodPkey = 'payment_method_pkey'
}

export enum Payment_Method_Enum {
  BankTransfer = 'BANK_TRANSFER',
  NoPayment = 'NO_PAYMENT',
  SddAuto = 'SDD_AUTO',
  SddTriggered = 'SDD_TRIGGERED'
}

/** Boolean expression to compare columns of type "payment_method_enum". All fields are combined with logical 'AND'. */
export type Payment_Method_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Payment_Method_Enum>;
  _in?: InputMaybe<Array<Payment_Method_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Payment_Method_Enum>;
  _nin?: InputMaybe<Array<Payment_Method_Enum>>;
};

/** input type for inserting data into table "payment_method" */
export type Payment_Method_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Payment_Method_Max_Fields = {
  __typename?: 'payment_method_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Payment_Method_Min_Fields = {
  __typename?: 'payment_method_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "payment_method" */
export type Payment_Method_Mutation_Response = {
  __typename?: 'payment_method_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Method>;
};

/** on_conflict condition type for table "payment_method" */
export type Payment_Method_On_Conflict = {
  constraint: Payment_Method_Constraint;
  update_columns?: Array<Payment_Method_Update_Column>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_method". */
export type Payment_Method_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_method */
export type Payment_Method_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "payment_method" */
export enum Payment_Method_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payment_method" */
export type Payment_Method_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "payment_method" */
export type Payment_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Method_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "payment_method" */
export enum Payment_Method_Update_Column {
  /** column name */
  Value = 'value'
}

export type Payment_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Method_Bool_Exp;
};

/** columns and relationships of "payment_process_status" */
export type Payment_Process_Status = {
  __typename?: 'payment_process_status';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "payment_process_status" */
export type Payment_Process_Status_Aggregate = {
  __typename?: 'payment_process_status_aggregate';
  aggregate?: Maybe<Payment_Process_Status_Aggregate_Fields>;
  nodes: Array<Payment_Process_Status>;
};

/** aggregate fields of "payment_process_status" */
export type Payment_Process_Status_Aggregate_Fields = {
  __typename?: 'payment_process_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Payment_Process_Status_Max_Fields>;
  min?: Maybe<Payment_Process_Status_Min_Fields>;
};


/** aggregate fields of "payment_process_status" */
export type Payment_Process_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Process_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "payment_process_status". All fields are combined with a logical 'AND'. */
export type Payment_Process_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Process_Status_Bool_Exp>>;
  _not?: InputMaybe<Payment_Process_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Process_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_process_status" */
export enum Payment_Process_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PaymentProcessStatusPkey = 'payment_process_status_pkey'
}

export enum Payment_Process_Status_Enum {
  /** The process payment is finished. */
  Finished = 'Finished',
  /** We received the money in on Lemonway and successfuly linked it to an order. */
  MoneyInReceived = 'MoneyInReceived',
  /** We have initiated the money transfer from Lemonway to the supplier's real bank account. */
  MoneyOutPerformed = 'MoneyOutPerformed',
  /** We have credited the supplier's account on Lemonway. */
  SupplierAccountCredited = 'SupplierAccountCredited',
  /** The order's payment is managed by Lemonway, we are waiting for the money in from the buyer. */
  WaitingForMoneyIn = 'WaitingForMoneyIn'
}

/** Boolean expression to compare columns of type "payment_process_status_enum". All fields are combined with logical 'AND'. */
export type Payment_Process_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Payment_Process_Status_Enum>;
  _in?: InputMaybe<Array<Payment_Process_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Payment_Process_Status_Enum>;
  _nin?: InputMaybe<Array<Payment_Process_Status_Enum>>;
};

/** input type for inserting data into table "payment_process_status" */
export type Payment_Process_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Payment_Process_Status_Max_Fields = {
  __typename?: 'payment_process_status_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Payment_Process_Status_Min_Fields = {
  __typename?: 'payment_process_status_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "payment_process_status" */
export type Payment_Process_Status_Mutation_Response = {
  __typename?: 'payment_process_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Process_Status>;
};

/** on_conflict condition type for table "payment_process_status" */
export type Payment_Process_Status_On_Conflict = {
  constraint: Payment_Process_Status_Constraint;
  update_columns?: Array<Payment_Process_Status_Update_Column>;
  where?: InputMaybe<Payment_Process_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_process_status". */
export type Payment_Process_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_process_status */
export type Payment_Process_Status_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "payment_process_status" */
export enum Payment_Process_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payment_process_status" */
export type Payment_Process_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "payment_process_status" */
export type Payment_Process_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Process_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Process_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "payment_process_status" */
export enum Payment_Process_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Payment_Process_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Process_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Process_Status_Bool_Exp;
};

/** columns and relationships of "photo" */
export type Photo = {
  __typename?: 'photo';
  base_unit_id_deprecated?: Maybe<Scalars['uuid']['output']>;
  companyId_deprecated?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  path: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "photo" */
export type Photo_Aggregate = {
  __typename?: 'photo_aggregate';
  aggregate?: Maybe<Photo_Aggregate_Fields>;
  nodes: Array<Photo>;
};

/** aggregate fields of "photo" */
export type Photo_Aggregate_Fields = {
  __typename?: 'photo_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Photo_Max_Fields>;
  min?: Maybe<Photo_Min_Fields>;
};


/** aggregate fields of "photo" */
export type Photo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Photo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "photo". All fields are combined with a logical 'AND'. */
export type Photo_Bool_Exp = {
  _and?: InputMaybe<Array<Photo_Bool_Exp>>;
  _not?: InputMaybe<Photo_Bool_Exp>;
  _or?: InputMaybe<Array<Photo_Bool_Exp>>;
  base_unit_id_deprecated?: InputMaybe<Uuid_Comparison_Exp>;
  companyId_deprecated?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "photo" */
export enum Photo_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkCda7cad80fc363fd735aefa13a9 = 'PK_cda7cad80fc363fd735aefa13a9'
}

/** input type for inserting data into table "photo" */
export type Photo_Insert_Input = {
  base_unit_id_deprecated?: InputMaybe<Scalars['uuid']['input']>;
  companyId_deprecated?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Photo_Max_Fields = {
  __typename?: 'photo_max_fields';
  base_unit_id_deprecated?: Maybe<Scalars['uuid']['output']>;
  companyId_deprecated?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Photo_Min_Fields = {
  __typename?: 'photo_min_fields';
  base_unit_id_deprecated?: Maybe<Scalars['uuid']['output']>;
  companyId_deprecated?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "photo" */
export type Photo_Mutation_Response = {
  __typename?: 'photo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Photo>;
};

/** input type for inserting object relation for remote table "photo" */
export type Photo_Obj_Rel_Insert_Input = {
  data: Photo_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Photo_On_Conflict>;
};

/** on_conflict condition type for table "photo" */
export type Photo_On_Conflict = {
  constraint: Photo_Constraint;
  update_columns?: Array<Photo_Update_Column>;
  where?: InputMaybe<Photo_Bool_Exp>;
};

/** Ordering options when selecting data from "photo". */
export type Photo_Order_By = {
  base_unit_id_deprecated?: InputMaybe<Order_By>;
  companyId_deprecated?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: photo */
export type Photo_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "photo" */
export enum Photo_Select_Column {
  /** column name */
  BaseUnitIdDeprecated = 'base_unit_id_deprecated',
  /** column name */
  CompanyIdDeprecated = 'companyId_deprecated',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "photo" */
export type Photo_Set_Input = {
  base_unit_id_deprecated?: InputMaybe<Scalars['uuid']['input']>;
  companyId_deprecated?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "photo" */
export type Photo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Photo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Photo_Stream_Cursor_Value_Input = {
  base_unit_id_deprecated?: InputMaybe<Scalars['uuid']['input']>;
  companyId_deprecated?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "photo" */
export enum Photo_Update_Column {
  /** column name */
  BaseUnitIdDeprecated = 'base_unit_id_deprecated',
  /** column name */
  CompanyIdDeprecated = 'companyId_deprecated',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Photo_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Photo_Set_Input>;
  /** filter the rows which have to be updated */
  where: Photo_Bool_Exp;
};

/** columns and relationships of "privacy_setting" */
export type Privacy_Setting = {
  __typename?: 'privacy_setting';
  value: Scalars['String']['output'];
};

/** aggregated selection of "privacy_setting" */
export type Privacy_Setting_Aggregate = {
  __typename?: 'privacy_setting_aggregate';
  aggregate?: Maybe<Privacy_Setting_Aggregate_Fields>;
  nodes: Array<Privacy_Setting>;
};

/** aggregate fields of "privacy_setting" */
export type Privacy_Setting_Aggregate_Fields = {
  __typename?: 'privacy_setting_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Privacy_Setting_Max_Fields>;
  min?: Maybe<Privacy_Setting_Min_Fields>;
};


/** aggregate fields of "privacy_setting" */
export type Privacy_Setting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Privacy_Setting_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "privacy_setting". All fields are combined with a logical 'AND'. */
export type Privacy_Setting_Bool_Exp = {
  _and?: InputMaybe<Array<Privacy_Setting_Bool_Exp>>;
  _not?: InputMaybe<Privacy_Setting_Bool_Exp>;
  _or?: InputMaybe<Array<Privacy_Setting_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "privacy_setting" */
export enum Privacy_Setting_Constraint {
  /** unique or primary key constraint on columns "value" */
  PrivacySettingPkey = 'privacy_setting_pkey'
}

export enum Privacy_Setting_Enum {
  Contractualized = 'CONTRACTUALIZED',
  Everyone = 'EVERYONE',
  Nobody = 'NOBODY'
}

/** Boolean expression to compare columns of type "privacy_setting_enum". All fields are combined with logical 'AND'. */
export type Privacy_Setting_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Privacy_Setting_Enum>;
  _in?: InputMaybe<Array<Privacy_Setting_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Privacy_Setting_Enum>;
  _nin?: InputMaybe<Array<Privacy_Setting_Enum>>;
};

/** input type for inserting data into table "privacy_setting" */
export type Privacy_Setting_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Privacy_Setting_Max_Fields = {
  __typename?: 'privacy_setting_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Privacy_Setting_Min_Fields = {
  __typename?: 'privacy_setting_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "privacy_setting" */
export type Privacy_Setting_Mutation_Response = {
  __typename?: 'privacy_setting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Privacy_Setting>;
};

/** on_conflict condition type for table "privacy_setting" */
export type Privacy_Setting_On_Conflict = {
  constraint: Privacy_Setting_Constraint;
  update_columns?: Array<Privacy_Setting_Update_Column>;
  where?: InputMaybe<Privacy_Setting_Bool_Exp>;
};

/** Ordering options when selecting data from "privacy_setting". */
export type Privacy_Setting_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: privacy_setting */
export type Privacy_Setting_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "privacy_setting" */
export enum Privacy_Setting_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "privacy_setting" */
export type Privacy_Setting_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "privacy_setting" */
export type Privacy_Setting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Privacy_Setting_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Privacy_Setting_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "privacy_setting" */
export enum Privacy_Setting_Update_Column {
  /** column name */
  Value = 'value'
}

export type Privacy_Setting_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Privacy_Setting_Set_Input>;
  /** filter the rows which have to be updated */
  where: Privacy_Setting_Bool_Exp;
};

/** columns and relationships of "product" */
export type Product = {
  __typename?: 'product';
  allergens?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  base_units: Array<Base_Unit>;
  /** An aggregate relationship */
  base_units_aggregate: Base_Unit_Aggregate;
  calories?: Maybe<Scalars['numeric']['output']>;
  calories_kj?: Maybe<Scalars['numeric']['output']>;
  carbohydrate?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  company: Company;
  company_id: Scalars['uuid']['output'];
  created_date: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fat?: Maybe<Scalars['numeric']['output']>;
  id: Scalars['uuid']['output'];
  ingredients?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  labels: Array<Product_Product_Labels_Product_Label>;
  /** An aggregate relationship */
  labels_aggregate: Product_Product_Labels_Product_Label_Aggregate;
  name: Scalars['String']['output'];
  /** An array relationship */
  packagings: Array<Packaging>;
  /** An aggregate relationship */
  packagings_aggregate: Packaging_Aggregate;
  /** An object relationship */
  product_sub_family: Product_Sub_Family;
  protein?: Maybe<Scalars['numeric']['output']>;
  saturated_fat?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration_type?: Maybe<Duration_Type_Enum>;
  sku: Scalars['Int']['output'];
  sodium?: Maybe<Scalars['numeric']['output']>;
  sub_family: Scalars['String']['output'];
  sugars?: Maybe<Scalars['numeric']['output']>;
  vat_rate?: Maybe<Scalars['numeric']['output']>;
};


/** columns and relationships of "product" */
export type ProductBase_UnitsArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Order_By>>;
  where?: InputMaybe<Base_Unit_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductBase_Units_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Order_By>>;
  where?: InputMaybe<Base_Unit_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductLabelsArgs = {
  distinct_on?: InputMaybe<Array<Product_Product_Labels_Product_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Product_Labels_Product_Label_Order_By>>;
  where?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductLabels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Product_Labels_Product_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Product_Labels_Product_Label_Order_By>>;
  where?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductPackagingsArgs = {
  distinct_on?: InputMaybe<Array<Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_Order_By>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductPackagings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_Order_By>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

export type Product_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Aggregate_Bool_Exp_Count>;
};

export type Product_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields';
  avg?: Maybe<Product_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Max_Order_By>;
  min?: InputMaybe<Product_Min_Order_By>;
  stddev?: InputMaybe<Product_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: 'product_avg_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "product" */
export type Product_Avg_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Bool_Exp>>;
  _not?: InputMaybe<Product_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Bool_Exp>>;
  allergens?: InputMaybe<String_Comparison_Exp>;
  base_units?: InputMaybe<Base_Unit_Bool_Exp>;
  base_units_aggregate?: InputMaybe<Base_Unit_Aggregate_Bool_Exp>;
  calories?: InputMaybe<Numeric_Comparison_Exp>;
  calories_kj?: InputMaybe<Numeric_Comparison_Exp>;
  carbohydrate?: InputMaybe<Numeric_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deprecated_sku?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  fat?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ingredients?: InputMaybe<String_Comparison_Exp>;
  labels?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
  labels_aggregate?: InputMaybe<Product_Product_Labels_Product_Label_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  packagings?: InputMaybe<Packaging_Bool_Exp>;
  packagings_aggregate?: InputMaybe<Packaging_Aggregate_Bool_Exp>;
  product_sub_family?: InputMaybe<Product_Sub_Family_Bool_Exp>;
  protein?: InputMaybe<Numeric_Comparison_Exp>;
  saturated_fat?: InputMaybe<Numeric_Comparison_Exp>;
  shelf_life_duration?: InputMaybe<Numeric_Comparison_Exp>;
  shelf_life_duration_type?: InputMaybe<Duration_Type_Enum_Comparison_Exp>;
  sku?: InputMaybe<Int_Comparison_Exp>;
  sodium?: InputMaybe<Numeric_Comparison_Exp>;
  sub_family?: InputMaybe<String_Comparison_Exp>;
  sugars?: InputMaybe<Numeric_Comparison_Exp>;
  vat_rate?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkBebc9158e480b949565b4dc7a82 = 'PK_bebc9158e480b949565b4dc7a82',
  /** unique or primary key constraint on columns "company_id", "sku" */
  ProductSkuCompanyIdUnique = 'product_sku_company_id_unique'
}

/** columns and relationships of "product_family" */
export type Product_Family = {
  __typename?: 'product_family';
  name: Product_Family_Name_Enum;
};

/** aggregated selection of "product_family" */
export type Product_Family_Aggregate = {
  __typename?: 'product_family_aggregate';
  aggregate?: Maybe<Product_Family_Aggregate_Fields>;
  nodes: Array<Product_Family>;
};

/** aggregate fields of "product_family" */
export type Product_Family_Aggregate_Fields = {
  __typename?: 'product_family_aggregate_fields';
  count: Scalars['Int']['output'];
};


/** aggregate fields of "product_family" */
export type Product_Family_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Family_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "product_family". All fields are combined with a logical 'AND'. */
export type Product_Family_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Family_Bool_Exp>>;
  _not?: InputMaybe<Product_Family_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Family_Bool_Exp>>;
  name?: InputMaybe<Product_Family_Name_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_family" */
export enum Product_Family_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProductFamilyPkey = 'product_family_pkey'
}

/** input type for inserting data into table "product_family" */
export type Product_Family_Insert_Input = {
  name?: InputMaybe<Product_Family_Name_Enum>;
};

/** response of any mutation on the table "product_family" */
export type Product_Family_Mutation_Response = {
  __typename?: 'product_family_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Family>;
};

/** columns and relationships of "product_family_name" */
export type Product_Family_Name = {
  __typename?: 'product_family_name';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "product_family_name" */
export type Product_Family_Name_Aggregate = {
  __typename?: 'product_family_name_aggregate';
  aggregate?: Maybe<Product_Family_Name_Aggregate_Fields>;
  nodes: Array<Product_Family_Name>;
};

/** aggregate fields of "product_family_name" */
export type Product_Family_Name_Aggregate_Fields = {
  __typename?: 'product_family_name_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Product_Family_Name_Max_Fields>;
  min?: Maybe<Product_Family_Name_Min_Fields>;
};


/** aggregate fields of "product_family_name" */
export type Product_Family_Name_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Family_Name_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "product_family_name". All fields are combined with a logical 'AND'. */
export type Product_Family_Name_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Family_Name_Bool_Exp>>;
  _not?: InputMaybe<Product_Family_Name_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Family_Name_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_family_name" */
export enum Product_Family_Name_Constraint {
  /** unique or primary key constraint on columns "value" */
  ProductFamilyNamePkey = 'product_family_name_pkey'
}

export enum Product_Family_Name_Enum {
  Beverages = 'BEVERAGES',
  FreshFood = 'FRESH_FOOD',
  Grocery = 'GROCERY',
  NonFoodProducts = 'NON_FOOD_PRODUCTS'
}

/** Boolean expression to compare columns of type "product_family_name_enum". All fields are combined with logical 'AND'. */
export type Product_Family_Name_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Product_Family_Name_Enum>;
  _in?: InputMaybe<Array<Product_Family_Name_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Product_Family_Name_Enum>;
  _nin?: InputMaybe<Array<Product_Family_Name_Enum>>;
};

/** input type for inserting data into table "product_family_name" */
export type Product_Family_Name_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Product_Family_Name_Max_Fields = {
  __typename?: 'product_family_name_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Product_Family_Name_Min_Fields = {
  __typename?: 'product_family_name_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "product_family_name" */
export type Product_Family_Name_Mutation_Response = {
  __typename?: 'product_family_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Family_Name>;
};

/** on_conflict condition type for table "product_family_name" */
export type Product_Family_Name_On_Conflict = {
  constraint: Product_Family_Name_Constraint;
  update_columns?: Array<Product_Family_Name_Update_Column>;
  where?: InputMaybe<Product_Family_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "product_family_name". */
export type Product_Family_Name_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_family_name */
export type Product_Family_Name_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "product_family_name" */
export enum Product_Family_Name_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "product_family_name" */
export type Product_Family_Name_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "product_family_name" */
export type Product_Family_Name_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Family_Name_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Family_Name_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "product_family_name" */
export enum Product_Family_Name_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Product_Family_Name_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Family_Name_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Family_Name_Bool_Exp;
};

/** on_conflict condition type for table "product_family" */
export type Product_Family_On_Conflict = {
  constraint: Product_Family_Constraint;
  update_columns?: Array<Product_Family_Update_Column>;
  where?: InputMaybe<Product_Family_Bool_Exp>;
};

/** Ordering options when selecting data from "product_family". */
export type Product_Family_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_family */
export type Product_Family_Pk_Columns_Input = {
  name: Product_Family_Name_Enum;
};

/** select columns of table "product_family" */
export enum Product_Family_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "product_family" */
export type Product_Family_Set_Input = {
  name?: InputMaybe<Product_Family_Name_Enum>;
};

/** Streaming cursor of the table "product_family" */
export type Product_Family_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Family_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Family_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Product_Family_Name_Enum>;
};

/** update columns of table "product_family" */
export enum Product_Family_Update_Column {
  /** column name */
  Name = 'name'
}

export type Product_Family_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Family_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Family_Bool_Exp;
};

/** columns and relationships of "product_history" */
export type Product_History = {
  __typename?: 'product_history';
  allergens?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  base_units: Array<Base_Unit_History>;
  /** An aggregate relationship */
  base_units_aggregate: Base_Unit_History_Aggregate;
  calories?: Maybe<Scalars['numeric']['output']>;
  calories_kj?: Maybe<Scalars['numeric']['output']>;
  carbohydrate?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  company: Company;
  company_id: Scalars['uuid']['output'];
  created_date: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fat?: Maybe<Scalars['numeric']['output']>;
  id: Scalars['uuid']['output'];
  ingredients?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  labels: Array<Product_Product_Labels_Product_Label>;
  /** An aggregate relationship */
  labels_aggregate: Product_Product_Labels_Product_Label_Aggregate;
  name: Scalars['String']['output'];
  protein?: Maybe<Scalars['numeric']['output']>;
  saturated_fat?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration_type?: Maybe<Scalars['String']['output']>;
  sku: Scalars['Int']['output'];
  sodium?: Maybe<Scalars['numeric']['output']>;
  sub_family: Scalars['String']['output'];
  sugars?: Maybe<Scalars['numeric']['output']>;
  vat_rate?: Maybe<Scalars['numeric']['output']>;
};


/** columns and relationships of "product_history" */
export type Product_HistoryBase_UnitsArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_History_Order_By>>;
  where?: InputMaybe<Base_Unit_History_Bool_Exp>;
};


/** columns and relationships of "product_history" */
export type Product_HistoryBase_Units_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_History_Order_By>>;
  where?: InputMaybe<Base_Unit_History_Bool_Exp>;
};


/** columns and relationships of "product_history" */
export type Product_HistoryLabelsArgs = {
  distinct_on?: InputMaybe<Array<Product_Product_Labels_Product_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Product_Labels_Product_Label_Order_By>>;
  where?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
};


/** columns and relationships of "product_history" */
export type Product_HistoryLabels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Product_Labels_Product_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Product_Labels_Product_Label_Order_By>>;
  where?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
};

/** aggregated selection of "product_history" */
export type Product_History_Aggregate = {
  __typename?: 'product_history_aggregate';
  aggregate?: Maybe<Product_History_Aggregate_Fields>;
  nodes: Array<Product_History>;
};

/** aggregate fields of "product_history" */
export type Product_History_Aggregate_Fields = {
  __typename?: 'product_history_aggregate_fields';
  avg?: Maybe<Product_History_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Product_History_Max_Fields>;
  min?: Maybe<Product_History_Min_Fields>;
  stddev?: Maybe<Product_History_Stddev_Fields>;
  stddev_pop?: Maybe<Product_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_History_Stddev_Samp_Fields>;
  sum?: Maybe<Product_History_Sum_Fields>;
  var_pop?: Maybe<Product_History_Var_Pop_Fields>;
  var_samp?: Maybe<Product_History_Var_Samp_Fields>;
  variance?: Maybe<Product_History_Variance_Fields>;
};


/** aggregate fields of "product_history" */
export type Product_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Product_History_Avg_Fields = {
  __typename?: 'product_history_avg_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "product_history". All fields are combined with a logical 'AND'. */
export type Product_History_Bool_Exp = {
  _and?: InputMaybe<Array<Product_History_Bool_Exp>>;
  _not?: InputMaybe<Product_History_Bool_Exp>;
  _or?: InputMaybe<Array<Product_History_Bool_Exp>>;
  allergens?: InputMaybe<String_Comparison_Exp>;
  base_units?: InputMaybe<Base_Unit_History_Bool_Exp>;
  base_units_aggregate?: InputMaybe<Base_Unit_History_Aggregate_Bool_Exp>;
  calories?: InputMaybe<Numeric_Comparison_Exp>;
  calories_kj?: InputMaybe<Numeric_Comparison_Exp>;
  carbohydrate?: InputMaybe<Numeric_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deprecated_sku?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  fat?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ingredients?: InputMaybe<String_Comparison_Exp>;
  labels?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
  labels_aggregate?: InputMaybe<Product_Product_Labels_Product_Label_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  protein?: InputMaybe<Numeric_Comparison_Exp>;
  saturated_fat?: InputMaybe<Numeric_Comparison_Exp>;
  shelf_life_duration?: InputMaybe<Numeric_Comparison_Exp>;
  shelf_life_duration_type?: InputMaybe<String_Comparison_Exp>;
  sku?: InputMaybe<Int_Comparison_Exp>;
  sodium?: InputMaybe<Numeric_Comparison_Exp>;
  sub_family?: InputMaybe<String_Comparison_Exp>;
  sugars?: InputMaybe<Numeric_Comparison_Exp>;
  vat_rate?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_history" */
export enum Product_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductHistoryPkey = 'product_history_pkey'
}

/** input type for incrementing numeric columns in table "product_history" */
export type Product_History_Inc_Input = {
  calories?: InputMaybe<Scalars['numeric']['input']>;
  calories_kj?: InputMaybe<Scalars['numeric']['input']>;
  carbohydrate?: InputMaybe<Scalars['numeric']['input']>;
  fat?: InputMaybe<Scalars['numeric']['input']>;
  protein?: InputMaybe<Scalars['numeric']['input']>;
  saturated_fat?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  sodium?: InputMaybe<Scalars['numeric']['input']>;
  sugars?: InputMaybe<Scalars['numeric']['input']>;
  vat_rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "product_history" */
export type Product_History_Insert_Input = {
  allergens?: InputMaybe<Scalars['String']['input']>;
  base_units?: InputMaybe<Base_Unit_History_Arr_Rel_Insert_Input>;
  calories?: InputMaybe<Scalars['numeric']['input']>;
  calories_kj?: InputMaybe<Scalars['numeric']['input']>;
  carbohydrate?: InputMaybe<Scalars['numeric']['input']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fat?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ingredients?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Product_Product_Labels_Product_Label_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  protein?: InputMaybe<Scalars['numeric']['input']>;
  saturated_fat?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration_type?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  sodium?: InputMaybe<Scalars['numeric']['input']>;
  sub_family?: InputMaybe<Scalars['String']['input']>;
  sugars?: InputMaybe<Scalars['numeric']['input']>;
  vat_rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Product_History_Max_Fields = {
  __typename?: 'product_history_max_fields';
  allergens?: Maybe<Scalars['String']['output']>;
  calories?: Maybe<Scalars['numeric']['output']>;
  calories_kj?: Maybe<Scalars['numeric']['output']>;
  carbohydrate?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fat?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ingredients?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  protein?: Maybe<Scalars['numeric']['output']>;
  saturated_fat?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration_type?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  sodium?: Maybe<Scalars['numeric']['output']>;
  sub_family?: Maybe<Scalars['String']['output']>;
  sugars?: Maybe<Scalars['numeric']['output']>;
  vat_rate?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Product_History_Min_Fields = {
  __typename?: 'product_history_min_fields';
  allergens?: Maybe<Scalars['String']['output']>;
  calories?: Maybe<Scalars['numeric']['output']>;
  calories_kj?: Maybe<Scalars['numeric']['output']>;
  carbohydrate?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fat?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ingredients?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  protein?: Maybe<Scalars['numeric']['output']>;
  saturated_fat?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration_type?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  sodium?: Maybe<Scalars['numeric']['output']>;
  sub_family?: Maybe<Scalars['String']['output']>;
  sugars?: Maybe<Scalars['numeric']['output']>;
  vat_rate?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "product_history" */
export type Product_History_Mutation_Response = {
  __typename?: 'product_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_History>;
};

/** input type for inserting object relation for remote table "product_history" */
export type Product_History_Obj_Rel_Insert_Input = {
  data: Product_History_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_History_On_Conflict>;
};

/** on_conflict condition type for table "product_history" */
export type Product_History_On_Conflict = {
  constraint: Product_History_Constraint;
  update_columns?: Array<Product_History_Update_Column>;
  where?: InputMaybe<Product_History_Bool_Exp>;
};

/** Ordering options when selecting data from "product_history". */
export type Product_History_Order_By = {
  allergens?: InputMaybe<Order_By>;
  base_units_aggregate?: InputMaybe<Base_Unit_History_Aggregate_Order_By>;
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredients?: InputMaybe<Order_By>;
  labels_aggregate?: InputMaybe<Product_Product_Labels_Product_Label_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  shelf_life_duration_type?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sub_family?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_history */
export type Product_History_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "product_history" */
export enum Product_History_Select_Column {
  /** column name */
  Allergens = 'allergens',
  /** column name */
  Calories = 'calories',
  /** column name */
  CaloriesKj = 'calories_kj',
  /** column name */
  Carbohydrate = 'carbohydrate',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Description = 'description',
  /** column name */
  Fat = 'fat',
  /** column name */
  Id = 'id',
  /** column name */
  Ingredients = 'ingredients',
  /** column name */
  Name = 'name',
  /** column name */
  Protein = 'protein',
  /** column name */
  SaturatedFat = 'saturated_fat',
  /** column name */
  ShelfLifeDuration = 'shelf_life_duration',
  /** column name */
  ShelfLifeDurationType = 'shelf_life_duration_type',
  /** column name */
  Sku = 'sku',
  /** column name */
  Sodium = 'sodium',
  /** column name */
  SubFamily = 'sub_family',
  /** column name */
  Sugars = 'sugars',
  /** column name */
  VatRate = 'vat_rate'
}

/** input type for updating data in table "product_history" */
export type Product_History_Set_Input = {
  allergens?: InputMaybe<Scalars['String']['input']>;
  calories?: InputMaybe<Scalars['numeric']['input']>;
  calories_kj?: InputMaybe<Scalars['numeric']['input']>;
  carbohydrate?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fat?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ingredients?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protein?: InputMaybe<Scalars['numeric']['input']>;
  saturated_fat?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration_type?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  sodium?: InputMaybe<Scalars['numeric']['input']>;
  sub_family?: InputMaybe<Scalars['String']['input']>;
  sugars?: InputMaybe<Scalars['numeric']['input']>;
  vat_rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Product_History_Stddev_Fields = {
  __typename?: 'product_history_stddev_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Product_History_Stddev_Pop_Fields = {
  __typename?: 'product_history_stddev_pop_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Product_History_Stddev_Samp_Fields = {
  __typename?: 'product_history_stddev_samp_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "product_history" */
export type Product_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_History_Stream_Cursor_Value_Input = {
  allergens?: InputMaybe<Scalars['String']['input']>;
  calories?: InputMaybe<Scalars['numeric']['input']>;
  calories_kj?: InputMaybe<Scalars['numeric']['input']>;
  carbohydrate?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fat?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ingredients?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protein?: InputMaybe<Scalars['numeric']['input']>;
  saturated_fat?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration_type?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  sodium?: InputMaybe<Scalars['numeric']['input']>;
  sub_family?: InputMaybe<Scalars['String']['input']>;
  sugars?: InputMaybe<Scalars['numeric']['input']>;
  vat_rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Product_History_Sum_Fields = {
  __typename?: 'product_history_sum_fields';
  calories?: Maybe<Scalars['numeric']['output']>;
  calories_kj?: Maybe<Scalars['numeric']['output']>;
  carbohydrate?: Maybe<Scalars['numeric']['output']>;
  fat?: Maybe<Scalars['numeric']['output']>;
  protein?: Maybe<Scalars['numeric']['output']>;
  saturated_fat?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  sodium?: Maybe<Scalars['numeric']['output']>;
  sugars?: Maybe<Scalars['numeric']['output']>;
  vat_rate?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "product_history" */
export enum Product_History_Update_Column {
  /** column name */
  Allergens = 'allergens',
  /** column name */
  Calories = 'calories',
  /** column name */
  CaloriesKj = 'calories_kj',
  /** column name */
  Carbohydrate = 'carbohydrate',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Description = 'description',
  /** column name */
  Fat = 'fat',
  /** column name */
  Id = 'id',
  /** column name */
  Ingredients = 'ingredients',
  /** column name */
  Name = 'name',
  /** column name */
  Protein = 'protein',
  /** column name */
  SaturatedFat = 'saturated_fat',
  /** column name */
  ShelfLifeDuration = 'shelf_life_duration',
  /** column name */
  ShelfLifeDurationType = 'shelf_life_duration_type',
  /** column name */
  Sku = 'sku',
  /** column name */
  Sodium = 'sodium',
  /** column name */
  SubFamily = 'sub_family',
  /** column name */
  Sugars = 'sugars',
  /** column name */
  VatRate = 'vat_rate'
}

export type Product_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_History_Var_Pop_Fields = {
  __typename?: 'product_history_var_pop_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Product_History_Var_Samp_Fields = {
  __typename?: 'product_history_var_samp_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Product_History_Variance_Fields = {
  __typename?: 'product_history_variance_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "product" */
export type Product_Inc_Input = {
  calories?: InputMaybe<Scalars['numeric']['input']>;
  calories_kj?: InputMaybe<Scalars['numeric']['input']>;
  carbohydrate?: InputMaybe<Scalars['numeric']['input']>;
  fat?: InputMaybe<Scalars['numeric']['input']>;
  protein?: InputMaybe<Scalars['numeric']['input']>;
  saturated_fat?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  sodium?: InputMaybe<Scalars['numeric']['input']>;
  sugars?: InputMaybe<Scalars['numeric']['input']>;
  vat_rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  allergens?: InputMaybe<Scalars['String']['input']>;
  base_units?: InputMaybe<Base_Unit_Arr_Rel_Insert_Input>;
  calories?: InputMaybe<Scalars['numeric']['input']>;
  calories_kj?: InputMaybe<Scalars['numeric']['input']>;
  carbohydrate?: InputMaybe<Scalars['numeric']['input']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fat?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ingredients?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Product_Product_Labels_Product_Label_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  packagings?: InputMaybe<Packaging_Arr_Rel_Insert_Input>;
  product_sub_family?: InputMaybe<Product_Sub_Family_Obj_Rel_Insert_Input>;
  protein?: InputMaybe<Scalars['numeric']['input']>;
  saturated_fat?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration_type?: InputMaybe<Duration_Type_Enum>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  sodium?: InputMaybe<Scalars['numeric']['input']>;
  sub_family?: InputMaybe<Scalars['String']['input']>;
  sugars?: InputMaybe<Scalars['numeric']['input']>;
  vat_rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** columns and relationships of "product_label_name" */
export type Product_Label_Name = {
  __typename?: 'product_label_name';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "product_label_name" */
export type Product_Label_Name_Aggregate = {
  __typename?: 'product_label_name_aggregate';
  aggregate?: Maybe<Product_Label_Name_Aggregate_Fields>;
  nodes: Array<Product_Label_Name>;
};

/** aggregate fields of "product_label_name" */
export type Product_Label_Name_Aggregate_Fields = {
  __typename?: 'product_label_name_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Product_Label_Name_Max_Fields>;
  min?: Maybe<Product_Label_Name_Min_Fields>;
};


/** aggregate fields of "product_label_name" */
export type Product_Label_Name_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Label_Name_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "product_label_name". All fields are combined with a logical 'AND'. */
export type Product_Label_Name_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Label_Name_Bool_Exp>>;
  _not?: InputMaybe<Product_Label_Name_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Label_Name_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_label_name" */
export enum Product_Label_Name_Constraint {
  /** unique or primary key constraint on columns "value" */
  ProductLabelNamePkey = 'product_label_name_pkey'
}

export enum Product_Label_Name_Enum {
  AgricultureBiologique = 'AGRICULTURE_BIOLOGIQUE',
  AppellationOrigineControlee = 'APPELLATION_ORIGINE_CONTROLEE',
  AppellationOrigineProtegee = 'APPELLATION_ORIGINE_PROTEGEE',
  EcocertEcodetergent = 'ECOCERT_ECODETERGENT',
  GoutezArdeche = 'GOUTEZ_ARDECHE',
  HauteValeurEnvironnementale = 'HAUTE_VALEUR_ENVIRONNEMENTALE',
  IndicationGeographiqueProtegee = 'INDICATION_GEOGRAPHIQUE_PROTEGEE',
  LabelRouge = 'LABEL_ROUGE',
  LePorcFrancais = 'LE_PORC_FRANCAIS',
  NatureEtProgres = 'NATURE_ET_PROGRES',
  OrigineFranceGarantie = 'ORIGINE_FRANCE_GARANTIE',
  ProduitIleDeFrance = 'PRODUIT_ILE_DE_FRANCE',
  SaveursDeNormandie = 'SAVEURS_DE_NORMANDIE',
  ValeursParcNaturelRegional = 'VALEURS_PARC_NATUREL_REGIONAL'
}

/** Boolean expression to compare columns of type "product_label_name_enum". All fields are combined with logical 'AND'. */
export type Product_Label_Name_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Product_Label_Name_Enum>;
  _in?: InputMaybe<Array<Product_Label_Name_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Product_Label_Name_Enum>;
  _nin?: InputMaybe<Array<Product_Label_Name_Enum>>;
};

/** input type for inserting data into table "product_label_name" */
export type Product_Label_Name_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Product_Label_Name_Max_Fields = {
  __typename?: 'product_label_name_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Product_Label_Name_Min_Fields = {
  __typename?: 'product_label_name_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "product_label_name" */
export type Product_Label_Name_Mutation_Response = {
  __typename?: 'product_label_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Label_Name>;
};

/** on_conflict condition type for table "product_label_name" */
export type Product_Label_Name_On_Conflict = {
  constraint: Product_Label_Name_Constraint;
  update_columns?: Array<Product_Label_Name_Update_Column>;
  where?: InputMaybe<Product_Label_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "product_label_name". */
export type Product_Label_Name_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_label_name */
export type Product_Label_Name_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "product_label_name" */
export enum Product_Label_Name_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "product_label_name" */
export type Product_Label_Name_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "product_label_name" */
export type Product_Label_Name_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Label_Name_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Label_Name_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "product_label_name" */
export enum Product_Label_Name_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Product_Label_Name_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Label_Name_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Label_Name_Bool_Exp;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields';
  allergens?: Maybe<Scalars['String']['output']>;
  calories?: Maybe<Scalars['numeric']['output']>;
  calories_kj?: Maybe<Scalars['numeric']['output']>;
  carbohydrate?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fat?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ingredients?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  protein?: Maybe<Scalars['numeric']['output']>;
  saturated_fat?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  sodium?: Maybe<Scalars['numeric']['output']>;
  sub_family?: Maybe<Scalars['String']['output']>;
  sugars?: Maybe<Scalars['numeric']['output']>;
  vat_rate?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  allergens?: InputMaybe<Order_By>;
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredients?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sub_family?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields';
  allergens?: Maybe<Scalars['String']['output']>;
  calories?: Maybe<Scalars['numeric']['output']>;
  calories_kj?: Maybe<Scalars['numeric']['output']>;
  carbohydrate?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  deprecated_sku?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fat?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ingredients?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  protein?: Maybe<Scalars['numeric']['output']>;
  saturated_fat?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  sodium?: Maybe<Scalars['numeric']['output']>;
  sub_family?: Maybe<Scalars['String']['output']>;
  sugars?: Maybe<Scalars['numeric']['output']>;
  vat_rate?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  allergens?: InputMaybe<Order_By>;
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredients?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sub_family?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** on_conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  allergens?: InputMaybe<Order_By>;
  base_units_aggregate?: InputMaybe<Base_Unit_Aggregate_Order_By>;
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deprecated_sku?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredients?: InputMaybe<Order_By>;
  labels_aggregate?: InputMaybe<Product_Product_Labels_Product_Label_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  packagings_aggregate?: InputMaybe<Packaging_Aggregate_Order_By>;
  product_sub_family?: InputMaybe<Product_Sub_Family_Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  shelf_life_duration_type?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sub_family?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label = {
  __typename?: 'product_product_labels_product_label';
  created_at: Scalars['timestamptz']['output'];
  label: Product_Label_Name_Enum;
  product_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Aggregate = {
  __typename?: 'product_product_labels_product_label_aggregate';
  aggregate?: Maybe<Product_Product_Labels_Product_Label_Aggregate_Fields>;
  nodes: Array<Product_Product_Labels_Product_Label>;
};

export type Product_Product_Labels_Product_Label_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Product_Labels_Product_Label_Aggregate_Bool_Exp_Count>;
};

export type Product_Product_Labels_Product_Label_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Product_Labels_Product_Label_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Aggregate_Fields = {
  __typename?: 'product_product_labels_product_label_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Product_Product_Labels_Product_Label_Max_Fields>;
  min?: Maybe<Product_Product_Labels_Product_Label_Min_Fields>;
};


/** aggregate fields of "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Product_Labels_Product_Label_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Product_Labels_Product_Label_Max_Order_By>;
  min?: InputMaybe<Product_Product_Labels_Product_Label_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Arr_Rel_Insert_Input = {
  data: Array<Product_Product_Labels_Product_Label_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Product_Labels_Product_Label_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_product_labels_product_label". All fields are combined with a logical 'AND'. */
export type Product_Product_Labels_Product_Label_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Product_Labels_Product_Label_Bool_Exp>>;
  _not?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Product_Labels_Product_Label_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  label?: InputMaybe<Product_Label_Name_Enum_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_product_labels_product_label" */
export enum Product_Product_Labels_Product_Label_Constraint {
  /** unique or primary key constraint on columns "product_id", "label" */
  ProductProductLabelsProductLabelPkey = 'product_product_labels_product_label_pkey'
}

/** input type for inserting data into table "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  label?: InputMaybe<Product_Label_Name_Enum>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Product_Product_Labels_Product_Label_Max_Fields = {
  __typename?: 'product_product_labels_product_label_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Product_Labels_Product_Label_Min_Fields = {
  __typename?: 'product_product_labels_product_label_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Mutation_Response = {
  __typename?: 'product_product_labels_product_label_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Product_Labels_Product_Label>;
};

/** on_conflict condition type for table "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_On_Conflict = {
  constraint: Product_Product_Labels_Product_Label_Constraint;
  update_columns?: Array<Product_Product_Labels_Product_Label_Update_Column>;
  where?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
};

/** Ordering options when selecting data from "product_product_labels_product_label". */
export type Product_Product_Labels_Product_Label_Order_By = {
  created_at?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_product_labels_product_label */
export type Product_Product_Labels_Product_Label_Pk_Columns_Input = {
  label: Product_Label_Name_Enum;
  product_id: Scalars['uuid']['input'];
};

/** select columns of table "product_product_labels_product_label" */
export enum Product_Product_Labels_Product_Label_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Label = 'label',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  label?: InputMaybe<Product_Label_Name_Enum>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "product_product_labels_product_label" */
export type Product_Product_Labels_Product_Label_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Product_Labels_Product_Label_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Product_Labels_Product_Label_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  label?: InputMaybe<Product_Label_Name_Enum>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "product_product_labels_product_label" */
export enum Product_Product_Labels_Product_Label_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Label = 'label',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Product_Product_Labels_Product_Label_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Product_Labels_Product_Label_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Product_Labels_Product_Label_Bool_Exp;
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  Allergens = 'allergens',
  /** column name */
  Calories = 'calories',
  /** column name */
  CaloriesKj = 'calories_kj',
  /** column name */
  Carbohydrate = 'carbohydrate',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Description = 'description',
  /** column name */
  Fat = 'fat',
  /** column name */
  Id = 'id',
  /** column name */
  Ingredients = 'ingredients',
  /** column name */
  Name = 'name',
  /** column name */
  Protein = 'protein',
  /** column name */
  SaturatedFat = 'saturated_fat',
  /** column name */
  ShelfLifeDuration = 'shelf_life_duration',
  /** column name */
  ShelfLifeDurationType = 'shelf_life_duration_type',
  /** column name */
  Sku = 'sku',
  /** column name */
  Sodium = 'sodium',
  /** column name */
  SubFamily = 'sub_family',
  /** column name */
  Sugars = 'sugars',
  /** column name */
  VatRate = 'vat_rate'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  allergens?: InputMaybe<Scalars['String']['input']>;
  calories?: InputMaybe<Scalars['numeric']['input']>;
  calories_kj?: InputMaybe<Scalars['numeric']['input']>;
  carbohydrate?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fat?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ingredients?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protein?: InputMaybe<Scalars['numeric']['input']>;
  saturated_fat?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration_type?: InputMaybe<Duration_Type_Enum>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  sodium?: InputMaybe<Scalars['numeric']['input']>;
  sub_family?: InputMaybe<Scalars['String']['input']>;
  sugars?: InputMaybe<Scalars['numeric']['input']>;
  vat_rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: 'product_stddev_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "product" */
export type Product_Stddev_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: 'product_stddev_pop_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "product" */
export type Product_Stddev_Pop_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: 'product_stddev_samp_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "product" */
export type Product_Stddev_Samp_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product" */
export type Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
  allergens?: InputMaybe<Scalars['String']['input']>;
  calories?: InputMaybe<Scalars['numeric']['input']>;
  calories_kj?: InputMaybe<Scalars['numeric']['input']>;
  carbohydrate?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deprecated_sku?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fat?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ingredients?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protein?: InputMaybe<Scalars['numeric']['input']>;
  saturated_fat?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration?: InputMaybe<Scalars['numeric']['input']>;
  shelf_life_duration_type?: InputMaybe<Duration_Type_Enum>;
  sku?: InputMaybe<Scalars['Int']['input']>;
  sodium?: InputMaybe<Scalars['numeric']['input']>;
  sub_family?: InputMaybe<Scalars['String']['input']>;
  sugars?: InputMaybe<Scalars['numeric']['input']>;
  vat_rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** columns and relationships of "product_sub_family" */
export type Product_Sub_Family = {
  __typename?: 'product_sub_family';
  name: Product_Sub_Family_Name_Enum;
  product_family_name: Scalars['String']['output'];
};

/** aggregated selection of "product_sub_family" */
export type Product_Sub_Family_Aggregate = {
  __typename?: 'product_sub_family_aggregate';
  aggregate?: Maybe<Product_Sub_Family_Aggregate_Fields>;
  nodes: Array<Product_Sub_Family>;
};

export type Product_Sub_Family_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Sub_Family_Aggregate_Bool_Exp_Count>;
};

export type Product_Sub_Family_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Sub_Family_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Sub_Family_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_sub_family" */
export type Product_Sub_Family_Aggregate_Fields = {
  __typename?: 'product_sub_family_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Product_Sub_Family_Max_Fields>;
  min?: Maybe<Product_Sub_Family_Min_Fields>;
};


/** aggregate fields of "product_sub_family" */
export type Product_Sub_Family_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Sub_Family_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "product_sub_family" */
export type Product_Sub_Family_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Sub_Family_Max_Order_By>;
  min?: InputMaybe<Product_Sub_Family_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_sub_family" */
export type Product_Sub_Family_Arr_Rel_Insert_Input = {
  data: Array<Product_Sub_Family_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Sub_Family_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_sub_family". All fields are combined with a logical 'AND'. */
export type Product_Sub_Family_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Sub_Family_Bool_Exp>>;
  _not?: InputMaybe<Product_Sub_Family_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Sub_Family_Bool_Exp>>;
  name?: InputMaybe<Product_Sub_Family_Name_Enum_Comparison_Exp>;
  product_family_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_sub_family" */
export enum Product_Sub_Family_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProductSubFamilyPkey = 'product_sub_family_pkey'
}

/** input type for inserting data into table "product_sub_family" */
export type Product_Sub_Family_Insert_Input = {
  name?: InputMaybe<Product_Sub_Family_Name_Enum>;
  product_family_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Product_Sub_Family_Max_Fields = {
  __typename?: 'product_sub_family_max_fields';
  product_family_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "product_sub_family" */
export type Product_Sub_Family_Max_Order_By = {
  product_family_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Sub_Family_Min_Fields = {
  __typename?: 'product_sub_family_min_fields';
  product_family_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "product_sub_family" */
export type Product_Sub_Family_Min_Order_By = {
  product_family_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_sub_family" */
export type Product_Sub_Family_Mutation_Response = {
  __typename?: 'product_sub_family_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Sub_Family>;
};

/** columns and relationships of "product_sub_family_name" */
export type Product_Sub_Family_Name = {
  __typename?: 'product_sub_family_name';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "product_sub_family_name" */
export type Product_Sub_Family_Name_Aggregate = {
  __typename?: 'product_sub_family_name_aggregate';
  aggregate?: Maybe<Product_Sub_Family_Name_Aggregate_Fields>;
  nodes: Array<Product_Sub_Family_Name>;
};

/** aggregate fields of "product_sub_family_name" */
export type Product_Sub_Family_Name_Aggregate_Fields = {
  __typename?: 'product_sub_family_name_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Product_Sub_Family_Name_Max_Fields>;
  min?: Maybe<Product_Sub_Family_Name_Min_Fields>;
};


/** aggregate fields of "product_sub_family_name" */
export type Product_Sub_Family_Name_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Sub_Family_Name_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "product_sub_family_name". All fields are combined with a logical 'AND'. */
export type Product_Sub_Family_Name_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Sub_Family_Name_Bool_Exp>>;
  _not?: InputMaybe<Product_Sub_Family_Name_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Sub_Family_Name_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_sub_family_name" */
export enum Product_Sub_Family_Name_Constraint {
  /** unique or primary key constraint on columns "value" */
  ProductSubFamilyNamePkey = 'product_sub_family_name_pkey'
}

export enum Product_Sub_Family_Name_Enum {
  Bakery = 'BAKERY',
  BeerCider = 'BEER_CIDER',
  ClothingTextile = 'CLOTHING_TEXTILE',
  Dairy = 'DAIRY',
  Deli = 'DELI',
  FishSeafood = 'FISH_SEAFOOD',
  Floral = 'FLORAL',
  FrozenFood = 'FROZEN_FOOD',
  Fruits = 'FRUITS',
  /**   */
  GamesToys = 'GAMES_TOYS',
  HouseholdCleaning = 'HOUSEHOLD_CLEANING',
  HygieneBeauty = 'HYGIENE_BEAUTY',
  NonAlcoholicBeverages = 'NON_ALCOHOLIC_BEVERAGES',
  Pets = 'PETS',
  RawMeat = 'RAW_MEAT',
  SaltyGrocery = 'SALTY_GROCERY',
  Spirits = 'SPIRITS',
  SweetGrocery = 'SWEET_GROCERY',
  Vegetables = 'VEGETABLES',
  VegetarianVegan = 'VEGETARIAN_VEGAN',
  WineChampagneSparkling = 'WINE_CHAMPAGNE_SPARKLING'
}

/** Boolean expression to compare columns of type "product_sub_family_name_enum". All fields are combined with logical 'AND'. */
export type Product_Sub_Family_Name_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Product_Sub_Family_Name_Enum>;
  _in?: InputMaybe<Array<Product_Sub_Family_Name_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Product_Sub_Family_Name_Enum>;
  _nin?: InputMaybe<Array<Product_Sub_Family_Name_Enum>>;
};

/** input type for inserting data into table "product_sub_family_name" */
export type Product_Sub_Family_Name_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Product_Sub_Family_Name_Max_Fields = {
  __typename?: 'product_sub_family_name_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Product_Sub_Family_Name_Min_Fields = {
  __typename?: 'product_sub_family_name_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "product_sub_family_name" */
export type Product_Sub_Family_Name_Mutation_Response = {
  __typename?: 'product_sub_family_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Sub_Family_Name>;
};

/** on_conflict condition type for table "product_sub_family_name" */
export type Product_Sub_Family_Name_On_Conflict = {
  constraint: Product_Sub_Family_Name_Constraint;
  update_columns?: Array<Product_Sub_Family_Name_Update_Column>;
  where?: InputMaybe<Product_Sub_Family_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "product_sub_family_name". */
export type Product_Sub_Family_Name_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_sub_family_name */
export type Product_Sub_Family_Name_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "product_sub_family_name" */
export enum Product_Sub_Family_Name_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "product_sub_family_name" */
export type Product_Sub_Family_Name_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "product_sub_family_name" */
export type Product_Sub_Family_Name_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Sub_Family_Name_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Sub_Family_Name_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "product_sub_family_name" */
export enum Product_Sub_Family_Name_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Product_Sub_Family_Name_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Sub_Family_Name_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Sub_Family_Name_Bool_Exp;
};

/** input type for inserting object relation for remote table "product_sub_family" */
export type Product_Sub_Family_Obj_Rel_Insert_Input = {
  data: Product_Sub_Family_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Sub_Family_On_Conflict>;
};

/** on_conflict condition type for table "product_sub_family" */
export type Product_Sub_Family_On_Conflict = {
  constraint: Product_Sub_Family_Constraint;
  update_columns?: Array<Product_Sub_Family_Update_Column>;
  where?: InputMaybe<Product_Sub_Family_Bool_Exp>;
};

/** Ordering options when selecting data from "product_sub_family". */
export type Product_Sub_Family_Order_By = {
  name?: InputMaybe<Order_By>;
  product_family_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_sub_family */
export type Product_Sub_Family_Pk_Columns_Input = {
  name: Product_Sub_Family_Name_Enum;
};

/** select columns of table "product_sub_family" */
export enum Product_Sub_Family_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  ProductFamilyName = 'product_family_name'
}

/** input type for updating data in table "product_sub_family" */
export type Product_Sub_Family_Set_Input = {
  name?: InputMaybe<Product_Sub_Family_Name_Enum>;
  product_family_name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "product_sub_family" */
export type Product_Sub_Family_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Sub_Family_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Sub_Family_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Product_Sub_Family_Name_Enum>;
  product_family_name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "product_sub_family" */
export enum Product_Sub_Family_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  ProductFamilyName = 'product_family_name'
}

export type Product_Sub_Family_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Sub_Family_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Sub_Family_Bool_Exp;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: 'product_sum_fields';
  calories?: Maybe<Scalars['numeric']['output']>;
  calories_kj?: Maybe<Scalars['numeric']['output']>;
  carbohydrate?: Maybe<Scalars['numeric']['output']>;
  fat?: Maybe<Scalars['numeric']['output']>;
  protein?: Maybe<Scalars['numeric']['output']>;
  saturated_fat?: Maybe<Scalars['numeric']['output']>;
  shelf_life_duration?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
  sodium?: Maybe<Scalars['numeric']['output']>;
  sugars?: Maybe<Scalars['numeric']['output']>;
  vat_rate?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "product" */
export type Product_Sum_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  Allergens = 'allergens',
  /** column name */
  Calories = 'calories',
  /** column name */
  CaloriesKj = 'calories_kj',
  /** column name */
  Carbohydrate = 'carbohydrate',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeprecatedSku = 'deprecated_sku',
  /** column name */
  Description = 'description',
  /** column name */
  Fat = 'fat',
  /** column name */
  Id = 'id',
  /** column name */
  Ingredients = 'ingredients',
  /** column name */
  Name = 'name',
  /** column name */
  Protein = 'protein',
  /** column name */
  SaturatedFat = 'saturated_fat',
  /** column name */
  ShelfLifeDuration = 'shelf_life_duration',
  /** column name */
  ShelfLifeDurationType = 'shelf_life_duration_type',
  /** column name */
  Sku = 'sku',
  /** column name */
  Sodium = 'sodium',
  /** column name */
  SubFamily = 'sub_family',
  /** column name */
  Sugars = 'sugars',
  /** column name */
  VatRate = 'vat_rate'
}

export type Product_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: 'product_var_pop_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "product" */
export type Product_Var_Pop_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: 'product_var_samp_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "product" */
export type Product_Var_Samp_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: 'product_variance_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_kj?: Maybe<Scalars['Float']['output']>;
  carbohydrate?: Maybe<Scalars['Float']['output']>;
  fat?: Maybe<Scalars['Float']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  saturated_fat?: Maybe<Scalars['Float']['output']>;
  shelf_life_duration?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
  sodium?: Maybe<Scalars['Float']['output']>;
  sugars?: Maybe<Scalars['Float']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "product" */
export type Product_Variance_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_kj?: InputMaybe<Order_By>;
  carbohydrate?: InputMaybe<Order_By>;
  fat?: InputMaybe<Order_By>;
  protein?: InputMaybe<Order_By>;
  saturated_fat?: InputMaybe<Order_By>;
  shelf_life_duration?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  sodium?: InputMaybe<Order_By>;
  sugars?: InputMaybe<Order_By>;
  vat_rate?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "act_hi_procinst" */
  act_hi_procinst: Array<Act_Hi_Procinst>;
  /** fetch aggregated fields from the table: "act_hi_procinst" */
  act_hi_procinst_aggregate: Act_Hi_Procinst_Aggregate;
  /** fetch data from the table: "act_hi_procinst" using primary key columns */
  act_hi_procinst_by_pk?: Maybe<Act_Hi_Procinst>;
  /** fetch data from the table: "act_hi_taskinst" */
  act_hi_taskinst: Array<Act_Hi_Taskinst>;
  /** fetch aggregated fields from the table: "act_hi_taskinst" */
  act_hi_taskinst_aggregate: Act_Hi_Taskinst_Aggregate;
  /** fetch data from the table: "act_hi_taskinst" using primary key columns */
  act_hi_taskinst_by_pk?: Maybe<Act_Hi_Taskinst>;
  /** fetch data from the table: "act_ru_event_subscr" */
  act_ru_event_subscr: Array<Act_Ru_Event_Subscr>;
  /** fetch aggregated fields from the table: "act_ru_event_subscr" */
  act_ru_event_subscr_aggregate: Act_Ru_Event_Subscr_Aggregate;
  /** fetch data from the table: "act_ru_event_subscr" using primary key columns */
  act_ru_event_subscr_by_pk?: Maybe<Act_Ru_Event_Subscr>;
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "administrative_area" */
  administrative_area: Array<Administrative_Area>;
  /** fetch aggregated fields from the table: "administrative_area" */
  administrative_area_aggregate: Administrative_Area_Aggregate;
  /** fetch data from the table: "administrative_area" using primary key columns */
  administrative_area_by_pk?: Maybe<Administrative_Area>;
  all_contracts_total_price: Array<Contract_Total_Price>;
  /** fetch data from the table: "authentication_code" */
  authentication_code: Array<Authentication_Code>;
  /** fetch aggregated fields from the table: "authentication_code" */
  authentication_code_aggregate: Authentication_Code_Aggregate;
  /** fetch data from the table: "authentication_code" using primary key columns */
  authentication_code_by_pk?: Maybe<Authentication_Code>;
  /** fetch data from the table: "authentication_verification_type" */
  authentication_verification_type: Array<Authentication_Verification_Type>;
  /** fetch aggregated fields from the table: "authentication_verification_type" */
  authentication_verification_type_aggregate: Authentication_Verification_Type_Aggregate;
  /** fetch data from the table: "authentication_verification_type" using primary key columns */
  authentication_verification_type_by_pk?: Maybe<Authentication_Verification_Type>;
  /** fetch data from the table: "availability_status" */
  availability_status: Array<Availability_Status>;
  /** fetch aggregated fields from the table: "availability_status" */
  availability_status_aggregate: Availability_Status_Aggregate;
  /** fetch data from the table: "availability_status" using primary key columns */
  availability_status_by_pk?: Maybe<Availability_Status>;
  /** fetch data from the table: "base_unit" */
  base_unit: Array<Base_Unit>;
  /** fetch aggregated fields from the table: "base_unit" */
  base_unit_aggregate: Base_Unit_Aggregate;
  /** fetch data from the table: "base_unit" using primary key columns */
  base_unit_by_pk?: Maybe<Base_Unit>;
  /** fetch data from the table: "base_unit_history" */
  base_unit_history: Array<Base_Unit_History>;
  /** fetch aggregated fields from the table: "base_unit_history" */
  base_unit_history_aggregate: Base_Unit_History_Aggregate;
  /** fetch data from the table: "base_unit_history" using primary key columns */
  base_unit_history_by_pk?: Maybe<Base_Unit_History>;
  /** fetch data from the table: "base_unit_photo" */
  base_unit_photo: Array<Base_Unit_Photo>;
  /** fetch aggregated fields from the table: "base_unit_photo" */
  base_unit_photo_aggregate: Base_Unit_Photo_Aggregate;
  /** fetch data from the table: "base_unit_photo" using primary key columns */
  base_unit_photo_by_pk?: Maybe<Base_Unit_Photo>;
  /** fetch data from the table: "base_unit_photo_history" */
  base_unit_photo_history: Array<Base_Unit_Photo_History>;
  /** fetch aggregated fields from the table: "base_unit_photo_history" */
  base_unit_photo_history_aggregate: Base_Unit_Photo_History_Aggregate;
  /** fetch data from the table: "base_unit_photo_history" using primary key columns */
  base_unit_photo_history_by_pk?: Maybe<Base_Unit_Photo_History>;
  /** fetch data from the table: "base_unit_type" */
  base_unit_type: Array<Base_Unit_Type>;
  /** fetch aggregated fields from the table: "base_unit_type" */
  base_unit_type_aggregate: Base_Unit_Type_Aggregate;
  /** fetch data from the table: "base_unit_type" using primary key columns */
  base_unit_type_by_pk?: Maybe<Base_Unit_Type>;
  /** fetch data from the table: "basket" */
  basket: Array<Basket>;
  /** fetch aggregated fields from the table: "basket" */
  basket_aggregate: Basket_Aggregate;
  /** fetch data from the table: "basket" using primary key columns */
  basket_by_pk?: Maybe<Basket>;
  /** fetch data from the table: "basket_packaging_quantity" */
  basket_packaging_quantity: Array<Basket_Packaging_Quantity>;
  /** fetch aggregated fields from the table: "basket_packaging_quantity" */
  basket_packaging_quantity_aggregate: Basket_Packaging_Quantity_Aggregate;
  /** fetch data from the table: "basket_packaging_quantity" using primary key columns */
  basket_packaging_quantity_by_pk?: Maybe<Basket_Packaging_Quantity>;
  /** fetch data from the table: "basket_status" */
  basket_status: Array<Basket_Status>;
  /** fetch aggregated fields from the table: "basket_status" */
  basket_status_aggregate: Basket_Status_Aggregate;
  /** fetch data from the table: "basket_status" using primary key columns */
  basket_status_by_pk?: Maybe<Basket_Status>;
  /** fetch data from the table: "billing_type" */
  billing_type: Array<Billing_Type>;
  /** fetch aggregated fields from the table: "billing_type" */
  billing_type_aggregate: Billing_Type_Aggregate;
  /** fetch data from the table: "billing_type" using primary key columns */
  billing_type_by_pk?: Maybe<Billing_Type>;
  /** fetch data from the table: "brand" */
  brand: Array<Brand>;
  /** fetch aggregated fields from the table: "brand" */
  brand_aggregate: Brand_Aggregate;
  /** fetch data from the table: "brand" using primary key columns */
  brand_by_pk?: Maybe<Brand>;
  /** fetch data from the table: "business_profile" */
  business_profile: Array<Business_Profile>;
  /** fetch aggregated fields from the table: "business_profile" */
  business_profile_aggregate: Business_Profile_Aggregate;
  /** fetch data from the table: "business_profile" using primary key columns */
  business_profile_by_pk?: Maybe<Business_Profile>;
  /** fetch data from the table: "catalog" */
  catalog: Array<Catalog>;
  /** fetch aggregated fields from the table: "catalog" */
  catalog_aggregate: Catalog_Aggregate;
  /** fetch data from the table: "catalog" using primary key columns */
  catalog_by_pk?: Maybe<Catalog>;
  /** fetch data from the table: "catalog_packaging" */
  catalog_packaging: Array<Catalog_Packaging>;
  /** fetch aggregated fields from the table: "catalog_packaging" */
  catalog_packaging_aggregate: Catalog_Packaging_Aggregate;
  /** fetch data from the table: "catalog_packaging" using primary key columns */
  catalog_packaging_by_pk?: Maybe<Catalog_Packaging>;
  /** fetch data from the table: "catalog_packaging_history" */
  catalog_packaging_history: Array<Catalog_Packaging_History>;
  /** fetch aggregated fields from the table: "catalog_packaging_history" */
  catalog_packaging_history_aggregate: Catalog_Packaging_History_Aggregate;
  /** fetch data from the table: "catalog_packaging_history" using primary key columns */
  catalog_packaging_history_by_pk?: Maybe<Catalog_Packaging_History>;
  /** fetch data from the table: "comment" */
  comment: Array<Comment>;
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: Comment_Aggregate;
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>;
  /** fetch data from the table: "comment_history" */
  comment_history: Array<Comment_History>;
  /** fetch aggregated fields from the table: "comment_history" */
  comment_history_aggregate: Comment_History_Aggregate;
  /** fetch data from the table: "comment_history" using primary key columns */
  comment_history_by_pk?: Maybe<Comment_History>;
  /** fetch data from the table: "comment_type" */
  comment_type: Array<Comment_Type>;
  /** fetch aggregated fields from the table: "comment_type" */
  comment_type_aggregate: Comment_Type_Aggregate;
  /** fetch data from the table: "comment_type" using primary key columns */
  comment_type_by_pk?: Maybe<Comment_Type>;
  /** fetch data from the table: "commission" */
  commission: Array<Commission>;
  /** fetch aggregated fields from the table: "commission" */
  commission_aggregate: Commission_Aggregate;
  /** fetch data from the table: "commission" using primary key columns */
  commission_by_pk?: Maybe<Commission>;
  /** fetch data from the table: "commission_type" */
  commission_type: Array<Commission_Type>;
  /** fetch aggregated fields from the table: "commission_type" */
  commission_type_aggregate: Commission_Type_Aggregate;
  /** fetch data from the table: "commission_type" using primary key columns */
  commission_type_by_pk?: Maybe<Commission_Type>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "company_photo" */
  company_photo: Array<Company_Photo>;
  /** fetch aggregated fields from the table: "company_photo" */
  company_photo_aggregate: Company_Photo_Aggregate;
  /** fetch data from the table: "company_photo" using primary key columns */
  company_photo_by_pk?: Maybe<Company_Photo>;
  company_product_count_by_sub_family: Array<Company_Product_Sub_Family_Count>;
  /** fetch data from the table: "contract" */
  contract: Array<Contract>;
  /** fetch aggregated fields from the table: "contract" */
  contract_aggregate: Contract_Aggregate;
  /** fetch data from the table: "contract" using primary key columns */
  contract_by_pk?: Maybe<Contract>;
  /** fetch data from the table: "contract_history" */
  contract_history: Array<Contract_History>;
  /** fetch aggregated fields from the table: "contract_history" */
  contract_history_aggregate: Contract_History_Aggregate;
  /** fetch data from the table: "contract_history" using primary key columns */
  contract_history_by_pk?: Maybe<Contract_History>;
  /** fetch data from the table: "contract_status" */
  contract_status: Array<Contract_Status>;
  /** fetch aggregated fields from the table: "contract_status" */
  contract_status_aggregate: Contract_Status_Aggregate;
  /** fetch data from the table: "contract_status" using primary key columns */
  contract_status_by_pk?: Maybe<Contract_Status>;
  contract_total_price: Array<Contract_Total_Price>;
  /** fetch data from the table: "delivery_zone" */
  delivery_zone: Array<Delivery_Zone>;
  /** fetch data from the table: "delivery_zone_administrative_area" */
  delivery_zone_administrative_area: Array<Delivery_Zone_Administrative_Area>;
  /** fetch aggregated fields from the table: "delivery_zone_administrative_area" */
  delivery_zone_administrative_area_aggregate: Delivery_Zone_Administrative_Area_Aggregate;
  /** fetch data from the table: "delivery_zone_administrative_area" using primary key columns */
  delivery_zone_administrative_area_by_pk?: Maybe<Delivery_Zone_Administrative_Area>;
  /** fetch aggregated fields from the table: "delivery_zone" */
  delivery_zone_aggregate: Delivery_Zone_Aggregate;
  /** fetch data from the table: "delivery_zone" using primary key columns */
  delivery_zone_by_pk?: Maybe<Delivery_Zone>;
  /** fetch data from the table: "document" */
  document: Array<Document>;
  /** fetch aggregated fields from the table: "document" */
  document_aggregate: Document_Aggregate;
  /** fetch data from the table: "document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table: "document_type" */
  document_type: Array<Document_Type>;
  /** fetch aggregated fields from the table: "document_type" */
  document_type_aggregate: Document_Type_Aggregate;
  /** fetch data from the table: "document_type" using primary key columns */
  document_type_by_pk?: Maybe<Document_Type>;
  /** fetch data from the table: "duration_type" */
  duration_type: Array<Duration_Type>;
  /** fetch aggregated fields from the table: "duration_type" */
  duration_type_aggregate: Duration_Type_Aggregate;
  /** fetch data from the table: "duration_type" using primary key columns */
  duration_type_by_pk?: Maybe<Duration_Type>;
  getDownloadUrl?: Maybe<GetDownloadUrlOutput>;
  getOrderNextActions?: Maybe<NextActions>;
  /** getOrderRecommendations */
  getOrderRecommendations?: Maybe<GetOrderRecommendationsOutput>;
  getUploadUrl?: Maybe<GetUploadUrlOutput>;
  /** get a user's contact information */
  getUserContactInformation?: Maybe<UserContactInformationOutput>;
  /** fetch data from the table: "global_product_family" */
  global_product_family: Array<Global_Product_Family>;
  /** fetch aggregated fields from the table: "global_product_family" */
  global_product_family_aggregate: Global_Product_Family_Aggregate;
  /** fetch data from the table: "global_product_family" using primary key columns */
  global_product_family_by_pk?: Maybe<Global_Product_Family>;
  /** fetch data from the table: "gtin_type" */
  gtin_type: Array<Gtin_Type>;
  /** fetch aggregated fields from the table: "gtin_type" */
  gtin_type_aggregate: Gtin_Type_Aggregate;
  /** fetch data from the table: "gtin_type" using primary key columns */
  gtin_type_by_pk?: Maybe<Gtin_Type>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table: "invoice_type" */
  invoice_type: Array<Invoice_Type>;
  /** fetch aggregated fields from the table: "invoice_type" */
  invoice_type_aggregate: Invoice_Type_Aggregate;
  /** fetch data from the table: "invoice_type" using primary key columns */
  invoice_type_by_pk?: Maybe<Invoice_Type>;
  /** fetch data from the table: "measurement_unit" */
  measurement_unit: Array<Measurement_Unit>;
  /** fetch aggregated fields from the table: "measurement_unit" */
  measurement_unit_aggregate: Measurement_Unit_Aggregate;
  /** fetch data from the table: "measurement_unit" using primary key columns */
  measurement_unit_by_pk?: Maybe<Measurement_Unit>;
  /** fetch data from the table: "non_reconciliated_moneyin" */
  non_reconciliated_moneyin: Array<Non_Reconciliated_Moneyin>;
  /** fetch aggregated fields from the table: "non_reconciliated_moneyin" */
  non_reconciliated_moneyin_aggregate: Non_Reconciliated_Moneyin_Aggregate;
  /** fetch data from the table: "non_reconciliated_moneyin" using primary key columns */
  non_reconciliated_moneyin_by_pk?: Maybe<Non_Reconciliated_Moneyin>;
  /** fetch data from the table: "notification_business_profile" */
  notification_business_profile: Array<Notification_Business_Profile>;
  /** fetch aggregated fields from the table: "notification_business_profile" */
  notification_business_profile_aggregate: Notification_Business_Profile_Aggregate;
  /** fetch data from the table: "notification_business_profile" using primary key columns */
  notification_business_profile_by_pk?: Maybe<Notification_Business_Profile>;
  /** fetch data from the table: "notification_code" */
  notification_code: Array<Notification_Code>;
  /** fetch aggregated fields from the table: "notification_code" */
  notification_code_aggregate: Notification_Code_Aggregate;
  /** fetch data from the table: "notification_code" using primary key columns */
  notification_code_by_pk?: Maybe<Notification_Code>;
  /** fetch data from the table: "notification_default" */
  notification_default: Array<Notification_Default>;
  /** fetch aggregated fields from the table: "notification_default" */
  notification_default_aggregate: Notification_Default_Aggregate;
  /** fetch data from the table: "notification_default" using primary key columns */
  notification_default_by_pk?: Maybe<Notification_Default>;
  /** fetch data from the table: "notification_method" */
  notification_method: Array<Notification_Method>;
  /** fetch aggregated fields from the table: "notification_method" */
  notification_method_aggregate: Notification_Method_Aggregate;
  /** fetch data from the table: "notification_method" using primary key columns */
  notification_method_by_pk?: Maybe<Notification_Method>;
  /** fetch data from the table: "notification_recipient" */
  notification_recipient: Array<Notification_Recipient>;
  /** fetch aggregated fields from the table: "notification_recipient" */
  notification_recipient_aggregate: Notification_Recipient_Aggregate;
  /** fetch data from the table: "notification_recipient" using primary key columns */
  notification_recipient_by_pk?: Maybe<Notification_Recipient>;
  /** fetch data from the table: "notification_scope" */
  notification_scope: Array<Notification_Scope>;
  /** fetch aggregated fields from the table: "notification_scope" */
  notification_scope_aggregate: Notification_Scope_Aggregate;
  /** fetch data from the table: "notification_scope" using primary key columns */
  notification_scope_by_pk?: Maybe<Notification_Scope>;
  /** An array relationship */
  notification_subscription: Array<Notification_Subscription>;
  /** An aggregate relationship */
  notification_subscription_aggregate: Notification_Subscription_Aggregate;
  /** fetch data from the table: "notification_subscription" using primary key columns */
  notification_subscription_by_pk?: Maybe<Notification_Subscription>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_history" */
  order_history: Array<Order_History>;
  /** fetch aggregated fields from the table: "order_history" */
  order_history_aggregate: Order_History_Aggregate;
  /** fetch data from the table: "order_history" using primary key columns */
  order_history_by_pk?: Maybe<Order_History>;
  /** fetch data from the table: "order_packaging_quantity" */
  order_packaging_quantity: Array<Order_Packaging_Quantity>;
  /** fetch aggregated fields from the table: "order_packaging_quantity" */
  order_packaging_quantity_aggregate: Order_Packaging_Quantity_Aggregate;
  /** fetch data from the table: "order_packaging_quantity" using primary key columns */
  order_packaging_quantity_by_pk?: Maybe<Order_Packaging_Quantity>;
  /** fetch data from the table: "order_status" */
  order_status: Array<Order_Status>;
  /** fetch aggregated fields from the table: "order_status" */
  order_status_aggregate: Order_Status_Aggregate;
  /** fetch data from the table: "order_status" using primary key columns */
  order_status_by_pk?: Maybe<Order_Status>;
  /** fetch data from the table: "packaging" */
  packaging: Array<Packaging>;
  /** fetch aggregated fields from the table: "packaging" */
  packaging_aggregate: Packaging_Aggregate;
  /** fetch data from the table: "packaging" using primary key columns */
  packaging_by_pk?: Maybe<Packaging>;
  /** fetch data from the table: "packaging_history" */
  packaging_history: Array<Packaging_History>;
  /** fetch aggregated fields from the table: "packaging_history" */
  packaging_history_aggregate: Packaging_History_Aggregate;
  /** fetch data from the table: "packaging_history" using primary key columns */
  packaging_history_by_pk?: Maybe<Packaging_History>;
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>;
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate;
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>;
  /** fetch data from the table: "payment_process_status" */
  payment_process_status: Array<Payment_Process_Status>;
  /** fetch aggregated fields from the table: "payment_process_status" */
  payment_process_status_aggregate: Payment_Process_Status_Aggregate;
  /** fetch data from the table: "payment_process_status" using primary key columns */
  payment_process_status_by_pk?: Maybe<Payment_Process_Status>;
  /** fetch data from the table: "photo" */
  photo: Array<Photo>;
  /** fetch aggregated fields from the table: "photo" */
  photo_aggregate: Photo_Aggregate;
  /** fetch data from the table: "photo" using primary key columns */
  photo_by_pk?: Maybe<Photo>;
  /** fetch data from the table: "privacy_setting" */
  privacy_setting: Array<Privacy_Setting>;
  /** fetch aggregated fields from the table: "privacy_setting" */
  privacy_setting_aggregate: Privacy_Setting_Aggregate;
  /** fetch data from the table: "privacy_setting" using primary key columns */
  privacy_setting_by_pk?: Maybe<Privacy_Setting>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_family" */
  product_family: Array<Product_Family>;
  /** fetch aggregated fields from the table: "product_family" */
  product_family_aggregate: Product_Family_Aggregate;
  /** fetch data from the table: "product_family" using primary key columns */
  product_family_by_pk?: Maybe<Product_Family>;
  /** fetch data from the table: "product_family_name" */
  product_family_name: Array<Product_Family_Name>;
  /** fetch aggregated fields from the table: "product_family_name" */
  product_family_name_aggregate: Product_Family_Name_Aggregate;
  /** fetch data from the table: "product_family_name" using primary key columns */
  product_family_name_by_pk?: Maybe<Product_Family_Name>;
  /** fetch data from the table: "product_history" */
  product_history: Array<Product_History>;
  /** fetch aggregated fields from the table: "product_history" */
  product_history_aggregate: Product_History_Aggregate;
  /** fetch data from the table: "product_history" using primary key columns */
  product_history_by_pk?: Maybe<Product_History>;
  /** fetch data from the table: "product_label_name" */
  product_label_name: Array<Product_Label_Name>;
  /** fetch aggregated fields from the table: "product_label_name" */
  product_label_name_aggregate: Product_Label_Name_Aggregate;
  /** fetch data from the table: "product_label_name" using primary key columns */
  product_label_name_by_pk?: Maybe<Product_Label_Name>;
  /** fetch data from the table: "product_product_labels_product_label" */
  product_product_labels_product_label: Array<Product_Product_Labels_Product_Label>;
  /** fetch aggregated fields from the table: "product_product_labels_product_label" */
  product_product_labels_product_label_aggregate: Product_Product_Labels_Product_Label_Aggregate;
  /** fetch data from the table: "product_product_labels_product_label" using primary key columns */
  product_product_labels_product_label_by_pk?: Maybe<Product_Product_Labels_Product_Label>;
  /** fetch data from the table: "product_sub_family" */
  product_sub_family: Array<Product_Sub_Family>;
  /** fetch aggregated fields from the table: "product_sub_family" */
  product_sub_family_aggregate: Product_Sub_Family_Aggregate;
  /** fetch data from the table: "product_sub_family" using primary key columns */
  product_sub_family_by_pk?: Maybe<Product_Sub_Family>;
  /** fetch data from the table: "product_sub_family_name" */
  product_sub_family_name: Array<Product_Sub_Family_Name>;
  /** fetch aggregated fields from the table: "product_sub_family_name" */
  product_sub_family_name_aggregate: Product_Sub_Family_Name_Aggregate;
  /** fetch data from the table: "product_sub_family_name" using primary key columns */
  product_sub_family_name_by_pk?: Maybe<Product_Sub_Family_Name>;
  /** fetch data from the table: "recently_updated_packaging_evolution" */
  recently_updated_packaging_evolution: Array<Recently_Updated_Packaging_Evolution>;
  /** fetch aggregated fields from the table: "recently_updated_packaging_evolution" */
  recently_updated_packaging_evolution_aggregate: Recently_Updated_Packaging_Evolution_Aggregate;
  /** fetch data from the table: "recommended_supplier" */
  recommended_supplier: Array<Recommended_Supplier>;
  /** fetch aggregated fields from the table: "recommended_supplier" */
  recommended_supplier_aggregate: Recommended_Supplier_Aggregate;
  /** fetch data from the table: "recommended_supplier" using primary key columns */
  recommended_supplier_by_pk?: Maybe<Recommended_Supplier>;
  /** fetch data from the table: "refresh_token" */
  refresh_token: Array<Refresh_Token>;
  /** fetch aggregated fields from the table: "refresh_token" */
  refresh_token_aggregate: Refresh_Token_Aggregate;
  /** fetch data from the table: "refresh_token" using primary key columns */
  refresh_token_by_pk?: Maybe<Refresh_Token>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role_assignment" */
  role_assignment: Array<Role_Assignment>;
  /** fetch aggregated fields from the table: "role_assignment" */
  role_assignment_aggregate: Role_Assignment_Aggregate;
  /** fetch data from the table: "role_assignment" using primary key columns */
  role_assignment_by_pk?: Maybe<Role_Assignment>;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table: "staff_role" */
  staff_role: Array<Staff_Role>;
  /** fetch aggregated fields from the table: "staff_role" */
  staff_role_aggregate: Staff_Role_Aggregate;
  /** fetch data from the table: "staff_role" using primary key columns */
  staff_role_by_pk?: Maybe<Staff_Role>;
  /** fetch data from the table: "staff_user" */
  staff_user: Array<Staff_User>;
  /** fetch aggregated fields from the table: "staff_user" */
  staff_user_aggregate: Staff_User_Aggregate;
  /** fetch data from the table: "staff_user" using primary key columns */
  staff_user_by_pk?: Maybe<Staff_User>;
  /** fetch data from the table: "trade_item_unit_descriptor" */
  trade_item_unit_descriptor: Array<Trade_Item_Unit_Descriptor>;
  /** fetch aggregated fields from the table: "trade_item_unit_descriptor" */
  trade_item_unit_descriptor_aggregate: Trade_Item_Unit_Descriptor_Aggregate;
  /** fetch data from the table: "trade_item_unit_descriptor" using primary key columns */
  trade_item_unit_descriptor_by_pk?: Maybe<Trade_Item_Unit_Descriptor>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_product_family" */
  user_product_family: Array<User_Product_Family>;
  /** fetch aggregated fields from the table: "user_product_family" */
  user_product_family_aggregate: User_Product_Family_Aggregate;
  /** fetch data from the table: "user_product_family" using primary key columns */
  user_product_family_by_pk?: Maybe<User_Product_Family>;
};


export type Query_RootAct_Hi_ProcinstArgs = {
  distinct_on?: InputMaybe<Array<Act_Hi_Procinst_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Hi_Procinst_Order_By>>;
  where?: InputMaybe<Act_Hi_Procinst_Bool_Exp>;
};


export type Query_RootAct_Hi_Procinst_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Act_Hi_Procinst_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Hi_Procinst_Order_By>>;
  where?: InputMaybe<Act_Hi_Procinst_Bool_Exp>;
};


export type Query_RootAct_Hi_Procinst_By_PkArgs = {
  id_: Scalars['String']['input'];
};


export type Query_RootAct_Hi_TaskinstArgs = {
  distinct_on?: InputMaybe<Array<Act_Hi_Taskinst_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Hi_Taskinst_Order_By>>;
  where?: InputMaybe<Act_Hi_Taskinst_Bool_Exp>;
};


export type Query_RootAct_Hi_Taskinst_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Act_Hi_Taskinst_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Hi_Taskinst_Order_By>>;
  where?: InputMaybe<Act_Hi_Taskinst_Bool_Exp>;
};


export type Query_RootAct_Hi_Taskinst_By_PkArgs = {
  id_: Scalars['String']['input'];
};


export type Query_RootAct_Ru_Event_SubscrArgs = {
  distinct_on?: InputMaybe<Array<Act_Ru_Event_Subscr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Ru_Event_Subscr_Order_By>>;
  where?: InputMaybe<Act_Ru_Event_Subscr_Bool_Exp>;
};


export type Query_RootAct_Ru_Event_Subscr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Act_Ru_Event_Subscr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Ru_Event_Subscr_Order_By>>;
  where?: InputMaybe<Act_Ru_Event_Subscr_Bool_Exp>;
};


export type Query_RootAct_Ru_Event_Subscr_By_PkArgs = {
  id_: Scalars['String']['input'];
};


export type Query_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAdministrative_AreaArgs = {
  distinct_on?: InputMaybe<Array<Administrative_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Administrative_Area_Order_By>>;
  where?: InputMaybe<Administrative_Area_Bool_Exp>;
};


export type Query_RootAdministrative_Area_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Administrative_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Administrative_Area_Order_By>>;
  where?: InputMaybe<Administrative_Area_Bool_Exp>;
};


export type Query_RootAdministrative_Area_By_PkArgs = {
  area: Scalars['String']['input'];
  country_code: Scalars['String']['input'];
};


export type Query_RootAll_Contracts_Total_PriceArgs = {
  args: All_Contracts_Total_Price_Arguments;
  distinct_on?: InputMaybe<Array<Contract_Total_Price_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Total_Price_Order_By>>;
  where?: InputMaybe<Contract_Total_Price_Bool_Exp_Bool_Exp>;
};


export type Query_RootAuthentication_CodeArgs = {
  distinct_on?: InputMaybe<Array<Authentication_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authentication_Code_Order_By>>;
  where?: InputMaybe<Authentication_Code_Bool_Exp>;
};


export type Query_RootAuthentication_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Authentication_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authentication_Code_Order_By>>;
  where?: InputMaybe<Authentication_Code_Bool_Exp>;
};


export type Query_RootAuthentication_Code_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthentication_Verification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Authentication_Verification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authentication_Verification_Type_Order_By>>;
  where?: InputMaybe<Authentication_Verification_Type_Bool_Exp>;
};


export type Query_RootAuthentication_Verification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Authentication_Verification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authentication_Verification_Type_Order_By>>;
  where?: InputMaybe<Authentication_Verification_Type_Bool_Exp>;
};


export type Query_RootAuthentication_Verification_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootAvailability_StatusArgs = {
  distinct_on?: InputMaybe<Array<Availability_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Availability_Status_Order_By>>;
  where?: InputMaybe<Availability_Status_Bool_Exp>;
};


export type Query_RootAvailability_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Availability_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Availability_Status_Order_By>>;
  where?: InputMaybe<Availability_Status_Bool_Exp>;
};


export type Query_RootAvailability_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootBase_UnitArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Order_By>>;
  where?: InputMaybe<Base_Unit_Bool_Exp>;
};


export type Query_RootBase_Unit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Order_By>>;
  where?: InputMaybe<Base_Unit_Bool_Exp>;
};


export type Query_RootBase_Unit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBase_Unit_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_History_Order_By>>;
  where?: InputMaybe<Base_Unit_History_Bool_Exp>;
};


export type Query_RootBase_Unit_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_History_Order_By>>;
  where?: InputMaybe<Base_Unit_History_Bool_Exp>;
};


export type Query_RootBase_Unit_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBase_Unit_PhotoArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
};


export type Query_RootBase_Unit_Photo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
};


export type Query_RootBase_Unit_Photo_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBase_Unit_Photo_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_History_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
};


export type Query_RootBase_Unit_Photo_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_History_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
};


export type Query_RootBase_Unit_Photo_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBase_Unit_TypeArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Type_Order_By>>;
  where?: InputMaybe<Base_Unit_Type_Bool_Exp>;
};


export type Query_RootBase_Unit_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Type_Order_By>>;
  where?: InputMaybe<Base_Unit_Type_Bool_Exp>;
};


export type Query_RootBase_Unit_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootBasketArgs = {
  distinct_on?: InputMaybe<Array<Basket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Order_By>>;
  where?: InputMaybe<Basket_Bool_Exp>;
};


export type Query_RootBasket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Basket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Order_By>>;
  where?: InputMaybe<Basket_Bool_Exp>;
};


export type Query_RootBasket_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBasket_Packaging_QuantityArgs = {
  distinct_on?: InputMaybe<Array<Basket_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
};


export type Query_RootBasket_Packaging_Quantity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Basket_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
};


export type Query_RootBasket_Packaging_Quantity_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBasket_StatusArgs = {
  distinct_on?: InputMaybe<Array<Basket_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Status_Order_By>>;
  where?: InputMaybe<Basket_Status_Bool_Exp>;
};


export type Query_RootBasket_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Basket_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Status_Order_By>>;
  where?: InputMaybe<Basket_Status_Bool_Exp>;
};


export type Query_RootBasket_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootBilling_TypeArgs = {
  distinct_on?: InputMaybe<Array<Billing_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Type_Order_By>>;
  where?: InputMaybe<Billing_Type_Bool_Exp>;
};


export type Query_RootBilling_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Type_Order_By>>;
  where?: InputMaybe<Billing_Type_Bool_Exp>;
};


export type Query_RootBilling_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootBrandArgs = {
  distinct_on?: InputMaybe<Array<Brand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Brand_Order_By>>;
  where?: InputMaybe<Brand_Bool_Exp>;
};


export type Query_RootBrand_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Brand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Brand_Order_By>>;
  where?: InputMaybe<Brand_Bool_Exp>;
};


export type Query_RootBrand_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootBusiness_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Business_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Business_Profile_Order_By>>;
  where?: InputMaybe<Business_Profile_Bool_Exp>;
};


export type Query_RootBusiness_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Business_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Business_Profile_Order_By>>;
  where?: InputMaybe<Business_Profile_Bool_Exp>;
};


export type Query_RootBusiness_Profile_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootCatalogArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Order_By>>;
  where?: InputMaybe<Catalog_Bool_Exp>;
};


export type Query_RootCatalog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Order_By>>;
  where?: InputMaybe<Catalog_Bool_Exp>;
};


export type Query_RootCatalog_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCatalog_PackagingArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Packaging_Order_By>>;
  where?: InputMaybe<Catalog_Packaging_Bool_Exp>;
};


export type Query_RootCatalog_Packaging_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Packaging_Order_By>>;
  where?: InputMaybe<Catalog_Packaging_Bool_Exp>;
};


export type Query_RootCatalog_Packaging_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCatalog_Packaging_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Packaging_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Packaging_History_Order_By>>;
  where?: InputMaybe<Catalog_Packaging_History_Bool_Exp>;
};


export type Query_RootCatalog_Packaging_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Packaging_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Packaging_History_Order_By>>;
  where?: InputMaybe<Catalog_Packaging_History_Bool_Exp>;
};


export type Query_RootCatalog_Packaging_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCommentArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Query_RootComment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Query_RootComment_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootComment_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Comment_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_History_Order_By>>;
  where?: InputMaybe<Comment_History_Bool_Exp>;
};


export type Query_RootComment_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_History_Order_By>>;
  where?: InputMaybe<Comment_History_Bool_Exp>;
};


export type Query_RootComment_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootComment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Comment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Type_Order_By>>;
  where?: InputMaybe<Comment_Type_Bool_Exp>;
};


export type Query_RootComment_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Type_Order_By>>;
  where?: InputMaybe<Comment_Type_Bool_Exp>;
};


export type Query_RootComment_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootCommissionArgs = {
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  where?: InputMaybe<Commission_Bool_Exp>;
};


export type Query_RootCommission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  where?: InputMaybe<Commission_Bool_Exp>;
};


export type Query_RootCommission_By_PkArgs = {
  type: Commission_Type_Enum;
};


export type Query_RootCommission_TypeArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type Query_RootCommission_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type Query_RootCommission_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Query_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Query_RootCompany_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCompany_PhotoArgs = {
  distinct_on?: InputMaybe<Array<Company_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Photo_Order_By>>;
  where?: InputMaybe<Company_Photo_Bool_Exp>;
};


export type Query_RootCompany_Photo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Photo_Order_By>>;
  where?: InputMaybe<Company_Photo_Bool_Exp>;
};


export type Query_RootCompany_Photo_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCompany_Product_Count_By_Sub_FamilyArgs = {
  args: Company_Product_Count_By_Sub_Family_Arguments;
  distinct_on?: InputMaybe<Array<Company_Product_Sub_Family_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Product_Sub_Family_Count_Order_By>>;
  where?: InputMaybe<Company_Product_Sub_Family_Count_Bool_Exp_Bool_Exp>;
};


export type Query_RootContractArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


export type Query_RootContract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


export type Query_RootContract_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootContract_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Contract_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_History_Order_By>>;
  where?: InputMaybe<Contract_History_Bool_Exp>;
};


export type Query_RootContract_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_History_Order_By>>;
  where?: InputMaybe<Contract_History_Bool_Exp>;
};


export type Query_RootContract_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootContract_StatusArgs = {
  distinct_on?: InputMaybe<Array<Contract_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Status_Order_By>>;
  where?: InputMaybe<Contract_Status_Bool_Exp>;
};


export type Query_RootContract_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Status_Order_By>>;
  where?: InputMaybe<Contract_Status_Bool_Exp>;
};


export type Query_RootContract_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootContract_Total_PriceArgs = {
  args: Contract_Total_Price_Arguments;
  distinct_on?: InputMaybe<Array<Contract_Total_Price_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Total_Price_Order_By>>;
  where?: InputMaybe<Contract_Total_Price_Bool_Exp_Bool_Exp>;
};


export type Query_RootDelivery_ZoneArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Bool_Exp>;
};


export type Query_RootDelivery_Zone_Administrative_AreaArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
};


export type Query_RootDelivery_Zone_Administrative_Area_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
};


export type Query_RootDelivery_Zone_Administrative_Area_By_PkArgs = {
  area: Scalars['String']['input'];
  country_code: Scalars['String']['input'];
  delivery_zone_id: Scalars['uuid']['input'];
};


export type Query_RootDelivery_Zone_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Bool_Exp>;
};


export type Query_RootDelivery_Zone_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootDocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Query_RootDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Query_RootDocument_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootDocument_TypeArgs = {
  distinct_on?: InputMaybe<Array<Document_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Type_Order_By>>;
  where?: InputMaybe<Document_Type_Bool_Exp>;
};


export type Query_RootDocument_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Type_Order_By>>;
  where?: InputMaybe<Document_Type_Bool_Exp>;
};


export type Query_RootDocument_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootDuration_TypeArgs = {
  distinct_on?: InputMaybe<Array<Duration_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Duration_Type_Order_By>>;
  where?: InputMaybe<Duration_Type_Bool_Exp>;
};


export type Query_RootDuration_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Duration_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Duration_Type_Order_By>>;
  where?: InputMaybe<Duration_Type_Bool_Exp>;
};


export type Query_RootDuration_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootGetDownloadUrlArgs = {
  bucket: Scalars['String']['input'];
  objectKey: Scalars['String']['input'];
};


export type Query_RootGetOrderNextActionsArgs = {
  orderReferenceId: Scalars['uuid']['input'];
  userCompanyId?: InputMaybe<Scalars['uuid']['input']>;
  userId: Scalars['uuid']['input'];
};


export type Query_RootGetOrderRecommendationsArgs = {
  buyingCompanyId: Scalars['uuid']['input'];
};


export type Query_RootGetUploadUrlArgs = {
  fileOriginalName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  pathId: Scalars['uuid']['input'];
};


export type Query_RootGetUserContactInformationArgs = {
  userId: Scalars['uuid']['input'];
};


export type Query_RootGlobal_Product_FamilyArgs = {
  distinct_on?: InputMaybe<Array<Global_Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Global_Product_Family_Order_By>>;
  where?: InputMaybe<Global_Product_Family_Bool_Exp>;
};


export type Query_RootGlobal_Product_Family_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Global_Product_Family_Order_By>>;
  where?: InputMaybe<Global_Product_Family_Bool_Exp>;
};


export type Query_RootGlobal_Product_Family_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootGtin_TypeArgs = {
  distinct_on?: InputMaybe<Array<Gtin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Gtin_Type_Order_By>>;
  where?: InputMaybe<Gtin_Type_Bool_Exp>;
};


export type Query_RootGtin_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gtin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Gtin_Type_Order_By>>;
  where?: InputMaybe<Gtin_Type_Bool_Exp>;
};


export type Query_RootGtin_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Query_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Query_RootInvoice_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootInvoice_TypeArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Type_Order_By>>;
  where?: InputMaybe<Invoice_Type_Bool_Exp>;
};


export type Query_RootInvoice_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Type_Order_By>>;
  where?: InputMaybe<Invoice_Type_Bool_Exp>;
};


export type Query_RootInvoice_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootMeasurement_UnitArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Measurement_Unit_Order_By>>;
  where?: InputMaybe<Measurement_Unit_Bool_Exp>;
};


export type Query_RootMeasurement_Unit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Measurement_Unit_Order_By>>;
  where?: InputMaybe<Measurement_Unit_Bool_Exp>;
};


export type Query_RootMeasurement_Unit_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootNon_Reconciliated_MoneyinArgs = {
  distinct_on?: InputMaybe<Array<Non_Reconciliated_Moneyin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Non_Reconciliated_Moneyin_Order_By>>;
  where?: InputMaybe<Non_Reconciliated_Moneyin_Bool_Exp>;
};


export type Query_RootNon_Reconciliated_Moneyin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Non_Reconciliated_Moneyin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Non_Reconciliated_Moneyin_Order_By>>;
  where?: InputMaybe<Non_Reconciliated_Moneyin_Bool_Exp>;
};


export type Query_RootNon_Reconciliated_Moneyin_By_PkArgs = {
  id: Scalars['numeric']['input'];
};


export type Query_RootNotification_Business_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Notification_Business_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Business_Profile_Order_By>>;
  where?: InputMaybe<Notification_Business_Profile_Bool_Exp>;
};


export type Query_RootNotification_Business_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Business_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Business_Profile_Order_By>>;
  where?: InputMaybe<Notification_Business_Profile_Bool_Exp>;
};


export type Query_RootNotification_Business_Profile_By_PkArgs = {
  business_profile: Business_Profile_Enum;
  code: Notification_Code_Enum;
};


export type Query_RootNotification_CodeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Code_Order_By>>;
  where?: InputMaybe<Notification_Code_Bool_Exp>;
};


export type Query_RootNotification_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Code_Order_By>>;
  where?: InputMaybe<Notification_Code_Bool_Exp>;
};


export type Query_RootNotification_Code_By_PkArgs = {
  code: Scalars['String']['input'];
};


export type Query_RootNotification_DefaultArgs = {
  distinct_on?: InputMaybe<Array<Notification_Default_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Default_Order_By>>;
  where?: InputMaybe<Notification_Default_Bool_Exp>;
};


export type Query_RootNotification_Default_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Default_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Default_Order_By>>;
  where?: InputMaybe<Notification_Default_Bool_Exp>;
};


export type Query_RootNotification_Default_By_PkArgs = {
  code: Notification_Code_Enum;
  recipient: Notification_Recipient_Enum;
  scope: Notification_Scope_Enum;
};


export type Query_RootNotification_MethodArgs = {
  distinct_on?: InputMaybe<Array<Notification_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Method_Order_By>>;
  where?: InputMaybe<Notification_Method_Bool_Exp>;
};


export type Query_RootNotification_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Method_Order_By>>;
  where?: InputMaybe<Notification_Method_Bool_Exp>;
};


export type Query_RootNotification_Method_By_PkArgs = {
  code: Notification_Code_Enum;
  user_id: Scalars['uuid']['input'];
};


export type Query_RootNotification_RecipientArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Recipient_Order_By>>;
  where?: InputMaybe<Notification_Recipient_Bool_Exp>;
};


export type Query_RootNotification_Recipient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Recipient_Order_By>>;
  where?: InputMaybe<Notification_Recipient_Bool_Exp>;
};


export type Query_RootNotification_Recipient_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootNotification_ScopeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Scope_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Scope_Order_By>>;
  where?: InputMaybe<Notification_Scope_Bool_Exp>;
};


export type Query_RootNotification_Scope_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Scope_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Scope_Order_By>>;
  where?: InputMaybe<Notification_Scope_Bool_Exp>;
};


export type Query_RootNotification_Scope_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootNotification_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Notification_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Subscription_Order_By>>;
  where?: InputMaybe<Notification_Subscription_Bool_Exp>;
};


export type Query_RootNotification_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Subscription_Order_By>>;
  where?: InputMaybe<Notification_Subscription_Bool_Exp>;
};


export type Query_RootNotification_Subscription_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootOrder_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOrder_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Order_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_History_Order_By>>;
  where?: InputMaybe<Order_History_Bool_Exp>;
};


export type Query_RootOrder_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_History_Order_By>>;
  where?: InputMaybe<Order_History_Bool_Exp>;
};


export type Query_RootOrder_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOrder_Packaging_QuantityArgs = {
  distinct_on?: InputMaybe<Array<Order_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
};


export type Query_RootOrder_Packaging_Quantity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
};


export type Query_RootOrder_Packaging_Quantity_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOrder_StatusArgs = {
  distinct_on?: InputMaybe<Array<Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Status_Order_By>>;
  where?: InputMaybe<Order_Status_Bool_Exp>;
};


export type Query_RootOrder_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Status_Order_By>>;
  where?: InputMaybe<Order_Status_Bool_Exp>;
};


export type Query_RootOrder_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootPackagingArgs = {
  distinct_on?: InputMaybe<Array<Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_Order_By>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};


export type Query_RootPackaging_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_Order_By>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};


export type Query_RootPackaging_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPackaging_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Packaging_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_History_Order_By>>;
  where?: InputMaybe<Packaging_History_Bool_Exp>;
};


export type Query_RootPackaging_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Packaging_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_History_Order_By>>;
  where?: InputMaybe<Packaging_History_Bool_Exp>;
};


export type Query_RootPackaging_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPayment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};


export type Query_RootPayment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};


export type Query_RootPayment_Method_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootPayment_Process_StatusArgs = {
  distinct_on?: InputMaybe<Array<Payment_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Process_Status_Order_By>>;
  where?: InputMaybe<Payment_Process_Status_Bool_Exp>;
};


export type Query_RootPayment_Process_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Process_Status_Order_By>>;
  where?: InputMaybe<Payment_Process_Status_Bool_Exp>;
};


export type Query_RootPayment_Process_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootPhotoArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


export type Query_RootPhoto_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


export type Query_RootPhoto_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPrivacy_SettingArgs = {
  distinct_on?: InputMaybe<Array<Privacy_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Privacy_Setting_Order_By>>;
  where?: InputMaybe<Privacy_Setting_Bool_Exp>;
};


export type Query_RootPrivacy_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Privacy_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Privacy_Setting_Order_By>>;
  where?: InputMaybe<Privacy_Setting_Bool_Exp>;
};


export type Query_RootPrivacy_Setting_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Query_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Query_RootProduct_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProduct_FamilyArgs = {
  distinct_on?: InputMaybe<Array<Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Family_Order_By>>;
  where?: InputMaybe<Product_Family_Bool_Exp>;
};


export type Query_RootProduct_Family_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Family_Order_By>>;
  where?: InputMaybe<Product_Family_Bool_Exp>;
};


export type Query_RootProduct_Family_By_PkArgs = {
  name: Product_Family_Name_Enum;
};


export type Query_RootProduct_Family_NameArgs = {
  distinct_on?: InputMaybe<Array<Product_Family_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Family_Name_Order_By>>;
  where?: InputMaybe<Product_Family_Name_Bool_Exp>;
};


export type Query_RootProduct_Family_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Family_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Family_Name_Order_By>>;
  where?: InputMaybe<Product_Family_Name_Bool_Exp>;
};


export type Query_RootProduct_Family_Name_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootProduct_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Product_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_History_Order_By>>;
  where?: InputMaybe<Product_History_Bool_Exp>;
};


export type Query_RootProduct_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_History_Order_By>>;
  where?: InputMaybe<Product_History_Bool_Exp>;
};


export type Query_RootProduct_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProduct_Label_NameArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Label_Name_Order_By>>;
  where?: InputMaybe<Product_Label_Name_Bool_Exp>;
};


export type Query_RootProduct_Label_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Label_Name_Order_By>>;
  where?: InputMaybe<Product_Label_Name_Bool_Exp>;
};


export type Query_RootProduct_Label_Name_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootProduct_Product_Labels_Product_LabelArgs = {
  distinct_on?: InputMaybe<Array<Product_Product_Labels_Product_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Product_Labels_Product_Label_Order_By>>;
  where?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
};


export type Query_RootProduct_Product_Labels_Product_Label_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Product_Labels_Product_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Product_Labels_Product_Label_Order_By>>;
  where?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
};


export type Query_RootProduct_Product_Labels_Product_Label_By_PkArgs = {
  label: Product_Label_Name_Enum;
  product_id: Scalars['uuid']['input'];
};


export type Query_RootProduct_Sub_FamilyArgs = {
  distinct_on?: InputMaybe<Array<Product_Sub_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Sub_Family_Order_By>>;
  where?: InputMaybe<Product_Sub_Family_Bool_Exp>;
};


export type Query_RootProduct_Sub_Family_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Sub_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Sub_Family_Order_By>>;
  where?: InputMaybe<Product_Sub_Family_Bool_Exp>;
};


export type Query_RootProduct_Sub_Family_By_PkArgs = {
  name: Product_Sub_Family_Name_Enum;
};


export type Query_RootProduct_Sub_Family_NameArgs = {
  distinct_on?: InputMaybe<Array<Product_Sub_Family_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Sub_Family_Name_Order_By>>;
  where?: InputMaybe<Product_Sub_Family_Name_Bool_Exp>;
};


export type Query_RootProduct_Sub_Family_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Sub_Family_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Sub_Family_Name_Order_By>>;
  where?: InputMaybe<Product_Sub_Family_Name_Bool_Exp>;
};


export type Query_RootProduct_Sub_Family_Name_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootRecently_Updated_Packaging_EvolutionArgs = {
  distinct_on?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Order_By>>;
  where?: InputMaybe<Recently_Updated_Packaging_Evolution_Bool_Exp>;
};


export type Query_RootRecently_Updated_Packaging_Evolution_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Order_By>>;
  where?: InputMaybe<Recently_Updated_Packaging_Evolution_Bool_Exp>;
};


export type Query_RootRecommended_SupplierArgs = {
  distinct_on?: InputMaybe<Array<Recommended_Supplier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Recommended_Supplier_Order_By>>;
  where?: InputMaybe<Recommended_Supplier_Bool_Exp>;
};


export type Query_RootRecommended_Supplier_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommended_Supplier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Recommended_Supplier_Order_By>>;
  where?: InputMaybe<Recommended_Supplier_Bool_Exp>;
};


export type Query_RootRecommended_Supplier_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRefresh_TokenArgs = {
  distinct_on?: InputMaybe<Array<Refresh_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Refresh_Token_Order_By>>;
  where?: InputMaybe<Refresh_Token_Bool_Exp>;
};


export type Query_RootRefresh_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Refresh_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Refresh_Token_Order_By>>;
  where?: InputMaybe<Refresh_Token_Bool_Exp>;
};


export type Query_RootRefresh_Token_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Query_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Query_RootRole_AssignmentArgs = {
  distinct_on?: InputMaybe<Array<Role_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Assignment_Order_By>>;
  where?: InputMaybe<Role_Assignment_Bool_Exp>;
};


export type Query_RootRole_Assignment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Assignment_Order_By>>;
  where?: InputMaybe<Role_Assignment_Bool_Exp>;
};


export type Query_RootRole_Assignment_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRole_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootStaff_RoleArgs = {
  distinct_on?: InputMaybe<Array<Staff_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Staff_Role_Order_By>>;
  where?: InputMaybe<Staff_Role_Bool_Exp>;
};


export type Query_RootStaff_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Staff_Role_Order_By>>;
  where?: InputMaybe<Staff_Role_Bool_Exp>;
};


export type Query_RootStaff_Role_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootStaff_UserArgs = {
  distinct_on?: InputMaybe<Array<Staff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Staff_User_Order_By>>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};


export type Query_RootStaff_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Staff_User_Order_By>>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};


export type Query_RootStaff_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTrade_Item_Unit_DescriptorArgs = {
  distinct_on?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Order_By>>;
  where?: InputMaybe<Trade_Item_Unit_Descriptor_Bool_Exp>;
};


export type Query_RootTrade_Item_Unit_Descriptor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Order_By>>;
  where?: InputMaybe<Trade_Item_Unit_Descriptor_Bool_Exp>;
};


export type Query_RootTrade_Item_Unit_Descriptor_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_Product_FamilyArgs = {
  distinct_on?: InputMaybe<Array<User_Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Product_Family_Order_By>>;
  where?: InputMaybe<User_Product_Family_Bool_Exp>;
};


export type Query_RootUser_Product_Family_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Product_Family_Order_By>>;
  where?: InputMaybe<User_Product_Family_Bool_Exp>;
};


export type Query_RootUser_Product_Family_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "recently_updated_packaging_evolution" */
export type Recently_Updated_Packaging_Evolution = {
  __typename?: 'recently_updated_packaging_evolution';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "recently_updated_packaging_evolution" */
export type Recently_Updated_Packaging_Evolution_Aggregate = {
  __typename?: 'recently_updated_packaging_evolution_aggregate';
  aggregate?: Maybe<Recently_Updated_Packaging_Evolution_Aggregate_Fields>;
  nodes: Array<Recently_Updated_Packaging_Evolution>;
};

/** aggregate fields of "recently_updated_packaging_evolution" */
export type Recently_Updated_Packaging_Evolution_Aggregate_Fields = {
  __typename?: 'recently_updated_packaging_evolution_aggregate_fields';
  avg?: Maybe<Recently_Updated_Packaging_Evolution_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Recently_Updated_Packaging_Evolution_Max_Fields>;
  min?: Maybe<Recently_Updated_Packaging_Evolution_Min_Fields>;
  stddev?: Maybe<Recently_Updated_Packaging_Evolution_Stddev_Fields>;
  stddev_pop?: Maybe<Recently_Updated_Packaging_Evolution_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recently_Updated_Packaging_Evolution_Stddev_Samp_Fields>;
  sum?: Maybe<Recently_Updated_Packaging_Evolution_Sum_Fields>;
  var_pop?: Maybe<Recently_Updated_Packaging_Evolution_Var_Pop_Fields>;
  var_samp?: Maybe<Recently_Updated_Packaging_Evolution_Var_Samp_Fields>;
  variance?: Maybe<Recently_Updated_Packaging_Evolution_Variance_Fields>;
};


/** aggregate fields of "recently_updated_packaging_evolution" */
export type Recently_Updated_Packaging_Evolution_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Recently_Updated_Packaging_Evolution_Avg_Fields = {
  __typename?: 'recently_updated_packaging_evolution_avg_fields';
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "recently_updated_packaging_evolution". All fields are combined with a logical 'AND'. */
export type Recently_Updated_Packaging_Evolution_Bool_Exp = {
  _and?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Bool_Exp>>;
  _not?: InputMaybe<Recently_Updated_Packaging_Evolution_Bool_Exp>;
  _or?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  sku?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Recently_Updated_Packaging_Evolution_Max_Fields = {
  __typename?: 'recently_updated_packaging_evolution_max_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Recently_Updated_Packaging_Evolution_Min_Fields = {
  __typename?: 'recently_updated_packaging_evolution_min_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "recently_updated_packaging_evolution". */
export type Recently_Updated_Packaging_Evolution_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
};

/** select columns of table "recently_updated_packaging_evolution" */
export enum Recently_Updated_Packaging_Evolution_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  Sku = 'sku'
}

/** aggregate stddev on columns */
export type Recently_Updated_Packaging_Evolution_Stddev_Fields = {
  __typename?: 'recently_updated_packaging_evolution_stddev_fields';
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Recently_Updated_Packaging_Evolution_Stddev_Pop_Fields = {
  __typename?: 'recently_updated_packaging_evolution_stddev_pop_fields';
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Recently_Updated_Packaging_Evolution_Stddev_Samp_Fields = {
  __typename?: 'recently_updated_packaging_evolution_stddev_samp_fields';
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "recently_updated_packaging_evolution" */
export type Recently_Updated_Packaging_Evolution_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recently_Updated_Packaging_Evolution_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recently_Updated_Packaging_Evolution_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Recently_Updated_Packaging_Evolution_Sum_Fields = {
  __typename?: 'recently_updated_packaging_evolution_sum_fields';
  price?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Recently_Updated_Packaging_Evolution_Var_Pop_Fields = {
  __typename?: 'recently_updated_packaging_evolution_var_pop_fields';
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Recently_Updated_Packaging_Evolution_Var_Samp_Fields = {
  __typename?: 'recently_updated_packaging_evolution_var_samp_fields';
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Recently_Updated_Packaging_Evolution_Variance_Fields = {
  __typename?: 'recently_updated_packaging_evolution_variance_fields';
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "recommended_supplier" */
export type Recommended_Supplier = {
  __typename?: 'recommended_supplier';
  buying_company_id: Scalars['uuid']['output'];
  /** An object relationship */
  contract: Contract;
  contract_reference_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  probability: Scalars['numeric']['output'];
  rank: Scalars['Int']['output'];
};

/** aggregated selection of "recommended_supplier" */
export type Recommended_Supplier_Aggregate = {
  __typename?: 'recommended_supplier_aggregate';
  aggregate?: Maybe<Recommended_Supplier_Aggregate_Fields>;
  nodes: Array<Recommended_Supplier>;
};

/** aggregate fields of "recommended_supplier" */
export type Recommended_Supplier_Aggregate_Fields = {
  __typename?: 'recommended_supplier_aggregate_fields';
  avg?: Maybe<Recommended_Supplier_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Recommended_Supplier_Max_Fields>;
  min?: Maybe<Recommended_Supplier_Min_Fields>;
  stddev?: Maybe<Recommended_Supplier_Stddev_Fields>;
  stddev_pop?: Maybe<Recommended_Supplier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recommended_Supplier_Stddev_Samp_Fields>;
  sum?: Maybe<Recommended_Supplier_Sum_Fields>;
  var_pop?: Maybe<Recommended_Supplier_Var_Pop_Fields>;
  var_samp?: Maybe<Recommended_Supplier_Var_Samp_Fields>;
  variance?: Maybe<Recommended_Supplier_Variance_Fields>;
};


/** aggregate fields of "recommended_supplier" */
export type Recommended_Supplier_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recommended_Supplier_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Recommended_Supplier_Avg_Fields = {
  __typename?: 'recommended_supplier_avg_fields';
  probability?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "recommended_supplier". All fields are combined with a logical 'AND'. */
export type Recommended_Supplier_Bool_Exp = {
  _and?: InputMaybe<Array<Recommended_Supplier_Bool_Exp>>;
  _not?: InputMaybe<Recommended_Supplier_Bool_Exp>;
  _or?: InputMaybe<Array<Recommended_Supplier_Bool_Exp>>;
  buying_company_id?: InputMaybe<Uuid_Comparison_Exp>;
  contract?: InputMaybe<Contract_Bool_Exp>;
  contract_reference_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  probability?: InputMaybe<Numeric_Comparison_Exp>;
  rank?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recommended_supplier" */
export enum Recommended_Supplier_Constraint {
  /** unique or primary key constraint on columns "contract_reference_id", "rank" */
  RecommendedSupplierContractReferenceIdRankKey = 'recommended_supplier_contract_reference_id_rank_key',
  /** unique or primary key constraint on columns "id" */
  RecommendedSupplierPkey = 'recommended_supplier_pkey'
}

/** input type for incrementing numeric columns in table "recommended_supplier" */
export type Recommended_Supplier_Inc_Input = {
  probability?: InputMaybe<Scalars['numeric']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "recommended_supplier" */
export type Recommended_Supplier_Insert_Input = {
  buying_company_id?: InputMaybe<Scalars['uuid']['input']>;
  contract?: InputMaybe<Contract_Obj_Rel_Insert_Input>;
  contract_reference_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  probability?: InputMaybe<Scalars['numeric']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Recommended_Supplier_Max_Fields = {
  __typename?: 'recommended_supplier_max_fields';
  buying_company_id?: Maybe<Scalars['uuid']['output']>;
  contract_reference_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  probability?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Recommended_Supplier_Min_Fields = {
  __typename?: 'recommended_supplier_min_fields';
  buying_company_id?: Maybe<Scalars['uuid']['output']>;
  contract_reference_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  probability?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "recommended_supplier" */
export type Recommended_Supplier_Mutation_Response = {
  __typename?: 'recommended_supplier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Recommended_Supplier>;
};

/** on_conflict condition type for table "recommended_supplier" */
export type Recommended_Supplier_On_Conflict = {
  constraint: Recommended_Supplier_Constraint;
  update_columns?: Array<Recommended_Supplier_Update_Column>;
  where?: InputMaybe<Recommended_Supplier_Bool_Exp>;
};

/** Ordering options when selecting data from "recommended_supplier". */
export type Recommended_Supplier_Order_By = {
  buying_company_id?: InputMaybe<Order_By>;
  contract?: InputMaybe<Contract_Order_By>;
  contract_reference_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** primary key columns input for table: recommended_supplier */
export type Recommended_Supplier_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "recommended_supplier" */
export enum Recommended_Supplier_Select_Column {
  /** column name */
  BuyingCompanyId = 'buying_company_id',
  /** column name */
  ContractReferenceId = 'contract_reference_id',
  /** column name */
  Id = 'id',
  /** column name */
  Probability = 'probability',
  /** column name */
  Rank = 'rank'
}

/** input type for updating data in table "recommended_supplier" */
export type Recommended_Supplier_Set_Input = {
  buying_company_id?: InputMaybe<Scalars['uuid']['input']>;
  contract_reference_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  probability?: InputMaybe<Scalars['numeric']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Recommended_Supplier_Stddev_Fields = {
  __typename?: 'recommended_supplier_stddev_fields';
  probability?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Recommended_Supplier_Stddev_Pop_Fields = {
  __typename?: 'recommended_supplier_stddev_pop_fields';
  probability?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Recommended_Supplier_Stddev_Samp_Fields = {
  __typename?: 'recommended_supplier_stddev_samp_fields';
  probability?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "recommended_supplier" */
export type Recommended_Supplier_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recommended_Supplier_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recommended_Supplier_Stream_Cursor_Value_Input = {
  buying_company_id?: InputMaybe<Scalars['uuid']['input']>;
  contract_reference_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  probability?: InputMaybe<Scalars['numeric']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Recommended_Supplier_Sum_Fields = {
  __typename?: 'recommended_supplier_sum_fields';
  probability?: Maybe<Scalars['numeric']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "recommended_supplier" */
export enum Recommended_Supplier_Update_Column {
  /** column name */
  BuyingCompanyId = 'buying_company_id',
  /** column name */
  ContractReferenceId = 'contract_reference_id',
  /** column name */
  Id = 'id',
  /** column name */
  Probability = 'probability',
  /** column name */
  Rank = 'rank'
}

export type Recommended_Supplier_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Recommended_Supplier_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recommended_Supplier_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recommended_Supplier_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recommended_Supplier_Var_Pop_Fields = {
  __typename?: 'recommended_supplier_var_pop_fields';
  probability?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Recommended_Supplier_Var_Samp_Fields = {
  __typename?: 'recommended_supplier_var_samp_fields';
  probability?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Recommended_Supplier_Variance_Fields = {
  __typename?: 'recommended_supplier_variance_fields';
  probability?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
};

export type ReconciliateMoneyinOutput = {
  __typename?: 'reconciliateMoneyinOutput';
  success: Scalars['Boolean']['output'];
};

/** columns and relationships of "refresh_token" */
export type Refresh_Token = {
  __typename?: 'refresh_token';
  created_at: Scalars['timestamp']['output'];
  /** A computed field, executes function "compute_current_live_token_id" */
  current_live_token_id?: Maybe<Scalars['uuid']['output']>;
  expires_at: Scalars['timestamp']['output'];
  id: Scalars['uuid']['output'];
  is_revoked: Scalars['Boolean']['output'];
  parent_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "refresh_token" */
export type Refresh_Token_Aggregate = {
  __typename?: 'refresh_token_aggregate';
  aggregate?: Maybe<Refresh_Token_Aggregate_Fields>;
  nodes: Array<Refresh_Token>;
};

/** aggregate fields of "refresh_token" */
export type Refresh_Token_Aggregate_Fields = {
  __typename?: 'refresh_token_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Refresh_Token_Max_Fields>;
  min?: Maybe<Refresh_Token_Min_Fields>;
};


/** aggregate fields of "refresh_token" */
export type Refresh_Token_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Refresh_Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "refresh_token". All fields are combined with a logical 'AND'. */
export type Refresh_Token_Bool_Exp = {
  _and?: InputMaybe<Array<Refresh_Token_Bool_Exp>>;
  _not?: InputMaybe<Refresh_Token_Bool_Exp>;
  _or?: InputMaybe<Array<Refresh_Token_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  current_live_token_id?: InputMaybe<Uuid_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_revoked?: InputMaybe<Boolean_Comparison_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "refresh_token" */
export enum Refresh_Token_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB575dd3c21fb0831013c909e7fe = 'PK_b575dd3c21fb0831013c909e7fe',
  /** unique or primary key constraint on columns "parent_id" */
  RefreshTokenParentIdKey = 'refresh_token_parent_id_key'
}

/** input type for inserting data into table "refresh_token" */
export type Refresh_Token_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_revoked?: InputMaybe<Scalars['Boolean']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Refresh_Token_Max_Fields = {
  __typename?: 'refresh_token_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  /** A computed field, executes function "compute_current_live_token_id" */
  current_live_token_id?: Maybe<Scalars['uuid']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Refresh_Token_Min_Fields = {
  __typename?: 'refresh_token_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  /** A computed field, executes function "compute_current_live_token_id" */
  current_live_token_id?: Maybe<Scalars['uuid']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "refresh_token" */
export type Refresh_Token_Mutation_Response = {
  __typename?: 'refresh_token_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Refresh_Token>;
};

/** on_conflict condition type for table "refresh_token" */
export type Refresh_Token_On_Conflict = {
  constraint: Refresh_Token_Constraint;
  update_columns?: Array<Refresh_Token_Update_Column>;
  where?: InputMaybe<Refresh_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "refresh_token". */
export type Refresh_Token_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current_live_token_id?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_revoked?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: refresh_token */
export type Refresh_Token_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "refresh_token" */
export enum Refresh_Token_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsRevoked = 'is_revoked',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "refresh_token" */
export type Refresh_Token_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_revoked?: InputMaybe<Scalars['Boolean']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "refresh_token" */
export type Refresh_Token_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Refresh_Token_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Refresh_Token_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_revoked?: InputMaybe<Scalars['Boolean']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "refresh_token" */
export enum Refresh_Token_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsRevoked = 'is_revoked',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Refresh_Token_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Refresh_Token_Set_Input>;
  /** filter the rows which have to be updated */
  where: Refresh_Token_Bool_Exp;
};

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role';
  businessProfile: Business_Profile_Enum;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate';
  aggregate?: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Role_Max_Fields>;
  min?: Maybe<Role_Min_Fields>;
};


/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "role_assignment" */
export type Role_Assignment = {
  __typename?: 'role_assignment';
  /** An object relationship */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  notification_subscription: Array<Notification_Subscription>;
  /** An aggregate relationship */
  notification_subscription_aggregate: Notification_Subscription_Aggregate;
  /** An array relationship */
  product_families: Array<User_Product_Family>;
  /** An aggregate relationship */
  product_families_aggregate: User_Product_Family_Aggregate;
  /** An object relationship */
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid']['output'];
};


/** columns and relationships of "role_assignment" */
export type Role_AssignmentNotification_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Notification_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Subscription_Order_By>>;
  where?: InputMaybe<Notification_Subscription_Bool_Exp>;
};


/** columns and relationships of "role_assignment" */
export type Role_AssignmentNotification_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Subscription_Order_By>>;
  where?: InputMaybe<Notification_Subscription_Bool_Exp>;
};


/** columns and relationships of "role_assignment" */
export type Role_AssignmentProduct_FamiliesArgs = {
  distinct_on?: InputMaybe<Array<User_Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Product_Family_Order_By>>;
  where?: InputMaybe<User_Product_Family_Bool_Exp>;
};


/** columns and relationships of "role_assignment" */
export type Role_AssignmentProduct_Families_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Product_Family_Order_By>>;
  where?: InputMaybe<User_Product_Family_Bool_Exp>;
};

/** aggregated selection of "role_assignment" */
export type Role_Assignment_Aggregate = {
  __typename?: 'role_assignment_aggregate';
  aggregate?: Maybe<Role_Assignment_Aggregate_Fields>;
  nodes: Array<Role_Assignment>;
};

export type Role_Assignment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Assignment_Aggregate_Bool_Exp_Count>;
};

export type Role_Assignment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Assignment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Role_Assignment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_assignment" */
export type Role_Assignment_Aggregate_Fields = {
  __typename?: 'role_assignment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Role_Assignment_Max_Fields>;
  min?: Maybe<Role_Assignment_Min_Fields>;
};


/** aggregate fields of "role_assignment" */
export type Role_Assignment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Assignment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "role_assignment" */
export type Role_Assignment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Assignment_Max_Order_By>;
  min?: InputMaybe<Role_Assignment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_assignment" */
export type Role_Assignment_Arr_Rel_Insert_Input = {
  data: Array<Role_Assignment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Assignment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_assignment". All fields are combined with a logical 'AND'. */
export type Role_Assignment_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Assignment_Bool_Exp>>;
  _not?: InputMaybe<Role_Assignment_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Assignment_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_subscription?: InputMaybe<Notification_Subscription_Bool_Exp>;
  notification_subscription_aggregate?: InputMaybe<Notification_Subscription_Aggregate_Bool_Exp>;
  product_families?: InputMaybe<User_Product_Family_Bool_Exp>;
  product_families_aggregate?: InputMaybe<User_Product_Family_Aggregate_Bool_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  roleId?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_assignment" */
export enum Role_Assignment_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_7e79671a8a5db18936173148cb4 = 'PK_7e79671a8a5db18936173148cb4',
  /** unique or primary key constraint on columns "roleId", "userId", "companyId" */
  UqD3f619cb595bd66a7253b1ccc5e = 'UQ_d3f619cb595bd66a7253b1ccc5e'
}

/** input type for inserting data into table "role_assignment" */
export type Role_Assignment_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_subscription?: InputMaybe<Notification_Subscription_Arr_Rel_Insert_Input>;
  product_families?: InputMaybe<User_Product_Family_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Role_Assignment_Max_Fields = {
  __typename?: 'role_assignment_max_fields';
  companyId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  roleId?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "role_assignment" */
export type Role_Assignment_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Assignment_Min_Fields = {
  __typename?: 'role_assignment_min_fields';
  companyId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  roleId?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "role_assignment" */
export type Role_Assignment_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_assignment" */
export type Role_Assignment_Mutation_Response = {
  __typename?: 'role_assignment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Assignment>;
};

/** input type for inserting object relation for remote table "role_assignment" */
export type Role_Assignment_Obj_Rel_Insert_Input = {
  data: Role_Assignment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Assignment_On_Conflict>;
};

/** on_conflict condition type for table "role_assignment" */
export type Role_Assignment_On_Conflict = {
  constraint: Role_Assignment_Constraint;
  update_columns?: Array<Role_Assignment_Update_Column>;
  where?: InputMaybe<Role_Assignment_Bool_Exp>;
};

/** Ordering options when selecting data from "role_assignment". */
export type Role_Assignment_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_subscription_aggregate?: InputMaybe<Notification_Subscription_Aggregate_Order_By>;
  product_families_aggregate?: InputMaybe<User_Product_Family_Aggregate_Order_By>;
  role?: InputMaybe<Role_Order_By>;
  roleId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_assignment */
export type Role_Assignment_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "role_assignment" */
export enum Role_Assignment_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "role_assignment" */
export type Role_Assignment_Set_Input = {
  companyId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "role_assignment" */
export type Role_Assignment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Assignment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Assignment_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "role_assignment" */
export enum Role_Assignment_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type Role_Assignment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Assignment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Assignment_Bool_Exp;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Bool_Exp>>;
  _not?: InputMaybe<Role_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Bool_Exp>>;
  businessProfile?: InputMaybe<Business_Profile_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkB36bcfe02fc8de3c57a8b2391c2 = 'PK_b36bcfe02fc8de3c57a8b2391c2',
  /** unique or primary key constraint on columns "businessProfile", "name" */
  UqB65354c6af446b5cb5dfc2173a1 = 'UQ_b65354c6af446b5cb5dfc2173a1'
}

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  businessProfile?: InputMaybe<Business_Profile_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns?: Array<Role_Update_Column>;
  where?: InputMaybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  businessProfile?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  BusinessProfile = 'businessProfile',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  businessProfile?: InputMaybe<Business_Profile_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  businessProfile?: InputMaybe<Business_Profile_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  BusinessProfile = 'businessProfile',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Bool_Exp;
};

/** columns and relationships of "staff_role" */
export type Staff_Role = {
  __typename?: 'staff_role';
  value: Scalars['String']['output'];
};

/** aggregated selection of "staff_role" */
export type Staff_Role_Aggregate = {
  __typename?: 'staff_role_aggregate';
  aggregate?: Maybe<Staff_Role_Aggregate_Fields>;
  nodes: Array<Staff_Role>;
};

/** aggregate fields of "staff_role" */
export type Staff_Role_Aggregate_Fields = {
  __typename?: 'staff_role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Staff_Role_Max_Fields>;
  min?: Maybe<Staff_Role_Min_Fields>;
};


/** aggregate fields of "staff_role" */
export type Staff_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staff_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "staff_role". All fields are combined with a logical 'AND'. */
export type Staff_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Staff_Role_Bool_Exp>>;
  _not?: InputMaybe<Staff_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Staff_Role_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "staff_role" */
export enum Staff_Role_Constraint {
  /** unique or primary key constraint on columns "value" */
  StaffRolePkey = 'staff_role_pkey'
}

export enum Staff_Role_Enum {
  Admin = 'ADMIN'
}

/** Boolean expression to compare columns of type "staff_role_enum". All fields are combined with logical 'AND'. */
export type Staff_Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Staff_Role_Enum>;
  _in?: InputMaybe<Array<Staff_Role_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Staff_Role_Enum>;
  _nin?: InputMaybe<Array<Staff_Role_Enum>>;
};

/** input type for inserting data into table "staff_role" */
export type Staff_Role_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Staff_Role_Max_Fields = {
  __typename?: 'staff_role_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Staff_Role_Min_Fields = {
  __typename?: 'staff_role_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "staff_role" */
export type Staff_Role_Mutation_Response = {
  __typename?: 'staff_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Staff_Role>;
};

/** on_conflict condition type for table "staff_role" */
export type Staff_Role_On_Conflict = {
  constraint: Staff_Role_Constraint;
  update_columns?: Array<Staff_Role_Update_Column>;
  where?: InputMaybe<Staff_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "staff_role". */
export type Staff_Role_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staff_role */
export type Staff_Role_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "staff_role" */
export enum Staff_Role_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "staff_role" */
export type Staff_Role_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "staff_role" */
export type Staff_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staff_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staff_Role_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "staff_role" */
export enum Staff_Role_Update_Column {
  /** column name */
  Value = 'value'
}

export type Staff_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staff_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staff_Role_Bool_Exp;
};

/** columns and relationships of "staff_user" */
export type Staff_User = {
  __typename?: 'staff_user';
  created_at: Scalars['timestamptz']['output'];
  email: Scalars['citext']['output'];
  first_name: Scalars['String']['output'];
  google_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  last_name: Scalars['String']['output'];
  picture?: Maybe<Scalars['String']['output']>;
  role: Staff_Role_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<User>;
};

/** aggregated selection of "staff_user" */
export type Staff_User_Aggregate = {
  __typename?: 'staff_user_aggregate';
  aggregate?: Maybe<Staff_User_Aggregate_Fields>;
  nodes: Array<Staff_User>;
};

/** aggregate fields of "staff_user" */
export type Staff_User_Aggregate_Fields = {
  __typename?: 'staff_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Staff_User_Max_Fields>;
  min?: Maybe<Staff_User_Min_Fields>;
};


/** aggregate fields of "staff_user" */
export type Staff_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staff_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "staff_user". All fields are combined with a logical 'AND'. */
export type Staff_User_Bool_Exp = {
  _and?: InputMaybe<Array<Staff_User_Bool_Exp>>;
  _not?: InputMaybe<Staff_User_Bool_Exp>;
  _or?: InputMaybe<Array<Staff_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  google_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  picture?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Staff_Role_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "staff_user" */
export enum Staff_User_Constraint {
  /** unique or primary key constraint on columns "email" */
  StaffUserEmailKey = 'staff_user_email_key',
  /** unique or primary key constraint on columns "id" */
  StaffUserPkey = 'staff_user_pkey'
}

/** input type for inserting data into table "staff_user" */
export type Staff_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  google_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Staff_Role_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Staff_User_Max_Fields = {
  __typename?: 'staff_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  google_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Staff_User_Min_Fields = {
  __typename?: 'staff_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  google_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "staff_user" */
export type Staff_User_Mutation_Response = {
  __typename?: 'staff_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Staff_User>;
};

/** input type for inserting object relation for remote table "staff_user" */
export type Staff_User_Obj_Rel_Insert_Input = {
  data: Staff_User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Staff_User_On_Conflict>;
};

/** on_conflict condition type for table "staff_user" */
export type Staff_User_On_Conflict = {
  constraint: Staff_User_Constraint;
  update_columns?: Array<Staff_User_Update_Column>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};

/** Ordering options when selecting data from "staff_user". */
export type Staff_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  google_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: staff_user */
export type Staff_User_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "staff_user" */
export enum Staff_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GoogleId = 'google_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Picture = 'picture',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "staff_user" */
export type Staff_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  google_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Staff_Role_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "staff_user" */
export type Staff_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staff_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staff_User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  google_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Staff_Role_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "staff_user" */
export enum Staff_User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GoogleId = 'google_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Picture = 'picture',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Staff_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staff_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staff_User_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "act_hi_procinst" */
  act_hi_procinst: Array<Act_Hi_Procinst>;
  /** fetch aggregated fields from the table: "act_hi_procinst" */
  act_hi_procinst_aggregate: Act_Hi_Procinst_Aggregate;
  /** fetch data from the table: "act_hi_procinst" using primary key columns */
  act_hi_procinst_by_pk?: Maybe<Act_Hi_Procinst>;
  /** fetch data from the table in a streaming manner: "act_hi_procinst" */
  act_hi_procinst_stream: Array<Act_Hi_Procinst>;
  /** fetch data from the table: "act_hi_taskinst" */
  act_hi_taskinst: Array<Act_Hi_Taskinst>;
  /** fetch aggregated fields from the table: "act_hi_taskinst" */
  act_hi_taskinst_aggregate: Act_Hi_Taskinst_Aggregate;
  /** fetch data from the table: "act_hi_taskinst" using primary key columns */
  act_hi_taskinst_by_pk?: Maybe<Act_Hi_Taskinst>;
  /** fetch data from the table in a streaming manner: "act_hi_taskinst" */
  act_hi_taskinst_stream: Array<Act_Hi_Taskinst>;
  /** fetch data from the table: "act_ru_event_subscr" */
  act_ru_event_subscr: Array<Act_Ru_Event_Subscr>;
  /** fetch aggregated fields from the table: "act_ru_event_subscr" */
  act_ru_event_subscr_aggregate: Act_Ru_Event_Subscr_Aggregate;
  /** fetch data from the table: "act_ru_event_subscr" using primary key columns */
  act_ru_event_subscr_by_pk?: Maybe<Act_Ru_Event_Subscr>;
  /** fetch data from the table in a streaming manner: "act_ru_event_subscr" */
  act_ru_event_subscr_stream: Array<Act_Ru_Event_Subscr>;
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<Address>;
  /** fetch data from the table: "administrative_area" */
  administrative_area: Array<Administrative_Area>;
  /** fetch aggregated fields from the table: "administrative_area" */
  administrative_area_aggregate: Administrative_Area_Aggregate;
  /** fetch data from the table: "administrative_area" using primary key columns */
  administrative_area_by_pk?: Maybe<Administrative_Area>;
  /** fetch data from the table in a streaming manner: "administrative_area" */
  administrative_area_stream: Array<Administrative_Area>;
  all_contracts_total_price: Array<Contract_Total_Price>;
  /** fetch data from the table: "authentication_code" */
  authentication_code: Array<Authentication_Code>;
  /** fetch aggregated fields from the table: "authentication_code" */
  authentication_code_aggregate: Authentication_Code_Aggregate;
  /** fetch data from the table: "authentication_code" using primary key columns */
  authentication_code_by_pk?: Maybe<Authentication_Code>;
  /** fetch data from the table in a streaming manner: "authentication_code" */
  authentication_code_stream: Array<Authentication_Code>;
  /** fetch data from the table: "authentication_verification_type" */
  authentication_verification_type: Array<Authentication_Verification_Type>;
  /** fetch aggregated fields from the table: "authentication_verification_type" */
  authentication_verification_type_aggregate: Authentication_Verification_Type_Aggregate;
  /** fetch data from the table: "authentication_verification_type" using primary key columns */
  authentication_verification_type_by_pk?: Maybe<Authentication_Verification_Type>;
  /** fetch data from the table in a streaming manner: "authentication_verification_type" */
  authentication_verification_type_stream: Array<Authentication_Verification_Type>;
  /** fetch data from the table: "availability_status" */
  availability_status: Array<Availability_Status>;
  /** fetch aggregated fields from the table: "availability_status" */
  availability_status_aggregate: Availability_Status_Aggregate;
  /** fetch data from the table: "availability_status" using primary key columns */
  availability_status_by_pk?: Maybe<Availability_Status>;
  /** fetch data from the table in a streaming manner: "availability_status" */
  availability_status_stream: Array<Availability_Status>;
  /** fetch data from the table: "base_unit" */
  base_unit: Array<Base_Unit>;
  /** fetch aggregated fields from the table: "base_unit" */
  base_unit_aggregate: Base_Unit_Aggregate;
  /** fetch data from the table: "base_unit" using primary key columns */
  base_unit_by_pk?: Maybe<Base_Unit>;
  /** fetch data from the table: "base_unit_history" */
  base_unit_history: Array<Base_Unit_History>;
  /** fetch aggregated fields from the table: "base_unit_history" */
  base_unit_history_aggregate: Base_Unit_History_Aggregate;
  /** fetch data from the table: "base_unit_history" using primary key columns */
  base_unit_history_by_pk?: Maybe<Base_Unit_History>;
  /** fetch data from the table in a streaming manner: "base_unit_history" */
  base_unit_history_stream: Array<Base_Unit_History>;
  /** fetch data from the table: "base_unit_photo" */
  base_unit_photo: Array<Base_Unit_Photo>;
  /** fetch aggregated fields from the table: "base_unit_photo" */
  base_unit_photo_aggregate: Base_Unit_Photo_Aggregate;
  /** fetch data from the table: "base_unit_photo" using primary key columns */
  base_unit_photo_by_pk?: Maybe<Base_Unit_Photo>;
  /** fetch data from the table: "base_unit_photo_history" */
  base_unit_photo_history: Array<Base_Unit_Photo_History>;
  /** fetch aggregated fields from the table: "base_unit_photo_history" */
  base_unit_photo_history_aggregate: Base_Unit_Photo_History_Aggregate;
  /** fetch data from the table: "base_unit_photo_history" using primary key columns */
  base_unit_photo_history_by_pk?: Maybe<Base_Unit_Photo_History>;
  /** fetch data from the table in a streaming manner: "base_unit_photo_history" */
  base_unit_photo_history_stream: Array<Base_Unit_Photo_History>;
  /** fetch data from the table in a streaming manner: "base_unit_photo" */
  base_unit_photo_stream: Array<Base_Unit_Photo>;
  /** fetch data from the table in a streaming manner: "base_unit" */
  base_unit_stream: Array<Base_Unit>;
  /** fetch data from the table: "base_unit_type" */
  base_unit_type: Array<Base_Unit_Type>;
  /** fetch aggregated fields from the table: "base_unit_type" */
  base_unit_type_aggregate: Base_Unit_Type_Aggregate;
  /** fetch data from the table: "base_unit_type" using primary key columns */
  base_unit_type_by_pk?: Maybe<Base_Unit_Type>;
  /** fetch data from the table in a streaming manner: "base_unit_type" */
  base_unit_type_stream: Array<Base_Unit_Type>;
  /** fetch data from the table: "basket" */
  basket: Array<Basket>;
  /** fetch aggregated fields from the table: "basket" */
  basket_aggregate: Basket_Aggregate;
  /** fetch data from the table: "basket" using primary key columns */
  basket_by_pk?: Maybe<Basket>;
  /** fetch data from the table: "basket_packaging_quantity" */
  basket_packaging_quantity: Array<Basket_Packaging_Quantity>;
  /** fetch aggregated fields from the table: "basket_packaging_quantity" */
  basket_packaging_quantity_aggregate: Basket_Packaging_Quantity_Aggregate;
  /** fetch data from the table: "basket_packaging_quantity" using primary key columns */
  basket_packaging_quantity_by_pk?: Maybe<Basket_Packaging_Quantity>;
  /** fetch data from the table in a streaming manner: "basket_packaging_quantity" */
  basket_packaging_quantity_stream: Array<Basket_Packaging_Quantity>;
  /** fetch data from the table: "basket_status" */
  basket_status: Array<Basket_Status>;
  /** fetch aggregated fields from the table: "basket_status" */
  basket_status_aggregate: Basket_Status_Aggregate;
  /** fetch data from the table: "basket_status" using primary key columns */
  basket_status_by_pk?: Maybe<Basket_Status>;
  /** fetch data from the table in a streaming manner: "basket_status" */
  basket_status_stream: Array<Basket_Status>;
  /** fetch data from the table in a streaming manner: "basket" */
  basket_stream: Array<Basket>;
  /** fetch data from the table: "billing_type" */
  billing_type: Array<Billing_Type>;
  /** fetch aggregated fields from the table: "billing_type" */
  billing_type_aggregate: Billing_Type_Aggregate;
  /** fetch data from the table: "billing_type" using primary key columns */
  billing_type_by_pk?: Maybe<Billing_Type>;
  /** fetch data from the table in a streaming manner: "billing_type" */
  billing_type_stream: Array<Billing_Type>;
  /** fetch data from the table: "brand" */
  brand: Array<Brand>;
  /** fetch aggregated fields from the table: "brand" */
  brand_aggregate: Brand_Aggregate;
  /** fetch data from the table: "brand" using primary key columns */
  brand_by_pk?: Maybe<Brand>;
  /** fetch data from the table in a streaming manner: "brand" */
  brand_stream: Array<Brand>;
  /** fetch data from the table: "business_profile" */
  business_profile: Array<Business_Profile>;
  /** fetch aggregated fields from the table: "business_profile" */
  business_profile_aggregate: Business_Profile_Aggregate;
  /** fetch data from the table: "business_profile" using primary key columns */
  business_profile_by_pk?: Maybe<Business_Profile>;
  /** fetch data from the table in a streaming manner: "business_profile" */
  business_profile_stream: Array<Business_Profile>;
  /** fetch data from the table: "catalog" */
  catalog: Array<Catalog>;
  /** fetch aggregated fields from the table: "catalog" */
  catalog_aggregate: Catalog_Aggregate;
  /** fetch data from the table: "catalog" using primary key columns */
  catalog_by_pk?: Maybe<Catalog>;
  /** fetch data from the table: "catalog_packaging" */
  catalog_packaging: Array<Catalog_Packaging>;
  /** fetch aggregated fields from the table: "catalog_packaging" */
  catalog_packaging_aggregate: Catalog_Packaging_Aggregate;
  /** fetch data from the table: "catalog_packaging" using primary key columns */
  catalog_packaging_by_pk?: Maybe<Catalog_Packaging>;
  /** fetch data from the table: "catalog_packaging_history" */
  catalog_packaging_history: Array<Catalog_Packaging_History>;
  /** fetch aggregated fields from the table: "catalog_packaging_history" */
  catalog_packaging_history_aggregate: Catalog_Packaging_History_Aggregate;
  /** fetch data from the table: "catalog_packaging_history" using primary key columns */
  catalog_packaging_history_by_pk?: Maybe<Catalog_Packaging_History>;
  /** fetch data from the table in a streaming manner: "catalog_packaging_history" */
  catalog_packaging_history_stream: Array<Catalog_Packaging_History>;
  /** fetch data from the table in a streaming manner: "catalog_packaging" */
  catalog_packaging_stream: Array<Catalog_Packaging>;
  /** fetch data from the table in a streaming manner: "catalog" */
  catalog_stream: Array<Catalog>;
  /** fetch data from the table: "comment" */
  comment: Array<Comment>;
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: Comment_Aggregate;
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>;
  /** fetch data from the table: "comment_history" */
  comment_history: Array<Comment_History>;
  /** fetch aggregated fields from the table: "comment_history" */
  comment_history_aggregate: Comment_History_Aggregate;
  /** fetch data from the table: "comment_history" using primary key columns */
  comment_history_by_pk?: Maybe<Comment_History>;
  /** fetch data from the table in a streaming manner: "comment_history" */
  comment_history_stream: Array<Comment_History>;
  /** fetch data from the table in a streaming manner: "comment" */
  comment_stream: Array<Comment>;
  /** fetch data from the table: "comment_type" */
  comment_type: Array<Comment_Type>;
  /** fetch aggregated fields from the table: "comment_type" */
  comment_type_aggregate: Comment_Type_Aggregate;
  /** fetch data from the table: "comment_type" using primary key columns */
  comment_type_by_pk?: Maybe<Comment_Type>;
  /** fetch data from the table in a streaming manner: "comment_type" */
  comment_type_stream: Array<Comment_Type>;
  /** fetch data from the table: "commission" */
  commission: Array<Commission>;
  /** fetch aggregated fields from the table: "commission" */
  commission_aggregate: Commission_Aggregate;
  /** fetch data from the table: "commission" using primary key columns */
  commission_by_pk?: Maybe<Commission>;
  /** fetch data from the table in a streaming manner: "commission" */
  commission_stream: Array<Commission>;
  /** fetch data from the table: "commission_type" */
  commission_type: Array<Commission_Type>;
  /** fetch aggregated fields from the table: "commission_type" */
  commission_type_aggregate: Commission_Type_Aggregate;
  /** fetch data from the table: "commission_type" using primary key columns */
  commission_type_by_pk?: Maybe<Commission_Type>;
  /** fetch data from the table in a streaming manner: "commission_type" */
  commission_type_stream: Array<Commission_Type>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "company_photo" */
  company_photo: Array<Company_Photo>;
  /** fetch aggregated fields from the table: "company_photo" */
  company_photo_aggregate: Company_Photo_Aggregate;
  /** fetch data from the table: "company_photo" using primary key columns */
  company_photo_by_pk?: Maybe<Company_Photo>;
  /** fetch data from the table in a streaming manner: "company_photo" */
  company_photo_stream: Array<Company_Photo>;
  company_product_count_by_sub_family: Array<Company_Product_Sub_Family_Count>;
  /** fetch data from the table in a streaming manner: "company" */
  company_stream: Array<Company>;
  /** fetch data from the table: "contract" */
  contract: Array<Contract>;
  /** fetch aggregated fields from the table: "contract" */
  contract_aggregate: Contract_Aggregate;
  /** fetch data from the table: "contract" using primary key columns */
  contract_by_pk?: Maybe<Contract>;
  /** fetch data from the table: "contract_history" */
  contract_history: Array<Contract_History>;
  /** fetch aggregated fields from the table: "contract_history" */
  contract_history_aggregate: Contract_History_Aggregate;
  /** fetch data from the table: "contract_history" using primary key columns */
  contract_history_by_pk?: Maybe<Contract_History>;
  /** fetch data from the table in a streaming manner: "contract_history" */
  contract_history_stream: Array<Contract_History>;
  /** fetch data from the table: "contract_status" */
  contract_status: Array<Contract_Status>;
  /** fetch aggregated fields from the table: "contract_status" */
  contract_status_aggregate: Contract_Status_Aggregate;
  /** fetch data from the table: "contract_status" using primary key columns */
  contract_status_by_pk?: Maybe<Contract_Status>;
  /** fetch data from the table in a streaming manner: "contract_status" */
  contract_status_stream: Array<Contract_Status>;
  /** fetch data from the table in a streaming manner: "contract" */
  contract_stream: Array<Contract>;
  contract_total_price: Array<Contract_Total_Price>;
  /** fetch data from the table: "delivery_zone" */
  delivery_zone: Array<Delivery_Zone>;
  /** fetch data from the table: "delivery_zone_administrative_area" */
  delivery_zone_administrative_area: Array<Delivery_Zone_Administrative_Area>;
  /** fetch aggregated fields from the table: "delivery_zone_administrative_area" */
  delivery_zone_administrative_area_aggregate: Delivery_Zone_Administrative_Area_Aggregate;
  /** fetch data from the table: "delivery_zone_administrative_area" using primary key columns */
  delivery_zone_administrative_area_by_pk?: Maybe<Delivery_Zone_Administrative_Area>;
  /** fetch data from the table in a streaming manner: "delivery_zone_administrative_area" */
  delivery_zone_administrative_area_stream: Array<Delivery_Zone_Administrative_Area>;
  /** fetch aggregated fields from the table: "delivery_zone" */
  delivery_zone_aggregate: Delivery_Zone_Aggregate;
  /** fetch data from the table: "delivery_zone" using primary key columns */
  delivery_zone_by_pk?: Maybe<Delivery_Zone>;
  /** fetch data from the table in a streaming manner: "delivery_zone" */
  delivery_zone_stream: Array<Delivery_Zone>;
  /** fetch data from the table: "document" */
  document: Array<Document>;
  /** fetch aggregated fields from the table: "document" */
  document_aggregate: Document_Aggregate;
  /** fetch data from the table: "document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table in a streaming manner: "document" */
  document_stream: Array<Document>;
  /** fetch data from the table: "document_type" */
  document_type: Array<Document_Type>;
  /** fetch aggregated fields from the table: "document_type" */
  document_type_aggregate: Document_Type_Aggregate;
  /** fetch data from the table: "document_type" using primary key columns */
  document_type_by_pk?: Maybe<Document_Type>;
  /** fetch data from the table in a streaming manner: "document_type" */
  document_type_stream: Array<Document_Type>;
  /** fetch data from the table: "duration_type" */
  duration_type: Array<Duration_Type>;
  /** fetch aggregated fields from the table: "duration_type" */
  duration_type_aggregate: Duration_Type_Aggregate;
  /** fetch data from the table: "duration_type" using primary key columns */
  duration_type_by_pk?: Maybe<Duration_Type>;
  /** fetch data from the table in a streaming manner: "duration_type" */
  duration_type_stream: Array<Duration_Type>;
  /** fetch data from the table: "global_product_family" */
  global_product_family: Array<Global_Product_Family>;
  /** fetch aggregated fields from the table: "global_product_family" */
  global_product_family_aggregate: Global_Product_Family_Aggregate;
  /** fetch data from the table: "global_product_family" using primary key columns */
  global_product_family_by_pk?: Maybe<Global_Product_Family>;
  /** fetch data from the table in a streaming manner: "global_product_family" */
  global_product_family_stream: Array<Global_Product_Family>;
  /** fetch data from the table: "gtin_type" */
  gtin_type: Array<Gtin_Type>;
  /** fetch aggregated fields from the table: "gtin_type" */
  gtin_type_aggregate: Gtin_Type_Aggregate;
  /** fetch data from the table: "gtin_type" using primary key columns */
  gtin_type_by_pk?: Maybe<Gtin_Type>;
  /** fetch data from the table in a streaming manner: "gtin_type" */
  gtin_type_stream: Array<Gtin_Type>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table in a streaming manner: "invoice" */
  invoice_stream: Array<Invoice>;
  /** fetch data from the table: "invoice_type" */
  invoice_type: Array<Invoice_Type>;
  /** fetch aggregated fields from the table: "invoice_type" */
  invoice_type_aggregate: Invoice_Type_Aggregate;
  /** fetch data from the table: "invoice_type" using primary key columns */
  invoice_type_by_pk?: Maybe<Invoice_Type>;
  /** fetch data from the table in a streaming manner: "invoice_type" */
  invoice_type_stream: Array<Invoice_Type>;
  /** fetch data from the table: "measurement_unit" */
  measurement_unit: Array<Measurement_Unit>;
  /** fetch aggregated fields from the table: "measurement_unit" */
  measurement_unit_aggregate: Measurement_Unit_Aggregate;
  /** fetch data from the table: "measurement_unit" using primary key columns */
  measurement_unit_by_pk?: Maybe<Measurement_Unit>;
  /** fetch data from the table in a streaming manner: "measurement_unit" */
  measurement_unit_stream: Array<Measurement_Unit>;
  /** fetch data from the table: "non_reconciliated_moneyin" */
  non_reconciliated_moneyin: Array<Non_Reconciliated_Moneyin>;
  /** fetch aggregated fields from the table: "non_reconciliated_moneyin" */
  non_reconciliated_moneyin_aggregate: Non_Reconciliated_Moneyin_Aggregate;
  /** fetch data from the table: "non_reconciliated_moneyin" using primary key columns */
  non_reconciliated_moneyin_by_pk?: Maybe<Non_Reconciliated_Moneyin>;
  /** fetch data from the table in a streaming manner: "non_reconciliated_moneyin" */
  non_reconciliated_moneyin_stream: Array<Non_Reconciliated_Moneyin>;
  /** fetch data from the table: "notification_business_profile" */
  notification_business_profile: Array<Notification_Business_Profile>;
  /** fetch aggregated fields from the table: "notification_business_profile" */
  notification_business_profile_aggregate: Notification_Business_Profile_Aggregate;
  /** fetch data from the table: "notification_business_profile" using primary key columns */
  notification_business_profile_by_pk?: Maybe<Notification_Business_Profile>;
  /** fetch data from the table in a streaming manner: "notification_business_profile" */
  notification_business_profile_stream: Array<Notification_Business_Profile>;
  /** fetch data from the table: "notification_code" */
  notification_code: Array<Notification_Code>;
  /** fetch aggregated fields from the table: "notification_code" */
  notification_code_aggregate: Notification_Code_Aggregate;
  /** fetch data from the table: "notification_code" using primary key columns */
  notification_code_by_pk?: Maybe<Notification_Code>;
  /** fetch data from the table in a streaming manner: "notification_code" */
  notification_code_stream: Array<Notification_Code>;
  /** fetch data from the table: "notification_default" */
  notification_default: Array<Notification_Default>;
  /** fetch aggregated fields from the table: "notification_default" */
  notification_default_aggregate: Notification_Default_Aggregate;
  /** fetch data from the table: "notification_default" using primary key columns */
  notification_default_by_pk?: Maybe<Notification_Default>;
  /** fetch data from the table in a streaming manner: "notification_default" */
  notification_default_stream: Array<Notification_Default>;
  /** fetch data from the table: "notification_method" */
  notification_method: Array<Notification_Method>;
  /** fetch aggregated fields from the table: "notification_method" */
  notification_method_aggregate: Notification_Method_Aggregate;
  /** fetch data from the table: "notification_method" using primary key columns */
  notification_method_by_pk?: Maybe<Notification_Method>;
  /** fetch data from the table in a streaming manner: "notification_method" */
  notification_method_stream: Array<Notification_Method>;
  /** fetch data from the table: "notification_recipient" */
  notification_recipient: Array<Notification_Recipient>;
  /** fetch aggregated fields from the table: "notification_recipient" */
  notification_recipient_aggregate: Notification_Recipient_Aggregate;
  /** fetch data from the table: "notification_recipient" using primary key columns */
  notification_recipient_by_pk?: Maybe<Notification_Recipient>;
  /** fetch data from the table in a streaming manner: "notification_recipient" */
  notification_recipient_stream: Array<Notification_Recipient>;
  /** fetch data from the table: "notification_scope" */
  notification_scope: Array<Notification_Scope>;
  /** fetch aggregated fields from the table: "notification_scope" */
  notification_scope_aggregate: Notification_Scope_Aggregate;
  /** fetch data from the table: "notification_scope" using primary key columns */
  notification_scope_by_pk?: Maybe<Notification_Scope>;
  /** fetch data from the table in a streaming manner: "notification_scope" */
  notification_scope_stream: Array<Notification_Scope>;
  /** An array relationship */
  notification_subscription: Array<Notification_Subscription>;
  /** An aggregate relationship */
  notification_subscription_aggregate: Notification_Subscription_Aggregate;
  /** fetch data from the table: "notification_subscription" using primary key columns */
  notification_subscription_by_pk?: Maybe<Notification_Subscription>;
  /** fetch data from the table in a streaming manner: "notification_subscription" */
  notification_subscription_stream: Array<Notification_Subscription>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_history" */
  order_history: Array<Order_History>;
  /** fetch aggregated fields from the table: "order_history" */
  order_history_aggregate: Order_History_Aggregate;
  /** fetch data from the table: "order_history" using primary key columns */
  order_history_by_pk?: Maybe<Order_History>;
  /** fetch data from the table in a streaming manner: "order_history" */
  order_history_stream: Array<Order_History>;
  /** fetch data from the table: "order_packaging_quantity" */
  order_packaging_quantity: Array<Order_Packaging_Quantity>;
  /** fetch aggregated fields from the table: "order_packaging_quantity" */
  order_packaging_quantity_aggregate: Order_Packaging_Quantity_Aggregate;
  /** fetch data from the table: "order_packaging_quantity" using primary key columns */
  order_packaging_quantity_by_pk?: Maybe<Order_Packaging_Quantity>;
  /** fetch data from the table in a streaming manner: "order_packaging_quantity" */
  order_packaging_quantity_stream: Array<Order_Packaging_Quantity>;
  /** fetch data from the table: "order_status" */
  order_status: Array<Order_Status>;
  /** fetch aggregated fields from the table: "order_status" */
  order_status_aggregate: Order_Status_Aggregate;
  /** fetch data from the table: "order_status" using primary key columns */
  order_status_by_pk?: Maybe<Order_Status>;
  /** fetch data from the table in a streaming manner: "order_status" */
  order_status_stream: Array<Order_Status>;
  /** fetch data from the table in a streaming manner: "order" */
  order_stream: Array<Order>;
  /** fetch data from the table: "packaging" */
  packaging: Array<Packaging>;
  /** fetch aggregated fields from the table: "packaging" */
  packaging_aggregate: Packaging_Aggregate;
  /** fetch data from the table: "packaging" using primary key columns */
  packaging_by_pk?: Maybe<Packaging>;
  /** fetch data from the table: "packaging_history" */
  packaging_history: Array<Packaging_History>;
  /** fetch aggregated fields from the table: "packaging_history" */
  packaging_history_aggregate: Packaging_History_Aggregate;
  /** fetch data from the table: "packaging_history" using primary key columns */
  packaging_history_by_pk?: Maybe<Packaging_History>;
  /** fetch data from the table in a streaming manner: "packaging_history" */
  packaging_history_stream: Array<Packaging_History>;
  /** fetch data from the table in a streaming manner: "packaging" */
  packaging_stream: Array<Packaging>;
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>;
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate;
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>;
  /** fetch data from the table in a streaming manner: "payment_method" */
  payment_method_stream: Array<Payment_Method>;
  /** fetch data from the table: "payment_process_status" */
  payment_process_status: Array<Payment_Process_Status>;
  /** fetch aggregated fields from the table: "payment_process_status" */
  payment_process_status_aggregate: Payment_Process_Status_Aggregate;
  /** fetch data from the table: "payment_process_status" using primary key columns */
  payment_process_status_by_pk?: Maybe<Payment_Process_Status>;
  /** fetch data from the table in a streaming manner: "payment_process_status" */
  payment_process_status_stream: Array<Payment_Process_Status>;
  /** fetch data from the table: "photo" */
  photo: Array<Photo>;
  /** fetch aggregated fields from the table: "photo" */
  photo_aggregate: Photo_Aggregate;
  /** fetch data from the table: "photo" using primary key columns */
  photo_by_pk?: Maybe<Photo>;
  /** fetch data from the table in a streaming manner: "photo" */
  photo_stream: Array<Photo>;
  /** fetch data from the table: "privacy_setting" */
  privacy_setting: Array<Privacy_Setting>;
  /** fetch aggregated fields from the table: "privacy_setting" */
  privacy_setting_aggregate: Privacy_Setting_Aggregate;
  /** fetch data from the table: "privacy_setting" using primary key columns */
  privacy_setting_by_pk?: Maybe<Privacy_Setting>;
  /** fetch data from the table in a streaming manner: "privacy_setting" */
  privacy_setting_stream: Array<Privacy_Setting>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_family" */
  product_family: Array<Product_Family>;
  /** fetch aggregated fields from the table: "product_family" */
  product_family_aggregate: Product_Family_Aggregate;
  /** fetch data from the table: "product_family" using primary key columns */
  product_family_by_pk?: Maybe<Product_Family>;
  /** fetch data from the table: "product_family_name" */
  product_family_name: Array<Product_Family_Name>;
  /** fetch aggregated fields from the table: "product_family_name" */
  product_family_name_aggregate: Product_Family_Name_Aggregate;
  /** fetch data from the table: "product_family_name" using primary key columns */
  product_family_name_by_pk?: Maybe<Product_Family_Name>;
  /** fetch data from the table in a streaming manner: "product_family_name" */
  product_family_name_stream: Array<Product_Family_Name>;
  /** fetch data from the table in a streaming manner: "product_family" */
  product_family_stream: Array<Product_Family>;
  /** fetch data from the table: "product_history" */
  product_history: Array<Product_History>;
  /** fetch aggregated fields from the table: "product_history" */
  product_history_aggregate: Product_History_Aggregate;
  /** fetch data from the table: "product_history" using primary key columns */
  product_history_by_pk?: Maybe<Product_History>;
  /** fetch data from the table in a streaming manner: "product_history" */
  product_history_stream: Array<Product_History>;
  /** fetch data from the table: "product_label_name" */
  product_label_name: Array<Product_Label_Name>;
  /** fetch aggregated fields from the table: "product_label_name" */
  product_label_name_aggregate: Product_Label_Name_Aggregate;
  /** fetch data from the table: "product_label_name" using primary key columns */
  product_label_name_by_pk?: Maybe<Product_Label_Name>;
  /** fetch data from the table in a streaming manner: "product_label_name" */
  product_label_name_stream: Array<Product_Label_Name>;
  /** fetch data from the table: "product_product_labels_product_label" */
  product_product_labels_product_label: Array<Product_Product_Labels_Product_Label>;
  /** fetch aggregated fields from the table: "product_product_labels_product_label" */
  product_product_labels_product_label_aggregate: Product_Product_Labels_Product_Label_Aggregate;
  /** fetch data from the table: "product_product_labels_product_label" using primary key columns */
  product_product_labels_product_label_by_pk?: Maybe<Product_Product_Labels_Product_Label>;
  /** fetch data from the table in a streaming manner: "product_product_labels_product_label" */
  product_product_labels_product_label_stream: Array<Product_Product_Labels_Product_Label>;
  /** fetch data from the table in a streaming manner: "product" */
  product_stream: Array<Product>;
  /** fetch data from the table: "product_sub_family" */
  product_sub_family: Array<Product_Sub_Family>;
  /** fetch aggregated fields from the table: "product_sub_family" */
  product_sub_family_aggregate: Product_Sub_Family_Aggregate;
  /** fetch data from the table: "product_sub_family" using primary key columns */
  product_sub_family_by_pk?: Maybe<Product_Sub_Family>;
  /** fetch data from the table: "product_sub_family_name" */
  product_sub_family_name: Array<Product_Sub_Family_Name>;
  /** fetch aggregated fields from the table: "product_sub_family_name" */
  product_sub_family_name_aggregate: Product_Sub_Family_Name_Aggregate;
  /** fetch data from the table: "product_sub_family_name" using primary key columns */
  product_sub_family_name_by_pk?: Maybe<Product_Sub_Family_Name>;
  /** fetch data from the table in a streaming manner: "product_sub_family_name" */
  product_sub_family_name_stream: Array<Product_Sub_Family_Name>;
  /** fetch data from the table in a streaming manner: "product_sub_family" */
  product_sub_family_stream: Array<Product_Sub_Family>;
  /** fetch data from the table: "recently_updated_packaging_evolution" */
  recently_updated_packaging_evolution: Array<Recently_Updated_Packaging_Evolution>;
  /** fetch aggregated fields from the table: "recently_updated_packaging_evolution" */
  recently_updated_packaging_evolution_aggregate: Recently_Updated_Packaging_Evolution_Aggregate;
  /** fetch data from the table in a streaming manner: "recently_updated_packaging_evolution" */
  recently_updated_packaging_evolution_stream: Array<Recently_Updated_Packaging_Evolution>;
  /** fetch data from the table: "recommended_supplier" */
  recommended_supplier: Array<Recommended_Supplier>;
  /** fetch aggregated fields from the table: "recommended_supplier" */
  recommended_supplier_aggregate: Recommended_Supplier_Aggregate;
  /** fetch data from the table: "recommended_supplier" using primary key columns */
  recommended_supplier_by_pk?: Maybe<Recommended_Supplier>;
  /** fetch data from the table in a streaming manner: "recommended_supplier" */
  recommended_supplier_stream: Array<Recommended_Supplier>;
  /** fetch data from the table: "refresh_token" */
  refresh_token: Array<Refresh_Token>;
  /** fetch aggregated fields from the table: "refresh_token" */
  refresh_token_aggregate: Refresh_Token_Aggregate;
  /** fetch data from the table: "refresh_token" using primary key columns */
  refresh_token_by_pk?: Maybe<Refresh_Token>;
  /** fetch data from the table in a streaming manner: "refresh_token" */
  refresh_token_stream: Array<Refresh_Token>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role_assignment" */
  role_assignment: Array<Role_Assignment>;
  /** fetch aggregated fields from the table: "role_assignment" */
  role_assignment_aggregate: Role_Assignment_Aggregate;
  /** fetch data from the table: "role_assignment" using primary key columns */
  role_assignment_by_pk?: Maybe<Role_Assignment>;
  /** fetch data from the table in a streaming manner: "role_assignment" */
  role_assignment_stream: Array<Role_Assignment>;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>;
  /** fetch data from the table: "staff_role" */
  staff_role: Array<Staff_Role>;
  /** fetch aggregated fields from the table: "staff_role" */
  staff_role_aggregate: Staff_Role_Aggregate;
  /** fetch data from the table: "staff_role" using primary key columns */
  staff_role_by_pk?: Maybe<Staff_Role>;
  /** fetch data from the table in a streaming manner: "staff_role" */
  staff_role_stream: Array<Staff_Role>;
  /** fetch data from the table: "staff_user" */
  staff_user: Array<Staff_User>;
  /** fetch aggregated fields from the table: "staff_user" */
  staff_user_aggregate: Staff_User_Aggregate;
  /** fetch data from the table: "staff_user" using primary key columns */
  staff_user_by_pk?: Maybe<Staff_User>;
  /** fetch data from the table in a streaming manner: "staff_user" */
  staff_user_stream: Array<Staff_User>;
  /** fetch data from the table: "trade_item_unit_descriptor" */
  trade_item_unit_descriptor: Array<Trade_Item_Unit_Descriptor>;
  /** fetch aggregated fields from the table: "trade_item_unit_descriptor" */
  trade_item_unit_descriptor_aggregate: Trade_Item_Unit_Descriptor_Aggregate;
  /** fetch data from the table: "trade_item_unit_descriptor" using primary key columns */
  trade_item_unit_descriptor_by_pk?: Maybe<Trade_Item_Unit_Descriptor>;
  /** fetch data from the table in a streaming manner: "trade_item_unit_descriptor" */
  trade_item_unit_descriptor_stream: Array<Trade_Item_Unit_Descriptor>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_product_family" */
  user_product_family: Array<User_Product_Family>;
  /** fetch aggregated fields from the table: "user_product_family" */
  user_product_family_aggregate: User_Product_Family_Aggregate;
  /** fetch data from the table: "user_product_family" using primary key columns */
  user_product_family_by_pk?: Maybe<User_Product_Family>;
  /** fetch data from the table in a streaming manner: "user_product_family" */
  user_product_family_stream: Array<User_Product_Family>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
};


export type Subscription_RootAct_Hi_ProcinstArgs = {
  distinct_on?: InputMaybe<Array<Act_Hi_Procinst_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Hi_Procinst_Order_By>>;
  where?: InputMaybe<Act_Hi_Procinst_Bool_Exp>;
};


export type Subscription_RootAct_Hi_Procinst_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Act_Hi_Procinst_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Hi_Procinst_Order_By>>;
  where?: InputMaybe<Act_Hi_Procinst_Bool_Exp>;
};


export type Subscription_RootAct_Hi_Procinst_By_PkArgs = {
  id_: Scalars['String']['input'];
};


export type Subscription_RootAct_Hi_Procinst_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Act_Hi_Procinst_Stream_Cursor_Input>>;
  where?: InputMaybe<Act_Hi_Procinst_Bool_Exp>;
};


export type Subscription_RootAct_Hi_TaskinstArgs = {
  distinct_on?: InputMaybe<Array<Act_Hi_Taskinst_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Hi_Taskinst_Order_By>>;
  where?: InputMaybe<Act_Hi_Taskinst_Bool_Exp>;
};


export type Subscription_RootAct_Hi_Taskinst_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Act_Hi_Taskinst_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Hi_Taskinst_Order_By>>;
  where?: InputMaybe<Act_Hi_Taskinst_Bool_Exp>;
};


export type Subscription_RootAct_Hi_Taskinst_By_PkArgs = {
  id_: Scalars['String']['input'];
};


export type Subscription_RootAct_Hi_Taskinst_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Act_Hi_Taskinst_Stream_Cursor_Input>>;
  where?: InputMaybe<Act_Hi_Taskinst_Bool_Exp>;
};


export type Subscription_RootAct_Ru_Event_SubscrArgs = {
  distinct_on?: InputMaybe<Array<Act_Ru_Event_Subscr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Ru_Event_Subscr_Order_By>>;
  where?: InputMaybe<Act_Ru_Event_Subscr_Bool_Exp>;
};


export type Subscription_RootAct_Ru_Event_Subscr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Act_Ru_Event_Subscr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Act_Ru_Event_Subscr_Order_By>>;
  where?: InputMaybe<Act_Ru_Event_Subscr_Bool_Exp>;
};


export type Subscription_RootAct_Ru_Event_Subscr_By_PkArgs = {
  id_: Scalars['String']['input'];
};


export type Subscription_RootAct_Ru_Event_Subscr_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Act_Ru_Event_Subscr_Stream_Cursor_Input>>;
  where?: InputMaybe<Act_Ru_Event_Subscr_Bool_Exp>;
};


export type Subscription_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAddress_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAdministrative_AreaArgs = {
  distinct_on?: InputMaybe<Array<Administrative_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Administrative_Area_Order_By>>;
  where?: InputMaybe<Administrative_Area_Bool_Exp>;
};


export type Subscription_RootAdministrative_Area_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Administrative_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Administrative_Area_Order_By>>;
  where?: InputMaybe<Administrative_Area_Bool_Exp>;
};


export type Subscription_RootAdministrative_Area_By_PkArgs = {
  area: Scalars['String']['input'];
  country_code: Scalars['String']['input'];
};


export type Subscription_RootAdministrative_Area_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Administrative_Area_Stream_Cursor_Input>>;
  where?: InputMaybe<Administrative_Area_Bool_Exp>;
};


export type Subscription_RootAll_Contracts_Total_PriceArgs = {
  args: All_Contracts_Total_Price_Arguments;
  distinct_on?: InputMaybe<Array<Contract_Total_Price_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Total_Price_Order_By>>;
  where?: InputMaybe<Contract_Total_Price_Bool_Exp_Bool_Exp>;
};


export type Subscription_RootAuthentication_CodeArgs = {
  distinct_on?: InputMaybe<Array<Authentication_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authentication_Code_Order_By>>;
  where?: InputMaybe<Authentication_Code_Bool_Exp>;
};


export type Subscription_RootAuthentication_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Authentication_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authentication_Code_Order_By>>;
  where?: InputMaybe<Authentication_Code_Bool_Exp>;
};


export type Subscription_RootAuthentication_Code_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthentication_Code_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Authentication_Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Authentication_Code_Bool_Exp>;
};


export type Subscription_RootAuthentication_Verification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Authentication_Verification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authentication_Verification_Type_Order_By>>;
  where?: InputMaybe<Authentication_Verification_Type_Bool_Exp>;
};


export type Subscription_RootAuthentication_Verification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Authentication_Verification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authentication_Verification_Type_Order_By>>;
  where?: InputMaybe<Authentication_Verification_Type_Bool_Exp>;
};


export type Subscription_RootAuthentication_Verification_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootAuthentication_Verification_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Authentication_Verification_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Authentication_Verification_Type_Bool_Exp>;
};


export type Subscription_RootAvailability_StatusArgs = {
  distinct_on?: InputMaybe<Array<Availability_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Availability_Status_Order_By>>;
  where?: InputMaybe<Availability_Status_Bool_Exp>;
};


export type Subscription_RootAvailability_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Availability_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Availability_Status_Order_By>>;
  where?: InputMaybe<Availability_Status_Bool_Exp>;
};


export type Subscription_RootAvailability_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootAvailability_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Availability_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Availability_Status_Bool_Exp>;
};


export type Subscription_RootBase_UnitArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Order_By>>;
  where?: InputMaybe<Base_Unit_Bool_Exp>;
};


export type Subscription_RootBase_Unit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Order_By>>;
  where?: InputMaybe<Base_Unit_Bool_Exp>;
};


export type Subscription_RootBase_Unit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBase_Unit_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_History_Order_By>>;
  where?: InputMaybe<Base_Unit_History_Bool_Exp>;
};


export type Subscription_RootBase_Unit_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_History_Order_By>>;
  where?: InputMaybe<Base_Unit_History_Bool_Exp>;
};


export type Subscription_RootBase_Unit_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBase_Unit_History_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Base_Unit_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Base_Unit_History_Bool_Exp>;
};


export type Subscription_RootBase_Unit_PhotoArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
};


export type Subscription_RootBase_Unit_Photo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
};


export type Subscription_RootBase_Unit_Photo_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBase_Unit_Photo_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_History_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
};


export type Subscription_RootBase_Unit_Photo_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Photo_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Photo_History_Order_By>>;
  where?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
};


export type Subscription_RootBase_Unit_Photo_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBase_Unit_Photo_History_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Base_Unit_Photo_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Base_Unit_Photo_History_Bool_Exp>;
};


export type Subscription_RootBase_Unit_Photo_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Base_Unit_Photo_Stream_Cursor_Input>>;
  where?: InputMaybe<Base_Unit_Photo_Bool_Exp>;
};


export type Subscription_RootBase_Unit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Base_Unit_Stream_Cursor_Input>>;
  where?: InputMaybe<Base_Unit_Bool_Exp>;
};


export type Subscription_RootBase_Unit_TypeArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Type_Order_By>>;
  where?: InputMaybe<Base_Unit_Type_Bool_Exp>;
};


export type Subscription_RootBase_Unit_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Unit_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Unit_Type_Order_By>>;
  where?: InputMaybe<Base_Unit_Type_Bool_Exp>;
};


export type Subscription_RootBase_Unit_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootBase_Unit_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Base_Unit_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Base_Unit_Type_Bool_Exp>;
};


export type Subscription_RootBasketArgs = {
  distinct_on?: InputMaybe<Array<Basket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Order_By>>;
  where?: InputMaybe<Basket_Bool_Exp>;
};


export type Subscription_RootBasket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Basket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Order_By>>;
  where?: InputMaybe<Basket_Bool_Exp>;
};


export type Subscription_RootBasket_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBasket_Packaging_QuantityArgs = {
  distinct_on?: InputMaybe<Array<Basket_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
};


export type Subscription_RootBasket_Packaging_Quantity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Basket_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
};


export type Subscription_RootBasket_Packaging_Quantity_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBasket_Packaging_Quantity_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Basket_Packaging_Quantity_Stream_Cursor_Input>>;
  where?: InputMaybe<Basket_Packaging_Quantity_Bool_Exp>;
};


export type Subscription_RootBasket_StatusArgs = {
  distinct_on?: InputMaybe<Array<Basket_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Status_Order_By>>;
  where?: InputMaybe<Basket_Status_Bool_Exp>;
};


export type Subscription_RootBasket_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Basket_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Basket_Status_Order_By>>;
  where?: InputMaybe<Basket_Status_Bool_Exp>;
};


export type Subscription_RootBasket_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootBasket_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Basket_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Basket_Status_Bool_Exp>;
};


export type Subscription_RootBasket_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Basket_Stream_Cursor_Input>>;
  where?: InputMaybe<Basket_Bool_Exp>;
};


export type Subscription_RootBilling_TypeArgs = {
  distinct_on?: InputMaybe<Array<Billing_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Type_Order_By>>;
  where?: InputMaybe<Billing_Type_Bool_Exp>;
};


export type Subscription_RootBilling_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Type_Order_By>>;
  where?: InputMaybe<Billing_Type_Bool_Exp>;
};


export type Subscription_RootBilling_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootBilling_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Billing_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Billing_Type_Bool_Exp>;
};


export type Subscription_RootBrandArgs = {
  distinct_on?: InputMaybe<Array<Brand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Brand_Order_By>>;
  where?: InputMaybe<Brand_Bool_Exp>;
};


export type Subscription_RootBrand_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Brand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Brand_Order_By>>;
  where?: InputMaybe<Brand_Bool_Exp>;
};


export type Subscription_RootBrand_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootBrand_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Brand_Stream_Cursor_Input>>;
  where?: InputMaybe<Brand_Bool_Exp>;
};


export type Subscription_RootBusiness_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Business_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Business_Profile_Order_By>>;
  where?: InputMaybe<Business_Profile_Bool_Exp>;
};


export type Subscription_RootBusiness_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Business_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Business_Profile_Order_By>>;
  where?: InputMaybe<Business_Profile_Bool_Exp>;
};


export type Subscription_RootBusiness_Profile_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootBusiness_Profile_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Business_Profile_Stream_Cursor_Input>>;
  where?: InputMaybe<Business_Profile_Bool_Exp>;
};


export type Subscription_RootCatalogArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Order_By>>;
  where?: InputMaybe<Catalog_Bool_Exp>;
};


export type Subscription_RootCatalog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Order_By>>;
  where?: InputMaybe<Catalog_Bool_Exp>;
};


export type Subscription_RootCatalog_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCatalog_PackagingArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Packaging_Order_By>>;
  where?: InputMaybe<Catalog_Packaging_Bool_Exp>;
};


export type Subscription_RootCatalog_Packaging_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Packaging_Order_By>>;
  where?: InputMaybe<Catalog_Packaging_Bool_Exp>;
};


export type Subscription_RootCatalog_Packaging_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCatalog_Packaging_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Packaging_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Packaging_History_Order_By>>;
  where?: InputMaybe<Catalog_Packaging_History_Bool_Exp>;
};


export type Subscription_RootCatalog_Packaging_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Catalog_Packaging_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Catalog_Packaging_History_Order_By>>;
  where?: InputMaybe<Catalog_Packaging_History_Bool_Exp>;
};


export type Subscription_RootCatalog_Packaging_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCatalog_Packaging_History_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Catalog_Packaging_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Catalog_Packaging_History_Bool_Exp>;
};


export type Subscription_RootCatalog_Packaging_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Catalog_Packaging_Stream_Cursor_Input>>;
  where?: InputMaybe<Catalog_Packaging_Bool_Exp>;
};


export type Subscription_RootCatalog_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Catalog_Stream_Cursor_Input>>;
  where?: InputMaybe<Catalog_Bool_Exp>;
};


export type Subscription_RootCommentArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Subscription_RootComment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Subscription_RootComment_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootComment_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Comment_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_History_Order_By>>;
  where?: InputMaybe<Comment_History_Bool_Exp>;
};


export type Subscription_RootComment_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_History_Order_By>>;
  where?: InputMaybe<Comment_History_Bool_Exp>;
};


export type Subscription_RootComment_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootComment_History_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Comment_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_History_Bool_Exp>;
};


export type Subscription_RootComment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Comment_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Subscription_RootComment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Comment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Type_Order_By>>;
  where?: InputMaybe<Comment_Type_Bool_Exp>;
};


export type Subscription_RootComment_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Type_Order_By>>;
  where?: InputMaybe<Comment_Type_Bool_Exp>;
};


export type Subscription_RootComment_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootComment_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Comment_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_Type_Bool_Exp>;
};


export type Subscription_RootCommissionArgs = {
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  where?: InputMaybe<Commission_Bool_Exp>;
};


export type Subscription_RootCommission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  where?: InputMaybe<Commission_Bool_Exp>;
};


export type Subscription_RootCommission_By_PkArgs = {
  type: Commission_Type_Enum;
};


export type Subscription_RootCommission_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Commission_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Bool_Exp>;
};


export type Subscription_RootCommission_TypeArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type Subscription_RootCommission_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type Subscription_RootCommission_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootCommission_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Commission_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type Subscription_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCompany_PhotoArgs = {
  distinct_on?: InputMaybe<Array<Company_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Photo_Order_By>>;
  where?: InputMaybe<Company_Photo_Bool_Exp>;
};


export type Subscription_RootCompany_Photo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Photo_Order_By>>;
  where?: InputMaybe<Company_Photo_Bool_Exp>;
};


export type Subscription_RootCompany_Photo_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCompany_Photo_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Company_Photo_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Photo_Bool_Exp>;
};


export type Subscription_RootCompany_Product_Count_By_Sub_FamilyArgs = {
  args: Company_Product_Count_By_Sub_Family_Arguments;
  distinct_on?: InputMaybe<Array<Company_Product_Sub_Family_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Company_Product_Sub_Family_Count_Order_By>>;
  where?: InputMaybe<Company_Product_Sub_Family_Count_Bool_Exp_Bool_Exp>;
};


export type Subscription_RootCompany_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootContractArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


export type Subscription_RootContract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


export type Subscription_RootContract_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootContract_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Contract_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_History_Order_By>>;
  where?: InputMaybe<Contract_History_Bool_Exp>;
};


export type Subscription_RootContract_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_History_Order_By>>;
  where?: InputMaybe<Contract_History_Bool_Exp>;
};


export type Subscription_RootContract_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootContract_History_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contract_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Contract_History_Bool_Exp>;
};


export type Subscription_RootContract_StatusArgs = {
  distinct_on?: InputMaybe<Array<Contract_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Status_Order_By>>;
  where?: InputMaybe<Contract_Status_Bool_Exp>;
};


export type Subscription_RootContract_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Status_Order_By>>;
  where?: InputMaybe<Contract_Status_Bool_Exp>;
};


export type Subscription_RootContract_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootContract_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contract_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Contract_Status_Bool_Exp>;
};


export type Subscription_RootContract_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contract_Stream_Cursor_Input>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


export type Subscription_RootContract_Total_PriceArgs = {
  args: Contract_Total_Price_Arguments;
  distinct_on?: InputMaybe<Array<Contract_Total_Price_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contract_Total_Price_Order_By>>;
  where?: InputMaybe<Contract_Total_Price_Bool_Exp_Bool_Exp>;
};


export type Subscription_RootDelivery_ZoneArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Bool_Exp>;
};


export type Subscription_RootDelivery_Zone_Administrative_AreaArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
};


export type Subscription_RootDelivery_Zone_Administrative_Area_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Administrative_Area_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
};


export type Subscription_RootDelivery_Zone_Administrative_Area_By_PkArgs = {
  area: Scalars['String']['input'];
  country_code: Scalars['String']['input'];
  delivery_zone_id: Scalars['uuid']['input'];
};


export type Subscription_RootDelivery_Zone_Administrative_Area_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Delivery_Zone_Administrative_Area_Stream_Cursor_Input>>;
  where?: InputMaybe<Delivery_Zone_Administrative_Area_Bool_Exp>;
};


export type Subscription_RootDelivery_Zone_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Zone_Order_By>>;
  where?: InputMaybe<Delivery_Zone_Bool_Exp>;
};


export type Subscription_RootDelivery_Zone_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootDelivery_Zone_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Delivery_Zone_Stream_Cursor_Input>>;
  where?: InputMaybe<Delivery_Zone_Bool_Exp>;
};


export type Subscription_RootDocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootDocument_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootDocument_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Document_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootDocument_TypeArgs = {
  distinct_on?: InputMaybe<Array<Document_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Type_Order_By>>;
  where?: InputMaybe<Document_Type_Bool_Exp>;
};


export type Subscription_RootDocument_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Type_Order_By>>;
  where?: InputMaybe<Document_Type_Bool_Exp>;
};


export type Subscription_RootDocument_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootDocument_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Document_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Type_Bool_Exp>;
};


export type Subscription_RootDuration_TypeArgs = {
  distinct_on?: InputMaybe<Array<Duration_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Duration_Type_Order_By>>;
  where?: InputMaybe<Duration_Type_Bool_Exp>;
};


export type Subscription_RootDuration_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Duration_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Duration_Type_Order_By>>;
  where?: InputMaybe<Duration_Type_Bool_Exp>;
};


export type Subscription_RootDuration_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootDuration_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Duration_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Duration_Type_Bool_Exp>;
};


export type Subscription_RootGlobal_Product_FamilyArgs = {
  distinct_on?: InputMaybe<Array<Global_Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Global_Product_Family_Order_By>>;
  where?: InputMaybe<Global_Product_Family_Bool_Exp>;
};


export type Subscription_RootGlobal_Product_Family_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Global_Product_Family_Order_By>>;
  where?: InputMaybe<Global_Product_Family_Bool_Exp>;
};


export type Subscription_RootGlobal_Product_Family_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootGlobal_Product_Family_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Global_Product_Family_Stream_Cursor_Input>>;
  where?: InputMaybe<Global_Product_Family_Bool_Exp>;
};


export type Subscription_RootGtin_TypeArgs = {
  distinct_on?: InputMaybe<Array<Gtin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Gtin_Type_Order_By>>;
  where?: InputMaybe<Gtin_Type_Bool_Exp>;
};


export type Subscription_RootGtin_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gtin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Gtin_Type_Order_By>>;
  where?: InputMaybe<Gtin_Type_Bool_Exp>;
};


export type Subscription_RootGtin_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootGtin_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Gtin_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Gtin_Type_Bool_Exp>;
};


export type Subscription_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoice_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootInvoice_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Invoice_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoice_TypeArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Type_Order_By>>;
  where?: InputMaybe<Invoice_Type_Bool_Exp>;
};


export type Subscription_RootInvoice_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Type_Order_By>>;
  where?: InputMaybe<Invoice_Type_Bool_Exp>;
};


export type Subscription_RootInvoice_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootInvoice_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Invoice_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Type_Bool_Exp>;
};


export type Subscription_RootMeasurement_UnitArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Measurement_Unit_Order_By>>;
  where?: InputMaybe<Measurement_Unit_Bool_Exp>;
};


export type Subscription_RootMeasurement_Unit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Measurement_Unit_Order_By>>;
  where?: InputMaybe<Measurement_Unit_Bool_Exp>;
};


export type Subscription_RootMeasurement_Unit_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootMeasurement_Unit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Measurement_Unit_Stream_Cursor_Input>>;
  where?: InputMaybe<Measurement_Unit_Bool_Exp>;
};


export type Subscription_RootNon_Reconciliated_MoneyinArgs = {
  distinct_on?: InputMaybe<Array<Non_Reconciliated_Moneyin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Non_Reconciliated_Moneyin_Order_By>>;
  where?: InputMaybe<Non_Reconciliated_Moneyin_Bool_Exp>;
};


export type Subscription_RootNon_Reconciliated_Moneyin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Non_Reconciliated_Moneyin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Non_Reconciliated_Moneyin_Order_By>>;
  where?: InputMaybe<Non_Reconciliated_Moneyin_Bool_Exp>;
};


export type Subscription_RootNon_Reconciliated_Moneyin_By_PkArgs = {
  id: Scalars['numeric']['input'];
};


export type Subscription_RootNon_Reconciliated_Moneyin_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Non_Reconciliated_Moneyin_Stream_Cursor_Input>>;
  where?: InputMaybe<Non_Reconciliated_Moneyin_Bool_Exp>;
};


export type Subscription_RootNotification_Business_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Notification_Business_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Business_Profile_Order_By>>;
  where?: InputMaybe<Notification_Business_Profile_Bool_Exp>;
};


export type Subscription_RootNotification_Business_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Business_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Business_Profile_Order_By>>;
  where?: InputMaybe<Notification_Business_Profile_Bool_Exp>;
};


export type Subscription_RootNotification_Business_Profile_By_PkArgs = {
  business_profile: Business_Profile_Enum;
  code: Notification_Code_Enum;
};


export type Subscription_RootNotification_Business_Profile_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Business_Profile_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Business_Profile_Bool_Exp>;
};


export type Subscription_RootNotification_CodeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Code_Order_By>>;
  where?: InputMaybe<Notification_Code_Bool_Exp>;
};


export type Subscription_RootNotification_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Code_Order_By>>;
  where?: InputMaybe<Notification_Code_Bool_Exp>;
};


export type Subscription_RootNotification_Code_By_PkArgs = {
  code: Scalars['String']['input'];
};


export type Subscription_RootNotification_Code_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Code_Bool_Exp>;
};


export type Subscription_RootNotification_DefaultArgs = {
  distinct_on?: InputMaybe<Array<Notification_Default_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Default_Order_By>>;
  where?: InputMaybe<Notification_Default_Bool_Exp>;
};


export type Subscription_RootNotification_Default_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Default_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Default_Order_By>>;
  where?: InputMaybe<Notification_Default_Bool_Exp>;
};


export type Subscription_RootNotification_Default_By_PkArgs = {
  code: Notification_Code_Enum;
  recipient: Notification_Recipient_Enum;
  scope: Notification_Scope_Enum;
};


export type Subscription_RootNotification_Default_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Default_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Default_Bool_Exp>;
};


export type Subscription_RootNotification_MethodArgs = {
  distinct_on?: InputMaybe<Array<Notification_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Method_Order_By>>;
  where?: InputMaybe<Notification_Method_Bool_Exp>;
};


export type Subscription_RootNotification_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Method_Order_By>>;
  where?: InputMaybe<Notification_Method_Bool_Exp>;
};


export type Subscription_RootNotification_Method_By_PkArgs = {
  code: Notification_Code_Enum;
  user_id: Scalars['uuid']['input'];
};


export type Subscription_RootNotification_Method_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Method_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Method_Bool_Exp>;
};


export type Subscription_RootNotification_RecipientArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Recipient_Order_By>>;
  where?: InputMaybe<Notification_Recipient_Bool_Exp>;
};


export type Subscription_RootNotification_Recipient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Recipient_Order_By>>;
  where?: InputMaybe<Notification_Recipient_Bool_Exp>;
};


export type Subscription_RootNotification_Recipient_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootNotification_Recipient_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Recipient_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Recipient_Bool_Exp>;
};


export type Subscription_RootNotification_ScopeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Scope_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Scope_Order_By>>;
  where?: InputMaybe<Notification_Scope_Bool_Exp>;
};


export type Subscription_RootNotification_Scope_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Scope_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Scope_Order_By>>;
  where?: InputMaybe<Notification_Scope_Bool_Exp>;
};


export type Subscription_RootNotification_Scope_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootNotification_Scope_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Scope_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Scope_Bool_Exp>;
};


export type Subscription_RootNotification_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Notification_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Subscription_Order_By>>;
  where?: InputMaybe<Notification_Subscription_Bool_Exp>;
};


export type Subscription_RootNotification_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Subscription_Order_By>>;
  where?: InputMaybe<Notification_Subscription_Bool_Exp>;
};


export type Subscription_RootNotification_Subscription_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNotification_Subscription_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Subscription_Bool_Exp>;
};


export type Subscription_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootOrder_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Order_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_History_Order_By>>;
  where?: InputMaybe<Order_History_Bool_Exp>;
};


export type Subscription_RootOrder_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_History_Order_By>>;
  where?: InputMaybe<Order_History_Bool_Exp>;
};


export type Subscription_RootOrder_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootOrder_History_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Order_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_History_Bool_Exp>;
};


export type Subscription_RootOrder_Packaging_QuantityArgs = {
  distinct_on?: InputMaybe<Array<Order_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
};


export type Subscription_RootOrder_Packaging_Quantity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Packaging_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Packaging_Quantity_Order_By>>;
  where?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
};


export type Subscription_RootOrder_Packaging_Quantity_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootOrder_Packaging_Quantity_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Order_Packaging_Quantity_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Packaging_Quantity_Bool_Exp>;
};


export type Subscription_RootOrder_StatusArgs = {
  distinct_on?: InputMaybe<Array<Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Status_Order_By>>;
  where?: InputMaybe<Order_Status_Bool_Exp>;
};


export type Subscription_RootOrder_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Status_Order_By>>;
  where?: InputMaybe<Order_Status_Bool_Exp>;
};


export type Subscription_RootOrder_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootOrder_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Order_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Status_Bool_Exp>;
};


export type Subscription_RootOrder_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Order_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootPackagingArgs = {
  distinct_on?: InputMaybe<Array<Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_Order_By>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};


export type Subscription_RootPackaging_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Packaging_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_Order_By>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};


export type Subscription_RootPackaging_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPackaging_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Packaging_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_History_Order_By>>;
  where?: InputMaybe<Packaging_History_Bool_Exp>;
};


export type Subscription_RootPackaging_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Packaging_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Packaging_History_Order_By>>;
  where?: InputMaybe<Packaging_History_Bool_Exp>;
};


export type Subscription_RootPackaging_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPackaging_History_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Packaging_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Packaging_History_Bool_Exp>;
};


export type Subscription_RootPackaging_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Packaging_Stream_Cursor_Input>>;
  where?: InputMaybe<Packaging_Bool_Exp>;
};


export type Subscription_RootPayment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};


export type Subscription_RootPayment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};


export type Subscription_RootPayment_Method_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootPayment_Method_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Payment_Method_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};


export type Subscription_RootPayment_Process_StatusArgs = {
  distinct_on?: InputMaybe<Array<Payment_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Process_Status_Order_By>>;
  where?: InputMaybe<Payment_Process_Status_Bool_Exp>;
};


export type Subscription_RootPayment_Process_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Process_Status_Order_By>>;
  where?: InputMaybe<Payment_Process_Status_Bool_Exp>;
};


export type Subscription_RootPayment_Process_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootPayment_Process_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Payment_Process_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Process_Status_Bool_Exp>;
};


export type Subscription_RootPhotoArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


export type Subscription_RootPhoto_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


export type Subscription_RootPhoto_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPhoto_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Photo_Stream_Cursor_Input>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


export type Subscription_RootPrivacy_SettingArgs = {
  distinct_on?: InputMaybe<Array<Privacy_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Privacy_Setting_Order_By>>;
  where?: InputMaybe<Privacy_Setting_Bool_Exp>;
};


export type Subscription_RootPrivacy_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Privacy_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Privacy_Setting_Order_By>>;
  where?: InputMaybe<Privacy_Setting_Bool_Exp>;
};


export type Subscription_RootPrivacy_Setting_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootPrivacy_Setting_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Privacy_Setting_Stream_Cursor_Input>>;
  where?: InputMaybe<Privacy_Setting_Bool_Exp>;
};


export type Subscription_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProduct_FamilyArgs = {
  distinct_on?: InputMaybe<Array<Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Family_Order_By>>;
  where?: InputMaybe<Product_Family_Bool_Exp>;
};


export type Subscription_RootProduct_Family_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Family_Order_By>>;
  where?: InputMaybe<Product_Family_Bool_Exp>;
};


export type Subscription_RootProduct_Family_By_PkArgs = {
  name: Product_Family_Name_Enum;
};


export type Subscription_RootProduct_Family_NameArgs = {
  distinct_on?: InputMaybe<Array<Product_Family_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Family_Name_Order_By>>;
  where?: InputMaybe<Product_Family_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Family_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Family_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Family_Name_Order_By>>;
  where?: InputMaybe<Product_Family_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Family_Name_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootProduct_Family_Name_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Family_Name_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Family_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Family_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Family_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Family_Bool_Exp>;
};


export type Subscription_RootProduct_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Product_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_History_Order_By>>;
  where?: InputMaybe<Product_History_Bool_Exp>;
};


export type Subscription_RootProduct_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_History_Order_By>>;
  where?: InputMaybe<Product_History_Bool_Exp>;
};


export type Subscription_RootProduct_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProduct_History_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_History_Bool_Exp>;
};


export type Subscription_RootProduct_Label_NameArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Label_Name_Order_By>>;
  where?: InputMaybe<Product_Label_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Label_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Label_Name_Order_By>>;
  where?: InputMaybe<Product_Label_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Label_Name_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootProduct_Label_Name_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Label_Name_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Label_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Product_Labels_Product_LabelArgs = {
  distinct_on?: InputMaybe<Array<Product_Product_Labels_Product_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Product_Labels_Product_Label_Order_By>>;
  where?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
};


export type Subscription_RootProduct_Product_Labels_Product_Label_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Product_Labels_Product_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Product_Labels_Product_Label_Order_By>>;
  where?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
};


export type Subscription_RootProduct_Product_Labels_Product_Label_By_PkArgs = {
  label: Product_Label_Name_Enum;
  product_id: Scalars['uuid']['input'];
};


export type Subscription_RootProduct_Product_Labels_Product_Label_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Product_Labels_Product_Label_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Product_Labels_Product_Label_Bool_Exp>;
};


export type Subscription_RootProduct_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_Sub_FamilyArgs = {
  distinct_on?: InputMaybe<Array<Product_Sub_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Sub_Family_Order_By>>;
  where?: InputMaybe<Product_Sub_Family_Bool_Exp>;
};


export type Subscription_RootProduct_Sub_Family_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Sub_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Sub_Family_Order_By>>;
  where?: InputMaybe<Product_Sub_Family_Bool_Exp>;
};


export type Subscription_RootProduct_Sub_Family_By_PkArgs = {
  name: Product_Sub_Family_Name_Enum;
};


export type Subscription_RootProduct_Sub_Family_NameArgs = {
  distinct_on?: InputMaybe<Array<Product_Sub_Family_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Sub_Family_Name_Order_By>>;
  where?: InputMaybe<Product_Sub_Family_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Sub_Family_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Sub_Family_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Sub_Family_Name_Order_By>>;
  where?: InputMaybe<Product_Sub_Family_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Sub_Family_Name_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootProduct_Sub_Family_Name_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Sub_Family_Name_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Sub_Family_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Sub_Family_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Sub_Family_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Sub_Family_Bool_Exp>;
};


export type Subscription_RootRecently_Updated_Packaging_EvolutionArgs = {
  distinct_on?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Order_By>>;
  where?: InputMaybe<Recently_Updated_Packaging_Evolution_Bool_Exp>;
};


export type Subscription_RootRecently_Updated_Packaging_Evolution_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Recently_Updated_Packaging_Evolution_Order_By>>;
  where?: InputMaybe<Recently_Updated_Packaging_Evolution_Bool_Exp>;
};


export type Subscription_RootRecently_Updated_Packaging_Evolution_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Recently_Updated_Packaging_Evolution_Stream_Cursor_Input>>;
  where?: InputMaybe<Recently_Updated_Packaging_Evolution_Bool_Exp>;
};


export type Subscription_RootRecommended_SupplierArgs = {
  distinct_on?: InputMaybe<Array<Recommended_Supplier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Recommended_Supplier_Order_By>>;
  where?: InputMaybe<Recommended_Supplier_Bool_Exp>;
};


export type Subscription_RootRecommended_Supplier_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommended_Supplier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Recommended_Supplier_Order_By>>;
  where?: InputMaybe<Recommended_Supplier_Bool_Exp>;
};


export type Subscription_RootRecommended_Supplier_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRecommended_Supplier_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Recommended_Supplier_Stream_Cursor_Input>>;
  where?: InputMaybe<Recommended_Supplier_Bool_Exp>;
};


export type Subscription_RootRefresh_TokenArgs = {
  distinct_on?: InputMaybe<Array<Refresh_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Refresh_Token_Order_By>>;
  where?: InputMaybe<Refresh_Token_Bool_Exp>;
};


export type Subscription_RootRefresh_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Refresh_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Refresh_Token_Order_By>>;
  where?: InputMaybe<Refresh_Token_Bool_Exp>;
};


export type Subscription_RootRefresh_Token_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRefresh_Token_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Refresh_Token_Stream_Cursor_Input>>;
  where?: InputMaybe<Refresh_Token_Bool_Exp>;
};


export type Subscription_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_AssignmentArgs = {
  distinct_on?: InputMaybe<Array<Role_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Assignment_Order_By>>;
  where?: InputMaybe<Role_Assignment_Bool_Exp>;
};


export type Subscription_RootRole_Assignment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Assignment_Order_By>>;
  where?: InputMaybe<Role_Assignment_Bool_Exp>;
};


export type Subscription_RootRole_Assignment_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRole_Assignment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Role_Assignment_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Assignment_Bool_Exp>;
};


export type Subscription_RootRole_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRole_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Subscription_RootStaff_RoleArgs = {
  distinct_on?: InputMaybe<Array<Staff_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Staff_Role_Order_By>>;
  where?: InputMaybe<Staff_Role_Bool_Exp>;
};


export type Subscription_RootStaff_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Staff_Role_Order_By>>;
  where?: InputMaybe<Staff_Role_Bool_Exp>;
};


export type Subscription_RootStaff_Role_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootStaff_Role_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Staff_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Staff_Role_Bool_Exp>;
};


export type Subscription_RootStaff_UserArgs = {
  distinct_on?: InputMaybe<Array<Staff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Staff_User_Order_By>>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};


export type Subscription_RootStaff_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Staff_User_Order_By>>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};


export type Subscription_RootStaff_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootStaff_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Staff_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};


export type Subscription_RootTrade_Item_Unit_DescriptorArgs = {
  distinct_on?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Order_By>>;
  where?: InputMaybe<Trade_Item_Unit_Descriptor_Bool_Exp>;
};


export type Subscription_RootTrade_Item_Unit_Descriptor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Order_By>>;
  where?: InputMaybe<Trade_Item_Unit_Descriptor_Bool_Exp>;
};


export type Subscription_RootTrade_Item_Unit_Descriptor_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootTrade_Item_Unit_Descriptor_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Trade_Item_Unit_Descriptor_Stream_Cursor_Input>>;
  where?: InputMaybe<Trade_Item_Unit_Descriptor_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Product_FamilyArgs = {
  distinct_on?: InputMaybe<Array<User_Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Product_Family_Order_By>>;
  where?: InputMaybe<User_Product_Family_Bool_Exp>;
};


export type Subscription_RootUser_Product_Family_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Product_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Product_Family_Order_By>>;
  where?: InputMaybe<User_Product_Family_Bool_Exp>;
};


export type Subscription_RootUser_Product_Family_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Product_Family_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Product_Family_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Product_Family_Bool_Exp>;
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "trade_item_unit_descriptor" */
export type Trade_Item_Unit_Descriptor = {
  __typename?: 'trade_item_unit_descriptor';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "trade_item_unit_descriptor" */
export type Trade_Item_Unit_Descriptor_Aggregate = {
  __typename?: 'trade_item_unit_descriptor_aggregate';
  aggregate?: Maybe<Trade_Item_Unit_Descriptor_Aggregate_Fields>;
  nodes: Array<Trade_Item_Unit_Descriptor>;
};

/** aggregate fields of "trade_item_unit_descriptor" */
export type Trade_Item_Unit_Descriptor_Aggregate_Fields = {
  __typename?: 'trade_item_unit_descriptor_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Trade_Item_Unit_Descriptor_Max_Fields>;
  min?: Maybe<Trade_Item_Unit_Descriptor_Min_Fields>;
};


/** aggregate fields of "trade_item_unit_descriptor" */
export type Trade_Item_Unit_Descriptor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "trade_item_unit_descriptor". All fields are combined with a logical 'AND'. */
export type Trade_Item_Unit_Descriptor_Bool_Exp = {
  _and?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Bool_Exp>>;
  _not?: InputMaybe<Trade_Item_Unit_Descriptor_Bool_Exp>;
  _or?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "trade_item_unit_descriptor" */
export enum Trade_Item_Unit_Descriptor_Constraint {
  /** unique or primary key constraint on columns "value" */
  TradeItemUnitDescriptorPkey = 'trade_item_unit_descriptor_pkey'
}

export enum Trade_Item_Unit_Descriptor_Enum {
  Case = 'CASE',
  Crate = 'CRATE',
  Display = 'DISPLAY',
  Pallet = 'PALLET',
  Sack = 'SACK',
  /** Used when base units are sold individually */
  Unit = 'UNIT'
}

/** Boolean expression to compare columns of type "trade_item_unit_descriptor_enum". All fields are combined with logical 'AND'. */
export type Trade_Item_Unit_Descriptor_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Trade_Item_Unit_Descriptor_Enum>;
  _in?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Trade_Item_Unit_Descriptor_Enum>;
  _nin?: InputMaybe<Array<Trade_Item_Unit_Descriptor_Enum>>;
};

/** input type for inserting data into table "trade_item_unit_descriptor" */
export type Trade_Item_Unit_Descriptor_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Trade_Item_Unit_Descriptor_Max_Fields = {
  __typename?: 'trade_item_unit_descriptor_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Trade_Item_Unit_Descriptor_Min_Fields = {
  __typename?: 'trade_item_unit_descriptor_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "trade_item_unit_descriptor" */
export type Trade_Item_Unit_Descriptor_Mutation_Response = {
  __typename?: 'trade_item_unit_descriptor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Trade_Item_Unit_Descriptor>;
};

/** on_conflict condition type for table "trade_item_unit_descriptor" */
export type Trade_Item_Unit_Descriptor_On_Conflict = {
  constraint: Trade_Item_Unit_Descriptor_Constraint;
  update_columns?: Array<Trade_Item_Unit_Descriptor_Update_Column>;
  where?: InputMaybe<Trade_Item_Unit_Descriptor_Bool_Exp>;
};

/** Ordering options when selecting data from "trade_item_unit_descriptor". */
export type Trade_Item_Unit_Descriptor_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trade_item_unit_descriptor */
export type Trade_Item_Unit_Descriptor_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "trade_item_unit_descriptor" */
export enum Trade_Item_Unit_Descriptor_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "trade_item_unit_descriptor" */
export type Trade_Item_Unit_Descriptor_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "trade_item_unit_descriptor" */
export type Trade_Item_Unit_Descriptor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trade_Item_Unit_Descriptor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trade_Item_Unit_Descriptor_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "trade_item_unit_descriptor" */
export enum Trade_Item_Unit_Descriptor_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Trade_Item_Unit_Descriptor_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trade_Item_Unit_Descriptor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trade_Item_Unit_Descriptor_Bool_Exp;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  auth_provider_avatar_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  comment_histories: Array<Comment_History>;
  /** An aggregate relationship */
  comment_histories_aggregate: Comment_History_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  default_avatar?: Maybe<Photo>;
  default_avatar_photo_id?: Maybe<Scalars['uuid']['output']>;
  email: Scalars['citext']['output'];
  email_privacy_setting?: Maybe<Privacy_Setting_Enum>;
  first_name: Scalars['String']['output'];
  google_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  landline_phone?: Maybe<Scalars['String']['output']>;
  landline_privacy_setting?: Maybe<Privacy_Setting_Enum>;
  last_name: Scalars['String']['output'];
  mobile_phone?: Maybe<Scalars['String']['output']>;
  mobile_privacy_setting?: Maybe<Privacy_Setting_Enum>;
  /** An array relationship */
  order_creators_histories: Array<Order_History>;
  /** An aggregate relationship */
  order_creators_histories_aggregate: Order_History_Aggregate;
  /** An array relationship */
  order_suggestors_histories: Array<Order_History>;
  /** An aggregate relationship */
  order_suggestors_histories_aggregate: Order_History_Aggregate;
  password?: Maybe<Scalars['String']['output']>;
  phone_deprecated?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  role_assignments: Array<Role_Assignment>;
  /** An aggregate relationship */
  role_assignments_aggregate: Role_Assignment_Aggregate;
  sendbird_access_token?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  staff_user?: Maybe<Staff_User>;
  time_zone_preference?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "user" */
export type UserComment_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Comment_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_History_Order_By>>;
  where?: InputMaybe<Comment_History_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserComment_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_History_Order_By>>;
  where?: InputMaybe<Comment_History_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserOrder_Creators_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Order_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_History_Order_By>>;
  where?: InputMaybe<Order_History_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserOrder_Creators_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_History_Order_By>>;
  where?: InputMaybe<Order_History_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserOrder_Suggestors_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Order_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_History_Order_By>>;
  where?: InputMaybe<Order_History_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserOrder_Suggestors_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_History_Order_By>>;
  where?: InputMaybe<Order_History_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserRole_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Role_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Assignment_Order_By>>;
  where?: InputMaybe<Role_Assignment_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserRole_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Assignment_Order_By>>;
  where?: InputMaybe<Role_Assignment_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  auth_provider_avatar_url?: InputMaybe<String_Comparison_Exp>;
  comment_histories?: InputMaybe<Comment_History_Bool_Exp>;
  comment_histories_aggregate?: InputMaybe<Comment_History_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default_avatar?: InputMaybe<Photo_Bool_Exp>;
  default_avatar_photo_id?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  email_privacy_setting?: InputMaybe<Privacy_Setting_Enum_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  google_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  landline_phone?: InputMaybe<String_Comparison_Exp>;
  landline_privacy_setting?: InputMaybe<Privacy_Setting_Enum_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  mobile_phone?: InputMaybe<String_Comparison_Exp>;
  mobile_privacy_setting?: InputMaybe<Privacy_Setting_Enum_Comparison_Exp>;
  order_creators_histories?: InputMaybe<Order_History_Bool_Exp>;
  order_creators_histories_aggregate?: InputMaybe<Order_History_Aggregate_Bool_Exp>;
  order_suggestors_histories?: InputMaybe<Order_History_Bool_Exp>;
  order_suggestors_histories_aggregate?: InputMaybe<Order_History_Aggregate_Bool_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  phone_deprecated?: InputMaybe<String_Comparison_Exp>;
  role_assignments?: InputMaybe<Role_Assignment_Bool_Exp>;
  role_assignments_aggregate?: InputMaybe<Role_Assignment_Aggregate_Bool_Exp>;
  sendbird_access_token?: InputMaybe<String_Comparison_Exp>;
  staff_user?: InputMaybe<Staff_User_Bool_Exp>;
  time_zone_preference?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkCace4a159ff9f2512dd42373760 = 'PK_cace4a159ff9f2512dd42373760',
  /** unique or primary key constraint on columns "email" */
  UqE12875dfb3b1d92d7d7c5377e22 = 'UQ_e12875dfb3b1d92d7d7c5377e22',
  /** unique or primary key constraint on columns "sendbird_access_token" */
  UserSendbirdAccessTokenKey = 'user_sendbird_access_token_key'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  auth_provider_avatar_url?: InputMaybe<Scalars['String']['input']>;
  comment_histories?: InputMaybe<Comment_History_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_avatar?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  default_avatar_photo_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  email_privacy_setting?: InputMaybe<Privacy_Setting_Enum>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  google_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  landline_phone?: InputMaybe<Scalars['String']['input']>;
  landline_privacy_setting?: InputMaybe<Privacy_Setting_Enum>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  mobile_phone?: InputMaybe<Scalars['String']['input']>;
  mobile_privacy_setting?: InputMaybe<Privacy_Setting_Enum>;
  order_creators_histories?: InputMaybe<Order_History_Arr_Rel_Insert_Input>;
  order_suggestors_histories?: InputMaybe<Order_History_Arr_Rel_Insert_Input>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_deprecated?: InputMaybe<Scalars['String']['input']>;
  role_assignments?: InputMaybe<Role_Assignment_Arr_Rel_Insert_Input>;
  sendbird_access_token?: InputMaybe<Scalars['String']['input']>;
  staff_user?: InputMaybe<Staff_User_Obj_Rel_Insert_Input>;
  time_zone_preference?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  auth_provider_avatar_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  default_avatar_photo_id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  google_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  landline_phone?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  mobile_phone?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone_deprecated?: Maybe<Scalars['String']['output']>;
  sendbird_access_token?: Maybe<Scalars['String']['output']>;
  time_zone_preference?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  auth_provider_avatar_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  default_avatar_photo_id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  google_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  landline_phone?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  mobile_phone?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone_deprecated?: Maybe<Scalars['String']['output']>;
  sendbird_access_token?: Maybe<Scalars['String']['output']>;
  time_zone_preference?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  auth_provider_avatar_url?: InputMaybe<Order_By>;
  comment_histories_aggregate?: InputMaybe<Comment_History_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_avatar?: InputMaybe<Photo_Order_By>;
  default_avatar_photo_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_privacy_setting?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  google_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landline_phone?: InputMaybe<Order_By>;
  landline_privacy_setting?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  mobile_phone?: InputMaybe<Order_By>;
  mobile_privacy_setting?: InputMaybe<Order_By>;
  order_creators_histories_aggregate?: InputMaybe<Order_History_Aggregate_Order_By>;
  order_suggestors_histories_aggregate?: InputMaybe<Order_History_Aggregate_Order_By>;
  password?: InputMaybe<Order_By>;
  phone_deprecated?: InputMaybe<Order_By>;
  role_assignments_aggregate?: InputMaybe<Role_Assignment_Aggregate_Order_By>;
  sendbird_access_token?: InputMaybe<Order_By>;
  staff_user?: InputMaybe<Staff_User_Order_By>;
  time_zone_preference?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "user_product_family" */
export type User_Product_Family = {
  __typename?: 'user_product_family';
  created_at: Scalars['timestamptz']['output'];
  global_setting?: Maybe<Global_Product_Family_Enum>;
  id: Scalars['uuid']['output'];
  product_family?: Maybe<Product_Family_Name_Enum>;
  /** An array relationship */
  product_family_sub_families: Array<Product_Sub_Family>;
  /** An aggregate relationship */
  product_family_sub_families_aggregate: Product_Sub_Family_Aggregate;
  product_sub_family?: Maybe<Product_Sub_Family_Name_Enum>;
  /** An object relationship */
  product_sub_family_family_name?: Maybe<Product_Sub_Family>;
  /** An object relationship */
  role_assignment: Role_Assignment;
  role_assignment_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "user_product_family" */
export type User_Product_FamilyProduct_Family_Sub_FamiliesArgs = {
  distinct_on?: InputMaybe<Array<Product_Sub_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Sub_Family_Order_By>>;
  where?: InputMaybe<Product_Sub_Family_Bool_Exp>;
};


/** columns and relationships of "user_product_family" */
export type User_Product_FamilyProduct_Family_Sub_Families_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Sub_Family_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Sub_Family_Order_By>>;
  where?: InputMaybe<Product_Sub_Family_Bool_Exp>;
};

/** aggregated selection of "user_product_family" */
export type User_Product_Family_Aggregate = {
  __typename?: 'user_product_family_aggregate';
  aggregate?: Maybe<User_Product_Family_Aggregate_Fields>;
  nodes: Array<User_Product_Family>;
};

export type User_Product_Family_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Product_Family_Aggregate_Bool_Exp_Count>;
};

export type User_Product_Family_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Product_Family_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Product_Family_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_product_family" */
export type User_Product_Family_Aggregate_Fields = {
  __typename?: 'user_product_family_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Product_Family_Max_Fields>;
  min?: Maybe<User_Product_Family_Min_Fields>;
};


/** aggregate fields of "user_product_family" */
export type User_Product_Family_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Product_Family_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_product_family" */
export type User_Product_Family_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Product_Family_Max_Order_By>;
  min?: InputMaybe<User_Product_Family_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_product_family" */
export type User_Product_Family_Arr_Rel_Insert_Input = {
  data: Array<User_Product_Family_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Product_Family_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_product_family". All fields are combined with a logical 'AND'. */
export type User_Product_Family_Bool_Exp = {
  _and?: InputMaybe<Array<User_Product_Family_Bool_Exp>>;
  _not?: InputMaybe<User_Product_Family_Bool_Exp>;
  _or?: InputMaybe<Array<User_Product_Family_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  global_setting?: InputMaybe<Global_Product_Family_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_family?: InputMaybe<Product_Family_Name_Enum_Comparison_Exp>;
  product_family_sub_families?: InputMaybe<Product_Sub_Family_Bool_Exp>;
  product_family_sub_families_aggregate?: InputMaybe<Product_Sub_Family_Aggregate_Bool_Exp>;
  product_sub_family?: InputMaybe<Product_Sub_Family_Name_Enum_Comparison_Exp>;
  product_sub_family_family_name?: InputMaybe<Product_Sub_Family_Bool_Exp>;
  role_assignment?: InputMaybe<Role_Assignment_Bool_Exp>;
  role_assignment_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_product_family" */
export enum User_Product_Family_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserProductFamilyIdKey = 'user_product_family_id_key',
  /** unique or primary key constraint on columns "id" */
  UserProductFamilyPkey = 'user_product_family_pkey'
}

/** input type for inserting data into table "user_product_family" */
export type User_Product_Family_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  global_setting?: InputMaybe<Global_Product_Family_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_family?: InputMaybe<Product_Family_Name_Enum>;
  product_family_sub_families?: InputMaybe<Product_Sub_Family_Arr_Rel_Insert_Input>;
  product_sub_family?: InputMaybe<Product_Sub_Family_Name_Enum>;
  product_sub_family_family_name?: InputMaybe<Product_Sub_Family_Obj_Rel_Insert_Input>;
  role_assignment?: InputMaybe<Role_Assignment_Obj_Rel_Insert_Input>;
  role_assignment_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type User_Product_Family_Max_Fields = {
  __typename?: 'user_product_family_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role_assignment_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "user_product_family" */
export type User_Product_Family_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_assignment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Product_Family_Min_Fields = {
  __typename?: 'user_product_family_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role_assignment_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "user_product_family" */
export type User_Product_Family_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_assignment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_product_family" */
export type User_Product_Family_Mutation_Response = {
  __typename?: 'user_product_family_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Product_Family>;
};

/** on_conflict condition type for table "user_product_family" */
export type User_Product_Family_On_Conflict = {
  constraint: User_Product_Family_Constraint;
  update_columns?: Array<User_Product_Family_Update_Column>;
  where?: InputMaybe<User_Product_Family_Bool_Exp>;
};

/** Ordering options when selecting data from "user_product_family". */
export type User_Product_Family_Order_By = {
  created_at?: InputMaybe<Order_By>;
  global_setting?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_family?: InputMaybe<Order_By>;
  product_family_sub_families_aggregate?: InputMaybe<Product_Sub_Family_Aggregate_Order_By>;
  product_sub_family?: InputMaybe<Order_By>;
  product_sub_family_family_name?: InputMaybe<Product_Sub_Family_Order_By>;
  role_assignment?: InputMaybe<Role_Assignment_Order_By>;
  role_assignment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_product_family */
export type User_Product_Family_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_product_family" */
export enum User_Product_Family_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GlobalSetting = 'global_setting',
  /** column name */
  Id = 'id',
  /** column name */
  ProductFamily = 'product_family',
  /** column name */
  ProductSubFamily = 'product_sub_family',
  /** column name */
  RoleAssignmentId = 'role_assignment_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_product_family" */
export type User_Product_Family_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  global_setting?: InputMaybe<Global_Product_Family_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_family?: InputMaybe<Product_Family_Name_Enum>;
  product_sub_family?: InputMaybe<Product_Sub_Family_Name_Enum>;
  role_assignment_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "user_product_family" */
export type User_Product_Family_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Product_Family_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Product_Family_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  global_setting?: InputMaybe<Global_Product_Family_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_family?: InputMaybe<Product_Family_Name_Enum>;
  product_sub_family?: InputMaybe<Product_Sub_Family_Name_Enum>;
  role_assignment_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "user_product_family" */
export enum User_Product_Family_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GlobalSetting = 'global_setting',
  /** column name */
  Id = 'id',
  /** column name */
  ProductFamily = 'product_family',
  /** column name */
  ProductSubFamily = 'product_sub_family',
  /** column name */
  RoleAssignmentId = 'role_assignment_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Product_Family_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Product_Family_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Product_Family_Bool_Exp;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  AuthProviderAvatarUrl = 'auth_provider_avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultAvatarPhotoId = 'default_avatar_photo_id',
  /** column name */
  Email = 'email',
  /** column name */
  EmailPrivacySetting = 'email_privacy_setting',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GoogleId = 'google_id',
  /** column name */
  Id = 'id',
  /** column name */
  LandlinePhone = 'landline_phone',
  /** column name */
  LandlinePrivacySetting = 'landline_privacy_setting',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  MobilePrivacySetting = 'mobile_privacy_setting',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneDeprecated = 'phone_deprecated',
  /** column name */
  SendbirdAccessToken = 'sendbird_access_token',
  /** column name */
  TimeZonePreference = 'time_zone_preference',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  auth_provider_avatar_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_avatar_photo_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  email_privacy_setting?: InputMaybe<Privacy_Setting_Enum>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  google_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  landline_phone?: InputMaybe<Scalars['String']['input']>;
  landline_privacy_setting?: InputMaybe<Privacy_Setting_Enum>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  mobile_phone?: InputMaybe<Scalars['String']['input']>;
  mobile_privacy_setting?: InputMaybe<Privacy_Setting_Enum>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_deprecated?: InputMaybe<Scalars['String']['input']>;
  sendbird_access_token?: InputMaybe<Scalars['String']['input']>;
  time_zone_preference?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  auth_provider_avatar_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_avatar_photo_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  email_privacy_setting?: InputMaybe<Privacy_Setting_Enum>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  google_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  landline_phone?: InputMaybe<Scalars['String']['input']>;
  landline_privacy_setting?: InputMaybe<Privacy_Setting_Enum>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  mobile_phone?: InputMaybe<Scalars['String']['input']>;
  mobile_privacy_setting?: InputMaybe<Privacy_Setting_Enum>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_deprecated?: InputMaybe<Scalars['String']['input']>;
  sendbird_access_token?: InputMaybe<Scalars['String']['input']>;
  time_zone_preference?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  AuthProviderAvatarUrl = 'auth_provider_avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultAvatarPhotoId = 'default_avatar_photo_id',
  /** column name */
  Email = 'email',
  /** column name */
  EmailPrivacySetting = 'email_privacy_setting',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GoogleId = 'google_id',
  /** column name */
  Id = 'id',
  /** column name */
  LandlinePhone = 'landline_phone',
  /** column name */
  LandlinePrivacySetting = 'landline_privacy_setting',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  MobilePrivacySetting = 'mobile_privacy_setting',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneDeprecated = 'phone_deprecated',
  /** column name */
  SendbirdAccessToken = 'sendbird_access_token',
  /** column name */
  TimeZonePreference = 'time_zone_preference',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type Verify_User_Authentication_Code_Args = {
  auth_code?: InputMaybe<Scalars['String']['input']>;
  user_email?: InputMaybe<Scalars['citext']['input']>;
};
