import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Basket_Packaging_Quantity_Insert_Input, Maybe } from 'kheops-graphql';
import { useCallback, useEffect, useRef } from 'react';
import { debounceTime, switchMap, tap } from 'rxjs';
import { createSignal } from '@react-rxjs/utils';
import * as Sentry from '@sentry/react';
import { currentContextAtom, userAtom } from '../state';
import { BasketDocument, BasketQuery, useBasketLazyQuery } from '../queries/__generated__/basket.generated';
import { useCreateBasketMutation } from '../mutations/__generated__/createBasket.generated';
import { useUpdateBasketMutation } from '../mutations/__generated__/updateBasketQuantities.generated';
import { basketDataAtom, currentBasketQuantitiesAtom, isBasketLoadingAtom, isRemoteBasketEmptyAtom } from './state/state';

interface BasketMutationParams {
  packagingQuantities: Basket_Packaging_Quantity_Insert_Input | Basket_Packaging_Quantity_Insert_Input[];
  deletedIds: string[],
  id: string;
}

const [basketQuantities$, postNewBasketQuantities] = createSignal<BasketMutationParams>();

export default function useBasket(): void {
  const setIsRemoteBasketEmpty = useSetAtom(isRemoteBasketEmptyAtom);
  const [basketQuantities, setBasketQuantities] = useAtom(
    currentBasketQuantitiesAtom,
  );
  const { id: userId } = useAtomValue(userAtom);
  const { companyId } = useAtomValue(currentContextAtom);
  const [getBasket, { data: dataBasket, loading: basketLoading, error: basketQueryError }] = useBasketLazyQuery({
    variables: { userId, companyId },
  });
  const [createBasket, { loading: createBasketLoading }] = useCreateBasketMutation({ refetchQueries: [BasketDocument], awaitRefetchQueries: true });
  const [updateBasketQuantities, { loading: updateBasketLoading }] = useUpdateBasketMutation();

  const loading = basketLoading || createBasketLoading || updateBasketLoading;
  const setIsBasketLoading = useSetAtom(isBasketLoadingAtom);

  const setBasketData = useSetAtom(basketDataAtom);

  const updateBasketControllerRef = useRef<AbortController>();

  useEffect(() => {
    if (userId) {
      getBasket();
    }
  }, [userId]);

  const updateLocalBasket = useCallback((basket?: Maybe<BasketQuery['basket'][number]>) => {
    setIsRemoteBasketEmpty(!basket?.packaging_quantities.length);

    if (basket) {
      setBasketQuantities(
        ...basket.packaging_quantities.map(({ id, packaging_id, quantity, contract_id }) => ({
          id,
          packagingId: packaging_id,
          quantity,
          contractId: contract_id,
        })),
      );
    }

    setBasketData(basket || undefined);
  }, []);

  useEffect(() => {
    if (!userId || loading || !dataBasket) {
      return;
    }

    const basket = dataBasket?.basket[0];

    if (!basket && !basketQueryError) {
      createBasket({ variables: { packagingQuantities: [], userId, company_id: companyId } });
      setBasketQuantities();
    }

    updateLocalBasket(basket);
  }, [loading, dataBasket, basketQueryError]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    const basket = dataBasket?.basket[0];
    const packagingQuantities: Basket_Packaging_Quantity_Insert_Input[] = [];
    const deletedIds: string[] = [];

    Array.from(basketQuantities.entries()).forEach(([, { id, packagingId, quantity, contractId }]) => {
      if (quantity) {
        packagingQuantities.push({
          id,
          packaging_id: packagingId,
          basket_id: basket?.id,
          quantity,
          contract_id: contractId,
        });
      } else if (id) {
        deletedIds.push(id);
      }
    });

    const basketHasChanged = basket
      && (basket.packaging_quantities.length !== basketQuantities.size || basket.packaging_quantities.some((packagingQuantity) => {
        const newPackagingQuantity = basketQuantities.get(
          `${packagingQuantity.packaging_id}_${packagingQuantity.contract_id}`,
        );

        return newPackagingQuantity?.quantity !== packagingQuantity.quantity;
      }));

    if (basketHasChanged) {
      postNewBasketQuantities({ packagingQuantities, id: basket!.id, deletedIds });
    }
  }, [userId, basketQuantities]);

  useEffect(() => {
    const updateBasketResult$ = basketQuantities$.pipe(
      tap(() => {
        if (updateBasketControllerRef.current && !updateBasketControllerRef.current.signal.aborted) {
          updateBasketControllerRef.current.abort('New basket update queued');
        }
      }),
      debounceTime(500),
      switchMap(async (param) => {
        const controller = new AbortController();
        updateBasketControllerRef.current = controller;

        try {
          const { data } = await updateBasketQuantities({
            variables: { ...param!, now: new Date() },
            context: {
              fetchOptions: {
                signal: controller.signal,
              },
            },
          });

          return {
            data,
            controller,
          };
        } catch (error) {
          Sentry.captureException(error);
        }
      }),
      tap((response) => {
        if (response?.data && !response.controller.signal.aborted) {
          const basket = response.data.update_basket_by_pk;

          updateLocalBasket(basket);
        }

        delete updateBasketControllerRef.current;
      }),
    );

    const noop = (): void => { /* noop */ };
    // subscribe to trigger observable, but we don't need the result, as everything is plugged on apollo's hooks
    const subscriptions = [
      updateBasketResult$.subscribe(noop),
    ];

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  useEffect(() => {
    setIsBasketLoading(loading);
  }, [loading]);
}
