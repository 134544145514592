import { Box, Button, IconButton, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useMemo, useState } from 'react';
import { Business_Profile_Enum, Order_Status_Enum } from 'kheops-graphql';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import { useAtomValue, useAtom } from 'jotai';
import { currentContextAtom } from '../../state';
import CustomDateTimePicker from '../../common/components/CustomDateTimePicker';
import useLongDateFormat from '../../hooks/useLongDateFormat';
import { orderAtom, orderDeliveryDateAtom } from '../state/state';
import OrderUpdateDeliveryDateButton from './OrderUpdateDeliveryDateButton';
import { DeliveryDateOrderStatus } from './OrderDeliveryDateDialog';

export default function OrderDeliveryDateForm(): React.JSX.Element {
  const { t } = useTranslation(['order', 'common']);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const { realm } = useAtomValue(currentContextAtom);
  const order = useAtomValue(orderAtom);
  const [deliveryDate, setDeliveryDate] = useAtom(orderDeliveryDateAtom);

  const handleDateChange = (value: Date | null): void => {
    setDeliveryDate(value);
  };

  useEffect(() => {
    setIsInEditMode(false);
  }, [order]);

  const canEditDeliveryDate = useMemo(() => {
    return (
      order.status === Order_Status_Enum.OrderToBeValidatedBySupplier
      || order.status === Order_Status_Enum.OrderToBeDeliveredBySupplier
      || order.status === Order_Status_Enum.OrderToBePreparedBySupplier
    ) && realm === Business_Profile_Enum.Supplier;
  }, [order]);
  const dateFormat = useLongDateFormat();
  const [initialDate] = useState(order.delivery_date);

  const handleAbortButton = ():void => {
    if (initialDate) {
      setDeliveryDate(initialDate);
    }
    setIsInEditMode(false);
  };

  const dateComponent = useMemo(() => {
    if (!canEditDeliveryDate) {
      let message: string;
      if (!deliveryDate) {
        message = order.status === Order_Status_Enum.OrderToBeValidatedBySupplier
          ? t('order:to_be_defined_by_the_supplier')
          : t('common:undefined');
      } else {
        message = format(new Date(deliveryDate), dateFormat);
      }
      return (
        <Typography variant="body1">{message}</Typography>
      );
    }
    if (!order.delivery_date) {
      return (
        <Button
          startIcon={<AddIcon />}
          onClick={() => (setIsInEditMode(true))}
          sx={{
            whiteSpace: 'normal',
            textAlign: 'start',
            display: isInEditMode ? 'none' : 'flex',
            p: 0,
            fontSize: '0.875rem',
            textDecoration: 'underline',
            '& .MuiButton-startIcon': {
              mr: 0.5,
            },
            '& .MuiLoadingButton-loadingIndicator': {
              left: 0,
            },
            '&:hover': {
              textDecoration: 'underline',
              backgroundColor: 'transparent',
            },
          }}
        >
          {t('order:add_delivery_date')}
        </Button>
      );
    }
    return (
      <Box sx={{ display: isInEditMode ? 'none' : 'flex', alignItems: 'center' }}>
        <Typography variant="body1">{format(new Date(deliveryDate!), dateFormat)}</Typography>
        {
          order.status === Order_Status_Enum.OrderToBeValidatedBySupplier
            ? (
              <IconButton color="secondary" onClick={() => setIsInEditMode(true)}>
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            )
            : (
              <OrderUpdateDeliveryDateButton
                orderId={order.id}
                orderReferenceId={order.reference_id}
                orderStatus={order.status as DeliveryDateOrderStatus}
                useEditIcon
              />
            )
        }
      </Box>
    );
  }, [canEditDeliveryDate, isInEditMode, order]);

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography
          variant="h6"
        >
          {t('common:delivery')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            mt: 2,
            width: '100%',
            gap: 2,
            flexDirection: canEditDeliveryDate ? 'column' : 'row',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2">{t('common:address')}</Typography>
            <Typography variant="body1">{order.buying_company_formatted_address}</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2">{t('common:date')}</Typography>
            {dateComponent}
            <Box sx={{ display: isInEditMode ? 'flex' : 'none', alignItems: 'center' }}>
              <CustomDateTimePicker
                shouldDialogBeOpen={isInEditMode}
                date={deliveryDate}
                handleDateChange={handleDateChange}
              />
              <IconButton color="secondary" onClick={handleAbortButton}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
