import { Box, Typography } from '@mui/material';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import i18n from '../../i18n';
import { round } from '../utils/common.utils';

interface VersionedPriceProps {
  currentValue: number;
  color: string;
  previousValue?: number | null;
  valueFormatter?: (value: number | bigint) => string;
  shouldHighlightDifference?: boolean;
  fontWeight?: number
  variant: 'h5' | 'subtitle1' | 'h6' | 'body1';
}

export default function VersionedValue({ currentValue, previousValue, color, shouldHighlightDifference, fontWeight, variant, valueFormatter }: VersionedPriceProps): React.JSX.Element {
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
  const shouldDisplayPriceDifference = (previousValue !== undefined && previousValue !== null)
    && round(previousValue, 3) !== round(currentValue, 3);
  const isPreviousInferiorToCurrent = shouldDisplayPriceDifference && previousValue < currentValue;
  const formatter = valueFormatter ?? numberFormat.format;

  return (
    <Box>
      { shouldDisplayPriceDifference && (
      <Typography sx={{ textDecorationLine: 'line-through', color: 'text.secondary' }}>
        {formatter(previousValue)}
      </Typography>
      ) }

      <Box
        sx={{
          display: 'flex',
          color: shouldDisplayPriceDifference && shouldHighlightDifference ? 'warning.main' : color,
          alignItems: 'center',
        }}
      >
        <Typography
          variant={variant}
          sx={{
            fontWeight: shouldDisplayPriceDifference ? 800 : fontWeight,
            color: shouldDisplayPriceDifference && shouldHighlightDifference ? 'warning.main' : color,
            lineHeight: 1,
          }}
        >
          {formatter(currentValue)}
        </Typography>
        { shouldDisplayPriceDifference
            && (isPreviousInferiorToCurrent
              ? <NorthEastIcon sx={{ fontSize: '1.125rem' }} />
              : <SouthEastIcon sx={{ fontSize: '1.125rem' }} />)}
      </Box>
    </Box>
  );
}
